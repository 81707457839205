/**
 * @generated SignedSource<<d6c0ea5d61e27753f82237dccbf6ea15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePeriodMutation$variables = {
  groupId: string;
  startDate: string;
};
export type CreatePeriodMutation$data = {
  readonly createPeriod: {
    readonly period: {
      readonly endDate: string;
      readonly id: string;
      readonly masterSchedule: {
        readonly id: string;
      } | null;
      readonly name: string;
      readonly numberOfWeeks: number;
      readonly schedules: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null;
        } | null>;
      };
      readonly startDate: string;
    } | null;
  } | null;
};
export type CreatePeriodMutation = {
  response: CreatePeriodMutation$data;
  variables: CreatePeriodMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "groupId",
            "variableName": "groupId"
          },
          {
            "kind": "Variable",
            "name": "startDate",
            "variableName": "startDate"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CreatePeriodPayload",
    "kind": "LinkedField",
    "name": "createPeriod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "period",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfWeeks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleNode",
            "kind": "LinkedField",
            "name": "masterSchedule",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleNodeConnection",
            "kind": "LinkedField",
            "name": "schedules",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePeriodMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePeriodMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "67b4587b149a00ba00e355899f16eac4",
    "id": null,
    "metadata": {},
    "name": "CreatePeriodMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePeriodMutation(\n  $groupId: ID!\n  $startDate: Date!\n) {\n  createPeriod(input: {groupId: $groupId, startDate: $startDate}) {\n    period {\n      id\n      name\n      startDate\n      endDate\n      numberOfWeeks\n      masterSchedule {\n        id\n      }\n      schedules {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3d4dc11ba94c2b94b63a197fe84fc41";

export default node;

import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray, isNonNull } from "relay-help/arrays";

import { SettingViewPlaceholder as Placeholder } from "components/loading/pages";
import { SettingBoxes } from "components/setting/team_group";

import { TeamGroupSettingsQuery as Query } from "./types";

const query = graphql`
  query TeamGroupSettingsQuery($teamGroupId: ID!) {
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      id
      constraintModules
      ...HoursAndShiftsSettingTeamGroup_fragment
      ...DayRestSettingTeamGroup_fragment
      ...CompetenceDemandSettingTeamGroup_fragment
      ...CostSettingTeamGroup_fragment
      ...BaseDemandSettingTeamGroup_fragment
      ...ConsecutiveWorkSettingTeamGroup_fragment
      ...ConsecutiveRestSettingTeamGroup_fragment
      ...WorkDayCadenceSettingTeamGroup_fragment
      ...DayNightFairnessSettingTeamGroup_fragment
      ...DayNightDistributionSettingTeamGroup_fragment
      ...DayNightCadenceSettingTeamGroup_fragment
      ...WeekRestSettingTeamGroup_fragment
      ...WeekendRestSettingTeamGroup_fragment
      ...ShiftDistributionSettingTeamGroup_fragment
      ...ResourceTimeSettingTeamGroup_fragment
      ...SplitShiftsSettingTeamGroup_fragment
      ...ResponsibilityTimeSettingTeamGroup_fragment
      ...OptimizationSettingTeamGroup_fragment
      ...SmoothResourcesSettingTeamGroup_fragment
      ...RestBetweenShiftsSettingTeamGroup_fragment
      ...ClockwiseRotationSettingTeamGroup_fragment
      ...RequestedDaysOffSettingTeamGroup_fragment
      ...VacationSettingTeamGroup_fragment
      ...NightShiftSettingTeamGroup_fragment
      ...PartTimeSettingTeamGroup_fragment
      ...ConstraintModuleSwitch_fragment
    }
    usersOnTeamGroup(teamGroupId: $teamGroupId) {
      id
      fullName
      userSetting {
        settingModules
        ...HoursAndShiftsSettingUserSetting_fragment
        ...DayRestSettingUserSetting_fragment
        ...ConsecutiveWorkSettingUserSetting_fragment
        ...ConsecutiveRestSettingUserSetting_fragment
        ...WorkDayCadenceSettingUserSetting_fragment
        ...DayNightFairnessSettingUserSetting_fragment
        ...DayNightDistributionSettingUserSetting_fragment
        ...DayNightCadenceSettingUserSetting_fragment
        ...WeekRestSettingUserSetting_fragment
        ...WeekendRestSettingUserSetting_fragment
        ...ShiftDistributionSettingUserSetting_fragment
        ...ResourceTimeSettingUserSetting_fragment
        ...ResponsibilityTimeSettingUserSetting_fragment
        ...NightShiftSettingUserSetting_fragment
      }
      ...SettingExceptionsUser_fragment
    }
    ruleGroups(teamGroupId: $teamGroupId) {
      id
      name
      ruleGroupSetting {
        id
        settingModules
        ...HoursAndShiftsSettingRuleGroup_fragment
        ...ConsecutiveWorkSettingRuleGroup_fragment
        ...DayRestSettingRuleGroup_fragment
        ...WeekRestSettingRuleGroup_fragment
        ...WeekendRestSettingRuleGroup_fragment
        ...ConsecutiveRestSettingRuleGroup_fragment
        ...ShiftDistributionSettingRuleGroup_fragment
        ...NightShiftSettingRuleGroup_fragment
        ...CostSettingRuleGroup_fragment
        ...PartTimeSettingRuleGroup_fragment
      }
      ...SettingExceptionsRuleGroup_fragment
    }
    shiftTypes(teamGroupId: $teamGroupId) {
      id
      name
      start
      end
      shiftParts {
        edges {
          node {
            id
          }
        }
      }
    }
    shifts(teamGroupId: $teamGroupId) {
      shifts {
        worktimeHours
      }
    }
  }
`;

type Props = {
  ruleId?: string;
};

type ContentProps = Props & {
  queryRef: PreloadedQuery<Query>;
};

function Content({ ruleId, queryRef }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const shiftTypes = useMemo(
    () =>
      (data?.shiftTypes || []).filter(isNonNull).map((x) => ({
        ...x,
        shiftParts: connectionToArray(x.shiftParts),
      })),
    [data.shiftTypes],
  );

  return (
    <SettingBoxes
      ruleId={ruleId}
      fragmentRef={data.settingForTeamGroup}
      teamGroupMembers={data.usersOnTeamGroup}
      constraintModulesRef={data.settingForTeamGroup!}
      shiftTypes={shiftTypes}
      shifts={(data.shifts || []).flatMap((x) => x.shifts)}
      ruleGroups={data.ruleGroups}
    />
  );
}

export function TeamGroupSettings({ ruleId }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id;

  useEffect(() => {
    if (!teamGroupId) return;
    loadQuery({ teamGroupId });
  }, [teamGroupId, loadQuery]);

  return (
    <Suspense fallback={<Placeholder />}>
      {!!queryRef && <Content ruleId={ruleId} queryRef={queryRef} />}
    </Suspense>
  );
}

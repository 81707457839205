import { SettingBox } from "components/setting/common";
import { ClockwiseShiftRotationField } from "components/setting/common/forms/Advanced";

type Props = { readOnly?: boolean };

export function ClockwiseShiftRotationOldForm({ readOnly }: Props) {
  return (
    <SettingBox>
      <ClockwiseShiftRotationField readOnly={readOnly} />
    </SettingBox>
  );
}

import { yupTimeString } from "libs/yup";
import { DateTime, Duration } from "luxon";
import * as yup from "yup";

import type { ShiftValue } from "components/shifts/form/validator";
import {
  shiftPartOutside,
  shiftPartsOverlap,
} from "components/shifts/form/validator";
import { shiftPartTypeChoices } from "components/shifts/types";

import { meetingErrorCodeChoices, meetingSelectChoices } from "../types";
import { hhmm2Dt, START_OF_WEEK, startEndStringToDateTime } from "../utils";

interface StartEndDates {
  start: DateTime;
  end: DateTime;
}

function aCoversB<A extends StartEndDates, B extends StartEndDates>(
  { start: astart, end: aend }: A,
  { start: bstart, end: bend }: B,
) {
  return astart <= bstart && aend >= bend;
}

const shiftPartSchema = yup.object({
  id: yup.string().nullable(),
  start: yupTimeString.required("Får ej vara tomt"),
  end: yupTimeString.required("Får ej vara tomt"),
  partType: yup
    .string()
    .oneOf(shiftPartTypeChoices, "Ogiltig deltyp")
    .required("Får ej vara tomt"),
});

const meetingShiftSchema = yup
  .object({
    id: yup.string().nullable(),
    name: yup.string().required("Får ej vara tomt"),
    start: yupTimeString.required("Får ej vara tomt"),
    end: yupTimeString.required("Får ej vara tomt"),
    meetingDay: yup.number().integer().required(),
    breakTime: yup
      .number()
      .min(0, "Får inte vara negativt")
      .required("Får ej vara tomt"),
    shiftParts: yup
      .array(shiftPartSchema.required("Får ej vara tomt"))
      .required(),
  })
  .test(
    "shiftPartsOverlap",
    "Passdel överlappar med annan passdel",
    (value, context) => shiftPartsOverlap(value, context),
  )
  .test("partOutsideShift", "Passdel utanför pass", (value, context) =>
    shiftPartOutside(value, context),
  );

// Fields specified in src/components/meeting/mutations/SuggestMeetingShifts.ts
const meetingErrorCodeSchema = yup
  .string()
  .oneOf(meetingErrorCodeChoices as string[]);
const meetingErrorCausedByMeetingSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
});
const meetingErrorCausedByUserSchema = yup.object({
  id: yup.string(),
  fullName: yup.string(),
});

const meetingUserErrorSchema = yup.object({
  code: meetingErrorCodeSchema.required(),
  causedByMeeting: meetingErrorCausedByMeetingSchema.required(),
  causedByUser: meetingErrorCausedByUserSchema.required(),
});
const meetingOverlapsWithShiftPartError = yup.object({
  code: meetingErrorCodeSchema.required(),
  causedByShift: yup.object({ name: yup.string().required() }),
});
const meetingErrorSchema = yup
  .object()
  .oneOf([meetingUserErrorSchema, meetingOverlapsWithShiftPartError]);

const meetingSelectSchema = yup
  .string()
  .oneOf(meetingSelectChoices as string[]);

export const meetingFormValidationSchema = yup.object({
  id: yup.string(),
  name: yup.string().required("Mötet måste ha ett namn"),
  start: yupTimeString.required("Mötet måste ha en starttid"),
  end: yupTimeString
    .required("Mötet måste ha en sluttid")
    .test("tooShort", "Mötet måste pågå minst 15 minuter", function (end) {
      const start: string = this.parent.start;
      if (!start) return true;

      const [startD, endD] = startEndStringToDateTime(
        { start, end },
        START_OF_WEEK,
        "HH:mm",
      );

      return endD.diff(startD) >= Duration.fromObject({ minutes: 15 });
    }),
  selectDaysBy: meetingSelectSchema.required("Fältet får inte vara tomt"),
  users: yup
    .array(yup.string().required())
    .min(1, "Mötet måste ha minst 1 deltagare")
    .required("Mötet måste ha deltagare"),
  dayMatrix: yup
    .array(yup.array(yup.number().required()).required())
    .min(1, "Måste innehålla minst en vecka")
    .required("Måste välja dagar")
    .test("dayMatrixEmpty", "Dagmatrisen får inte vara tom", (value) =>
      value.some((row) => row.length > 0),
    ),
  shifts: yup
    .array(
      meetingShiftSchema
        .required("Obligatoriskt")
        .test(
          "shiftOutsideMeeting",
          "Passet täcker inte mötet",
          function test({ start, end }: ShiftValue) {
            const {
              start: mStart,
              end: mEnd,
              meetingDay,
            } = this?.options?.context ?? {};
            if (!start || !end || !mStart || !mEnd) return true;

            const meetingStart = hhmm2Dt(
              START_OF_WEEK.plus({ days: meetingDay }),
              mStart,
            );
            let meetingEnd = hhmm2Dt(meetingStart, mEnd);
            if (meetingEnd < meetingStart) {
              meetingEnd = meetingEnd.plus({ days: 1 });
            }
            let shiftStart = hhmm2Dt(meetingStart, start);
            if (shiftStart > meetingStart) {
              shiftStart = shiftStart.minus({ days: 1 });
            }
            let shiftEnd = hhmm2Dt(shiftStart, end);
            if (shiftEnd < shiftStart) {
              shiftEnd = shiftEnd.plus({ days: 1 });
            }

            return aCoversB(
              { start: shiftStart, end: shiftEnd },
              { start: meetingStart, end: meetingEnd },
            );
          },
        ),
    )
    .min(1, "Möte måste innehålla minst 1 pass")
    .required("Mötet måte innehålla pass"),
  errors: yup.array(meetingErrorSchema).max(0, "Får inte ha valideringsfel"),
});

import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultTeamGroupSubmitFunction,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import {
  WeekendRestSettingRuleGroup_fragment$data as RuleGroupData,
  WeekendRestSettingRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/WeekendRestSettingRuleGroup_fragment.graphql";
import {
  WeekendRestSettingTeamGroup_fragment$data as TeamGroupData,
  WeekendRestSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/WeekendRestSettingTeamGroup_fragment.graphql";
import {
  WeekendRestSettingUserSetting_fragment$data as UserSettingData,
  WeekendRestSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/WeekendRestSettingUserSetting_fragment.graphql";
import { WeekendRestForm } from "./WeekendRestForm";
import { WeekendRestTeamgroupOnlyForm } from "./WeekendRestTeamGroupOnlyForm";

const teamGroupFragment = graphql`
  fragment WeekendRestSettingTeamGroup_fragment on SettingNode {
    id
    periodLengthWeeks
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendWeight
    freeWeekendSoft
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    constraintModules
  }
`;

const userSettingFragment = graphql`
  fragment WeekendRestSettingUserSetting_fragment on UserSettingNode {
    id
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendSoft
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    settingModules
  }
`;

const ruleGroupFragment = graphql`
  fragment WeekendRestSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendSoft
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    settingModules
  }
`;

const SETTING_NAME = "Helgvila";
const MODULE_NAME = "FreeWeekends";
const SETTING_URL_ID = "weekend-rest";

type WeekendRestFormValues = Omit<
  TeamGroupData,
  " $fragmentType" | "periodLengthWeeks" | "freeWeekendWeight"
>;

type WeekendRestTeamGroupFormValues = Pick<
  TeamGroupData,
  "id" | "freeWeekendWeight" | "constraintModules"
>;

type AdditionalData = {
  periodLengthWeeks: number;
};

const validationSchema = yup.object().shape({
  id: yup.string().required("Får ej vara tomt"),
  workEveryXWeekend: yup
    .number()
    .required("Får ej vara tomt")
    .max(10, "Måste vara högst 10")
    .min(1, "Måste vara minst 1"),
  freeEveryXOfYWeekends: yup
    .number()
    .required("Får ej vara tomt")
    .max(52, "Måste vara högst 52")
    .min(0, "Måste vara minst 0"),
  freeWeekendConstraintTypeEveryX: yup.boolean().required("Får ej vara tomt"),
  freeWeekendConstraintTypeXOfY: yup.boolean().required("Får ej vara tomt"),
  freeWeekendSoft: yup.boolean().required("Får ej vara tomt"),
  workEveryUnfreeWeekend: yup.boolean().required("Får ej vara tomt"),
  workEveryUnfreeWeekendSoft: yup.boolean().required("Får ej vara tomt"),
  constraintModules: yup.array().of(yup.string()),
});

function baseConvertDataToInitialValues(
  data: TeamGroupData | UserSettingData | RuleGroupData,
) {
  return {
    id: data.id,
    workEveryXWeekend: data?.workEveryXWeekend || 2,
    freeEveryXOfYWeekends: data?.freeEveryXOfYWeekends || 0,
    freeWeekendConstraintTypeEveryX:
      data.freeWeekendConstraintTypeEveryX ?? true,
    freeWeekendConstraintTypeXOfY: data.freeWeekendConstraintTypeXOfY ?? true,
    freeWeekendSoft: data.freeWeekendSoft ?? true,
    workEveryUnfreeWeekend: data.workEveryUnfreeWeekend ?? true,
    workEveryUnfreeWeekendSoft: data.workEveryUnfreeWeekendSoft ?? true,
  };
}

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): WeekendRestFormValues {
  return {
    ...baseConvertDataToInitialValues(teamGroupData),
    constraintModules: teamGroupData.constraintModules,
  };
}
function convertUserOrRuleGroupDataToInitialValues(
  data: UserSettingData | RuleGroupData,
): WeekendRestFormValues {
  return {
    ...baseConvertDataToInitialValues(data),
    constraintModules: data.settingModules,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): WeekendRestTeamGroupFormValues {
  return {
    id: teamGroupData.id,
    freeWeekendWeight: teamGroupData?.freeWeekendWeight || "M",
    constraintModules: teamGroupData.constraintModules,
  };
}

function useAdditionalDataForTeamGroup(fragmentRef: TeamGroupKey) {
  const teamGroupData = useFragment(teamGroupFragment, fragmentRef);
  const periodLengthWeeks = teamGroupData.periodLengthWeeks;
  return {
    periodLengthWeeks,
  };
}

function renderComponent(
  initialValues: WeekendRestFormValues,
  onSubmit: (formValues: WeekendRestFormValues) => void,
  additionalData: AdditionalData,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <WeekendRestForm periodLengthWeeks={additionalData.periodLengthWeeks} />
    </BaseSettings>
  );
}

function renderTeamGroupComponent(
  initialValues: WeekendRestTeamGroupFormValues,
  onSubmit: (formValues: WeekendRestTeamGroupFormValues) => void,
) {
  return (
    <BaseSettings initialValues={initialValues} onSubmit={onSubmit}>
      <WeekendRestTeamgroupOnlyForm />
    </BaseSettings>
  );
}

export const weekendRestSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  RuleGroupKey,
  WeekendRestFormValues,
  AdditionalData,
  WeekendRestTeamGroupFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  useAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
    renderComponent: renderTeamGroupComponent,
  },
};

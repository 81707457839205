import type { Unwrap } from "relay-help/arrays";

import type { SuggestMeetingShiftsMutation$data as SuggestMeetingShiftsData } from "./__generated__/SuggestMeetingShiftsMutation.graphql";
import type {
  MeetingErrorCode,
  ValidateMeetingShiftsMutation$data as ValidateData,
} from "./__generated__/ValidateMeetingShiftsMutation.graphql";

export * from "./__generated__/CreateMeetingMutation.graphql";
export * from "./__generated__/DeleteMeetingMutation.graphql";
export type {
  EditMeetingInput,
  EditMeetingMutation,
  EditMeetingMutation$data,
  EditMeetingMutation$variables,
} from "./__generated__/EditMeetingMutation.graphql";
export * from "./__generated__/SuggestMeetingShiftsMutation.graphql";
export type {
  ValidateMeetingShiftsInput,
  ValidateMeetingShiftsMutation,
  ValidateMeetingShiftsMutation$data,
  ValidateMeetingShiftsMutation$variables,
} from "./__generated__/ValidateMeetingShiftsMutation.graphql";

type SuggestMeetingShiftsReturn = NonNullable<
  SuggestMeetingShiftsData["suggestMeetingShifts"]
>;
export type SuggestedMeetingShifts = SuggestMeetingShiftsReturn["shifts"];
export type SuggestedMeetingShift = Unwrap<SuggestedMeetingShifts>;
export type MeetingError = Unwrap<
  NonNullable<NonNullable<ValidateData["validateMeetingShifts"]>["errors"]>
>;
export enum meetingErrorCodes {
  MEETING_OVERLAPS_WITH_SHIFT_PART = "MEETING_OVERLAPS_WITH_SHIFT_PART",
  MEETING_SHIFT_DOES_NOT_COVER_MEETING = "MEETING_SHIFT_DOES_NOT_COVER_MEETING",
  OTHER = "OTHER",
  USER_HAS_CONFLICTING_MEETING = "USER_HAS_CONFLICTING_MEETING",
}
export const meetingErrorCodeChoices: MeetingErrorCode[] =
  Object.values(meetingErrorCodes);

type UserHasConflictingMeeting = MeetingError & {
  causedByMeeting: NonNullable<MeetingError["causedByMeeting"]>;
  causedByUser: NonNullable<MeetingError["causedByUser"]>;
};
type MeetingOverlapsWithShiftPart = MeetingError & {
  causedByShift: NonNullable<MeetingError["causedByShift"]>;
};
type MeetingShiftDoesNotCoverMeeting = MeetingError & {
  causedByShift: NonNullable<MeetingError["causedByShift"]>;
};

export function isErrorUserHasConflictingMeeting(
  e: MeetingError,
): e is UserHasConflictingMeeting {
  return e.code === meetingErrorCodes.USER_HAS_CONFLICTING_MEETING;
}
export function isErrorMeetingOverlapsWithShiftPart(
  e: MeetingError,
): e is MeetingOverlapsWithShiftPart {
  return e.code === meetingErrorCodes.MEETING_OVERLAPS_WITH_SHIFT_PART;
}

export function isErrorMeetingShiftDoesNotCoverMeeting(
  e: MeetingError,
): e is MeetingShiftDoesNotCoverMeeting {
  return e.code === meetingErrorCodes.MEETING_SHIFT_DOES_NOT_COVER_MEETING;
}

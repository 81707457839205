import { DateTime } from "luxon";

import type { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";
import DatePickerWithWeekNumbers from "components/schedule/DatePickerWithWeekNumbers";

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
};

export default function RecurrenceDateItem({ value, onChange }: Props) {
  function handleChange(inputDate: DateTime | null) {
    onChange({
      ...value,
      dtstart: inputDate ? inputDate.toJSDate() : new Date(),
    });
  }

  return (
    <DatePickerWithWeekNumbers
      onChange={handleChange}
      value={
        value.dtstart
          ? DateTime.fromJSDate(value.dtstart)
          : DateTime.fromJSDate(new Date())
      }
      views={["year", "month", "day"]}
    />
  );
}

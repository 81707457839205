import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import { ValidateMeetingShiftsMutation as Mutation } from "./types";

const mutation = graphql`
  mutation ValidateMeetingShiftsMutation($input: ValidateMeetingShiftsInput!) {
    validateMeetingShifts(input: $input) {
      ok
      errors {
        code
        causedByMeeting {
          id
          name
        }
        causedByUser {
          id
          fullName
        }
        causedByShift {
          name
        }
        partStartsAt
      }
    }
  }
`;

export const useValidateMeetingShifts = () => useMutation<Mutation>(mutation);

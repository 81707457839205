/**
 * @generated SignedSource<<c559a0f82c78b8d6b3ba600160fb7cae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiUserSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiUserSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NightShiftSettingUserSetting_fragment$data = {
  readonly id: string;
  readonly nightShiftRestriction: ApiUserSettingNightShiftRestrictionChoices;
  readonly nightShiftRotation: ApiUserSettingNightShiftRotationChoices;
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "NightShiftSettingUserSetting_fragment";
};
export type NightShiftSettingUserSetting_fragment$key = {
  readonly " $data"?: NightShiftSettingUserSetting_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NightShiftSettingUserSetting_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NightShiftSettingUserSetting_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRestriction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRotation",
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "1f43cab721b269bba92f084e39422333";

export default node;

import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "styles/colors";
import { useBooleanState } from "utils/useBooleanState";

type ExceptionOption = {
  settingName: string;
  moduleName: string;
};

type Props = {
  name: string;
  options: ExceptionOption[];
  addException: (settingModule: string) => void;
};

export function ExceptionViewHeader({ name, options, addException }: Props) {
  const [key, setKey] = useState(0);
  const {
    value: isFocused,
    setTrue: setFocused,
    setFalse: setNotFocused,
  } = useBooleanState();

  const menuItems = useMemo(() => {
    return options.map((option) => {
      return {
        label: option.settingName,
        id: option.moduleName,
      };
    });
  }, [options]);

  const onChangeCallback = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      value: { id: string; label: string } | null,
    ) => {
      if (value) {
        addException(value.id);
        setKey((k) => k + 1);
        setFocused();
      }
    },
    [addException, setKey, setFocused],
  );

  const renderInputCallback = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label="Lägg till undantag"
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <Add />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          ...params.InputLabelProps,
          shrink: isFocused || params.inputProps.value !== "",
        }}
        onFocus={setFocused}
        onBlur={setNotFocused}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: Colors.TURE,
            },
          },
          "& .MuiInputLabel-root": {
            color: Colors.TURE,
            "&:not(.MuiInputLabel-shrink)": {
              padding: "0 20px",
            },
          },
          "& input": {
            color: Colors.TURE,
          },
        }}
      />
    ),
    [isFocused, setFocused, setNotFocused],
  );

  /*
    The key in the autocomplete form is there to force a rerender of the component.
    This is an ugly hack which we want to revisit and remove. 
    The component needs to be rerendered for it to clear the input field when an option is selected. 
  */

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="h1" component="span" sx={{ color: "text.primary" }}>
        Undantag för {name}
      </Typography>
      <Autocomplete
        disablePortal
        size="small"
        options={menuItems}
        onChange={onChangeCallback}
        sx={{ minWidth: "200px", maxHeight: "500px" }}
        renderInput={renderInputCallback}
        key={key}
      />
    </Stack>
  );
}

import { Stack, Typography } from "@mui/material";

import { MipgapLimit } from "./MipgapLimit";

type Props = {
  readOnly?: boolean;
};

export function MipgapLimitField({ readOnly }: Props) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Mipgap-gräns</Typography>
      <Typography>
        Detta fält styr toleransnivån på mip-gap. Vi rekommenderar att denna
        inställning alltid bör vara 5%.
      </Typography>
      <MipgapLimit readOnly={readOnly} />
    </Stack>
  );
}

import { useCallback, useMemo } from "react";
import { useField } from "formik";

import { ApiBlockedTimeSlotTimeslotTypeChoices } from "components/blocked_time_slot/mutations/__generated__/EditBlockedTimeSlotMutation.graphql";
import {
  BlockedTimeSlotTimeslotTypeChoices,
  TranslateBlockedTimeSlotCategory,
  TranslateBlockedTimeSlotType,
} from "components/blocked_time_slot/types";

import GenericSelect from "./GenericSelect";

type Option = {
  value: ApiBlockedTimeSlotTimeslotTypeChoices;
  label: string;
  category: string;
};

type BlockedTimeSlotTypeSelectProps = {
  name?: string;
  label?: string;
};

const getOptionCategory = (option: Option) => option.category;

const BlockedTimeSlotTypeSelect = ({
  name = "timeslotType",
  label = "Händelsetyp",
}: BlockedTimeSlotTypeSelectProps) => {
  const [, meta, helpers] =
    useField<ApiBlockedTimeSlotTimeslotTypeChoices>(name);
  const { value } = meta;
  const { setValue } = helpers;
  const activityOptions: ApiBlockedTimeSlotTimeslotTypeChoices[] =
    BlockedTimeSlotTimeslotTypeChoices;
  const options: Option[] = useMemo(
    () =>
      activityOptions.map((u) => ({
        value: u,
        label: TranslateBlockedTimeSlotType(u) ?? "",
        category: TranslateBlockedTimeSlotCategory(u) ?? "",
      })),
    [activityOptions],
  );
  const selected = useMemo(
    () => options?.find((o) => o.value === value),
    [options, value],
  );

  const onChange = useCallback(
    (_e: React.SyntheticEvent, v: Option) => setValue(v.value),
    [setValue],
  );
  const getOptionLabel = useCallback((option: Option) => option.label, []);

  return (
    <GenericSelect
      label={label}
      options={options}
      value={selected}
      onChange={onChange}
      groupBy={getOptionCategory}
      getOptionLabel={getOptionLabel}
      placeholder="Välj händelsetyp..."
    />
  );
};

export default BlockedTimeSlotTypeSelect;

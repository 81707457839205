import { SettingBox } from "components/setting/common";
import { WishedDaysOffField } from "components/setting/common/forms/Advanced";

type Props = { readOnly?: boolean };

export function WishedDaysOffOldForm({ readOnly }: Props) {
  return (
    <SettingBox>
      <WishedDaysOffField readOnly={readOnly} />
    </SettingBox>
  );
}

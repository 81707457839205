import { PageHeader } from "components/layout/PageWrapper";

type Props = {
  teamGroupName: string;
  info?: React.ReactNode;
};

export default function DemandHeader({ teamGroupName, info }: Props) {
  const title = `Behovsprofil för enhet ${teamGroupName}`;
  return <PageHeader title={title} info={info} />;
}

import { useState } from "react";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";

import type { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";

import CountSelect from "./CountSelect";
import UntilDateSelect from "./UntilDateSelect";

const COUNT = "count";
const UNTIL = "until";

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
};

export default function RecurrenceUntilSelect({ value, onChange }: Props) {
  const [selectedLimit, setSelectedLimit] = useState(
    value?.count ? COUNT : UNTIL,
  );

  const isActive: boolean = value?.count !== null || !!value?.until;

  function handleCheckboxChange(checked: boolean) {
    const newVal = { ...value };
    if (checked) {
      newVal.count = selectedLimit === COUNT ? value.count || 1 : null;
      newVal.until = selectedLimit === UNTIL ? value.until || new Date() : null;
    } else {
      newVal.count = null;
      newVal.until = null;
    }
    onChange(newVal);
  }

  function handleUntilChange(until: Date | null) {
    const newVal = { ...value };
    if (!until) {
      setSelectedLimit(COUNT);
      newVal.count = value.count || 1;
      newVal.until = null;
    } else {
      newVal.until = until;
      newVal.count = null;
    }
    onChange(newVal);
  }

  function handleCountChange(count: number | "" | null) {
    const newVal = { ...value };
    if (count === null) {
      setSelectedLimit(UNTIL);
      newVal.count = null;
      newVal.until = value.until || new Date();
    } else {
      newVal.count = !count ? 0 : count;
      newVal.until = null;
    }
    onChange(newVal);
  }

  return (
    <Stack gap={0}>
      <FormControlLabel
        label="Upprepa regeln tills..."
        control={<Checkbox />}
        checked={isActive}
        onChange={(_e: any, checked) => handleCheckboxChange(checked)}
      />
      <Stack gap={1}>
        <UntilDateSelect value={value.until} onChange={handleUntilChange} />
        <CountSelect value={value.count} onChange={handleCountChange} />
      </Stack>
    </Stack>
  );
}

import type { ReactNode } from "react";
import { Divider, Stack } from "@mui/material";

type Props = {
  titleComponent?: ReactNode;
  switchComponent?: ReactNode;
  radiosComponent?: ReactNode;
  weightComponent?: ReactNode;
};

export const SettingBoxHeader = ({
  titleComponent,
  switchComponent,
  radiosComponent,
  weightComponent,
}: Props) => {
  return (
    <Stack gap={1}>
      {titleComponent}
      <Stack direction="row" alignItems="center" flexGrow={2}>
        {switchComponent}
        <Stack
          gap={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {radiosComponent}
          {weightComponent}
        </Stack>
      </Stack>
      <Divider sx={{ mb: 2 }} />
    </Stack>
  );
};

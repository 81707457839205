import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertDataToInitialValues,
  defaultConvertTeamGroupDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { CostSettingRuleGroup_fragment$key as RuleGroupKey } from "./__generated__/CostSettingRuleGroup_fragment.graphql";
import {
  CostSettingTeamGroup_fragment$data as TeamGroupData,
  CostSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/CostSettingTeamGroup_fragment.graphql";
import { Cost } from "./CostForm";

const teamGroupFragment = graphql`
  fragment CostSettingTeamGroup_fragment on SettingNode {
    id
    hourlyCost
  }
`;

const ruleGroupFragment = graphql`
  fragment CostSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    hourlyCost
  }
`;

const SETTING_NAME = "Kostnader";
const MODULE_NAME = "HourlyCost";
const SETTING_URL_ID = "cost";

type CostFormValues = Pick<TeamGroupData, "id" | "hourlyCost">;

const validationSchema = yup.object().shape({
  id: yup.string(),
  hourlyCost: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(10000, "Får ej överstiga 10000")
    .required("Får ej vara tomt"),
});

function renderComponent(
  initialValues: CostFormValues,
  onSubmit: (formValues: CostFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Cost />
    </BaseSettings>
  );
}

export const costSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  RuleGroupKey,
  CostFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: defaultConvertDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
};

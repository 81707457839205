import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  HeaderWeight,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Types6, Types6Info as Info } from "components/setting/common/forms";

const MODULE_NAME = "ShiftDayTypeFairness";

type FormProps = {
  groupName: string;
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  const title = (
    <>
      Dag-/Kväll-/Natt-rättvisa för enhet <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      type="onlySoft"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="dayTypeFairnessWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Types6Form({ groupName, readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title name={groupName} />}
          weightComponent={<Weight readOnly={readOnly} disabled={disabled} />}
        />
        <Types6 readOnly={readOnly} disabled={disabled} />
      </Stack>
    </SettingBox>
  );
}

import { Button } from "@mui/material";

import type { GroupSettingCardProps } from "./GroupSettingCard";
import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<GroupSettingCardProps, "title" | "action"> & {
  openCreateTeam?: () => void;
  noAction?: boolean;
};

const TITLE = "Avdelningar";

export function TeamsCard({
  openCreateTeam = () => {},
  noAction,
  ...props
}: Props) {
  return (
    <GroupSettingCard
      title={TITLE}
      action={
        noAction ? undefined : (
          <Button variant="outlined" onClick={openCreateTeam}>
            Skapa ny avdelning
          </Button>
        )
      }
      {...props}
    />
  );
}

TeamsCard.id = "teams" as const;
TeamsCard.title = TITLE;

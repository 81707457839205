import { useCallback, useMemo } from "react";
import type { CSSObject } from "@mui/material";
import { linearGradient } from "helpers/stylingFunctions";
import { connectionToArray } from "relay-help/arrays";
import { secondaryColors } from "styles/mui/light-palette";

import { shiftDayTypes } from "components/shifts/types";

import type { ColorFocusType } from "../../types";
import { colorFocuses } from "../../types";

import { important } from "./GanttItem";
import type {
  ActivityGanttTimelineActivity as Item,
  ActivityGanttTimelinePreviousWeekActivity as PrevItem,
  ActivityGanttTimelineQuery$data as Data,
  PrevTimelineItem,
  TimelineItem,
  UserItem,
} from "./types";
import { activityTypes } from "./types";

const INCONSPICUOUS = "inconspicuous";

function merge(a: any, b: any) {
  return Object.assign(a, b);
}

function listCompare(a: string[], b: string[]): number {
  if (a.length !== b.length) return a.length - b.length;
  for (let i = 0; i < a.length; i++) {
    const ab = a[i].localeCompare(b[i]);
    if (ab !== 0) return ab;
  }
  return 0;
}

function getAlterationColor(alteration?: number | null) {
  if (alteration == null) {
    return "lightgray";
  }
  if (alteration < 0) {
    return secondaryColors.hjarta;
  }
  if (alteration > 0) {
    return secondaryColors.fisk;
  }
  return "lightgray";
}

function resolveItemProps(
  item: TimelineItem,
  group: any,
  colorFocusType: ColorFocusType,
  filterOnTeam: string | null,
): { className: string; sx: CSSObject } {
  const classes = [];
  const sx: CSSObject = {};
  const bgKey = "background";

  // Define itemClass for the base case
  let itemClass = `rct-item-${item.titleCode.toLowerCase()}`;
  if (item.isPart && ["J", "M"].includes(item.titleCode)) {
    itemClass += item.activity.activityType.toLowerCase();
  }

  // If a resource shift belongs to a team which is not one of the user's
  // home team, then it is a fixed resource shift and gets special coloring.
  const group_teams = group?.teams ? group?.teams.map((t: any) => t.id) : [];
  if (
    item.titleCode === activityTypes.RESOURCE &&
    !item.isPart &&
    item.team &&
    !group_teams.includes(item.team?.id)
  ) {
    itemClass += "-fixed";
  }

  // Apply special styling depending on colorFocusType
  if (colorFocusType === "activity_types") {
    classes.push(itemClass);
  } else if (["S", "R", "Z", "J", "M"].includes(item.titleCode)) {
    if (colorFocusType === "start_end_alterations") {
      const colors = [];
      colors.push(getAlterationColor(item?.startAlteration));
      colors.push(getAlterationColor(item?.endAlteration));
      if (colors.length > 0) {
        sx[bgKey] = important(linearGradient(colors));
      } else {
        sx[bgKey] = important("lightgray");
      }
    } else if (colorFocusType === "day_evening_night") {
      switch (item.activity.shiftDayType) {
        case shiftDayTypes.FULL_DAY:
          classes.push("rct-shift-pass-full");
          break;
        case shiftDayTypes.DAY:
          classes.push("rct-shift-pass-day");
          break;
        case shiftDayTypes.EVENING:
          classes.push("rct-shift-pass-evening");
          break;
        case shiftDayTypes.NIGHT:
          classes.push("rct-shift-pass-night");
          break;
        default:
          break;
      }
    } else if (colorFocusType === "competences") {
      const colors = (group?.competences || []).map((c: any) => c.color);
      if (colors.length > 0) {
        sx[bgKey] = important(linearGradient(colors));
      } else {
        sx[bgKey] = important("lightgray");
      }
    } else if (colorFocusType === "teams") {
      const color = item?.team?.color;
      if (color) {
        sx[bgKey] = important(color);
      } else {
        sx[bgKey] = important("lightgray");
      }
      if (filterOnTeam && item?.team?.id !== filterOnTeam) {
        classes.push(INCONSPICUOUS);
      }
    } else if (colorFocusType === "activity_types") {
      classes.push(itemClass);
    }
  } else {
    classes.push(`${INCONSPICUOUS}-rest`);
  }
  const className = classes.join(" ");

  return {
    className,
    sx,
  };
}

export function useItemsToTimelineItems(
  items: ReadonlyArray<Item> | ReadonlyArray<PrevItem>,
  {
    colorFocus,
    groups,
    selectedActivities,
    selectedTeam,
    itemProps,
  }: {
    colorFocus: ColorFocusType;
    groups: UserItem[];
    selectedActivities: string[];
    selectedTeam: string | null;
    itemProps?: TimelineItem["itemProps"];
  },
) {
  const activities = useMemo(
    () =>
      items.flatMap((a) =>
        a.ganttItems.map(({ title, ...i }) => ({
          id: i.id,
          group: a.user?.id || "",
          titleCode: i.titleCode,
          itemTitle: title,
          activityId: a.id || "",
          start_time: Date.parse(i.startTime) || 0,
          end_time: Date.parse(i.endTime) || 0,
          isPart: i.isPart,
          shiftName: a?.shift?.name ?? a.meetingShift?.name ?? "",
          shiftDayType: a?.shiftDayType || "",
          breakTime: i.breakTime,
          startAlteration: i.startAlteration,
          endAlteration: i.endAlteration,
          duration: i.duration,
          worktime: i.worktime,
          activity: a,
          team: a.team,
        })),
      ),
    [items],
  );

  // activities and activitiesWithItemProps are split to reduce what needs to be re-evaluated.
  const activitiesWithItemProps = useMemo(
    () =>
      activities.map((i) => ({
        ...i,
        itemProps: merge(
          resolveItemProps(
            i,
            groups.find((g) => g.id === i.activity.user?.id),
            colorFocus,
            selectedTeam || null,
          ),
          itemProps,
        ),
      })),
    [activities, groups, colorFocus, itemProps, selectedTeam],
  );

  const activityTypeFilter = useCallback(
    (i: TimelineItem) => selectedActivities.includes(i?.titleCode || ""),
    [selectedActivities],
  );
  /** Do not filter out activities if team is null, or if color focus is 'teams'. */
  const teamFilter = useCallback(
    (i: TimelineItem) =>
      selectedTeam === null ||
      i?.team === null ||
      colorFocus === colorFocuses.TEAMS ||
      i?.team?.id === selectedTeam,
    [selectedTeam, colorFocus],
  );

  const timelineItems = useMemo(
    () => activitiesWithItemProps.filter(activityTypeFilter).filter(teamFilter),
    [activitiesWithItemProps, activityTypeFilter, teamFilter],
  );

  return timelineItems;
}

export function useGroups(
  data: Data,
  {
    selectedTeam,
    selectedCompetence,
  }: { selectedTeam: string | null; selectedCompetence: string | null },
) {
  const itemsOnGroup = useMemo(
    () =>
      connectionToArray(data?.schedule?.activities).reduce(
        (acc, a) => {
          const id = a?.user?.id;
          if (!id) return acc;

          if (!acc[id]) {
            acc[id] = { teams: [] };
          }
          const teamId = a.team?.id;
          if (!teamId) return acc;

          if (!acc[id].teams.includes(teamId)) {
            acc[id].teams.push(teamId);
          }
          return acc;
        },
        {} as Record<string, { teams: string[] }>,
      ),
    [data?.schedule?.activities],
  );

  const teamFilter = useCallback(
    (g: UserItem) =>
      selectedTeam === null ||
      (itemsOnGroup?.[g.id]?.teams || []).includes(selectedTeam),
    [selectedTeam, itemsOnGroup],
  );
  const competenceFilter = useCallback(
    (g: UserItem) =>
      selectedCompetence === null ||
      g.competences.map((c) => c.id).includes(selectedCompetence),
    [selectedCompetence],
  );
  const filter = useCallback(
    (g: UserItem) => teamFilter(g) && competenceFilter(g),
    [teamFilter, competenceFilter],
  );

  const users = useMemo<ReadonlyArray<UserItem>>(
    () =>
      connectionToArray(data?.schedule?.users).map(
        ({ teams, competences, name, ...u }) => ({
          ...u,
          title: name,
          teams: connectionToArray(teams),
          competences: connectionToArray(competences),
        }),
      ),
    [data?.schedule?.users],
  );

  const groups = useMemo<UserItem[]>(
    () =>
      users.filter(filter).sort(
        (a, b) =>
          listCompare(
            a.teams.map((t) => t.name),
            b.teams.map((t) => t.name),
          ) || a.title.localeCompare(b.title),
      ),
    [users, filter],
  );
  return groups;
}

export const useRemapReferences = (
  items: PrevTimelineItem[],
  groups: UserItem[],
): PrevTimelineItem[] =>
  useMemo<PrevTimelineItem[]>(
    () =>
      items.map((i) => ({
        ...i,
        group: remapGroup(i.activity.user?.referenceId, groups),
      })),
    [items, groups],
  );

const remapGroup = (
  id: NonNullable<PrevTimelineItem["activity"]["user"]>["referenceId"],
  groups: UserItem[],
): UserItem["id"] => groups.find((g) => g.referenceId === id)?.id ?? id;

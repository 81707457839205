/**
 * @generated SignedSource<<5998397f20ade465c0414b4ae53bbd42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlteredShiftsChart_fragment$data = {
  readonly userShifts: ReadonlyArray<{
    readonly alteredShifts: ReadonlyArray<{
      readonly netAlteration: number;
    }>;
    readonly user: {
      readonly id: string;
    };
  }>;
  readonly userStats: ReadonlyArray<{
    readonly competences: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly id: string;
    readonly name: string;
    readonly nrShiftsAltered: number;
    readonly shiftsMinutesAltered: number;
    readonly shiftsMinutesAlteredAbsolute: number;
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
  }>;
  readonly " $fragmentType": "AlteredShiftsChart_fragment";
};
export type AlteredShiftsChart_fragment$key = {
  readonly " $data"?: AlteredShiftsChart_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlteredShiftsChart_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlteredShiftsChart_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSnapshotNode",
      "kind": "LinkedField",
      "name": "userStats",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nrShiftsAltered",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftsMinutesAltered",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftsMinutesAlteredAbsolute",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "competences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetenceSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompetenceSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserShiftStats",
      "kind": "LinkedField",
      "name": "userShifts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSnapshotNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AlteredShift",
          "kind": "LinkedField",
          "name": "alteredShifts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "netAlteration",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleStats",
  "abstractKey": null
};
})();

(node as any).hash = "757f70ecfe1c43ee4bcf00585b9f0340";

export default node;

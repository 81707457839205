import InfoIcon from "@mui/icons-material/InfoOutlined";
import MuiButton from "@mui/material/Button";

type Props = React.ComponentProps<typeof MuiButton>;

export const InfoButton = (props: Props) => (
  <MuiButton
    endIcon={<InfoIcon />}
    size="large"
    sx={{ color: "text.secondary", p: 0 }}
    {...props}
  />
);

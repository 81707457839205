import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { Box, Stack, Typography } from "@mui/material";
import type { BarLegendProps } from "@nivo/bar";
import { ResponsiveBar } from "@nivo/bar";
import graphql from "babel-plugin-relay/macro";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";
import { secondaryColors, ture } from "styles/mui/light-palette";
import { EMPTY_ARRAY } from "utils/constants";

import { shiftDayTypes, TranslateShiftDayType } from "components/shifts/types";

import GraphTooltip from "../common/GraphTooltip";
import { XOption, XSelect } from "../common/XSelect";

import type {
  ShiftDayTypeDistributionChart_fragment$data as Data,
  ShiftDayTypeDistributionChart_fragment$key as Key,
} from "./__generated__/ShiftDayTypeDistributionChart_fragment.graphql";
import { calcPercent } from "./utils";

const fragment = graphql`
  fragment ShiftDayTypeDistributionChart_fragment on ScheduleStats {
    userStats {
      id
      name
      competences {
        edges {
          node {
            id
          }
        }
      }
      teams {
        edges {
          node {
            id
          }
        }
      }
    }
    userShifts {
      user {
        id
      }
      shiftDayTypeVariants {
        variant
        shiftDayTypes {
          dayType
          count
          hours
        }
      }
    }
  }
`;

type UserShift = Unwrap<Data["userShifts"]>;
type ShiftDayTypeVariant = Unwrap<UserShift["shiftDayTypeVariants"]>;
type ShiftDayType = Unwrap<ShiftDayTypeVariant["shiftDayTypes"]>;
type UserType = Unwrap<Data["userStats"]>;
type Team = ExtractNode<UserType["teams"]>;
type Competence = ExtractNode<UserType["competences"]>;
type RowType = Omit<UserType, "competences" | "teams"> &
  Omit<UserShift, "user"> & {
    competences: ReadonlyArray<Competence>;
    teams: ReadonlyArray<Team>;
  };

type ChartRow = {
  userKey: string;
  userName: string;
} & { [key: string]: string | number };

type Props = {
  fragmentRef: Key;
  teamId?: string | null;
  competenceId?: string | null;
  filterCompetence: boolean;
};

type XAxisValue = "count" | "hours" | "percent" | "percentHours";
type XAxisOptionType = XOption<XAxisValue> & { unit: string };
const DEFAULT_X_AXIS_OPTION: XAxisOptionType = {
  value: "count",
  label: "Antal pass",
  unit: "pass",
};
const X_AXIS_OPTIONS: ReadonlyArray<XAxisOptionType> = [
  DEFAULT_X_AXIS_OPTION,
  { value: "hours", label: "Antal timmar", unit: "timmar" },
  { value: "percent", label: "Procent pass", unit: "procent" },
  { value: "percentHours", label: "Procent timmar", unit: "procent" },
];

const DEFAULT_VARIANT_OPTION = { value: "total", label: "Hela perioden" };
const VARIANT_OPTIONS: ReadonlyArray<XOption> = [
  DEFAULT_VARIANT_OPTION,
  { value: "weekend", label: "Helger" },
];

export function ShiftDayTypeDistributionChart({
  fragmentRef,
  teamId,
  competenceId,
  filterCompetence,
}: Props) {
  const [xAxis, setXAxis] = useState<XAxisOptionType>(DEFAULT_X_AXIS_OPTION);
  const [variant, setVariant] = useState<XOption>(DEFAULT_VARIANT_OPTION);
  const data = useFragment<Key>(fragment, fragmentRef);

  const keys = useMemo(() => Object.values(shiftDayTypes), []);

  const teamFilter = useCallback(
    (row: RowType) => !teamId || row.teams.some((t) => t.id === teamId),
    [teamId],
  );
  const competenceFilter = useCallback(
    (row: RowType) =>
      !filterCompetence || row.competences.some((c) => c.id === competenceId),
    [filterCompetence, competenceId],
  );
  const filter = useCallback(
    (row: RowType) => teamFilter(row) && competenceFilter(row),
    [teamFilter, competenceFilter],
  );

  const filteredData = useMemo(
    () =>
      (data.userStats ?? EMPTY_ARRAY)
        .map(({ teams, competences, ...u }) => {
          const { shiftDayTypeVariants } =
            data.userShifts.find((s) => s.user.id === u.id) || {};
          return {
            ...u,
            shiftDayTypeVariants: shiftDayTypeVariants ?? EMPTY_ARRAY,
            teams: connectionToArray(teams),
            competences: connectionToArray(competences),
          };
        })
        .filter(filter),
    [data, filter],
  );

  const chartData = useMemo<Record<XAxisValue, ReadonlyArray<ChartRow>>>(() => {
    const hours: ChartRow[] = [];
    const count: ChartRow[] = [];
    const percent: ChartRow[] = [];
    const percentHours: ChartRow[] = [];

    filteredData.forEach((row: RowType) => {
      const { id, name, shiftDayTypeVariants } = row;
      const userFields = {
        userKey: `${id} ${name}`,
        userName: name,
      };
      const dayTypes =
        shiftDayTypeVariants.find((x) => x.variant === variant.value)
          ?.shiftDayTypes ?? [];
      count.push({
        ...userFields,
        ...dayTypes.reduce(
          (acc: any, shiftDayType: ShiftDayType) => ({
            ...acc,
            [shiftDayType.dayType as string]: shiftDayType.count,
          }),
          {},
        ),
      });
      hours.push({
        ...userFields,
        ...dayTypes.reduce(
          (acc: any, shiftDayType: ShiftDayType) => ({
            ...acc,
            [shiftDayType.dayType as string]: shiftDayType.hours,
          }),
          {},
        ),
      });
      const countTotal = dayTypes.reduce(
        (acc: number, shiftDayType) => acc + shiftDayType.count,
        0,
      );
      percent.push({
        ...userFields,
        ...dayTypes.reduce(
          (acc: any, shiftDayType: ShiftDayType) => ({
            ...acc,
            [shiftDayType.dayType as string]: calcPercent(
              shiftDayType.count,
              countTotal,
            ),
          }),
          {},
        ),
      });
      const hoursTotal = dayTypes.reduce(
        (acc: number, shiftDayType) => acc + shiftDayType.hours,
        0,
      );
      percentHours.push({
        ...userFields,
        ...dayTypes.reduce(
          (acc: any, shiftDayType: ShiftDayType) => ({
            ...acc,
            [shiftDayType.dayType as string]: calcPercent(
              shiftDayType.hours,
              hoursTotal,
            ),
          }),
          {},
        ),
      });
    });
    return { hours, count, percent, percentHours };
  }, [filteredData, variant]);

  const chartMinHeight = 75 + chartData.count.length * 21;

  const legendsData = useMemo(
    () =>
      chartData[xAxis.value].length > 0
        ? [
            {
              id: shiftDayTypes.DAY,
              label: TranslateShiftDayType(shiftDayTypes.DAY),
              color: secondaryColors.klippa,
            },
            {
              id: shiftDayTypes.EVENING,
              label: TranslateShiftDayType(shiftDayTypes.EVENING),
              color: secondaryColors.manet,
            },
            {
              id: shiftDayTypes.NIGHT,
              label: TranslateShiftDayType(shiftDayTypes.NIGHT),
              color: ture[100],
            },
            {
              id: shiftDayTypes.FULL_DAY,
              label: TranslateShiftDayType(shiftDayTypes.FULL_DAY),
              color: secondaryColors.fisk,
            },
          ]
        : [],
    [chartData, xAxis],
  );
  const legendWidth =
    30 + Math.max(...legendsData.map((d) => d.label.length)) * 7;

  const colors = useMemo(() => legendsData.map((d) => d.color), [legendsData]);

  const legends = useMemo<BarLegendProps[]>(
    () => [
      {
        data: legendsData,
        dataFrom: "keys",
        anchor: "right",
        direction: "column",
        translateX: legendWidth + 20,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: legendWidth,
        itemHeight: 20,
        itemDirection: "left-to-right",
        itemOpacity: 0.85,
        symbolShape: "circle",
        effects: [
          {
            on: "hover",
            style: {
              itemOpacity: 1,
            },
          },
        ],
      },
    ],
    [legendWidth, legendsData],
  );
  const leftMargin =
    13 + Math.max(...chartData.hours.map((u: any) => u.userName.length)) * 5.6;

  const margin = useMemo(
    () => ({
      top: 25,
      right: legendWidth + 20,
      bottom: 50,
      left: leftMargin,
    }),
    [leftMargin, legendWidth],
  );
  return (
    <Stack px={1} alignItems="center">
      <Box height={chartMinHeight} width="100%">
        <ResponsiveBar
          data={chartData[xAxis.value]}
          keys={keys}
          colors={colors}
          indexBy="userName"
          groupMode="stacked"
          layout="horizontal"
          labelSkipWidth={12}
          labelTextColor="white"
          theme={{ text: { fontFamily: "Nunito" } }}
          legends={legends}
          margin={margin}
          borderRadius={3}
          innerPadding={1}
          tooltip={({ id, value, data }) => (
            <GraphTooltip
              keyId={id}
              translateKey={(d) =>
                legendsData.find((x) => x.id === d)?.label || d
              }
              value={value}
              totalValue={
                ((data.D as number) || 0) +
                ((data.E as number) || 0) +
                ((data.N as number) || 0) +
                ((data.F as number) || 0)
              }
              user={data?.userName as string}
              unit={xAxis.unit}
            />
          )}
        />
      </Box>
      <Box>
        <Stack gap={1} direction="row">
          <XSelect value={xAxis} onChange={setXAxis} options={X_AXIS_OPTIONS} />
          <Typography variant="caption" pt={0.6} pr={0.3}>
            som infaller under
          </Typography>
          <XSelect
            value={variant}
            onChange={setVariant}
            options={VARIANT_OPTIONS}
          />
        </Stack>
      </Box>
    </Stack>
  );
}

import { Stack, Typography } from "@mui/material";

import { FormikSmallSwitch } from "components/common/Switch";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function MaxApprovedWishes({ disabled, readOnly }: Props) {
  return (
    <Stack gap={1}>
      <Stack direction="row" gap={2}>
        <Typography variant="h4">Maximera beviljade fridagar</Typography>
        <FormikSmallSwitch
          name="maximizeApprovedWishes"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <Typography>
        Om denna inställning är påslagen så kommer algoritmen eftersträva att
        bevilja så många som möjligt av de önskade fridagarna.
      </Typography>
    </Stack>
  );
}

/**
 * @generated SignedSource<<250575da04901398ed9d4dbe72da9e8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandProfile_fragment$data = {
  readonly id: string;
  readonly uniqueWeeks: boolean;
  readonly useDemandWeights: boolean;
  readonly useDutyDemand: boolean;
  readonly " $fragmentType": "DemandProfile_fragment";
};
export type DemandProfile_fragment$key = {
  readonly " $data"?: DemandProfile_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandProfile_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandProfile_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uniqueWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useDutyDemand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useDemandWeights",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "80253b274093dc9808209106d61869d3";

export default node;

import { useRelayEnvironment } from "react-relay/hooks";
import { Button, Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import type { FormikHelpers } from "formik";
import { Form as FormikForm, Formik } from "formik";
import { refresh as refreshMe, useRequireMe } from "hooks/Me";
import { commitMutation } from "libs/commitMutation";
import * as yup from "yup";

import Field from "components/forms/CustomField";

import { EditMeMutation } from "./__generated__/EditMeMutation.graphql";

const mutation = graphql`
  mutation EditMeMutation($username: String!, $email: String!) {
    editMe(username: $username, email: $email) {
      ok
      error {
        usernameTaken
        emailTaken
        error
      }
    }
  }
`;

const validationSchema = yup.object({
  username: yup.string().required("Required"),
  email: yup.string().email("Invalid email address").required("Required"),
});

function Form({ isSubmitting }: { isSubmitting: boolean }) {
  return (
    <FormikForm>
      <Stack spacing={2}>
        <Field name="username" label="Användarnamn" size="small" />
        <Field name="email" label="E-postadress" size="small" />
        <Button
          type="submit"
          variant="primary"
          disabled={isSubmitting}
          sx={{ alignSelf: "flex-end" }}
        >
          Spara
        </Button>
      </Stack>
    </FormikForm>
  );
}

export default function EditMe() {
  const environment = useRelayEnvironment();
  const me = useRequireMe().userData;
  const initialValues = {
    username: me?.username || "",
    email: me?.email || "",
  };

  async function onSubmit(
    values: typeof initialValues,
    { setSubmitting, setErrors }: FormikHelpers<typeof initialValues>,
  ) {
    const variables = {
      username: values.username,
      email: values.email,
    };
    const { editMe } = await commitMutation<EditMeMutation>(environment, {
      mutation,
      variables,
    });
    if (editMe?.ok) {
      setSubmitting(false);
      refreshMe();
    } else {
      const errors = {} as typeof initialValues;
      if (editMe?.error?.usernameTaken) {
        setSubmitting(false);
        errors.username = "Användarnamnet är upptaget";
      }
      if (editMe?.error?.emailTaken) {
        setSubmitting(false);
        errors.email = "E-postadressen är upptagen";
      }
      setErrors(errors);
    }
  }

  return (
    <Paper variant="box" sx={{ p: 2 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => <Form isSubmitting={isSubmitting} />}
      </Formik>
    </Paper>
  );
}

/**
 * @generated SignedSource<<4ebd74678d78f2791fcda3fa7ea69c04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiMeetingSelectDaysByChoices = "FLEXIBLE" | "STATIC" | "%future added value";
export type ApiMeetingShiftPartPartTypeChoices = "B" | "J" | "M" | "Z" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditMeetingForm_meeting$data = {
  readonly dayMatrix: ReadonlyArray<ReadonlyArray<number>>;
  readonly end: string;
  readonly id: string;
  readonly name: string;
  readonly nrMeetings: number;
  readonly selectDaysBy: ApiMeetingSelectDaysByChoices;
  readonly shifts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly breakTime: number;
        readonly end: string;
        readonly id: string;
        readonly meetingDay: number;
        readonly meetings: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly end: string;
              readonly id: string;
              readonly start: string;
            } | null;
          } | null>;
        };
        readonly name: string;
        readonly shiftParts: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly end: string;
              readonly id: string;
              readonly partType: ApiMeetingShiftPartPartTypeChoices;
              readonly start: string;
            } | null;
          } | null>;
        };
        readonly start: string;
      } | null;
    } | null>;
  };
  readonly start: string;
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fullName: string;
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "EditMeetingForm_meeting";
};
export type EditMeetingForm_meeting$key = {
  readonly " $data"?: EditMeetingForm_meeting$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditMeetingForm_meeting">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditMeetingForm_meeting",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayMatrix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectDaysBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nrMeetings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNodeConnection",
      "kind": "LinkedField",
      "name": "users",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MeetingShiftNodeConnection",
      "kind": "LinkedField",
      "name": "shifts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MeetingShiftNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MeetingShiftNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "meetingDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "breakTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MeetingShiftPartNodeConnection",
                  "kind": "LinkedField",
                  "name": "shiftParts",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MeetingShiftPartNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MeetingShiftPartNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "partType",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MeetingNodeConnection",
                  "kind": "LinkedField",
                  "name": "meetings",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MeetingNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MeetingNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MeetingNode",
  "abstractKey": null
};
})();

(node as any).hash = "f48861bcd676aaceded030d4f654b4da";

export default node;

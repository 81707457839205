import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedValue, useFormikState } from "hooks";

import { useSnackbar } from "components/Snackbar";

import {
  useSuggestMeetingShifts,
  useValidateMeetingShifts,
} from "../mutations";
import type {
  MeetingShiftInput,
  SuggestMeetingShiftsInput,
  ValidateMeetingShiftsInput,
} from "../types";
import { sortStartFn } from "../utils";

import type { FormShift, FormValues } from "./types";
import { suggestedMeetingShiftToFormShift } from "./utils";

type FormShifts = ReadonlyArray<FormShift>;

type UseFormikStateReturn<V> = ReturnType<typeof useFormikState<V>>;
type FormValue<V> = UseFormikStateReturn<V>["value"];
type SetFormValue<V> = UseFormikStateReturn<V>["setValue"];
type UseFormType<Field> = [FormValue<Field>, SetFormValue<Field>, boolean];

const DEBOUNCE_MS = 1000;

function useFormValueTouched<T = any>(name: string): [T, boolean] {
  const {
    value,
    meta: { touched },
  } = useFormikState<T>(name);
  return useMemo(() => [value, touched], [value, touched]);
}

export const useFormShifts = (): UseFormType<FormShifts> => {
  const {
    value,
    setValue,
    meta: { touched },
  } = useFormikState<FormShifts>("shifts");
  return [value, setValue, touched];
};

export const useFormErrors = (): UseFormType<FormValues["errors"]> => {
  const {
    value,
    setValue,
    meta: { touched },
  } = useFormikState<FormValues["errors"]>("errors");
  return [value, setValue, touched];
};

export const useSuggestAndValidateShifts = () => {
  const isSuggesting = useUpdateSuggestedShifts();
  const isValidating = useValidateShifts();
  return [isSuggesting, isValidating];
};

/* Fetch fields used for suggestMeetingShifts,
 * along with their `touched` meta data. */
export const useFieldsThatAffectShifts = (onlyFields = false) => {
  const { value: id } = useFormikState<string>("id");
  const [name, nameTouched] = useFormValueTouched<FormValues["name"]>("name");
  const [start, startTouched] =
    useFormValueTouched<FormValues["start"]>("start");
  const [end, endTouched] = useFormValueTouched<FormValues["end"]>("end");
  const [dayMatrix, dayMatrixTouched] =
    useFormValueTouched<FormValues["dayMatrix"]>("dayMatrix");
  const [users, usersTouched] =
    useFormValueTouched<FormValues["users"]>("users");
  const { value: teamGroupId } =
    useFormikState<FormValues["teamGroupId"]>("teamGroupId");

  const values = useMemo(
    () => ({ id, name, start, end, dayMatrix, users, teamGroupId }),
    [id, name, start, end, dayMatrix, users, teamGroupId],
  );
  const touchedState = useMemo(
    () => ({
      nameTouched,
      startTouched,
      endTouched,
      dayMatrixTouched,
      usersTouched,
    }),
    [nameTouched, startTouched, endTouched, dayMatrixTouched, usersTouched],
  );

  return useMemo(
    () => ({
      ...values,
      ...(!onlyFields && touchedState),
    }),
    [values, onlyFields, touchedState],
  );
};

/** Used in MeetingForm.
 * Requests SuggestMeetingShifts and updates formik form's `shifts` field.
 *
 * @returns pending */
export function useUpdateSuggestedShifts() {
  const { addSnack } = useSnackbar();
  const rawValues = useFieldsThatAffectShifts();
  const [values, pending] = useDebouncedValue(rawValues, DEBOUNCE_MS);
  const [, setShifts] = useFormShifts();
  const [fetchSuggestedMeetingShifts, isInFlight] = useSuggestMeetingShifts();

  const updateSuggestedShifts = useCallback(
    async (input: SuggestMeetingShiftsInput) =>
      fetchSuggestedMeetingShifts({ variables: { input } })
        .then((res) => {
          const { shifts: shiftsInput = [] } = res?.suggestMeetingShifts ?? {};

          setShifts(
            shiftsInput.map(suggestedMeetingShiftToFormShift).sort(sortStartFn),
          );
        })
        .catch((e) => {
          console.error(e);
          addSnack({
            severity: "error",
            message: "Kunde inte hämta passförslag",
          });
        }),
    [fetchSuggestedMeetingShifts, setShifts, addSnack],
  );

  useEffect(() => {
    if (!anyFieldIsTouched(values)) {
      return;
    }
    if (!allFieldsAreNonEmpty(values)) {
      setShifts([]);
      return;
    }

    const {
      id = null,
      name,
      start,
      end,
      dayMatrix,
      users,
      teamGroupId,
    } = values;
    updateSuggestedShifts({
      id,
      teamGroupId,
      name,
      start,
      end,
      dayMatrix,
      users,
    });
  }, [values, updateSuggestedShifts, setShifts]);

  return pending || isInFlight;
}

/** Requests ValidateMeetingShifts and updates formik form's `errors` field.
 *
 * @returns pending */
export function useValidateShifts() {
  const [commit, inFlight] = useValidateMeetingShifts();
  const [shifts, , shiftsTouched] = useFormShifts();
  const [, setErrors] = useFormErrors();
  const { addSnack } = useSnackbar();
  const rawValues = useFieldsThatAffectShifts();
  const [values, pending] = useDebouncedValue(rawValues, DEBOUNCE_MS);

  const updateErrors = useCallback(
    async (input: ValidateMeetingShiftsInput) => {
      setErrors([]);
      await commit({ variables: { input } })
        .then((res) => {
          const { errors } = res?.validateMeetingShifts ?? {};

          setErrors(errors || []);
        })
        .catch((e) => {
          console.error(e);
          addSnack({
            severity: "error",
            message: "Kunde inte validera passförslag",
          });
        });
    },
    [commit, addSnack, setErrors],
  );

  useEffect(() => {
    if (!anyFieldIsTouched(values)) {
      return;
    }
    if (shifts.length < 1) {
      return;
    }

    const { id = null, start, end, dayMatrix, users, teamGroupId } = values;
    const meetingShifts = shifts.map(toMeetingShift);

    updateErrors({
      id,
      start,
      end,
      dayMatrix,
      users,
      teamGroupId,
      shifts: meetingShifts,
    });
  }, [shiftsTouched, shifts, updateErrors]); // eslint-disable-line react-hooks/exhaustive-deps

  return pending || inFlight;
}

function toMeetingShift({ meetings, ...shift }: FormShift): MeetingShiftInput {
  return { ...shift, otherMeetings: meetings.map((m) => m.id) };
}

const anyFieldIsTouched = ({
  nameTouched,
  startTouched,
  endTouched,
  dayMatrixTouched,
  usersTouched,
}: ReturnType<typeof useFieldsThatAffectShifts>) =>
  Boolean(
    nameTouched ||
      startTouched ||
      endTouched ||
      dayMatrixTouched ||
      usersTouched,
  );

const allFieldsAreNonEmpty = ({
  name,
  start,
  end,
  users,
  dayMatrix,
}: ReturnType<typeof useFieldsThatAffectShifts>) =>
  Boolean(
    name &&
      start &&
      end &&
      users.length > 0 &&
      dayMatrix.some((row) => row.length > 0),
  );

export function useElementHeightChange(id: string) {
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    const observer = new ResizeObserver((es) => {
      if (es.length < 1) {
        return;
      }
      const [e] = es;
      setHeight(e.contentRect.height);
    });
    const el = document.getElementById(id);
    if (!el) return;

    observer.observe(el);
  }, [id]);

  return height;
}

export enum SolutionStatus {
  ERROR = "ERROR",
  FEASIBLE = "FEASIBLE",
  HARD_CONSTRAINT_COLLISION = "HARD_CONSTRAINT_COLLISION",
  INFEASIBLE = "INFEASIBLE",
  RUNNING = "RUNNING",
  RUNNING_WITH_INFEASIBILITY_VENTS = "RUNNING_WITH_INFEASIBILITY_VENTS",
  QUEUED = "QUEUED",
}

export enum TerminationCondition {
  FEASIBLE = "FEASIBLE",
  HARD_CONSTRAINT_COLLISION = "HARD_CONSTRAINT_COLLISION",
  INFEASIBLE = "INFEASIBLE",
  MAX_STALL_TIME = "MAX_STALL_TIME",
  MAX_TIME_LIMIT = "MAX_TIME_LIMIT",
  OPTIMAL = "OPTIMAL",
  OTHER = "OTHER",
  UNBOUNDED = "UNBOUNDED",
  UNKNOWN = "UNKNOWN",
}

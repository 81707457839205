import { Code } from "react-content-loader";
import { Paper, Stack } from "@mui/material";

import { FooterRender } from "components/shifts/form/Footer";

const noop = () => {};

export function ShiftsPlaceholder() {
  return (
    <Stack gap={4}>
      <Paper variant="box" sx={{ height: 300, p: 2 }}>
        <Code style={{ height: 240 }} />
      </Paper>
      <FooterRender
        onAdd={noop}
        onReset={noop}
        onSubmit={noop}
        addDisabled
        resetDisabled
        submitDisabled
      />
    </Stack>
  );
}

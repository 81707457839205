import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import { InfoButtonAndModal, InfoModalProvider } from "components/layout";

import { CommonHeader } from "./Common";

const Content = () => (
  <Stack gap={4}>
    <CommonHeader />

    <Stack gap={1}>
      <Typography variant="h3">
        Ett möte har en titel, start- och sluttid, deltagare, dagar och
        mötespass
      </Typography>
      <Typography>
        När du skapar ett nytt möte behöver du bestämma dig för en titel på
        mötet, dess start- och sluttid, vilka personer som ska gå på mötet och
        vilka dagar under perioden som mötet ska inträffa.
      </Typography>
      <Typography>
        För varje möte du skapar, så kommer det också skapas en uppsättning
        mötespass. Mötespassen är de särskilda passtyper som medarbetare får lov
        att gå på i samband med mötet.
      </Typography>
    </Stack>

    <Stack gap={1}>
      <Typography variant="h3">
        Mötespass (åter)genereras automatiskt - men kan redigeras
      </Typography>
      <Typography>
        Mötespassen genereras automatiskt utifrån de passtyper som du har skapat
        i <Link to={"/shifts"}>Passprofilen</Link>. Notera! Om du ändrar mötets
        titel, start- eller sluttid, deltagare eller dagar så kommer mötespassen
        återgenereras och dina eventuella manuella ändringar förkastas.
      </Typography>
      <Typography>
        Det är viktigt att mötespassen inte skapar olösliga konflikter i schemat
        (exempelvis överlappande möten), varför skapande och redigering av
        mötespass behöver gå igenom en rad kontroller. Om du ändrar mötet på
        något sätt, så behöver mötepassen gå igenom dessa kontroller på nytt. Du
        kan spara mötet först när alla eventuella konflikter är lösta.
      </Typography>
      <Typography>
        Notera att ändringar i mötespassen inte kommer påverka de underliggande
        passtyperna i <Link to={"/shifts"}>Passprofilen</Link>.
      </Typography>
    </Stack>
  </Stack>
);

export function FormInfo() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal buttonLabel="Hjälp" title="Skapa och redigera möten">
        <Content />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

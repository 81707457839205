import { forwardRef } from "react";
import { ReactComponent } from "assets/svg/plus.svg";

import CustomIcon from "./CustomIcon";

function Plus(p: any, ref: any) {
  return <CustomIcon ref={ref} {...p} component={ReactComponent} />;
}

export default forwardRef(Plus);

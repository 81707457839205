import type { FC, ReactNode } from "react";
import type { StackProps } from "@mui/material";
import { FormGroup, Paper, Stack, Typography } from "@mui/material";

import { FormLabel } from "./form/FormLabel";
import { useElementHeightChange } from "./form/hooks";
import { RefetchShiftsButton } from "./form/RefetchShiftsButton";
import { SectionTitle } from "./form/SectionTitle";

type FormFieldsProps = {
  titleField: ReactNode;
  startField: ReactNode;
  endField: ReactNode;
  dateGridSection: ReactNode;
  userField: ReactNode;
  userList: ReactNode;
  shiftsSection: ReactNode;
};

export const MeetingsScaffold: FC<StackProps> = (p) => <Stack gap={3} {...p} />;
export const MeetingListScaffold: FC<StackProps> = (p) => (
  <Stack gap={1} {...p} />
);
export const MeetingGraphScaffold: FC<StackProps> = ({ children, ...p }) => (
  <Stack gap={1} {...p}>
    <Typography fontSize={18} fontWeight={600}>
      Mötespass
    </Typography>
    {children}
  </Stack>
);
export const MeetingFormScaffold: FC<StackProps> = (p) => (
  <Stack gap={3} {...p} />
);
export const FormHeaderScaffold: FC<{
  headerInfo: ReactNode;
  formInfo: ReactNode;
  actions: ReactNode;
}> = ({ headerInfo, formInfo, actions }) => (
  <Stack direction="row" flexBasis="100%" justifyContent="space-between">
    {headerInfo}
    <Stack direction="row" gap={2} alignItems="center">
      {formInfo}
      {actions}
    </Stack>
  </Stack>
);
export function FormFieldsScaffold({
  titleField,
  startField,
  endField,
  dateGridSection,
  userField,
  userList,
  shiftsSection,
}: FormFieldsProps) {
  const LEFT_DIV_ID = "left-side";
  const rightHeight = useElementHeightChange(LEFT_DIV_ID);

  return (
    <Stack gap={4}>
      <Stack
        direction={{ lg: "row", xs: "column" }}
        justifyContent="space-between"
        gap={3}
      >
        <Stack id={LEFT_DIV_ID} gap={3} flexBasis="66%" height="fit-content">
          <Stack gap={1}>
            <SectionTitle>Information</SectionTitle>
            <FormGroup>
              <FormLabel sx={{ color: "text.primary", fontWeight: 700 }}>
                Mötestitel
              </FormLabel>
              {titleField}
            </FormGroup>
            <Stack direction="row" gap={3} flexBasis="100%">
              <FormGroup sx={{ flexBasis: "50%" }}>
                <FormLabel>Starttid</FormLabel>
                {startField}
              </FormGroup>
              <FormGroup sx={{ flexBasis: "50%" }}>
                <FormLabel>Sluttid</FormLabel>
                {endField}
              </FormGroup>
            </Stack>
          </Stack>
          <Stack gap={1}>
            <SectionTitle>Dagar</SectionTitle>
            {dateGridSection}
          </Stack>
        </Stack>
        <Stack
          gap={1}
          flexBasis="33%"
          height={`clamp(200px, ${rightHeight})`}
          sx={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <SectionTitle>Deltagare</SectionTitle>
          <FormGroup>
            <FormLabel>Lägg till deltagare</FormLabel>
            <Paper variant="box" sx={{ p: 2 }}>
              {userField}
              {userList}
            </Paper>
          </FormGroup>
        </Stack>
      </Stack>
      {shiftsSection}
    </Stack>
  );
}

export const ShiftSectionScaffold: FC<{
  refetch?: () => Promise<void>;
  inFlight?: boolean;
  shiftsField: ReactNode;
}> = ({ refetch = () => Promise.resolve(), inFlight, shiftsField }) => (
  <Stack gap={2}>
    <Stack direction="row" gap={2}>
      <SectionTitle>Passtyper</SectionTitle>
      <div>
        <RefetchShiftsButton onClick={refetch} disabled={inFlight} />
      </div>
    </Stack>
    {shiftsField}
  </Stack>
);

export const DateGridScaffold: FC<{
  selectDaysBy?: ReactNode;
  matrix: ReactNode;
}> = ({ selectDaysBy, matrix }) => (
  <Stack component={Paper} variant="box" p={2}>
    {selectDaysBy}
    {matrix}
  </Stack>
);

export const HeaderActionsScaffold: FC<
  StackProps & { cancelButton: ReactNode; submitButton: ReactNode }
> = ({ cancelButton, submitButton, ...p }) => (
  <Stack>
    <Stack direction="row" gap={1} flexWrap="wrap" {...p}>
      {cancelButton}
      {submitButton}
    </Stack>
  </Stack>
);

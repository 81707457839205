import { InputAdornment } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import StyledInputField from "components/forms/material/StyledInputField";

type EmploymentDegreeInputProps = {
  name: string;
  label?: string;
};

export default function EmploymentDegreeInput({
  name,
  label,
}: EmploymentDegreeInputProps) {
  const { value, setValue } = useFormikState(name);
  const handleChange = (e: React.ChangeEvent<{ value: string }>) => {
    const res = parseFloat(e.target.value);
    setValue(isNaN(res) ? e.target.value : res);
  };
  return (
    <StyledInputField
      label={label}
      fullWidth
      size="small"
      type="number"
      endAdornment={<InputAdornment position="end">%</InputAdornment>}
      inputProps={{ step: 0.01 }}
      name="employmentDegree"
      value={value}
      onChange={handleChange}
    />
  );
}

import useFormikState from "hooks/useFormikState";

import { SettingBoxHeaderRadios } from "components/common/RadioButtons";

type Props = Omit<
  React.ComponentProps<typeof SettingBoxHeaderRadios>,
  "nameSoftRadio" | "valueSoftRadio" | "handleSoftRadio"
> & {
  name: string;
};

export default function HardSoftRadios({ name, ...props }: Props) {
  const { value, setValue } = useFormikState<boolean>(name);
  function onChange() {
    setValue(!value);
  }
  return (
    <SettingBoxHeaderRadios
      nameSoftRadio={name}
      valueSoftRadio={value}
      handleSoftRadio={onChange}
      {...props}
    />
  );
}

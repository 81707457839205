import useFormikState from "hooks/useFormikState";

import { ShiftDayTypeSelect } from "components/common/selectors/ShiftDayTypeSelect";
import type { ShiftDayType } from "components/shifts/types";

type Props = Omit<
  React.ComponentProps<typeof ShiftDayTypeSelect>,
  "value" | "onChange"
> & {
  name: string;
};

export function DayTypeSelect({ name, ...props }: Props) {
  const { value, setValue } = useFormikState<ShiftDayType>(name);
  function onChange(value: ShiftDayType) {
    setValue(value);
  }
  return <ShiftDayTypeSelect {...props} value={value} onChange={onChange} />;
}

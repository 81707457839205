import { useMemo } from "react";
import type { SelectChangeEvent } from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material";
import { minutes2HHMM } from "helpers/dateFunctions";
import { useFormikState } from "hooks";

type DayBreakAlternative = {
  id: number;
  label: string;
};

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

const dayBreakAlternatives: DayBreakAlternative[] = Array.from(
  Array(48).keys(),
).map((i) => ({ id: i, label: `${minutes2HHMM((i / 2) * 60)}` }));

export function DayBreakAlternativeSelect({ name, disabled, readOnly }: Props) {
  const { value, setValue } = useFormikState<number[]>(name);
  const fieldValue = useMemo(() => value[0], [value]);

  const handleChange = (event: SelectChangeEvent<number>) => {
    const { value } = event.target;
    setValue([value as number]);
  };
  const renderValue = (selected: number) => {
    return dayBreakAlternatives
      .filter(({ id }: DayBreakAlternative) => selected === id)
      .map((x) => x.label);
  };

  return (
    <FormControl fullWidth>
      <Select
        size="small"
        name="dayBreakAlternatives"
        labelId="day-break-alternative"
        value={fieldValue}
        renderValue={renderValue}
        onChange={handleChange}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250,
            },
          },
        }}
        inputProps={{ readOnly }}
      >
        {dayBreakAlternatives.map((d: DayBreakAlternative) => (
          <MenuItem key={d.id} value={d.id}>
            {d.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

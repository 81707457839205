import { TeamDemandSettings } from "./BaseDemand/TeamDemandSettings";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function BaseDemandForm({ disabled, readOnly }: Props) {
  return (
    <TeamDemandSettings
      name="teamDemandSettings"
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

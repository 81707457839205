import ExpandIcon from "@mui/icons-material/ExpandMoreSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderRadius: 0,
  borderWidth: 0,
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(() => ({
  alignItems: "center",
  margin: 0,
  paddingTop: 0,
  paddingBottom: "4px",
  minHeight: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));

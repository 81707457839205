import axios from "axios";
import { API_BASE_URL } from "utils/constants";

import { refreshTokenIfNeeded } from ".././environment";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // If you need to include credentials in every request
  // Other default configurations can be added here
});

axiosInstance.interceptors.request.use(
  async (config) => {
    await refreshTokenIfNeeded();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;

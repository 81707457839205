import { useCallback, useEffect, useState } from "react";
import { MoreVert as MenuIcon } from "@mui/icons-material";
import { IconButton, Menu } from "@mui/material";

export function RowMenu({
  disabled,
  children,
}: React.PropsWithChildren<{ disabled?: boolean }>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget),
    [setAnchorEl],
  );
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  // close on unmount
  useEffect(() => () => onClose(), [onClose]);

  return (
    <>
      <IconButton onClick={onOpen} disabled={disabled}>
        <MenuIcon />
      </IconButton>
      <Menu open={!!anchorEl} onClose={onClose} anchorEl={anchorEl}>
        {children}
      </Menu>
    </>
  );
}

import { Stack } from "@mui/material";
import { useFormikState } from "hooks";

import { ShiftType } from "components/setting/types";

import { timebankModes } from "../types";

import { TimebankAllowanceField } from "./TimebankAllowanceField";
import { TimebankDisallowedShiftChangesField } from "./TimebankDisallowedShiftChangesField";
import { TimebankModeField } from "./TimebankModeField";
import { TimebankOffsetImbalanceField } from "./TimebankOffsetImbalanceField";

type Props = {
  shiftTypes: ReadonlyArray<ShiftType>;
  readOnly?: boolean;
};

export function TimebankForm({ shiftTypes, readOnly }: Props) {
  const timebankMode =
    useFormikState<string>("timebankMode")?.value.toUpperCase();

  return (
    <Stack gap={3}>
      <TimebankModeField readOnly={readOnly} />
      {timebankMode === timebankModes.TIMEBANK && (
        <Stack gap={4}>
          <TimebankAllowanceField readOnly={readOnly} />
          <TimebankOffsetImbalanceField readOnly={readOnly} />
        </Stack>
      )}
      {timebankMode === timebankModes.SPARETIME && (
        <TimebankDisallowedShiftChangesField
          shiftTypes={shiftTypes}
          readOnly={readOnly}
        />
      )}
    </Stack>
  );
}

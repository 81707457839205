import { useMemo } from "react";
import { useFormikState } from "hooks";

export function useIsConstraintModuleEnabled(constraintModule: string) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  return useMemo(
    () => constraintModules.includes(constraintModule),
    [constraintModules, constraintModule],
  );
}

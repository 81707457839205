import { Suspense, useCallback, useMemo, useState } from "react";
import { PreloadedQuery, useFragment } from "react-relay/hooks";
import { Box, Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { useResultsParams } from "hooks";
import { DateTime } from "luxon";
import { ResultsScheduleWarningsContextQuery } from "pages/types";
import { useBooleanState } from "utils/useBooleanState";

import { date2string } from "components/common/GraphAxisFormat";
import {
  CreateActivityForm,
  EditActivityForm,
} from "components/schedule/activities";
import { ScheduleProfileChart } from "components/schedule/graphs";

import { ActivityGanttTimeline } from "../graphs/ActivityGanttTimeline/ActivityGanttTimeline";
import { ActivityGanttTimelineSeparateService } from "../graphs/ActivityGanttTimeline/ActivityGanttTimelineSeparateService";

import { Header } from "./Header";
import type { ActivityCharts_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment ActivityCharts_fragment on ScheduleNode {
    id
    period {
      startDate
      endDate
    }
    ...Header_fragment
  }
`;

type ActivityChartsProps = {
  fragmentRef: Key;
  toggleDrawer: () => void;
  refresh: () => Promise<void>;
  scheduleWarningsQueryRef:
    | PreloadedQuery<ResultsScheduleWarningsContextQuery>
    | null
    | undefined;
};

const allTeams = {
  id: "",
  name: "Alla avdelningar",
  color: "",
  group: { id: "", name: "" },
};
const allCompetences = { id: "", name: "Alla kompetenser", color: "" };

export function ActivityCharts({
  fragmentRef,
  toggleDrawer,
  refresh,
  scheduleWarningsQueryRef,
}: ActivityChartsProps) {
  const [refreshData, setRefreshData] = useState(false);
  const schedule = useFragment<Key>(fragment, fragmentRef);
  const { startDate, endDate } = schedule.period || {};
  const periodStart = useMemo(
    () => DateTime.fromISO(startDate).toJSDate(),
    [startDate],
  );
  const periodEnd = useMemo(
    () => DateTime.fromISO(endDate).toJSDate(),
    [endDate],
  );
  const [editingId, setEditingId] = useState<string>();
  const {
    value: showCreate,
    setFalse: closeCreateDialog,
    setTrue: openCreateDialog,
  } = useBooleanState();

  const {
    colorFocus,
    setColorFocus,
    team,
    setTeam,
    competence,
    setCompetence,
    activities: selectedActivities,
    setActivities,
    from,
    to,
  } = useResultsParams({
    periodStart,
    periodEnd,
  });

  const onCloseEdit = useCallback(() => setEditingId(undefined), []);
  const triggerRefresh = useCallback(() => {
    setRefreshData((prev) => !prev);
    refresh();
  }, [setRefreshData, refresh]);

  const { flags } = useFeatureFlags();

  return (
    <Stack gap={2}>
      <Header
        fragmentRef={schedule}
        team={team}
        setTeam={(id: string | null) => setTeam(id || allTeams.id)}
        competence={competence}
        setCompetence={(id: string | null) =>
          setCompetence(id || allCompetences.id)
        }
        allTeams={allTeams}
        allCompetences={allCompetences}
        activities={selectedActivities}
        colorFocus={colorFocus}
        setColorFocus={setColorFocus}
        setActivities={setActivities}
        openCreateDialog={openCreateDialog}
        toggleDrawer={toggleDrawer}
        scheduleWarningsQueryRef={scheduleWarningsQueryRef}
      />
      <Paper variant="box" sx={{ p: 2, mb: 2 }}>
        <Suspense fallback="">
          {!!schedule?.id && (
            <ScheduleProfileChart
              scheduleId={schedule.id}
              minDate={date2string(from.getTime())}
              maxDate={date2string(to.getTime())}
              selectedTeam={team === allTeams.id ? null : team}
              selectedCompetence={
                competence === allCompetences.id ? null : competence
              }
              colorFocus={colorFocus}
            />
          )}
        </Suspense>
        <Box sx={{ mr: "2px" }}>
          <Suspense fallback={null}>
            {!!schedule?.id && (
              <>
                {flags.useScheduleService ? (
                  <ActivityGanttTimelineSeparateService
                    scheduleId={schedule.id}
                    onItemSelect={setEditingId}
                    minDate={date2string(from.getTime())}
                    maxDate={date2string(to.getTime())}
                    selectedTeam={team === allTeams.id ? null : team}
                    selectedActivities={selectedActivities}
                    selectedCompetence={
                      competence === allCompetences.id ? null : competence
                    }
                    colorFocus={colorFocus}
                    refreshData={refreshData}
                  />
                ) : (
                  <ActivityGanttTimeline
                    scheduleId={schedule.id}
                    onItemSelect={setEditingId}
                    minDate={date2string(from.getTime())}
                    maxDate={date2string(to.getTime())}
                    selectedTeam={team === allTeams.id ? null : team}
                    selectedActivities={selectedActivities}
                    selectedCompetence={
                      competence === allCompetences.id ? null : competence
                    }
                    colorFocus={colorFocus}
                  />
                )}
              </>
            )}
          </Suspense>
        </Box>
      </Paper>
      <Suspense fallback={null}>
        {editingId && (
          <EditActivityForm
            onClose={onCloseEdit}
            afterSubmit={triggerRefresh}
            activityId={editingId}
          />
        )}
      </Suspense>
      <Suspense fallback={null}>
        {showCreate && (
          <CreateActivityForm
            open
            scheduleId={schedule.id}
            onClose={closeCreateDialog}
            afterSubmit={triggerRefresh}
          />
        )}
      </Suspense>
    </Stack>
  );
}

import React, { useCallback } from "react";
import { Button } from "@mui/material";

import { useModifyRuleGroupSettingModulesMutation } from "./mutations/modifyRuleGroupSettingModules";
import { useModifyUserSettingModulesMutation } from "./mutations/modifyUserSettingModules";

type Props = {
  onClick: () => void;
};

const BaseSettingExceptionsRemoveButton = React.memo(
  function BaseSettingExceptionsRemoveButtonFn({ onClick }: Props) {
    return (
      <Button
        variant="outlined"
        onClick={onClick}
        color="error"
        sx={{ borderColor: "red", color: "red" }}
      >
        Ta bort undantag
      </Button>
    );
  },
);

type SettingIdAndList = {
  id: string;
  settingModules: ReadonlyArray<string>;
};

type UserSettingExceptionsRemoveButtonProps = {
  settingModule: string;
  userSetting?: SettingIdAndList;
};

export const UserSettingExceptionsRemoveButton = React.memo(
  function UserSettingExceptionsRemoveButtonFn({
    settingModule,
    userSetting,
  }: UserSettingExceptionsRemoveButtonProps) {
    const [modifyUserSettingModules] = useModifyUserSettingModulesMutation();
    const onRemoveUserException = useCallback(() => {
      if (userSetting == null) {
        return;
      }

      const newConstraintModules = userSetting.settingModules.filter(
        (moduleName) => moduleName !== settingModule,
      );
      modifyUserSettingModules({
        variables: {
          input: {
            id: userSetting?.id,
            constraintModules: newConstraintModules,
          },
        },
      });
    }, [settingModule, modifyUserSettingModules, userSetting]);

    if (userSetting == null) {
      console.error(
        "User exception does not have corresponding setting modules loaded",
      );
      return null;
    }

    return (
      <BaseSettingExceptionsRemoveButton onClick={onRemoveUserException} />
    );
  },
);

type RuleGroupSettingExceptionsRemoveButtonProps = {
  settingModule: string;
  ruleGroupSetting?: SettingIdAndList;
};
export const RuleGroupSettingExceptionsRemoveButton = React.memo(
  function RuleGroupSettingExceptionsRemoveButtonFn({
    settingModule,
    ruleGroupSetting,
  }: RuleGroupSettingExceptionsRemoveButtonProps) {
    const [modifyRuleGroupSettingModules] =
      useModifyRuleGroupSettingModulesMutation();
    const onRemoveRuleGroupException = useCallback(() => {
      if (ruleGroupSetting == null) {
        return;
      }

      const newConstraintModules = ruleGroupSetting.settingModules.filter(
        (moduleName) => moduleName !== settingModule,
      );
      modifyRuleGroupSettingModules({
        variables: {
          input: {
            id: ruleGroupSetting.id,
            constraintModules: newConstraintModules,
          },
        },
      });
    }, [settingModule, modifyRuleGroupSettingModules, ruleGroupSetting]);

    if (ruleGroupSetting == null) {
      console.error(
        "Rule group exception does not have corresponding setting modules loaded",
      );
      return null;
    }

    return (
      <BaseSettingExceptionsRemoveButton onClick={onRemoveRuleGroupException} />
    );
  },
);

import { useCallback, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormGroup,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import useFormikState from "hooks/useFormikState";
import { commitMutation } from "libs/commitMutation";
import { DateTime } from "luxon";
// import type { RecordSourceSelectorProxy } from "relay-runtime";
import * as yup from "yup";

import { DialogTitle } from "components/common/Dialog";
import DatePickerWithWeekNumbers from "components/schedule/DatePickerWithWeekNumbers";

import environment from "../../../environment";

import { CreatePeriodMutation } from "./__generated__/CreatePeriodMutation.graphql";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultStartDate?: string;
  periodLengthWeeks?: number;
};

type FormProps = {
  periodLengthWeeks: number;
  onClose: () => void;
};

type FormValues = {
  groupId: string;
  startDate: string;
};

//type UpdaterStore = RecordSourceSelectorProxy<CreatePeriodMutation$data>;

const mutation = graphql`
  mutation CreatePeriodMutation($groupId: ID!, $startDate: Date!) {
    createPeriod(input: { groupId: $groupId, startDate: $startDate }) {
      period {
        id
        name
        startDate
        endDate
        numberOfWeeks
        masterSchedule {
          id
        }
        schedules {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

/*
 * TODO: periods is not found. Using refresh manually until this is resolved.
function updater(store: UpdaterStore) {
  const newPeriod = store
    .getRootField("createPeriod")
    .getLinkedRecord("period");
  console.log({ newPeriod }, store);
  if (!newPeriod) return;

  const root = store.getRoot();
  console.log(root);
  const periods = root.getLinkedRecords("periods", {groupId: newPeriod.getValue("groupId")});
  console.log({ periods });
  if (!periods) return;

  const newRecordsList = [...(periods || []), newPeriod];
  console.log("new", newRecordsList);
  root.setLinkedRecords(newRecordsList, "periods");
}
*/

const validationSchema = yup.object({
  groupId: yup.string(),
  startDate: yup.date().required("Ange startdatum"),
});

function Form({ periodLengthWeeks, onClose }: FormProps) {
  const { isSubmitting, setFieldValue } = useFormikContext<FormValues>();
  const { value: start } = useFormikState("startDate");
  const startDate = useMemo(
    () => DateTime.fromJSDate(start ? new Date(start) : new Date()),
    [start],
  );
  const dtformatOnlyDate = "yyyy-MM-dd";

  const changeStart = useCallback(
    (newValue: DateTime | null) => {
      if (!newValue) {
        return;
      }
      setFieldValue("startDate", newValue.toFormat(dtformatOnlyDate));
    },
    [setFieldValue],
  );
  return (
    <>
      <DialogTitle onClose={onClose}>
        <Typography variant="h2">Skapa ny schemaperiod</Typography>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <Stack gap={2}>
          <FormGroup>
            <FormLabel>Startdatum</FormLabel>
            <DatePickerWithWeekNumbers
              label="Gå till"
              value={startDate}
              onChange={changeStart}
              views={["year", "month", "day"]}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Schemaperiodens längd</FormLabel>
            <Typography>{periodLengthWeeks} veckor</Typography>
          </FormGroup>
        </Stack>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Stack direction="row" gap={2}>
          <Button variant="cancel" onClick={onClose}>
            Avbryt
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Skapa schemaperiod
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}

export default function CreatePeriod({
  open,
  onClose,
  defaultStartDate,
  periodLengthWeeks = 1,
}: Props) {
  const teamGroup = useCurrentTeamGroup();
  const initialValues: FormValues = {
    groupId: teamGroup?.id || "",
    startDate: defaultStartDate || "",
  };

  async function onSubmit(input: FormValues) {
    await commitMutation<CreatePeriodMutation>(environment, {
      mutation,
      variables: input,
      // updater,
    })
      .then(onClose)
      .catch((error) => console.error(error));
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      fullWidth
      maxWidth="xs"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <FormikForm autoComplete="off">
          <Form periodLengthWeeks={periodLengthWeeks} onClose={onClose} />
        </FormikForm>
      </Formik>
    </Dialog>
  );
}

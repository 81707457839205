export function linearGradient(
  colors: string[],
  degree: number = 45,
  repeat: boolean = false,
) {
  if (colors.length === 1) {
    return colors[0];
  }
  /* Example: ["red", "blue"]
   * repeating-linear-gradient(45deg, red 0px, red 4px, blue 4px, blue 8px) !important
   */
  if (repeat) {
    const colorStringRepeat = colors.reduce(
      (acc, color, i) =>
        i === colors.length - 1
          ? acc + `${color} 5px, ${color} 5px`
          : acc + `${color}, ${color} 5px, `,
      "",
    );
    return `repeating-linear-gradient(${degree}deg, ${colorStringRepeat} 10px)`;
  } else {
    const percentage = Math.round(100 / colors.length);
    const colorString = colors.reduce(
      (acc, color, i) =>
        i === 0
          ? acc + `${color} ${(i + 1) * percentage}%, `
          : i === colors.length - 1
            ? acc + `${color} ${i * percentage}%`
            : acc + `${color} ${i * percentage}% ${(i + 1) * percentage}%, `,
      "",
    );
    return `linear-gradient(${degree}deg, ${colorString})`;
  }
}

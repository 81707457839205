import { useCallback } from "react";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

import { useSnackbar } from "components/Snackbar";

import { useSuggestMeetingShifts } from "../mutations";
import type { SuggestMeetingShiftsInput as Input } from "../types";
import { sortStartFn } from "../utils";

import { useFieldsThatAffectShifts, useFormShifts } from "./hooks";
import { suggestedMeetingShiftToFormShift } from "./utils";

type PromiseFunction<T = void> = () => Promise<T>;
type Props = {
  onClick: PromiseFunction;
  disabled?: boolean;
};

export function RefetchShiftsButton({ onClick, disabled }: Props) {
  return (
    <Button
      variant="outlined"
      startIcon={<RefreshIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      Återskapa med aktuell passprofil (och släng manuella ändringar)
    </Button>
  );
}

export function useRefetchShiftsButton(): [PromiseFunction, boolean] {
  const [suggestMeetingShifts, inFlight] = useSuggestMeetingShifts();
  const values = useFieldsThatAffectShifts(true);
  const [, setShifts] = useFormShifts();
  const { addSnack } = useSnackbar();

  const refetch = useCallback(async () => {
    const input: Input = {
      ...values,
      id: values?.id ?? null,
      refreshRelatedMeetingShifts: true,
    };

    await suggestMeetingShifts({ variables: { input } })
      .then((res) => {
        const { shifts: shiftsIn = [] } = res?.suggestMeetingShifts ?? {};
        setShifts(
          shiftsIn.map(suggestedMeetingShiftToFormShift).sort(sortStartFn),
        );
      })
      .catch((e) => {
        console.error(e);
        addSnack({
          severity: "error",
          message: "Kunde inte återskapa passförslag",
        });
      });
  }, [suggestMeetingShifts, values, setShifts, addSnack]);

  return [refetch, inFlight];
}

import { useMemo } from "react";
import { RoomPreferencesOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useCurrentTeamGroup } from "hooks";
import { ConstraintModuleSwitch_fragment$key as ConstraintModuleSwitchKey } from "settings/common/__generated__/ConstraintModuleSwitch_fragment.graphql";
import { CONSTRAINT_MODULES } from "settings/common/constants";
import { ConstraintModuleSwitch } from "settings/common/ConstraintModuleSwitch";

type Props = {
  settingName?: string;
  name?: string;
  Icon: React.ElementType;
  rightElement?: React.ReactNode;
};

function BaseSettingBoxHeader({
  name,
  Icon,
  rightElement,
  settingName,
}: Props) {
  return (
    <Box
      display="flex"
      paddingBottom={1}
      sx={{ "&:only-child": { pb: 0 }, pb: 1, alignItems: "center" }}
    >
      <Icon />
      <Typography variant="h3" sx={{ ml: 2, flexGrow: 1 }}>
        {settingName}
        {name && (
          <>
            {" för "}
            <b>{name}</b>
          </>
        )}
      </Typography>
      {rightElement}
    </Box>
  );
}

type TeamGroupSettingBoxHeaderProps = {
  settingModule: string;
  fragmentRef: ConstraintModuleSwitchKey;
  settingName?: string;
};

export function TeamGroupSettingBoxHeader({
  settingModule,
  fragmentRef,
  settingName,
}: TeamGroupSettingBoxHeaderProps) {
  const teamGroup = useCurrentTeamGroup();
  const displayToggle = CONSTRAINT_MODULES.has(settingModule);

  const rightElement = useMemo(() => {
    if (!displayToggle) {
      return null;
    }
    return (
      <ConstraintModuleSwitch
        constraintModule={settingModule}
        fragmentRef={fragmentRef}
      />
    );
  }, [displayToggle, settingModule, fragmentRef]);

  return (
    <BaseSettingBoxHeader
      name={teamGroup.name}
      Icon={RoomPreferencesOutlined}
      rightElement={rightElement}
      settingName={settingName}
    />
  );
}

import { InputAdornment, Stack, Typography } from "@mui/material";

import CustomField from "components/forms/CustomField";

import { HardSoftRadios } from "../components/HardSoftRadios";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function Rhythm2({ disabled, readOnly }: Props) {
  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Typography variant="h4">
          Maximum antal lediga kalenderdagar i följd
        </Typography>
        <Typography>
          Detta fält styr hur många sammanhängande kalenderdagar i följd en
          fridagarsperiod <i>som mest</i> får lov att vara.
        </Typography>
        <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
          <CustomField
            name="maxConsecutiveFreeDays"
            size="small"
            type="number"
            disabled={disabled}
            sx={{ minWidth: 200 }}
            InputProps={{
              readOnly,
              endAdornment: (
                <InputAdornment position="end">dagar</InputAdornment>
              ),
            }}
          />
          <HardSoftRadios
            name="maxConsecutiveFreeDaysSoft"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography variant="h4">
          Minimum antal lediga kalenderdagar i följd
        </Typography>
        <Typography>
          Detta fält styr hur många sammanhängande kalenderdagar i följd en
          fridagarsperiod <i>som minst</i> får lov att vara.
        </Typography>
        <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
          <CustomField
            size="small"
            type="number"
            name="minConsecutiveFreeDays"
            disabled={disabled}
            sx={{ minWidth: 200 }}
            InputProps={{
              readOnly,
              endAdornment: (
                <InputAdornment position="end">dagar</InputAdornment>
              ),
            }}
          />
          <HardSoftRadios
            name="minConsecutiveFreeDaysSoft"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

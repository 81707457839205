import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import {
  CheckCircle as CheckCirleIcon,
  DoDisturb as DoDisturbIcon,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { DateTime } from "luxon";
import type { MRT_ColumnDef } from "material-react-table";

import ColorDot from "components/common/ColorDot";
import Table from "components/common/MRTable";

import {
  ActivateButton,
  ArchiveButton,
  DeleteButton,
  EditButton,
} from "../common/AdminTableIconButtons";

import type { Competence, CompetencesTable_fragment$key as Key } from "./types";

const TABLE_CONFIG_KEY = "admin-competences-table-config";

const fragment = graphql`
  fragment CompetencesTable_fragment on CompetenceNode @relay(plural: true) {
    id
    name
    description
    color
    isActive
    archivedAt
  }
`;

type RowType = NonNullable<Competence>;
type ColumnType = MRT_ColumnDef<RowType>;

type Props = {
  fragmentRef: Key;
  onEdit: (id: string) => void;
  onDelete: (competence: Competence) => void;
  onActivate: (id: string) => void;
  onArchive: (id: string) => void;
};

type ActionsProps = {
  isActive: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onActivate: () => void;
  onArchive: () => void;
};

function Actions({
  isActive,
  onEdit,
  onDelete,
  onActivate,
  onArchive,
}: ActionsProps) {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <EditButton onClick={() => onEdit()} />
      {isActive ? (
        <ArchiveButton onClick={() => onArchive()} />
      ) : (
        <ActivateButton onClick={() => onActivate()} />
      )}
      <DeleteButton onClick={() => onDelete()} />
    </Stack>
  );
}

export default function CompetencesTable({
  fragmentRef,
  onEdit,
  onDelete,
  onActivate,
  onArchive,
}: Props) {
  const items = useFragment<Key>(fragment, fragmentRef);
  const columns = useMemo<ColumnType[]>(
    () => [
      { accessorKey: "id", header: "ID" },
      {
        accessorKey: "isActive",
        header: "Aktiv",
        Cell: ({ cell }) =>
          cell.getValue<boolean>() ? (
            <CheckCirleIcon color="primary" sx={{ p: "2px" }} />
          ) : (
            <DoDisturbIcon color="disabled" sx={{ p: "2px" }} />
          ),
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} aktiva`,
        Footer: ({ table }) => {
          const activeCount =
            table
              .getFilteredRowModel()
              .flatRows.filter((r) => r.original.isActive).length || 0;
          return <Stack>{activeCount} aktiva</Stack>;
        },
      },
      {
        accessorKey: "archivedAt",
        header: "Tid för arkivering",
        accessorFn: (c) =>
          c.archivedAt ? DateTime.fromISO(c.archivedAt).toFormat("F") : null,
        Cell: ({ cell }) => cell.getValue<string>() || "-",
      },
      {
        accessorKey: "name",
        header: "Namn",
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} kompetenser`,
        Footer: ({ table }) => {
          const itemCount = table.getFilteredRowModel().flatRows.length || 0;
          return <Stack>{itemCount} kompetenser</Stack>;
        },
      },
      {
        accessorKey: "color",
        header: "Färg",
        Cell: ({ cell }) => <ColorDot color={cell.getValue<string>()} />,
      },
      { accessorKey: "description", header: "Beskrivning" },
      {
        header: "Åtgärder",
        align: "right",
        Cell: ({ row }) => {
          const c = row.original;
          return (
            <Actions
              isActive={c?.isActive}
              onEdit={() => c?.id && onEdit(c.id)}
              onDelete={() => onDelete(c)}
              onActivate={() => c?.id && onActivate(c.id)}
              onArchive={() => c?.id && onArchive(c.id)}
            />
          );
        },
      },
    ],
    [onEdit, onDelete, onActivate, onArchive],
  );
  return (
    <Table
      tableConfigKey={TABLE_CONFIG_KEY}
      columns={columns as any[]}
      data={items as any[]}
    />
  );
}

import { Stack, Typography as Text } from "@mui/material";

import { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";

import RulesAndDatesAccordion from "./RulesAndDatesAccordion";

type Props = {
  value: RecurrenceRuleType[];
  onChange: (value: RecurrenceRuleType[]) => void;
  openIdx: number | null;
  onOpen: (index: number) => void;
  durationMs: number;
};

export default function RecurrenceRules({
  value,
  onChange,
  openIdx,
  onOpen,
  durationMs,
}: Props) {
  function handleChange(index: number, newEl: RecurrenceRuleType) {
    const newValue = [...value];
    newValue[index] = newEl;
    onChange(newValue);
  }

  function onDelete(index: number) {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  }

  function ruleSummary(index: number) {
    return <Text>Upprepningsregel {index + 1}</Text>;
  }

  if (!value?.length) {
    return null;
  }

  return (
    <Stack gap={1}>
      {value.map((item, index) => (
        <RulesAndDatesAccordion
          key={index}
          expanded={openIdx === index}
          onExpand={() => onOpen(index)}
          onDelete={() => onDelete(index)}
          summary={ruleSummary(index)}
          item={item}
          onChange={(v) => handleChange(index, v)}
          durationMs={durationMs}
        />
      ))}
    </Stack>
  );
}

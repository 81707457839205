import { Stack, Typography } from "@mui/material";

import { InfoButtonAndModal, InfoModalProvider } from "components/layout";

import type { GroupSettingCardProps } from "./GroupSettingCard";
import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<GroupSettingCardProps, "title" | "action">;

const TITLE = "Schemaperioder";

const Info = () => (
  <InfoModalProvider>
    <InfoButtonAndModal
      title="Hur fungerar Schemaperioder?"
      buttonLabel="Hur fungerar Schemaperioder?"
    >
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography variant="h4">Schemaperiodens längd</Typography>
          <Typography>
            Detta fält styr hur lång en schemaperiod ska vara. Ibland kanske du
            vill ha en längre schemaperiod, exempelvis i samband med
            sommarmånaderna. Då justerar du alltså periodens längd här!
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">Rullande schemaperioder</Typography>
          <Typography>
            Om schemat är rullande innebär det att man skulle kunna använda
            samma schema 2 eller fler perioder i rad utan att bryta mot någon
            regel.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">
            Schemaregler i skarven mellan perioder
          </Typography>
          <Typography>
            Om du optimerar om schemat inför en ny schemaperiod så vill du
            såklart att viktiga regler ska tillämpas i skarven mellan den gamla
            och den nya perioden. Här kommer det nya schemat förhålla sig till
            det fastställda schemat i föregående period. Om det inte finns något
            fastställt schema i föregående period så tillämpas inga
            skarv-regler.
          </Typography>
        </Stack>
      </Stack>
    </InfoButtonAndModal>
  </InfoModalProvider>
);

export function SchedulePeriodCard(props: Props) {
  return <GroupSettingCard title={TITLE} action={<Info />} {...props} />;
}

SchedulePeriodCard.id = "shifts" as const;
SchedulePeriodCard.title = TITLE;

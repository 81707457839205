import { useCallback, useEffect, useRef, useState } from "react";

type Timeout = ReturnType<typeof setTimeout>;

/** Debounce `value` `delay` milliseconds.
 * Returns a tuple: [debouncedValue: T, valueUpdateIsPending: boolean, triggerNow: Dispatch<SetStateAction<T>>] */
export function useDebouncedValue<T = any>(
  value: T,
  delay: number,
): [T, boolean, (v: T) => void] {
  const [debouncedValue, setDebouncedValue] = useState<T>(() => value);
  const fn = useCallback(() => setDebouncedValue(value), [value]);
  const [pending, , clear] = useDebouncedFunction(fn, delay);
  const bypass = useCallback(
    (v: T) => {
      setDebouncedValue(v);
      clear();
    },
    [setDebouncedValue, clear],
  );

  return [debouncedValue, pending, bypass];
}

/** Call `fn` with a debounce of `delay` milliseconds. */
export function useDebouncedFunction(
  fn: VoidFunction | (() => Promise<void>),
  delay: number,
): [boolean, VoidFunction, VoidFunction] {
  const [fetchIsPending, setFetchIsPending] = useState(false);
  const timeout = useRef<Timeout>();

  const clear = useCallback(() => {
    timeout.current && clearTimeout(timeout.current);
    timeout.current = undefined;
  }, []);

  const triggerNow = useCallback(async () => {
    clear();
    const ret = fn();
    if (ret instanceof Promise) {
      await ret;
    }
    setFetchIsPending(false);
  }, [fn, clear]);

  const run = useCallback(() => {
    setFetchIsPending(true);
    timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(triggerNow, delay);
  }, [triggerNow, delay]);

  useEffect(() => {
    run();
    return clear;
  }, [run, clear, delay]);

  return [fetchIsPending, triggerNow, clear];
}

import { CircularProgress as Loading, Container } from "@mui/material";

export default function FullPageLoading() {
  return (
    <Container
      maxWidth="lg"
      fixed
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <Loading />
    </Container>
  );
}

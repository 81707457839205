import { Suspense, useCallback, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  useChangeTitle,
  useCurrentTeamGroup,
  useRedirectOnGroupChange,
} from "hooks";

import { UserSelectFragmentRefProvider } from "components/common/selectors/UserSelect/UserSelectFragmentRefContext";
import { PageWrapper } from "components/layout";
import { CreateMeetingPlaceholder as Placeholder } from "components/loading";
import { CreateMeetingForm } from "components/meetings";
import { ParticipantListProvider } from "components/meetings/form/ParticipantList";

import type { CreateMeetingQuery as Query } from "./types";

type QueryRef = PreloadedQuery<Query>;
type ContentProps = {
  queryRef: QueryRef;
};

const query = graphql`
  query CreateMeetingQuery($teamGroupId: ID!) {
    usersOnTeamGroup(teamGroupId: $teamGroupId) {
      ...UserSelect_fragment
      ...ParticipantList_users
    }
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      ...CreateMeetingForm_setting
    }
  }
`;

export function CreateMeeting() {
  const queryRef = useCreateMeeting();
  useChangeTitle("Nytt möte");

  return (
    <Container>
      <PageWrapper>
        <Suspense fallback={<Placeholder />}>
          {!!queryRef && <Content queryRef={queryRef} />}
        </Suspense>
      </PageWrapper>
    </Container>
  );
}

function Content({ queryRef }: ContentProps) {
  const { data, teamGroupId, onClose } = useContent(queryRef);

  if (!data.settingForTeamGroup) {
    return <p>Laddar...</p>;
  }

  return (
    <UserSelectFragmentRefProvider value={data.usersOnTeamGroup}>
      <ParticipantListProvider fragmentRef={data.usersOnTeamGroup}>
        <CreateMeetingForm
          teamGroupId={teamGroupId}
          settingFragmentRef={data.settingForTeamGroup}
          onClose={onClose}
        />
      </ParticipantListProvider>
    </UserSelectFragmentRefProvider>
  );
}

function useContent(queryRef: QueryRef) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const nav = useNavigate();
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id ?? "";
  const goToList = useCallback(() => nav("/meetings"), [nav]);

  return {
    data,
    teamGroupId,
    onClose: goToList,
  };
}

function useCreateMeeting() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = useMemo(() => teamGroup?.id ?? "", [teamGroup]);

  useRedirectOnGroupChange("..");

  useEffect(() => {
    if (!teamGroupId) return;
    loadQuery({ teamGroupId }, { fetchPolicy: "network-only" });
  }, [teamGroupId, loadQuery]);

  return queryRef;
}

import { useCurrentTeamGroup } from "hooks";

import { UserSelect } from "components/common/selectors/UserSelect";

export function SelectParticipantsField() {
  const teamGroup = useCurrentTeamGroup();

  return (
    <UserSelect
      name="users"
      teamGroup={teamGroup}
      multi
      disableCloseOnSelect
      showSelectAll
    />
  );
}

/**
 * @generated SignedSource<<5db6eff38f10db5f7361ab47d7ed0a1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlockedTimeSlotShiftSelect_fragment$data = ReadonlyArray<{
  readonly end: string;
  readonly id: string;
  readonly name: string;
  readonly start: string;
  readonly " $fragmentType": "BlockedTimeSlotShiftSelect_fragment";
}>;
export type BlockedTimeSlotShiftSelect_fragment$key = ReadonlyArray<{
  readonly " $data"?: BlockedTimeSlotShiftSelect_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockedTimeSlotShiftSelect_fragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BlockedTimeSlotShiftSelect_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "end",
      "storageKey": null
    }
  ],
  "type": "ShiftNode",
  "abstractKey": null
};

(node as any).hash = "6d522cf1417931ad3eb2738c3f2cedee";

export default node;

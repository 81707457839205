/**
 * @generated SignedSource<<dfa8d0671b95a4b051c1769d5b61b31d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApiActivityActivityTypeChoices = "A" | "C" | "D" | "F" | "J" | "M" | "O" | "P" | "Q" | "R" | "S" | "V" | "W" | "Y" | "Z" | "%future added value";
export type ApiActivityPartPartTypeChoices = "B" | "J" | "M" | "P" | "Z" | "%future added value";
export type CreateActivityInput = {
  activityParts?: ReadonlyArray<ActivityPartInput | null> | null;
  activityType: ApiActivityActivityTypeChoices;
  breakTime: number;
  clientMutationId?: string | null;
  end: string;
  scheduleId: string;
  start: string;
  teamId?: string | null;
  userId: string;
};
export type ActivityPartInput = {
  end: string;
  id?: string | null;
  partType: ApiActivityPartPartTypeChoices;
  start: string;
};
export type CreateActivityMutation$variables = {
  input: CreateActivityInput;
};
export type CreateActivityMutation$data = {
  readonly createActivity: {
    readonly clonedSchedule: {
      readonly id: string;
    } | null;
    readonly ok: boolean | null;
  } | null;
};
export type CreateActivityMutation = {
  response: CreateActivityMutation$data;
  variables: CreateActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateActivityPayload",
    "kind": "LinkedField",
    "name": "createActivity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "clonedSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateActivityMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateActivityMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e21f8dc5dd71550afc311b0dc3af51b8",
    "id": null,
    "metadata": {},
    "name": "CreateActivityMutation",
    "operationKind": "mutation",
    "text": "mutation CreateActivityMutation(\n  $input: CreateActivityInput!\n) {\n  createActivity(input: $input) {\n    ok\n    clonedSchedule {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c750324c3f151545229f9e08b9c7601";

export default node;

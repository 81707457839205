import React from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";

const BarChartPlaceholder = (props: IContentLoaderProps) => {
  return (
    <ContentLoader width={145} height={150} viewBox="0 0 145 150" {...props}>
      <rect x="0" y="90" rx="0" ry="0" width="25" height="40" />
      <rect x="30" y="75" rx="0" ry="0" width="25" height="55" />
      <rect x="60" y="56" rx="0" ry="0" width="25" height="74" />
      <rect x="90" y="10" rx="0" ry="0" width="25" height="120" />
      <rect x="120" y="72" rx="0" ry="0" width="25" height="58" />
    </ContentLoader>
  );
};

export default BarChartPlaceholder;

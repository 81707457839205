import { Stack, Typography } from "@mui/material";
import { ErrorMessage } from "formik";
import { useFormikState } from "hooks";

import { FormikDayChips } from "components/common/DayCard";

import type { DayMatrix } from "./types";

type WeekRowProps = {
  label: string;
  name: string;
};

const NAME = "dayMatrix";

export function DayMatrixField() {
  const { value: dayMatrix } = useFormikState<DayMatrix>(NAME);

  return (
    <Stack>
      <Stack gap={1}>
        {dayMatrix.map((_, i: number) => (
          <WeekRow
            key={`row-${i}`}
            label={`Vecka ${String(i + 1)}`}
            name={`${NAME}.${i}`}
          />
        ))}
      </Stack>
      <Typography color="error.main" fontSize={12}>
        <ErrorMessage name={NAME} />
      </Typography>
    </Stack>
  );
}

function WeekRow({ label, name }: WeekRowProps) {
  return (
    <Stack direction="row" gap={1} alignItems="center" flexBasis="100%">
      <Typography fontWeight={300} fontSize={12} minWidth={50}>
        {label}
      </Typography>
      <FormikDayChips name={name} sx={{ width: "100%" }} />
    </Stack>
  );
}

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import PaperWrapper from "settings/common/PaperWrapper";
import { WeightField } from "settings/common/TeamGroupOnlyForms";
import { requestedDaysOffSetting } from "settings/RequestedDaysOff/RequestedDaysOffSetting";

import { MaxApprovedWishes } from "components/setting/common/forms/Advanced/WishedDaysOffField/MaxApprovedWishes";
import { OptimizeApprovedWishesDistributionBetweenEmployees } from "components/setting/common/forms/Advanced/WishedDaysOffField/OptimizeApprovedWishesDistributionBetweenEmployees";

type Props = { readOnly?: boolean };

export function WishedDaysOffForm({ readOnly }: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(
    requestedDaysOffSetting.moduleName,
  );

  return (
    <PaperWrapper>
      <Stack gap={3}>
        <MaxApprovedWishes readOnly={readOnly} disabled={disabled} />
        <OptimizeApprovedWishesDistributionBetweenEmployees
          readOnly={readOnly}
          disabled={disabled}
        />
        <WeightField
          moduleName={requestedDaysOffSetting.moduleName}
          weightFieldName="wishedFreeDaysWeight"
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

import { Stack, Typography } from "@mui/material";

type Props = {
  title: React.ReactNode;
  infoButton?: React.ReactNode;
};

export function HeaderTitle({ title, infoButton }: Props) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h3">{title}</Typography>
      {infoButton}
    </Stack>
  );
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import { EditBlockedTimeSlotMutation as Mutation } from "./__generated__/EditBlockedTimeSlotMutation.graphql";

const mutation = graphql`
  mutation EditBlockedTimeSlotMutation($input: EditBlockedTimeSlotInput!) {
    editBlockedTimeSlot(input: $input) {
      ok
      blockedTimeSlot {
        ...EditBlockedTimeSlot_fragment
      }
    }
  }
`;

export const useEditBlockedTimeSlot = () => useMutation<Mutation>(mutation);

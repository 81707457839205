import CustomSelectField from "components/forms/CustomSelectField";

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export default function ErrorMarginSelect({ name, disabled, readOnly }: Props) {
  const options = [
    { value: -1, label: "Ingen tolerans" },
    { value: 1, label: "±1 pass" },
    { value: 2, label: "±2 pass" },
    { value: 3, label: "±3 pass" },
    { value: 4, label: "±4 pass" },
    { value: 5, label: "±5 pass" },
    { value: 0, label: "Efter bästa förmåga" },
  ];
  return (
    <CustomSelectField
      name={name}
      disabled={disabled}
      readOnly={readOnly}
      options={options}
      size="small"
      sx={{ width: 200 }}
    />
  );
}

import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { useFormikState } from "hooks";

import GenericSelect from "components/common/selectors/GenericSelect";

import { BlockedTimeSlotShiftSelect_fragment$key as Key } from "./__generated__/BlockedTimeSlotShiftSelect_fragment.graphql";
import { ShiftType } from "./types";

const fragment = graphql`
  fragment BlockedTimeSlotShiftSelect_fragment on ShiftNode
  @relay(plural: true) {
    id
    name
    start
    end
  }
`;

type Props = {
  fragmentRef: Key;
};

const formatDt = (dt: string) => dt.substring(0, 5);

export function BlockedTimeSlotShiftSelect({ fragmentRef }: Props) {
  const { setValue, meta } = useFormikState<string>("shiftId");

  const shiftTypes = useFragment<Key>(fragment, fragmentRef);

  const shiftById = useMemo(() => {
    const shiftById: Record<string, ShiftType> = {};
    shiftTypes.forEach((shift) => (shiftById[shift.id] = shift));
    return shiftById;
  }, [shiftTypes]);

  const options = useMemo(() => Object.keys(shiftById), [shiftById]);

  const getLabel = useCallback(
    (shiftId: string) => {
      const shift = shiftById[shiftId];
      return `${shift.name} | ${formatDt(shift.start)}-${formatDt(shift.end)}`;
    },
    [shiftById],
  );

  const onChange = useCallback(
    (_e: React.SyntheticEvent, v: string) => setValue(v),
    [setValue],
  );

  return (
    <GenericSelect
      size="small"
      defaultValue={meta.initialValue}
      onChange={onChange}
      options={options}
      getOptionLabel={getLabel}
      label="Pass"
    />
  );
}

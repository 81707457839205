import { useCallback } from "react";
import { useFormikState } from "hooks";

export function useIndicesFormikField(name: string) {
  const {
    value: checked,
    setValue,
    meta: { error },
    helpers: { setTouched },
  } = useFormikState<number[]>(name);

  const setChecked = useCallback(
    (newValue: number[]) => {
      setValue(newValue);
      setTouched(true);
    },
    [setValue, setTouched],
  );

  return { checked, setChecked, error };
}

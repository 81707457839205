import { useMemo } from "react";
import { useFragment } from "react-relay";
import { KeyType } from "react-relay/relay-hooks/helpers";
import { OptimizationSetting } from "settings/common/optimizationSetting";

import { RuleGroupSettingExceptionsRemoveButton } from "components/setting/common/SettingExceptionsRemoveButton";

import { SettingExceptionBox } from "./SettingExceptionBox";

type Props<
  TeamGroupKey extends KeyType,
  UserKey extends KeyType,
  RuleGroupKey extends KeyType,
  SettingFormValues,
  AdditionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues,
> = {
  setting: OptimizationSetting<
    TeamGroupKey,
    UserKey,
    RuleGroupKey,
    SettingFormValues,
    AdditionalData,
    TeamGroupSettingFormValues,
    UserSettingFormValues
  >;
  ruleGroupFragmentRef: UserKey;
  teamGroupFragment: TeamGroupKey;
  settingModules: ReadonlyArray<string>;
  settingsId: string;
};

export function RuleGroupSettingExceptionBox<
  TeamGroupKey extends KeyType,
  UserKey extends KeyType,
  RuleGroupKey extends KeyType,
  SettingFormValues,
  AdditionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues = undefined,
>({
  setting,
  ruleGroupFragmentRef,
  teamGroupFragment,
  settingModules,
  settingsId,
}: Props<
  TeamGroupKey,
  UserKey,
  RuleGroupKey,
  SettingFormValues,
  AdditionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues
>) {
  const data = useFragment(
    setting.ruleGroups.ruleGroupFragment,
    ruleGroupFragmentRef,
  );
  const additionalData =
    setting.useAdditionalDataForTeamGroup(teamGroupFragment);

  const { onSubmitRuleGroup } = setting.useSubmitFunctions();
  const ruleGroupSetting = useMemo(
    () => ({ id: settingsId, settingModules }),
    [settingsId, settingModules],
  );

  return (
    <SettingExceptionBox
      title={setting.name}
      removeExceptionButton={
        <RuleGroupSettingExceptionsRemoveButton
          settingModule={setting.moduleName}
          ruleGroupSetting={ruleGroupSetting}
        />
      }
    >
      {setting.renderComponent(
        setting.ruleGroups.convertDataToInitialValues(data),
        onSubmitRuleGroup,
        additionalData,
      )}
    </SettingExceptionBox>
  );
}

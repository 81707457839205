/**
 * @generated SignedSource<<c91464b8547313350817b34716e0ff06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeetingForm_setting$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ShiftDayTypeContext_fragment">;
  readonly " $fragmentType": "MeetingForm_setting";
};
export type MeetingForm_setting$key = {
  readonly " $data"?: MeetingForm_setting$data;
  readonly " $fragmentSpreads": FragmentRefs<"MeetingForm_setting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MeetingForm_setting",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShiftDayTypeContext_fragment"
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "82fa22c329e9d7c4bf3fe23c12b0ac00";

export default node;

import { SettingBox } from "components/setting/common";
import { NightShiftField } from "components/setting/common/forms/Advanced";

type Props = { readOnly?: boolean };

export function NightShiftsOldForm({ readOnly }: Props) {
  return (
    <SettingBox>
      <NightShiftField readOnly={readOnly} />
    </SettingBox>
  );
}

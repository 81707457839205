import { SettingBox } from "components/setting/common";
import { RestBetweenShiftsField } from "components/setting/common/forms/Advanced";

type Props = { groupName: string; readOnly?: boolean };

export function RestBetweenShiftsForm({ groupName, readOnly }: Props) {
  return (
    <SettingBox>
      <RestBetweenShiftsField groupName={groupName} readOnly={readOnly} />
    </SettingBox>
  );
}

/**
 * @generated SignedSource<<3ab6abbcee43cb7787ea41a86e653316>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetenceSelect_fragment$data = ReadonlyArray<{
  readonly color: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "CompetenceSelect_fragment";
}>;
export type CompetenceSelect_fragment$key = ReadonlyArray<{
  readonly " $data"?: CompetenceSelect_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetenceSelect_fragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CompetenceSelect_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    }
  ],
  "type": "CompetenceNode",
  "abstractKey": null
};

(node as any).hash = "faa7e03880a42638cf0f07cc412fab3e";

export default node;

import { useField } from "formik";

import { CompetenceSelect } from "./CompetenceSelect";
import type { FormikProps } from "./types";

export function FormikCompetenceSelect({ name, ...props }: FormikProps) {
  const [, meta, helpers] = useField<string | string[] | null>(name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <CompetenceSelect
      {...props}
      value={value}
      onChange={(v: typeof value) => {
        setValue(v);
      }}
    />
  );
}

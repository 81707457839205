import useFormikState from "hooks/useFormikState";

import { RadioButtonHardSoft } from "components/common/RadioButtons";

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export function HardSoftRadios({ name, disabled, readOnly }: Props) {
  const { value, setValue } = useFormikState(name);

  return (
    <RadioButtonHardSoft
      value={value}
      handleChange={() => setValue(!value)}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

import type { PropsWithChildren, ReactNode } from "react";
import type { CardProps } from "@mui/material";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

export type GroupSettingCardProps = CardProps &
  PropsWithChildren<{
    title?: string;
    action?: ReactNode;
  }>;

export function GroupSettingCard({
  title,
  action,
  children,
  ...props
}: GroupSettingCardProps) {
  return (
    <Card {...props}>
      <CardHeader
        title={title}
        action={action}
        titleTypographyProps={{ variant: "h3" }}
        sx={{ mr: 1, mt: 0.5 }}
      />
      <Divider sx={{ mx: 2 }} />
      <CardContent>{children}</CardContent>
    </Card>
  );
}

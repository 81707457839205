import { Component } from "react";

type Props = {
  onRetry: () => void;
  fallback?:
    | React.ReactNode
    | ((props: { error: Error; retry: () => void }) => React.ReactNode);
};

type State = {
  error: Error | null;
};

export class ErrorBoundaryWithRetry extends Component<
  React.PropsWithChildren<Props>,
  State
> {
  /* This component is used when useQueryLoader/loadQuery in react-relay/hooks is used.
   * If you use useLazyLoadQuery, you should use ErrorBoundaryWithRetryLazyLoad
   */
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  _retry = () => {
    this.props.onRetry();
    this.setState({ error: null });
  };

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      if (typeof fallback == "function") {
        return fallback({ error, retry: this._retry });
      }
      return fallback;
    }
    return children;
  }
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { EditRuleGroupMutation as Mutation } from "./types";

const mutation = graphql`
  mutation EditRuleGroupMutation($input: EditRuleGroupInput!) {
    editRuleGroup(input: $input) {
      ruleGroup {
        ...EditRuleGroupForm_fragment
      }
    }
  }
`;

export const useEditRuleGroup = () => useMutation<Mutation>(mutation);

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentTeamGroup } from "./CurrentTeamGroup";

/** Redirect user to `redirectTo` when team group id changes */
export function useRedirectOnGroupChange(redirectTo: string) {
  const group = useCurrentTeamGroup();
  const nav = useNavigate();
  const [id, setId] = useState<string>(group.id);
  useEffect(() => {
    if (id !== group.id) {
      setId(group.id);
      nav(redirectTo);
    }
  }, [redirectTo, nav, group.id, id]);
}

import { createContext, useContext } from "react";

import type { CompetenceSelect_fragment$key as Key } from "./types";

export const CompetenceSelectFragmentRefContext = createContext<Key | null>(
  null,
);
export const CompetenceSelectFragmentRefProvider =
  CompetenceSelectFragmentRefContext.Provider;
export const useCompetenceSelectFragmentRef = () => {
  const ref = useContext(CompetenceSelectFragmentRefContext);
  if (!ref) {
    throw new Error(
      "useCompetenceSelectFragmentRef requires a CompetenceSelectFragmentRefProvider",
    );
  }
  return ref;
};

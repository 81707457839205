import type { SelectChangeEvent } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import type { ShiftDayType } from "components/shifts/types";
import {
  shiftDayTypeChoices,
  TranslateShiftDayType,
} from "components/shifts/types";

type Option = {
  value: ShiftDayType;
  label: string;
};

const options: Option[] = shiftDayTypeChoices.map((dayType) => ({
  value: dayType,
  label: TranslateShiftDayType(dayType),
}));

type OmitProps = "value" | "onChange" | "defaultValue";

type Props = Omit<React.ComponentProps<typeof Select>, OmitProps> & {
  value: ShiftDayType;
  onChange: (value: ShiftDayType) => void;
};

export function ShiftDayTypeSelect({ value, onChange, ...props }: Props) {
  const { label } = props;

  function handleChange(e: SelectChangeEvent<ShiftDayType>) {
    onChange(e.target.value as ShiftDayType);
  }

  function renderOption(option: Option) {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  const labelId = `${props.name}-label`;

  return (
    <FormControl sx={{ width: 110 }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        size="small"
        {...props}
        labelId={labelId}
        value={value}
        onChange={handleChange}
        fullWidth
      >
        {options.map(renderOption)}
      </Select>
    </FormControl>
  );
}

/**
 * @generated SignedSource<<b552e9def64dc1f467de83994a11db60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingDayTypeCadenceWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamGroupCadenceRestrictionDayTypeChoices = "D" | "E" | "F" | "N" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DayNightCadenceSettingTeamGroup_fragment$data = {
  readonly cadenceRestrictions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly calendarDays: number;
        readonly dayType: ApiTeamGroupCadenceRestrictionDayTypeChoices;
        readonly id: string;
        readonly isSoft: boolean;
        readonly maxCadence: number;
      } | null;
    } | null>;
  };
  readonly constraintModules: ReadonlyArray<string>;
  readonly dayTypeCadenceWeight: ApiSettingDayTypeCadenceWeightChoices;
  readonly id: string;
  readonly " $fragmentType": "DayNightCadenceSettingTeamGroup_fragment";
};
export type DayNightCadenceSettingTeamGroup_fragment$key = {
  readonly " $data"?: DayNightCadenceSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DayNightCadenceSettingTeamGroup_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DayNightCadenceSettingTeamGroup_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamGroupCadenceRestrictionNodeConnection",
      "kind": "LinkedField",
      "name": "cadenceRestrictions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamGroupCadenceRestrictionNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamGroupCadenceRestrictionNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dayType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxCadence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "calendarDays",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isSoft",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayTypeCadenceWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};
})();

(node as any).hash = "6aebd6980b72919d11cdf452b3c3c20d";

export default node;

import { useMemo } from "react";

import { scaleHours, scaleShifts } from "components/setting/common/functions";
import type { ApiSettingShiftsEmploymentDegreeLogicChoices } from "components/setting/types";

export interface Shift {
  worktimeHours: number;
}
type Coord = { x: string | number; y: string | number };
type GraphPoint = { id: string; data: Coord[] };
export type GraphData = GraphPoint[];

type Props = {
  hoursPerWeek: number;
  periodLengthWeeks: number;
  shiftsFactor: number;
  shiftsPerWeek: number;
  shiftsScalingLogic: ApiSettingShiftsEmploymentDegreeLogicChoices;
  shifts: ReadonlyArray<Shift>;
  hoursMargin: number;
  shiftsMargin: number;
};

const DEGREE_ARRAY = Array.from(Array(101).keys()).map((i) => i / 100);

export function useGraphData({
  hoursPerWeek,
  periodLengthWeeks,
  shiftsFactor,
  shiftsPerWeek,
  shiftsScalingLogic,
  shifts,
  hoursMargin,
  shiftsMargin,
}: Props) {
  const maxHoursMargin = 1 + 0.01 * hoursMargin;
  const minHoursMargin = 1 - 0.01 * hoursMargin;
  const maxShiftsMargin = 1 + 0.01 * shiftsMargin;
  const minShiftsMargin = 1 - 0.01 * shiftsMargin;

  const shiftLengths = useMemo(
    () => (shifts?.length ? shifts.map((e) => e.worktimeHours) : [0]),
    [shifts],
  );
  const shiftMax = useMemo(() => Math.max(...shiftLengths), [shiftLengths]);
  const shiftMin = useMemo(() => Math.min(...shiftLengths), [shiftLengths]);

  return useMemo<GraphData>(
    () =>
      DEGREE_ARRAY.reduce(
        (acc, e) => {
          const x = Math.round(e * 100);
          const hours = scaleHours(e, hoursPerWeek, 100) * periodLengthWeeks;
          const shifts = scaleShifts(
            e,
            shiftsFactor,
            shiftsPerWeek,
            periodLengthWeeks,
            shiftsScalingLogic,
          );

          acc[0].data.push({ x, y: Math.max(0, shifts) });
          acc[1].data.push({ x, y: hours });
          acc[2].data.push({ x, y: shiftMax });
          acc[3].data.push({ x, y: shiftMin });
          acc[4].data.push({
            x,
            y: shifts > 0 ? hours / shifts : 0,
          });
          acc[5].data.push({
            x,
            y:
              Math.round(minShiftsMargin * shifts) > 0
                ? (maxHoursMargin * hours) /
                  Math.round(minShiftsMargin * shifts)
                : 0,
          });
          acc[6].data.push({
            x,
            y:
              Math.round(maxShiftsMargin * shifts) > 0
                ? (minHoursMargin * hours) /
                  Math.round(maxShiftsMargin * shifts)
                : 0,
          });

          return acc;
        },
        [
          { id: "shifts", data: [] as Coord[] },
          { id: "hours", data: [] as Coord[] },
          { id: "shiftMax", data: [] as Coord[] },
          { id: "shiftMin", data: [] as Coord[] },
          { id: "duration", data: [] as Coord[] },
          { id: "durationMax", data: [] as Coord[] },
          { id: "durationMin", data: [] as Coord[] },
        ],
      ),
    [
      hoursPerWeek,
      shiftsFactor,
      shiftsScalingLogic,
      shiftsPerWeek,
      minHoursMargin,
      maxHoursMargin,
      minShiftsMargin,
      maxShiftsMargin,
      periodLengthWeeks,
      shiftMax,
      shiftMin,
    ],
  );
}

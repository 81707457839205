import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

type LinGradKeys =
  | "backgroundColor"
  | "backgroundImage"
  | "backgroundPosition"
  | "backgroundSize"
  | "backgroundRepeat";
type LinGrad = Record<LinGradKeys, string>;

const important = (str: string) => `${str} !important`;
const reduceImportant = (obj: Record<string, string>) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: important(value),
    }),
    {} as Record<string, string>,
  );

const background = (
  color: string,
  isImportant = true,
): Record<"backgroundColor", string> => {
  const obj = {
    backgroundColor: color,
  };
  if (isImportant) {
    return reduceImportant(obj);
  }
  return obj;
};

const repeatingLinearGradient = (
  color1: string,
  color2: string,
  shape = "45deg",
  isImportant = true,
): Record<"backgroundImage" | "border", string> => {
  const obj = {
    backgroundImage: `repeating-linear-gradient(${shape}, ${color1}, ${color1} 3px, ${color2} 3px, ${color2} 6px)`,
    border: border(color2),
  };
  if (isImportant) {
    return reduceImportant(obj);
  }
  return obj;
};

const linearGradient = (shape: string, ...parts: string[]) =>
  `linear-gradient(${shape}, ${parts.join(", ")})`;

const wavyLinGrad = (
  color1: string,
  color2: string,
  isImportant = true,
): LinGrad => {
  const wavyPx = "3.2px";
  const wavySize = "6.4px";
  const obj = {
    backgroundColor: color2,
    backgroundImage: [
      linearGradient("135deg", `${color1} 25%`, "transparent 25%"),
      linearGradient("225deg", `${color1} 25%`, "transparent 25%"),
      linearGradient("45deg", `${color1} 25%`, "transparent 25%"),
      linearGradient("315deg", `${color1} 25%`, `${color2} 25%`),
    ].join(", "),
    backgroundPosition: `${wavyPx} 0, ${wavyPx} 0, 0 0, 0 0`,
    backgroundSize: `${wavySize} ${wavySize}`,
    backgroundRepeat: "repeat",
    border: border(color2),
  };

  if (isImportant) {
    return reduceImportant(obj) as LinGrad;
  }
  return obj;
};

const radialGradient = (color1: string, color2: string, x: string) =>
  `radial-gradient(${color1} ${x}, ${color2} ${x})`;

const dottedGradient = (
  color1: string,
  color2: string,
  isImportant = true,
): LinGrad => {
  const dottedX = "2.3px";
  const dottedSize = "9px";
  const dottedSpacing = "4.5px";
  const obj = {
    backgroundColor: color2,
    backgroundImage: [
      radialGradient(color1, "transparent", dottedX),
      radialGradient(color1, color2, dottedX),
    ].join(", "),
    backgroundSize: `${dottedSize} ${dottedSize}`,
    backgroundPosition: `0 0, ${dottedSpacing} ${dottedSpacing}`,
    backgroundRepeat: "repeat",
    border: border(color2),
  };
  if (isImportant) {
    return reduceImportant(obj) as LinGrad;
  }
  return obj;
};

const triangleLinGrad = (
  color1: string,
  color2: string,
  isImportant = true,
): Record<
  "backgroundColor" | "backgroundImage" | "backgroundSize" | "backgroundRepeat",
  string
> => {
  const trianglePx = "6.6px";
  const obj = {
    backgroundColor: color1,
    backgroundImage: linearGradient(
      "-45deg",
      `${color1} 50%`,
      `${color2} 50%`,
      color2,
    ),
    backgroundSize: `${trianglePx} ${trianglePx}`,
    backgroundRepeat: "repeat",
    border: border(color1),
  };
  if (isImportant) {
    return reduceImportant(obj) as LinGrad;
  }
  return obj;
};

const border = (color: string, width = "1px", variant = "solid") =>
  `${width} ${variant} ${color}`;

export const ShiftTimelineItem = styled(Box)(({ theme }) => {
  const klippa = theme.palette.klippa.main;
  const manet = theme.palette.manet.main;
  const ture = theme.palette.ture[100];
  const fisk = theme.palette.fisk.main;
  const hav = theme.palette.hav.main;
  const landDark = theme.palette.land.dark;
  const ture60 = theme.palette.ture[60];
  const tang = theme.palette.tang.main;
  return {
    border: important("none"),
    overflow: "hidden",
    borderRadius: 4,

    "&.rct-shift-pass-day": background(klippa),
    "&.rct-shift-pass-evening": background(manet),
    "&.rct-shift-pass-night": background(ture),
    "&.rct-shift-pass-full": background(fisk),

    "&.rct-shift-work-day": repeatingLinearGradient(hav, klippa),
    "&.rct-shift-work-evening": repeatingLinearGradient(landDark, manet),
    "&.rct-shift-work-night": repeatingLinearGradient(ture, ture60),
    "&.rct-shift-work-full": repeatingLinearGradient(tang, fisk),

    "&.rct-shift-apt-day": wavyLinGrad(hav, klippa),
    "&.rct-shift-apt-evening": wavyLinGrad(landDark, manet),
    "&.rct-shift-apt-night": wavyLinGrad(ture60, ture),
    "&.rct-shift-apt-full": wavyLinGrad(tang, fisk),

    "&.rct-shift-bilagaj-day": dottedGradient(hav, klippa),
    "&.rct-shift-bilagaj-evening": dottedGradient(landDark, manet),
    "&.rct-shift-bilagaj-night": dottedGradient(ture, ture60),
    "&.rct-shift-bilagaj-full": dottedGradient(tang, fisk),

    "&.rct-shift-abjour-day": triangleLinGrad(klippa, hav),
    "&.rct-shift-abjour-evening": triangleLinGrad(manet, landDark),
    "&.rct-shift-abjour-night": triangleLinGrad(ture60, ture),
    "&.rct-shift-abjour-full": triangleLinGrad(fisk, tang),
  };
});

import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { WorkDayCadenceRestrictions } from "./WorkDayCadence/WorkDayCadenceRestrictions";
import { workdayCadenceSetting } from "./WorkDayCadenceSetting";

type Props = {
  readOnly?: boolean;
};

type CadenceProps = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function WorkDayCadence({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    workdayCadenceSetting.moduleName,
  );

  return <Cadence disabled={disabled} readOnly={readOnly} />;
}

function Cadence({ disabled, readOnly }: CadenceProps) {
  return (
    <PaperWrapper>
      <Stack gap={4}>
        <Stack gap={1}>
          <Typography variant="h4">Regler för sammanhängande arbete</Typography>
          <Typography>
            Här kan du skapa egna, särskilda regler, för sammanhängande
            arbetskalenderdagar.
          </Typography>
        </Stack>
        <WorkDayCadenceRestrictions
          name="workDayCadenceRestrictions"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

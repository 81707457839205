import { Colors } from "styles/colors";

export const chartColor = {
  SHIFT: "#1c84c6", //Colors.SEA,
  SHIFTADMIN: "#2da3ed",
  RESOURCE: "#23c6c8", // Colors.PURPLE, //"#23c6c8",
  FIXED_RESOURCE: Colors.KERSTIN,
  RESOURCEADMIN: "#34dee0",
  UNDERSTAFFED: Colors.ROUGE, //"#ed5565",
  UNDERSTAFFEDCOVERED: Colors.SKIN, //"#e69aa2",
  OVERSTAFFED: Colors.LIGHTBLUE, //"#55ade6",
  DENIED: Colors.PINK, //"#ED5565",
  APPROVED: Colors.GRASS, //"#6FC17B",
  CONTRIBUTION: Colors.GREEN,
  WEIGHT: Colors.ROUGE,
  SOLUTION: Colors.YELLOW,
  ALTERED: Colors.ANEMON,
};

import { Route, Routes } from "react-router-dom";
import {
  Admin,
  BlockedTimeSlot,
  CreateMeeting,
  Demand,
  EditMeeting,
  GroupSettings,
  Home,
  Login,
  Meetings,
  Organisation,
  Page404,
  Period,
  Schedule,
  Settings,
  Shifts,
  UserSettings,
} from "pages";

import { ProtectedRoute } from "components/ProtectedRoute";
import { RequirePermission } from "components/RequirePermission";
import { RequireTeamGroupRoute } from "components/RequireTeamGroupRoute";

const permissionRoutes = [
  { path: "/demand", element: <Demand />, permission: "demands" },
  {
    path: "/group-settings",
    element: <GroupSettings />,
    permission: "group-settings",
  },
  { path: "/members", element: <Organisation />, permission: "members" },
  { path: "/periods/:id?", element: <Period />, permission: "periods" },
  {
    path: "/schedules/:id/:index?",
    element: <Schedule />,
    permission: "periods",
  },
  { path: "/shifts", element: <Shifts />, permission: "shifts" },
  { path: "/vacation", element: <BlockedTimeSlot />, permission: "events" },
  {
    path: "/schedule-settings/:levelId?/:ruleId?",
    element: <Settings />,
    permission: "schedule-settings",
  },
];

const routes = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route element={<ProtectedRoute redirectTo="/login" />}>
      <Route index element={<Home />} />
      <Route element={<RequirePermission redirectTo="/" pname={"admin"} />}>
        <Route path="/admin" element={<Admin />} />
      </Route>
      <Route path="/settings" element={<UserSettings />} />
      <Route element={<RequireTeamGroupRoute redirectTo="/" />}>
        <Route element={<RequirePermission redirectTo="/" pname="meetings" />}>
          <Route path="/meetings">
            <Route index element={<Meetings />} />
            <Route path=":id" element={<EditMeeting />} />
            <Route path="new" element={<CreateMeeting />} />
          </Route>
        </Route>
        {permissionRoutes.map(({ path, element, permission }) => (
          <Route
            key={path}
            element={<RequirePermission redirectTo="/" pname={permission} />}
          >
            <Route path={path} element={element} />
          </Route>
        ))}
      </Route>
      <Route path="*" element={<Page404 />} />
    </Route>
  </Routes>
);

export default routes;

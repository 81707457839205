import { Colors } from "styles/colors";
import { UnreachableCaseError } from "ts-essentials";

import { ApiScheduleTerminationConditionChoices } from "components/period/schedule_runs/types";

import type { EditActivityType } from "./activities/forms/types";
import { chartColor } from "./graphs/constants";

export * from "./__generated__/ScheduleContext_fragment.graphql";
export * from "./graphs/types";
export * from "./results/Header/types";
export * from "./schedule_warnings/types";

export type ActivityActivityType = EditActivityType;
export type GanttItemBreakType = "B" | "E";
export type GanttItemTitleCode = EditActivityType | GanttItemBreakType;

export const activityList: ActivityActivityType[] = [
  "S",
  "R",
  "Z",
  "P",
  "J",
  "M",
  "V",
  "F",
  "O",
  "A",
  "D",
  "Y",
  "W",
  "Q",
];
export const ganttItemBreakOptions: GanttItemBreakType[] = ["B", "E"];
export const ganttItemTitleCodeOptions: GanttItemTitleCode[] = [
  ...activityList,
  ...ganttItemBreakOptions,
];

export enum activityTypeFilterEnum {
  // A = "A",
  DAY_BREAK = "B", // TODO: Remove since backend ActivityType should not have this option
  // C = "C",
  // D = "D",
  WEEK_BREAK = "E", // TODO: Remove since backend ActivityType should not have this option
  // F = "F",
  BILAGAJ_JOUR = "J",
  AB_JOUR = "M",
  // O = "O",
  // P = "P",
  // Q = "Q",
  RESOURCE = "R",
  SHIFT = "S",
  // V = "V",
  // W = "W",
  // Y = "Y",
  WORK_OUTSIDE_DEMAND = "Z",
}

export const TranslateActivityType = (
  type: GanttItemTitleCode | undefined,
): string | undefined => {
  switch (type) {
    case "S":
      return "Pass";
    case "R":
      return "Resurspass";
    case "W":
      return "Veckovila";
    case "E":
      return "Veckobryt";
    case "Y":
      return "Dygnsvila";
    case "B":
      return "Dygnsbryt";
    case "V":
      return "Semester";
    case "F":
      return "Ledig helg";
    case "C":
      return "Sammanhängande ledighet";
    case "A":
      return "Önskad fridag (beviljad)";
    case "D":
      return "Önskad fridag (nekad)";
    case "O":
      return "Fast fridag";
    case "Z":
      return "Ansvarstid";
    case "P":
      return "Möte";
    case "J":
      return "Jour (Bilaga J)";
    case "M":
      return "Jour (AB)";
    case "Q":
      return "Kompensationsvila";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export const TranslateActivityColor = (
  type: GanttItemTitleCode | undefined,
): string | undefined => {
  switch (type) {
    case "S":
      return "#1c84c6";
    case "R":
      return "#23c6c8";
    case "W":
      return Colors.SKIN;
    case "O":
      return Colors.SEA; // TODO: Change to correct color
    case "E":
      return Colors.TEXTCOLOR;
    case "Y":
      return Colors.FISK;
    case "B":
      return Colors.TEXTCOLOR;
    case "V":
      return Colors.PINK;
    case "F":
      return Colors.YELLOW;
    case "C":
      return Colors.YELLOW;
    case "A":
      return Colors.PURPLE;
    case "D":
      return Colors.PURPLE;
    case "Z":
      return Colors.APRICOT;
    case "P":
      return Colors.SKUM;
    case "J":
      return Colors.BLACK40;
    case "M":
      return Colors.BLACK40;
    case "Q":
      return Colors.BLACK40;
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export const TranslateActivityCategory = (
  type: GanttItemTitleCode | undefined,
): string | undefined => {
  switch (type) {
    case "S":
    case "R":
    case "Z":
    case "P":
    case "J":
    case "M":
      return "Arbete";
    case "W":
    case "E":
    case "Y":
    case "B":
    case "Q":
      return "Allmänna bestämmelser";
    case "V":
    case "F":
    case "C":
    case "A":
    case "D":
    case "O":
      return "Ledighet";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export const TranslateKPIType = (type: string): string | undefined => {
  switch (type) {
    case "demand":
      return "Behovstid";
    case "staffed":
      return "Passtid";
    case "covered_demand":
      return "Schemalagd behovstid";
    case "resources":
      return "Resurstid";
    case "overstaffed":
      return "Överbemannad spilltid";
    case "understaffed":
      return "Underbemannad tid";
    case "understaffed_meeting":
      return "Varav vid Möte";
    case "understaffed_covered":
      return "Varav täckt av resurstid";
    case "understaffed_actual":
      return "Varav faktisk";
    case "available_time":
      return "Tillgänglig tid";
    case "scheduled":
      return "Schemalagd tid";
    case "shift_breaks":
      return "Paustid för pass";
    case "resource_breaks":
      return "Paustid för resurspass";
    case "work_outside_demand":
      return "Ansvarstid";
    case "meeting":
      return "Möte";
    case undefined:
    case "%future added value":
      return undefined;
  }
};

export const TranslateProfileSeriesType = (
  type: string,
): string | undefined => {
  switch (type) {
    case "unfixed_resources":
      return "Resurstid";
    case "understaffed_meeting":
      return "Underbemanning vid möte";
    case "fixed_resources":
      return "Täckt underbemanning";
    case "overstaffed":
      return "Spilltid";
    case "understaffed_actual":
      return "Underbemannat";
    case "covered_demand":
      return "Bemannat";
    case undefined:
    case "%future added value":
      return undefined;
  }
};

export const TranslateStopCriteriaType = (
  type: ApiScheduleTerminationConditionChoices | undefined,
): string | undefined => {
  switch (type) {
    case "FEASIBLE":
      return "Optimal";
    case "INFEASIBLE":
      return "Olöslig";
    case "MAX_STALL_TIME":
      return "Lösningsplatå nådd";
    case "MAX_TIME_LIMIT":
      return "Tidsbegränsning nådd";
    case "OPTIMAL":
      return "Optimal";
    case "OTHER":
      return "Annat";
    case "UNBOUNDED":
      return "Obegränsad";
    case "UNKNOWN":
      return "Okänt";
    case "HARD_CONSTRAINT_COLLISION":
      return "Hård regelkrock";
    case "INFEASIBLE_NOW_RERUN":
      return "Analyserar hårda regelkrockar...";
    case undefined:
      return "Systemfel";
    case "%future added value":
      return undefined;
  }
};

export const TranslateVariableType = (type: string): string => {
  switch (type) {
    case "var_clockwise_rotation_unfulfilled_total":
      return "Medsols passrotation (minimera bruten regel)";
    case "var_min_consecutive_free_unfulfilled_total":
      return "Minsta sammanhängande lediga kalenderdagar (minimera bruten regel)";
    case "var_max_consecutive_free_unfulfilled_total":
      return "Mesta sammanhängande lediga kalenderdagar (minimera bruten regel)";
    case "var_min_consecutive_unfulfilled_total":
      return "Minsta sammanhängande arbetskalenderdagar (minimera bruten regel)";
    case "var_max_consecutive_workdays_unfulfilled_total":
      return "Mesta sammanhängande arbetskalenderdagar (minimera bruten regel)";
    case "var_workday_cadence_unfulfilled_total":
      return "Kadensregler för sammanhängande arbetskalenderdagar (minimera bruten regel)";
    case "var_workday_cadence_unfulfilled_max":
      return "Kadensregler för sammanhängande arbetskalenderdagar (rättvisa)";
    case "var_wishes_denied_max":
      return "Önskade lediga dagar (rättvisa)";
    case "var_wishes_denied_total":
      return "Önskade lediga dagar (maximera beviljade)";
    case "var_every_x_weekend_unfulfilled_total":
      return "Arbete var X:e helg (minimera bruten regel)";
    case "var_split_assignment_max":
      return "Rättvis fördelning av delade turer";
    case "var_xofy_weekend_unfulfilled_total":
      return "Ledig X av Y helger (minimera bruten regel)";
    case "var_consecutive_weekend_unfulfilled_total":
      return "Undvik 2 eller fler arbetshelger på raken";
    case "var_shifts_deviation_total":
      return "Antal schemalagda skift (kom nära målvärde)";
    case "var_hours_deviation_total":
      return "Antal schemalagda timmar (kom nära målvärde)";
    case "var_global_understaffed_max":
      return "Minimera den maximala underbemanningen";
    case "var_global_understaffed_diff_total":
      return "Sprid underbemanning jämnt över perioden (totalt)";
    case "var_global_understaffed_diff_max":
      return "Sprid underbemanning jämnt över perioden (max)";
    case "var_global_understaffed_day_type_max":
      return "Sprid underbemanning jämnt över dag/kväll/natt";
    case "var_global_understaffed_weekend_max":
      return "Sprid underbemanning jämnt över olika helger";
    case "var_overstaffed_max":
      return "Minimera den maximala överbemanningen (inom avdelningar)";
    case "var_understaffed_max":
      return "Minimera den maximala underbemanningen (inom avdelningar)";
    case "var_overstaffed_total":
      return "Minimera överbemannad spilltid";
    case "var_understaffed_total":
      return "Minimera underbemanning";
    case "var_overstaffed_total_weekend":
      return "Minimera överbemannad spilltid (helg)";
    case "var_understaffed_total_weekend":
      return "Minimera underbemanning (helg)";
    case "var_weekrest_unwanted_total":
      return "Undvik veckovilor utanför perioden";
    case "var_shift_type_total":
      return "Balansera fördelning av skifttyper";
    case "var_day_type_shift_total_deviation":
      return "Uppfyll krav på fördelning av dag/kväll/natt";
    case "var_day_type_total_mean_deviation":
      return "Rättvis fördelning av dag/kväll/natt/dygn";
    case "var_day_type_weekend_total_mean_deviation":
      return "Rättvis fördelning av dag/kväll/natt/dygn på helger";
    case "var_day_type_cadence_unfulfilled_total":
      return "Dag-/Kväll-/Natt-kadens (minimera bruten regel)";
    case "var_resource_fairness_diff":
      return "Rättvis fördelning av resurspass (mellan personer)";
    case "var_resource_diff_max":
      return "Jämn fördelning av resurspass (max)";
    case "var_resource_diff_total":
      return "Jämn fördelning av resurspass (totalt)";
    case "var_resource_diff_total_week":
      return "Jämn fördelning av resurspass (per kalendervecka)";
    case "var_resource_team_diff_total":
      return "Jämn fördelning av resurspass (totalt per avdelning)";
    case "var_resource_team_diff_max":
      return "Jämn fördelning av resurspass (max per avdelning)";
    case "var_resources_on_zerodemand":
      return "Undvik resurspass utanför behov";
    case "var_uncovered_understaffing_sum":
      return "Täck underbemanning med resurspass (mellan avdelningar)";
    case "var_daily_rest_unfulfilled_total":
      return "Vila mellan pass (minimera bruten regel)";
    case "var_weekly_rest_ab_unfulfilled_total":
      return "Veckovila Allmänna Bestämmelser (minimera bruten regel)";
    case "var_daily_rest_ab_unfulfilled_total":
      return "Dygnsvila Allmänna Bestämmelser (minimera bruten regel)";
    case "var_demand_duty_diff_total":
      return "Uppfyll bemanningskrav för vaken/sovande natt";
    case type:
    case "%future added value":
      return type;
  }
  return type;
};

export const TranslateVariableGroup = (type: string): string => {
  switch (type) {
    case "1_team_demand":
      return "Bemanningsbehov (för avdelningar)";
    case "2_understaffing":
      return "Bemanningsbehov (för hela enheten)";
    case "3_competence_demand":
      return "Bemanningsbehov (för kompetenser)";
    case "4_timebank":
      return "Timbank";
    case "5_resource":
      return "Resurspass";
    case "6_wishes":
      return "Önskemål";
    case "7_day_type":
      return "Dag/Kväll/Natt/Dygn";
    case "8_rhytm":
      return "Schemarytm";
    case "hours_shifts":
      return "Inkompatibelt hel-/deltidsmått";
    case "fixed_day_off":
      return "Krock för fasta fridagar";
    case "fixed_shift":
      return "Krock för fasta pass";
    case "meeting":
      return "Inkompatibla mötespass";
    case "admin_time":
      return "Inkompatibelt ansvarstidsmått";
    case "resource_time":
      return "Inkompatibelt resurstidsmått";
    case "free_weekends":
      return "Lediga helger";
    case "night_restriction":
      return "Nattpass";
    case "consecutive_daytype":
      return "Sammanhängande Dag/Kväll/Natt";
    case "max_consecutive_freedays":
      return "Maximum för lediga dagar i följd";
    case "min_consecutive_freedays":
      return "Minimum för lediga dagar i följd";
    case "day_type":
      return "Dag-/Kväll-/Natt-fördelning";
    case "max_consecutive_workdays":
      return "Maximum för arbetskalenderdagar i följd";
    case "min_consecutive_workdays":
      return "Minimum för arbetskalenderdagar i följd";
    case "consecutive_workdays":
      return "Sammanhängande arbetskalenderdagar";
    case "daily_rest_ab":
      return "Dygnsvila";
    case type:
    case "%future added value":
      return type;
  }
  return type;
};

export const TranslateShiftVsResourceKey = (type: string | any): string => {
  switch (type) {
    case "shift":
      return "Pass";
    case "shift_hours":
      return "Pass";
    case "resource":
      return "Resurspass";
    case "resource_hours":
      return "Resurspass";
    case "shift_admin":
      return "Pass med ansvarstid";
    case "shift_admin_hours":
      return "Pass med ansvarstid";
    case "resource_admin":
      return "Resurspass med ansvarstid";
    case "resource_admin_hours":
      return "Resurspass med ansvarstid";
    case type:
    case "%future added value":
      return type;
  }
  return type;
};

export const TranslateWishedKey = (type: string | any): string => {
  switch (type) {
    case "approved":
      return "Beviljad";
    case "denied":
      return "Nekad";
    case "approved_hours":
      return "Beviljad";
    case "denied_hours":
      return "Nekad";
    case type:
    case "%future added value":
      return type;
  }
  return type;
};

export const TranslateAlteredKey = (type: string | any): string => {
  switch (type) {
    case "altered":
      return "Totalt justerade pass";
    case "alteredHours":
      return "Totalt justerad tid";
    case type:
    case "%future added value":
      return type;
  }
  return type;
};

export const VariableColorSwitch = (
  type: string | undefined,
): string | undefined => {
  switch (type) {
    case "contribution":
      return chartColor.CONTRIBUTION;
    case "weight":
      return chartColor.WEIGHT;
    case "solution":
      return chartColor.SOLUTION;
  }
};

export const TranslateVariableKey = (type: string | any): string => {
  switch (type) {
    case "contribution":
      return "Bidrag";
    case "weight":
      return "Vikt";
    case "solution":
      return "Värde";
    case type:
    case "%future added value":
      return type;
  }
  return type;
};

/**
 * @generated SignedSource<<4bb6de886bf94fcab3c692ef22761d3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiScheduleSolutionStatusChoices = "ERROR" | "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "QUEUED" | "RUNNING" | "RUNNING_WITH_INFEASIBILITY_VENTS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScheduleTabs_fragment$data = {
  readonly id: string;
  readonly isMaster: boolean;
  readonly period: {
    readonly id: string;
  };
  readonly solutionStatus: ApiScheduleSolutionStatusChoices;
  readonly " $fragmentType": "ScheduleTabs_fragment";
};
export type ScheduleTabs_fragment$key = {
  readonly " $data"?: ScheduleTabs_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleTabs_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleTabs_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMaster",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "solutionStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodNode",
      "kind": "LinkedField",
      "name": "period",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleNode",
  "abstractKey": null
};
})();

(node as any).hash = "62517da8ef5f52ac00229e244bd5f59d";

export default node;

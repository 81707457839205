import { useMemo } from "react";
import type { Unwrap } from "relay-help/arrays";

import type { Group, TimelineItem } from "./TimelineRenderer";
import type {
  ShiftTimeline_fragment$data as Data,
  ShiftTimelineItem,
} from "./types";

type ShiftType = Unwrap<Data["shiftTypes"]>;

function convertActivity(a: ShiftTimelineItem, i: number): TimelineItem {
  return {
    id: i,
    group: a.shiftId,
    start: new Date(a.startTime),
    end: new Date(a.endTime),
    breakTime: a.breakTime ?? 0,
    shiftType: a.shiftType,
    dayType: a.dayType,
  };
}

function convertShiftType(type: ShiftType): Group {
  return {
    id: type?.id,
    title: type?.name,
  };
}

export function useShiftTimeline(data: Data) {
  const items = useMemo(
    () => (data?.shifts ?? []).map(convertActivity),
    [data?.shifts],
  );

  const groups = useMemo(
    () => (data?.shiftTypes ?? []).map(convertShiftType),
    [data?.shiftTypes],
  );

  return useMemo(() => ({ groups, items }), [groups, items]);
}

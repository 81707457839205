import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
} from "@mui/material";
import { Form as FormikForm, useFormikContext } from "formik";

import { FormikColorPicker } from "components/common/ColorPicker";
import { DialogTitle } from "components/common/Dialog";
import CustomField from "components/forms/CustomField";
import SingleTeamGroupSelect from "components/forms/UserForm/SingleTeamGroupSelect";

import type { MemberTeamGroupType } from "../../types";

type Props = {
  title: string;
  submitText: string;
  onClose: () => void;
  teamGroupOptions?: MemberTeamGroupType[];
};

export function TeamForm({
  title,
  submitText,
  onClose,
  teamGroupOptions,
}: Props) {
  const { isSubmitting } = useFormikContext();
  return (
    <FormikForm autoComplete="off">
      <DialogTitle title={title} />

      <Divider />

      <DialogContent>
        <Stack gap={3} pb={1}>
          <Stack gap={2}>
            <CustomField name="name" label="Namn" />
          </Stack>
          {!!teamGroupOptions?.length && (
            <SingleTeamGroupSelect
              name="groupId"
              options={teamGroupOptions}
              label="Tillhör Enhet"
            />
          )}
          <FormikColorPicker
            name="color"
            label="Färg"
            variant="primary_and_secondary"
            sx={{ width: "9rem" }}
          />
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {submitText}
        </Button>
        <Button variant="cancel" onClick={onClose}>
          Avbryt
        </Button>
      </DialogActions>
    </FormikForm>
  );
}

import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
} from "@mui/material";
import { useFormikContext } from "formik";
import * as yup from "yup";

import { DialogTitle } from "components/common/Dialog";
import CustomField from "components/forms/CustomField";

export const ruleGroupFormValidationSchema = yup.object({
  name: yup.string().required("Fyll i ett namn"),
  description: yup.string().required("Fyll i en beskrivning"),
});

type Props = {
  title: any;
  submitText: any;
  onClose: () => void;
};

export type RuleGroupFormValues = {
  name: string;
  description: string;
};

export function RuleGroupForm({ title, submitText, onClose }: Props) {
  const { isSubmitting } = useFormikContext<RuleGroupFormValues>();
  return (
    <>
      <DialogTitle title={title} onClose={onClose} />
      <Divider />

      <DialogContent>
        <Stack gap={3} sx={{ py: 2 }}>
          <CustomField label="Namn" name="name" />
          <CustomField label="Beskrivning" name="description" />
        </Stack>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Stack gap={0.5} direction="row">
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
          <Button variant="cancel" onClick={onClose}>
            Avbryt
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}

/**
 * @generated SignedSource<<32d8cd25b64e123d1dbaf8e17c9c4201>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PriorityWeight = "ELEVATED" | "NORMAL" | "%future added value";
export type CreateDemandsInput = {
  clientMutationId?: string | null;
  competenceId?: string | null;
  demands: ReadonlyArray<DemandWeeksInput>;
  teamId: string;
};
export type DemandWeeksInput = {
  demands: ReadonlyArray<DemandDaysInput>;
  weeks: ReadonlyArray<number>;
};
export type DemandDaysInput = {
  days: ReadonlyArray<number>;
  demands: ReadonlyArray<DemandItemInput>;
};
export type DemandItemInput = {
  demand: number;
  dutyShare?: number | null;
  end: string;
  enforceDuty?: boolean | null;
  minimizeOverstaffingWeight: PriorityWeight;
  minimizeUnderstaffingWeight: PriorityWeight;
  start: string;
};
export type CreateDemandsMutation$variables = {
  input: CreateDemandsInput;
};
export type CreateDemandsMutation$data = {
  readonly createDemands: {
    readonly demandsWereMerged: boolean | null;
    readonly ok: boolean | null;
  } | null;
};
export type CreateDemandsMutation = {
  response: CreateDemandsMutation$data;
  variables: CreateDemandsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateDemandsPayload",
    "kind": "LinkedField",
    "name": "createDemands",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "demandsWereMerged",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDemandsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateDemandsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "25b616252575b042f71b3d65637d4682",
    "id": null,
    "metadata": {},
    "name": "CreateDemandsMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDemandsMutation(\n  $input: CreateDemandsInput!\n) {\n  createDemands(input: $input) {\n    ok\n    demandsWereMerged\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c788bf1c109f72bb2415da4e137df17";

export default node;

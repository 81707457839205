import { Divider, Stack } from "@mui/material";

import { LeftSideAndContent } from "./common";
import { ConstraintModuleBridgeField } from "./ConstraintModuleBridgeField";
import { PeriodLengthWeeksField } from "./PeriodLengthWeeksField";
import { WrapAroundPeriodEndField } from "./WrapAroundPeriodEndField";

type Props = {
  readOnly?: boolean;
};

export function SchedulePeriodForm({ readOnly }: Props) {
  return (
    <Stack gap={2}>
      <LeftSideAndContent>
        <PeriodLengthWeeksField readOnly={readOnly} />
      </LeftSideAndContent>
      <Divider />
      <WrapAroundPeriodEndField readOnly={readOnly} />
      <Divider />
      <ConstraintModuleBridgeField readOnly={readOnly} />
    </Stack>
  );
}

/**
 * @generated SignedSource<<7226ccd3e8202cfe66d4e614f4945c3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditCompetenceInput = {
  clientMutationId?: string | null;
  color: string;
  description: string;
  id: string;
  name: string;
};
export type EditCompetenceMutation$variables = {
  input: EditCompetenceInput;
};
export type EditCompetenceMutation$data = {
  readonly editCompetence: {
    readonly competence: {
      readonly " $fragmentSpreads": FragmentRefs<"EditCompetenceForm_fragment">;
    } | null;
  } | null;
};
export type EditCompetenceMutation = {
  response: EditCompetenceMutation$data;
  variables: EditCompetenceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCompetenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditCompetencePayload",
        "kind": "LinkedField",
        "name": "editCompetence",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetenceNode",
            "kind": "LinkedField",
            "name": "competence",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditCompetenceForm_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCompetenceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditCompetencePayload",
        "kind": "LinkedField",
        "name": "editCompetence",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetenceNode",
            "kind": "LinkedField",
            "name": "competence",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6dacce7091f5666cbf4d1c6ce04e7aa6",
    "id": null,
    "metadata": {},
    "name": "EditCompetenceMutation",
    "operationKind": "mutation",
    "text": "mutation EditCompetenceMutation(\n  $input: EditCompetenceInput!\n) {\n  editCompetence(input: $input) {\n    competence {\n      ...EditCompetenceForm_fragment\n      id\n    }\n  }\n}\n\nfragment EditCompetenceForm_fragment on CompetenceNode {\n  id\n  name\n  description\n  color\n}\n"
  }
};
})();

(node as any).hash = "1b43d4ccfb7066f17dcc35510894414f";

export default node;

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderWeight,
  SettingBoxHeader,
} from "components/setting/common";

type Props = {
  moduleName: string;
  radioSoft: boolean;
  readOnly?: boolean;
  disabled?: boolean;
};

type RadiosProps = Pick<Props, "readOnly" | "disabled">;
type WeightProps = RadiosProps;

function Radios({ readOnly, disabled }: RadiosProps) {
  return (
    <HeaderRadios
      name="clockwiseRotationSoft"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

function Weight({ readOnly, disabled }: WeightProps) {
  return (
    <HeaderWeight
      name="clockwiseRotationWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Header({ moduleName, radioSoft, readOnly, disabled }: Props) {
  return (
    <SettingBoxHeader
      radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
      switchComponent={<HeaderSwitch name={moduleName} readOnly={readOnly} />}
      weightComponent={
        <Weight readOnly={readOnly} disabled={disabled || !radioSoft} />
      }
    />
  );
}

/**
 * @generated SignedSource<<e5a542759f1719fda2dfd6c3fe8734c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Comprest_fragment$data = {
  readonly comprestCanCutDaybreakAbjour: boolean;
  readonly comprestCanCutDaybreakBilagaj: boolean;
  readonly id: string;
  readonly " $fragmentType": "Comprest_fragment";
};
export type Comprest_fragment$key = {
  readonly " $data"?: Comprest_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Comprest_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Comprest_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comprestCanCutDaybreakBilagaj",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comprestCanCutDaybreakAbjour",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "ec2b18864ae9ed58ece95660f6900d15";

export default node;

/**
 * @generated SignedSource<<d3f02d0bac8fd5fa810a35ee6742abb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminEditUserInput = {
  clientMutationId?: string | null;
  competences?: ReadonlyArray<string | null> | null;
  email?: string | null;
  employmentDegree?: number | null;
  employmentForm?: string | null;
  employmentTitle?: string | null;
  firstName?: string | null;
  id?: string | null;
  invite?: boolean | null;
  lastName?: string | null;
  password?: string | null;
  ruleGroups?: ReadonlyArray<string | null> | null;
  teamGroups?: ReadonlyArray<string | null> | null;
  teams?: ReadonlyArray<string | null> | null;
  timebankBaseBalance?: number | null;
};
export type AdminEditUserMutation$variables = {
  input: AdminEditUserInput;
};
export type AdminEditUserMutation$data = {
  readonly adminEditUser: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"AdminEditUserForm_fragment">;
    } | null;
  } | null;
};
export type AdminEditUserMutation = {
  response: AdminEditUserMutation$data;
  variables: AdminEditUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminEditUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AdminEditUserPayload",
        "kind": "LinkedField",
        "name": "adminEditUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdminEditUserForm_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminEditUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AdminEditUserPayload",
        "kind": "LinkedField",
        "name": "adminEditUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentDegree",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RuleGroupNodeConnection",
                "kind": "LinkedField",
                "name": "ruleGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RuleGroupNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RuleGroupNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentForm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timebankBaseBalance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeConnection",
                "kind": "LinkedField",
                "name": "memberOf",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetenceNodeConnection",
                "kind": "LinkedField",
                "name": "competences",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetenceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CompetenceNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamGroupNodeConnection",
                "kind": "LinkedField",
                "name": "groupMemberOf",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamGroupNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamGroupNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "492591e11fa9fe472ade8a47e042a26d",
    "id": null,
    "metadata": {},
    "name": "AdminEditUserMutation",
    "operationKind": "mutation",
    "text": "mutation AdminEditUserMutation(\n  $input: AdminEditUserInput!\n) {\n  adminEditUser(input: $input) {\n    user {\n      ...AdminEditUserForm_fragment\n      id\n    }\n  }\n}\n\nfragment AdminEditUserForm_fragment on UserNode {\n  id\n  firstName\n  lastName\n  fullName\n  email\n  employmentDegree\n  ruleGroups {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  employmentForm\n  employmentTitle\n  timebankBaseBalance\n  memberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  competences {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  groupMemberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe0585836b1a53bb2f25ab9ce32e7b39";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import { isNonNull } from "relay-help/arrays";
import type { RecordProxy, SelectorStoreUpdater } from "relay-runtime";

import type { CreateTeamMutation as Mutation } from "./types";

const mutation = graphql`
  mutation CreateTeamMutation($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        id
        name
        color
        isActive
        archivedAt
        group {
          id
          name
        }
      }
    }
  }
`;

function update(
  r: RecordProxy,
  key: string,
  newRecord: any,
  linkedRecordArgs = {},
) {
  const records = r.getLinkedRecords(key, linkedRecordArgs);
  const newRecordsList = [...(records || []).filter(isNonNull), newRecord];
  r.setLinkedRecords(newRecordsList, key, linkedRecordArgs);
}

const updater: SelectorStoreUpdater<Mutation["response"]> = (store, res) => {
  const newRecord = store.getRootField("createTeam").getLinkedRecord("team");
  const root = store.getRoot();
  update(root, "allTeams", newRecord);
  const groupId = res?.createTeam?.team?.group.id;
  update(root, "teams", newRecord, { groupId });
};

export const useCreateTeam = () => useMutation<Mutation>(mutation, { updater });

import { Dialog } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

import { useCreateTeamGroup } from "../mutations";
import type { CreateTeamGroupInput as FormValues } from "../types";

import { TeamGroupForm } from "./common";

export const validationSchema = yup.object({
  name: yup.string().required("Fyll i ett namn"),
});

type Props = {
  open: boolean;
  onClose: (newId?: string) => void;
};

export function CreateTeamGroupForm({ open, onClose }: Props) {
  const [commit] = useCreateTeamGroup();

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } })
      .then((res) => onClose(res.createTeamGroup?.teamGroup?.id))
      .catch(console.error);
  }

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth={"xs"}>
      <Formik
        initialValues={{ name: "" }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <TeamGroupForm
          title="Skapa ny enhet"
          submitText="Spara"
          onClose={onClose}
        />
      </Formik>
    </Dialog>
  );
}

import { styled } from "@mui/material/styles";

export const NumberCircle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.light,
  color: "white",
  fontSize: "12px",
  fontWeight: "bold",
  textAlign: "center",
  lineHeight: "20px",
}));

import AdvancedGroupIcon from "@mui/icons-material/AdminPanelSettings";

import type { MenuItem } from "../types";

export const menuItems: MenuItem[] = [
  {
    sectionTitle: "Grundinställningar",
    sectionID: "b",
    menuRows: [
      { itemID: 4, title: "Dygnsdefinition", constraintModule: "Default" },
      {
        itemID: 6,
        title: "Kostnadsinställningar",
        constraintModule: "Default",
      },
    ],
  },
  {
    sectionTitle: "Behov",
    sectionID: "d",
    menuRows: [
      { itemID: 1, title: "Timmar & Pass", constraintModule: "Default" },
      { itemID: 2, title: "Timbank", constraintModule: "Default" },
      { itemID: 3, title: "Grundbehov", constraintModule: "Default" },
      { itemID: 4, title: "Kompetensbehov", constraintModule: "Default" },
    ],
  },
  {
    sectionTitle: "Allmänna Bestämmelser",
    sectionID: "ab",
    menuRows: [
      { itemID: 0, title: "Dygnsvila", constraintModule: "DailyRestAB" },
      { itemID: 1, title: "Veckovila", constraintModule: "WeeklyRestAB" },
    ],
  },
  {
    sectionTitle: "Schemarytm",
    sectionID: "r",
    menuRows: [
      {
        itemID: 1,
        title: "Sammanhängande ledighet",
        constraintModule: "ConsecutiveFreeDays",
      },
      {
        itemID: 2,
        title: "Sammanhängande arbete",
        constraintModule: "ConsecutiveWorkDays",
      },
    ],
  },
  {
    sectionTitle: "Ledighet",
    sectionID: "w",
    menuRows: [
      { itemID: 0, title: "Helgvila", constraintModule: "FreeWeekends" },
    ],
  },
  {
    sectionTitle: "Dag/Kväll/Natt",
    sectionID: "n",
    menuRows: [
      {
        itemID: 0,
        title: "Dag-/Kväll-/Natt-fördelning",
        constraintModule: "ShiftDayTypeDistribution",
      },
      {
        itemID: 1,
        title: "Dag-/Kväll-/Natt-rättvisa",
        constraintModule: "ShiftDayTypeFairness",
      },
      {
        itemID: 2,
        title: "Dag-/Kväll-/Natt-kadens",
        constraintModule: "ShiftDayTypeCadence",
      },
    ],
  },
  {
    sectionTitle: "Särskilda passtyper",
    sectionID: "s",
    menuRows: [
      {
        itemID: 2,
        title: "Passtypsfördelning",
        constraintModule: "ShiftTypeDistribution",
      },
      { itemID: 1, title: "Resurspass", constraintModule: "SmoothResources" },
      { itemID: 0, title: "Delade turer", constraintModule: "SplitShifts" },
    ],
  },
  {
    sectionTitle: "Annan arbetstid",
    sectionID: "t",
    menuRows: [
      {
        itemID: 0,
        title: "Ansvarstid",
        constraintModule: "AdminTime",
      },
      {
        itemID: 1,
        title: "Resurstid",
        constraintModule: "ResourceTime",
      },
    ],
  },
  {
    sectionTitle: "Avancerat",
    sectionID: "advanced",
    icon: <AdvancedGroupIcon sx={{ color: "text.secondary" }} />,
    menuRows: [
      { itemID: 0, title: "Optimering", constraintModule: "Default" },
      { itemID: 1, title: "Deltidsmått", constraintModule: "Default" },
      { itemID: 2, title: "Vila mellan pass", constraintModule: "DailyRest" },
      {
        itemID: 3,
        title: "Medsols passrotation",
        constraintModule: "ClockwiseRotation",
      },
      {
        itemID: 4,
        title: "Önskade fridagar",
        constraintModule: "WishedDaysOff",
      },
      {
        itemID: 5,
        title: "Semester",
        constraintModule: "Vacation",
      },
      { itemID: 6, title: "Nattpass", constraintModule: "NightShifts" },
    ],
  },
];

import { useState } from "react";
import { MouseEvent } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import {
  Dialog,
  DialogTitle,
  Typography as Text,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Formik } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { commitMutation } from "libs/commitMutation";
import { DateTime } from "luxon";

import { useSnackbar } from "components/Snackbar";
import { DraggablePaper } from "components/utils/DraggablePaper";

import BlockedTimeSlotForm, {
  validationSchema,
} from "../common/BlockedTimeSlotForm";
import { prepareBeforeForm, prepareBeforeSubmit } from "../common/Recurrence";
import type { VerifyCbProps } from "../common/VerifyDialog";
import VerifyDialog from "../common/VerifyDialog";
import type { BlockedTimeSlotTimelineItem } from "../graphs";

import { useEditBlockedTimeSlot } from "./EditBlockedTimeSlotMutation";
import type {
  BlockedTimeSlotFormValues as FormValues,
  BlockedTimeSlotType,
} from "./types";
import { CreateBlockedTimeSlotMutation } from "./types";

type Props = {
  open: boolean;
  onClose: () => void;
  copyFrom?: any;
  onOpenOriginalTimeSlot: () => void;
};

type VerifyData = {
  values: FormValues;
};

const mutation = graphql`
  mutation CreateBlockedTimeSlotMutation($input: CreateBlockedTimeSlotInput!) {
    createBlockedTimeSlot(input: $input) {
      ok
      blockedTimeSlot {
        id
      }
    }
  }
`;

const dtFormat = "yyyy-MM-dd'T'T";

function formatCopyFrom(copyFrom: BlockedTimeSlotTimelineItem): FormValues {
  return {
    ...copyFrom,
    userIds: (copyFrom?.users || []).map((edge) => edge.id ?? null),
    start: DateTime.fromMillis(copyFrom.start_time).toFormat(dtFormat),
    end: DateTime.fromMillis(copyFrom.end_time).toFormat(dtFormat),
    recurrences: copyFrom?.recurrences
      ? prepareBeforeForm(copyFrom.recurrences)
      : null,
  };
}

const defaultInitialValues: FormValues = {
  start: DateTime.now().startOf("day").toFormat(dtFormat),
  end: DateTime.now().endOf("day").toFormat(dtFormat),
  approvedByAdmin: true,
  userIds: [],
  timeslotType: "V" as BlockedTimeSlotType,
  shiftId: null,
  shiftDate: null,
  recurrences: null,
};

function ReturnToOriginal({
  isEditingRecurrenceItem,
  onOpenOriginalTimeSlot,
}: {
  isEditingRecurrenceItem: boolean;
  onOpenOriginalTimeSlot: () => void;
}) {
  if (isEditingRecurrenceItem) {
    return (
      <Text
        variant="h3"
        fontWeight={500}
        component="span"
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onOpenOriginalTimeSlot();
        }}
        onMouseDown={(e: MouseEvent) => {
          e.stopPropagation();
        }}
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
          userSelect: "none",
          marginLeft: 2,
        }}
      >
        Öppna ursprunglig händelse
      </Text>
    );
  }
}

export function CreateBlockedTimeSlot(props: Props) {
  /** CreateBlockedTimeSlot can be a new instance or a copy of an existing one.
   *
   *   If submitting a copy of a recurrence, we ask the user if they want to overwrite the
   *   selected instance or all future instances.
   *   If submitting a new instance, we just submit the form.
   *   */
  const [verifyData, setVerifyData] = useState<VerifyData | null>(null);
  const environment = useRelayEnvironment();
  const { addSnack } = useSnackbar();

  const teamGroup = useCurrentTeamGroup();

  const initialValues = props.copyFrom
    ? formatCopyFrom(props.copyFrom)
    : defaultInitialValues;

  const isEditingRecurrenceItem = !!props.copyFrom;
  const verifyDialogOpen = !!verifyData;

  const [commit] = useEditBlockedTimeSlot();
  async function onSubmit(values: FormValues & VerifyCbProps) {
    const {
      start,
      end,
      shiftId,
      shiftDate,
      recurrences,
      approvedByAdmin,
      timeslotType,
      userIds,
      editOne,
      originalTimeslotId,
    } = prepareBeforeSubmit({ ...values });
    const input = {
      timeslotType,
      start,
      end,
      shiftId,
      shiftDate,
      recurrences,
      approvedByAdmin,
      userIds,
      editOne,
      originalTimeslotId,
    };

    if (values.editFutureRecurrences) {
      const editInput = {
        timeslotType,
        start,
        end,
        shiftId,
        shiftDate,
        recurrences,
        approvedByAdmin,
        userIds,
        id: originalTimeslotId,
      };
      await commit({
        variables: { input: editInput },
      })
        .then(() => {
          props.onClose();
          addSnack({ message: "Ändringar sparade", severity: "success" });
        })
        .catch((error) => {
          console.error(error);
          addSnack({ message: "Något gick fel", severity: "error" });
        })
        .finally(() => {
          setVerifyData(null);
        });
    } else {
      // regular create
      await commitMutation<CreateBlockedTimeSlotMutation>(environment, {
        mutation,
        variables: { input },
      })
        .then(() => {
          props.onClose();
          addSnack({ message: "Händelse skapad", severity: "success" });
        })
        .catch((error) => {
          console.error(error);
          addSnack({
            message: "Något gick fel. Kunde inte skapa händelse.",
            severity: "error",
          });
        })
        .finally(() => {
          setVerifyData(null);
        });
    }
  }

  function openVerifyDialog(data: VerifyData) {
    setVerifyData(data);
  }
  async function confirmVerifyCb({
    editOne,
    editFutureRecurrences,
  }: VerifyCbProps) {
    if (verifyData) {
      await onSubmit({ ...verifyData.values, editOne, editFutureRecurrences });
    }
  }
  function cancelVerifyCb() {
    setVerifyData(null);
  }

  const { open } = props;
  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        PaperComponent={DraggablePaper}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <DialogTitle
          style={{
            cursor: "move",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          id="draggable-dialog-title"
        >
          <Typography variant="h3">
            {isEditingRecurrenceItem
              ? "Redigera upprepning"
              : "Skapa ny händelse"}{" "}
            för enhet <b>{teamGroup?.name}</b>
          </Typography>
          <ReturnToOriginal
            isEditingRecurrenceItem={isEditingRecurrenceItem}
            onOpenOriginalTimeSlot={props.onOpenOriginalTimeSlot}
          />
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (isEditingRecurrenceItem) {
              openVerifyDialog({ values });
            } else {
              await onSubmit(values);
            }
          }}
        >
          <BlockedTimeSlotForm
            submitText={
              isEditingRecurrenceItem ? "Redigera upprepning" : "Skapa händelse"
            }
            modalProps={props}
            deletable={isEditingRecurrenceItem}
          />
        </Formik>
      </Dialog>
      <VerifyDialog
        open={verifyDialogOpen}
        onClose={cancelVerifyCb}
        onConfirm={confirmVerifyCb}
      />
    </>
  );
}

import { InputAdornment, Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import CustomField from "components/forms/CustomField";
import HardSoftRadios from "components/forms/HardSoftRadios";

import { consecutiveRestSetting } from "./ConsecutiveRestSetting";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

function ConsecutiveRest({ disabled, readOnly }: Props) {
  return (
    <PaperWrapper>
      <Stack gap={5}>
        <Stack gap={1}>
          <Typography variant="h4">
            Maximum antal lediga kalenderdagar i följd
          </Typography>
          <Typography>
            Detta fält styr hur många sammanhängande kalenderdagar i följd en
            fridagarsperiod <i>som mest</i> får lov att vara.
          </Typography>
          <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
            <CustomField
              name="maxConsecutiveFreeDays"
              size="small"
              type="number"
              disabled={disabled}
              sx={{ minWidth: 200 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">dagar</InputAdornment>
                ),
              }}
            />
            <HardSoftRadios
              name="maxConsecutiveFreeDaysSoft"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">
            Minimum antal lediga kalenderdagar i följd
          </Typography>
          <Typography>
            Detta fält styr hur många sammanhängande kalenderdagar i följd en
            fridagarsperiod <i>som minst</i> får lov att vara.
          </Typography>
          <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
            <CustomField
              size="small"
              type="number"
              name="minConsecutiveFreeDays"
              disabled={disabled}
              sx={{ minWidth: 200 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">dagar</InputAdornment>
                ),
              }}
            />
            <HardSoftRadios
              name="minConsecutiveFreeDaysSoft"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        </Stack>
      </Stack>
    </PaperWrapper>
  );
}
export function ConsecutiveRestForm({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    consecutiveRestSetting.moduleName,
  );

  return <ConsecutiveRest disabled={disabled} readOnly={readOnly} />;
}

import { useMemo } from "react";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import type { RuleGroup, User } from "pages/Schedule/types";

import { Level1Menu as Level1MenuRender } from "components/setting/Level1Menu";

type Props = Pick<
  React.ComponentProps<typeof Level1MenuRender>,
  "levelId" | "onSelect"
> & {
  ruleGroups: ReadonlyArray<RuleGroup>;
  users: ReadonlyArray<User>;
};

export function Level1Menu({
  ruleGroups: ruleGroupsProp,
  users: usersProp,
  ...props
}: Props) {
  const teamGroup = useCurrentTeamGroup();
  const groupId = teamGroup?.id ?? "";
  const groupName = teamGroup?.name ?? "";

  const ruleGroups = useMemo(
    () => ruleGroupsProp.map((x) => ({ value: x.id, label: x.name })),
    [ruleGroupsProp],
  );
  const users = useMemo(
    () =>
      usersProp.map((x) => ({
        value: x.id,
        label: x.name,
      })),
    [usersProp],
  );

  return (
    <Level1MenuRender
      teamGroupId={groupId}
      teamGroupName={groupName}
      users={users}
      ruleGroups={ruleGroups}
      {...props}
    />
  );
}

/**
 * @generated SignedSource<<e913c5beef37037bef19868f070542b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HoursAndShiftsSettingUserSetting_fragment$data = {
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly shiftsPerWeek: number;
  readonly " $fragmentType": "HoursAndShiftsSettingUserSetting_fragment";
};
export type HoursAndShiftsSettingUserSetting_fragment$key = {
  readonly " $data"?: HoursAndShiftsSettingUserSetting_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HoursAndShiftsSettingUserSetting_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HoursAndShiftsSettingUserSetting_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "5307d87a73cf726643f08993022c9c71";

export default node;

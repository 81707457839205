/**
 * @generated SignedSource<<5d478592513112cc152d5925a58cfd24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeetingsQuery$variables = {
  teamGroupId: string;
};
export type MeetingsQuery$data = {
  readonly meetingShifts: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"MeetingGraph_shifts">;
  }>;
  readonly meetings: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"MeetingList_meetings">;
  }>;
  readonly settingForTeamGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"MeetingGraph_setting">;
  } | null;
};
export type MeetingsQuery = {
  response: MeetingsQuery$data;
  variables: MeetingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamGroupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "teamGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayMatrix",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MeetingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MeetingNode",
        "kind": "LinkedField",
        "name": "meetings",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MeetingList_meetings"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MeetingShiftNode",
        "kind": "LinkedField",
        "name": "meetingShifts",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MeetingGraph_shifts"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MeetingGraph_setting"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MeetingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MeetingNode",
        "kind": "LinkedField",
        "name": "meetings",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNodeConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MeetingShiftNode",
        "kind": "LinkedField",
        "name": "meetingShifts",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meetingDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingShiftPartNodeConnection",
            "kind": "LinkedField",
            "name": "shiftParts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeetingShiftPartNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MeetingShiftPartNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "partType",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingNodeConnection",
            "kind": "LinkedField",
            "name": "meetings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeetingNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MeetingNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodLengthWeeks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "morningStartsNightEnds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eveningStartsMorningEnds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nightStartsEveningEnds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullDayDuration",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bccbd1994e2676bf58e1eb01755825d2",
    "id": null,
    "metadata": {},
    "name": "MeetingsQuery",
    "operationKind": "query",
    "text": "query MeetingsQuery(\n  $teamGroupId: ID!\n) {\n  meetings(teamGroupId: $teamGroupId) {\n    ...MeetingList_meetings\n    id\n  }\n  meetingShifts(teamGroupId: $teamGroupId) {\n    ...MeetingGraph_shifts\n    id\n  }\n  settingForTeamGroup(teamGroupId: $teamGroupId) {\n    ...MeetingGraph_setting\n    id\n  }\n}\n\nfragment MeetingGraph_setting on SettingNode {\n  periodLengthWeeks\n  ...ShiftDayTypeContext_fragment\n}\n\nfragment MeetingGraph_shifts on MeetingShiftNode {\n  id\n  name\n  start\n  end\n  meetingDay\n  breakTime\n  shiftParts {\n    edges {\n      node {\n        start\n        end\n        partType\n        id\n      }\n    }\n  }\n  meetings {\n    edges {\n      node {\n        start\n        end\n        dayMatrix\n        id\n      }\n    }\n  }\n}\n\nfragment MeetingList_meetings on MeetingNode {\n  id\n  name\n  start\n  end\n  dayMatrix\n  users {\n    edges {\n      node {\n        id\n        fullName\n      }\n    }\n  }\n}\n\nfragment ShiftDayTypeContext_fragment on SettingNode {\n  morningStartsNightEnds\n  eveningStartsMorningEnds\n  nightStartsEveningEnds\n  fullDayDuration\n}\n"
  }
};
})();

(node as any).hash = "8e0d46348375030285b876153f399442";

export default node;

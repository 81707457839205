import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { UnreachableCaseError } from "ts-essentials";
import { NN } from "types";

import type { MemberList_fragment$data } from "./__generated__/MemberList_fragment.graphql";

export * from "./__generated__/MemberList_fragment.graphql";
export type {
  EditUserForm_fragment$data,
  EditUserForm_fragment$key,
} from "./forms/types";
export type {
  CreateUserInput,
  CreateUserMutation,
  CreateUserMutation$data,
  CreateUserMutation$variables,
  EditUserInput,
  EditUserMutation,
  EditUserMutation$data,
  EditUserMutation$variables,
} from "./mutations/types";

export type EmploymentForm = "P" | "H" | "S" | "%future added value";

export const TranslateEmploymentForm = (
  type: EmploymentForm | undefined,
): string | undefined => {
  switch (type) {
    case "P":
      return "Tillsvidareanställning";
    case "H":
      return "Timanställning";
    case "S":
      return "Vikarie";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export type EmploymentTitle = "S" | "C" | "A" | "N" | "%future added value";

export const TranslateEmploymentTitle = (
  type: EmploymentTitle | undefined,
): string | undefined => {
  switch (type) {
    case "S":
      return "Servicebiträde";
    case "C":
      return "Vårdbiträde";
    case "A":
      return "Undersköterska";
    case "N":
      return "Sjuksköterska";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export type ShiftPreferenceType = "N" | "B" | "F" | "M" | "%future added value";

export const TranslateShiftPreferenceType = (
  type: ShiftPreferenceType | undefined,
): string | undefined => {
  switch (type) {
    case "N":
      return "Ingen preferens";
    case "B":
      return "Balans";
    case "F":
      return "Få men långa";
    case "M":
      return "Många men korta";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export type MembersType = NN<MemberList_fragment$data>;

export type MemberType = Unwrap<MembersType>;

export type CompetenceType = ExtractNode<NN<MemberType["competences"]>>;

export type TeamType = ExtractNode<NN<MemberType["memberOf"]>>;

export type MemberTeamType = {
  id: string;
  name: string;
  color: string;
};

export type MemberTeamGroupType = {
  id: string;
  name: string;
  teams?: MemberTeamType[];
};

export type Member = MemberType & {
  team?: MemberTeamType;
  teamGroup: MemberTeamGroupType;
  teams: MemberTeamType[];
  teamGroups: MemberTeamGroupType[];
};

export type TeamFormFields = {
  id?: string;
  name: string;
  color: string;
};

export type TeamGroupFormFields = {
  id?: string;
  name: string;
};

// Note! This file contains sensitive business logic. If you change any logic here,
// make sure you change the corresponding business logic files on optimization and client.

import { DateTime } from "luxon";

import type {
  ShiftInput as Shift,
  ShiftPartInput as ShiftPart,
} from "components/shifts/types";
import { shiftPartTypes } from "components/shifts/types";
import { shiftDayTypes, TranslateShiftDayType } from "components/shifts/types";

type DayTypeBreaks = {
  morningStartsNightEnds: string;
  eveningStartsMorningEnds: string;
  nightStartsEveningEnds: string;
  fullDayDuration: number;
};

type partDuration = {
  dayType: shiftDayTypes;
  duration: number;
};

/** Helper function for calculating duration (in minutes) between 2 times. */
export function calculateTimeDuration(start: string, end: string): number {
  const arbitraryDay = "2024-06-26 ";
  const format = "yyyy-MM-dd HH:mm";
  const from = DateTime.fromFormat(arbitraryDay.concat(start), format);
  let to = DateTime.fromFormat(arbitraryDay.concat(end), format);
  if (!from.isValid || !to.isValid) {
    return 0;
  }
  if (to < from) {
    to = to.plus({ days: 1 });
  }
  const duration = to.diff(from);
  return duration.as("minutes");
}

/** Helper function for calculating duration (in hours) between 2 'hours from midnight'. */
function getHoursDiff(from: number, to: number): number {
  if (to < from) {
    return 24 - from + to;
  }
  return to - from;
}

/** Logic for deriving the worktime of a shift part. */
export function derivePartWorktime(part: ShiftPart) {
  if (part.partType === shiftPartTypes.BILAGA_J_JOUR) {
    return calculateTimeDuration(part.start, part.end) / 2;
  }
  if (part.partType === shiftPartTypes.AB_JOUR) {
    return 0;
  }
  if (part.partType === shiftPartTypes.UNCOMPENSATED_BREAK) {
    return 0;
  }
  return calculateTimeDuration(part.start, part.end);
}

/** Logic for deriving the worktime of a shift with parts. */
export function deriveWorktime(shift: Shift) {
  const baseWorktime =
    calculateTimeDuration(shift.start, shift.end) - shift.breakTime;
  const partsSubtractableWorktime = (shift?.shiftParts || []).reduce(
    (acc: number, part: ShiftPart) =>
      acc +
      calculateTimeDuration(part.start, part.end) -
      derivePartWorktime(part),
    0,
  );
  return baseWorktime - partsSubtractableWorktime;
}

/** Determines whether 'check_time' is between 'begin_time' and 'end_time'. */
function isTimeBetween(
  beginTime: number,
  endTime: number,
  checkTime: number,
): boolean {
  if (beginTime < endTime) {
    return checkTime >= beginTime && checkTime < endTime;
  }
  return checkTime >= beginTime || checkTime < beginTime;
}

/** Determines which ShiftDayType 'timeInstance' is in. */
function timeInDayPart(
  timeInstance: number,
  morning: number,
  evening: number,
  night: number,
): shiftDayTypes {
  if (isTimeBetween(morning, evening, timeInstance)) {
    return shiftDayTypes.DAY;
  }
  if (isTimeBetween(evening, night, timeInstance)) {
    return shiftDayTypes.EVENING;
  }
  if (isTimeBetween(night, morning, timeInstance)) {
    return shiftDayTypes.NIGHT;
  }
  throw new Error("ShiftDayType is improperly defined.");
}

/** Takes a time string like 18:30:00 and returns the hours from midnight (18.5) */
function asHoursFromMidnight(t: string, format = "HH:mm:ss") {
  const dateTime = DateTime.fromFormat(t, format);
  return dateTime.hour + dateTime.minute / 60;
}

/** Logic for deriving the start and end of 'dayType'. */
function calculateDayTypeTimes(
  dayType: shiftDayTypes,
  morning: number,
  evening: number,
  night: number,
) {
  if (dayType === shiftDayTypes.DAY) {
    return { start: morning, end: evening };
  }
  if (dayType === shiftDayTypes.EVENING) {
    return { start: evening, end: night };
  }
  if (dayType === shiftDayTypes.NIGHT) {
    return { start: night, end: morning };
  }
  throw new Error("ShiftDayType is improperly defined.");
}

/** Logic for deriving the following day type after 'dayType'. */
function getNextDayType(dayType: shiftDayTypes) {
  if (dayType === shiftDayTypes.DAY) {
    return shiftDayTypes.EVENING;
  }
  if (dayType === shiftDayTypes.EVENING) {
    return shiftDayTypes.NIGHT;
  }
  return shiftDayTypes.DAY;
}

/** Logic for deriving the day type of a shift. */
export function calculateShiftDayType<
  S extends { readonly start: string; readonly end: string },
>(shift: S, dayTypeBreakpoints: DayTypeBreaks): shiftDayTypes | null {
  if (!shift?.start) {
    return null;
  }

  // Get settings
  const {
    morningStartsNightEnds,
    eveningStartsMorningEnds,
    nightStartsEveningEnds,
    fullDayDuration,
  } = dayTypeBreakpoints;

  // Handle very long shifts, which will be classified as FULL_DAY
  const duration = calculateTimeDuration(shift.start, shift.end);
  if (duration >= fullDayDuration) {
    return shiftDayTypes.FULL_DAY;
  }

  // Determine the start and end of DAY, EVENING, NIGHT
  const mo = asHoursFromMidnight(morningStartsNightEnds);
  const ev = asHoursFromMidnight(eveningStartsMorningEnds);
  const ni = asHoursFromMidnight(nightStartsEveningEnds);
  if (!mo || !ev || !ni) {
    return null;
  }

  // Find out in what ShiftDayType 'start' and 'end' occur in
  const start = asHoursFromMidnight(shift.start, "HH:mm");
  const end = asHoursFromMidnight(shift.end, "HH:mm");
  const startType = timeInDayPart(start, mo, ev, ni);
  const endType = timeInDayPart(end, mo, ev, ni);

  // We start by checking the simplest case where start_type == end_type.
  // Note! This ususally means that we can return 'start_type', but not for
  // very long shifts that may span over other day types as well.
  if (startType === endType) {
    const dayTypeTimes = calculateDayTypeTimes(startType, mo, ev, ni);
    if (
      (start < end && dayTypeTimes.start < dayTypeTimes.end) ||
      !(start < dayTypeTimes.end && end > dayTypeTimes.start)
    ) {
      return startType;
    }
  }

  // We need to derive the duration of the overlap with each day type
  // and than choose the one with longest overlap.
  const startPartDuration = {
    dayType: startType,
    duration: getHoursDiff(
      start,
      calculateDayTypeTimes(startType, mo, ev, ni)?.end,
    ),
  };
  const partDurations = [startPartDuration];

  // Here, we derive the duration of the overlap with each day
  // type and then choose the one with longest overlap.
  if (endType !== getNextDayType(startType)) {
    const middleParts = [
      shiftDayTypes.DAY,
      shiftDayTypes.EVENING,
      shiftDayTypes.NIGHT,
    ].filter((v) => v !== startType && v !== endType);

    middleParts.map((mp) =>
      partDurations.push({
        dayType: mp,
        duration: getHoursDiff(
          calculateDayTypeTimes(mp, mo, ev, ni)?.start,
          calculateDayTypeTimes(mp, mo, ev, ni)?.end,
        ),
      }),
    );
  }

  // Add the end part to part_durations
  const endPartDuration = {
    dayType: endType,
    duration: getHoursDiff(
      calculateDayTypeTimes(endType, mo, ev, ni)?.start,
      end,
    ),
  };
  partDurations.push(endPartDuration);

  // If there are duplicates, group them. This can happen for shifts that
  // start and end in the same day type, but not in the same day.
  const groupedPartDurations: partDuration[] = [];
  partDurations.reduce(function (res: any, value: partDuration) {
    if (!res[value.dayType]) {
      res[value.dayType] = { dayType: value.dayType, duration: 0 };
      groupedPartDurations.push(res[value.dayType]);
    }
    res[value.dayType].duration += value.duration;
    return res;
  }, {});

  // Find the part with the longest duration
  const longestDayType = groupedPartDurations.reduce((prev, current) =>
    prev && prev.duration < current.duration ? current : prev,
  );

  return longestDayType.dayType;
}

/** Derive shift day type label. */
export function deriveShiftDayType<S extends { start: string; end: string }>(
  shift: S,
  dayTypeBreakpoints: DayTypeBreaks,
): string {
  if (!shift?.start) {
    return TranslateShiftDayType(shiftDayTypes.DAY);
  }

  const dayType = calculateShiftDayType(shift, dayTypeBreakpoints);
  if (dayType === null) {
    return "-";
  }

  return TranslateShiftDayType(dayType);
}

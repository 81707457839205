import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { connectionToArray } from "relay-help/arrays";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummySubmit,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { useUpdateSettingsMutation } from "components/setting/team_group/setting_boxes/updateSettings";

import { weightChoices } from "../../components/setting/team_group/setting_boxes/constants";
import { TeamDemandSetting } from "../types";

import {
  BaseDemandSettingTeamGroup_fragment$data as TeamGroupData,
  BaseDemandSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/BaseDemandSettingTeamGroup_fragment.graphql";
import { BaseDemandForm } from "./BaseDemandForm";
import { BaseDemandInfo } from "./BaseDemandInfo";

const teamGroupFragment = graphql`
  fragment BaseDemandSettingTeamGroup_fragment on SettingNode {
    id
    group {
      teams {
        edges {
          node {
            teamDemandSetting {
              id
              allowUnderstaffing
              understaffingWeight
              weekendUnderstaffingWeight
              overstaffingWeight
              weekendOverstaffingWeight
              team {
                id
                name
                color
              }
            }
          }
        }
      }
    }
  }
`;

const SETTING_NAME = "Grundbehov";
const MODULE_NAME = "BaseDemand";
const SETTING_URL_ID = "base-demand";

type BaseDemandFormValues = {
  id: string;
  teamDemandSettings?: Array<TeamDemandSetting>;
};

const weightSchema = yup.string().oneOf(weightChoices, "Ogiltig vikt");

const teamDemandSettingSchema = yup.object().shape({
  id: yup.string().required("Måste anges"),
  allowUnderstaffing: yup.boolean().required("Måste anges"),
  understaffingWeight: weightSchema.required("Måste anges"),
  weekendUnderstaffingWeight: weightSchema.required("Måste anges"),
  overstaffingWeight: weightSchema.required("Måste anges"),
});

const validationSchema = yup.object().shape({
  id: yup.string().required("Måste anges"),
  teamDemandSettings: yup
    .array()
    .of(teamDemandSettingSchema)
    .required("Måste anges"),
});

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): BaseDemandFormValues {
  return {
    id: teamGroupData.id,
    teamDemandSettings: connectionToArray(teamGroupData.group?.teams).map(
      (x) => x.teamDemandSetting,
    ),
  };
}

function useSubmitFunctions() {
  const [updateSettings] = useUpdateSettingsMutation();

  const onSubmitTeamGroup = useCallback(
    (input: BaseDemandFormValues) => {
      const convertedInput = {
        ...input,
        teamDemandSettings: (input.teamDemandSettings || []).map((setting) => ({
          id: setting?.id || "",
          allowUnderstaffing: setting?.allowUnderstaffing || false,
          understaffingWeight: setting?.understaffingWeight || "M",
          weekendUnderstaffingWeight:
            setting?.weekendUnderstaffingWeight || "M",
          overstaffingWeight: setting?.overstaffingWeight || "M",
          weekendOverstaffingWeight: setting?.weekendOverstaffingWeight || "M",
        })),
      };

      updateSettings({ variables: { input: convertedInput } });
    },
    [updateSettings],
  );

  return {
    onSubmitTeamGroup,
    onSubmitUser: dummySubmit,
    onSubmitRuleGroup: dummySubmit,
  };
}

function renderComponent(
  initialValues: BaseDemandFormValues,
  onSubmit: (formValues: BaseDemandFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <BaseDemandForm />
    </BaseSettings>
  );
}

export const baseDemandSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  BaseDemandFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions,
  renderComponent,
  InfoComponent: BaseDemandInfo,
};

import type { CSSObject } from "@mui/material";
import { Collapse, Stack, Typography } from "@mui/material";

import ErrorMarginSelect from "./ErrorMarginSelect";

type Props = {
  visible: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  sx?: CSSObject;
};

export default function AllowedErrorMargin({
  visible,
  disabled,
  readOnly,
  sx = {},
}: Props) {
  return (
    <Collapse in={visible}>
      <Stack gap={1} sx={sx}>
        <Typography variant="h4">Tillåten felmarginal</Typography>
        <Typography>
          Om fördelningen av Dag / Kväll / Natt är mjuk så kan du här sätta en
          övre gräns för hur stor felmarginalen får lov att vara. Om du väljer
          alternativet <i>Efter bästa förmåga</i> så används ingen sådan hård
          övre gräns på felmarginalen.
        </Typography>
        <ErrorMarginSelect
          name="shiftDayTypeAllowedErrorMargin"
          disabled={disabled || !visible}
          readOnly={readOnly}
        />
      </Stack>
    </Collapse>
  );
}

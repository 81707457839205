import { DateTime, Duration } from "luxon";

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function padThousands(num: number) {
  return Math.round(num).toLocaleString();
}

export function formatDate(date: Date) {
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
}

export function formatDateTime(date: Date) {
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd HH:mm");
}

/** Returns minutes as HH:MM. Default 'showSign' value is 'onlyMinus'. */
export function minutes2HHMM(
  minutes: number,
  showSign: "onlyMinus" | "plusMinus" = "onlyMinus",
) {
  const sign = minutes === 0 ? "" : minutes < 0 ? "-" : "+";
  const dt = Duration.fromObject({
    minutes: Math.abs(Math.round(minutes)),
  }).toFormat("hh:mm");
  if (showSign === "plusMinus") {
    return `${sign}${dt}`;
  }
  if (showSign === "onlyMinus" && sign === "-") {
    return `-${dt}`;
  }
  return dt;
}

export function seconds2HHMMSS(num: number, showSign: boolean = true) {
  const sign = num === 0 ? "" : num < 0 ? "-" : "+";
  const dt = Duration.fromObject({ seconds: Math.abs(num) }).toFormat(
    "hh:mm:ss",
  );
  return `${showSign ? sign : ""}${dt}`;
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import type { SelectorStoreUpdater } from "relay-runtime";

import { DeleteTeamMutation as Mutation } from "./types";

const mutation = graphql`
  mutation DeleteTeamMutation($id: ID!) {
    deleteTeam(input: { id: $id }) {
      ok
      deletedId @deleteRecord
    }
  }
`;

const updater: SelectorStoreUpdater<Mutation["response"]> = (store) => {
  // with the @deleteRecord directive, the record is deleted from the store and be null in the list
  const root = store.getRoot();
  const allTeamsRecords = root.getLinkedRecords("allTeams");
  if (allTeamsRecords) {
    // remove null records
    root.setLinkedRecords(allTeamsRecords.filter(Boolean), "allTeams");
  }
  const teamsRecords = root.getLinkedRecords("teams");
  if (teamsRecords) {
    // remove null records
    root.setLinkedRecords(teamsRecords.filter(Boolean), "teams");
  }
};

export const useDeleteTeam = () => useMutation<Mutation>(mutation, { updater });

import { styled } from "@mui/material/styles";

import { drawerWidth as DEFAULT_DRAWER_WIDTH } from "./constants";

type Props = {
  open: boolean;
  drawerWidth?: number;
};

const shouldForwardProp = (prop: string) =>
  prop !== "open" && prop !== "drawerWidth";

const DrawerSibling = styled("div", {
  shouldForwardProp,
})<Props>(({ theme, open, drawerWidth = DEFAULT_DRAWER_WIDTH }) => ({
  flexGrow: 1,
  width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

export default DrawerSibling;

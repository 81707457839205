/**
 * @generated SignedSource<<5e9b3a275043126d645626a758e045fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentTeamGroup_teamgroups$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "CurrentTeamGroup_teamgroups";
}>;
export type CurrentTeamGroup_teamgroups$key = ReadonlyArray<{
  readonly " $data"?: CurrentTeamGroup_teamgroups$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentTeamGroup_teamgroups">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CurrentTeamGroup_teamgroups",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "TeamGroupNode",
  "abstractKey": null
};

(node as any).hash = "b9fba108de5cd82dbb00fbcd46ba2f7a";

export default node;

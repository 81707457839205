import { useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { OptimizationSetting } from "settings/common/optimizationSetting";

import { ExceptionViewHeader } from "./ExceptionViewHeader";

type Props = {
  name: string;
  settingModules: ReadonlyArray<string>;
  settingsId: string;
  allowedSettingsList: ReadonlyArray<
    OptimizationSetting<any, any, any, any, any, any, any>
  >;
  modifySettings: (settings: {
    id: string;
    constraintModules: string[];
  }) => void;
  renderExceptionBox: (
    setting: OptimizationSetting<any, any, any, any>,
  ) => React.ReactNode;
};

export function SettingsExceptions({
  name,
  settingModules,
  settingsId,
  allowedSettingsList,
  modifySettings,
  renderExceptionBox,
}: Props) {
  const { settingsWithExceptions, settingsWithoutExceptions } = useMemo(() => {
    const settingModuleSet = new Set(settingModules);
    const settingsWithExceptions: OptimizationSetting<any, any, any, any>[] =
      [];
    const settingsWithoutExceptions: OptimizationSetting<any, any, any, any>[] =
      [];

    allowedSettingsList.forEach((setting) =>
      (settingModuleSet.has(setting.moduleName)
        ? settingsWithExceptions
        : settingsWithoutExceptions
      ).push(setting),
    );
    return { settingsWithExceptions, settingsWithoutExceptions };
  }, [settingModules, allowedSettingsList]);

  const settingModulesWithoutExceptions = useMemo(
    () =>
      settingsWithoutExceptions.map(({ name, moduleName }) => ({
        settingName: name,
        moduleName,
      })),
    [settingsWithoutExceptions],
  );

  const addException = useCallback(
    (settingModule: string) => {
      const newConstraintModules = [...settingModules, settingModule];

      modifySettings({
        id: settingsId,
        constraintModules: newConstraintModules,
      });
    },
    [settingModules, settingsId, modifySettings],
  );

  const exceptionBoxes = useMemo(
    () => settingsWithExceptions.map(renderExceptionBox),
    [settingsWithExceptions, renderExceptionBox],
  );

  return (
    <Stack
      gap={2}
      width="100%"
      sx={{
        p: {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 3,
          xl: 4,
        },
      }}
    >
      <ExceptionViewHeader
        name={name}
        options={settingModulesWithoutExceptions}
        addException={addException}
      />
      {exceptionBoxes}
    </Stack>
  );
}

import { useState } from "react";
import useInterval from "hooks/useInterval";
import { DateTime, Duration } from "luxon";

type Props = {
  since?: number;
};

export default function TimeTicker({ since }: Props) {
  const computeDiff = () => (since ? DateTime.now().toMillis() - since : 0);
  const [diff, setDiff] = useState(computeDiff());

  // Update every second
  useInterval(() => setDiff(computeDiff()), 1000);

  const str = Duration.fromObject({ milliseconds: diff }).toFormat("hh:mm:ss");

  return since ? <span>{str}</span> : null;
}

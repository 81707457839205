import { Stack, Typography } from "@mui/material";
import { ErrorMessage } from "formik";

import DayShiftDistributionSum from "./DayShiftDistributionSum";
import PercentInputField from "./PercentInputField";
import useShiftNumberDistribution from "./useShiftNumberDistribution";

type Props = {
  isActive: boolean;
  totalShifts: number;
  disabled?: boolean;
  readOnly?: boolean;
};

export default function ShareInput({
  isActive,
  totalShifts,
  readOnly,
  disabled,
}: Props) {
  const labelSx = { width: 65 };
  const fieldSx = { width: 110 };
  const columnGap = 5;

  // Get percentages and shifts
  const percentages = useShiftNumberDistribution(totalShifts);

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={columnGap} alignItems="center">
        <Typography variant="caption" sx={labelSx}>
          Dagspass
        </Typography>
        <PercentInputField
          name="dayShiftDistributionShare"
          disabled={disabled || !isActive}
          readOnly={readOnly}
          sx={fieldSx}
        />
        {isActive && (
          <Typography sx={labelSx}>
            {percentages.find((i) => i.id === "day")?.shifts ?? 0} pass
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap={columnGap} alignItems="center">
        <Typography variant="caption" sx={labelSx}>
          Kvällspass
        </Typography>
        <PercentInputField
          name="eveningShiftDistributionShare"
          disabled={disabled || !isActive}
          readOnly={readOnly}
          sx={fieldSx}
        />
        {isActive && (
          <Typography sx={labelSx}>
            {percentages.find((i) => i.id === "evening")?.shifts ?? 0} pass
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap={columnGap} alignItems="center">
        <Typography variant="caption" sx={labelSx}>
          Nattpass
        </Typography>
        <PercentInputField
          name="nightShiftDistributionShare"
          disabled={disabled || !isActive}
          readOnly={readOnly}
          sx={fieldSx}
        />
        {isActive && (
          <Typography sx={labelSx}>
            {percentages.find((i) => i.id === "night")?.shifts ?? 0} pass
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap={columnGap} alignItems="center">
        <Typography variant="caption" sx={labelSx}>
          Dygnspass
        </Typography>
        <PercentInputField
          name="fullDayShiftDistributionShare"
          disabled={disabled || !isActive}
          readOnly={readOnly}
          sx={fieldSx}
        />
        {isActive && (
          <Typography sx={labelSx}>
            {percentages.find((i) => i.id === "fullday")?.shifts ?? 0} pass
          </Typography>
        )}
      </Stack>

      <Stack direction="row" gap={columnGap} alignItems="center">
        {isActive && (
          <Stack sx={labelSx}>
            <Typography variant="caption">Totalt</Typography>
          </Stack>
        )}
        {isActive && (
          <Stack sx={fieldSx}>
            <DayShiftDistributionSum />
          </Stack>
        )}
        {isActive && (
          <Stack sx={labelSx} direction="row">
            <Typography>{totalShifts} pass</Typography>
          </Stack>
        )}
      </Stack>

      {isActive && (
        <Stack direction="row" gap={columnGap} alignItems="center">
          <Stack sx={labelSx} />
          <Stack>
            <ErrorMessage name="id">
              {(msg: string) => (
                <Typography variant="caption" color="error">
                  {msg}
                </Typography>
              )}
            </ErrorMessage>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

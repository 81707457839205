import { Box, FormControlLabel, Stack, Switch } from "@mui/material";

import type { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";

import DateItem from "./DateItem";
import RuleItem from "./RuleItem";

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
  durationMs: number;
};

export default function RuleOrDateItem({ value, onChange, durationMs }: Props) {
  function handleExludeChange(checked: boolean) {
    onChange({ ...value, exclude: checked });
  }
  return (
    <Stack>
      <Box>
        <FormControlLabel
          control={<Switch />}
          label={
            value.exclude
              ? "Exkludera dessa händelser från upprepningsregeln"
              : "Inkludera dessa händelser i upprepningsregeln"
          }
          value={value.exclude}
          onChange={(_e, b) => handleExludeChange(b)}
        />
      </Box>
      {value.isDate ? (
        <DateItem value={value} onChange={onChange} />
      ) : (
        <RuleItem value={value} onChange={onChange} durationMs={durationMs} />
      )}
    </Stack>
  );
}

import { PropsWithChildren, useMemo } from "react";
import { GraphQLTaggedNode, useFragment } from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { TeamGroupSettingsQuery$data as TeamGroupData } from "pages/Settings/__generated__/TeamGroupSettingsQuery.graphql";
import { SettingExceptionBox } from "pages/Settings/SettingExceptionBox";
import { TeamGroupUser, TeamGroupUsers } from "pages/types";

import {
  SettingExceptionsRuleGroup_fragment$data as RuleGroupData,
  SettingExceptionsRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/SettingExceptionsRuleGroup_fragment.graphql";
import {
  SettingExceptionsUser_fragment$data as UserData,
  SettingExceptionsUser_fragment$key as UserKey,
} from "./__generated__/SettingExceptionsUser_fragment.graphql";
import {
  RuleGroupSettingExceptionsHeader,
  UserSettingExceptionsHeader,
} from "./SettingExceptionsHeader";
import {
  RuleGroupSettingExceptionsRemoveButton,
  UserSettingExceptionsRemoveButton,
} from "./SettingExceptionsRemoveButton";

export const userExceptionsFragment = graphql`
  fragment SettingExceptionsUser_fragment on UserNode @relay(plural: true) {
    id
    fullName
    userSetting {
      id
      settingModules
    }
  }
`;

export const ruleGroupExceptionsFragment = graphql`
  fragment SettingExceptionsRuleGroup_fragment on RuleGroupNode
  @relay(plural: true) {
    id
    name
    ruleGroupSetting {
      id
      settingModules
    }
  }
`;

type Props = {
  header: React.ReactNode;
};

function BaseSettingExceptions({ header, children }: PropsWithChildren<Props>) {
  return (
    <Stack gap={1}>
      {header}
      {children}
    </Stack>
  );
}

type BaseProps<V extends KeyType | undefined> = {
  renderSettingComponent: (
    props: KeyTypeData<NonNullable<V>>,
  ) => React.ReactNode;
  settingName: string;
  settingModule: string;
};

type UserSettingExceptionsProps<V extends KeyType | undefined> =
  BaseProps<V> & {
    exceptions: TeamGroupUsers;
    userFragment: GraphQLTaggedNode;
    userFragmentRef: UserKey;
  };

export function UserSettingExceptions<V extends KeyType | undefined>({
  exceptions,
  userFragment,
  renderSettingComponent,
  settingName,
  settingModule,
  userFragmentRef,
}: UserSettingExceptionsProps<V>) {
  const userExceptionsData = useFragment(
    userExceptionsFragment,
    userFragmentRef,
  );
  const userSettingsById = useMemo(() => {
    return userExceptionsData.reduce(
      (acc, user) => {
        if (user?.userSetting != null) {
          acc[user.id] = user.userSetting;
        }
        return acc;
      },
      {} as Record<string, NonNullable<UserData[number]["userSetting"]>>,
    );
  }, [userExceptionsData]);

  return (
    <BaseSettingExceptions
      header={
        <UserSettingExceptionsHeader
          settingModule={settingModule}
          fragmentRef={userFragmentRef}
        />
      }
    >
      <Stack gap={2}>
        {exceptions.map((exception, i) => (
          <UserSettingException
            key={`${exception.id}-i`}
            exception={exception}
            renderSettingComponent={renderSettingComponent}
            fragment={userFragment}
            settingName={settingName}
            settingModule={settingModule}
            userSetting={userSettingsById[exception.id] || {}}
          />
        ))}
      </Stack>
    </BaseSettingExceptions>
  );
}

type UserSettingExceptionProps<V extends KeyType | undefined> = BaseProps<V> & {
  exception: TeamGroupUser;
  fragment: GraphQLTaggedNode;
  userSetting?: NonNullable<UserData[number]["userSetting"]>;
};
function UserSettingException<V extends KeyType | undefined>({
  exception,
  fragment,
  renderSettingComponent,
  settingName,
  settingModule,
  userSetting,
}: UserSettingExceptionProps<V>) {
  const userSettingData = useFragment(fragment, exception?.userSetting);

  return (
    <SettingExceptionBox
      title={`Avvikande ${settingName} för ${exception.fullName}`}
      removeExceptionButton={
        <UserSettingExceptionsRemoveButton
          settingModule={settingModule}
          userSetting={userSetting}
        />
      }
    >
      {renderSettingComponent(userSettingData)}
    </SettingExceptionBox>
  );
}

type RuleGroupSettingExceptionsProps<V extends KeyType | undefined> =
  BaseProps<V> & {
    exceptions: TeamGroupData["ruleGroups"];
    ruleGroupFragment: GraphQLTaggedNode;
    ruleGroupFragmentRef: RuleGroupKey;
  };

export function RuleGroupSettingExceptions<V extends KeyType | undefined>({
  exceptions,
  ruleGroupFragment,
  renderSettingComponent,
  settingName,
  settingModule,
  ruleGroupFragmentRef,
}: RuleGroupSettingExceptionsProps<V>) {
  const ruleGroupExceptionsData = useFragment(
    ruleGroupExceptionsFragment,
    ruleGroupFragmentRef,
  );

  const ruleGroupSettingsById = useMemo(() => {
    return ruleGroupExceptionsData.reduce(
      (acc, ruleGroup) => {
        if (ruleGroup.ruleGroupSetting != null) {
          acc[ruleGroup.id] = ruleGroup.ruleGroupSetting;
        }
        return acc;
      },
      {} as Record<
        string,
        NonNullable<RuleGroupData[number]["ruleGroupSetting"]>
      >,
    );
  }, [ruleGroupExceptionsData]);

  return (
    <BaseSettingExceptions
      header={
        <RuleGroupSettingExceptionsHeader
          settingModule={settingModule}
          fragmentRef={ruleGroupFragmentRef}
        />
      }
    >
      <Stack gap={2}>
        {exceptions.map((exception, i) => (
          <RuleGroupSettingException
            key={`${exception.id}-i`}
            exception={exception}
            renderSettingComponent={renderSettingComponent}
            fragment={ruleGroupFragment}
            settingName={settingName}
            settingModule={settingModule}
            ruleGroupSetting={ruleGroupSettingsById[exception.id] || {}}
          />
        ))}
      </Stack>
    </BaseSettingExceptions>
  );
}

type RuleGroupSettingExceptionProps<V extends KeyType | undefined> =
  BaseProps<V> & {
    exception: TeamGroupData["ruleGroups"][number];
    fragment: GraphQLTaggedNode;
    ruleGroupSetting?: NonNullable<RuleGroupData[number]["ruleGroupSetting"]>;
  };
function RuleGroupSettingException<V extends KeyType | undefined>({
  exception,
  fragment,
  renderSettingComponent,
  settingName,
  settingModule,
  ruleGroupSetting,
}: RuleGroupSettingExceptionProps<V>) {
  const ruleGroupData = useFragment(fragment, exception.ruleGroupSetting);

  return (
    <SettingExceptionBox
      title={`Avvikande ${settingName} för ${exception.name}`}
      removeExceptionButton={
        <RuleGroupSettingExceptionsRemoveButton
          settingModule={settingModule}
          ruleGroupSetting={ruleGroupSetting}
        />
      }
    >
      {renderSettingComponent(ruleGroupData)}
    </SettingExceptionBox>
  );
}

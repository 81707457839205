import { useCallback } from "react";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { GraphQLTaggedNode } from "relay-runtime";

import { useUpdateRuleGroupSettingsMutation } from "components/setting/team_group/setting_boxes/updateRuleGroupSettings";
import { useUpdateSettingsMutation } from "components/setting/team_group/setting_boxes/updateSettings";
import { useUpdateUserSettingsMutation } from "components/setting/team_group/setting_boxes/updateUserSettings";
import { UpdateSettingInput } from "components/setting/types";

export type GenericOptimizationSetting = OptimizationSetting<
  any,
  any,
  any,
  any,
  any,
  any,
  any
>;

export type TeamGroupOnly<
  TeamGroupKey extends KeyType,
  TeamGroupSettingFormValues,
  AdditionalData,
> = {
  convertTeamGroupDataToInitialValues: (
    data: KeyTypeData<TeamGroupKey>,
  ) => TeamGroupSettingFormValues;
  useSubmitTeamGroupOnly: () => (data: TeamGroupSettingFormValues) => void;
  renderComponent: (
    initialValues: TeamGroupSettingFormValues,
    onSubmit: (formValues: TeamGroupSettingFormValues) => void,
    additionalData: AdditionalData,
  ) => React.ReactNode;
};

export type UserOnly<UserKey extends KeyType, UserSettingFormValues> = {
  convertUserDataToInitialValues: (
    data: KeyTypeData<UserKey>,
  ) => UserSettingFormValues;
  useSubmitUserOnly: () => (data: UserSettingFormValues) => void;
  renderComponent: (
    initialValues: UserSettingFormValues,
    onSubmit: (formValues: UserSettingFormValues) => void,
  ) => React.ReactNode;
};

export type OptimizationSetting<
  TeamGroupKey extends KeyType,
  UserKey extends KeyType | undefined,
  RuleGroupKey extends KeyType | undefined,
  SettingFormValues,
  AdditionalData = {},
  TeamGroupSettingFormValues = undefined,
  UserSettingFormValues = undefined,
> = {
  name: string;
  moduleName: string;
  urlId: string;
  teamGroupFragment: GraphQLTaggedNode;
  userSettingFragment: UserKey extends KeyType ? GraphQLTaggedNode : undefined;
  convertTeamGroupDataToInitialValues: (
    data: KeyTypeData<TeamGroupKey>,
  ) => SettingFormValues;
  convertUserDataToInitialValues: UserKey extends KeyType
    ? (data: KeyTypeData<UserKey>) => SettingFormValues
    : undefined;
  useAdditionalDataForTeamGroup: (
    teamGroupFragmentRef: TeamGroupKey,
  ) => AdditionalData;
  useSubmitFunctions: () => {
    onSubmitTeamGroup: (data: SettingFormValues) => void;
    onSubmitUser: (data: SettingFormValues) => void;
    onSubmitRuleGroup: (data: SettingFormValues) => void;
  };
  renderComponent: (
    initialValues: SettingFormValues,
    onSubmit: (values: SettingFormValues) => void,
    additionalData: AdditionalData,
  ) => React.ReactNode;
  InfoComponent?: React.ComponentType;
  teamGroupOnly?: TeamGroupSettingFormValues extends undefined
    ? undefined
    : TeamGroupOnly<TeamGroupKey, TeamGroupSettingFormValues, AdditionalData>;
  userOnly?: UserKey extends KeyType
    ? UserSettingFormValues extends undefined
      ? undefined
      : UserOnly<UserKey, UserSettingFormValues>
    : undefined;
  ruleGroups: RuleGroupKey extends KeyType
    ? {
        ruleGroupFragment: GraphQLTaggedNode;
        convertDataToInitialValues: (
          data: KeyTypeData<RuleGroupKey>,
        ) => SettingFormValues;
      }
    : undefined;
};

export const dummyUseAdditionalDataForTeamGroup = () => {
  return {};
};
export const dummySubmit = () => {
  const isDev = process.env.NODE_ENV === "development";
  if (isDev) {
    console.warn("Ignoring setting submission");
  }
};

export function defaultConvertTeamGroupDataToInitialValues<
  TeamGroupData extends SettingFormValues,
  SettingFormValues,
>(teamGroupData: TeamGroupData): SettingFormValues {
  return {
    ...teamGroupData,
  };
}

export function defaultConvertUserDataToInitialValues<
  UserData extends SettingFormValues,
  SettingFormValues,
>(userData: UserData): SettingFormValues {
  return {
    ...userData,
  };
}

export function defaultConvertDataToInitialValues<
  Data extends SettingFormValues,
  SettingFormValues,
>(data: Data): SettingFormValues {
  return {
    ...data,
  };
}

export function useDefaultTeamGroupSubmitFunction<
  SettingFormValues extends UpdateSettingInput,
>() {
  const [updateSettings] = useUpdateSettingsMutation();
  const onSubmitTeamGroup = useCallback(
    (input: SettingFormValues) => updateSettings({ variables: { input } }),
    [updateSettings],
  );

  return onSubmitTeamGroup;
}

export function useDefaultUserSubmitFunction<
  SettingFormValues extends UpdateSettingInput,
>() {
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const onSubmitUser = useCallback(
    (input: SettingFormValues) => updateUserSettings({ variables: { input } }),
    [updateUserSettings],
  );

  return onSubmitUser;
}

export function useDefaultRuleGroupSubmitFunction<
  SettingFormValues extends UpdateSettingInput,
>() {
  const [updateRuleGroupSettings] = useUpdateRuleGroupSettingsMutation();
  const onSubmitRuleGroup = useCallback(
    (input: SettingFormValues) =>
      updateRuleGroupSettings({ variables: { input } }),
    [updateRuleGroupSettings],
  );

  return onSubmitRuleGroup;
}

export function useDefaultSubmitFunctions() {
  const onSubmitTeamGroup = useDefaultTeamGroupSubmitFunction();
  const onSubmitUser = useDefaultUserSubmitFunction();
  const onSubmitRuleGroup = useDefaultRuleGroupSubmitFunction();

  return { onSubmitTeamGroup, onSubmitUser, onSubmitRuleGroup };
}

export function useDefaultSubmitFunctionsOnlyTeamGroup() {
  const onSubmitTeamGroup = useDefaultTeamGroupSubmitFunction();

  return {
    onSubmitTeamGroup,
    onSubmitUser: dummySubmit,
    onSubmitRuleGroup: dummySubmit,
  };
}

import { useCallback, useMemo } from "react";
import type { ReactCalendarItemRendererProps } from "react-calendar-timeline";
import Timeline, { DateHeader, TimelineHeaders } from "react-calendar-timeline";
import type { CSSObject } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { useRCTResizer } from "hooks/useRCTResizer";
import resizeDetector from "libs/react-calendar-timeline/container-resize-detector";

import {
  shiftDayTypes,
  TranslateShiftDayType,
  TranslateShiftType,
} from "components/shifts/types";

import { TimelineItemRenderer } from "./TimelineItemRenderer";
import type { ShiftDayType, ShiftTimelineType } from "./types";
import { shiftTimelineTypeEnum } from "./types";

export type Group = {
  id: string;
  title: string;
};

export type TimelineItem = {
  id: number;
  group: Group["id"];
  start: Date;
  end: Date;
  breakTime: number;
  dayType: ShiftDayType;
  shiftType: ShiftTimelineType;
};

type InternalTimelineItem = {
  id: number;
  group: Group["id"];
  start_time: number;
  end_time: number;
  title: string;
  dayTypeTitle: string;
  dayType: ShiftDayType;
  breakTime: number;
  itemProps: {
    className: string;
  };
};

type Props = {
  items: TimelineItem[];
  groups: Group[];
  start: Date;
  end: Date;
  sx?: CSSObject;
};

function resolveClassName(shiftType: ShiftTimelineType, dayType: ShiftDayType) {
  let className = "";
  switch (shiftType) {
    case shiftTimelineTypeEnum.Z:
      className = "rct-shift-work";
      break;
    case shiftTimelineTypeEnum.P:
      className = "rct-shift-apt";
      break;
    case shiftTimelineTypeEnum.J:
      className = "rct-shift-bilagaj";
      break;
    case shiftTimelineTypeEnum.M:
      className = "rct-shift-abjour";
      break;
    default:
      className = "rct-shift-pass";
      break;
  }
  switch (dayType) {
    case shiftDayTypes.DAY:
      className += "-day";
      break;
    case shiftDayTypes.EVENING:
      className += "-evening";
      break;
    case shiftDayTypes.NIGHT:
      className += "-night";
      break;
    case shiftDayTypes.FULL_DAY:
      className += "-full";
      break;
    default:
      break;
  }
  return className;
}

function normalizeItem({
  id,
  group,
  start,
  end,
  breakTime,
  shiftType,
  dayType,
}: TimelineItem): InternalTimelineItem {
  return {
    id,
    group,
    title: TranslateShiftType(shiftType),
    start_time: start.valueOf(),
    end_time: end.valueOf(),
    dayTypeTitle: TranslateShiftDayType(dayType),
    dayType,
    breakTime,
    itemProps: { className: resolveClassName(shiftType, dayType) },
  };
}

export function TimelineRenderer({
  groups,
  items,
  start,
  end,
  sx = {},
}: Props) {
  useRCTResizer();

  const normalizedItems = useMemo<InternalTimelineItem[]>(
    () => items.map(normalizeItem),
    [items],
  );

  const renderSidebar = useCallback(
    ({ group }: { group: Group }) => (
      <Typography variant="caption">{group.title}</Typography>
    ),
    [],
  );
  const itemRenderer = useCallback(
    (props: ReactCalendarItemRendererProps<InternalTimelineItem>) => (
      <TimelineItemRenderer {...props} />
    ),
    [],
  );

  const defaultMin = start.valueOf();
  const defaultMax = end.valueOf();

  if (!groups?.length) return null;

  const height = 10 + 31 * groups.length;

  return (
    <Box sx={{ ...sx, height }}>
      <Timeline
        groups={groups}
        items={normalizedItems}
        visibleTimeStart={defaultMin}
        visibleTimeEnd={defaultMax}
        buffer={1}
        canChangeGroup={false}
        canMove={false}
        canResize={false}
        itemHeightRatio={0.5}
        sidebarWidth={112}
        itemRenderer={itemRenderer}
        groupRenderer={renderSidebar}
        resizeDetector={resizeDetector as any}
      >
        <TimelineHeaders>
          <Typography variant="caption" sx={{ textTransform: "capitalize" }}>
            <DateHeader unit="day" labelFormat="dddd" height={23} />
          </Typography>
        </TimelineHeaders>
      </Timeline>
    </Box>
  );
}

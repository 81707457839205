/**
 * @generated SignedSource<<dc4cc9efac8b99506602cbd6c11e4d9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiTeamGroupSettingSnapshotTimebankModeChoices = "S" | "T" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScheduleGroupSettingsViewer_fragment$data = {
  readonly comprestCanCutDaybreakAbjour: boolean;
  readonly comprestCanCutDaybreakBilagaj: boolean;
  readonly constraintModules: ReadonlyArray<string>;
  readonly constraintModulesBridge: ReadonlyArray<string>;
  readonly eveningStartsMorningEnds: string;
  readonly fullDayDuration: number;
  readonly id: string;
  readonly maxTimebankPeriod: number;
  readonly maxTimebankTotal: number;
  readonly minTimebankPeriod: number;
  readonly minTimebankTotal: number;
  readonly morningStartsNightEnds: string;
  readonly nightStartsEveningEnds: string;
  readonly offsetTimebankImbalance: boolean;
  readonly periodLengthWeeks: number;
  readonly schedule: {
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly color: string;
          readonly id: string;
          readonly name: string;
        } | null;
      } | null>;
    };
  };
  readonly shiftsEarlierEndNotOk: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly shiftsEarlierStartNotOk: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly shiftsLaterEndNotOk: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly shiftsLaterStartNotOk: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly showAdvancedSettings: boolean;
  readonly teamsExemptFromResourcePool: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly timebankMode: ApiTeamGroupSettingSnapshotTimebankModeChoices;
  readonly uniqueWeeks: boolean;
  readonly useDemandWeights: boolean;
  readonly useDutyDemand: boolean;
  readonly weekendEndsAt: string;
  readonly weekendEndsAtDay: number;
  readonly weekendStartsAt: string;
  readonly weekendStartsAtDay: number;
  readonly wrapAroundPeriodEnd: boolean;
  readonly " $fragmentType": "ScheduleGroupSettingsViewer_fragment";
};
export type ScheduleGroupSettingsViewer_fragment$key = {
  readonly " $data"?: ScheduleGroupSettingsViewer_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleGroupSettingsViewer_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ShiftSnapshotNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ShiftSnapshotNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleGroupSettingsViewer_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uniqueWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wrapAroundPeriodEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useDutyDemand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useDemandWeights",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAdvancedSettings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModulesBridge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "morningStartsNightEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningStartsMorningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightStartsEveningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendStartsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendStartsAtDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendEndsAtDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timebankMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offsetTimebankImbalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxTimebankPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minTimebankPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxTimebankTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minTimebankTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "shiftsLaterStartNotOk",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "shiftsLaterEndNotOk",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "shiftsEarlierStartNotOk",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "shiftsEarlierEndNotOk",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "teamsExemptFromResourcePool",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamSnapshotNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSnapshotNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleNode",
      "kind": "LinkedField",
      "name": "schedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comprestCanCutDaybreakBilagaj",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comprestCanCutDaybreakAbjour",
      "storageKey": null
    }
  ],
  "type": "TeamGroupSettingSnapshotNode",
  "abstractKey": null
};
})();

(node as any).hash = "a51293cec9e77e1f06dee14b2da43eb5";

export default node;

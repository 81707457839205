import ExpandIcon from "@mui/icons-material/ExpandMoreSharp";
import type { AccordionProps, AccordionSummaryProps } from "@mui/material";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { svart } from "styles/mui/light-palette";
import { useBooleanState } from "utils/useBooleanState";

import { ElementList } from "./ElementList";
import type { Section } from "./types";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} {...props} />
))(() => ({
  "&:before": {
    display: "none",
  },
  "&.Mui-expanded": {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(1),
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  minHeight: 0,
  borderRadius: 5,
  paddingRight: 5,
  paddingLeft: 12,
  "& .MuiAccordionSummary-content": {
    marginTop: 8,
    marginBottom: 8,
  },
}));

export function SectionAccordion({ section }: { section: Section }) {
  const { value: expanded, toggle: toggleExpanded } = useBooleanState(
    section.defaultExpanded,
  );

  return (
    <Accordion expanded={expanded} onChange={toggleExpanded} disableGutters>
      <AccordionSummary>
        <Stack gap={1} direction="row">
          {section?.icon}
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            textTransform="uppercase"
            fontWeight={650}
            fontSize={12}
            letterSpacing={1}
            color={svart[70]}
          >
            {section.title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <ElementList elements={section.elements} />
      </AccordionDetails>
    </Accordion>
  );
}

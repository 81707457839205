import type { PaletteOptions } from "@mui/material";
import { createTheme } from "@mui/material";

const t = createTheme();
function transformColorObject(obj: Record<string, string>) {
  /*
   * input:  { [color_a]: [hex], ... }
   * output: { [color_a]: {main: [hex_1], light: [hex_2], dark: [hex_3], contrastText: [hex_4]}, ... }
   * */
  return Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: t.palette.augmentColor({ color: { main: value }, name: key }),
    }),
    {} as Record<string, PaletteOptions["primary"]>,
  );
}

const ture = {
  120: "#09455A",
  100: "#0B5671",
  90: "#23677F",
  80: "#3C788D",
  60: "#6D9AAA",
  40: "#9DBBC6",
  20: "#CEDDE3",
  15: "#DEE6EA",
  10: "#F3F7F8",
};
const annkatrin = {
  100: "#77ACA2",
  80: "#92BDB5",
  60: "#ADCDC7",
  40: "#C9DEDA",
  35: "#D1E3DF",
  30: "#D6E7E3",
  20: "#E5EFEC",
};
const hassan = {
  100: "#F79096",
  80: "#F9A6AB",
  60: "#FABCC0",
  40: "#FCD3D5",
};
const kerstin = {
  100: "#FDD177",
  80: "#FDDA92",
  60: "#FEE3AD",
  40: "#FEEDC9",
};

const svart = {
  100: "#263238",
  70: "#677074",
  50: "#92989B",
  40: "#A8ADAF",
  25: "#C9CCCD",
  15: "#DFE0E1",
  10: "#F9F9F8",
};

/* primary colours */
const primaryColors = {
  ture: ture[100],
  annkatrin: annkatrin[100],
  hassan: hassan[100],
  kerstin: kerstin[100],
};
const primaries = transformColorObject(primaryColors);

/* secondary colours */
const secondaryColors = {
  hav: "#4594B7",
  manet: "#F5AC83",
  hjarta: "#FD7777",
  anemon: "#A877AC",
  tang: "#77AC7C",
  land: "#F08E56",
  skrov: "#635147",
  kind: "#FFB0AB",
  skum: "#A99BCF",
  fisk: "#88D3A2",
  snacka: "#FFE299",
  klippa: "#B0E3FF",
};
const secondaries = transformColorObject(secondaryColors);

/* secondary colours */
const tertiaryColors = {
  info: svart[70],
  shift: "#1C84C6",
  resource: "#44CED0",
  understaffed: "#FD8B8B",
  overstaffed: "#BCE7FF",
  covered: "#FFBCB8",
};
const tertiaries = transformColorObject(tertiaryColors);

const palette: PaletteOptions = {
  primary: primaries.ture,
  secondary: primaries.annkatrin,
  warning: primaries.kerstin,
  error: primaries.hassan,
  success: primaries.annkatrin,
  info: tertiaries.info,
  // primary
  ture,
  annkatrin,
  hassan,
  kerstin,
  svart,
  // secondary
  ...secondaries,
  // tertiary
  ...tertiaries,
  text: {
    primary: "#263238",
    secondary: "#677074",
  },
  background: {
    default: "#fff",
    paper: "#FFFFFF",
  },
};

export {
  annkatrin,
  hassan,
  kerstin,
  palette,
  primaryColors,
  secondaryColors,
  svart,
  ture,
};

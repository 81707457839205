import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertDataToInitialValues,
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultTeamGroupSubmitFunction,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { HoursAndShiftsSettingRuleGroup_fragment$key as RuleGroupKey } from "./__generated__/HoursAndShiftsSettingRuleGroup_fragment.graphql";
import {
  HoursAndShiftsSettingTeamGroup_fragment$data as TeamGroupData,
  HoursAndShiftsSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/HoursAndShiftsSettingTeamGroup_fragment.graphql";
import { HoursAndShiftsSettingUserSetting_fragment$key as UserSettingKey } from "./__generated__/HoursAndShiftsSettingUserSetting_fragment.graphql";
import { HoursAndShiftsForm } from "./HoursAndShiftsForm";
import { HoursAndShiftsTeamGroupForm } from "./HoursAndShiftsTeamGroupForm";

const teamGroupFragment = graphql`
  fragment HoursAndShiftsSettingTeamGroup_fragment on SettingNode {
    id
    periodLengthWeeks
    hoursPerWeek
    shiftsPerWeek
    totalHoursMargin
    totalShiftsMargin
  }
`;

const userSettingFragment = graphql`
  fragment HoursAndShiftsSettingUserSetting_fragment on UserSettingNode {
    id
    hoursPerWeek
    shiftsPerWeek
  }
`;

const ruleGroupFragment = graphql`
  fragment HoursAndShiftsSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    hoursPerWeek
    shiftsPerWeek
  }
`;

const SETTING_NAME = "Timmar & Pass";
const MODULE_NAME = "HoursAndShifts";
const SETTING_URL_ID = "hours-and-shifts";

type FullTimeHoursFormValues = Pick<
  TeamGroupData,
  "id" | "hoursPerWeek" | "shiftsPerWeek"
>;

type FullTimeHoursTeamGroupFormValues = FullTimeHoursFormValues &
  Pick<TeamGroupData, "totalHoursMargin" | "totalShiftsMargin">;

type AdditionalData = {
  periodLengthWeeks: number;
};

const validationSchema = yup.object().shape({
  hoursPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  shiftsPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 skift")
    .max(168, "Måste vara högst 168 skift")
    .required("Får ej vara tomt"),
});

const teamGroupValidationSchema = yup.object().shape({
  hoursPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(168, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  shiftsPerWeek: yup
    .number()
    .min(0, "Måste vara minst 0 skift")
    .max(168, "Måste vara högst 168 skift")
    .required("Får ej vara tomt"),
  totalHoursMargin: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
  totalShiftsMargin: yup
    .number()
    .min(0, "Måste vara minst 0%")
    .max(100, "Måste vara högst 100%")
    .required("Får ej vara tomt"),
});

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  data: TeamGroupData,
): FullTimeHoursTeamGroupFormValues {
  return {
    id: data.id,
    hoursPerWeek: data.hoursPerWeek,
    shiftsPerWeek: data.shiftsPerWeek,
    totalHoursMargin: data.totalHoursMargin,
    totalShiftsMargin: data.totalShiftsMargin,
  };
}

function useAdditionalDataForTeamGroup(fragmentRef: TeamGroupKey) {
  const teamGroupData = useFragment(teamGroupFragment, fragmentRef);
  const periodLengthWeeks = teamGroupData.periodLengthWeeks;
  return {
    periodLengthWeeks,
  };
}

function renderComponent(
  initialValues: FullTimeHoursFormValues,
  onSubmit: (formValues: FullTimeHoursFormValues) => void,
  additionalData: AdditionalData,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <HoursAndShiftsForm
        periodLengthWeeks={additionalData.periodLengthWeeks}
        readOnly={false}
      />
    </BaseSettings>
  );
}

function renderTeamGroupComponent(
  initialValues: FullTimeHoursTeamGroupFormValues,
  onSubmit: (formValues: FullTimeHoursTeamGroupFormValues) => void,
  additionalData: AdditionalData,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <HoursAndShiftsTeamGroupForm
        periodLengthWeeks={additionalData.periodLengthWeeks}
      />
    </BaseSettings>
  );
}

export const hoursAndShiftsSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  RuleGroupKey,
  FullTimeHoursFormValues,
  AdditionalData,
  FullTimeHoursTeamGroupFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: defaultConvertDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues: defaultConvertDataToInitialValues,
  convertUserDataToInitialValues: defaultConvertDataToInitialValues,
  useAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    renderComponent: renderTeamGroupComponent,
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
  },
};

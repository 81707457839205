import { Stack } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { WeightAndHardSoftFields } from "settings/common/TeamGroupOnlyForms";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { AB2A } from "components/setting/common/forms";

import { weekRestSetting } from "./WeekRestSetting";

type Props = {
  readOnly?: boolean;
};

export function WeekRestTeamGroupOnlyForm({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(weekRestSetting.moduleName);

  return (
    <PaperWrapper>
      <Stack gap={3}>
        <AB2A disabled={disabled} readOnly={readOnly} />

        <WeightAndHardSoftFields
          moduleName={weekRestSetting.moduleName}
          softFieldName="minWeeklyRestAbSoft"
          weightFieldName="minWeeklyRestAbWeight"
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

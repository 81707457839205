import { useMemo } from "react";
import { useFragment } from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import {
  OptimizationSetting,
  UserOnly,
} from "settings/common/optimizationSetting";

import { UserSettingExceptionsRemoveButton } from "components/setting/common/SettingExceptionsRemoveButton";

import { SettingExceptionBox } from "./SettingExceptionBox";

type Props<
  TeamGroupKey extends KeyType,
  UserKey extends KeyType,
  RuleGroupKey extends KeyType,
  SettingFormValues,
  AdditionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues,
> = {
  setting: OptimizationSetting<
    TeamGroupKey,
    UserKey,
    RuleGroupKey,
    SettingFormValues,
    AdditionalData,
    TeamGroupSettingFormValues,
    UserSettingFormValues
  >;
  userSettingFragment: UserKey;
  teamGroupFragment: TeamGroupKey;
  settingModules: ReadonlyArray<string>;
  settingsId: string;
};

export function UserSettingExceptionBox<
  TeamGroupKey extends KeyType,
  UserKey extends KeyType,
  RuleGroupKey extends KeyType,
  SettingFormValues,
  AdditionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues = undefined,
>({
  setting,
  userSettingFragment,
  teamGroupFragment,
  settingModules,
  settingsId,
}: Props<
  TeamGroupKey,
  UserKey,
  RuleGroupKey,
  SettingFormValues,
  AdditionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues
>) {
  const data = useFragment(setting.userSettingFragment, userSettingFragment);
  const additionalData =
    setting.useAdditionalDataForTeamGroup(teamGroupFragment);

  const { onSubmitUser } = setting.useSubmitFunctions();
  const userSetting = useMemo(
    () => ({ id: settingsId, settingModules }),
    [settingsId, settingModules],
  );

  return (
    <SettingExceptionBox
      title={setting.name}
      removeExceptionButton={
        <UserSettingExceptionsRemoveButton
          settingModule={setting.moduleName}
          userSetting={userSetting}
        />
      }
    >
      {setting.renderComponent(
        setting.convertUserDataToInitialValues(data),
        onSubmitUser,
        additionalData,
      )}
      {setting.userOnly && (
        <UserOnlySettingView
          userSettingData={data}
          userSettingMetadata={setting.userOnly}
        />
      )}
    </SettingExceptionBox>
  );
}

type UserOnlySettingViewProps<
  UserSettingKey extends KeyType,
  UserSettingsFormValues,
> = {
  userSettingData: KeyTypeData<UserSettingKey>;
  userSettingMetadata: UserOnly<UserSettingKey, UserSettingsFormValues>;
};

function UserOnlySettingView<UserKey extends KeyType, UserSettingsFormValues>({
  userSettingData,
  userSettingMetadata,
}: UserOnlySettingViewProps<UserKey, UserSettingsFormValues>) {
  const onSubmit = userSettingMetadata.useSubmitUserOnly();
  return userSettingMetadata.renderComponent(
    userSettingMetadata.convertUserDataToInitialValues(userSettingData),
    onSubmit,
  );
}

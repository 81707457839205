/**
 * @generated SignedSource<<5e08e952f1a12013430e78d99b411330>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RuleGroupSelect_fragment$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "RuleGroupSelect_fragment";
}>;
export type RuleGroupSelect_fragment$key = ReadonlyArray<{
  readonly " $data"?: RuleGroupSelect_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSelect_fragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RuleGroupSelect_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "RuleGroupNode",
  "abstractKey": null
};

(node as any).hash = "f00cb28a5f8d365b687c84809fbbe340";

export default node;

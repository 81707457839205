import { useField } from "formik";

import {
  EmploymentTitle,
  TranslateEmploymentTitle,
} from "components/organisation/types";

import GenericSelect from "./GenericSelect";

type EmploymentTitleSelectProps = {
  name: string;
  label?: string;
};

const typeOptions: EmploymentTitle[] = ["N", "A", "C", "S"];
const options = typeOptions.map((u) => ({
  value: u.toLowerCase(),
  label: TranslateEmploymentTitle(u),
}));

const EmploymentTitleSelect = ({ name, label }: EmploymentTitleSelectProps) => {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  const selected = options?.find((o) => o.value === value);

  return (
    <GenericSelect
      label={label}
      options={options}
      value={selected}
      onChange={(_e: any, v: any) => setValue(v?.value)}
      placeholder="Välj regelgrupp..."
      error={meta.touched && !!meta.error}
      helperText={meta.touched ? meta.error : undefined}
      sx={{ bgcolor: "white" }}
    />
  );
};

export default EmploymentTitleSelect;

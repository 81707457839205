import AdvancedGroupIcon from "@mui/icons-material/AdminPanelSettings";
import { baseDemandSetting } from "settings/BaseDemand/BaseDemandSetting";
import { clockwiseRotationSetting } from "settings/ClockwiseRotations/ClockwiseRotationSetting";
import { GenericOptimizationSetting } from "settings/common/optimizationSetting";
import { competenceDemandSetting } from "settings/CompetenceDemand/CompetenceDemandSetting";
import { consecutiveRestSetting } from "settings/ConsecutiveRest/ConsecutiveRestSetting";
import { consecutiveWorkSetting } from "settings/ConsecutiveWork/ConsecutiveWorkSetting";
import { costSetting } from "settings/Cost/CostSetting";
import { dayNightCadenceSetting } from "settings/DayNightCadence/DayNightCadenceSetting";
import { dayNightDistributionSetting } from "settings/DayNightDistribution/DayNightDistributionSetting";
import { dayNightFairnessSetting } from "settings/DayNightFairness/DayNightFairnessSetting";
import { dayRestSetting } from "settings/DayRest/DayRestSetting";
import { hoursAndShiftsSetting } from "settings/HoursAndShifts/HoursAndShiftsSetting";
import { nightShiftSetting } from "settings/NightShift/NightShiftSetting";
import { optimizationSetting } from "settings/Optimization/OptimizationSetting";
import { partTimeSetting } from "settings/PartTime/PartTimeSetting";
import { requestedDaysOffSetting } from "settings/RequestedDaysOff/RequestedDaysOffSetting";
import { resourceTimeSetting } from "settings/ResourceTime/ResourceTimeSetting";
import { responsibilityTimeSetting } from "settings/ResponsibilityTime/ResponsibilityTimeSetting";
import { restBetweenShiftsSetting } from "settings/RestBetweenShifts/RestBetweenShiftsSetting";
import { shiftDistributionSetting } from "settings/ShiftDistribution/ShiftDistributionSetting";
import { smoothResourcesSetting } from "settings/SmoothResources/SmoothResourcesSetting";
import { splitShiftsSetting } from "settings/SplitShifts/SplitShiftsSetting";
import { vacationSetting } from "settings/Vacation/VacationSetting";
import { weekendRestSetting } from "settings/WeekendRest/WeekendRestSetting";
import { weekRestSetting } from "settings/WeekRest/WeekRestSetting";
import { workdayCadenceSetting } from "settings/WorkDayCadence/WorkDayCadenceSetting";

import { Level2Icon as ModuleActiveIcon } from "./common/Level2Icon";
import type { Section } from "./SettingsPanel/types";

export const ruleSections = (
  modules: string[],
  showAdvanced = true,
): Section[] => [
  {
    title: "Behov, timmar & pass",
    defaultExpanded: true,
    elements: [
      hoursAndShiftsSetting,
      baseDemandSetting,
      competenceDemandSetting,
      costSetting,
    ].map(elementWithoutIcon),
  },
  {
    title: "Arbete & rytm",
    defaultExpanded: true,
    elements: [
      consecutiveWorkSetting,
      workdayCadenceSetting,
      dayNightDistributionSetting,
      dayNightFairnessSetting,
      dayNightCadenceSetting,
    ].map((setting) => elementWithIcon(setting, modules)),
  },
  {
    title: "Ledighet & vila",
    defaultExpanded: true,
    elements: [
      consecutiveRestSetting,
      dayRestSetting,
      weekRestSetting,
      weekendRestSetting,
    ].map((setting) => elementWithIcon(setting, modules)),
  },
  {
    title: "Passtyper",
    defaultExpanded: true,
    elements: [
      shiftDistributionSetting,
      resourceTimeSetting,
      responsibilityTimeSetting,
      splitShiftsSetting,
    ].map((setting) => elementWithIcon(setting, modules)),
  },
  ...(showAdvanced
    ? [
        {
          title: "Avancerat",
          icon: <AdvancedGroupIcon sx={{ color: "text.secondary" }} />,
          elements: [
            ...[optimizationSetting, partTimeSetting].map(elementWithoutIcon),
            ...[
              smoothResourcesSetting,
              restBetweenShiftsSetting,
              clockwiseRotationSetting,
              requestedDaysOffSetting,
              vacationSetting,
              nightShiftSetting,
            ].map((setting) => elementWithIcon(setting, modules)),
          ],
        },
      ]
    : []),
];

function elementWithoutIcon(setting: GenericOptimizationSetting) {
  return {
    id: setting.urlId,
    title: setting.name,
  };
}

function elementWithIcon(
  setting: GenericOptimizationSetting,
  modules: string[],
) {
  return {
    id: setting.urlId,
    title: setting.name,
    endAdornment: (
      <ModuleActiveIcon active={modules.includes(setting.moduleName)} />
    ),
  };
}

/**
 * @generated SignedSource<<a8df78c85626c1d4a5fdc825584e31e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiEditUsersMutation$variables = {
  teamGroupIds: ReadonlyArray<string>;
  teamIds: ReadonlyArray<string>;
  userIds: ReadonlyArray<string>;
};
export type MultiEditUsersMutation$data = {
  readonly multiEditUsers: {
    readonly ok: boolean | null;
    readonly users: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"MultiEditUsers_fragment">;
    } | null> | null;
  } | null;
};
export type MultiEditUsersMutation = {
  response: MultiEditUsersMutation$data;
  variables: MultiEditUsersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamGroupIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userIds"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "teamGroupIds",
        "variableName": "teamGroupIds"
      },
      {
        "kind": "Variable",
        "name": "teamIds",
        "variableName": "teamIds"
      },
      {
        "kind": "Variable",
        "name": "userIds",
        "variableName": "userIds"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MultiEditUsersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MultiEditUsersPayload",
        "kind": "LinkedField",
        "name": "multiEditUsers",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MultiEditUsers_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MultiEditUsersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MultiEditUsersPayload",
        "kind": "LinkedField",
        "name": "multiEditUsers",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamGroupNodeConnection",
                "kind": "LinkedField",
                "name": "groupMemberOf",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamGroupNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamGroupNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeConnection",
                "kind": "LinkedField",
                "name": "memberOf",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ab5ce8c8090ba80e7f5d499106f0dfc8",
    "id": null,
    "metadata": {},
    "name": "MultiEditUsersMutation",
    "operationKind": "mutation",
    "text": "mutation MultiEditUsersMutation(\n  $userIds: [ID!]!\n  $teamIds: [ID!]!\n  $teamGroupIds: [ID!]!\n) {\n  multiEditUsers(input: {userIds: $userIds, teamIds: $teamIds, teamGroupIds: $teamGroupIds}) {\n    ok\n    users {\n      ...MultiEditUsers_fragment\n      id\n    }\n  }\n}\n\nfragment MultiEditUsers_fragment on UserNode {\n  id\n  fullName\n  groupMemberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  memberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28b97c64d30e5cfb99e7e0a3c3a32ebc";

export default node;

import { Form, Formik } from "formik";

type Props = {
  initialValues: any;
};

export function FormikMock({
  initialValues,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      <Form autoComplete="off">{children}</Form>
    </Formik>
  );
}

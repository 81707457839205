import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { BaseTeamSelect as BaseSelect } from "./BaseTeamSelect";
import { useTeamSelectFragmentRef } from "./TeamSelectFragmentRefContext";
import type {
  Option,
  Props as SelectProps,
  TeamSelect_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment TeamSelect_fragment on TeamNode @relay(plural: true) {
    id
    name
    color
    group {
      id
      name
    }
  }
`;

type Props = Omit<SelectProps, "options">;

export function TeamSelect(props: Props) {
  const fragmentRef = useTeamSelectFragmentRef();
  const teams = useFragment<Key>(fragment, fragmentRef);

  const options = useMemo<Option[]>(
    () =>
      (teams || []).map((t) => ({
        id: t?.id ?? "",
        name: t?.name ?? "",
        color: t?.color ?? "#616161",
        group: { id: t?.group?.id ?? "", name: t?.group?.name ?? "" },
      })),
    [teams],
  );
  const sx = useMemo(
    () => ({ minWidth: 170, ...(props.sx ?? {}) }),
    [props.sx],
  );

  return <BaseSelect {...(props as SelectProps)} options={options} sx={sx} />;
}

import {
  CircularProgress as Loading,
  Paper,
  Skeleton,
  Stack,
} from "@mui/material";

export function PeriodTimelinePlaceholder() {
  return (
    <Stack gap={1}>
      <Skeleton height={70.56} width={160} sx={{ pt: 2, ml: 4 }} />
      <Skeleton height={255} width="100%" />
    </Stack>
  );
}
export function ScheduleRunsPlaceholder() {
  return (
    <Paper
      variant="box"
      sx={{ height: 173.69, display: "flex", justifyContent: "center" }}
    >
      <Loading sx={{ mt: 4 }} />
    </Paper>
  );
}

export function PeriodPlaceholder() {
  return (
    <Stack>
      <PeriodTimelinePlaceholder />
      <ScheduleRunsPlaceholder />
    </Stack>
  );
}

import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import { DayEveNightFields } from "./DayEveNightFields";
import { FullDayDurationField } from "./FullDayDurationField";

type Props = { disabled?: boolean; readOnly?: boolean };

export function Base5({ disabled = false, readOnly }: Props) {
  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography variant="h4">Definition av dygnsgruppering</Typography>
        <Typography>
          Här kan du styra dag/kväll/natt-klassificeringen av passtyper. Ett
          pass klassas som ett dagspass om majoriteten av passets timmar
          infaller under dagen (och vice versa för kvällspass och nattpass). Om
          lika många timmar infaller under dag, kväll eller natt, så kommer
          passet i första hand klassas till den dygnsdel där passet startar,
          därefter i turordning dag, kväll och sist natt. . Passen i{" "}
          <Link to={"/shifts"}>Passprofilen</Link> klassificeras automatiskt
          utifrån denna definition.
        </Typography>
      </Stack>
      <DayEveNightFields disabled={disabled} readOnly={readOnly} />
      <FullDayDurationField
        name="fullDayDuration"
        disabled={disabled}
        InputProps={{ readOnly }}
      />
    </Stack>
  );
}

/**
 * @generated SignedSource<<4aa87bcf6f8b466de3277cad6c3c9392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WeekRestSettingRuleGroup_fragment$data = {
  readonly id: string;
  readonly settingModules: ReadonlyArray<string>;
  readonly weekBreakDaysAlternatives: ReadonlyArray<number>;
  readonly weekBreakHourAlternatives: ReadonlyArray<number>;
  readonly " $fragmentType": "WeekRestSettingRuleGroup_fragment";
};
export type WeekRestSettingRuleGroup_fragment$key = {
  readonly " $data"?: WeekRestSettingRuleGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WeekRestSettingRuleGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeekRestSettingRuleGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakHourAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakDaysAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "b75794a75172ac01dbc7b8e96abfb564";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import type { RecordProxy, SelectorStoreUpdater } from "relay-runtime";

import { CreateTeamGroupMutation as Mutation } from "./__generated__/CreateTeamGroupMutation.graphql";

const mutation = graphql`
  mutation CreateTeamGroupMutation($input: CreateTeamGroupInput!) {
    createTeamGroup(input: $input) {
      teamGroup {
        id
        name
        isActive
        archivedAt
        deleteable
      }
    }
  }
`;

function update(r: RecordProxy, key: string, newRecord: any) {
  const list = r.getLinkedRecords(key);
  const newList = [...(list || []), newRecord];
  r.setLinkedRecords(newList, key);
}

const updater: SelectorStoreUpdater<Mutation["response"]> = (store) => {
  const newTeamGroup = store
    .getRootField("createTeamGroup")
    .getLinkedRecord("teamGroup");
  if (!newTeamGroup) return;
  const root = store.getRoot();
  update(root, "allTeamGroups", newTeamGroup);
};

export const useCreateTeamGroup = () =>
  useMutation<Mutation>(mutation, { updater });

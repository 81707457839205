import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  HeaderWeight,
  SettingBoxHeader,
} from "components/setting/common";
import { Rhythm1Info as Info } from "components/setting/common/forms";

type Props = {
  moduleName: string;
  radioSoft: boolean;
  groupName: string;
  disabled?: boolean;
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  const title = (
    <>
      Vila mellan pass för enhet <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      name="minDailyRestSoft"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="minDailyRestWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Header({
  moduleName,
  groupName,
  radioSoft,
  readOnly,
  disabled,
}: Props) {
  return (
    <SettingBoxHeader
      radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
      switchComponent={<HeaderSwitch name={moduleName} readOnly={readOnly} />}
      titleComponent={<Title name={groupName} />}
      weightComponent={
        <Weight readOnly={readOnly} disabled={disabled || !radioSoft} />
      }
    />
  );
}

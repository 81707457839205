import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { UpdateDefinitionsMutation as Mutation } from "./types";

const mutation = graphql`
  mutation UpdateDefinitionsMutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      ok
      setting {
        ...Definitions_fragment
      }
    }
  }
`;

export const useUpdateDefinitionsMutation = () =>
  useMutation<Mutation>(mutation);

import { Component } from "react";

type Props = {
  onRetry: () => void;
  fallback?:
    | React.ReactNode
    | ((props: { error: Error; retry: () => void }) => React.ReactNode);
  children: (props: { fetchKey: number }) => React.ReactNode;
};

type State = {
  error: Error | null;
  fetchKey: number; // This is used to force refetch
};

export class ErrorBoundaryWithRetryLazyLoad extends Component<Props, State> {
  /* This component is used when useLazyLoadQuery in react-relay/hooks is used.
   * If you use useQueryLoader/loadQuery, you should use ErrorBoundaryWithRetry
   */
  constructor(props: Props) {
    super(props);
    this.state = { error: null, fetchKey: 0 };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  _retry = () => {
    this.props.onRetry();
    this.setState((prev) => ({ error: null, fetchKey: prev.fetchKey + 1 }));
  };

  render() {
    const { children, fallback } = this.props;
    const { error, fetchKey } = this.state;

    if (error) {
      if (typeof fallback == "function") {
        return fallback({ error, retry: this._retry });
      }
      return fallback;
    }
    return children({ fetchKey });
  }
}

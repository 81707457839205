import { useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { Alert, Button, Paper, Snackbar, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import type { FormikHelpers } from "formik";
import { Form as FormikForm, Formik } from "formik";
import { commitMutation } from "libs/commitMutation";
import * as yup from "yup";

import Field from "components/forms/CustomField";

import { ChangePasswordMutation } from "./__generated__/ChangePasswordMutation.graphql";

const mutation = graphql`
  mutation ChangePasswordMutation(
    $oldPassword: String!
    $newPassword: String!
  ) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      ok
    }
  }
`;

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

type FormValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type FormActions = FormikHelpers<FormValues>;

type FormProps = {
  isSubmitting: boolean;
};

function ChangePasswordForm({ isSubmitting }: FormProps) {
  return (
    <FormikForm autoComplete="off">
      <Stack gap={2}>
        <Field
          name="oldPassword"
          type="password"
          label="Gammalt lösenord"
          validate={(value) => (value ? undefined : "Får inte vara tomt")}
          sx={{ bgcolor: "white" }}
          size="small"
        />
        <Field
          name="newPassword"
          type="password"
          autoComplete="new-password"
          label="Nytt lösenord"
          validate={(value) => (value ? undefined : "Får inte vara tomt")}
          sx={{ bgcolor: "white" }}
          size="small"
        />
        <Field
          name="confirmPassword"
          type="password"
          label="Nytt lösenord (igen)"
          validate={(value) => (value ? undefined : "Får inte vara tomt")}
          sx={{ bgcolor: "white" }}
          size="small"
        />
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitting}
          sx={{ alignSelf: "flex-end" }}
        >
          Spara
        </Button>
      </Stack>
    </FormikForm>
  );
}

export default function ChangePassword() {
  const environment = useRelayEnvironment();
  const [message, setMessage] = useState<string | null>(null);

  const clearMessage = () => setMessage(null);

  const initialValues: FormValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  async function onSubmit(
    values: FormValues,
    { setSubmitting, resetForm }: FormActions,
  ) {
    setSubmitting(true);
    const variables = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    await commitMutation<ChangePasswordMutation>(environment, {
      mutation,
      variables,
    })
      .then(() => {
        resetForm({ values: initialValues });
        setMessage("Lösenordet ändrat");
        setTimeout(clearMessage, 6000);
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <>
      <Paper variant="box" sx={{ p: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <ChangePasswordForm isSubmitting={isSubmitting} />
          )}
        </Formik>
      </Paper>
      <Snackbar open={!!message} autoHideDuration={6000} onClose={clearMessage}>
        <Alert onClose={clearMessage} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

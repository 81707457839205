/**
 * @generated SignedSource<<b81fd5aa7f80a01de4dc5bd8ed628eb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteTeamMutation$variables = {
  id: string;
};
export type DeleteTeamMutation$data = {
  readonly deleteTeam: {
    readonly deletedId: string | null;
    readonly ok: boolean | null;
  } | null;
};
export type DeleteTeamMutation = {
  response: DeleteTeamMutation$data;
  variables: DeleteTeamMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteTeamPayload",
        "kind": "LinkedField",
        "name": "deleteTeam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteTeamPayload",
        "kind": "LinkedField",
        "name": "deleteTeam",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedId"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e088a12eb2bb5c38db19f5b5da4d1d70",
    "id": null,
    "metadata": {},
    "name": "DeleteTeamMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteTeamMutation(\n  $id: ID!\n) {\n  deleteTeam(input: {id: $id}) {\n    ok\n    deletedId\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e0ee5c3d8e4b5be5f054e875839a4a8";

export default node;

import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import SettingBox from "../../SettingBox";

import { BaseDemandInfo } from "./info";
import { TeamDemandSettings } from "./TeamDemandSettings";

type Props = {
  groupName: string;
  readOnly?: boolean;
};

export function Base4({ groupName, readOnly }: Props) {
  return (
    <SettingBox
      title={
        <>
          Grundbehov för enhet <b>{groupName}</b>
        </>
      }
      infoButton={<BaseDemandInfo />}
    >
      <Stack gap={5}>
        <Stack gap={1}>
          <Typography variant="h4">
            Kort om inställningarna för grundbehovet
          </Typography>
          <Typography>
            Verksamhetens behov anges under fliken som heter{" "}
            <Link to={"/demand"}>Behovsprofil</Link>. Algoritmen kommer göra
            sitt bästa för att täcka behovet som anges i behovsprofilen. Men, om
            antalet personer inte räcker till, eller andra schemaregler är för
            snävt satta, så kanske inte hela behovsprofilen går att täcka.
          </Typography>
          <Typography>
            Här kan du alltså styra om schemat ska tillåta underbemanning i de
            fall det är nödvändigt, för respektive avdelning. Vi rekommenderar
            att underbemanning alltid ska vara tillåtet - för om det inte går
            att täcka behovsprofilen (exempelvis på grund av att antal personer
            på avdelningen är för få), och detta val är påslaget, då kommer
            algoritmen inte kunna skapa något schema alls.
          </Typography>
        </Stack>
        <Stack gap={2}>
          <Typography variant="h4">
            Grundbehovsinställningar för respektive avdelning
          </Typography>
          <TeamDemandSettings name="teamDemandSettings" readOnly={readOnly} />
        </Stack>
      </Stack>
    </SettingBox>
  );
}

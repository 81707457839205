import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { EditUserMutation as Mutation } from "./types";

const mutation = graphql`
  mutation EditUserMutation($input: EditUserInput!) {
    editUser(input: $input) {
      user {
        ...EditUserForm_fragment
      }
    }
  }
`;

export const useEditUser = () => useMutation<Mutation>(mutation);

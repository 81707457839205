import { createContext, useContext } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type { ShiftDayTypeContext_fragment$key as Key } from "./types";

type FragmentProviderProps = React.PropsWithChildren<{
  fragmentRef: Key;
}>;

export type DayTypeBreakpoints = {
  morningStartsNightEnds: string;
  eveningStartsMorningEnds: string;
  nightStartsEveningEnds: string;
  fullDayDuration: number;
};

const fragment = graphql`
  fragment ShiftDayTypeContext_fragment on SettingNode {
    morningStartsNightEnds
    eveningStartsMorningEnds
    nightStartsEveningEnds
    fullDayDuration
  }
`;

export const ShiftDayTypeContext = createContext<DayTypeBreakpoints>({
  morningStartsNightEnds: "",
  eveningStartsMorningEnds: "",
  nightStartsEveningEnds: "",
  fullDayDuration: 1e6,
});

export const ShiftDayTypeProvider = ShiftDayTypeContext.Provider;

export const ShiftDayTypeFragmentProvider = ({
  fragmentRef,
  children,
}: FragmentProviderProps) => {
  const dayTypeBreakpoints = useFragment<Key>(fragment, fragmentRef);

  return (
    <ShiftDayTypeProvider value={dayTypeBreakpoints} children={children} />
  );
};

export const useShiftDayType = () => useContext(ShiftDayTypeContext);

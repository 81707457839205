import type { PropsWithChildren } from "react";
import { useMemo } from "react";
import useFormikState from "hooks/useFormikState";

import { FormikDayCard } from "components/common/DayCard";

import type { ShiftGroupInput as ShiftGroup } from "../types";

import { initialShift } from "./constants";

type Props = {
  name: string;
  onRemove: () => void;
  expanded: boolean;
  onExpand: () => void;
  daysLeft: number[];
};

export function ShiftGroupDayCard({
  name,
  onRemove,
  expanded,
  onExpand,
  children,
}: PropsWithChildren<Props>) {
  const { value: shiftGroup, setValue: setShiftGroup } =
    useFormikState<ShiftGroup>(name);

  function onAddShift() {
    const shifts = [...shiftGroup?.shifts, initialShift];
    setShiftGroup({ ...shiftGroup, shifts });
  }

  const countLabel = useMemo(() => {
    const count = shiftGroup?.shifts.length ?? 0;
    return `${count} passtyp${count !== 1 ? "er" : ""}`;
  }, [shiftGroup?.shifts]);

  return (
    <FormikDayCard
      name={`${name}.days`}
      expanded={expanded}
      onExpand={onExpand}
      onRemove={onRemove}
      onAddRow={onAddShift}
      countLabel={countLabel}
      addLabel="Lägg till passtyp"
    >
      {children}
    </FormikDayCard>
  );
}

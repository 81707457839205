import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Types5A, Types5Info as Info } from "components/setting/common/forms";

type TitleProps = { name: string };
type FormProps = TitleProps & {
  name: string;
  groupName: string;
  totalShifts: number;
  readOnly?: boolean;
};

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande Dag-/Kväll-/Natt-fördelning för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}
function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      name="shiftDayTypeDistributionSoft"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}
function Switch({ readOnly }: { readOnly?: boolean }) {
  return <HeaderSwitch name="ShiftDayTypeDistribution" readOnly={readOnly} />;
}

export function Types5Form({
  name,
  groupName,
  totalShifts,
  readOnly,
}: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes("ShiftDayTypeDistribution");

  const who = (
    <>
      för <b>{name}</b> på enhet {groupName}
    </>
  );

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={<Switch readOnly={readOnly} />}
          titleComponent={<Title name={name} />}
        />
        <Types5A
          disabled={disabled}
          who={who}
          totalShifts={totalShifts}
          readOnly={readOnly}
        />
      </Stack>
    </SettingBox>
  );
}

import { Stack, Typography } from "@mui/material";

import { FormikSmallSwitch } from "components/common/Switch";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function Types6({ disabled, readOnly }: Props) {
  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Stack gap={2} direction="row">
          <Typography variant="h4">
            Eftersträva rättvis fördelning av Dag/Kväll/Natt
          </Typography>
          <FormikSmallSwitch
            name="optimizeDayTypeFairness"
            disabled={disabled}
          />
        </Stack>
        <Typography>
          Med denna inställning påslagen, så kommer optimeringsalgoritmen att
          eftersträva en rättvis fördelning av Dag/Kväll/Natt mellan
          medarbetare. Detta är alltid en mjuk regel.
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Stack gap={2} direction="row">
          <Typography variant="h4">
            Eftersträva rättvis fördelning av Dag/Kväll/Natt på helger
          </Typography>
          <FormikSmallSwitch
            name="optimizeDayTypeFairnessWeekend"
            disabled={disabled}
          />
        </Stack>
        <Typography>
          Med denna inställning påslagen, så kommer optimeringsalgoritmen att
          eftersträva en rättvis fördelning av Dag/Kväll/Natt{" "}
          <strong>för helgpass</strong> mellan medarbetare. Detta är alltid en
          mjuk regel.
        </Typography>
      </Stack>
    </Stack>
  );
}

import {
  Environment,
  FetchFunction,
  GraphQLResponseWithData,
  Network,
  RecordSource,
  Store,
  Variables,
} from "relay-runtime";
import { isTokenExpired } from "utils/checkTokenExpiration";

import { GRAPHQL_URL } from "./utils/constants";

const REFRESH_TOKEN_MUTATION = `
mutation RefreshTokenMutation {
  refreshToken {
    token
    payload
    refreshToken
    refreshExpiresIn
  }
}`;

let HEADERS: Record<string, string> = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const recordSource = new RecordSource();

const isError = (data: GraphQLResponseWithData, error: string) =>
  data.errors?.some((e) => e.message === error);
const simpleFetch = (
  query: string | null | undefined,
  variables: Variables,
  customHeaders = HEADERS,
) =>
  fetch(GRAPHQL_URL, {
    method: "POST",
    credentials: "include",
    headers: customHeaders,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

export const refreshTokenIfNeeded = async () => {
  const token = localStorage.getItem("authToken");

  if (isTokenExpired(token)) {
    const refreshTokenResponse = await simpleFetch(REFRESH_TOKEN_MUTATION, {});
    const refreshTokenData: GraphQLResponseWithData =
      await refreshTokenResponse.json();

    if (refreshTokenData.data?.refreshToken?.token) {
      const newToken = refreshTokenData.data.refreshToken.token;
      localStorage.setItem("authToken", newToken);
      HEADERS = {
        ...HEADERS,
        Authorization: `Bearer ${newToken}`,
      };
    } else {
      localStorage.removeItem("authToken");
    }
  }
};

const fetchQuery: FetchFunction = async (operation, variables) => {
  await refreshTokenIfNeeded();
  let response = await simpleFetch(operation.text, variables, HEADERS);
  let data: GraphQLResponseWithData = await response.json();

  let responseHasError =
    isError(data, "Signature has expired") ||
    isError(data, "Error decoding signature") ||
    isError(data, "You do not have permission to perform this action");

  if (responseHasError) {
    const refreshTokenResponse = await simpleFetch(REFRESH_TOKEN_MUTATION, {});
    const refreshTokenData: GraphQLResponseWithData =
      await refreshTokenResponse.json();

    if (refreshTokenData.data?.refreshToken?.token) {
      const newToken = refreshTokenData.data.refreshToken.token;
      HEADERS = {
        ...HEADERS,
        Authorization: `Bearer ${newToken}`,
      };

      response = await simpleFetch(operation.text, variables, HEADERS);
      data = await response.json();
    } else {
      recordSource.clear();
      localStorage.removeItem("authToken");
      window.location.href = "/login";
    }
  }

  return data;
};

const env = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(recordSource),
});

export default env;

import { Chip as MuiChip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Chip = styled(MuiChip)(({ theme }) => ({
  height: 22.6,
  fontSize: 12,
  fontWeight: 600,
  backgroundColor: theme.palette.mode === "light" ? "#EBECF0" : "#303030",
  color: theme.palette.mode === "light" ? "#172B4D" : "#fff",
  borderRadius: 4,
}));

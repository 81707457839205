import { Box, Stack, Typography } from "@mui/material";

import Field from "components/forms/CustomField";

import DurationPie from "./DurationPie";
import EvenSplitPie from "./EvenSplitPie";

type Props = { disabled?: boolean; readOnly?: boolean };

export function DayEveNightFields({ disabled, readOnly }: Props) {
  const labelSx = { width: 200 };
  const fieldSx = { width: 100 };
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      rowGap={2}
      columnGap={2}
      alignItems="center"
    >
      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography sx={labelSx}>Dagen börjar och natten slutar</Typography>
          <Field
            type="time"
            name="morningStartsNightEnds"
            fullWidth
            disabled={disabled}
            InputProps={{ readOnly }}
            sx={fieldSx}
          />
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography sx={labelSx}>Kvällen börjar och dagen slutar</Typography>
          <Field
            type="time"
            name="eveningStartsMorningEnds"
            fullWidth
            disabled={disabled}
            InputProps={{ readOnly }}
            sx={fieldSx}
          />
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography sx={labelSx}>Natten börjar och kvällen slutar</Typography>
          <Field
            type="time"
            name="nightStartsEveningEnds"
            fullWidth
            disabled={disabled}
            InputProps={{ readOnly }}
            sx={fieldSx}
          />
        </Stack>
      </Stack>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexGrow={2}
      >
        <Box height={180} width={180} position="relative">
          <EvenSplitPie
            width={112}
            height={112}
            sx={{ mt: "34px", ml: "34px" }}
            position="absolute"
            variant="links"
          />
          <EvenSplitPie
            width={180}
            height={180}
            position="absolute"
            variant="labels"
          />
          <DurationPie
            width={112}
            height={112}
            sx={{ mt: "34px", ml: "34px" }}
            position="absolute"
          />
        </Box>
      </Box>
    </Stack>
  );
}

import { Suspense, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { Dialog } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";

import { useEditRuleGroup } from "../mutations";
import type { EditRuleGroupInput as FormValues } from "../types";

import {
  RuleGroupForm,
  ruleGroupFormValidationSchema as validationSchema,
} from "./common";
import type {
  EditRuleGroupForm_fragment$key as Key,
  EditRuleGroupFormQuery as Query,
} from "./types";

type CommonProps = {
  open: boolean;
  onClose: () => void;
};

type Props = CommonProps & { id: string | null };

type ContentProps = CommonProps & {
  queryRef: PreloadedQuery<Query>;
};

const fragment = graphql`
  fragment EditRuleGroupForm_fragment on RuleGroupNode {
    id
    name
    description
  }
`;

const query = graphql`
  query EditRuleGroupFormQuery($id: ID!) {
    ruleGroup(id: $id) {
      ...EditRuleGroupForm_fragment
    }
  }
`;

function Content({ queryRef, open, onClose }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const selected = useFragment<Key>(fragment, data.ruleGroup);
  const [commit] = useEditRuleGroup();

  const Title = () => (
    <>
      Redigera regelgrupp <b>{selected?.name}</b>
    </>
  );
  const initialValues: FormValues = {
    id: selected?.id || "",
    name: selected?.name || "",
    description: selected?.description || "",
  };

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } })
      .then(onClose)
      .catch((error) => console.error(error));
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <FormikForm autoComplete="off">
          <RuleGroupForm
            title={<Title />}
            submitText="Spara"
            onClose={onClose}
          />
        </FormikForm>
      </Formik>
    </Dialog>
  );
}

export function EditRuleGroupForm({ id, open, onClose }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    if (!id) return;
    loadQuery({ id });
  }, [id, loadQuery]);

  return (
    <Suspense fallback={null}>
      {!!queryRef && (
        <Content queryRef={queryRef} open={open} onClose={onClose} />
      )}
    </Suspense>
  );
}

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { AB1B, AB1Info as Info } from "components/setting/common/forms";

const MODULE_NAME = "DailyRestAB";

type TitleProps = { name: string };
type FormProps = TitleProps & { readOnly?: boolean };

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande dygnsvila för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

export function AB1Form({ readOnly, ...titleProps }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title {...titleProps} />}
        />
        <AB1B disabled={disabled} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

/**
 * @generated SignedSource<<f70126fbcabd409cafa9f4b3091c1b78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResponsibilityTimeSettingUserSetting_fragment$data = {
  readonly adminHoursMax: number;
  readonly adminHoursMin: number;
  readonly id: string;
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "ResponsibilityTimeSettingUserSetting_fragment";
};
export type ResponsibilityTimeSettingUserSetting_fragment$key = {
  readonly " $data"?: ResponsibilityTimeSettingUserSetting_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResponsibilityTimeSettingUserSetting_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResponsibilityTimeSettingUserSetting_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminHoursMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminHoursMax",
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "1506890be9fa21565dded75c7585e954";

export default node;

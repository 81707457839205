import { Suspense, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { Dialog, Typography as Text } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";

import { useEditCompetence } from "../mutations";
import type { EditCompetenceInput as FormValues } from "../types";

import type { EditCompetenceForm_fragment$key as Key } from "./__generated__/EditCompetenceForm_fragment.graphql";
import type { EditCompetenceFormQuery as Query } from "./__generated__/EditCompetenceFormQuery.graphql";
import {
  CompetenceForm,
  competenceFormValidationSchema as validationSchema,
} from "./common";

const fragment = graphql`
  fragment EditCompetenceForm_fragment on CompetenceNode {
    id
    name
    description
    color
  }
`;

const query = graphql`
  query EditCompetenceFormQuery($id: ID!) {
    competence(id: $id) {
      ...EditCompetenceForm_fragment
    }
  }
`;

type TitleProps = { competenceName: string };

type CommonProps = { open: boolean; onClose: () => void };
type ContentProps = CommonProps & { queryRef: PreloadedQuery<Query> };
type Props = CommonProps & { id: string | null };

function Title({ competenceName }: TitleProps) {
  return (
    <Text variant="h2">
      Redigera kompetens <b>{competenceName}</b>
    </Text>
  );
}

function Content({ queryRef, open, onClose }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const selected = useFragment<Key>(fragment, data.competence);
  const [commit] = useEditCompetence();
  const initialValues: FormValues = {
    id: selected?.id || "",
    name: selected?.name || "",
    description: selected?.description || "",
    color: selected?.color || "",
  };

  async function onSubmit(input: FormValues) {
    try {
      await commit({ variables: { input } });
      onClose();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <FormikForm autoComplete="off">
          <CompetenceForm
            title={<Title competenceName={selected?.name || ""} />}
            submitText="Spara"
            onClose={onClose}
          />
        </FormikForm>
      </Formik>
    </Dialog>
  );
}

export function EditCompetenceForm({ id, onClose, open }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    if (!id) return;
    loadQuery({ id });
  }, [id, loadQuery]);

  return (
    <Suspense fallback={null}>
      {!!queryRef && (
        <Content queryRef={queryRef} open={open} onClose={onClose} />
      )}
    </Suspense>
  );
}

import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import GenericSelect from "components/common/selectors/GenericSelect";
import { nightRestrictOptions } from "components/types/NightRestrictType";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

function NightRestrictionSelect({
  disabled,
  readOnly,
}: {
  disabled?: boolean;
  readOnly?: boolean;
}) {
  const { value, setValue } = useFormikState<string>("nightShiftRestriction");
  const selectedOption = nightRestrictOptions.find((o) => o.value === value);

  return (
    <GenericSelect
      options={nightRestrictOptions}
      value={selectedOption}
      onChange={(_e: any, v: any) => setValue(v?.value)}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export function NightRestrictionField({ disabled, readOnly }: Props) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Begränsning för andelen nattpass</Typography>
      <Typography>
        Detta fält styr huruvida andelen nattpass, uttryckt i timmar, ska
        begränsas. Nattpass definieras enligt Allmänna Bestämmelser.
      </Typography>
      <NightRestrictionSelect disabled={disabled} readOnly={readOnly} />
    </Stack>
  );
}

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import type { CompetenceDemandSetting as CompetenceDemandSettingType } from "settings/types";

import { CompetenceDemandSetting } from "./CompetenceDemandSetting";

type Props = { name: string; disabled?: boolean; readOnly?: boolean };

export function CompetenceDemandSettings({ name, disabled, readOnly }: Props) {
  const { value } = useFormikState<CompetenceDemandSettingType[]>(name);

  return (
    <Stack gap={5}>
      {value?.map((_, index) => (
        <CompetenceDemandSetting
          key={index}
          name={`${name}.${index}`}
          disabled={disabled}
          readOnly={readOnly}
        />
      ))}
    </Stack>
  );
}

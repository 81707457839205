import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  refresh as refreshPageQuery,
  usePageQuery,
} from "contexts/PageQueryContext";
import {
  useCurrentTeamGroup,
  useCurrentTeamGroupUpdater,
} from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import type {
  TeamGroupOption,
  TeamGroupsType,
  TeamGroupType,
  TeamOption,
  TeamType,
} from "types/TeamGroupsContextQueryTypes";

import {
  CreateTeamGroupForm,
  EditTeamGroupForm,
  useArchiveTeam,
  useArchiveTeamGroup,
} from "components/admin";
import DeleteModal from "components/common/DeleteModal";
import { EditTeamForm } from "components/group_settings";

import { TeamGroupsContext_teamgroups$key as Key } from "./types";

const fragment = graphql`
  fragment TeamGroupsContext_teamgroups on TeamGroupNode @relay(plural: true) {
    id
    name
    teams {
      edges {
        node {
          id
          name
          color
        }
      }
    }
  }
`;

function teamMapper(team: TeamType): TeamOption {
  return {
    id: team.id || "",
    name: team.name || "",
    color: team.color || "",
  };
}

function teamGroupMapper(teamGroup: TeamGroupType | null): TeamGroupOption {
  return {
    id: teamGroup?.id || "",
    name: teamGroup?.name || "",
    teams: connectionToArray(teamGroup?.teams).map(teamMapper),
  };
}

type TeamWithTeamGroup = Partial<TeamType> & {
  teamGroup: Partial<TeamGroupType>;
};

type TeamGroupsContextType = {
  teamGroups: TeamGroupsType;
  selectedTeamGroup: TeamGroupType | null;
  teamGroupOptions: TeamGroupOption[];

  loading: boolean;
  refetch: () => void;

  openCreateTeamGroup: () => void;

  setTeamToDelete: (team: TeamWithTeamGroup | null) => void;
  setTeamGroupToDelete: (teamGroup: Partial<TeamGroupType> | null) => void;

  setTeamToEdit: (id: string) => void;
  setTeamGroupToEdit: (id: string) => void;
};

const noop = () => {};
const initialContext: TeamGroupsContextType = {
  teamGroups: [],
  loading: false,
  refetch: noop,
  openCreateTeamGroup: noop,
  selectedTeamGroup: null,
  teamGroupOptions: [],
  setTeamToDelete: noop,
  setTeamGroupToDelete: noop,
  setTeamToEdit: noop,
  setTeamGroupToEdit: noop,
};

const TeamGroupsContext = createContext<TeamGroupsContextType>(initialContext);
export const useTeamGroups = () =>
  useContext(TeamGroupsContext) as TeamGroupsContextType;

export function TeamGroupsProvider({ children }: PropsWithChildren) {
  const currentTeamGroup = useCurrentTeamGroup();
  const setCurrentTeamGroup = useCurrentTeamGroupUpdater();
  const [loading, setLoading] = useState<boolean>(false);
  const [archiveTeam] = useArchiveTeam();
  const [archiveTeamGroup] = useArchiveTeamGroup();

  const data = usePageQuery();
  const teamGroups = useFragment<Key>(fragment, data.teamGroups);

  const selectedTeamGroup =
    (teamGroups || []).find((tg) => tg?.id === currentTeamGroup?.id) || null;

  const teamGroupOptions: TeamGroupOption[] = (teamGroups || [])
    .map(teamGroupMapper)
    .filter((x) => x?.id);

  async function refetch() {
    setLoading(true);
    await refreshPageQuery().finally(() => setLoading(false));
  }

  const [teamToDelete, setTeamToDelete] = useState<TeamWithTeamGroup | null>(
    null,
  );
  const [teamGroupToDelete, setTeamGroupToDelete] =
    useState<Partial<TeamGroupType> | null>(null);

  const [createTeamGroupOpen, setCreateTeamGroupOpen] =
    useState<boolean>(false);
  const [teamToEdit, setTeamToEdit] = useState<string | null>(null);
  const [teamGroupToEdit, setTeamGroupToEdit] = useState<string | null>(null);

  const cancelDeleteTeam = () => setTeamToDelete(null);
  const confirmDeleteTeam = () => {
    if (!teamToDelete?.id) return;
    archiveTeam({ variables: { id: teamToDelete.id } }).then(() => refetch());
    setTeamToDelete(null);
  };

  const cancelDeleteTeamGroup = () => setTeamGroupToDelete(null);
  const confirmDeleteTeamGroup = () => {
    if (!teamGroupToDelete?.id) return;
    archiveTeamGroup({ variables: { id: teamGroupToDelete.id } }).then(() =>
      refetch(),
    );
    setTeamGroupToDelete(null);
  };

  const value = {
    teamGroups,
    selectedTeamGroup,
    teamGroupOptions,

    teamToEdit,
    setTeamToEdit,
    teamGroupToEdit,
    setTeamGroupToEdit,

    loading,
    refetch,

    openCreateTeamGroup: () => setCreateTeamGroupOpen(true),

    setTeamToDelete,
    setTeamGroupToDelete,
  };

  return (
    <TeamGroupsContext.Provider value={value}>
      {children}
      <CreateTeamGroupForm
        open={createTeamGroupOpen}
        onClose={(newId?: string) => {
          setCreateTeamGroupOpen(false);
          if (newId) {
            setCurrentTeamGroup(newId);
            window.location.reload();
          }
        }}
      />
      {!!teamToEdit && (
        <EditTeamForm
          id={teamToEdit}
          open={!!teamToEdit}
          onClose={() => setTeamToEdit(null)}
        />
      )}
      <EditTeamGroupForm
        id={teamGroupToEdit}
        open={!!teamGroupToEdit}
        onClose={() => setTeamGroupToEdit(null)}
      />
      <DeleteModal /* DeleteTeam */
        show={Boolean(teamToDelete)}
        onHide={() => cancelDeleteTeam()}
        deleteTitle="Radera avdelning?"
        deleteMessage={
          <>
            <Typography>
              Är du säker på att du vill radera avdelning{" "}
              <strong>{teamToDelete?.name}</strong> från enhet{" "}
              <strong>{teamToDelete?.teamGroup?.name}</strong>?
            </Typography>
            <Typography>Denna åtgärd kan inte ångras.</Typography>
          </>
        }
        buttonText="Radera avdelning"
        onDeleteClick={() => {
          confirmDeleteTeam();
        }}
      />
      <DeleteModal /* DeleteTeamGroup */
        show={Boolean(teamGroupToDelete)}
        onHide={() => cancelDeleteTeamGroup()}
        deleteTitle="Radera enhet?"
        deleteMessage={
          <>
            <Typography>
              Är du säker på att du vill radera enhet{" "}
              <strong>{teamGroupToDelete?.name}</strong>?
            </Typography>
            <Typography>Denna åtgärd kan inte ångras.</Typography>
          </>
        }
        buttonText="Radera enhet"
        onDeleteClick={() => {
          confirmDeleteTeamGroup();
        }}
      />
    </TeamGroupsContext.Provider>
  );
}

import { Link } from "react-router-dom";
import { List, ListItem, Stack, Typography } from "@mui/material";
import { useCurrentTeamGroup } from "hooks";
import { workdayCadenceSetting } from "settings/WorkDayCadence/WorkDayCadenceSetting";
import { optimizationRuleUrl } from "utils/urls";

const listStyle = { listStyleType: "disc", pl: 2, pb: 1.5, pt: 0 };
const listItemStyle = { display: "list-item", pb: 1 };

export function DayNightCadenceInfo() {
  const { id: teamGroupId } = useCurrentTeamGroup();

  return (
    <Stack gap={1}>
      <Typography variant="h4">
        Skapa egna regler för kadensen av dags-, kvälls- och nattpass
      </Typography>
      <Typography>
        I den här regelmodulen kan du skapa egna, särskilda regler, för att
        styra kadensen av dags-, kvälls- och nattpass. Du kan skapa flera regler
        som kompletterar varandra. Här följer 3 exempel på regelformuleringar
        som vi brukar rekommendera:
      </Typography>
      <List sx={listStyle}>
        <ListItem sx={listItemStyle}>
          <Typography fontWeight="bold">
            3 nattpass på 4 rullande kalenderdagar - Hård
          </Typography>
          <Typography>
            När du skapar en regel som begränsar nattpassen till 3 stycken på 4
            rullande kalenderdagar, så innebär det i praktiken att det max får
            vara 3 nattpass på raken. Detta kan vara särskilt effektivt i ett
            schema där både dag-/kvälls- och nattpass förekommer. Du kanske
            exempelvis vill kunna tillåta max 4 dagpass på raken, men skruva åt
            begränsningen till max 3 nattpass på raken. Det gör du lättast genom
            att lägga till en sådan här 3-av-4-regel!
          </Typography>
        </ListItem>
        <ListItem sx={listItemStyle}>
          <Typography fontWeight="bold">
            5 kvällspass på 10 rullande kalenderdagar - Mjuk
          </Typography>
          <Typography>
            Det brukar vara uppskattat av medarbetare på dag-/kvällscheman att
            få till en bra rytm mellan dagpass och kvällspass. Många medarbetare
            brukar vilja undvika att alla kvällspass läggs i början alternativt
            slutet av perioden, utan vill hellre växla flera gånger under
            schemaperioden mellan dag- respektive kvällspass. Då kan 5
            kvällspass på rullande 10 kalenderdagar vara en effektiv
            regelformulering för att få till en sådan rytm. Den här
            regelformuleringen brukar vi rekommendera ska vara mjuk då den kan
            ge upphov till hårda regelkrockar för dem som av olika anledningar
            ska jobba extra många kvällspass.
          </Typography>
        </ListItem>
      </List>
      <Typography variant="h4">
        Vill du skapa sådana här regler, fast för alla passtyper?
      </Typography>
      <Typography>
        Då ska du titta närmre på regelmodulen{" "}
        <Link to={optimizationRuleUrl(teamGroupId, workdayCadenceSetting)}>
          Schemarytm
        </Link>
        ! Där kan du skapa precis sådana här regler, fast för alla typer av
        arbetspass.
      </Typography>
    </Stack>
  );
}

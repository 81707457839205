import { SettingBox } from "components/setting/common";
import { Base6Info as Info } from "components/setting/common/forms";
import { MipgapLimitField } from "components/setting/common/forms/Advanced";

type Props = { groupName: string; readOnly?: boolean };
type TitleProps = Pick<Props, "groupName">;

function Title({ groupName }: TitleProps) {
  return (
    <>
      Optimeringsinställningar för enhet <b>{groupName}</b>
    </>
  );
}

export function OptimizationForm({ groupName, readOnly }: Props) {
  return (
    <SettingBox title={<Title groupName={groupName} />} infoButton={<Info />}>
      <MipgapLimitField readOnly={readOnly} />
    </SettingBox>
  );
}

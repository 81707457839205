import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

export const CommonHeader = () => (
  <Stack gap={1}>
    <Typography variant="h3">
      Använd möten för APT, utbildningar och andra samordnade händelser
    </Typography>
    <Typography>
      Ett möte är en viktig och central händelse i schemat där flera personers
      deltagande ska samordnas. Du vill typiskt använda mötesfunktionaliteten
      för att planera APT, utbildningar och liknande viktiga händelser.
    </Typography>
    <Typography>
      Det är möjligt att skapa ett möte för endast 1 person, men vi gissar att
      du för denna situation är mer betjänt av funktionaliteten för fasta pass.
      Fasta pass kan du lägga in i{" "}
      <Link to={"/vacation"}>Händelsekalendern</Link>.
    </Typography>
  </Stack>
);

import { useCallback, useMemo } from "react";
import {
  Check as CheckIcon,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Typography as Text,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateTime, Duration, Interval } from "luxon";
import { Colors } from "styles/colors";

import DatePickerWithWeekNumbers from "./DatePickerWithWeekNumbers";

type DurationSpan = "day" | "3days" | "week" | "2weeks" | "period" | "other";
enum SPANS {
  DAY = "day",
  THREE_DAYS = "3days",
  WEEK = "week",
  TWO_WEEKS = "2weeks",
  PERIOD = "period",
  OTHER = "other",
}

type ChipOptionProps = {
  label: string;
  onClick: () => void;
  selected: boolean;
};

const StyledChip = styled(Chip)(({ theme }) => ({
  flexDirection: "row-reverse",
  paddingLeft: theme.spacing(0.4),
  paddingRight: theme.spacing(0.4),
  paddingTop: theme.spacing(1.55),
  paddingBottom: theme.spacing(1.55),
  "& .MuiChip-icon": {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-1),
  },
}));

function ChipOption({ label, onClick, selected }: ChipOptionProps) {
  return (
    <StyledChip
      size="small"
      color="primary"
      label={label}
      onClick={onClick}
      variant={selected ? "filled" : "outlined"}
      icon={selected ? <CheckIcon sx={{ fontSize: 16 }} /> : <Box />}
    />
  );
}

function getSpanDuration(span: DurationSpan, onePeriod: Duration): Duration {
  switch (span) {
    case SPANS.DAY:
      return Duration.fromObject({ days: 1 });
    case SPANS.THREE_DAYS:
      return Duration.fromObject({ days: 3 });
    case SPANS.WEEK:
      return Duration.fromObject({ weeks: 1 });
    case SPANS.TWO_WEEKS:
      return Duration.fromObject({ weeks: 2 });
    case SPANS.PERIOD:
      return onePeriod;
    default:
      return onePeriod;
  }
}

function getSpan(duration: Duration, onePeriod: Duration) {
  const durationDays = duration.as("days");
  if (durationDays === getSpanDuration(SPANS.DAY, onePeriod).as("days")) {
    return SPANS.DAY;
  }
  if (
    durationDays === getSpanDuration(SPANS.THREE_DAYS, onePeriod).as("days")
  ) {
    return SPANS.THREE_DAYS;
  }
  if (durationDays === getSpanDuration(SPANS.WEEK, onePeriod).as("days")) {
    return SPANS.WEEK;
  }
  if (durationDays === getSpanDuration(SPANS.TWO_WEEKS, onePeriod).as("days")) {
    return SPANS.TWO_WEEKS;
  }
  if (durationDays === getSpanDuration(SPANS.PERIOD, onePeriod).as("days")) {
    return SPANS.PERIOD;
  }
  return SPANS.OTHER;
}

type Props = {
  minDate?: Date;
  maxDate?: Date;
  periodStart: Date;
  periodEnd: Date;
  from: Date;
  to: Date;
  onChange: (start: Date, end: Date) => void;
};

export default function SelectDurationSpan({
  minDate,
  maxDate,
  periodStart,
  periodEnd,
  onChange,
  from,
  to,
}: Props) {
  const fromDt = useMemo(() => DateTime.fromJSDate(from), [from]);
  const toDt = useMemo(() => DateTime.fromJSDate(to), [to]);
  const onePeriod = useMemo<Duration>(
    () =>
      Interval.fromDateTimes(
        DateTime.fromJSDate(periodStart),
        DateTime.fromJSDate(periodEnd),
      ).toDuration(),
    [periodStart, periodEnd],
  );
  const duration = useMemo<Duration>(
    () => Interval.fromDateTimes(fromDt, toDt).toDuration(),
    [fromDt, toDt],
  );

  const span = useMemo<DurationSpan>(
    () => getSpan(duration, onePeriod),
    [duration, onePeriod],
  );

  const getPrevFrom = useCallback(
    (fr: Date) => DateTime.fromJSDate(fr).minus(duration).toJSDate(),
    [duration],
  );
  const getNextFrom = useCallback(
    (fr: Date) => DateTime.fromJSDate(fr).plus(duration).toJSDate(),
    [duration],
  );

  const nextFromValue = useMemo(() => getNextFrom(from), [from, getNextFrom]);
  const prevDisabled = useMemo(
    () => !!minDate && from <= minDate,
    [minDate, from],
  );
  const nextDisabled = useMemo(
    () => !!maxDate && nextFromValue >= maxDate,
    [maxDate, nextFromValue],
  );

  function changeSpan(id: DurationSpan) {
    if (id === "period") {
      onChange(periodStart, periodEnd);
    } else {
      onChange(from, fromDt.plus(getSpanDuration(id, onePeriod)).toJSDate());
    }
  }

  function onFromChange(inputDate: DateTime | null) {
    let newFromDt = inputDate ?? fromDt;
    const tmpNewFrom = newFromDt.toJSDate();
    if (minDate && tmpNewFrom < minDate) {
      newFromDt = DateTime.fromJSDate(minDate);
    } else if (maxDate && tmpNewFrom > maxDate) {
      newFromDt = DateTime.fromJSDate(maxDate);
    }
    const newFrom = newFromDt.toJSDate();
    const newTo = newFromDt.plus(duration).toJSDate();
    onChange(newFrom, newTo);
  }

  function prevInterval() {
    if (prevDisabled) return;

    let newFrom = getPrevFrom(from);
    if (minDate && minDate > newFrom) {
      newFrom = minDate;
    }
    const newTo = getNextFrom(newFrom);
    onChange(newFrom, newTo);
  }

  function nextInterval() {
    if (nextDisabled) return;

    let newFrom = getNextFrom(from);
    let newTo = getNextFrom(newFrom);
    if (maxDate && maxDate < newTo) {
      newTo = maxDate;
      newFrom = getPrevFrom(newTo);
    }
    onChange(newFrom, newTo);
  }

  const chips = useMemo(
    () => [
      { label: "Dag", spanId: SPANS.DAY },
      { label: "3 dagar", spanId: SPANS.THREE_DAYS },
      { label: "Vecka", spanId: SPANS.WEEK },
      { label: "2 veckor", spanId: SPANS.TWO_WEEKS },
      { label: "Period", spanId: SPANS.PERIOD },
    ],
    [],
  );

  return (
    <Stack gap={2} direction="row">
      <Stack
        direction="column"
        alignItems="left"
        sx={{
          border: "1px solid",
          borderColor: Colors.FORMBORDERCOLOR,
          borderRadius: 1,
          px: 0.8,
          pb: 0.5,
        }}
      >
        <Text
          variant="caption"
          color="text.secondary"
          width={50}
          fontSize={10}
          mt={-0.85}
          mb={0.2}
          ml={0.2}
          px={0.5}
          lineHeight={1}
          sx={{ backgroundColor: "white" }}
        >
          Visa som
        </Text>
        <Stack gap={0.5} direction="row" alignItems="center" flexWrap="wrap">
          {chips.map((c) => (
            <ChipOption
              key={c.spanId}
              label={c.label}
              selected={span === c.spanId}
              onClick={() => changeSpan(c.spanId)}
            />
          ))}
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={prevInterval}
          disabled={prevDisabled}
          size="small"
          sx={{ p: 0 }}
        >
          <ChevronLeft />
        </IconButton>
        <DatePickerWithWeekNumbers
          onChange={onFromChange}
          value={fromDt}
          views={["year", "month", "day"]}
          minDate={minDate}
          maxDate={maxDate}
        />
        <IconButton
          onClick={nextInterval}
          disabled={nextDisabled}
          size="small"
          sx={{ p: 0 }}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
    </Stack>
  );
}

import { PropsWithChildren } from "react";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Box, Typography } from "@mui/material";
import { annkatrin } from "styles/mui/light-palette";
import { useBooleanState } from "utils/useBooleanState";

type Props = {
  title: string;
  removeExceptionButton: React.ReactNode;
};

export function SettingExceptionBox({
  title,
  removeExceptionButton,
  children,
}: PropsWithChildren<Props>) {
  const { value: expanded, toggle: toggleExpanded } = useBooleanState();

  return (
    <Accordion
      expanded={expanded}
      disableGutters
      square
      onChange={toggleExpanded}
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
        px: 2,
        py: 1,
        borderRadius: "10px",
        backgroundColor: annkatrin[35],
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore color="primary" />}
        sx={{
          pl: 0,
        }}
      >
        <Box display="flex" flexGrow={1} justifyContent="space-between">
          <Typography variant="h4" m={0}>
            {title}
          </Typography>
          <Typography color="primary">{expanded ? "Dölj" : "Visa"}</Typography>
        </Box>
      </AccordionSummary>
      <Box sx={{ pb: 1 }}>{children}</Box>
      <Box display="flex" justifyContent="end" sx={{ pb: 1 }}>
        {removeExceptionButton}
      </Box>
    </Accordion>
  );
}

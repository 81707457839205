import { CircularProgress as Loading, Skeleton, Stack } from "@mui/material";

import { PageWrapper } from "components/layout";
import { SettingPanelSkeleton } from "components/setting";
import { SettingViewHeader } from "components/setting/common/SettingViewHeader";

export function SettingPanelPlaceholder() {
  return (
    <SettingPanelSkeleton
      select={<Skeleton variant="rounded" height={38.56} sx={{ mt: 1.5 }} />}
      search={<Skeleton variant="rounded" height={53.56} />}
      list={<Skeleton variant="rectangular" height={700} />}
    />
  );
}

export function SettingViewPlaceholder() {
  return (
    <PageWrapper sx={{ width: "100%" }}>
      <SettingViewHeader name="Laddar..." hideInfo />
      <Skeleton variant="rounded" height={500} />
    </PageWrapper>
  );
}

export function SettingsPlaceholder() {
  return (
    <Stack direction="row" minHeight="100%" width="100%">
      <SettingPanelPlaceholder />
      <SettingViewPlaceholder />
    </Stack>
  );
}

// REMOVEME Deprecated: To be removed when old setting boxes are removed
export function SettingLevel3Placeholder() {
  return (
    <Stack p={2} width="100%" height="100%">
      <Loading />
    </Stack>
  );
}

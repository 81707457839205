import useFormikState from "hooks/useFormikState";
import { DateTime } from "luxon";

import RecurrenceForm from "./Form";

function useDurationMs(startName: string, endName: string) {
  /** calculate timeslot duration. Requires formik context. If start or end is not found 0 is returned. */
  const { value: start } = useFormikState(startName);
  const { value: end } = useFormikState(endName);
  if (!start || !end) {
    return 0;
  }

  const startTime = Date.parse(start);
  const endTime = Date.parse(end);
  return endTime - startTime;
}

type RecurrenceFormikFieldProps = {
  name: string;
};

export default function RecurrenceFormik({ name }: RecurrenceFormikFieldProps) {
  const { value, setValue } = useFormikState(name);
  const { value: startStr } = useFormikState("start");

  const durationMs = useDurationMs("start", "end");

  const start = startStr
    ? DateTime.fromISO(startStr).toJSDate()
    : DateTime.now().toJSDate();

  return (
    <RecurrenceForm
      value={value}
      onChange={setValue}
      start={start}
      durationMs={durationMs}
    />
  );
}

import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { useCurrentTeamGroup } from "hooks";
import { hoursAndShiftsSetting } from "settings/HoursAndShifts/HoursAndShiftsSetting";
import { optimizationRuleUrl } from "utils/urls";

import ExampleBox from "components/setting/common/ExampleBox";

const EXAMPLE_SX = {
  mb: 2,
};

export function VacationInfo() {
  const { id: teamGroupId } = useCurrentTeamGroup();

  return (
    <Stack gap={1}>
      <Typography variant="h4">Formler</Typography>
      <Typography>
        Första steget är att beräkna det genomsnittliga antalet arbetspass per
        vecka i schemaperioden. Har man exempelvis en 4-veckorsperiod med 19
        pass för en heltidare så blir det genomsnittliga antalet arbetspass per
        vecka 4,75. Detta mått kan du se och ställa in i regelmodulen{" "}
        <Link to={optimizationRuleUrl(teamGroupId, hoursAndShiftsSetting)}>
          Timmar & Pass
        </Link>
        .
      </Typography>

      <ExampleBox sx={EXAMPLE_SX}>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">Arbetspass per vecka (snitt)</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack>
              <Stack
                sx={{ borderBottom: "1px solid black" }}
                alignItems="center"
              >
                Antal pass i perioden
              </Stack>
              <Stack alignItems="center">Periodens längd i veckor</Stack>
            </Stack>
          </Stack>
        </Typography>
      </ExampleBox>

      <Typography>
        Nästa steg är att omvandla de semesterhändelser som matats in i{" "}
        <Link to={"/vacation"}>Händelsekalendern</Link> till antalet arbetspass
        som ska räknas bort från arbetstidsmåttet.
      </Typography>

      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">Antal semesterarbetspass</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack>
              <Stack
                sx={{ borderBottom: "1px solid black" }}
                alignItems="center"
              >
                Antal kalenderdagar för semester
              </Stack>
              <Stack alignItems={"center"}>7</Stack>
            </Stack>
            <Stack justifyContent="center">*</Stack>
            <Stack justifyContent="center">Arbetspass per vecka (snitt)</Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">Antal semesterarbetstimmar</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack>
              <Stack
                sx={{ borderBottom: "1px solid black" }}
                alignItems="center"
              >
                Antal arbetstimmar i perioden
              </Stack>
              <Stack alignItems={"center"}>Antal arbetspass i perioden</Stack>
            </Stack>
            <Stack justifyContent="center">*</Stack>
            <Stack justifyContent="center">Antal semesterarbetspass</Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">Justerade arbetspass</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack justifyContent="center">Ursprungligt antal arbetspass</Stack>
            <Stack justifyContent="center">-</Stack>
            <Stack justifyContent="center">Antal semesterarbetspass</Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <ExampleBox sx={EXAMPLE_SX}>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">Justerade arbetstimmar</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack justifyContent="center">
              Ursprungligt antal arbetstimmar
            </Stack>
            <Stack justifyContent="center">-</Stack>
            <Stack justifyContent="center">Antal semesterarbetstimmar</Stack>
          </Stack>
        </Typography>
      </ExampleBox>

      <Typography>
        Till sist ska vi också beräkna det slutgiltiga antalet semesterdagar som
        ska räknas bort från medarbetarens semestersaldo. Detta gör vi genom att
        använda den så kallade <i>semesterkoefficienten</i>.
      </Typography>

      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">Semesterkoefficient</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack>
              <Stack
                sx={{ borderBottom: "1px solid black" }}
                alignItems="center"
              >
                5
              </Stack>
              <Stack alignItems={"center"}>Arbetspass per vecka (snitt)</Stack>
            </Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <ExampleBox sx={EXAMPLE_SX}>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">Antal semesterdagar</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack justifyContent="center">Antal semesterarbetspass</Stack>
            <Stack justifyContent="center">*</Stack>
            <Stack justifyContent="center">Semesterkoefficient</Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <Example />
    </Stack>
  );
}

function Example() {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Exempel</Typography>
      <Typography>
        Schemaperioden är 6 veckor lång. En person som ska arbeta 28 pass under
        schemaperioden har således 4,67 arbetspass i snitt per vecka.
      </Typography>

      <ExampleBox sx={EXAMPLE_SX}>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">4,67 pass i snitt per vecka</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack>
              <Stack
                sx={{ borderBottom: "1px solid black" }}
                alignItems="center"
              >
                28 pass
              </Stack>
              <Stack alignItems="center">6 veckor</Stack>
            </Stack>
          </Stack>
        </Typography>
      </ExampleBox>

      <Typography>
        För personen i fråga har 14 kalenderdagars semester matats in i
        Händelsekalendern. Givet 4,67 arbetspass i snitt per vecka, så kan vi
        räkna fram att personen borde ha ungefär 9,33 arbetspass under denna
        14-dagarsperiod i ett färdigt schema (vi vet ju ännu inte hur det
        färdiga schemat kommer att se ut). Vi räknar bort 9,33 pass från det
        totala antalet pass, vilket innebär att personen ska arbeta 19 pass på
        de 4 veckor där semester inte är utlagd.
      </Typography>

      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">9,33 semesterarbetspass</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack>
              <Stack
                sx={{ borderBottom: "1px solid black" }}
                alignItems="center"
              >
                14 dagar
              </Stack>
              <Stack alignItems={"center"}>7</Stack>
            </Stack>
            <Stack justifyContent="center">*</Stack>
            <Stack justifyContent="center">4,67 pass i snitt per vecka</Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">71,36 semesterarbetstimmar</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack>
              <Stack
                sx={{ borderBottom: "1px solid black" }}
                alignItems="center"
              >
                222 timmar
              </Stack>
              <Stack alignItems={"center"}>28 pass</Stack>
            </Stack>
            <Stack justifyContent="center">*</Stack>
            <Stack justifyContent="center">9 semesterarbetspass</Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">19 pass</Stack>
            <Stack justifyContent="center">≈</Stack>
            <Stack justifyContent="center">28 pass</Stack>
            <Stack justifyContent="center">-</Stack>
            <Stack justifyContent="center">9,33 pass</Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <ExampleBox sx={EXAMPLE_SX}>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">150,64 timmar</Stack>
            <Stack justifyContent="center">≈</Stack>
            <Stack justifyContent="center">222 timmar</Stack>
            <Stack justifyContent="center">-</Stack>
            <Stack justifyContent="center">71,36 timmar</Stack>
          </Stack>
        </Typography>
      </ExampleBox>

      <Typography>
        Till sist ska vi också beräkna det slutgiltiga antalet semesterdagar som
        ska räknas bort från medarbetarens semestersaldo. Detta gör vi genom att
        använda den så kallade <i>semesterkoefficienten</i>.
      </Typography>

      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">1.07</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack>
              <Stack
                sx={{ borderBottom: "1px solid black" }}
                alignItems="center"
              >
                5
              </Stack>
              <Stack alignItems={"center"}>4,67 pass i snitt per vecka</Stack>
            </Stack>
          </Stack>
        </Typography>
      </ExampleBox>
      <ExampleBox>
        <Typography fontStyle="italic">
          <Stack gap={1} direction="row">
            <Stack justifyContent="center">10 semesterdagar</Stack>
            <Stack justifyContent="center">=</Stack>
            <Stack justifyContent="center">9,33 semesterarbetspass</Stack>
            <Stack justifyContent="center">*</Stack>
            <Stack justifyContent="center">1.07</Stack>
          </Stack>
        </Typography>
      </ExampleBox>
    </Stack>
  );
}

import * as yup from "yup";

import { activityList } from "../../../types";
import {
  ActivityFormValues,
  ActivityPartFormValues,
  activityParts,
} from "../../types";

const yupDt = yup
  .string()
  .test("is-datetime", "Fyll i datum och tid", (value) => {
    if (!value) return false;
    const dt = new Date(value);
    return dt instanceof Date && !isNaN(dt.getTime());
  });

const yupActivityPart = yup.object().shape({
  start: yupDt
    .required("Fyll i starttid")
    .test(
      "part-start-before-activity",
      "Starttid måste vara efter aktivitetens starttid",
      function (value, context) {
        const { start } = context?.from?.slice(-1)[0]
          .value as ActivityFormValues;
        return new Date(value) >= new Date(start);
      },
    ),
  end: yupDt
    .required("Fyll i sluttid")
    .test(
      "part-end-after-activity",
      "Sluttid måste vara före aktivitetens sluttid",
      function (value, context) {
        const { end } = context?.from?.slice(-1)[0].value as ActivityFormValues;
        return new Date(value) <= new Date(end);
      },
    )
    .test(
      "end-before-start",
      "Sluttid måste vara efter starttid",
      function (value) {
        const { start } = this.parent as ActivityFormValues;
        return new Date(value) > new Date(start);
      },
    ),
  partType: yup
    .string()
    .oneOf(activityParts, "Ogiltig deltyp")
    .required("Välj deltyp"),
});

export const activityFormSchema = yup.object().shape({
  start: yupDt.required("Fyll i starttid"),
  end: yupDt
    .required("Fyll i sluttid")
    .test(
      "end-after-start",
      "Sluttid måste vara efter starttid",
      function (value) {
        const { start } = this.parent as ActivityFormValues;
        return new Date(value) > new Date(start);
      },
    ),
  userId: yup.string().required("Välj person"),
  activityType: yup
    .string()
    .oneOf(activityList, "Ogiltig typ")
    .required("Välj händelsetyp"),
  teamId: yup.string().required("Välj avdelning"),
  breakTime: yup.number().required("Fyll i paustid"),
  activityParts: yup
    .array()
    .of(yupActivityPart)
    .test(
      "breaks-cover-activity",
      "Passhålen får inte täcka hela aktiviteten",
      function (value, context) {
        if (!value) return false;

        const { start, end } = context.parent as ActivityFormValues;
        // join parts that are adjacent and of the same type
        const reducedParts = (value as ActivityPartFormValues[]).reduce(
          (acc, part) => {
            if (acc.length === 0) return [part];
            const prev = acc[acc.length - 1];
            if (prev.partType === part.partType && prev.end === part.start) {
              prev.end = part.end;
            } else {
              acc.push(part);
            }
            return acc;
          },
          [] as ActivityPartFormValues[],
        );

        const breaksCoverActivity = (reducedParts as ActivityPartFormValues[])
          .filter((part) => part.partType === "B")
          .some(
            (part) =>
              new Date(part.start) <= new Date(start) &&
              new Date(part.end) >= new Date(end),
          );

        return !breaksCoverActivity;
      },
    ),
});

/**
 * @generated SignedSource<<5c235f0619df0cb1617c0d5e2a96387f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiUserSettingSnapshotNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiUserSettingSnapshotNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserSettings_fragment$data = {
  readonly adminHoursMax: number;
  readonly adminHoursMin: number;
  readonly dayBreakAlternatives: ReadonlyArray<number>;
  readonly dayShiftDistributionShare: number;
  readonly dayTypeCadenceRestrictions: any;
  readonly eveningShiftDistributionShare: number;
  readonly freeEveryXOfYWeekends: number;
  readonly freeWeekendConstraintTypeEveryX: boolean;
  readonly freeWeekendConstraintTypeXOfY: boolean;
  readonly freeWeekendSoft: boolean;
  readonly fullDayShiftDistributionShare: number;
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly includeInDayTypeWeekendFairness: boolean;
  readonly maxConsecutiveFreeDays: number;
  readonly maxConsecutiveFreeDaysSoft: boolean;
  readonly maxConsecutiveWorkDays: number;
  readonly maxConsecutiveWorkDaysSoft: boolean;
  readonly minConsecutiveFreeDays: number;
  readonly minConsecutiveFreeDaysSoft: boolean;
  readonly minConsecutiveWorkDays: number;
  readonly minConsecutiveWorkDaysSoft: boolean;
  readonly nightShiftDistributionShare: number;
  readonly nightShiftRestriction: ApiUserSettingSnapshotNightShiftRestrictionChoices;
  readonly nightShiftRotation: ApiUserSettingSnapshotNightShiftRotationChoices;
  readonly prohibitedResourceWeekDays: ReadonlyArray<number>;
  readonly prohibitedShiftTypes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly resourceHoursMax: number;
  readonly resourceHoursMin: number;
  readonly settingModules: ReadonlyArray<string>;
  readonly shiftDayTypeAllowedErrorMargin: number;
  readonly shiftDayTypeDistributionSoft: boolean;
  readonly shiftsPerWeek: number;
  readonly user: {
    readonly name: string;
  };
  readonly weekBreakDaysAlternatives: ReadonlyArray<number>;
  readonly weekBreakHourAlternatives: ReadonlyArray<number>;
  readonly workDayCadenceRestrictions: any;
  readonly workEveryUnfreeWeekend: boolean;
  readonly workEveryUnfreeWeekendSoft: boolean;
  readonly workEveryXWeekend: number;
  readonly " $fragmentType": "UserSettings_fragment";
};
export type UserSettings_fragment$key = {
  readonly " $data"?: UserSettings_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettings_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettings_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayBreakAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakHourAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakDaysAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryXWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEveryXOfYWeekends",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeEveryX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeXOfY",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includeInDayTypeWeekendFairness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeDistributionSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeAllowedErrorMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRestriction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRotation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminHoursMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminHoursMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceHoursMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceHoursMax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prohibitedResourceWeekDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayTypeCadenceRestrictions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workDayCadenceRestrictions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSnapshotNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftSnapshotNodeConnection",
      "kind": "LinkedField",
      "name": "prohibitedShiftTypes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShiftSnapshotNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShiftSnapshotNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserSettingSnapshotNode",
  "abstractKey": null
};
})();

(node as any).hash = "db047be20e97b632e951e70c5f2ac697";

export default node;

import type { FC, ReactNode } from "react";
import { useCallback } from "react";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import { useFormikState } from "hooks";

import { ErrorBox } from "components/common/WarningBox";

import type { MeetingError } from "../types";
import {
  isErrorMeetingOverlapsWithShiftPart,
  isErrorMeetingShiftDoesNotCoverMeeting,
  isErrorUserHasConflictingMeeting,
} from "../types";

type MeetingErrorProps = { error: MeetingError };
type CausedByMeeting = NonNullable<MeetingError["causedByMeeting"]>;
type CausedByUser = NonNullable<MeetingError["causedByUser"]>;
type UserErrorProps = {
  meeting: CausedByMeeting;
  user: CausedByUser;
};
type MeetingOverlapsWithShiftPartProps = {
  shiftName: string;
};
type MeetingShiftDoesNotCoverMeetingProps = {
  shiftName: string;
};

type ErrorAndSolutionProps = {
  error: ReactNode;
  solution: ReactNode;
};

type Props = { name: string };

const EllipsisText = (props: { text: string }) => {
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 120,
      }}
    >
      {props.text}
    </div>
  );
};

export const MeetingErrorsBox: FC<Props> = ({ name }) => {
  const { value: errors } = useFormikState<ReadonlyArray<MeetingError>>(name);

  const renderError = useCallback(
    (error: MeetingError, i: number) => (
      <MeetingErrorRenderer key={i} error={error} />
    ),
    [],
  );

  if (!errors.length) return null;

  return (
    <ErrorBox sx={{ maxHeight: 200 }}>
      <Stack gap={2}>
        <Typography fontWeight={700} fontSize={16}>
          Du kan inte spara eftersom mötet har konflikter! Följande konflikter
          uppstod:
        </Typography>
        <Grid
          container
          columns={2}
          rowGap={0.5}
          columnSpacing={1}
          sx={{ maxHeight: 134, overflowY: "auto" }}
        >
          <Grid item xs={1}>
            <Typography fontWeight={700} fontSize={14}>
              Konflikt
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography fontWeight={700} fontSize={14}>
              Lösning
            </Typography>
          </Grid>
          {errors.map(renderError)}
        </Grid>
      </Stack>
    </ErrorBox>
  );
};

const MeetingErrorRenderer: FC<MeetingErrorProps> = ({ error }) => {
  if (isErrorUserHasConflictingMeeting(error)) {
    return (
      <UserMeetingError
        meeting={error.causedByMeeting}
        user={error.causedByUser}
      />
    );
  }
  if (isErrorMeetingOverlapsWithShiftPart(error)) {
    return (
      <MeetingOverlapsWithShiftPartError shiftName={error.causedByShift.name} />
    );
  }

  if (isErrorMeetingShiftDoesNotCoverMeeting(error)) {
    return (
      <MeetingShiftDoesNotCoverMeeting shiftName={error.causedByShift.name} />
    );
  }

  return null;
};

const UserMeetingError: FC<UserErrorProps> = ({ meeting, user }) => (
  <ErrorAndSolution
    error={
      <Stack direction="row" gap={0.5} alignItems="center">
        <UserChip name={user.fullName} />
        <Typography fontSize={13}>har ett överlappande möte</Typography>
        <MeetingChip name={meeting.name} />
      </Stack>
    }
    solution={
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography fontSize={13}>Ta bort</Typography>
        <UserChip name={user.fullName} />
        <Typography fontSize={13}>från detta möte eller</Typography>
        <MeetingChip name={meeting.name} />
      </Stack>
    }
  />
);

const MeetingOverlapsWithShiftPartError: FC<
  MeetingOverlapsWithShiftPartProps
> = ({ shiftName }) => (
  <ErrorAndSolution
    error={
      <Typography fontSize={13}>
        <Stack direction="row" gap={0.5} alignItems="center">
          Passet
          <MeetingChip name={<EllipsisText text={shiftName} />} />
          har en passdel som överlappar med mötet.
        </Stack>
      </Typography>
    }
    solution={
      <Typography fontSize={13}>
        <Stack direction="row" gap={0.5} alignItems="center">
          Radera eller redigera
          <MeetingChip name={<EllipsisText text={shiftName} />} />
          här nedan.
        </Stack>
      </Typography>
    }
  />
);

const MeetingShiftDoesNotCoverMeeting: FC<
  MeetingShiftDoesNotCoverMeetingProps
> = ({ shiftName }) => (
  <ErrorAndSolution
    error={
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography fontSize={13}>Passet</Typography>
        <MeetingChip name={<EllipsisText text={shiftName} />} />
        <Typography fontSize={13}>täcker inte mötet.</Typography>
      </Stack>
    }
    solution={
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography fontSize={13}>Radera eller redigera</Typography>
        <MeetingChip name={<EllipsisText text={shiftName} />} />
        <Typography fontSize={13}>här nedan.</Typography>
      </Stack>
    }
  />
);

const ErrorAndSolution: FC<ErrorAndSolutionProps> = ({ error, solution }) => (
  <>
    <Grid item xs={1}>
      {error}
    </Grid>
    <Grid item xs={1}>
      {solution}
    </Grid>
  </>
);

const MeetingChip: FC<{ name: ReactNode }> = ({ name }) => (
  <Chip label={name} size="small" color="primary" />
);
const UserChip: FC<{ name: ReactNode }> = ({ name }) => (
  <Chip label={name} size="small" variant="outlined" />
);

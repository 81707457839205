import type { MouseEvent, PropsWithChildren, ReactNode } from "react";
import { useState } from "react";
import { Box, Button, Popover, Stack, Typography } from "@mui/material";

type Props = {
  deleteMessage: ReactNode;
  buttonText: string;
  onDeleteClick: () => void;
  disabled?: boolean;
};

const DeletePopover = ({
  children,
  deleteMessage,
  buttonText,
  onDeleteClick,
  disabled,
}: PropsWithChildren<Props>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  function onOpen(event: MouseEvent<HTMLElement>) {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  }
  function onClose() {
    setAnchorEl(null);
  }

  const id = open ? "delete-popover" : undefined;
  return (
    <>
      <Box onClick={onOpen} aria-describedby={id}>
        {children}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        slotProps={{ paper: { sx: { p: 2, maxWidth: 250 } } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack gap={2}>
          <Typography>{deleteMessage}</Typography>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Button variant="delete" onClick={() => onDeleteClick()}>
              {buttonText}
            </Button>
            <Button variant="cancel" onClick={onClose}>
              Avbryt
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default DeletePopover;

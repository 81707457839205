import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type {
  CreateActivityMutation,
  CreateActivityMutation$variables as Variables,
} from "./__generated__/CreateActivityMutation.graphql";

const mutation = graphql`
  mutation CreateActivityMutation($input: CreateActivityInput!) {
    createActivity(input: $input) {
      ok
      clonedSchedule {
        id
      }
    }
  }
`;

export async function CreateActivity(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<CreateActivityMutation>(environment, {
    mutation,
    variables,
  });
}

import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertTeamGroupDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import {
  VacationSettingTeamGroup_fragment$data as TeamGroupData,
  VacationSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/VacationSettingTeamGroup_fragment.graphql";
import { VacationForm } from "./form/VacationForm";
import { VacationInfo } from "./VacationInfo";

const teamGroupFragment = graphql`
  fragment VacationSettingTeamGroup_fragment on SettingNode {
    id
    constraintModules
  }
`;

const SETTING_NAME = "Semester";
const MODULE_NAME = "Vacation";
const SETTING_URL_ID = "vacation";

type NightShiftFormValues = Pick<TeamGroupData, "id" | "constraintModules">;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

function renderComponent(
  initialValues: NightShiftFormValues,
  onSubmit: (formValues: NightShiftFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <VacationForm />
    </BaseSettings>
  );
}

export const vacationSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  NightShiftFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  InfoComponent: VacationInfo,
};

import { useCallback } from "react";
import { FormControl, InputAdornment, Stack } from "@mui/material";

import { DeleteButton } from "components/common/buttons";
import CustomField from "components/forms/CustomField";
import { HardSoftRadios } from "components/setting/common/components/HardSoftRadios";

import { DayTypeSelect } from "./DayTypeSelect";

type Props = {
  name: string;
  onRemove: () => void;
  disabled?: boolean;
  readOnly?: boolean;
};

export function CadenceRestriction({
  name,
  disabled,
  readOnly,
  onRemove,
}: Props) {
  const getName = useCallback((s: string) => `${name}${s}`, [name]);

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
        <DayTypeSelect
          name={getName(".dayType")}
          label="Pass av typ"
          disabled={disabled}
          readOnly={readOnly}
        />
        <CustomField
          name={getName(".maxCadence")}
          label="Får förekomma högst"
          type="number"
          sx={{ width: 140 }}
          disabled={disabled}
          inputProps={{ readOnly }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">gånger</InputAdornment>
            ),
          }}
        />
        <CustomField
          name={getName(".calendarDays")}
          label="Inom kalenderdagar"
          type="number"
          sx={{ width: 140 }}
          disabled={disabled}
          inputProps={{ readOnly }}
          InputProps={{
            endAdornment: <InputAdornment position="end">dagar</InputAdornment>,
          }}
        />
        <FormControl>
          <Stack sx={{ mb: 0.3, ml: 2 }}>
            <HardSoftRadios
              name={getName(".isSoft")}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        </FormControl>
      </Stack>
      {!readOnly && <DeleteButton onClick={onRemove} disabled={disabled} />}
    </Stack>
  );
}

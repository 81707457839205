import { InputAdornment, Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import CustomField from "components/forms/CustomField";
import { HardSoftRadios } from "components/setting/common/components/HardSoftRadios";

import { consecutiveWorkSetting } from "./ConsecutiveWorkSetting";

type Props = {
  readOnly?: boolean;
};

type SubFormProps = Props & {
  disabled: boolean;
};

function Rhythm({ disabled, readOnly }: SubFormProps) {
  return (
    <PaperWrapper>
      <Stack gap={5}>
        <Stack gap={1}>
          <Typography variant="h4">
            Maximum antal arbetskalenderdagar i följd
          </Typography>
          <Typography>
            Detta fält styr hur många sammanhängande arbetskalenderdagar i följd{" "}
            <i>som mest</i> får lov att schemaläggas. Notera! Ett nattpass
            räknas endast till den kalenderdag då passet startar.
          </Typography>
          <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
            <CustomField
              name="maxConsecutiveWorkDays"
              size="small"
              type="number"
              disabled={disabled}
              sx={{ minWidth: 200 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">dagar</InputAdornment>
                ),
              }}
            />
            <HardSoftRadios
              name="maxConsecutiveWorkDaysSoft"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        </Stack>

        <Stack gap={1}>
          <Typography variant="h4">
            Minimum antal arbetskalenderdagar i följd
          </Typography>
          <Typography>
            Detta fält styr hur många sammanhängande arbetskalenderdagar i följd{" "}
            <i>som minst</i> får lov att schemaläggas. Notera! Ett nattpass
            räknas endast till den kalenderdag då passet startar.
          </Typography>
          <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
            <CustomField
              name="minConsecutiveWorkDays"
              size="small"
              type="number"
              disabled={disabled}
              sx={{ minWidth: 200 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">dagar</InputAdornment>
                ),
              }}
            />
            <HardSoftRadios
              name="minConsecutiveWorkDaysSoft"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        </Stack>
      </Stack>
    </PaperWrapper>
  );
}

export function ConsecutiveWork({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    consecutiveWorkSetting.moduleName,
  );

  return <Rhythm disabled={disabled} readOnly={readOnly} />;
}

export type DayShort = "Mån" | "Tis" | "Ons" | "Tors" | "Fre" | "Lör" | "Sön";
export type DayLong =
  | "Måndag"
  | "Tisdag"
  | "Onsdag"
  | "Torsdag"
  | "Fredag"
  | "Lördag"
  | "Söndag";
export type Day = DayShort | DayLong;

export const SHORT_DAYS: ReadonlyArray<DayShort> = [
  "Mån",
  "Tis",
  "Ons",
  "Tors",
  "Fre",
  "Lör",
  "Sön",
];

export const LONG_DAYS: ReadonlyArray<DayLong> = SHORT_DAYS.map(
  (d) => `${d}dag` as DayLong,
);

import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultTeamGroupSubmitFunction,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import {
  DayRestSettingRuleGroup_fragment$data as RuleGroupData,
  DayRestSettingRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/DayRestSettingRuleGroup_fragment.graphql";
import {
  DayRestSettingTeamGroup_fragment$data as TeamGroupData,
  DayRestSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/DayRestSettingTeamGroup_fragment.graphql";
import {
  DayRestSettingUserSetting_fragment$data as UserSettingData,
  DayRestSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/DayRestSettingUserSetting_fragment.graphql";
import { DayRest } from "./form/DayRestForm";
import { DayRestTeamGroupOnlyForm } from "./form/DayRestTeamGroupOnlyForm";

const teamGroupFragment = graphql`
  fragment DayRestSettingTeamGroup_fragment on SettingNode {
    id
    minDailyRestAbHours
    dayBreakAlternatives
    minDailyRestAbSoft
    minDailyRestAbWeight
    constraintModules
  }
`;

const userSettingFragment = graphql`
  fragment DayRestSettingUserSetting_fragment on UserSettingNode {
    id
    dayBreakAlternatives
    settingModules
  }
`;

const ruleGroupFragment = graphql`
  fragment DayRestSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    dayBreakAlternatives
    settingModules
  }
`;

const SETTING_NAME = "Dygnsvila";
const MODULE_NAME = "DailyRestAB";
const SETTING_URL_ID = "day-rest-ab";

type DayRestFormValues = Pick<
  TeamGroupData,
  "id" | "dayBreakAlternatives" | "constraintModules"
>;

type DayRestTeamGroupFormValues = Pick<
  TeamGroupData,
  | "id"
  | "minDailyRestAbHours"
  | "minDailyRestAbSoft"
  | "constraintModules"
  | "minDailyRestAbWeight"
>;

const validationSchema = yup.object().shape({
  dayBreakAlternatives: yup
    .array()
    .of(yup.number().min(0, "Måste vara minst 0 minuter"))
    .required("Måste anges"),
  constraintModules: yup.array().of(yup.string()).required("Måste anges"),
});

const teamGroupValidationSchema = yup.object().shape({
  minDailyRestAbHours: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(24, "Måste vara högst 24 timmar")
    .required("Får ej vara tomt"),
});

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): DayRestFormValues {
  return {
    ...teamGroupData,
  };
}

function convertUserOrRuleGroupDataToInitialValues(
  data: UserSettingData | RuleGroupData,
): DayRestFormValues {
  const { settingModules, ...rest } = data;
  return {
    ...rest,
    constraintModules: data.settingModules,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): DayRestTeamGroupFormValues {
  return {
    ...teamGroupData,
  };
}

function renderComponent(
  initialValues: DayRestFormValues,
  onSubmit: (formValues: DayRestFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <DayRest />
    </BaseSettings>
  );
}
function renderTeamGroupComponent(
  initialValues: DayRestTeamGroupFormValues,
  onSubmit: (formValues: DayRestTeamGroupFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <DayRestTeamGroupOnlyForm />
    </BaseSettings>
  );
}

export const dayRestSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  RuleGroupKey,
  DayRestFormValues,
  {},
  DayRestTeamGroupFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    renderComponent: renderTeamGroupComponent,
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
  },
};

import Box from "@mui/material/Box";
import { linearGradient } from "helpers/stylingFunctions";

type Props = {
  color: string | string[];
  size?: number;
};

export default function ColorDot({ color, size = 16 }: Props) {
  return (
    <Box
      sx={{
        minWidth: size,
        minHeight: size,
        width: size,
        height: size,
        borderRadius: "50%",
        background:
          typeof color === "string" ? color : linearGradient(color, -45),
      }}
    />
  );
}

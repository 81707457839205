import { useMemo } from "react";
import useFormikState from "hooks/useFormikState";

import GenericSelect from "components/common/selectors/GenericSelect";
import type { MemberTeamGroupType } from "components/organisation/types";

type Props = {
  name: string;
  teamGroups: MemberTeamGroupType[];
  disableCloseOnSelect?: boolean;
};

export function TeamGroupSelect({
  name,
  teamGroups,
  disableCloseOnSelect,
}: Props) {
  const { value, setValue } = useFormikState(name);

  const handleChange = (
    _event: any,
    newValue: MemberTeamGroupType[] | null,
  ) => {
    const v = (newValue || []).map((x) => x.id);
    setValue(v);
  };

  const selected = useMemo(
    () => teamGroups.filter((tg) => value.includes(tg.id)),
    [teamGroups, value],
  );

  return (
    <GenericSelect<MemberTeamGroupType>
      multiple
      value={selected}
      onChange={handleChange}
      options={teamGroups}
      label="Enheter"
      getOptionLabel={(option) => option.name}
      placeholder={selected.length ? "" : "Välj enheter..."}
      disableCloseOnSelect={disableCloseOnSelect}
    />
  );
}

// Setting modules that can be enabled for team groups
export const CONSTRAINT_MODULES = new Set([
  "ConsecutiveWorkDays",
  "ShiftDayTypeDistribution",
  "ShiftDayTypeFairness",
  "ShiftDayTypeCadence",
  "WorkDayCadence",
  "ConsecutiveFreeDays",
  "DailyRestAB",
  "DailyRest",
  "WeeklyRestAB",
  "FreeWeekends",
  "ShiftTypeDistribution",
  "ResourceTime",
  "AdminTime",
  "SmoothResources",
  "SplitShifts",
  "ClockwiseRotation",
  "WishedDaysOff",
  "Vacation",
  "NightShifts",
]);

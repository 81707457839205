import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import SettingBox from "../../SettingBox";

import { CompetenceDemandSettings } from "./CompetenceDemandSettings";
import { DemandCompetenceInfo } from "./info";

type Props = {
  groupName: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export function Base8({ groupName, disabled, readOnly }: Props) {
  return (
    <SettingBox
      title={
        <>
          Kompetensbehov för enhet <b>{groupName}</b>
        </>
      }
      infoButton={<DemandCompetenceInfo />}
    >
      <Stack gap={5}>
        <Stack gap={1}>
          <Typography variant="h4">
            Kort om inställningarna för kompetensbehov
          </Typography>
          <Typography>
            Bemanningsbehov för respektive kompetens anges under fliken som
            heter <Link to={"/demand"}>Behovsprofil</Link>. Algoritmen kommer
            göra sitt bästa för att täcka behovet som anges i behovsprofilen.
            Men, om antalet personer inte räcker till, eller andra schemaregler
            är för snävt satta, så kanske inte hela behovsprofilen går att
            täcka.
          </Typography>
          <Typography>
            Här kan du alltså styra om schemat ska tillåta underbemanning i de
            fall det är nödvändigt, för respektive kompetens. Vi rekommenderar
            att underbemanning alltid ska vara tillåtet - för om det inte går
            att täcka behovsprofilen (exempelvis på grund av att antal personer
            med kompetensen är för få), och detta val är påslaget, då kommer
            algoritmen inte kunna skapa något schema alls.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography variant="h4">
            Behovsinställningar för respektive kompetens
          </Typography>
          <CompetenceDemandSettings
            name="competenceDemandSettings"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>
    </SettingBox>
  );
}

import { InputAdornment, Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { WeightAndHardSoftFields } from "settings/common/TeamGroupOnlyForms";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import CustomField from "components/forms/CustomField";

import { dayRestSetting } from "../DayRestSetting";

type Props = {
  readOnly?: boolean;
};

export function DayRestTeamGroupOnlyForm({ readOnly = false }: Props) {
  const disabled = !useIsConstraintModuleEnabled(dayRestSetting.moduleName);

  return (
    <PaperWrapper>
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography variant="h4">Dygnsvilans längd (timmar)</Typography>
          <Typography>
            Detta fält styr hur lång dygnsvilan minst ska vara, uttryckt i antal
            timmar.
          </Typography>
          <CustomField
            fullWidth
            size="small"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">timmar</InputAdornment>
              ),
              readOnly,
            }}
            name="minDailyRestAbHours"
            disabled={disabled}
          />
        </Stack>
        <WeightAndHardSoftFields
          moduleName={dayRestSetting.moduleName}
          softFieldName="minDailyRestAbSoft"
          weightFieldName="minDailyRestAbWeight"
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

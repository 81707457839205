import { SyntheticEvent, useCallback } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteValue,
  TextField,
} from "@mui/material";

type DisableMulti = false;
type DisableFreeSolo = false;
export type DisableClearable = true;
export type EnableClearable = false;

export type SingleAutoSelectProps<
  Value,
  DisableClearableInternal extends boolean,
> = Pick<
  AutocompleteProps<
    Value,
    DisableMulti,
    DisableClearableInternal,
    DisableFreeSolo
  >,
  | "value"
  | "defaultValue"
  | "renderOption"
  | "getOptionLabel"
  | "noOptionsText"
  | "renderGroup"
  | "groupBy"
  | "size"
  | "disableClearable"
  | "sx"
> & {
  options: ReadonlyArray<Value>;
  onChange?: DisableClearableInternal extends true
    ? (newValue: Value) => void
    : (newValue: Value | null) => void;
  placeholder?: string;
  label?: string;
};

export function SingleAutoSelect<
  Value,
  DisableClearableInternal extends boolean,
>({
  onChange,
  size = "small",
  placeholder,
  label,
  disableClearable,
  ...props
}: SingleAutoSelectProps<Value, DisableClearableInternal>) {
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        placeholder={placeholder}
        label={label}
        sx={{ bgcolor: "background.default", borderRadius: 1 }}
      />
    ),
    [placeholder, label],
  );

  const onChangeInternal = useCallback(
    (
      _e: SyntheticEvent,
      newValue: AutocompleteValue<
        Value,
        DisableMulti,
        DisableClearableInternal,
        DisableFreeSolo
      >,
    ) => {
      onChange?.(newValue!);
    },
    [onChange],
  );

  return (
    <Autocomplete<
      Value,
      DisableMulti,
      DisableClearableInternal,
      DisableFreeSolo
    >
      {...props}
      renderInput={renderInput}
      multiple={false}
      onChange={onChangeInternal}
      size={size}
      disableClearable={disableClearable}
    />
  );
}

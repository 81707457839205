/**
 * @generated SignedSource<<88cd7d71551ed5828a855301e1a4c88e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiScheduleTerminationConditionChoices = "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "INFEASIBLE_NOW_RERUN" | "MAX_STALL_TIME" | "MAX_TIME_LIMIT" | "OPTIMAL" | "OTHER" | "UNBOUNDED" | "UNKNOWN" | "%future added value";
export type OptimiserStatisticsQuery$variables = {
  id: string;
};
export type OptimiserStatisticsQuery$data = {
  readonly schedule: {
    readonly period: {
      readonly group: {
        readonly id: string;
      };
    };
    readonly terminationCondition: ApiScheduleTerminationConditionChoices | null;
    readonly " $fragmentSpreads": FragmentRefs<"MipLogChart_fragment" | "VariableSolutionsChart_fragment">;
  } | null;
};
export type OptimiserStatisticsQuery = {
  response: OptimiserStatisticsQuery$data;
  variables: OptimiserStatisticsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminationCondition",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamGroupNode",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OptimiserStatisticsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PeriodNode",
            "kind": "LinkedField",
            "name": "period",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MipLogChart_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VariableSolutionsChart_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OptimiserStatisticsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PeriodNode",
            "kind": "LinkedField",
            "name": "period",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jsonString",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mipgapLimit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "VariableSolutionsNode",
            "kind": "LinkedField",
            "name": "variableSolutions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variableName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variableGroup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variableWeight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variableSolution",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "variableContribution",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7192880d533dba45e8ef470a3af0dac9",
    "id": null,
    "metadata": {},
    "name": "OptimiserStatisticsQuery",
    "operationKind": "query",
    "text": "query OptimiserStatisticsQuery(\n  $id: ID!\n) {\n  schedule(id: $id) {\n    terminationCondition\n    period {\n      group {\n        id\n      }\n      id\n    }\n    ...MipLogChart_fragment\n    ...VariableSolutionsChart_fragment\n    id\n  }\n}\n\nfragment MipLogChart_fragment on ScheduleNode {\n  jsonString\n  mipgapLimit\n}\n\nfragment VariableSolutionsChart_fragment on ScheduleNode {\n  variableSolutions {\n    variableName\n    variableGroup\n    variableWeight\n    variableSolution\n    variableContribution\n  }\n}\n"
  }
};
})();

(node as any).hash = "c34bf5106c4cda96ec14b9e3b2f16640";

export default node;

import { List, ListItem, Stack, Typography } from "@mui/material";

import { InfoButtonAndModal, InfoModalProvider } from "components/layout";

import type { GroupSettingCardProps } from "./GroupSettingCard";
import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<GroupSettingCardProps, "title" | "action">;

const TITLE = "Timbank";
const listStyle = { listStyleType: "disc", pl: 2, pb: 0 };
const listItemStyle = { display: "list-item", py: 0.5 };

const Info = () => (
  <InfoModalProvider>
    <InfoButtonAndModal
      title="Hur fungerar Timbanken?"
      buttonLabel="Hur fungerar Timbanken?"
    >
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography variant="h4">Hur fungerar Timbanken?</Typography>
          <Typography>
            Du kan välja att mellan att slå på/av användningen av timbank.
            <List sx={listStyle}>
              <ListItem sx={listItemStyle}>
                <Typography fontWeight="bold">Timbanken är på</Typography>
                <Typography>
                  När timbanken är påslagen kommer medarbetare schemaläggas inom
                  de tillåtna timbanksgränserna. Om en medarbetare ska arbeta
                  148 timmar över en schemaperiod och den tillåtna
                  timbanksgränsen är ±2 timmar, så kommer alltså denna
                  medarbetare schemaläggas någonstans mellan 146 - 150 timmar.
                </Typography>
              </ListItem>
              <ListItem sx={listItemStyle}>
                <Typography fontWeight="bold">Timbanken är av</Typography>
                <Typography>
                  När timbanken är avslagen kommer alla medarbetare schemaläggas
                  på sitt exakta arbetstidsmått. Även medarbetare som ska
                  arbeta, säg, 143 timmar och 20 minuter över en schemaperiod
                  kommer få exakt så många timmar och minuter schemalagda.
                  Sällan kommer passens längd exakt stämma överens med alla
                  medarbetares arbetstidsmått. Detta innebär att eventuell över-
                  eller underskottstid fördelas i mindre portioner genom att
                  vissa utvalda pass förkortas eller förlängs.
                </Typography>
              </ListItem>
            </List>
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">Timbanksgränser</Typography>
          <Typography>
            Om timbanken är påslagen (se ovan), så kommer den att begränsas av
            de saldogränser som du anger. Du kan ange saldogränserna både per
            schemaperiod och ackumulerat över flera schemaperioder.
            Timbankssaldot för ett schema räknas till det ackumulerade saldot
            för respektive medarbetare endast för fastställda scheman.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">Nollsaldo</Typography>
          <Typography>
            Om detta fält är påslaget kommer algoritmen eftersträva att det
            totala timbankssaldot (sammanräknat för alla fastställda scheman)
            för varje medarbetare ligger så nära ±0 som möjligt. Det innebär att
            om en medarbetare sedan tidigare ligger på minus i totala
            timbankssaldo, så kommer algoritmen eftersträva att denna
            medarbetares timbankssaldo i nästkommande schema ligger på plus.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">
            Otillåtna förkortningar och förlängningar
          </Typography>
          <Typography>
            Om timbanken är avstängd (se ovan) så kan eventuell över- eller
            underskottstid behöva fördelas i mindre portioner genom att vissa
            utvalda pass förkortas eller förlängs. Du kan dock aktivt ställa in
            att vissa passtyper inte får ha en förlängd/förkortad start-
            respektive sluttid. I så fall lämnas passets start- respektive
            sluttid oförändrade.
          </Typography>
        </Stack>
      </Stack>
    </InfoButtonAndModal>
  </InfoModalProvider>
);

export function TimebankCard(props: Props) {
  return <GroupSettingCard title={TITLE} action={<Info />} {...props} />;
}

TimebankCard.id = "timebank" as const;
TimebankCard.title = TITLE;

/**
 * @generated SignedSource<<dccc30abd3db2c8031aa9499bf91a9df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingMinWeeklyRestAbWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WeekRestSettingTeamGroup_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly minWeeklyRestAbHours: number;
  readonly minWeeklyRestAbSoft: boolean;
  readonly minWeeklyRestAbWeight: ApiSettingMinWeeklyRestAbWeightChoices;
  readonly weekBreakDaysAlternatives: ReadonlyArray<number>;
  readonly weekBreakHourAlternatives: ReadonlyArray<number>;
  readonly weeklyDailyCanOverlap: boolean;
  readonly " $fragmentType": "WeekRestSettingTeamGroup_fragment";
};
export type WeekRestSettingTeamGroup_fragment$key = {
  readonly " $data"?: WeekRestSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WeekRestSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeekRestSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minWeeklyRestAbHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weeklyDailyCanOverlap",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minWeeklyRestAbSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minWeeklyRestAbWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakHourAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekBreakDaysAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "ff2fc5415cf0a22081df50c908a2ad9b";

export default node;

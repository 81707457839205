import { InputAdornment } from "@mui/material";

import Field from "components/forms/CustomField";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function MinDailyRestHours({ disabled, readOnly }: Props) {
  return (
    <Field
      name="minDailyRestHours"
      fullWidth
      size="small"
      type="number"
      disabled={disabled}
      InputProps={{
        readOnly,
        endAdornment: <InputAdornment position="end">timmar</InputAdornment>,
      }}
    />
  );
}

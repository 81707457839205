import { useCallback } from "react";
import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { LabeledHardSoftRadios } from "../common/LabeledHardSoftRadios";
import { LabeledWeightSelect } from "../common/LabeledWeightSelect";

import { CompetenceLabel } from "./CompetenceLabel";

type Props = { name: string; disabled?: boolean; readOnly?: boolean };

export function CompetenceDemandSetting({ name, disabled, readOnly }: Props) {
  const { value: allowUnderstaffing } = useFormikState<boolean>(
    `${name}.allowUnderstaffing`,
  );

  const getName = useCallback(
    (fieldName: string) => `${name}.${fieldName}`,
    [name],
  );

  return (
    <Stack
      columnGap={6}
      rowGap={2}
      direction="row"
      flexWrap={{ xs: "wrap", lg: "nowrap" }}
    >
      <Stack columnGap={6} rowGap={2} direction="row">
        <Stack>
          <CompetenceLabel
            name={getName("competence")}
            pt={2.95}
            minWidth={110}
            width={110}
          />
        </Stack>
        <LabeledHardSoftRadios
          name={getName("allowUnderstaffing")}
          label="Tillåt underbemanning"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <LabeledWeightSelect
        name={getName("understaffingWeight")}
        label="Prioritet för minimering av total underbemanning"
        disabled={disabled || !allowUnderstaffing}
        readOnly={readOnly}
        sx={{ width: 150 }}
      />
    </Stack>
  );
}

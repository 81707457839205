import { useCallback, useMemo } from "react";
import type { Serie } from "@nivo/line";

import type { View, ViewDataPoint, Viewer } from "./types";
import {
  addMissingTimestamps,
  resolveColor,
  resolveId,
  resolveName,
} from "./utils";

type DataProps = {
  viewer: Viewer;
  teamId: string;
  competenceId: string;
  allTeams: boolean;
  allCompetences: boolean;
  windowStart: Date;
  windowEnd: Date;
};

type ChartDataProps = {
  items: Serie[];
  filteredViews: View[];
  allTeams: boolean;
  allCompetences: boolean;
};

export function useDemandProfileData({
  viewer,
  teamId,
  competenceId,
  allTeams,
  allCompetences,
  windowStart,
  windowEnd,
}: DataProps) {
  const teamFilter = useCallback(
    (item: View) => item?.team?.id === teamId,
    [teamId],
  );
  const competenceFilter = useCallback(
    (item: View) => item?.competence?.id === competenceId,
    [competenceId],
  );

  // Filter data based on axis selections
  const filteredViews = useMemo<View[]>(
    () =>
      (viewer || [])
        .filter((v) => {
          if (allTeams) return !v?.competence;
          if (allCompetences) return !v?.competence && teamFilter(v);
          return teamFilter(v) && (!v.competence || competenceFilter(v));
        })
        .reverse(),
    [viewer, allTeams, allCompetences, teamFilter, competenceFilter],
  );

  const viewDataPointToDatum = useCallback(
    (key: keyof ViewDataPoint) => (d: ViewDataPoint) => ({
      x: d.dt,
      y: d[key],
    }),
    [],
  );
  const viewToItem = useCallback(
    (v: View) => ({
      id: resolveId(v),
      data: v.data.map(viewDataPointToDatum("demand")),
    }),
    [viewDataPointToDatum],
  );

  const emptyProfile = useMemo<Serie[]>(
    () => [
      {
        id: teamId,
        data: [
          {
            x: windowStart,
            y: 0,
          },
        ],
      },
    ],
    [teamId, windowStart],
  );

  const cutWithinWindowMapper = useCallback(
    (item: Serie) => {
      const filteredData = item.data.filter((d) => {
        if (!d?.x) return false;
        const dx = new Date(d.x);
        return dx >= windowStart && dx <= windowEnd;
      });
      return { ...item, data: filteredData };
    },
    [windowStart, windowEnd],
  );

  const items = useMemo<Serie[]>(() => {
    if (filteredViews.length < 1) return emptyProfile;

    return addMissingTimestamps(filteredViews.map(viewToItem)).map(
      cutWithinWindowMapper,
    );
  }, [emptyProfile, filteredViews, cutWithinWindowMapper, viewToItem]);

  return useMemo(() => ({ filteredViews, items }), [filteredViews, items]);
}

export function useDemandProfileChartData({
  items,
  filteredViews,
  allTeams,
  allCompetences,
}: ChartDataProps) {
  const viewToLegendData = useCallback(
    (v: View) => ({
      id: resolveId(v),
      label: resolveName(v),
      color: resolveColor(v),
    }),
    [],
  );
  const legendData = useMemo(
    () => filteredViews.map(viewToLegendData),
    [filteredViews, viewToLegendData],
  );
  const maxLegend = useMemo(
    () => Math.max(...legendData.map((i) => i.label.length)),
    [legendData],
  );
  const maxY = useMemo(() => {
    if (allCompetences)
      return Math.max(
        ...Object.values(
          items
            .flatMap((i) => i.data)
            .reduce(
              (acc, d) => {
                if (!d.x) return acc;
                const x: string = d.x as string;
                if (!acc[x]) acc[x] = 0;
                acc[x] += d.y as number;
                return acc;
              },
              {} as Record<string, number>,
            ),
        ),
        1,
      );
    return Math.max(...items.flatMap((i) => i.data.map((e) => e.y as number)));
  }, [items, allCompetences]);

  const yGrids = useMemo(
    () => (maxY < 10 ? Array.from(Array(maxY + 1).keys()) : undefined),
    [maxY],
  );

  const chartColors = useMemo(() => {
    if (!allCompetences) {
      const color =
        filteredViews.find((v) => v.competence)?.competence?.color ??
        "lightgrey";
      return [color, "lightgrey"];
    }
    if (!allTeams) {
      const color =
        filteredViews.find((v) => v.team)?.team?.color ?? "lightgrey";
      return [color, "lightgrey"];
    }
    return legendData.map((v) => v?.color).filter(Boolean);
  }, [filteredViews, legendData, allTeams, allCompetences]);

  return useMemo(
    () => ({ legendData, maxLegend, yGrids, chartColors }),
    [legendData, maxLegend, yGrids, chartColors],
  );
}

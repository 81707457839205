import * as yup from "yup";

import { priorityWeightOptions } from "../types";

const demandItemSchema = yup.object().shape({
  demand: yup
    .number()
    .min(1, "Måste vara minst 1")
    .required("Måste vara minst 1"),
  dutyShare: yup
    .number()
    .min(0, "Måste vara positivt")
    .test("dutyLessThanDemand", "Får max vara behovet", function (val) {
      return !val || val <= this.parent.demand;
    }),
  enforceDuty: yup.boolean(),
  start: yup.string().required("Måste ha en starttid"),
  end: yup.string().required("Måste ha en sluttid"),
  minimizeOverstaffingWeight: yup
    .string()
    .oneOf(priorityWeightOptions)
    .required(),
  minimizeUnderstaffingWeight: yup
    .string()
    .oneOf(priorityWeightOptions)
    .required(),
});

const demandDaySchema = yup.object().shape({
  days: yup
    .array()
    .of(yup.number().required())
    .min(1, "Välj minst 1 dag")
    .required(),
  demands: yup.array().of(demandItemSchema.required()).min(1).required(),
});

const demandWeekSchema = yup.object().shape({
  weeks: yup
    .array()
    .of(yup.number().required())
    .min(1, "Välj minst 1 vecka")
    .required(),
  demands: yup.array().of(demandDaySchema.required()).min(1).required(),
});

export const createDemandsValidationSchema = yup.object().shape({
  teamId: yup.string().required("teamId obligatoriskt"),
  competenceId: yup.string(),
  demands: yup
    .array()
    .of(demandWeekSchema.required())
    .required("Behovsspann saknas"),
});

import { useMemo } from "react";
import type { SelectChangeEvent } from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useFormikState } from "hooks";

type WeekBreakAlternative = {
  id: number;
  label: string;
};

type Props = {
  name: string;
  disabled: boolean;
  readOnly?: boolean;
};

const weekBreakAlternatives: WeekBreakAlternative[] = [
  { id: 0, label: "Måndag" },
  { id: 1, label: "Tisdag" },
  { id: 2, label: "Onsdag" },
  { id: 3, label: "Torsdag" },
  { id: 4, label: "Fredag" },
  { id: 5, label: "Lördag" },
  { id: 6, label: "Söndag" },
];

export function WeekBreakDaySelect({ name, disabled, readOnly }: Props) {
  const { value, setValue } = useFormikState<number[]>(name);
  const fieldValue = useMemo(() => value[0], [value]);

  const handleChange = (event: SelectChangeEvent<number>) => {
    const { value } = event.target;
    setValue([value as number]);
  };
  const renderValue = (selected: number) => {
    return weekBreakAlternatives
      ?.filter((o) => selected === o.id)
      .map((x) => x.label)
      .join(", ");
  };

  return (
    <FormControl fullWidth>
      <Select
        size="small"
        name={name}
        labelId="day-break-alternative"
        value={fieldValue}
        renderValue={renderValue}
        onChange={handleChange}
        disabled={disabled}
        readOnly={readOnly}
      >
        {weekBreakAlternatives.map((d: WeekBreakAlternative) => (
          <MenuItem key={d.id} value={d.id}>
            {d.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

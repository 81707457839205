import type { ComponentProps, ReactNode } from "react";
import { useCallback } from "react";
import type { SelectChangeEvent } from "@mui/material";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { useField } from "formik";

export type Option = { value: any; label: string };

type Props = ComponentProps<typeof Select> & {
  name: string;
  options: Option[];
  renderOption?: (option: Option, selected: boolean) => ReactNode;
};

export default function CustomSelectField<T = string>({
  name,
  options,
  renderOption = (o, _) => o.label,
  ...p
}: Props) {
  const [field, meta, helpers] = useField<T>(name);
  const { touched, error } = meta;
  const showError = touched && !!error;

  const isSelected = useCallback(
    (option: Option) =>
      Array.isArray(field.value)
        ? field.value.includes(option.value)
        : field.value === option.value,
    [field.value],
  );

  function onChange(e: SelectChangeEvent<(typeof options)[number]["value"]>) {
    helpers.setValue(e.target.value);
  }

  return (
    <FormControl>
      <Select {...p} value={field.value} onChange={onChange} error={showError}>
        {options.map((o) => (
          <MenuItem key={o.label} value={o.value}>
            {renderOption(o, isSelected(o))}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

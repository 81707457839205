import {
  Visibility as ShowIcon,
  VisibilityOff as HideIcon,
} from "@mui/icons-material";
import type { TextFieldProps } from "@mui/material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useFormikState } from "hooks";
import { useBooleanState } from "utils/useBooleanState";

type ToggleAdornmentProps = {
  show: boolean;
  onClick?: () => void;
};
type Props = TextFieldProps;
type FormikProps = Omit<Props, "value" | "onChange"> & {
  name: string;
};

function ToggleAdornment({ show, onClick }: ToggleAdornmentProps) {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onClick}>
        {show ? <ShowIcon /> : <HideIcon />}
      </IconButton>
    </InputAdornment>
  );
}

function PasswordField(props: Props) {
  const { value: show, toggle } = useBooleanState();
  return (
    <TextField
      size="small"
      {...props}
      type={show ? "text" : "password"}
      InputProps={{
        endAdornment: <ToggleAdornment show={show} onClick={toggle} />,
      }}
    />
  );
}

export function FormikPasswordField({ name, ...props }: FormikProps) {
  const { setValue } = useFormikState<string>(name);
  return (
    <PasswordField onChange={(e) => setValue(e.target.value)} {...props} />
  );
}

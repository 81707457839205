/**
 * @generated SignedSource<<379144dc66683bb9b4857d1fa3e6f4cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetencesTable_fragment$data = ReadonlyArray<{
  readonly archivedAt: string | null;
  readonly color: string;
  readonly description: string;
  readonly id: string;
  readonly isActive: boolean;
  readonly name: string;
  readonly " $fragmentType": "CompetencesTable_fragment";
}>;
export type CompetencesTable_fragment$key = ReadonlyArray<{
  readonly " $data"?: CompetencesTable_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetencesTable_fragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CompetencesTable_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archivedAt",
      "storageKey": null
    }
  ],
  "type": "CompetenceNode",
  "abstractKey": null
};

(node as any).hash = "7265b7c82655c18648a9bae7bf4e5252";

export default node;

import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { CadenceRestrictions } from "components/setting/common/forms/Types7/CadenceRestrictions";

import { dayNightCadenceSetting } from "./DayNightCadenceSetting";

type Props = {
  readOnly?: boolean;
};

type CadenceProps = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function DayNightCadence({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    dayNightCadenceSetting.moduleName,
  );

  return <Cadence disabled={disabled} readOnly={readOnly} />;
}

function Cadence({ disabled, readOnly }: CadenceProps) {
  return (
    <PaperWrapper>
      <Stack gap={5}>
        <Stack gap={1}>
          <Typography variant="h4">Kadensregler för Dag/Kväll/Natt</Typography>
          <Typography>
            Här kan du skapa egna regler för att styra kadensen av dags-,
            kvälls- och nattpass.
          </Typography>
        </Stack>
        <CadenceRestrictions
          name="cadenceRestrictions"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

import Field from "components/forms/CustomField";

type Props = {
  name: string;
};
export type TimeFieldProps = Props;

export function TimeField({ name }: Props) {
  return <Field name={name} type="time" fullWidth />;
}

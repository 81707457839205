import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import type { SelectorStoreUpdater } from "relay-runtime";

import { DeleteTeamGroupMutation as Mutation } from "./types";

const mutation = graphql`
  mutation DeleteTeamGroupMutation($id: ID!) {
    deleteTeamGroup(input: { id: $id }) {
      ok
      deletedId @deleteRecord
    }
  }
`;

const updater: SelectorStoreUpdater<Mutation["response"]> = (store) => {
  // with the @deleteRecord directive, the record is deleted from the store and be null in the list
  const root = store.getRoot();
  const records = root.getLinkedRecords("allTeamGroups");
  if (records) {
    // remove null records
    root.setLinkedRecords(records.filter(Boolean), "allTeamGroups");
  }
};

export const useDeleteTeamGroup = () =>
  useMutation<Mutation>(mutation, { updater });

import { NumberCircle } from "./common/components/NumberIcon";
import type { Section } from "./SettingsPanel/types";
import type { ExceptionPaneEntry } from "./SettingsPanel";
export const exceptionsPane = (
  users: ExceptionPaneEntry[],
  query: string | null,
  ruleGroups: ExceptionPaneEntry[],
): Section[] => [
  {
    title: "Regelgrupper",
    elements: filterAndConvertEntries(ruleGroups, query),
  },
  {
    title: "Personer",
    elements: filterAndConvertEntries(users, query),
  },
];

function filterAndConvertEntries(
  entries: ExceptionPaneEntry[],
  query: string | null,
) {
  return entries
    .filter((entry) => !query || entry.label.toLowerCase().includes(query))
    .filter((entry) => (entry.ruleExceptions ?? 0) > 0)
    .map((entry) => ({
      id: entry.value,
      title: entry.label,
      endAdornment: <NumberCircle>{entry.ruleExceptions}</NumberCircle>,
    }));
}

import type { ThemeOptions } from "@mui/material";
import { createTheme } from "@mui/material";
import { svSE } from "@mui/material/locale";

import { components } from "./components";
import { palette } from "./light-palette";
import { typography } from "./typography";

const lightTheme: ThemeOptions = {
  palette,
  typography,
  components,
};

export default createTheme(lightTheme, svSE);
export { components, lightTheme, palette, typography };

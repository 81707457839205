import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertTeamGroupDataToInitialValues,
  dummySubmit,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { useUpdateSettingsMutation } from "components/setting/team_group/setting_boxes/updateSettings";

import {
  RequestedDaysOffSettingTeamGroup_fragment$data as TeamGroupData,
  RequestedDaysOffSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/RequestedDaysOffSettingTeamGroup_fragment.graphql";
import { WishedDaysOffForm } from "./form/WishedDaysOffForm";

const teamGroupFragment = graphql`
  fragment RequestedDaysOffSettingTeamGroup_fragment on SettingNode {
    id
    constraintModules
    maximizeApprovedWishes
    optimizeApprovedWishesDistributionBetweenEmployees
    wishedFreeDaysWeight
  }
`;

const SETTING_NAME = "Önskade fridagar";
const MODULE_NAME = "WishedDaysOff";
const SETTING_URL_ID = "wished-days-off";

type RequestedDaysOffFormValues = Pick<
  TeamGroupData,
  | "id"
  | "constraintModules"
  | "maximizeApprovedWishes"
  | "optimizeApprovedWishesDistributionBetweenEmployees"
  | "wishedFreeDaysWeight"
>;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()).required(),
  maximizeApprovedWishes: yup.boolean().required(),
  optimizeApprovedWishesDistributionBetweenEmployees: yup.boolean().required(),
});

function useSubmitFunctions() {
  const [updateSettings] = useUpdateSettingsMutation();

  const onSubmitTeamGroup = useCallback(
    (input: RequestedDaysOffFormValues) =>
      updateSettings({ variables: { input } }),
    [updateSettings],
  );

  return {
    onSubmitTeamGroup,
    onSubmitUser: dummySubmit,
    onSubmitRuleGroup: dummySubmit,
  };
}

function renderComponent(
  initialValues: RequestedDaysOffFormValues,
  onSubmit: (formValues: RequestedDaysOffFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <WishedDaysOffForm />
    </BaseSettings>
  );
}

export const requestedDaysOffSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  RequestedDaysOffFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions,
  renderComponent,
};

import { useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Button, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  FieldArray,
  Form as FormikForm,
  Formik,
  useFormikContext,
} from "formik";
import type { Writeable } from "types";
import type { FormikFieldArrayFn } from "types/formik";

import { useCreateDemands } from "components/demand/mutations/CreateDemands";
import { useInfoModal } from "components/layout/InfoModal/InfoModalContext";
import { useSnackbar } from "components/Snackbar";

import type { CreateDemandsInput } from "../types";

import { initialDemandDay, initialDemandWeek } from "./constants";
import { DemandDaysForm } from "./DemandDaysForm";
import { DemandFormProvider, useDemandForm } from "./DemandFormContext";
import { DemandWeeksForm } from "./DemandWeeksForm";
import { Footer } from "./Footer";
import { useDemandFormInitialValues } from "./hooks";
import type { DemandForm_fragment$key as Key } from "./types";
import { createDemandsValidationSchema as validationSchema } from "./validation_schema";

const fragment = graphql`
  fragment DemandForm_fragment on DemandFormViewer {
    demands {
      team {
        name
        id
        color
      }
      demands {
        weeks
        demands {
          days
          demands {
            demand
            dutyShare
            enforceDuty
            start
            end
            minimizeUnderstaffingWeight
            minimizeOverstaffingWeight
          }
        }
      }
    }
    demandCompetences {
      team {
        name
        id
        color
      }
      competence {
        name
        id
        color
      }
      demands {
        weeks
        demands {
          days
          demands {
            demand
            dutyShare
            start
            end
            minimizeUnderstaffingWeight
            minimizeOverstaffingWeight
          }
        }
      }
    }
  }
`;

type FormValues = Writeable<CreateDemandsInput>;

type Props = {
  fragmentRef: Key;
  teamId: string;
  competenceId: string | null;
  weekCount: number;
  uniqueWeeks: boolean;
  showDutyDemand: boolean;
  showDemandWeights: boolean;
  afterSubmit: () => Promise<void>;
};

const UNSELECTED = -1;

function Form() {
  const { weekCount, uniqueWeeks } = useDemandForm();
  const { values } = useFormikContext<CreateDemandsInput>();
  const [dayIdx, setDayIdx] = useState<number>(UNSELECTED);
  const [weekIdx, setWeekIdx] = useState<number>(UNSELECTED);
  const initWeekForSameWeeks = useMemo(
    () => ({
      weeks: Array.from({ length: weekCount }).map((_, i) => i),
      demands: [initialDemandDay],
    }),
    [weekCount],
  );

  return (
    <FormikForm autoComplete="off">
      <FieldArray name="demands">
        {({ push: pushWeek }: FormikFieldArrayFn<FormValues>) => (
          <FieldArray name="demands.0.demands">
            {({ push: pushDay }: FormikFieldArrayFn<FormValues>) => (
              <Stack gap={2}>
                {uniqueWeeks ? (
                  <>
                    <DemandWeeksForm
                      name="demands"
                      weekIdx={weekIdx}
                      setWeekIdx={setWeekIdx}
                      dayIdx={dayIdx}
                      setDayIdx={setDayIdx}
                    />
                    <Footer
                      onAdd={() => {
                        pushWeek(initialDemandWeek);
                        setWeekIdx(values.demands.length);
                      }}
                      addLabel="Lägg till veckoblock"
                    />
                  </>
                ) : (
                  <>
                    <DemandDaysForm
                      name="demands.0.demands"
                      dayIdx={dayIdx}
                      setDayIdx={setDayIdx}
                    />
                    <Footer
                      onAdd={() => {
                        if (values.demands.length > 0) {
                          pushDay(initialDemandDay);
                        } else {
                          pushWeek(initWeekForSameWeeks);
                        }
                        setDayIdx(values.demands.length + 1);
                      }}
                      addLabel="Lägg till dagblock"
                    />
                  </>
                )}
              </Stack>
            )}
          </FieldArray>
        )}
      </FieldArray>
    </FormikForm>
  );
}

export function DemandForm({
  fragmentRef,
  competenceId,
  teamId,
  weekCount,
  uniqueWeeks,
  showDutyDemand,
  showDemandWeights,
  afterSubmit,
}: Props) {
  const { addSnack } = useSnackbar();
  const formDemands = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useCreateDemands();
  const initialValues = useDemandFormInitialValues(
    teamId,
    competenceId,
    formDemands,
  );
  const { setOpen: setInfoOpen } = useInfoModal();

  async function onSubmit(input: FormValues) {
    try {
      const res = await commit({ variables: { input } });
      addSnack({
        message: "Ändringarna sparades",
        severity: "success",
        closeAfterMs: 2000,
      });
      if (res.createDemands?.demandsWereMerged) {
        addSnack({
          message: "Behovspunkter has slagits ihop",
          severity: "info",
          action: <Button onClick={() => setInfoOpen(true)}>Läs mer</Button>,
        });
      }
      return afterSubmit();
    } catch (error) {
      console.error(error);
      addSnack({
        message: "Något gick fel. Kunde inte spara ändringar",
        severity: "error",
        closeAfterMs: 2000,
      });
    }
  }

  return (
    <DemandFormProvider
      value={{ weekCount, uniqueWeeks, showDutyDemand, showDemandWeights }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={true}
        enableReinitialize
      >
        <Form />
      </Formik>
    </DemandFormProvider>
  );
}

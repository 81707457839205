import type { CSSObject } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import WeightSelect from "components/common/selectors/WeightSelect";

type EitherOr =
  | { name: string; value?: undefined }
  | { name?: undefined; value: string };

type Props = EitherOr & {
  disabled?: boolean;
  readOnly?: boolean;
  sx?: CSSObject;
};

const noop = () => {};

export function HeaderWeight({
  name = "",
  value,
  disabled = false,
  readOnly,
  sx = {},
}: Props) {
  const { value: formValue, setValue } = useFormikState<string>(name);
  return (
    <WeightSelect
      value={value || formValue?.toUpperCase()}
      handleChange={value !== undefined ? noop : setValue}
      disabled={disabled}
      readOnly={readOnly}
      sx={{ minWidth: 200, justifyContent: "flex-end", ...sx }}
    />
  );
}

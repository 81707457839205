import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { connectionToArray } from "relay-help/arrays";

import { TeamsCard, TeamsListContent } from "components/group_settings";

import type { ScheduleGroupSettingsTeamList_teams$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment ScheduleGroupSettingsTeamList_teams on TeamSnapshotNodeConnection {
    edges {
      node {
        name
        color
        members {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export function ScheduleGroupSettingsTeamList({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const teams = useMemo(
    () =>
      connectionToArray(data).map((team) => ({
        ...team,
        members: connectionToArray(team.members),
      })),
    [data],
  );

  return (
    <TeamsCard noAction>
      <TeamsListContent teams={teams} hideActions />
    </TeamsCard>
  );
}

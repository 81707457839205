/**
 * @generated SignedSource<<aba4f9ef0aa1a31d6245aab91481c913>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminCompetencesQuery$variables = {};
export type AdminCompetencesQuery$data = {
  readonly allCompetences: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CompetencesTable_fragment">;
  }>;
};
export type AdminCompetencesQuery = {
  response: AdminCompetencesQuery$data;
  variables: AdminCompetencesQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminCompetencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "allCompetences",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetencesTable_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminCompetencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "allCompetences",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90a9c77fbac8918c10ffaed92ccca749",
    "id": null,
    "metadata": {},
    "name": "AdminCompetencesQuery",
    "operationKind": "query",
    "text": "query AdminCompetencesQuery {\n  allCompetences {\n    ...CompetencesTable_fragment\n    id\n  }\n}\n\nfragment CompetencesTable_fragment on CompetenceNode {\n  id\n  name\n  description\n  color\n  isActive\n  archivedAt\n}\n"
  }
};

(node as any).hash = "5f30723333f6d87cbfc4aaa97bb4e1da";

export default node;

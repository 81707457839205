import { createContext, Suspense, useContext, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import { fetchQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { FullPageLoading } from "components/loading";

import environment from "../environment";

import type {
  PageQueryContextQuery as Query,
  PageQueryContextQuery$data as Data,
} from "./types";

type ContentProps = React.PropsWithChildren<{
  queryRef: PreloadedQuery<Query>;
}>;

const query = graphql`
  query PageQueryContextQuery {
    me {
      ...Me_me
    }
    teamGroups {
      ...TeamGroupsContext_teamgroups
      ...CurrentTeamGroup_teamgroups
      ...CurrentTeamGroupSelect_fragment
    }
  }
`;

const PageQueryContext = createContext<Data | null>(null);

export const usePageQuery = (): Data => {
  const context = useContext<Data | null>(PageQueryContext);

  if (!context) {
    throw new Error("usePageQuery must be used within PageQueryProvider");
  }

  return context as Data;
};

function Content({ queryRef, ...props }: ContentProps) {
  const value = usePreloadedQuery<Query>(query, queryRef);

  return <PageQueryContext.Provider value={value} {...props} />;
}

export function PageQueryProvider({ children }: React.PropsWithChildren) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    loadQuery({});
  }, [loadQuery]);

  return (
    <Suspense fallback={<FullPageLoading />}>
      {!!queryRef && <Content queryRef={queryRef} children={children} />}
    </Suspense>
  );
}

export const refresh = async () =>
  fetchQuery(environment, query, {}).toPromise();

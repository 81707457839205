import { InputAdornment } from "@mui/material";

import Field from "components/forms/CustomField";

type Props = {
  readOnly?: boolean;
};

export function MipgapLimit({ readOnly }: Props) {
  return (
    <Field
      name="mipgapLimit"
      fullWidth
      size="small"
      type="number"
      InputProps={{
        readOnly,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        style: { maxWidth: "105px" },
      }}
      inputProps={{ step: 0.5 }}
    />
  );
}

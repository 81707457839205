import { useMemo } from "react";
import { DateTime, Duration } from "luxon";
import { EMPTY_ARRAY } from "utils/constants";

import type { BaseTimelineItem, UserItem } from "./types";
import { activityTypes } from "./types";

const oneDay = Duration.fromObject({ days: 1 });

function dateToTimelineItem(date: Date, user: UserItem, week = false) {
  return {
    id: `${user.title}-${date.getTime()}`,
    group: user.id,
    itemTitle: `${user.title} ${week ? "vecko" : "dygns"}bryt`,
    start_time: date.getTime(),
    end_time: date.getTime() + 1,
  };
}

export function useUserBreaksToTimelineItem(
  groups: UserItem[],
  {
    periodStart,
    periodEnd,
  }: {
    periodStart: Date;
    periodEnd: Date;
  },
): {
  dayBreakItems: ReadonlyArray<BaseTimelineItem>;
  weekBreakItems: ReadonlyArray<BaseTimelineItem>;
} {
  const { dayDates, datesPerDay } = useMemo(() => {
    const dayDates: Date[] = [];
    const datesPerDay: Record<number, Date[]> = [];
    for (
      let dt: DateTime = DateTime.fromJSDate(periodStart);
      dt <= DateTime.fromJSDate(periodEnd);
      dt = dt.plus(oneDay)
    ) {
      const date = dt.toJSDate();
      dayDates.push(date);

      // DateTime weekdays are 1-indexed starting with Monday
      const weekday = dt.weekday - 1;
      if (datesPerDay[weekday] == null) {
        datesPerDay[weekday] = [];
      }
      datesPerDay[weekday].push(date);
    }
    return { dayDates, datesPerDay };
  }, [periodStart, periodEnd]);

  const dayBreakItems = useMemo<BaseTimelineItem[]>(
    () =>
      groups.flatMap((user) => {
        const { dayBreak } = user;
        if (!dayBreak) return EMPTY_ARRAY;

        const dayBreakDt = DateTime.fromFormat(dayBreak, "HH:mm:ss");

        const dayBreaks: BaseTimelineItem[] = dayDates
          .map((d) => {
            d.setHours(dayBreakDt.hour);
            d.setMinutes(dayBreakDt.minute);
            return d;
          })
          .map((d) => dateToTimelineItem(d, user))
          .map((i) => ({
            ...i,
            itemProps: {
              className: `rct-item-${(
                activityTypes.DAY_BREAK as string
              ).toLowerCase()}`,
            },
          }));
        return dayBreaks;
      }),
    [groups, dayDates],
  );

  const weekBreakItems = useMemo<BaseTimelineItem[]>(
    () =>
      groups.flatMap((user) => {
        const { weekBreakDay, weekBreak } = user;
        if (!weekBreak) {
          return EMPTY_ARRAY;
        }

        const weekBreakDt = DateTime.fromFormat(weekBreak, "HH:mm:ss");

        const weekBreaks: BaseTimelineItem[] =
          datesPerDay[weekBreakDay ?? 0]
            ?.map((d) => {
              d.setHours(weekBreakDt.hour);
              d.setMinutes(weekBreakDt.minute);
              return d;
            })
            .map((d) => dateToTimelineItem(d, user, true))
            .map((i) => ({
              ...i,
              itemProps: {
                className: `rct-item-${(
                  activityTypes.WEEK_BREAK as string
                ).toLowerCase()}`,
              },
            })) ?? EMPTY_ARRAY;

        return weekBreaks;
      }),
    [groups, datesPerDay],
  );

  return {
    dayBreakItems,
    weekBreakItems,
  };
}

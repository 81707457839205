import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertTeamGroupDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctionsOnlyTeamGroup,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import {
  OptimizationSettingTeamGroup_fragment$data as TeamGroupData,
  OptimizationSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/OptimizationSettingTeamGroup_fragment.graphql";
import { OptimizationForm } from "./OptimizationForm";
import { OptimizationInfo } from "./OptimizationInfo";

const teamGroupFragment = graphql`
  fragment OptimizationSettingTeamGroup_fragment on SettingNode {
    id
    mipgapLimit
  }
`;

const SETTING_NAME = "Optimering";
const MODULE_NAME = "OptimizationSettings";
const SETTING_URL_ID = "optimization";

type OptimizationSettingsValues = Omit<TeamGroupData, " $fragmentType">;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  mipgapLimit: yup
    .number()
    .required("får ej vara tomt")
    .min(0.01, "mipgap-gränsen måste vara större än 0")
    .max(100, "mipgap-gränsen måste vara mindre än 100"),
});

function renderComponent(
  initialValues: OptimizationSettingsValues,
  onSubmit: (formValues: OptimizationSettingsValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <OptimizationForm />
    </BaseSettings>
  );
}

export const optimizationSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  OptimizationSettingsValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctionsOnlyTeamGroup,
  renderComponent,
  InfoComponent: OptimizationInfo,
};

import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import PaperWrapper from "settings/common/PaperWrapper";

import { SmallSwitch } from "components/common/Switch";

const MODULE_NAME = "ShiftDayTypeFairness";

type FormProps = {
  readOnly?: boolean;
};
type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

function UserSettingForm({ disabled, readOnly }: Props) {
  return (
    <PaperWrapper>
      <Stack gap={5}>
        <Stack gap={2} direction="row">
          <Typography variant="h4">
            Inkludera i balansering av Dag-/Kväll-/Natt-rättvisa på helg
          </Typography>
          <SmallSwitch
            name="includeInDayTypeWeekendFairness"
            disabled={disabled}
          />
        </Stack>
        <Typography>
          Med denna inställning påslagen, så kommer optimeringsalgoritmen att
          inkludera användaren i rättvis balansering av Dag/Kväll/Natt mellan
          medarbetare på helger. Detta är alltid en mjuk regel.
        </Typography>
      </Stack>
    </PaperWrapper>
  );
}

export function DayNightFairnessUserOnlyForm({ readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return <UserSettingForm disabled={disabled} readOnly={readOnly} />;
}

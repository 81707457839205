import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

export default styled(Stack)(() => ({
  backgroundColor: "white",
  opacity: 1,
  padding: "5px 10px",
  border: "1px solid",
  borderColor: "lightgray",
  borderRadius: "4px",
}));

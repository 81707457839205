import ExpandIcon from "@mui/icons-material/ExpandMoreSharp";
import { Divider, MenuList, Stack, Typography } from "@mui/material";
import type { Unwrap } from "relay-help/arrays";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/Level2Accordion";
import { Level2Icon, Panel2, PanelItem } from "components/setting/common";

import type { MenuItem } from "../types";

type Props = Omit<React.ComponentProps<typeof Panel2>, "onSelect"> & {
  constraintModules: string[];
  ruleId: string;
  onSelect: (section: string, item: string) => void;
  menuItems: MenuItem[];
  showAdvanced?: boolean;
};

type SectionAccordionProps = {
  ruleId: string;
  constraintModules: string[];
  section: Unwrap<MenuItem>;
  onClick: (id: string) => void;
};

type MenuWrapperProps = Omit<
  Props,
  "ruleId" | "constraintModules" | "onSelect" | "menuItems"
>;

function SectionAccordion({
  constraintModules,
  section,
  onClick,
  ruleId,
}: SectionAccordionProps) {
  const menuItems = section.menuRows.map((m) => m.constraintModule);
  const expanded = ["Default", ...constraintModules].some((r) =>
    menuItems.includes(r),
  );

  return (
    <Stack gap={1}>
      <Accordion
        defaultExpanded={expanded}
        sx={{
          boxShadow: "none",
          "&:before": { display: "none" },
          m: 0,
          p: 0,
        }}
      >
        <AccordionSummary expandIcon={<ExpandIcon />} sx={{ px: 2 }}>
          <Stack gap={1} direction="row">
            {section?.icon}
            <Typography
              variant="h5"
              sx={{ display: "flex", alignItems: "center" }}
            >
              {section.sectionTitle}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {section.menuRows.map((m, i) => (
            <PanelItem
              key={`${section.sectionTitle}-${i}`}
              title={m.title}
              onClick={() => onClick(m.itemID.toString())}
              selected={`${section.sectionID}-${m.itemID}` === ruleId}
              icon={
                constraintModules && m.constraintModule !== "Default" ? (
                  <Level2Icon
                    active={constraintModules.includes(m.constraintModule)}
                  />
                ) : null
              }
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Divider sx={{ mx: 2 }} />
    </Stack>
  );
}

const MenuWrapper: React.FC<MenuWrapperProps> = ({ children, ...props }) => (
  <Panel2 id="panel2" {...props}>
    <MenuList>
      <Stack gap={2} pt={2}>
        {children}
      </Stack>
    </MenuList>
  </Panel2>
);

export function Level2Menu({
  ruleId,
  constraintModules,
  onSelect,
  menuItems,
  showAdvanced,
  ...props
}: Props) {
  return (
    <MenuWrapper {...props}>
      <Typography variant="h3" sx={{ px: 2 }}>
        Regelmodul
      </Typography>
      <Divider sx={{ mx: 2 }} />
      {menuItems
        .filter((i) => showAdvanced || i.sectionID !== "advanced")
        .map((s, i) => (
          <SectionAccordion
            key={i}
            constraintModules={constraintModules}
            section={s}
            ruleId={ruleId}
            onClick={(itemId: string) => onSelect(s.sectionID, itemId)}
          />
        ))}
    </MenuWrapper>
  );
}

import { useCallback } from "react";
import { Grid } from "@mui/material";
import { LabeledWeightSelect } from "settings/common/LabeledWeightSelect";

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export function Weights({ name, disabled, readOnly }: Props) {
  const getName = useCallback(
    (fieldName: string) => `${name}.${fieldName}`,
    [name],
  );

  return (
    <Grid container spacing={4} wrap="wrap" sx={{ width: "100%" }}>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <LabeledWeightSelect
          name={getName("understaffingWeight")}
          label="Total underbemanning"
          disabled={disabled}
          readOnly={readOnly}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <LabeledWeightSelect
          name={getName("overstaffingWeight")}
          label="Total spilltid"
          disabled={disabled}
          readOnly={readOnly}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <LabeledWeightSelect
          name={getName("weekendUnderstaffingWeight")}
          label="Underbemanning på helg"
          disabled={disabled}
          readOnly={readOnly}
          sx={{ width: "100%" }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={3}>
        <LabeledWeightSelect
          name={getName("weekendOverstaffingWeight")}
          label="Spilltid på helg"
          disabled={disabled}
          readOnly={readOnly}
          sx={{ width: "100%" }}
        />
      </Grid>
    </Grid>
  );
}

/**
 * @generated SignedSource<<26ecf357fd4b2e08dcff5dc182af22d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WishedDaysOffChart_fragment$data = {
  readonly userStats: ReadonlyArray<{
    readonly competences: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly hoursOffApproved: number;
    readonly hoursOffDenied: number;
    readonly id: string;
    readonly name: string;
    readonly nrDaysOffApproved: number;
    readonly nrDaysOffDenied: number;
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
  }>;
  readonly " $fragmentType": "WishedDaysOffChart_fragment";
};
export type WishedDaysOffChart_fragment$key = {
  readonly " $data"?: WishedDaysOffChart_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WishedDaysOffChart_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WishedDaysOffChart_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSnapshotNode",
      "kind": "LinkedField",
      "name": "userStats",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nrDaysOffDenied",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nrDaysOffApproved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hoursOffDenied",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hoursOffApproved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "competences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetenceSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompetenceSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleStats",
  "abstractKey": null
};
})();

(node as any).hash = "2c4476232a22d1cb0c5dc98e0a70a881";

export default node;

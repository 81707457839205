import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  HeaderWeight,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { AB1, AB1Info as Info } from "components/setting/common/forms";

type Props = {
  groupName: string;
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  const title = (
    <>
      Dygnsvila för enhet <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}
function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      name="minDailyRestAbSoft"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}
function Switch({ readOnly }: { readOnly?: boolean }) {
  return <HeaderSwitch name="DailyRestAB" readOnly={readOnly} />;
}
function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="minDailyRestAbWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function AB1Form({ groupName, readOnly = false }: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes("DailyRestAB");
  const { value: radioSoft } = useFormikState<boolean>("minDailyRestAbSoft");

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={<Switch readOnly={readOnly} />}
          titleComponent={<Title name={groupName} />}
          weightComponent={
            <Weight readOnly={readOnly} disabled={disabled || !radioSoft} />
          }
        />
        <AB1 disabled={disabled} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

/**
 * @generated SignedSource<<00027989ff8182b72da60d4f17e7c5d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RerunPostprocessingMutation$variables = {
  scheduleId: string;
};
export type RerunPostprocessingMutation$data = {
  readonly rerunPostprocessing: {
    readonly ok: boolean | null;
  } | null;
};
export type RerunPostprocessingMutation = {
  response: RerunPostprocessingMutation$data;
  variables: RerunPostprocessingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "scheduleId",
            "variableName": "scheduleId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "RerunPostprocessingPayload",
    "kind": "LinkedField",
    "name": "rerunPostprocessing",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RerunPostprocessingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RerunPostprocessingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9651b4ab124e334e2dcdf78c07739b26",
    "id": null,
    "metadata": {},
    "name": "RerunPostprocessingMutation",
    "operationKind": "mutation",
    "text": "mutation RerunPostprocessingMutation(\n  $scheduleId: ID!\n) {\n  rerunPostprocessing(input: {scheduleId: $scheduleId}) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "7be0bcf1ab91f9f250a14a74a75183ea";

export default node;

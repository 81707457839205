export * from "./__generated__/Advanced_fragment.graphql";
export * from "./__generated__/CompetencesList_competences.graphql";
export * from "./__generated__/Comprest_fragment.graphql";
export * from "./__generated__/Definitions_fragment.graphql";
export * from "./__generated__/DemandProfile_fragment.graphql";
export * from "./__generated__/ResourcePool_fragment.graphql";
export * from "./__generated__/RuleGroupsList_ruleGroups.graphql";
export * from "./__generated__/SchedulePeriod_fragment.graphql";
export * from "./__generated__/TeamsList_teams.graphql";
export * from "./__generated__/Timebank_fragment.graphql";
export * from "./forms/types";
export type {
  CreateRuleGroupInput,
  CreateTeamInput,
  EditRuleGroupInput,
  EditTeamInput,
  UpdateSettingInput,
} from "./mutations/types";

export type MemberTeamType = {
  id: string;
  name: string;
  color: string;
};

export type MemberTeamGroupType = {
  id: string;
  name: string;
  teams?: MemberTeamType[];
};

export type TeamFormFields = {
  id?: string;
  name: string;
  color: string;
};

export enum timebankModes {
  TIMEBANK = "T",
  SPARETIME = "S",
}

/**
 * @generated SignedSource<<d811a9af8c3c1b836acda63464494964>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResultsQuery$variables = {
  id: string;
};
export type ResultsQuery$data = {
  readonly schedule: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityCharts_fragment">;
  } | null;
};
export type ResultsQuery = {
  response: ResultsQuery$data;
  variables: ResultsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActivityCharts_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PeriodNode",
            "kind": "LinkedField",
            "name": "period",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42bf4064090ae1caec415428860cecf9",
    "id": null,
    "metadata": {},
    "name": "ResultsQuery",
    "operationKind": "query",
    "text": "query ResultsQuery(\n  $id: ID!\n) {\n  schedule(id: $id) {\n    ...ActivityCharts_fragment\n    id\n  }\n}\n\nfragment ActivityCharts_fragment on ScheduleNode {\n  id\n  period {\n    startDate\n    endDate\n    id\n  }\n  ...Header_fragment\n}\n\nfragment Header_fragment on ScheduleNode {\n  period {\n    startDate\n    endDate\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "08129ec57fcc437ad1a2aab7a3554fa1";

export default node;

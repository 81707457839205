import type { ComponentProps } from "react";
import {
  Archive as ArchiveIcon,
  Unarchive as ActivateIcon,
} from "@mui/icons-material";

import {
  DeleteButton as DefaultDeleteButton,
  EditButton as DefaultEditButton,
  IconButtonWithText,
} from "components/common/buttons";

type ButtonProps = Omit<ComponentProps<typeof IconButtonWithText>, "icon">;

export function ArchiveButton(p: ButtonProps) {
  return (
    <IconButtonWithText
      color="info"
      icon={<ArchiveIcon />}
      text="Inaktivera"
      {...p}
    />
  );
}

export function ActivateButton(p: ButtonProps) {
  return (
    <IconButtonWithText
      color="success"
      icon={<ActivateIcon />}
      text="Aktivera"
      {...p}
    />
  );
}

export function DeleteButton(p: ButtonProps) {
  return <DefaultDeleteButton color="error" text="Ta bort" {...p} />;
}

export function EditButton(p: ButtonProps) {
  return <DefaultEditButton color="success" text="Redigera" {...p} />;
}

import { Stack, Typography } from "@mui/material";
import { Frequency } from "rrule";

import type { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";
import {
  DAILY,
  days,
  MONTHLY,
  translateFrequency,
  WEEKLY,
  YEARLY,
} from "components/blocked_time_slot/common/Recurrence/types";

import FrequencySelect from "./FrequencySelect";
import IntervalSelect from "./IntervalSelect";

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
  disableDay: boolean;
  disableWeek: boolean;
  disableMonth: boolean;
  disableYear: boolean;
};

export default function FrequencyForm({
  value,
  onChange,
  disableDay,
  disableWeek,
  disableMonth,
  disableYear,
}: Props) {
  const { freq, interval } = value;

  function onFreqChange(f: Frequency) {
    const newValue = { ...value, freq: f };
    newValue.bymonth = null;
    newValue.bymonthday = null;
    newValue.byweekday = null;
    newValue.bysetpos = null;
    if (f === DAILY) {
      /* nothing */
    } else if (f === WEEKLY) {
      const todaysWeekday = new Date().getDay();
      newValue.byweekday = [days[todaysWeekday - 1]];
    } else if (f === MONTHLY) {
      const todaysMonthday = new Date().getDate();
      newValue.bymonthday = [todaysMonthday];
    } else if (f === YEARLY) {
      const todaysMonth = new Date().getMonth() + 1;
      newValue.bymonth = [todaysMonth];
    }

    onChange(newValue);
  }

  function onIntervalChange(newInterval: number) {
    onChange({ ...value, interval: newInterval });
  }

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <FrequencySelect
        value={freq as Frequency}
        onChange={onFreqChange}
        {...{ disableDay, disableWeek, disableMonth, disableYear }}
      />
      <IntervalSelect value={interval} onChange={onIntervalChange} />
      <Typography>{freq ? translateFrequency(freq, false) : ""}</Typography>
    </Stack>
  );
}

import type { SwitchProps } from "@mui/material";
import { Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import useFormikState from "hooks/useFormikState";
import { Colors } from "styles/colors";

type FormikBigSwitchProps = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};
type FormikSmallSwitchProps = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  showLabels?: boolean;
  labelOn?: string;
  labelOff?: string;
};

/** A mui switch component with implemented readOnly. */
const SwitchWithReadonly = ({
  readOnly,
  ...props
}: SwitchProps & { readOnly?: boolean }) => (
  <Switch
    {...props}
    onChange={(a, b) => {
      if (!readOnly && props?.onChange) props.onChange(a, b);
    }}
  />
);

export const BigSwitch = styled(SwitchWithReadonly)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: Colors.BLUE40,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: Colors.DISABLED,
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: Colors.BLUE,
      },
    },
    "&.Mui-disabled": {
      "& + .MuiSwitch-track": {
        backgroundColor: Colors.DISABLED,
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: Colors.GRAY,
  },
  "& .MuiSwitch-thumb": {
    width: 19,
    height: 19,
  },
}));

export const SmallSwitch = styled(SwitchWithReadonly)(() => ({
  width: 35,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: Colors.BLUE40,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: Colors.DISABLED,
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: Colors.BLUE,
      },
    },
    "&.Mui-disabled": {
      "& + .MuiSwitch-track": {
        backgroundColor: Colors.DISABLED,
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "white",
    boxShadow: "0 0 6px rgba(0,0,0,0.2)",
    width: 10.5,
    height: 10.5,
    margin: 0.5,
    borderRadius: 6,
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    backgroundColor: Colors.GRAY,
    opacity: 1,
  },
}));

export function FormikBigSwitch({
  name,
  disabled,
  readOnly,
}: FormikBigSwitchProps) {
  const {
    value,
    field: { onChange },
  } = useFormikState<boolean>(name);

  return (
    <BigSwitch
      name={name}
      checked={value}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export function FormikSmallSwitch({
  name,
  disabled,
  readOnly,
  showLabels = false,
  labelOn = "På",
  labelOff = "Av",
}: FormikSmallSwitchProps) {
  const {
    value,
    field: { onChange },
  } = useFormikState<boolean>(name);

  return (
    <>
      <SmallSwitch
        name={name}
        checked={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
      {showLabels && <Typography>{value ? labelOn : labelOff}</Typography>}
    </>
  );
}

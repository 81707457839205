import { Link } from "react-router-dom";
import { Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import { ShiftSelect } from "settings/common/ShiftSelect";

import type { ShiftType } from "components/setting/types";

import { timebankModes } from "../types";

type Props = {
  readOnly?: boolean;
  shiftTypes: ReadonlyArray<ShiftType>;
};

export function TimebankDisallowedShiftChangesField({
  readOnly,
  shiftTypes,
}: Props) {
  const timebankMode =
    useFormikState<string>("timebankMode")?.value.toUpperCase();
  const disabled = timebankMode !== timebankModes.SPARETIME;

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography variant="h4">
          Otillåtna förkortningar och förlängningar
        </Typography>
        <Typography>
          Här kan du ange vilka passtyper som ska få lov att ha en
          senare/tidigare start respektive ett senare/tidigare slut. Schemats
          olika passtyper anges under fliken som heter{" "}
          <Link to="/shifts">
            <b>Passprofil</b>
          </Link>
          .
        </Typography>
      </Stack>

      <Grid container columnSpacing={3} rowSpacing={2} columns={2}>
        <Grid xs={1}>
          <Stack gap={0.5}>
            <Typography variant="h4">Tidigare start</Typography>
            <Typography>
              Dessa passtyper får inte ha en tidigare start.
            </Typography>
            <ShiftSelect
              name="shiftsEarlierStartNotOk"
              options={shiftTypes}
              readOnly={readOnly}
              disabled={disabled}
            />
          </Stack>
        </Grid>

        <Grid xs={1}>
          <Stack gap={0.5}>
            <Typography variant="h4">Senare start</Typography>
            <Typography>
              Dessa passtyper får inte ha en senare start.
            </Typography>
            <ShiftSelect
              name="shiftsLaterStartNotOk"
              options={shiftTypes}
              readOnly={readOnly}
              disabled={disabled}
            />
          </Stack>
        </Grid>
        <Grid xs={1}>
          <Stack gap={0.5}>
            <Typography variant="h4">Tidigare slut</Typography>
            <Typography>
              Dessa passtyper får inte ha ett tidigare slut.
            </Typography>
            <ShiftSelect
              name="shiftsEarlierEndNotOk"
              options={shiftTypes}
              readOnly={readOnly}
              disabled={disabled}
            />
          </Stack>
        </Grid>

        <Grid xs={1}>
          <Stack gap={0.5}>
            <Typography variant="h4">Senare slut</Typography>
            <Typography>
              Dessa passtyper får inte ha ett senare slut.
            </Typography>
            <ShiftSelect
              name="shiftsLaterEndNotOk"
              options={shiftTypes}
              readOnly={readOnly}
              disabled={disabled}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

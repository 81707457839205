import type { ComponentProps } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { PaperProps, TableCellProps, TableProps } from "@mui/material";
import { Typography } from "@mui/material";
import type {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowData,
  MRT_TableState,
  MRT_VisibilityState,
} from "material-react-table";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_SV } from "material-react-table/locales/sv";
import { getTableConfig, setTableConfig } from "utils/localStorage";

type MRTableProps = ComponentProps<typeof MaterialReactTable>;

type Props = MRTableProps & {
  tableConfigKey?: string;
  columns: any;
  data: any;
};

type TableState = Partial<MRT_TableState<MRT_RowData>>;

type TableConfig = {
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
  columnVisibility: MRT_VisibilityState;
};

const defaultPagination: MRT_PaginationState = {
  pageIndex: 0,
  pageSize: 10,
};
const defaultInitialState: Pick<TableState, "density" | "expanded"> = {
  density: "compact",
  expanded: true,
};
const defaultTableProps: Partial<Parameters<typeof useMaterialReactTable>[0]> =
  {
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableDensityToggle: false,
    localization: MRT_Localization_SV,
  };

export default function MRTable({
  tableConfigKey,
  columns,
  data,
  ...props
}: Props) {
  /* `state` may arrive from props.state or `tableConfig` (localStorage).
   * The order of precedence is:
   * 1. props.state
   * 2. tableConfig
   * 3. default (internally)
   *
   * `tableConfig` is only used if `tableConfigKey` is set.
   * If none of props.state and tableConfigKey is set, the state is handled internally.
   *
   * If state is hoisted, all columns need to be mentioned in columnVisibility, both false and true.
   * `state` in RequiredVsScheduledChart is an example for how it can be done.
   */

  const {
    initialState,
    state,
    onColumnFiltersChange,
    onColumnVisibilityChange,
    onPaginationChange,
  } = useStates(props, tableConfigKey);

  const styles = useStyles(props);

  const table = useMaterialReactTable({
    ...defaultTableProps,
    ...props,
    columns,
    data,
    initialState,
    state,
    onColumnVisibilityChange,
    onColumnFiltersChange,
    onPaginationChange,
    ...styles,
  });
  return <MaterialReactTable table={table} />;
}

function useStates(
  { state: stateProp, initialState: initialStateProp }: Partial<MRTableProps>,
  tableConfigKey?: string,
) {
  const tableConfig = useMemo(
    () => (tableConfigKey ? getTableConfig<TableConfig>(tableConfigKey) : null),
    [tableConfigKey],
  );

  const initialState = useMemo<TableState>(
    () => ({ ...defaultInitialState, ...(initialStateProp ?? {}) }),
    [initialStateProp],
  );

  const [columnFilters, onColumnFiltersChange] =
    useState<MRT_ColumnFiltersState>(
      stateProp?.columnFilters ??
        tableConfig?.columnFilters ??
        initialStateProp?.columnFilters ??
        [],
    );
  const [columnVisibility, onColumnVisibilityChange] =
    useState<MRT_VisibilityState>(
      stateProp?.columnVisibility ??
        tableConfig?.columnVisibility ??
        initialStateProp?.columnVisibility ??
        {},
    );
  const [pagination, onPaginationChange] = useState<MRT_PaginationState>(
    stateProp?.pagination ??
      tableConfig?.pagination ??
      initialStateProp?.pagination ??
      defaultPagination,
  );

  const stateToStore = useMemo<TableState>(
    () => ({
      columnFilters,
      columnVisibility,
      pagination,
    }),
    [columnFilters, columnVisibility, pagination],
  );
  const state = useMemo<TableState>(
    () => ({
      ...stateProp,
      ...stateToStore,
    }),
    [stateProp, stateToStore],
  );

  useEffect(() => {
    // update localStorage on change
    if (!tableConfigKey) return;

    setTableConfig(tableConfigKey, stateToStore);
  }, [tableConfigKey, stateToStore]);

  return {
    state,
    initialState,
    onColumnFiltersChange,
    onColumnVisibilityChange,
    onPaginationChange,
  };
}

function useStyles(props: Partial<MRTableProps>) {
  const defaultColumn = useMemo(
    () => ({
      minSize: 40,
      maxSize: 300,
      size: 70,
      ...(props.defaultColumn ?? {}),
    }),
    [props.defaultColumn],
  );
  const muiTableProps = useMemo<TableProps>(
    () => ({
      ...(props.muiTableProps ?? {}),
      sx: (theme) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
      }),
    }),
    [props.muiTableProps],
  );
  const muiTablePaperProps = useMemo<PaperProps>(
    () => ({
      variant: "box",
      ...(props.muiTablePaperProps ?? {}),
      sx: {
        p: "0px 8px",
      },
    }),
    [props.muiTablePaperProps],
  );
  const muiTableBodyCellProps = useMemo<TableCellProps>(
    () => ({
      ...(props.muiTableBodyCellProps ?? {}),
      sx: {
        p: "0px 8px",
      },
    }),
    [props.muiTableBodyCellProps],
  );
  const muiToolbarAlertBannerProps = useMemo(
    () => ({
      ...(props.muiToolbarAlertBannerProps ?? {}),
      sx: {
        backgroundColor: "white",
        fontSize: 14,
        color: "black",
      },
    }),
    [props.muiToolbarAlertBannerProps],
  );
  const muiTableFooterCellProps = useMemo(
    () => ({
      ...(props.muiTableFooterCellProps ?? {}),
      sx: {
        p: "10.8px 8px",
      },
    }),
    [props.muiTableFooterCellProps],
  );
  const muiTableHeadCellProps = useMemo<TableCellProps>(
    () => ({
      ...(props.muiTableHeadCellProps ?? {}),
      sx: {
        p: "0px 8px",
        ...((props?.muiTableHeadCellProps as TableCellProps)?.sx ?? {}),
      },
    }),
    [props.muiTableHeadCellProps],
  );
  const renderEmptyRowsFallback = useCallback(
    () => (
      <Typography fontStyle="italic" sx={{ lineHeight: 5, pl: 2 }}>
        Tomt
      </Typography>
    ),
    [],
  );
  return {
    defaultColumn,
    muiTableProps,
    muiTablePaperProps,
    muiTableBodyCellProps,
    muiToolbarAlertBannerProps,
    muiTableFooterCellProps,
    muiTableHeadCellProps,
    renderEmptyRowsFallback,
  };
}

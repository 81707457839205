/**
 * @generated SignedSource<<0b2022b5a2e7c32fd4e01ec6824bb1ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HoursAndShiftsSettingTeamGroup_fragment$data = {
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly periodLengthWeeks: number;
  readonly shiftsPerWeek: number;
  readonly totalHoursMargin: number;
  readonly totalShiftsMargin: number;
  readonly " $fragmentType": "HoursAndShiftsSettingTeamGroup_fragment";
};
export type HoursAndShiftsSettingTeamGroup_fragment$key = {
  readonly " $data"?: HoursAndShiftsSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HoursAndShiftsSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HoursAndShiftsSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalHoursMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalShiftsMargin",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "691eea1b4238df581081c9ffd1694dc5";

export default node;

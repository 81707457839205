import type { PropsWithChildren, ReactNode } from "react";
import type { DialogProps } from "@mui/material";
import { Dialog, DialogActions, DialogContent, Divider } from "@mui/material";

import { DialogTitle } from "components/common/Dialog";

type Props = {
  title: ReactNode;
  open: boolean;
  onClose: () => void;
  dialogProps?: Partial<DialogProps>;
};

export function InfoModal({
  title,
  open,
  onClose,
  children,
  dialogProps = {},
}: PropsWithChildren<Props>) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      {...dialogProps}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <Divider />
      <DialogContent sx={{ minHeight: 200 }}>{children}</DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

import { createContext, useContext } from "react";

import type { RuleGroupSelect_fragment$key as Key } from "./types";

export const RuleGroupSelectFragmentRefContext = createContext<Key | null>(
  null,
);
export const RuleGroupSelectFragmentRefProvider =
  RuleGroupSelectFragmentRefContext.Provider;
export const useRuleGroupSelectFragmentRef = () => {
  const ref = useContext(RuleGroupSelectFragmentRefContext);
  if (!ref) {
    throw new Error(
      "useRuleGroupSelectFragmentRef requires a RuleGroupSelectFragmentRefProvider",
    );
  }
  return ref;
};

import AdvancedGroupIcon from "@mui/icons-material/AdminPanelSettings";

import type { MenuItem } from "../types";

export const menuItems: MenuItem[] = [
  {
    sectionTitle: "Behov",
    sectionID: "d",
    menuRows: [
      {
        itemID: 1,
        title: "Timmar & Pass",
        constraintModule: "HoursAndShifts",
      },
    ],
  },
  {
    sectionTitle: "Allmänna Bestämmelser",
    sectionID: "ab",
    menuRows: [
      {
        itemID: 0,
        title: "Dygnsvila",
        constraintModule: "DailyRestAB",
      },
      {
        itemID: 1,
        title: "Veckovila",
        constraintModule: "WeeklyRestAB",
      },
    ],
  },
  {
    sectionTitle: "Schemarytm",
    sectionID: "r",
    menuRows: [
      {
        itemID: 1,
        title: "Sammanhängande ledighet",
        constraintModule: "ConsecutiveFreeDays",
      },
      {
        itemID: 2,
        title: "Sammanhängande arbete",
        constraintModule: "ConsecutiveWorkDays",
      },
    ],
  },
  {
    sectionTitle: "Ledighet",
    sectionID: "w",
    menuRows: [
      { itemID: 0, title: "Helgvila", constraintModule: "FreeWeekends" },
    ],
  },
  {
    sectionTitle: "Dag/Kväll/Natt",
    sectionID: "n",
    menuRows: [
      {
        itemID: 0,
        title: "Dag-/Kväll-/Natt-fördelning",
        constraintModule: "ShiftDayTypeDistribution",
      },
      {
        itemID: 1,
        title: "Dag-/Kväll-/Natt-rättvisa",
        constraintModule: "ShiftDayTypeFairness",
      },
      {
        itemID: 2,
        title: "Dag-/Kväll-/Natt-kadens",
        constraintModule: "ShiftDayTypeCadence",
      },
    ],
  },
  {
    sectionTitle: "Särskilda passtyper",
    sectionID: "s",
    menuRows: [
      {
        itemID: 2,
        title: "Passtypsfördelning",
        constraintModule: "ShiftTypeDistribution",
      },
    ],
  },
  {
    sectionTitle: "Annan arbetstid",
    sectionID: "t",
    menuRows: [
      {
        itemID: 0,
        title: "Ansvarstid",
        constraintModule: "AdminTime",
      },
      {
        itemID: 1,
        title: "Resurstid",
        constraintModule: "ResourceTime",
      },
    ],
  },
  {
    sectionTitle: "Avancerat",
    sectionID: "advanced",
    icon: <AdvancedGroupIcon sx={{ color: "text.secondary" }} />,
    menuRows: [
      { itemID: 6, title: "Nattpass", constraintModule: "NightShifts" },
    ],
  },
];

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderSwitch,
  HeaderTitle,
  HeaderWeight,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Rhythm3, Rhythm3Info as Info } from "components/setting/common/forms";

const MODULE_NAME = "ConsecutiveWorkDays";

type FormProps = { groupName: string; readOnly?: boolean };

function Title({ name }: { name: string }) {
  const title = (
    <>
      Sammanhängande arbete för enhet <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="consecutiveWorkDaysWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Rhythm3Form({ groupName, readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title name={groupName} />}
          weightComponent={<Weight readOnly={readOnly} disabled={disabled} />}
        />
        <Rhythm3 disabled={disabled} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

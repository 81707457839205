import type { FC, ReactNode } from "react";
import type { StackProps } from "@mui/material";
import { Stack, Typography } from "@mui/material";
import { useFormikState } from "hooks";

import { useMeetingCadenceDescription } from "../hooks";

import type { FormValues } from "./types";

type Props = StackProps;

export function HeaderInfo(props: Props) {
  const { value: name } = useFormikState<FormValues["name"]>("name");
  const { value: start } = useFormikState<FormValues["start"]>("start");
  const { value: end } = useFormikState<FormValues["end"]>("end");
  const { value: users } = useFormikState<FormValues["users"]>("users");
  const { value: dayMatrix } =
    useFormikState<FormValues["dayMatrix"]>("dayMatrix");
  const magicDescriptor = useMeetingCadenceDescription(dayMatrix);

  return (
    <HeaderInfoScaffold
      {...props}
      title={
        <Typography fontSize={24} fontWeight={700} color="black">
          {name || "<Mötestitel>"}
        </Typography>
      }
      description={
        <Typography fontSize={30} fontWeight={700} color="black">
          {magicDescriptor || "<Dagar>"}
        </Typography>
      }
      duration={
        <Typography>{start && end ? `${start}-${end}, ` : ""}</Typography>
      }
      userCount={<Typography>{users.length} deltagare</Typography>}
    />
  );
}

export const HeaderInfoScaffold: FC<
  {
    title: ReactNode;
    description: ReactNode;
    duration: ReactNode;
    userCount: ReactNode;
  } & Omit<StackProps, "title">
> = ({ title, description, duration, userCount, ...props }) => (
  <Stack {...props}>
    {title}
    {description}
    <Stack direction="row" gap={0.5} pt={0.5}>
      {duration}
      {userCount}
    </Stack>
  </Stack>
);

import { List } from "@mui/material";

import { ElementItem } from "./ElementItem";
import type { Element } from "./types";

type Props = {
  elements: Element[];
};

export function ElementList({ elements }: Props) {
  return (
    <List disablePadding>
      {elements.map((element) => (
        <ElementItem key={element.id} element={element} />
      ))}
    </List>
  );
}

import { Paper, Stack } from "@mui/material";

import { LoadingCode } from "components/loading/LoadingCode";
import { LoadingSelector } from "components/loading/LoadingSelector";

function Filters() {
  return (
    <Stack direction="row" gap={2}>
      <LoadingSelector width={200} />
      <LoadingSelector width={200} />
    </Stack>
  );
}

function Chart() {
  return (
    <Paper variant="box" sx={{ height: 220, p: 2 }}>
      <LoadingCode />
    </Paper>
  );
}

export function DemandPlaceholder() {
  return (
    <Stack gap={2}>
      <Filters />
      <Chart />
    </Stack>
  );
}

import { SettingBox } from "components/setting/common";
import { Base3, Base3Info as Info } from "components/setting/common/forms";

type FormProps = {
  groupName: string;
  readOnly?: boolean;
  shiftTypes: ReadonlyArray<any>;
};

function Title({ name }: { name: string }) {
  return (
    <>
      Över- och underskottstid för enhet <b>{name}</b>
    </>
  );
}

export function Base3Form({ groupName, readOnly, shiftTypes }: FormProps) {
  return (
    <SettingBox title={<Title name={groupName} />} infoButton={<Info />}>
      <Base3 readOnly={readOnly} shiftTypes={shiftTypes} />
    </SettingBox>
  );
}

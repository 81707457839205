/**
 * @generated SignedSource<<e10415597e28fd557534e6d2dd6587f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlockedTimeSlotUserSelect_fragment$data = ReadonlyArray<{
  readonly fullName: string;
  readonly id: string;
  readonly " $fragmentType": "BlockedTimeSlotUserSelect_fragment";
}>;
export type BlockedTimeSlotUserSelect_fragment$key = ReadonlyArray<{
  readonly " $data"?: BlockedTimeSlotUserSelect_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockedTimeSlotUserSelect_fragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BlockedTimeSlotUserSelect_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "e29c7e41a585113cebdbba736e5aa931";

export default node;

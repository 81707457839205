import type { PriorityWeight } from "types/Demand";

import { DemandItemInput, priorityWeight } from "../types";

const DEFAULT_START = "00:00";
const DEFAULT_DEMAND = 0;
const DEFAULT_DUTY_SHARE = 0;
const NORMAL_WEIGHT: PriorityWeight = priorityWeight.NORMAL;

export const initialDemand: DemandItemInput = {
  start: DEFAULT_START,
  end: DEFAULT_START,
  demand: DEFAULT_DEMAND,
  dutyShare: DEFAULT_DUTY_SHARE,
  enforceDuty: false,
  minimizeUnderstaffingWeight: NORMAL_WEIGHT,
  minimizeOverstaffingWeight: NORMAL_WEIGHT,
};

export const initialDemandDay = {
  days: [],
  demands: [],
};

export const initialDemandWeek = {
  weeks: [],
  demands: [],
};

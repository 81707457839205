import { Stack } from "@mui/material";

import { ComprestCanCutDaybreakAbjourField } from "./ComprestCanCutDaybreakAbjourField";
import { ComprestCanCutDaybreakBilagajField } from "./ComprestCanCutDaybreakBilagajField";

type Props = { readOnly?: boolean };

export function ComprestForm({ readOnly }: Props) {
  return (
    <Stack gap={3}>
      <ComprestCanCutDaybreakBilagajField readOnly={readOnly} />
      <ComprestCanCutDaybreakAbjourField readOnly={readOnly} />
    </Stack>
  );
}

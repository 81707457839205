import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

export type ContextType = {
  selected?: string;
  setSelected: (s: string) => void;
};

type ProviderProps = PropsWithChildren<ContextType>;

const SettingsPanelContext = createContext<ContextType>({
  setSelected: () => {},
});

export const SettingsPanelProvider = ({
  selected,
  setSelected,
  children,
}: ProviderProps) => (
  <SettingsPanelContext.Provider
    value={{ selected, setSelected }}
    children={children}
  />
);

export const useSettingsPanel = () => {
  const context = useContext(SettingsPanelContext);
  if (!context) {
    throw new Error(
      "useSettingsPanel must only be used within SettingsPanelContext",
    );
  }
  return context;
};

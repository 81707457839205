import { useCallback } from "react";
import useFormikState from "hooks/useFormikState";

import { RadioButtonHardSoft } from "components/common/RadioButtons";

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export function HardSoftChoice({ name, disabled, readOnly }: Props) {
  const { value, setValue } = useFormikState<boolean>(name);
  const toggle = useCallback(() => setValue(!value), [value, setValue]);

  return (
    <RadioButtonHardSoft
      value={value}
      handleChange={toggle}
      softLabel={"Mjuk"}
      hardLabel={"Hård"}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

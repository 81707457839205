import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { updateSettingsMutation as Mutation } from "./__generated__/updateSettingsMutation.graphql";

const mutation = graphql`
  mutation updateSettingsMutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      ok
      setting {
        ...HoursAndShiftsSettingTeamGroup_fragment
        ...DayRestSettingTeamGroup_fragment
        ...CompetenceDemandSettingTeamGroup_fragment
        ...CostSettingTeamGroup_fragment
        ...BaseDemandSettingTeamGroup_fragment
        ...ConsecutiveWorkSettingTeamGroup_fragment
        ...ConsecutiveRestSettingTeamGroup_fragment
        ...DayNightFairnessSettingTeamGroup_fragment
        ...DayNightDistributionSettingTeamGroup_fragment
        ...DayNightCadenceSettingTeamGroup_fragment
        ...WeekRestSettingTeamGroup_fragment
        ...WeekendRestSettingTeamGroup_fragment
        ...ResourceTimeSettingTeamGroup_fragment
        ...SplitShiftsSettingTeamGroup_fragment
        ...ShiftDistributionSettingTeamGroup_fragment
        ...ResponsibilityTimeSettingTeamGroup_fragment
        ...OptimizationSettingTeamGroup_fragment
        ...SmoothResourcesSettingTeamGroup_fragment
        ...RestBetweenShiftsSettingTeamGroup_fragment
        ...ClockwiseRotationSettingTeamGroup_fragment
        ...RequestedDaysOffSettingTeamGroup_fragment
        ...NightShiftSettingTeamGroup_fragment
        ...VacationSettingTeamGroup_fragment
        ...WorkDayCadenceSettingTeamGroup_fragment
        ...PartTimeSettingTeamGroup_fragment
      }
    }
  }
`;

export const useUpdateSettingsMutation = () => useMutation<Mutation>(mutation);

import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useBooleanState } from "utils/useBooleanState";

import { BigSwitch } from "components/common/Switch";
import { useUpdateSettingsMutation } from "components/setting/team_group/setting_boxes/updateSettings";

import { ConstraintModuleSwitch_fragment$key as Key } from "./__generated__/ConstraintModuleSwitch_fragment.graphql";

const fragment = graphql`
  fragment ConstraintModuleSwitch_fragment on SettingNode {
    id
    constraintModules
  }
`;

type Props = {
  constraintModule: string;
  fragmentRef: Key;
  readOnly?: boolean;
};

export function ConstraintModuleSwitch({
  constraintModule,
  fragmentRef,
  readOnly = false,
}: Props) {
  const { id, constraintModules } = useFragment(fragment, fragmentRef);
  const {
    value: isSaving,
    setTrue: setSaving,
    setFalse: setNotSaving,
  } = useBooleanState();
  const initialIsEnabled = useMemo(
    () => constraintModules.includes(constraintModule),
    [constraintModules, constraintModule],
  );
  const { value: isEnabled, toggle: toggleIsEnabled } =
    useBooleanState(initialIsEnabled);

  const [updateSettings] = useUpdateSettingsMutation();
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSaving();
      toggleIsEnabled();

      const newIsEnabled = event.target.checked;
      const newConstraintModules = newIsEnabled
        ? [...constraintModules, constraintModule]
        : constraintModules.filter((module) => module !== constraintModule);

      const input = { id, constraintModules: newConstraintModules };
      updateSettings({ variables: { input } })
        .catch(console.log)
        .finally(setNotSaving);
    },
    [
      setSaving,
      setNotSaving,
      toggleIsEnabled,
      id,
      constraintModules,
      constraintModule,
      updateSettings,
    ],
  );

  return (
    <>
      <BigSwitch
        disabled={readOnly || isSaving}
        checked={isEnabled}
        onChange={onChange}
      />
      <Typography sx={{ minWidth: "80px" }}>
        {isEnabled ? "Aktiv regel" : "Inaktiv regel"}
      </Typography>
    </>
  );
}

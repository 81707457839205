import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import { CreateDemandsMutation as Mutation } from "./__generated__/CreateDemandsMutation.graphql";

const mutation = graphql`
  mutation CreateDemandsMutation($input: CreateDemandsInput!) {
    createDemands(input: $input) {
      ok
      demandsWereMerged
    }
  }
`;

export const useCreateDemands = () => useMutation<Mutation>(mutation);

/**
 * @generated SignedSource<<bfc8241684af6f856e6e16f9fbc872d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NightShiftSettingTeamGroup_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly nightShiftRestriction: ApiSettingNightShiftRestrictionChoices;
  readonly nightShiftRotation: ApiSettingNightShiftRotationChoices;
  readonly " $fragmentType": "NightShiftSettingTeamGroup_fragment";
};
export type NightShiftSettingTeamGroup_fragment$key = {
  readonly " $data"?: NightShiftSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NightShiftSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NightShiftSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRestriction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRotation",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "3a5f295f5886e76ba1d4ec59414a0ce3";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import { EditMeetingMutation as Mutation } from "./types";

const mutation = graphql`
  mutation EditMeetingMutation($input: EditMeetingInput!) {
    editMeeting(input: $input) {
      ok
      meeting {
        ...MeetingList_meetings @relay(plural: false)
      }
      errors {
        code
        causedByMeeting {
          id
          name
        }
        causedByUser {
          id
          fullName
        }
        causedByShift {
          name
        }
        partStartsAt
      }
    }
  }
`;

export const useEditMeeting = () => useMutation<Mutation>(mutation);

import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import type { CSSObject, ListItemTextProps } from "@mui/material";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
} from "@mui/material";
import type { Route } from "types/Route";

type NavListItemProps = {
  pathname: string;
  route: Route;
  sx?: CSSObject;
};

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.primary.contrastText,
  },
  "&.active": {
    color: theme.palette.primary.contrastText,
  },
})) as unknown as typeof NavLink;

const ActiveMarker = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active }: { active: boolean }) => ({
  opacity: active ? 1 : 0,
  background: "#99EBF0",
  height: 36,
  width: 4,
  borderRadius: "0 10px 10px 0",
}));

const NavIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const NavText = ({ label }: { label: ListItemTextProps["primary"] }) => (
  <ListItemText
    primary={label}
    primaryTypographyProps={{ fontSize: 16, fontWeight: 400, lineHeight: 1.2 }}
  />
);

export function NavListItem({ pathname, route, sx = {} }: NavListItemProps) {
  const subrouteActive = useMemo(
    () => pathname.startsWith(route.to),
    [pathname, route.to],
  );
  const exact = useMemo(() => pathname === route.to, [pathname, route.to]);
  const isActive = useMemo(
    () => (route?.end ? exact : subrouteActive),
    [route.end, exact, subrouteActive],
  );
  const showContent = useMemo(
    () => route.content?.length && (isActive || subrouteActive),
    [route.content, isActive, subrouteActive],
  );

  return (
    <>
      <ListItem
        component={StyledNavLink}
        to={route.to}
        end={!!route?.end}
        role="navigation"
        disableGutters
        sx={{
          flexBasis: 1,
          "&:hover .nav-content": {
            bgcolor: "rgba(255, 255, 255, 0.08)",
          },
          "&.active .nav-content": {
            bgcolor: "rgba(255, 255, 255, 0.16)",
          },
          ...sx,
        }}
      >
        <Stack direction="row" gap={1} alignItems="center" flexBasis="100%">
          <ActiveMarker active={isActive} />
          <Stack
            className="nav-content"
            direction="row"
            gap={1}
            alignItems="center"
            borderRadius={2}
            flexGrow={2}
            py={1}
            pl={2}
            mr={2}
          >
            <NavIcon sx={{ minWidth: 0 }}>{route.icon}</NavIcon>
            <NavText label={route.label} />
          </Stack>
        </Stack>
      </ListItem>
      {showContent &&
        (route?.content || []).map((subRoute, subIndex) => (
          <NavListItem
            key={subIndex}
            pathname={pathname}
            route={subRoute}
            sx={{ pl: 1 }}
          />
        ))}
    </>
  );
}

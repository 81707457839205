import { useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { Weekday } from "rrule";

import type { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";
import {
  days,
  translateWeekday,
} from "components/blocked_time_slot/common/Recurrence/types";

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
  disabled?: boolean;
};

export default function WeeklyOptions({ value, onChange, disabled }: Props) {
  const isSelected = useCallback(
    (day: Weekday) => {
      const selectedWeekdays = new Set(
        value?.byweekday?.map((weekday) => weekday.weekday) ?? [],
      );

      return selectedWeekdays.has(day.weekday);
    },
    [value?.byweekday],
  );

  function toggleDay(day: Weekday) {
    let newValue = value || {};
    if (!newValue.byweekday) {
      newValue.byweekday = [];
    }

    if (isSelected(day)) {
      newValue.byweekday = newValue.byweekday.filter(
        (d) => d.weekday !== day.weekday,
      );
    } else {
      newValue.byweekday.push(day);
    }
    onChange(newValue);
  }

  return (
    <Stack direction="row" gap={0.5}>
      {days.map((day) => (
        <Button
          key={day.toString()}
          onClick={() => toggleDay(day)}
          variant={isSelected(day) ? "primary" : "outlined"}
          disabled={disabled}
          sx={{ textTransform: "none" }}
        >
          {translateWeekday(day)}
        </Button>
      ))}
    </Stack>
  );
}

import ExpandIcon from "@mui/icons-material/ExpandMoreSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";

export { AccordionActions } from "@mui/material";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderWidth: 1,
  borderColor: theme.palette.divider,
  borderStyle: "solid",
  borderRadius: 4,
  "&:before": {
    display: "none",
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: 4,
  paddingLeft: 0,
  paddingRight: 0,
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  "&.Mui-expanded": {
    borderBottomWidth: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  "& > *:first-of-type": {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 64,
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTopWidth: 0,
}));

import { Button } from "@mui/material";

import { Plus as PlusIcon } from "components/icons";

const buttonSx = {
  "& .MuiButton-startIcon > *": {
    fontSize: 32,
    marginRight: "-4px",
    paddingTop: "2px",
  },
};

export function NewActivityButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      variant="icon"
      onClick={onClick}
      startIcon={<PlusIcon />}
      sx={buttonSx}
    >
      Ny händelse
    </Button>
  );
}

import { MenuItem, Select } from "@mui/material";
import useFormikState from "hooks/useFormikState";

type Props = {
  name: string;
  readOnly?: boolean;
};

const options = [
  { value: "T", label: "För över till timbank" },
  { value: "S", label: "Förläng/förkorta pass" },
];

export function TimebankSelect({ name, readOnly }: Props) {
  const { value: timebankMode, setValue: setTimebankMode } =
    useFormikState<string>(name);

  return (
    <Select
      value={timebankMode.toUpperCase()}
      onChange={(_e: any, v: any) => setTimebankMode(v?.props.value)}
      readOnly={readOnly}
      size="small"
    >
      {options.map((o) => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  );
}

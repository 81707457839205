import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Box, Stack } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import graphql from "babel-plugin-relay/macro";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";

import GraphTooltip from "../common/GraphTooltip";
import type { XOption } from "../common/XSelect";
import { XSelect } from "../common/XSelect";
import { TranslateWishedKey } from "../types";

import {
  WishedDaysOffChart_fragment$data as Data,
  WishedDaysOffChart_fragment$key as Key,
} from "./__generated__/WishedDaysOffChart_fragment.graphql";
import { chartColor } from "./constants";

const fragment = graphql`
  fragment WishedDaysOffChart_fragment on ScheduleStats {
    userStats {
      id
      name
      nrDaysOffDenied
      nrDaysOffApproved
      hoursOffDenied
      hoursOffApproved
      competences {
        edges {
          node {
            id
          }
        }
      }
      teams {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

type UserType = Unwrap<Data["userStats"]>;
type Competence = ExtractNode<UserType["competences"]>;
type Team = ExtractNode<UserType["teams"]>;
type RowType = Omit<UserType, "teams" | "competences"> & {
  teams: ReadonlyArray<Team>;
  competences: ReadonlyArray<Competence>;
};

type Props = {
  fragmentRef: Key;
  teamId: string | null;
  competence: string | null;
  filterCompetence: boolean;
};

const DEFAULT_OPTION = { value: "count", label: "Antal tillfällen" };
const OPTIONS: ReadonlyArray<XOption> = [
  DEFAULT_OPTION,
  { value: "hours", label: "Antal timmar" },
];

export function WishedDaysOffChart({
  fragmentRef,
  teamId,
  competence,
  filterCompetence,
}: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  const teamFilter = useCallback(
    (row: RowType) => !teamId || row.teams.some((e) => e?.id === teamId),
    [teamId],
  );
  const competenceFilter = useCallback(
    (row: RowType) =>
      !filterCompetence || row.competences.some((e) => e.id === competence),
    [filterCompetence, competence],
  );
  const filter = useCallback(
    (r: RowType) => teamFilter(r) && competenceFilter(r),
    [teamFilter, competenceFilter],
  );

  const dataConverted = useMemo(
    () =>
      (data.userStats || [])
        .map(({ competences, teams, ...u }) => ({
          ...u,
          teams: connectionToArray(teams),
          competences: connectionToArray(competences),
        }))
        .filter(filter)
        .map((row) => ({
          userKey: `${row.id} ${row.name}`,
          userName: row.name,
          userId: row.id,
          denied: row.nrDaysOffDenied,
          approved: row.nrDaysOffApproved,
          deniedHours: +row.hoursOffDenied.toFixed(2),
          approvedHours: +row.hoursOffApproved.toFixed(2),
        })),
    [data, filter],
  );
  const totalValues = useMemo<
    Record<string, Record<"count" | "hours", number>>
  >(
    () =>
      dataConverted.reduce(
        (acc, row) => ({
          ...acc,
          [row.userId]: {
            count: row.denied + row.approved,
            hours: row.deniedHours + row.approvedHours,
          },
        }),
        {},
      ),
    [dataConverted],
  );

  // Axis switch options
  const [xAxis, setXAxis] = useState(DEFAULT_OPTION);

  // Calculate minimal chart height and margins
  const height = 75 + dataConverted.length * 21;
  const maxValue = Math.max(...dataConverted.map((d) => d.approved + d.denied));
  const nrTicks = Math.min(maxValue, 10);
  const leftMargin =
    13 + Math.max(...dataConverted.map((u: any) => u.userName.length)) * 5.6;
  const legendWidth = 30 + 8 * 6;

  // Legend data
  const legendsData = [
    {
      id: "denied",
      label: "Nekad",
      color: chartColor.DENIED,
    },
    {
      id: "approved",
      label: "Beviljad",
      color: chartColor.APPROVED,
    },
  ];

  return (
    <Stack px={1} alignItems="center">
      <Box style={{ height, width: "100%" }}>
        {dataConverted.length > 0 && (
          <ResponsiveBar //This would have been nice: https://github.com/plouc/nivo/issues/535
            data={dataConverted}
            groupMode="stacked"
            layout="horizontal"
            keys={
              xAxis.value === "count"
                ? ["denied", "approved"]
                : ["deniedHours", "approvedHours"]
            }
            indexBy="userName"
            colors={[chartColor.DENIED, chartColor.APPROVED]}
            margin={{
              top: 25,
              right: legendWidth + 20,
              bottom: 50,
              left: leftMargin,
            }}
            labelSkipWidth={12}
            labelTextColor="white"
            theme={{ text: { fontFamily: "Nunito" } }}
            tooltip={({ id, value, data }) => (
              <GraphTooltip
                keyId={id}
                value={value}
                totalValue={
                  totalValues[data.userId][xAxis.value as "count" | "hours"]
                }
                translateKey={TranslateWishedKey}
                unit={xAxis.value === "hours" ? "timmar" : "tillfällen"}
                user={data.userName}
              />
            )}
            axisBottom={{
              legendPosition: "middle",
              legendOffset: 40,
              tickValues: nrTicks,
            }}
            borderRadius={3}
            innerPadding={1}
            legends={[
              {
                data: legendsData,
                dataFrom: "keys",
                anchor: "right",
                direction: "column",
                translateX: legendWidth + 37,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </Box>
      <Box>
        <XSelect
          value={xAxis}
          onChange={setXAxis}
          options={OPTIONS}
          defaultValue={DEFAULT_OPTION}
        />
      </Box>
    </Stack>
  );
}

import type { PropsWithChildren, ReactNode } from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import type { SnackbarOrigin } from "@mui/material";
import { Alert, Box, Snackbar as MuiSnackbar, Stack } from "@mui/material";

type SnackType = {
  message: string;
  severity: "success" | "info" | "warning" | "error";
  action?: ReactNode;
  closeAfterMs?: number;
};

type SnackbarContextType = {
  snacks: SnackType[];
  addSnack: (snack: SnackType) => void;
  removeSnack: (snack: SnackType) => void;
  clearSnacks: () => void;
};

type SnackbarProps = {
  snack: SnackType;
  onClose: () => void;
};

type SnackbarListProps = {
  snacks: SnackType[];
  removeSnack: (snack: SnackType) => void;
};

const SnackbarContext = createContext<SnackbarContextType>({
  snacks: [],
  addSnack: () => {},
  removeSnack: () => {},
  clearSnacks: () => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

const defaultOptions: SnackType = {
  message: "",
  severity: "info",
  closeAfterMs: 5000,
};

function Snackbar({ snack, onClose }: SnackbarProps) {
  const { severity, message, closeAfterMs } = snack;
  useEffect(() => {
    if (closeAfterMs) {
      const timeout = setTimeout(onClose, closeAfterMs);
      return () => clearTimeout(timeout);
    }
  }, [closeAfterMs, onClose]);

  return (
    <Alert
      severity={severity}
      action={snack?.action}
      onClose={onClose}
      sx={{ minWidth: 200, bgcolor: "white", alignItems: "center" }}
    >
      {message}
    </Alert>
  );
}

function SnackbarList({ snacks, removeSnack }: SnackbarListProps) {
  if (snacks.length < 1) return null;

  const anchorOrigin: SnackbarOrigin = {
    vertical: "bottom",
    horizontal: "right",
  };

  return (
    <MuiSnackbar open={snacks.length > 0} anchorOrigin={anchorOrigin}>
      <Box>
        <Stack gap={1} direction="column-reverse">
          {snacks.map((snack, idx) => (
            <Snackbar
              key={`${idx}-${snack.message}`}
              snack={snack}
              onClose={() => removeSnack(snack)}
            />
          ))}
        </Stack>
      </Box>
    </MuiSnackbar>
  );
}

export function SnackbarProvider({ children }: PropsWithChildren<{}>) {
  const [snacks, setSnacks] = useState<SnackType[]>([]);

  const addSnack = useCallback((snack: Partial<SnackType>) => {
    setSnacks((prev) => [...prev, { ...defaultOptions, ...snack }]);
  }, []);

  const removeSnack = useCallback((snack: SnackType) => {
    setSnacks((prev) => prev.filter((m) => m !== snack));
  }, []);

  const clearSnacks = useCallback(() => {
    setSnacks([]);
  }, []);

  const value = { snacks, addSnack, removeSnack, clearSnacks };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <SnackbarList snacks={snacks} removeSnack={removeSnack} />
    </SnackbarContext.Provider>
  );
}

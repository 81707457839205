import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { Add, GroupsOutlined, PersonOutline } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "styles/colors";
import { EMPTY_ARRAY } from "utils/constants";
import { useBooleanState } from "utils/useBooleanState";

import { SettingExceptionsRuleGroup_fragment$key as RuleGroupKey } from "./__generated__/SettingExceptionsRuleGroup_fragment.graphql";
import { SettingExceptionsUser_fragment$key as UserKey } from "./__generated__/SettingExceptionsUser_fragment.graphql";
import { useModifyRuleGroupSettingModulesMutation } from "./mutations/modifyRuleGroupSettingModules";
import { useModifyUserSettingModulesMutation } from "./mutations/modifyUserSettingModules";
import {
  ruleGroupExceptionsFragment,
  userExceptionsFragment,
} from "./SettingExceptions";

type ExceptionOption = {
  id: string;
  name: string;
  settingModules: ReadonlyArray<string>;
};

type Props = {
  exceptionTypeName: string;
  options: ReadonlyArray<ExceptionOption>;
  onCreateException: (option: ExceptionOption) => void;
  Icon: React.ElementType;
};

const BaseSettingExceptionsHeader = React.memo(
  function BaseSettingExceptionsHeaderFn({
    exceptionTypeName,
    options,
    onCreateException,
    Icon,
  }: Props) {
    const [key, setKey] = useState(0);
    const {
      value: isFocused,
      setTrue: setFocused,
      setFalse: setNotFocused,
    } = useBooleanState();
    const onChangeCallback = useCallback(
      (
        event: SyntheticEvent<Element, Event>,
        value: { label: string; id: string; option: ExceptionOption } | null,
      ) => {
        if (value) {
          onCreateException(value.option);
          setKey((k) => k + 1);
          setNotFocused();
        }
      },
      [setKey, onCreateException, setNotFocused],
    );

    const renderInputCallback = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label="Lägg till undantag"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Add />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: isFocused || params.inputProps.value !== "",
          }}
          onFocus={setFocused}
          onBlur={setNotFocused}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: Colors.TURE,
              },
            },
            "& .MuiInputLabel-root": {
              color: Colors.TURE,
              "&:not(.MuiInputLabel-shrink)": {
                padding: "0 20px",
              },
            },
            "& input": {
              color: Colors.TURE,
            },
          }}
        />
      ),
      [isFocused, setFocused, setNotFocused],
    );

    const menuItems = useMemo(() => {
      return options.map((option) => {
        return {
          label: option.name,
          id: option.id,
          option: option,
        };
      });
    }, [options]);

    /*
      The key in the autocomplete form is there to force a rerender of the component.
      This is an ugly hack which we want to revisit and remove. 
      The component needs to be rerendered for it to clear the input field when an option is selected. 
    */

    return (
      <Box
        display="flex"
        paddingBottom={1}
        sx={{ "&:only-child": { pb: 0 }, pb: 1, alignItems: "center" }}
      >
        <Icon />
        <Typography variant="h3" sx={{ ml: 2, flexGrow: 1 }}>
          {exceptionTypeName}
        </Typography>
        <Autocomplete
          disablePortal
          size="small"
          options={menuItems}
          onChange={onChangeCallback}
          sx={{ minWidth: "200px", maxHeight: "500px" }}
          renderInput={renderInputCallback}
          key={key}
        />
      </Box>
    );
  },
);

type UserSettingExceptionsHeaderProps = {
  settingModule: string;
  fragmentRef: UserKey;
};

export const UserSettingExceptionsHeader = React.memo(
  function UserSettingExceptionsHeaderFn({
    settingModule,
    fragmentRef,
  }: UserSettingExceptionsHeaderProps) {
    const data = useFragment(userExceptionsFragment, fragmentRef);
    const [modifyUserSettingModules] = useModifyUserSettingModulesMutation();

    const usersWithoutSettingException: ReadonlyArray<ExceptionOption> =
      useMemo(() => {
        return data.reduce((acc, user) => {
          if (
            user.userSetting != null &&
            !user.userSetting.settingModules.includes(settingModule)
          ) {
            acc.push({
              id: user.userSetting?.id,
              name: user.fullName,
              settingModules: user.userSetting?.settingModules ?? EMPTY_ARRAY,
            });
          }
          return acc;
        }, [] as ExceptionOption[]);
      }, [data, settingModule]);

    const onCreateUserException = useCallback(
      (option: ExceptionOption) => {
        const newConstraintModules = [...option.settingModules, settingModule];
        console.log(
          `Updating modules for ${option.name} (${option.id}) to ${newConstraintModules}`,
        );
        modifyUserSettingModules({
          variables: {
            input: { id: option.id, constraintModules: newConstraintModules },
          },
        });
      },
      [modifyUserSettingModules, settingModule],
    );

    return (
      <BaseSettingExceptionsHeader
        exceptionTypeName="Medarbetare"
        options={usersWithoutSettingException}
        onCreateException={onCreateUserException}
        Icon={PersonOutline}
      />
    );
  },
);

type RuleGroupSettingExceptionsHeaderProps = {
  settingModule: string;
  fragmentRef: RuleGroupKey;
};

export const RuleGroupSettingExceptionsHeader = React.memo(
  function RuleGroupSettingExceptionsHeaderFn({
    settingModule,
    fragmentRef,
  }: RuleGroupSettingExceptionsHeaderProps) {
    const data = useFragment(ruleGroupExceptionsFragment, fragmentRef);
    const [modifyRuleGroupSettingModules] =
      useModifyRuleGroupSettingModulesMutation();

    const ruleGroupsWithoutSettingException: ReadonlyArray<ExceptionOption> =
      useMemo(() => {
        return data.reduce((acc, ruleGroup) => {
          if (
            ruleGroup.ruleGroupSetting != null &&
            !ruleGroup.ruleGroupSetting.settingModules.includes(settingModule)
          ) {
            acc.push({
              id: ruleGroup.ruleGroupSetting.id,
              name: ruleGroup.name,
              settingModules:
                ruleGroup.ruleGroupSetting?.settingModules ?? EMPTY_ARRAY,
            });
          }
          return acc;
        }, [] as ExceptionOption[]);
      }, [data, settingModule]);

    const onCreateRuleGroupException = useCallback(
      (option: ExceptionOption) => {
        const newConstraintModules = [...option.settingModules, settingModule];
        modifyRuleGroupSettingModules({
          variables: {
            input: { id: option.id, constraintModules: newConstraintModules },
          },
        });
      },
      [modifyRuleGroupSettingModules, settingModule],
    );

    return (
      <BaseSettingExceptionsHeader
        exceptionTypeName="Regelgrupper"
        options={ruleGroupsWithoutSettingException}
        onCreateException={onCreateRuleGroupException}
        Icon={GroupsOutlined}
      />
    );
  },
);

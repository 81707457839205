import { InputAdornment, Stack, Typography } from "@mui/material";

import { DeleteButton } from "components/common/buttons";
import Field from "components/forms/CustomField";
import { HardSoftRadios } from "components/setting/common/components/HardSoftRadios";

type Props = {
  name: string;
  onRemove: () => void;
  disabled?: boolean;
  readOnly?: boolean;
};

export function WorkDayCadenceRestriction({
  name,
  onRemove,
  disabled,
  readOnly,
}: Props) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
        <Typography variant="caption" width={60} lineHeight={1.2}>
          Pass av alla typer
        </Typography>
        <Field
          type="number"
          name={`${name}.maxCadence`}
          label="Får förekomma högst"
          disabled={disabled}
          inputProps={{ readOnly }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">gånger</InputAdornment>
            ),
          }}
          sx={{ width: 140 }}
        />
        <Field
          type="number"
          name={`${name}.calendarDays`}
          label="Inom kalenderdagar"
          disabled={disabled}
          inputProps={{ readOnly }}
          InputProps={{
            endAdornment: <InputAdornment position="end">dagar</InputAdornment>,
          }}
          sx={{ width: 140 }}
        />
        <Stack sx={{ mb: 0.3, ml: 2 }}>
          <HardSoftRadios
            name={`${name}.isSoft`}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>
      {!readOnly && <DeleteButton onClick={onRemove} disabled={disabled} />}
    </Stack>
  );
}

/**
 * @generated SignedSource<<6cb9d3b0a6cd5043d717faf9bea404c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MultiEditUsersQuery$variables = {
  userIds: ReadonlyArray<string>;
};
export type MultiEditUsersQuery$data = {
  readonly teamGroups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly color: string;
          readonly id: string;
          readonly name: string;
        } | null;
      } | null>;
    };
  }>;
  readonly users: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"MultiEditUsers_fragment">;
  }>;
};
export type MultiEditUsersQuery = {
  response: MultiEditUsersQuery$data;
  variables: MultiEditUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "userIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamGroupNode",
  "kind": "LinkedField",
  "name": "teamGroups",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamNodeConnection",
      "kind": "LinkedField",
      "name": "teams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "color",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MultiEditUsersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MultiEditUsers_fragment"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MultiEditUsersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNodeConnection",
            "kind": "LinkedField",
            "name": "groupMemberOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamGroupNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamGroupNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "memberOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "0754a93b66cd47eb1c8ab79b0574ed0d",
    "id": null,
    "metadata": {},
    "name": "MultiEditUsersQuery",
    "operationKind": "query",
    "text": "query MultiEditUsersQuery(\n  $userIds: [ID!]!\n) {\n  users(ids: $userIds) {\n    ...MultiEditUsers_fragment\n    id\n  }\n  teamGroups {\n    id\n    name\n    teams {\n      edges {\n        node {\n          id\n          name\n          color\n        }\n      }\n    }\n  }\n}\n\nfragment MultiEditUsers_fragment on UserNode {\n  id\n  fullName\n  groupMemberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  memberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "15d87614d361160eeb3649a0f4d2dec9";

export default node;

import { DateTime } from "luxon";
import * as yup from "yup";

import { shiftPartTypeChoices } from "../types";

export interface StartEndString {
  start: string;
  end: string;
}
export type ShiftValue = StartEndString & {
  shiftParts: StartEndString[];
};

function toDates(start: string, end: string): [Date, Date] {
  // end is allowed to be before start if it's on the next day
  const s = DateTime.fromFormat(start, "HH:mm");
  let e = DateTime.fromFormat(end, "HH:mm");
  if (!s.isValid || !e.isValid) {
    throw new Error("Invalid time string");
  }
  if (e < s) {
    e = e.plus({ days: 1 });
  }
  return [s.toJSDate(), e.toJSDate()];
}

function overlaps<T extends StartEndString>(
  { start, end }: T,
  { start: otherStart, end: otherEnd }: T,
  shiftStart: Date,
) {
  let s, e, os, oe;
  try {
    [s, e] = toDates(start, end);
    [os, oe] = toDates(otherStart, otherEnd);
  } catch (e) {
    return false;
  }

  if (s < shiftStart) {
    // shift start is next day
    s = DateTime.fromJSDate(s).plus({ days: 1 }).toJSDate();
    e = DateTime.fromJSDate(e).plus({ days: 1 }).toJSDate();
  }

  if (os < shiftStart) {
    // other shift start is next day
    os = DateTime.fromJSDate(os).plus({ days: 1 }).toJSDate();
    oe = DateTime.fromJSDate(oe).plus({ days: 1 }).toJSDate();
  }

  if (s === os && e === oe) {
    // s: start, e: end
    // this  :: s e
    // other :: s e
    return true;
  }
  if (s < os && os < e) {
    // s: start, e: end
    // this  :: s   e
    // other ::   s
    return true;
  }
  if (s < oe && oe < e) {
    // s: start, e: end
    // this  :: s   e
    // other ::   e
    return true;
  }
  return false;
}

function aCoversB<T extends StartEndString>(a: T, b: T): boolean {
  const aStart = DateTime.fromFormat(a.start, "HH:mm");
  let aEnd = DateTime.fromFormat(a.end, "HH:mm");
  if (aEnd < aStart) {
    aEnd = aEnd.plus({ days: 1 });
  }

  const bStart = DateTime.fromFormat(b.start, "HH:mm");
  let bEnd = DateTime.fromFormat(b.end, "HH:mm");
  if (bEnd < bStart) {
    bEnd = bEnd.plus({ days: 1 });
  }

  return aStart <= bStart && aEnd >= bEnd;
}

export function shiftPartsOverlap<T extends ShiftValue>(
  value: T,
  context: any,
) {
  if (!value) return true;

  const { path } = context;
  const { shiftParts, start: sStart } = value;
  if (!shiftParts || !sStart) return true;
  if (shiftParts.some((part) => !part.start || !part.end)) return true;

  const shiftStart = DateTime.fromFormat(sStart, "HH:mm");
  if (!shiftStart.isValid) {
    return true;
  }

  const errors = shiftParts.reduce((acc, part, index) => {
    const otherParts = shiftParts.filter((_, idx) => idx !== index);
    const overlappingParts = otherParts.filter((otherPart) =>
      overlaps(part, otherPart, shiftStart.toJSDate()),
    );
    if (overlappingParts.length > 0) {
      const error = new yup.ValidationError(
        "Passdel överlappar med annan passdel",
        part,
        `${path}.shiftParts[${index}]`,
      );
      return [...acc, error];
    }
    return acc;
  }, [] as yup.ValidationError[]);

  if (errors.length > 0) {
    const shiftError = new yup.ValidationError(
      "Passdel överlappar med annan passdel",
      value,
      path,
    );
    return new yup.ValidationError([shiftError, ...errors]);
  }

  return true;
}

export function shiftPartOutside<T extends ShiftValue>(value: T, context: any) {
  if (!value) return true;

  const { path } = context;
  const { start: sStart, end: shiftEnd, shiftParts } = value;
  if (
    shiftParts.some(
      (part) =>
        !DateTime.fromFormat(part.start, "HH:mm").isValid ||
        !DateTime.fromFormat(part.end, "HH:mmm"),
    )
  ) {
    return true;
  }
  if (!DateTime.fromFormat(sStart, "HH:mm").isValid) {
    return true;
  }
  if (!DateTime.fromFormat(shiftEnd, "HH:mm").isValid) {
    return true;
  }

  const errors = shiftParts.reduce((acc, part, i) => {
    if (!aCoversB(value, part)) {
      acc.push(
        new yup.ValidationError(
          "Passdel utanför pass",
          part,
          `${path}.shiftParts[${i}]`,
        ),
      );
    }
    return acc;
  }, [] as yup.ValidationError[]);

  if (errors.length > 0) {
    const shiftError = new yup.ValidationError(
      "Passdel är utanför pass",
      value,
      path,
    );
    return new yup.ValidationError([shiftError, ...errors]);
  }
  return true;
}

export const timeString = yup
  .string()
  .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, "Ogiltig tidsträng");

export const shiftPartSchema = yup.object().shape({
  start: timeString.required("Får ej vara tomt"),
  end: timeString.required("Får ej vara tomt"),
  partType: yup
    .string()
    .oneOf(shiftPartTypeChoices, "Ogiltig deltyp")
    .required("Får ej vara tomt"),
});

export const shiftSchema = yup
  .object()
  .shape({
    shiftId: yup.number(),
    name: yup.string().required("Får ej vara tomt"),
    start: timeString.required("Får ej vara tomt"),
    end: timeString.required("Får ej vara tomt"),
    breakTime: yup
      .number()
      .min(0, "Får inte vara negativt")
      .required("Får ej vara tomt"),
    shiftParts: yup
      .array()
      .of(shiftPartSchema.required("Får ej vara tomt"))
      .required(),
  })
  .test(
    "shiftPartsOverlap",
    "Passdel överlappar med annan passdel",
    (value, context) => shiftPartsOverlap(value, context),
  );

export const shiftGroupSchema = yup.object().shape({
  days: yup.array().of(yup.number()).required("Får ej vara tomt"),
  shifts: yup.array().of(shiftSchema).required("Får ej vara tomt"),
});

const schema = yup.object().shape({
  shiftGroups: yup.array().of(shiftGroupSchema).required("Får ej vara tomt"),
});

export default schema;

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  HeaderWeight,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Types5, Types5Info as Info } from "components/setting/common/forms";

const MODULE_NAME = "ShiftDayTypeDistribution";

type FormProps = {
  groupName: string;
  totalShifts: number;
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  const title = (
    <>
      Dag-/Kväll-/Natt-fördelning för enhet <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      name="shiftDayTypeDistributionSoft"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="dayTypeDistributionWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Types5Form({ groupName, totalShifts, readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);
  const { value: radioSoft } = useFormikState<boolean>(
    "shiftDayTypeDistributionSoft",
  );

  const who = (
    <>
      enhet <b>{groupName}</b>
    </>
  );

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title name={groupName} />}
          weightComponent={
            <Weight readOnly={readOnly} disabled={disabled || !radioSoft} />
          }
        />
        <Types5 who={who} totalShifts={totalShifts} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

import { Stack, Typography } from "@mui/material";

type Props = {
  keyId: string | number;
  translateKey: (key: any) => string;
  value: number;
  totalValue?: number;
  user: string;
  unit: string;
};

export default function Tooltip({
  keyId,
  translateKey,
  value,
  totalValue,
  user,
  unit,
}: Props) {
  const valueStr = `${translateKey(keyId)}: ${value}`;
  const sx = {
    bgcolor: "white",
    opacity: 0.9,
    p: "5px 10px",
    borderRadius: 0.5,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "divider",
  };

  const label = `${valueStr} ${unit}${
    totalValue ? ` (${Math.round((value / totalValue) * 1000) / 10}%)` : ""
  }`;

  return (
    <Stack sx={sx}>
      <Typography>{user}</Typography>
      <Typography>
        <strong>{label}</strong>
      </Typography>
    </Stack>
  );
}

/**
 * @generated SignedSource<<c83623424d185344baf7a12ee52ae34f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSelect_fragment$data = ReadonlyArray<{
  readonly fullName: string;
  readonly id: string;
  readonly " $fragmentType": "UserSelect_fragment";
}>;
export type UserSelect_fragment$key = ReadonlyArray<{
  readonly " $data"?: UserSelect_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSelect_fragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserSelect_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "124412c6e5bf916722bb729186f1abf2";

export default node;

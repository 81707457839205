import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  connectionToArray,
  ExtractNode,
  useConnectionToArray,
} from "relay-help/arrays";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  OptimizationSetting,
  useDefaultRuleGroupSubmitFunction,
  useDefaultUserSubmitFunction,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { Shift } from "components/setting/common/forms/common/ShiftSelect";
import { shiftTypeDistributionWeightChoices } from "components/setting/team_group/setting_boxes/constants";
import { useUpdateSettingsMutation } from "components/setting/team_group/setting_boxes/updateSettings";

import {
  ShiftDistributionSettingRuleGroup_fragment$data as RuleGroupData,
  ShiftDistributionSettingRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/ShiftDistributionSettingRuleGroup_fragment.graphql";
import {
  ShiftDistributionSettingTeamGroup_fragment$data as TeamGroupData,
  ShiftDistributionSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/ShiftDistributionSettingTeamGroup_fragment.graphql";
import {
  ShiftDistributionSettingUserSetting_fragment$data as UserSettingData,
  ShiftDistributionSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/ShiftDistributionSettingUserSetting_fragment.graphql";
import { ShiftDistributionForm } from "./ShiftDistributionForm";
import { ShiftDistributionTeamGroupOnlyForm } from "./ShiftDistributionTeamGroupOnlyForm";

const teamGroupFragment = graphql`
  fragment ShiftDistributionSettingTeamGroup_fragment on SettingNode {
    id
    optimizeEvenShiftTypeDistributionBetweenEmployees
    shiftTypeDistributionWeight
    constraintModules
    prohibitedShiftTypes {
      edges {
        node {
          id
        }
      }
    }
    group {
      shifts {
        edges {
          node {
            id
            name
            start
            end
            shiftParts {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

const userSettingFragment = graphql`
  fragment ShiftDistributionSettingUserSetting_fragment on UserSettingNode {
    id
    settingModules
    prohibitedShiftTypes {
      edges {
        node {
          id
          name
          shiftParts {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const ruleGroupFragment = graphql`
  fragment ShiftDistributionSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    settingModules
    prohibitedShiftTypes {
      edges {
        node {
          id
          name
          shiftParts {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const SETTING_NAME = "Passtypsfördelning";
const MODULE_NAME = "ShiftTypeDistribution";
const SETTING_URL_ID = "shift-distro";

type ShiftDistributionFormValues = Pick<
  TeamGroupData,
  "id" | "constraintModules"
> & {
  prohibitedShiftTypes: ReadonlyArray<
    ExtractNode<TeamGroupData["prohibitedShiftTypes"]>["id"]
  >;
  currentTeamGroupId?: string;
};

type ShiftDistributionTeamGroupFormValues = Pick<
  TeamGroupData,
  | "id"
  | "optimizeEvenShiftTypeDistributionBetweenEmployees"
  | "shiftTypeDistributionWeight"
  | "constraintModules"
>;

type AdditionalData = {
  shiftTypes: ReadonlyArray<Shift>;
  currentTeamGroupId: string;
};

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  prohibitedShiftTypes: yup.array().of(yup.string()).required(),
  constraintModules: yup.array().of(yup.string()).required(),
});

const teamGroupValidationSchema = yup.object().shape({
  id: yup.string(),
  optimizeEvenShiftTypeDistributionBetweenEmployees: yup.boolean().required(),
  shiftTypeDistributionWeight: yup
    .string()
    .oneOf(shiftTypeDistributionWeightChoices)
    .required(),
});

function baseConvertDataToInitialValues(
  data: TeamGroupData | UserSettingData | RuleGroupData,
) {
  return {
    id: data.id,
    prohibitedShiftTypes: connectionToArray(data.prohibitedShiftTypes).map(
      (shift) => shift.id,
    ),
  };
}

function convertTeamGroupDataToInitialValues(
  data: TeamGroupData,
): ShiftDistributionFormValues {
  return {
    ...baseConvertDataToInitialValues(data),
    constraintModules: data.constraintModules,
  };
}
function convertUserOrRuleGroupDataToInitialValues(
  data: UserSettingData | RuleGroupData,
): ShiftDistributionFormValues {
  return {
    ...baseConvertDataToInitialValues(data),
    constraintModules: data.settingModules,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): ShiftDistributionTeamGroupFormValues {
  return {
    id: teamGroupData.id,
    optimizeEvenShiftTypeDistributionBetweenEmployees:
      teamGroupData.optimizeEvenShiftTypeDistributionBetweenEmployees,
    shiftTypeDistributionWeight: teamGroupData.shiftTypeDistributionWeight,
    constraintModules: teamGroupData.constraintModules,
  };
}

function useAdditionalDataForTeamGroup(
  fragmentRef: TeamGroupKey,
): AdditionalData {
  const teamGroupData = useFragment(teamGroupFragment, fragmentRef);
  const shifts = useConnectionToArray(teamGroupData.group.shifts);

  const shiftTypes = useMemo(() => {
    return shifts.map((shift) => {
      return { ...shift, shiftParts: connectionToArray(shift.shiftParts) };
    });
  }, [shifts]);

  return {
    shiftTypes,
    currentTeamGroupId: teamGroupData.id,
  };
}

function useSubmitFunctions() {
  const onSubmitUser = useDefaultUserSubmitFunction();
  const onSubmitRuleGroup = useDefaultRuleGroupSubmitFunction();

  const [updateSettings] = useUpdateSettingsMutation();
  const onSubmitTeamGroup = useCallback(
    ({ currentTeamGroupId, ...input }: ShiftDistributionFormValues) =>
      updateSettings({ variables: { input } }),
    [updateSettings],
  );

  return { onSubmitTeamGroup, onSubmitUser, onSubmitRuleGroup };
}

function useSubmitTeamGroupOnly() {
  const [updateSettings] = useUpdateSettingsMutation();
  const onSubmitTeamGroup = useCallback(
    (input: ShiftDistributionTeamGroupFormValues) =>
      updateSettings({ variables: { input } }),
    [updateSettings],
  );

  return onSubmitTeamGroup;
}

function renderComponent(
  initialValues: ShiftDistributionFormValues,
  onSubmit: (formValues: ShiftDistributionFormValues) => void,
  additionalData: AdditionalData,
) {
  return (
    <BaseSettings
      initialValues={{
        ...initialValues,
        currentTeamGroupId: additionalData.currentTeamGroupId,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ShiftDistributionForm shiftTypes={additionalData.shiftTypes} />
    </BaseSettings>
  );
}

function renderTeamGroupComponent(
  initialValues: ShiftDistributionTeamGroupFormValues,
  onSubmit: (formValues: ShiftDistributionTeamGroupFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <ShiftDistributionTeamGroupOnlyForm />
    </BaseSettings>
  );
}

export const shiftDistributionSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  RuleGroupKey,
  ShiftDistributionFormValues,
  AdditionalData,
  ShiftDistributionTeamGroupFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  useAdditionalDataForTeamGroup,
  useSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly,
    renderComponent: renderTeamGroupComponent,
  },
};

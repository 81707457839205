import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

import { FormikSwitchWithTitleAndDesc } from "./common";

type Props = { readOnly?: boolean; disabled?: boolean };

export function UseDemandWeightsField({ readOnly, disabled }: Props) {
  return (
    <FormikSwitchWithTitleAndDesc
      name="useDemandWeights"
      title="Viktade behovsspann"
      description={
        <Typography>
          Med denna inställning påslagen kan du ställa in så att vissa
          behovsspann i <Link to={`/demand`}>Behovsprofilen</Link> ges extra
          mycket prioritet gentomot andra behovsspann vid minimering av
          underbemanning respektive spilltid.
        </Typography>
      }
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

import { Navigate, Outlet } from "react-router-dom";
import { useRequireMe } from "hooks";

import FullPageLoading from "components/loading/FullPageLoading";

type Props = { redirectTo: string; pname: string };

export function RequirePermission({ redirectTo, pname }: Props) {
  const { evaluatePermission, loading } = useRequireMe();
  if (loading) {
    return <FullPageLoading />;
  }

  if (!evaluatePermission(pname, "READ")) {
    return <Navigate to={redirectTo} />;
  }

  return <Outlet />;
}

import { SettingBox } from "components/setting/common";
import { Base2, Base2Info as Info } from "components/setting/common/forms";

type FormProps = {
  groupName: string;
  periodLengthWeeks: number;
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  return (
    <>
      Timmar & Pass för enhet <b>{name}</b>
    </>
  );
}

export function Base2Form({
  groupName,
  periodLengthWeeks,
  readOnly,
}: FormProps) {
  return (
    <SettingBox title={<Title name={groupName} />} infoButton={<Info />}>
      <Base2
        periodLengthWeeks={periodLengthWeeks}
        who="för en heltidsarbetande"
        readOnly={readOnly}
      />
    </SettingBox>
  );
}

import { Suspense } from "react";
import { TeamGroupSettingsQuery$data as TeamGroupData } from "pages/Settings/__generated__/TeamGroupSettingsQuery.graphql";
import { TeamGroupUsers } from "pages/types";
import { ConstraintModuleSwitch_fragment$key as ConstraintModuleSwitchKey } from "settings/common/__generated__/ConstraintModuleSwitch_fragment.graphql";
import { ALL_SETTINGS } from "settings/constants";

import { Visible } from "components/common/Visible";
import { SettingViewPlaceholder as Placeholder } from "components/loading/pages";

import { Level3Box } from "../../common/Level3Box";

import { TeamGroupSettingView } from "./TeamGroupSettingView";

type Props = {
  ruleId?: string;
  shiftTypes: any[];
  shifts: { worktimeHours: number }[];
  fragmentRef: any;
  teamGroupMembers: TeamGroupUsers;
  constraintModulesRef: ConstraintModuleSwitchKey;
  ruleGroups: TeamGroupData["ruleGroups"];
};

export function SettingBoxes({
  ruleId = "",
  shiftTypes,
  shifts,
  ...refs
}: Props) {
  return (
    <Suspense fallback={<Placeholder />}>
      <Level3Box>
        {ALL_SETTINGS.map((setting) => (
          <Visible key={setting.urlId} visible={ruleId === setting.urlId}>
            <TeamGroupSettingView optimizationSetting={setting} {...refs} />
          </Visible>
        ))}
      </Level3Box>
    </Suspense>
  );
}

import { Suspense, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { Dialog } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Formik } from "formik";
import * as yup from "yup";

import { useEditTeam } from "../mutations";
import type { EditTeamInput as FormValues } from "../types";

import { TeamForm } from "./common";
import type {
  EditTeamForm_team$key as Key,
  EditTeamFormQuery as Query,
} from "./types";

type Common = {
  open: boolean;
  onClose: () => void;
};

type Props = Common & { id?: string };

type FormProps = Common & {
  queryRef: PreloadedQuery<Query>;
};

const fragment = graphql`
  fragment EditTeamForm_team on TeamNode {
    id
    name
    color
  }
`;
const query = graphql`
  query EditTeamFormQuery($id: ID!) {
    team(id: $id) {
      ...EditTeamForm_team
    }
  }
`;

const colorString = yup.string().matches(/^#[0-9a-fA-F]{6}$/, "Ogiltig färg");

const validationSchema = yup.object({
  id: yup.string().required(""),
  name: yup.string().required("Fyll i ett namn"),
  color: colorString,
});

function Content({ queryRef, open, onClose }: FormProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const selected = useFragment<Key>(fragment, data.team);
  const [commit] = useEditTeam();

  const initialValues: FormValues = {
    name: selected?.name || "",
    id: selected?.id || "",
    color: selected?.color || "",
  };

  async function onSubmit(input: FormValues) {
    return commit({ variables: { input } }).then(onClose).catch(console.error);
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <TeamForm
          title="Redigera avdelning"
          submitText="Spara"
          onClose={onClose}
        />
      </Formik>
    </Dialog>
  );
}

export function EditTeamForm({ id, ...props }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    if (!id) return;
    loadQuery({ id });
  }, [loadQuery, id]);

  return (
    <Suspense>
      {!!queryRef && <Content queryRef={queryRef} {...props} />}
    </Suspense>
  );
}

import type { StackProps } from "@mui/material";
import { Stack, Typography } from "@mui/material";

import ColorDot from "components/common/ColorDot";

type Props = Omit<StackProps, "color"> & {
  label: string;
  color?: string;
};

export function LabeledColorDot({ color, label, ...stackProps }: Props) {
  return (
    <Stack
      gap={1}
      direction="row"
      alignSelf="center"
      alignItems="stretch"
      {...stackProps}
    >
      {!!color && <ColorDot color={color} />}
      <Typography variant="h4" flexWrap={{ xs: "wrap", lg: "nowrap" }}>
        {label}
      </Typography>
    </Stack>
  );
}

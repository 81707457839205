import { Stack } from "@mui/material";

import { InfoText } from "components/layout";

export const BaseDemandInfo = () => (
  <Stack gap={1}>
    <InfoText>
      Algoritmen kommer göra sitt bästa för att täcka behovet som anges i
      behovsprofilen. Men, om antalet personer inte räcker till, eller andra
      schemaregler är för snävt satta, så kanske inte hela behovsprofilen går
      att täcka.
    </InfoText>
    <InfoText>
      Här kan du alltså styra om schemat ska tillåta underbemanning i de fall
      det är nödvändigt. Vi rekommenderar att detta val alltid ska vara påslaget
      - för om det inte går att täcka behovsprofilen (exempelvis på grund av att
      teamet är för litet), och detta val är påslaget, då kommer algoritmen inte
      kunna skapa något schema alls.
    </InfoText>
  </Stack>
);

import { useCallback } from "react";
import { Stack } from "@mui/material";

import { SectionAccordion } from "./SectionAccordion";
import type { Section } from "./types";

type Props = {
  sections: Section[];
};

export function SectionList({ sections }: Props) {
  const renderSection = useCallback(
    (section: Section) => (
      <SectionAccordion key={section.title} section={section} />
    ),
    [],
  );

  return (
    <Stack gap={1} overflow="auto">
      {sections.map(renderSection)}
    </Stack>
  );
}

import { useFragment } from "react-relay/hooks";
import { Paper, Stack, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { SolutionStatus, TerminationCondition } from "types/Schedule";

import type { InfeasibleResults_fragment$key as Key } from "./__generated__/InfeasibleResults_fragment.graphql";

const fragment = graphql`
  fragment InfeasibleResults_fragment on ScheduleNode {
    id
    terminationCondition
    solutionStatus
  }
`;

type Props = {
  fragmentRef: Key;
  emptyPaperSx: any;
};

export function InfeasibleResults({ fragmentRef, emptyPaperSx }: Props) {
  const { terminationCondition, solutionStatus } = useFragment<Key>(
    fragment,
    fragmentRef,
  );

  return (
    <Paper variant="box" sx={emptyPaperSx}>
      {solutionStatus === SolutionStatus.RUNNING ||
      terminationCondition === TerminationCondition.OTHER ||
      terminationCondition === null ? (
        <Stack
          gap={4}
          height={84}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography fontStyle="italic">
            {solutionStatus === SolutionStatus.RUNNING
              ? "Optimering pågår..."
              : "Ett systemfel har inträffat. Kontakta din administratör."}
          </Typography>
        </Stack>
      ) : (
        <Stack gap={4} maxWidth={700}>
          <Stack gap={1}>
            <Typography variant="h4">
              Här finns inget resultat att visa!
            </Typography>
            <Typography>
              Den här schemakörningen var olöslig. Det berodde på att en eller
              flera hårda regler krockade med varandra.
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="h4">Hör av dig till oss!</Typography>
            <Typography>
              Vi har aldrig stött på just den här specifika typen av krock
              mellan hårda regler förut, och därför kunde inte algoritmen
              hantera den. Hör av dig till oss, så bygger vi stöd för att
              algoritmen ska hantera krocken bättre i framtiden.
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="h4">Vad kan du göra nu?</Typography>
            <Typography>
              Tills dess kan du pröva att mjuka upp dina hårda regler. Kanske
              någon av dem kan göras mjuk?
            </Typography>
          </Stack>
        </Stack>
      )}
    </Paper>
  );
}

import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { AB2B } from "components/setting/common/forms";

import { weekRestSetting } from "./WeekRestSetting";

type FormProps = { readOnly?: boolean };

export function WeekRestForm({ readOnly = false }: FormProps) {
  const disabled = !useIsConstraintModuleEnabled(weekRestSetting.moduleName);

  return (
    <PaperWrapper>
      <AB2B disabled={disabled} readOnly={readOnly} />
    </PaperWrapper>
  );
}

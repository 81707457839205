import { useCallback, useMemo } from "react";
import { ListItem, ListItemText } from "@mui/material";
import { EMPTY_ARRAY } from "utils/constants";

import { ALL_TEAMS } from "components/demand";

import type { GenericRenderGroupParams } from "../GenericSelect";
import {
  DisableClearable,
  SingleAutoSelect,
  SingleAutoSelectProps,
} from "../GenericSelect/SingleAutoSelect";

import { OptionLabel } from "./OptionLabel";
import type { Option } from "./types";

const ALL_TEAMS_OPTION = {
  id: ALL_TEAMS,
  name: "Alla avdelningar",
  color: "",
  group: { id: "", name: "" },
};

function sortOptions(a: Option, b: Option) {
  return (
    a.group.name.localeCompare(b.group.name) || a.name.localeCompare(b.name)
  );
}

type Props<Value> = SingleAutoSelectProps<Value, DisableClearable> & {
  allowSelectAll?: boolean;
  getOptionLabel?: (option: Option) => string;
  groupByGroup?: boolean;
  filterOnGroupIds?: string[];
};

export function BaseTeamSelect2({
  options,
  groupByGroup = false,
  filterOnGroupIds = [],
  allowSelectAll = false,
  noOptionsText = "Inga ytterligare avdelningar finns tillagda",
  label = "Avdelning",
  placeholder = "Välj avdelning...",
  getOptionLabel = (option: Option) => option?.name ?? "",
  ...props
}: Props<Option>) {
  const optFilter = useCallback(
    (o: Option) =>
      filterOnGroupIds.length === 0 || filterOnGroupIds.includes(o.group.id),
    [filterOnGroupIds],
  );

  const groupBy = useMemo(
    () => (groupByGroup ? (o: Option) => o.group.id : undefined),
    [groupByGroup],
  );

  const allOptions = useMemo(
    () => [
      ...(allowSelectAll ? [ALL_TEAMS_OPTION] : EMPTY_ARRAY),
      ...options.filter(optFilter).sort(sortOptions),
    ],
    [allowSelectAll, options, optFilter],
  );

  const groupNames = useMemo(
    () =>
      allOptions.reduce(
        (acc, o) => {
          const { id, name } = o.group;
          if (!acc[id]) {
            acc[id] = name;
          }
          return acc;
        },
        {} as Record<string, string>,
      ),
    [allOptions],
  );

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement> & { key: any },
      option: Option,
    ) => {
      const { key, ...otherProps } = props;
      return (
        <ListItem sx={{ py: 0, pl: "2px" }} key={key} {...otherProps}>
          <OptionLabel label={getOptionLabel(option)} color={option.color} />
        </ListItem>
      );
    },
    [getOptionLabel],
  );

  const getGroupLabel = useCallback(
    (group: string) => groupNames[group],
    [groupNames],
  );

  const renderGroup = useCallback(
    (params: GenericRenderGroupParams) => [
      <ListItem key={params.key} sx={{ py: 0.2 }}>
        <ListItemText primary={getGroupLabel(params.group)} />
      </ListItem>,
      params.children,
    ],
    [getGroupLabel],
  );

  return (
    <SingleAutoSelect
      {...props}
      options={allOptions}
      defaultValue={allowSelectAll ? ALL_TEAMS_OPTION : undefined}
      groupBy={groupBy}
      renderGroup={renderGroup}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      label={label}
      placeholder={placeholder}
      noOptionsText={noOptionsText}
      disableClearable
    />
  );
}

import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { Dialog } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { commitMutation } from "libs/commitMutation";

import { MultiEditUsersForm as Form } from "./forms";
import type {
  MultiEditUsers_fragment$key as Key,
  MultiEditUsersMutation,
  MultiEditUsersMutation$variables as FormValues,
  MultiEditUsersQuery,
} from "./types";

const fragment = graphql`
  fragment MultiEditUsers_fragment on UserNode @relay(plural: true) {
    id
    fullName
    groupMemberOf {
      edges {
        node {
          id
        }
      }
    }
    memberOf {
      edges {
        node {
          id
        }
      }
    }
  }
`;

const query = graphql`
  query MultiEditUsersQuery($userIds: [ID!]!) {
    users(ids: $userIds) {
      ...MultiEditUsers_fragment
    }
    teamGroups {
      id
      name
      teams {
        edges {
          node {
            id
            name
            color
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation MultiEditUsersMutation(
    $userIds: [ID!]!
    $teamIds: [ID!]!
    $teamGroupIds: [ID!]!
  ) {
    multiEditUsers(
      input: {
        userIds: $userIds
        teamIds: $teamIds
        teamGroupIds: $teamGroupIds
      }
    ) {
      ok
      users {
        ...MultiEditUsers_fragment
      }
    }
  }
`;

type ContentProps = {
  queryRef: PreloadedQuery<MultiEditUsersQuery>;
  open: boolean;
  onClose: () => void;
};
type Props = {
  userIds?: string[];
  open: boolean;
  onClose: () => void;
};

function Content({ queryRef, open, onClose }: ContentProps) {
  const environment = useRelayEnvironment();
  const { users: fragmentRef, teamGroups } =
    usePreloadedQuery<MultiEditUsersQuery>(query, queryRef);
  const users = useFragment<Key>(fragment, fragmentRef);

  const userOpts = useMemo(
    () =>
      (users || []).map((x) => ({ id: x?.id || "", name: x?.fullName || "" })),
    [users],
  );
  const teamGroupOpts = useMemo(
    () =>
      (teamGroups || []).map((x) => ({
        name: x?.name || "",
        id: x?.id || "",
        teams: (x?.teams?.edges || []).map((y) => ({
          name: y?.node?.name || "",
          id: y?.node?.id || "",
          color: y?.node?.color || "",
        })),
      })),
    [teamGroups],
  );

  const initialValues: FormValues = {
    userIds: (users || []).map((user) => user?.id || "").filter(Boolean),
    teamIds: [],
    teamGroupIds: [],
  };
  async function onSubmit(values: FormValues) {
    await commitMutation<MultiEditUsersMutation>(environment, {
      mutation,
      variables: values,
    })
      .then(onClose)
      .catch((error) => console.error(error));
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <FormikForm autoComplete="off">
          <Form
            title={`Redigera ${users?.length} användares tillhörighet`}
            onClose={onClose}
            users={userOpts}
            teamGroups={teamGroupOpts}
          />
        </FormikForm>
      </Formik>
    </Dialog>
  );
}

export function MultiEditUsers({ userIds, open, onClose }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<MultiEditUsersQuery>(query);

  useEffect(() => {
    if (!userIds?.length) return;
    loadQuery({ userIds });
  }, [userIds, loadQuery]);

  return (
    <Suspense fallback={null}>
      {!!queryRef && (
        <Content queryRef={queryRef} open={open} onClose={onClose} />
      )}
    </Suspense>
  );
}

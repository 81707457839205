import { Suspense, useEffect, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import AddIcon from "@mui/icons-material/Add";
import { Button, Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useTeamGroups } from "contexts/TeamGroupsContext";
import { useAdminParams } from "hooks";

import { useActivateTeam, useArchiveTeam } from "components/admin";
import Table from "components/admin/tables/TeamsTable";
import type { Team } from "components/admin/types";
import DeleteModal from "components/common/DeleteModal";
import { CreateTeamForm, useDeleteTeam } from "components/group_settings";
import { AdminTeamsPlaceholder as Placeholder } from "components/loading/pages";

import { AdminTeamsQuery as Query } from "./__generated__/AdminTeamsQuery.graphql";

const query = graphql`
  query AdminTeamsQuery {
    allTeams {
      ...TeamsTable_fragment
    }
  }
`;

type InnerProps = {
  queryRef: PreloadedQuery<Query>;
  onDelete: (team: Team) => void;
  onEdit: (id: string) => void;
  onArchive: (id: string) => void;
  onActivate: (id: string) => void;
};

function Actions({ onNew }: { onNew: () => void }) {
  return (
    <Button
      variant="primary"
      endIcon={<AddIcon />}
      onClick={onNew}
      sx={{ alignSelf: "flex-end" }}
    >
      Ny avdelning
    </Button>
  );
}

function Inner({
  queryRef,
  onDelete,
  onEdit,
  onArchive,
  onActivate,
}: InnerProps) {
  const { allTeams } = usePreloadedQuery(query, queryRef);

  return (
    <Table
      fragmentRef={allTeams}
      onEdit={onEdit}
      onArchive={onArchive}
      onActivate={onActivate}
      onDelete={onDelete}
    />
  );
}

export function AdminTeams() {
  const { setTeamToEdit, teamGroupOptions } = useTeamGroups();
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const { newTeam: newOpen, setNewTeam: setNewOpen } = useAdminParams();
  const [toDelete, setToDelete] = useState<Team | null>(null);
  const [activateTeam] = useActivateTeam();
  const [archiveTeam] = useArchiveTeam();
  const [deleteTeam] = useDeleteTeam();

  const closeDelete = () => setToDelete(null);

  useEffect(() => {
    loadQuery({});
  }, [loadQuery]);

  function onEdit(id: string) {
    setTeamToEdit(id);
  }

  function onArchive(id?: string) {
    if (!id) return;
    archiveTeam({ variables: { id } });
  }

  function onActivate(id?: string) {
    if (!id) return;
    activateTeam({ variables: { id } });
  }

  function onDelete(id?: string) {
    if (!id) return;
    deleteTeam({ variables: { id } }).then(closeDelete);
  }

  return (
    <>
      <Paper variant="box" sx={{ p: 1.5 }}>
        <Stack gap={2}>
          <Actions onNew={() => setNewOpen(true)} />
          <Suspense fallback={<Placeholder />}>
            {!!queryRef && (
              <Inner
                queryRef={queryRef}
                onDelete={setToDelete}
                onEdit={onEdit}
                onArchive={onArchive}
                onActivate={onActivate}
              />
            )}
          </Suspense>
        </Stack>
      </Paper>
      <Suspense fallback={null}>
        <CreateTeamForm
          open={newOpen}
          onClose={() => setNewOpen(false)}
          teamGroupOptions={teamGroupOptions}
        />
      </Suspense>
      <DeleteModal
        show={!!toDelete}
        onHide={closeDelete}
        onDeleteClick={() => onDelete(toDelete?.id)}
        deleteTitle="Radera avdelning"
        deleteMessage={`Är du säker på att du vill radera avdelning ${toDelete?.name}? Detta går inte att ångra!`}
        buttonText="Radera"
      />
    </>
  );
}

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderSwitch,
  HeaderTitle,
  HeaderWeight,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Types7, Types7Info as Info } from "components/setting/common/forms";

type FormProps = {
  groupName: string;
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  const title = (
    <>
      Dag-/Kväll-/Natt-kadens för enhet <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

function Switch({ readOnly }: { readOnly?: boolean }) {
  return <HeaderSwitch name="ShiftDayTypeCadence" readOnly={readOnly} />;
}
function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="dayTypeCadenceWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Types7Form({ groupName, readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes("ShiftDayTypeCadence");

  const who = (
    <>
      enhet <b>{groupName}</b>
    </>
  );

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={<Switch readOnly={readOnly} />}
          titleComponent={<Title name={groupName} />}
          weightComponent={<Weight readOnly={readOnly} disabled={disabled} />}
        />
        <Types7 who={who} readOnly={readOnly} disabled={disabled} />
      </Stack>
    </SettingBox>
  );
}

import { useCallback } from "react";
import { Grid } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import PaperWrapper from "settings/common/PaperWrapper";

import { LabeledHardSoftRadios } from "../common/LabeledHardSoftRadios";
import { LabeledWeightSelect } from "../common/LabeledWeightSelect";

import { CompetenceLabel } from "./CompetenceLabel";

type Props = { name: string; disabled?: boolean; readOnly?: boolean };

export function CompetenceDemandSetting({ name, disabled, readOnly }: Props) {
  const { value: allowUnderstaffing } = useFormikState<boolean>(
    `${name}.allowUnderstaffing`,
  );

  const getName = useCallback(
    (fieldName: string) => `${name}.${fieldName}`,
    [name],
  );

  return (
    <PaperWrapper mt={0} sx={{ height: "100%" }}>
      <Grid
        container
        columnGap={6}
        rowGap={2}
        direction="column"
        flexWrap={{ xs: "wrap", lg: "nowrap" }}
      >
        <Grid
          container
          columnGap={3}
          rowGap={1}
          direction="row"
          justifyContent={"space-between"}
        >
          <CompetenceLabel name={getName("competence")} />

          <LabeledHardSoftRadios
            name={getName("allowUnderstaffing")}
            label="Tillåt underbemanning"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Grid>
        <LabeledWeightSelect
          name={getName("understaffingWeight")}
          label="Prioritet för minimering av total underbemanning"
          disabled={disabled || !allowUnderstaffing}
          readOnly={readOnly}
          sx={{ width: 150 }}
        />
      </Grid>
    </PaperWrapper>
  );
}

import type { Dispatch, SetStateAction } from "react";
import { useMemo } from "react";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import type { RuleGroup, User } from "pages/Schedule/types";

import { Level2Menu as Level2 } from "components/setting/common";

import { getSelectedMenuItems } from "./functions";
import { useSelectedSetting } from "./hooks";

type Props = {
  levelId: string;
  ruleId: string;
  setRuleId: Dispatch<SetStateAction<string>>;
  groupModules: string[];
  ruleGroups: ReadonlyArray<RuleGroup>;
  users: ReadonlyArray<User>;
  showAdvanced?: boolean;
};

export function Level2Menu({
  groupModules,
  ruleGroups,
  users,
  levelId,
  ruleId,
  setRuleId,
  showAdvanced,
}: Props) {
  const groupId = useCurrentTeamGroup()?.id ?? "";

  const { selectedRuleGroup, selectedUser, selectedType } = useSelectedSetting<
    RuleGroup,
    User
  >({
    groupId,
    levelId,
    userOptions: users,
    ruleGroupOptions: ruleGroups,
  });

  const ruleGroupModules = useMemo(
    () => selectedRuleGroup?.ruleGroupSetting?.settingModules || [],
    [selectedRuleGroup],
  );
  const userModules = useMemo(
    () => selectedUser?.userSetting?.settingModules || [],
    [selectedUser],
  );

  const constraintModules = useMemo<readonly string[]>(() => {
    // Return constraint modules for the selected type and levelId
    switch (selectedType) {
      case "group":
        return groupModules;
      case "ruleGroup":
        return ruleGroupModules;
      case "person":
        return userModules;
      default:
        return [];
    }
  }, [selectedType, groupModules, ruleGroupModules, userModules]);

  const menuItems = useMemo(
    () => (selectedType ? getSelectedMenuItems(selectedType) : []),
    [selectedType],
  );

  function onSelect(section: string, item: string) {
    setRuleId(`${section}-${item}`);
  }

  return (
    <Level2
      ruleId={ruleId}
      onSelect={onSelect}
      constraintModules={constraintModules.slice()}
      menuItems={menuItems}
      showAdvanced={showAdvanced}
    />
  );
}

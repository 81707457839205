import {
  InputAdornment,
  Radio,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

type Props = {
  value: number | null;
  onChange: (value: number | "" | null) => void;
};

export default function CountSelect({ value, onChange }: Props) {
  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "count") {
      onChange(2);
    } else {
      onChange(null);
    }
  }
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value === "" ? "" : Number(event.target.value));
  }

  return (
    <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
      <Radio
        value="count"
        checked={value !== null}
        onChange={handleRadioChange}
      />
      <Typography sx={{ pr: 1 }}>Händelsen har hänt</Typography>
      <TextField
        type="number"
        value={value || ""}
        onChange={handleChange}
        disabled={value === null}
        size="small"
        InputProps={{
          inputProps: { min: 2 },
          endAdornment: (
            <InputAdornment position="end">gång(er)</InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

/**
 * @generated SignedSource<<3b35c35c472a80add62ca7d820b7dd02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingWishedFreeDaysWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RequestedDaysOffSettingTeamGroup_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly maximizeApprovedWishes: boolean;
  readonly optimizeApprovedWishesDistributionBetweenEmployees: boolean;
  readonly wishedFreeDaysWeight: ApiSettingWishedFreeDaysWeightChoices;
  readonly " $fragmentType": "RequestedDaysOffSettingTeamGroup_fragment";
};
export type RequestedDaysOffSettingTeamGroup_fragment$key = {
  readonly " $data"?: RequestedDaysOffSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequestedDaysOffSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequestedDaysOffSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximizeApprovedWishes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeApprovedWishesDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wishedFreeDaysWeight",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "7c9bf966a89ef58776dd5ae25ca724dc";

export default node;

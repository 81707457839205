import {
  AccountTree as PassprofilIcon,
  CalendarMonth as ScheduleIcon,
  DateRange as VacationIcon,
  Event as MeetingIcon,
  Groups as MembersIcon,
  HomeRounded as HomeIcon,
  LeaderboardRounded as BehovsprofilIcon,
  Person as AdminIcon,
  RoomPreferences as GroupSettingIcon,
  Tornado as SettingsIcon,
} from "@mui/icons-material";
import type { Route } from "types/Route";
import { optimizationRulesUrl } from "utils/urls";

export const homeRoute: Route = {
  icon: <HomeIcon />,
  label: "Hem",
  to: "/",
  end: true,
};
export const adminRoute: Route = {
  icon: <AdminIcon />,
  label: "Admin",
  to: "/admin",
};
export const groupSettingRoute: Route = {
  icon: <GroupSettingIcon />,
  label: "Enhet",
  to: "/group-settings",
  end: true,
};
export const memberRoute: Route = {
  icon: <MembersIcon />,
  label: "Personallista",
  to: "/members",
};
export const settingRoute: Route = {
  icon: <SettingsIcon />,
  label: "Optimeringsregler",
  to: optimizationRulesUrl(),
};
export const shiftRoute: Route = {
  icon: <PassprofilIcon />,
  label: "Passprofil",
  to: "/shifts",
};
export const meetingRoute: Route = {
  icon: <MeetingIcon />,
  label: "Möten",
  to: "/meetings",
};
export const demandRoute: Route = {
  icon: <BehovsprofilIcon />,
  label: "Behovsprofil",
  to: "/demand",
};
export const vacationRoute: Route = {
  icon: <VacationIcon />,
  label: "Händelser",
  to: "/vacation",
};
export const periodRoute: Route = {
  icon: <ScheduleIcon />,
  label: "Lägg Schema",
  to: "/periods",
};

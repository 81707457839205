/**
 * @generated SignedSource<<2c402e3447891aaabcc6ac317810d48d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResourceTimeSettingTeamGroup_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly resourceHoursMax: number;
  readonly resourceHoursMin: number;
  readonly " $fragmentType": "ResourceTimeSettingTeamGroup_fragment";
};
export type ResourceTimeSettingTeamGroup_fragment$key = {
  readonly " $data"?: ResourceTimeSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResourceTimeSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResourceTimeSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceHoursMin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceHoursMax",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "afe930d1585b38da752456b815f2b5e7";

export default node;

import { useMemo } from "react";
import { LONG_DAYS } from "utils/day";

import type { DayMatrix } from "../types";

const days = LONG_DAYS;
const MANY_DAYS_SELECTED_LABEL = "Flera olika dagar & veckor";
const LONG_DESCRIPTION_LIMIT = 40;

export const useMeetingCadenceDescription = (dayMatrix: DayMatrix) =>
  useMemo(() => getMeetingCadenceDescription(dayMatrix), [dayMatrix]);

/** Get a human-readable description of how the dayMatrix pattern is. */
function getMeetingCadenceDescription(dayMatrix: DayMatrix) {
  if (!dayMatrix) return "";

  const weeksWithDay = dayMatrixToWeeksWithDay(dayMatrix);

  let dayStrings: string[] = weeksWithDay
    .map((d, i) => [d, i] as [number[], number])
    .filter(([d]) => d.length > 0)
    .map(([weeks, i]) => weeksWithDayToString(i, weeks));

  const weekNrsWithDays = dayMatrix
    .map((w, i) => [w.length, i])
    .filter(([w]) => w > 0)
    .map(([, i]) => i);
  if (
    weekNrsWithDays.length === 1 &&
    weeksWithDay.filter((d) => d.length > 0).length > 2
  ) {
    // One week, more than 2 days selected
    return `Flera olika dagar v. ${weekNrsWithDays[0] + 1}`;
  }

  const magicDescription = dayStrings.join(", ");

  if (magicDescription.length > LONG_DESCRIPTION_LIMIT) {
    return MANY_DAYS_SELECTED_LABEL;
  }
  return magicDescription;
}

/** Example: "Måndag v. 1, 2 & 3, Tisdag v. 1". */
function weeksWithDayToString(dayIdx: number, weeks: number[]) {
  if (weeks.length < 1) return "";
  const weeksPlus1 = weeks.map((w) => w + 1);
  const weekStr = weeksPlus1.slice(0, weeksPlus1.length - 1).join(", ");
  const andFinal = ` & ${weeksPlus1[weeks.length - 1]}`;
  return `${days[dayIdx]} v. ${weekStr}${weeks.length > 1 ? andFinal : weeksPlus1[0]}`;
}

/** Invert dayMatrix to weeksWithDay.
 * Weeks and days are 0-indexed. (0 = week 1) (0 = monday)
 *
 * dayMatrix has the form: `[[<week1 days>], [<week2 days>],...]`
 * weeksWithDay has the form: `[[weeks with monday], [weeks with tuesday],...]`
 */
function dayMatrixToWeeksWithDay(dayMatrix: DayMatrix) {
  const weeksWithDay: number[][] = Array.from({ length: 7 }, () => []);

  dayMatrix.forEach((week, weekIdx) => {
    week.forEach((day) => {
      weeksWithDay[day].push(weekIdx);
    });
  });

  return weeksWithDay;
}

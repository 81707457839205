import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import {
  Worktime2,
  Worktime2Info as Info,
} from "components/setting/common/forms";

const MODULE_NAME = "ResourceTime";

type TitleProps = { name: string };
type FormProps = TitleProps & {
  name: string;
  readOnly?: boolean;
};

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande resurstid för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}
function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      type="onlyHard"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Worktime2Form({ name, readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title name={name} />}
        />
        <Worktime2
          who={` för ${name}`}
          readOnly={readOnly}
          showProhibitedResourceWeekDays
        />
      </Stack>
    </SettingBox>
  );
}

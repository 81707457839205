/**
 * @generated SignedSource<<d0eef732695321dbf7fc38172373d227>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateShiftsInput = {
  clientMutationId?: string | null;
  shiftGroups: ReadonlyArray<ShiftGroupInput>;
  teamGroupId: string;
};
export type ShiftGroupInput = {
  days: ReadonlyArray<number>;
  shifts: ReadonlyArray<ShiftInput>;
};
export type ShiftInput = {
  breakTime: number;
  end: string;
  id?: string | null;
  name: string;
  shiftParts: ReadonlyArray<ShiftPartInputType>;
  start: string;
};
export type ShiftPartInputType = {
  end: string;
  id?: string | null;
  partType: string;
  start: string;
};
export type CreateShiftsMutation$variables = {
  input: CreateShiftsInput;
};
export type CreateShiftsMutation$data = {
  readonly createShifts: {
    readonly ok: boolean | null;
  } | null;
};
export type CreateShiftsMutation = {
  response: CreateShiftsMutation$data;
  variables: CreateShiftsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateShiftsPayload",
    "kind": "LinkedField",
    "name": "createShifts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateShiftsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateShiftsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f9a64695d556d17d3724a9c2e8544c58",
    "id": null,
    "metadata": {},
    "name": "CreateShiftsMutation",
    "operationKind": "mutation",
    "text": "mutation CreateShiftsMutation(\n  $input: CreateShiftsInput!\n) {\n  createShifts(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "933ddb396abb574b2ccdce383fbc9d4d";

export default node;

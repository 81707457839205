/**
 * @generated SignedSource<<6c350a2eefb88b186b8c99b017a04796>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiCompetenceDemandSettingUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CompetenceDemandSettingTeamGroup_fragment$data = {
  readonly group: {
    readonly competenceDemandSettings: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly allowUnderstaffing: boolean;
          readonly competence: {
            readonly color: string;
            readonly name: string;
          };
          readonly id: string;
          readonly understaffingWeight: ApiCompetenceDemandSettingUnderstaffingWeightChoices;
        } | null;
      } | null>;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "CompetenceDemandSettingTeamGroup_fragment";
};
export type CompetenceDemandSettingTeamGroup_fragment$key = {
  readonly " $data"?: CompetenceDemandSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetenceDemandSettingTeamGroup_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompetenceDemandSettingTeamGroup_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamGroupNode",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceDemandSettingNodeConnection",
          "kind": "LinkedField",
          "name": "competenceDemandSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetenceDemandSettingNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompetenceDemandSettingNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "allowUnderstaffing",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "understaffingWeight",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CompetenceNode",
                      "kind": "LinkedField",
                      "name": "competence",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "color",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};
})();

(node as any).hash = "71adaf356c9fe71613f3bd98b24554f9";

export default node;

import { ChangeEvent, useCallback } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useFormikState } from "hooks";

import type { ApiSettingTimebankModeChoices as TimebankModeType } from "../types";
import { timebankModes } from "../types";

type Props = {
  value: TimebankModeType;
  setValue: (v: TimebankModeType) => void;
  readOnly?: boolean;
  disabled?: boolean;
};

type FormikProps = Omit<Props, "value" | "setValue"> & { name: string };

function TimebankMode({ value, setValue, readOnly, disabled }: Props) {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      readOnly ? {} : setValue(e.target.value as TimebankModeType),
    [readOnly, setValue],
  );
  return (
    <RadioGroup name="timebankMode" value={value} onChange={onChange}>
      <FormControlLabel
        label="Nej, ingen timbank (passens start- och sluttider förlängs eller förkortas)"
        control={<Radio value={timebankModes.SPARETIME} readOnly={readOnly} />}
        disabled={disabled}
        sx={{ mb: 0 }}
      />
      <FormControlLabel
        name="timebankMode"
        label="Ja, för över till timbank (passens start- och sluttider lämnas oförändrade)"
        control={<Radio value={timebankModes.TIMEBANK} readOnly={readOnly} />}
        disabled={disabled}
      />
    </RadioGroup>
  );
}

export function FormikTimebankMode({ name, ...props }: FormikProps) {
  const { value, setValue } = useFormikState<TimebankModeType>(name);

  return <TimebankMode value={value} setValue={setValue} {...props} />;
}

import type { ComponentProps } from "react";
import { useMemo } from "react";
import { forwardRef } from "react";
import { IconButton, Stack, Typography } from "@mui/material";

type ButtonProps = ComponentProps<typeof IconButton>;
type StackProps = ComponentProps<typeof Stack>;
type TypographyProps = ComponentProps<typeof Typography>;

type IconButtonProps = ButtonProps & {
  icon: React.ReactNode;
  text?: string;
  stackProps?: StackProps;
  typographyProps?: TypographyProps;
};

export const IconButtonWithText = forwardRef(function IconButtonWithText(
  {
    icon,
    text,
    stackProps = {},
    typographyProps = {},
    size = "small",
    ...p
  }: IconButtonProps,
  ref: any,
) {
  const textComponent = useMemo(
    () =>
      text ? (
        <Typography variant="caption" {...typographyProps}>
          {text}
        </Typography>
      ) : null,
    [text, typographyProps],
  );

  return (
    <IconButton {...p} size={size} ref={ref}>
      <Stack alignItems="center" {...stackProps}>
        {icon}
        {textComponent}
      </Stack>
    </IconButton>
  );
});

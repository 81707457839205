import { useCallback, useMemo } from "react";
import { ListItem } from "@mui/material";

import { CompetenceChip } from "components/common/CompetenceChip";

import type {
  GenericOwnerState,
  GenericRenderGetTagProps,
  GenericRenderOptionState,
  MultiProps as GenericMultiProps,
} from "../GenericSelect";
import { GenericSelect, OptionCheckbox } from "../GenericSelect";

import { OptionLabel } from "./OptionLabel";
import type { MultiProps, Option } from "./types";

type GenericProps = Omit<GenericMultiProps<Option>, "value" | "onChange">;

type RenderOptionParams = React.HTMLAttributes<HTMLLIElement>;
type OwnerState = GenericOwnerState<Option, true>;

const getValue = (option: Option | null) => option?.id ?? "";

export function MultiSelect({
  showSelectAll = false,
  value: valueProp,
  onChange: onChangeProp,
  label = "Kompetenser",
  placeholder = "Välj kompetenser...",
  isOptionEqualToValue = (option: Option, value: Option | null) =>
    option.id === value?.id,
  ...props
}: MultiProps) {
  const { options } = props;

  const value = useMemo<Option[]>(
    () => options.filter((o) => (valueProp || []).includes(getValue(o))),
    [options, valueProp],
  );

  const select = useCallback(
    (v: ReadonlyArray<Option>) => {
      if (onChangeProp) onChangeProp(v.map(getValue));
    },
    [onChangeProp],
  );

  const onChange = useCallback(
    (_e: any, newValue: Option[] | null) => {
      select(newValue || []);
    },
    [select],
  );

  const onSelectAll = useCallback(() => {
    select(options);
  }, [select, options]);

  const renderTags = useCallback(
    (
      value: Option[],
      getTagProps: GenericRenderGetTagProps,
      _ownerState: OwnerState,
    ) =>
      (value || []).map((option, index: number) => {
        const { key, ...props } = getTagProps({ index });
        return <CompetenceChip key={key} {...props} competence={option} />;
      }),
    [],
  );

  const renderOption = useCallback(
    (
      params: RenderOptionParams,
      option: Option,
      { selected }: GenericRenderOptionState,
      ownerState: OwnerState,
    ) => (
      <ListItem sx={{ py: 0, pl: "2px" }} {...params}>
        <OptionCheckbox checked={selected} />
        <OptionLabel
          label={ownerState.getOptionLabel(option)}
          color={option.color}
        />
      </ListItem>
    ),
    [],
  );

  return (
    <GenericSelect<Option>
      onSelectAll={showSelectAll ? onSelectAll : undefined}
      renderOption={renderOption}
      renderTags={renderTags}
      {...(props as GenericProps)}
      multiple
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
}

/**
 * @generated SignedSource<<f255cd2af617426aad3a7693134a5788>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MeetingErrorCode = "MEETING_OVERLAPS_WITH_SHIFT_PART" | "MEETING_SHIFT_DOES_NOT_COVER_MEETING" | "OTHER" | "USER_HAS_CONFLICTING_MEETING" | "%future added value";
export type ValidateMeetingShiftsInput = {
  clientMutationId?: string | null;
  dayMatrix: ReadonlyArray<ReadonlyArray<number>>;
  end: string;
  id?: string | null;
  shifts: ReadonlyArray<MeetingShiftInput>;
  start: string;
  teamGroupId: string;
  users: ReadonlyArray<string>;
};
export type MeetingShiftInput = {
  breakTime: number;
  end: string;
  id?: string | null;
  meetingDay: number;
  name: string;
  otherMeetings: ReadonlyArray<string>;
  shiftParts: ReadonlyArray<MeetingShiftPartInput>;
  start: string;
};
export type MeetingShiftPartInput = {
  end: string;
  id?: string | null;
  partType: string;
  start: string;
};
export type ValidateMeetingShiftsMutation$variables = {
  input: ValidateMeetingShiftsInput;
};
export type ValidateMeetingShiftsMutation$data = {
  readonly validateMeetingShifts: {
    readonly errors: ReadonlyArray<{
      readonly causedByMeeting: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly causedByShift: {
        readonly name: string;
      } | null;
      readonly causedByUser: {
        readonly fullName: string;
        readonly id: string;
      } | null;
      readonly code: MeetingErrorCode;
      readonly partStartsAt: string | null;
    }> | null;
    readonly ok: boolean;
  } | null;
};
export type ValidateMeetingShiftsMutation = {
  response: ValidateMeetingShiftsMutation$data;
  variables: ValidateMeetingShiftsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "MeetingNode",
  "kind": "LinkedField",
  "name": "causedByMeeting",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "causedByUser",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partStartsAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateMeetingShiftsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ValidateMeetingShiftsPayload",
        "kind": "LinkedField",
        "name": "validateMeetingShifts",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SuggestedMeetingShift",
                "kind": "LinkedField",
                "name": "causedByShift",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateMeetingShiftsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ValidateMeetingShiftsPayload",
        "kind": "LinkedField",
        "name": "validateMeetingShifts",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SuggestedMeetingShift",
                "kind": "LinkedField",
                "name": "causedByShift",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb29dfc4123e10d6443f232a5ed00d98",
    "id": null,
    "metadata": {},
    "name": "ValidateMeetingShiftsMutation",
    "operationKind": "mutation",
    "text": "mutation ValidateMeetingShiftsMutation(\n  $input: ValidateMeetingShiftsInput!\n) {\n  validateMeetingShifts(input: $input) {\n    ok\n    errors {\n      code\n      causedByMeeting {\n        id\n        name\n      }\n      causedByUser {\n        id\n        fullName\n      }\n      causedByShift {\n        name\n        id\n      }\n      partStartsAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf733a18967ed3c40957fe0a2356584a";

export default node;

import type { ComponentProps } from "react";
import { Suspense, useCallback, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { connectionToArray, getId } from "relay-help/arrays";

import { useAdminEditUser } from "../mutations";
import type { AdminEditUserInput as FormValues } from "../types";

import { UserForm } from "./common";
import type {
  AdminEditUserForm_fragment$data as Data,
  AdminEditUserForm_fragment$key as Key,
  AdminEditUserFormQuery as Query,
} from "./types";

export const fragment = graphql`
  fragment AdminEditUserForm_fragment on UserNode {
    id
    firstName
    lastName
    fullName
    email
    employmentDegree
    ruleGroups {
      edges {
        node {
          id
        }
      }
    }
    employmentForm
    employmentTitle
    timebankBaseBalance
    memberOf {
      edges {
        node {
          id
        }
      }
    }
    competences {
      edges {
        node {
          id
        }
      }
    }
    groupMemberOf {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const query = graphql`
  query AdminEditUserFormQuery($userId: ID!) {
    user(id: $userId) {
      ...AdminEditUserForm_fragment
    }
  }
`;

type CommonProps = Pick<ComponentProps<typeof UserForm>, "teamGroups"> & {
  open: boolean;
  onClose: () => void;
};
type Props = CommonProps & { userId: string };
type MaybeRenderFormProps = CommonProps & {
  queryRef: PreloadedQuery<Query>;
};
type ContentProps = CommonProps & { selected: Data };

function Content({ open, onClose, selected, teamGroups }: ContentProps) {
  const [commit] = useAdminEditUser();
  const title = `Redigera ${selected.fullName}`;
  const initialValues: FormValues = useMemo<FormValues>(
    () => ({
      id: selected.id,
      teamGroups: connectionToArray(selected.groupMemberOf).map(getId),
      teams: connectionToArray(selected.memberOf).map(getId),
      firstName: selected.firstName,
      lastName: selected.lastName,
      email: selected.email,
      employmentDegree: selected.employmentDegree,
      ruleGroups: connectionToArray(selected.ruleGroups).map(getId),
      employmentForm: selected.employmentForm.toLowerCase(),
      employmentTitle: selected.employmentTitle.toLowerCase(),
      competences: connectionToArray(selected.competences).map(getId),
      timebankBaseBalance: selected.timebankBaseBalance,
      invite: false,
    }),
    [selected],
  );

  const onSubmit = useCallback(
    async (input: FormValues) =>
      commit({ variables: { input } }).then(onClose).catch(console.error),
    [commit, onClose],
  );

  return (
    <UserForm<FormValues>
      open={open}
      onClose={onClose}
      title={title}
      teamGroups={teamGroups}
      initialValues={initialValues}
      onSubmit={onSubmit}
      name={selected?.fullName}
      isAdminForm
    />
  );
}

function MaybeRenderForm({ queryRef, ...contentProps }: MaybeRenderFormProps) {
  const { user: userFragment } = usePreloadedQuery<Query>(query, queryRef);
  const selected = useFragment<Key>(fragment, userFragment);

  if (!selected) {
    return <Typography>Vald användare ej funnen</Typography>;
  }
  return <Content {...contentProps} selected={selected} />;
}

export function AdminEditUserForm({
  userId,
  open,
  onClose,
  teamGroups,
}: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    if (!userId) return;
    loadQuery({ userId });
  }, [userId, loadQuery]);

  return (
    <Suspense fallback={null}>
      {!!queryRef && (
        <MaybeRenderForm
          queryRef={queryRef}
          open={open}
          onClose={onClose}
          teamGroups={teamGroups}
        />
      )}
    </Suspense>
  );
}

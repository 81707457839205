/**
 * @generated SignedSource<<009c45b2bc52621ab1439bb1a7291110>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditMeetingForm_setting$data = {
  readonly periodLengthWeeks: number;
  readonly " $fragmentSpreads": FragmentRefs<"MeetingForm_setting">;
  readonly " $fragmentType": "EditMeetingForm_setting";
};
export type EditMeetingForm_setting$key = {
  readonly " $data"?: EditMeetingForm_setting$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditMeetingForm_setting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditMeetingForm_setting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MeetingForm_setting"
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "72b1c3e2346fe26acf9aa691e0b70fc3";

export default node;

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderWeight,
  SettingBoxHeader,
} from "components/setting/common";

type HeaderProps = {
  moduleName: string;
  readOnly?: boolean;
  disabled?: boolean;
};

function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      type="onlySoft"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}
function Switch({
  moduleName,
  readOnly,
}: {
  moduleName: string;
  readOnly?: boolean;
}) {
  return <HeaderSwitch name={moduleName} readOnly={readOnly} />;
}
function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="wishedFreeDaysWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Header({ moduleName, readOnly, disabled }: HeaderProps) {
  return (
    <SettingBoxHeader
      radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
      switchComponent={<Switch moduleName={moduleName} readOnly={readOnly} />}
      weightComponent={<Weight readOnly={readOnly} disabled={disabled} />}
    />
  );
}

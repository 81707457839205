/**
 * @generated SignedSource<<e2af6ab10a01a5ae967b2951bf25cc26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftDayTypeContext_fragment$data = {
  readonly eveningStartsMorningEnds: string;
  readonly fullDayDuration: number;
  readonly morningStartsNightEnds: string;
  readonly nightStartsEveningEnds: string;
  readonly " $fragmentType": "ShiftDayTypeContext_fragment";
};
export type ShiftDayTypeContext_fragment$key = {
  readonly " $data"?: ShiftDayTypeContext_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftDayTypeContext_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftDayTypeContext_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "morningStartsNightEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningStartsMorningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightStartsEveningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayDuration",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "a1f3d6edd508ef26017cb6e306b1d9ff";

export default node;

import type { PropsWithChildren } from "react";

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FloatingAccordion";

import { CardActions } from "./CardActions";
import { CardHeader } from "./CardHeader";
import type { CardProps } from "./types";

export function CardWithIndices({
  expanded,
  onExpand,
  onAddRow,
  addLabel,
  children,
  ...props
}: PropsWithChildren<CardProps>) {
  return (
    <Accordion expanded={expanded} onChange={onExpand}>
      <AccordionSummary>
        <CardHeader {...props} />
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 2, pb: 0 }}>{children}</AccordionDetails>
      {!!onAddRow && (
        <AccordionActions sx={{ justifyContent: "flex-start", px: 2 }}>
          <CardActions addLabel={addLabel} onAddRow={onAddRow} />
        </AccordionActions>
      )}
    </Accordion>
  );
}

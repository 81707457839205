import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Weekday } from "rrule";

import { SelectAB } from "components/blocked_time_slot/common/Recurrence/common";
import type {
  MonthType,
  RecurrenceRuleType,
} from "components/blocked_time_slot/common/Recurrence/types";
import {
  days,
  months,
  translateMonth,
  translateWeekday,
} from "components/blocked_time_slot/common/Recurrence/types";
import { nthOptions } from "components/blocked_time_slot/common/Recurrence/utils/functions";

const weekdayOptions = days.map((day) => ({
  value: day,
  label: `${translateWeekday(day, true)}en`,
}));

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
  disabled?: boolean;
};

function EveryMonthSelect({ value, onChange, disabled }: Props) {
  const { bymonth } = value ?? {};
  function isSelected(month: MonthType) {
    return bymonth?.includes(month);
  }

  function toggleMonth(month: MonthType) {
    const newValue = { ...value };
    if (!newValue?.bymonth) {
      newValue.bymonth = [];
    }

    if (isSelected(month)) {
      newValue.bymonth = newValue.bymonth.filter((m) => m !== month);
    } else {
      newValue.bymonth = [...newValue.bymonth, month];
    }

    onChange(newValue);
  }

  return (
    <Grid container width={255} gap={0.5}>
      {months.map((month) => (
        <Grid key={month} xs={3}>
          <Button
            variant={isSelected(month) ? "primary" : "outlined"}
            onClick={() => toggleMonth(month)}
            disabled={disabled}
          >
            {translateMonth(month)}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

function FrequencyOption({ value, onChange, disabled }: Props) {
  const [enabled, setEnabled] = useState(false);
  const { bysetpos, byweekday } = value ?? {};
  const a = nthOptions.find((n) => n.value === bysetpos)?.value || "";
  const b = weekdayOptions.find((w) => w.value === byweekday?.[0])?.value || "";
  const aOptions = nthOptions.map((x) => x.value);
  const bOptions = weekdayOptions.map((x) => x.value);

  function onNthChange(newPos: number) {
    onChange({ ...value, bysetpos: newPos });
  }
  function onWeekdayChange(newWeekday: Weekday) {
    onChange({ ...value, byweekday: [newWeekday] });
  }

  return (
    <Stack>
      <FormControlLabel
        control={<Checkbox />}
        label="På den:"
        checked={enabled}
        onChange={() => setEnabled((prev) => !prev)}
        disabled={disabled}
      />
      <SelectAB
        a={a}
        b={b}
        renderA={(value) => nthOptions.find((x) => x.value === value)?.label}
        renderB={(value) =>
          weekdayOptions.find((x) => x.value === value)?.label
        }
        aOptions={aOptions}
        bOptions={bOptions}
        onAChange={onNthChange}
        onBChange={onWeekdayChange}
        disabled={disabled || !enabled}
        asx={{ width: 200 }}
        bsx={{ width: 200 }}
      />
    </Stack>
  );
}

export default function YearlyOptions({ value, onChange, disabled }: Props) {
  return (
    <Stack gap={1}>
      <EveryMonthSelect value={value} onChange={onChange} disabled={disabled} />
      <FrequencyOption value={value} onChange={onChange} disabled={disabled} />
    </Stack>
  );
}

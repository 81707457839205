import { useRef, useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { Dialog, Typography } from "@mui/material";
import { Formik } from "formik";
import { formatDateTime } from "helpers/dateFunctions";
import { toDatetimeLocal } from "libs/toDatetimeLocal";
import debounce from "lodash.debounce";

import { ValidationResultState } from "components/schedule/activities/validations/types";
import { validateCreate } from "components/schedule/activities/validations/ValidateCreateActivity";
import { useSnackbar } from "components/Snackbar";

import { CreateActivity } from "../mutations";
import type { ActivityType, CreateActivityInput as FormValues } from "../types";

import { ActivityForm, activityFormSchema } from "./common";

type Props = {
  scheduleId: string;
  open: boolean;
  onClose: VoidFunction;
  afterSubmit: VoidFunction;
};

/** CreateActivity
 */
export function CreateActivityForm({
  scheduleId,
  open,
  onClose,
  afterSubmit,
}: Props) {
  const navigate = useNavigate();
  const environment = useRelayEnvironment();
  const { addSnack } = useSnackbar();

  // Validation helpers
  const [validationState, setValidationState] = useState<ValidationResultState>(
    { loading: false, result: undefined },
  );
  const lastValidatedValues = useRef<Partial<FormValues> | undefined>(
    undefined,
  );

  const initialValues: FormValues = {
    scheduleId: scheduleId || "",
    start: toDatetimeLocal(formatDateTime(new Date())) || "",
    end: toDatetimeLocal(formatDateTime(new Date())) || "",
    breakTime: 0,
    userId: "",
    teamId: undefined,
    activityType: "S" as ActivityType,
    activityParts: [],
  };

  const debouncedValidateCreate = debounce(
    (values: FormValues) =>
      validateCreate({
        values,
        lastValidatedValues,
        setValidationState,
        validationState,
        scheduleId,
        environment,
      }),
    250,
  );

  async function onSubmit(input: FormValues) {
    await CreateActivity(environment, { input })
      .then((response) => {
        if (response?.createActivity?.ok) {
          afterSubmit();
          addSnack({ message: "Händelse skapad", severity: "success" });
          const clonedId = response?.createActivity?.clonedSchedule?.id;
          if (clonedId) {
            navigate(`/schedules/${clonedId}`);
          }
        } else {
          console.error("Could not create/update activity");
          addSnack({ message: "Kunde inte skapa händelse", severity: "error" });
        }
      })
      .catch(() => {
        addSnack({ message: "Kunde inte skapa händelse", severity: "error" });
      });
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Formik
        initialValues={initialValues}
        validationSchema={activityFormSchema}
        validate={debouncedValidateCreate}
        onSubmit={onSubmit}
      >
        <ActivityForm
          title={<Typography variant="h2">Skapa ny händelse</Typography>}
          submitText="Skapa händelse"
          onClose={onClose}
          validationState={validationState}
        />
      </Formik>
    </Dialog>
  );
}

import { useMemo } from "react";
import { InputAdornment, Typography } from "@mui/material";
import { minutes2HHMM } from "helpers/dateFunctions";
import { useFormikState } from "hooks";

import Field from "components/forms/CustomField";
import ExampleBox from "components/setting/common/ExampleBox";

import { Title as TitleTemplate } from "./Title";
import { round } from "./utils";

type HookProps = {
  periodLengthWeeks: number;
  hoursPerWeek: number;
  totalHoursMargin: number;
};

function useTotalMarginHours({
  periodLengthWeeks,
  hoursPerWeek,
  totalHoursMargin,
}: HookProps) {
  const hoursPerPeriod: number = useMemo(
    () => round(hoursPerWeek * periodLengthWeeks),
    [hoursPerWeek, periodLengthWeeks],
  );

  const marginHours = useMemo(
    () => minutes2HHMM(60 * totalHoursMargin * hoursPerPeriod * 0.01),
    [totalHoursMargin, hoursPerPeriod],
  );
  const marginHoursMin = useMemo(
    () => minutes2HHMM(60 * 0.01 * (100 - totalHoursMargin) * hoursPerPeriod),
    [totalHoursMargin, hoursPerPeriod],
  );
  const marginHoursMax = useMemo(
    () => minutes2HHMM(60 * 0.01 * (100 + totalHoursMargin) * hoursPerPeriod),
    [totalHoursMargin, hoursPerPeriod],
  );

  return useMemo(
    () => ({
      hoursPerPeriod,
      marginHours,
      marginHoursMin,
      marginHoursMax,
    }),
    [hoursPerPeriod, marginHours, marginHoursMin, marginHoursMax],
  );
}

export const Title = () => (
  <TitleTemplate>Marginal för timmar (%)</TitleTemplate>
);

export const Description = () => (
  <Typography>
    Detta fält styr vilken marginal optimeringsalgoritmen tillåts röra sig inom
    för antalet schemalagda timmar.
  </Typography>
);

export const TotalHoursMarginField = ({
  disabled,
  readOnly,
}: {
  disabled?: boolean;
  readOnly?: boolean;
}) => (
  <Field
    name="totalHoursMargin"
    fullWidth
    size="small"
    type="number"
    InputProps={{
      readOnly,
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
    disabled={disabled}
    inputProps={{ step: 0.5 }}
  />
);

export const Example = ({
  periodLengthWeeks,
  hoursPerWeek,
}: {
  periodLengthWeeks: number;
  hoursPerWeek: number;
}) => {
  const totalHoursMargin = useFormikState<number>("totalHoursMargin")?.value;
  const { hoursPerPeriod, marginHours, marginHoursMin, marginHoursMax } =
    useTotalMarginHours({ periodLengthWeeks, hoursPerWeek, totalHoursMargin });

  return (
    <ExampleBox>
      <Typography variant="caption">
        För en heltidare som arbetar {minutes2HHMM(60 * hoursPerPeriod)} timmar
        per schemaperiod om {periodLengthWeeks} veckor kommer algoritmen att
        schemalägga mellan ±{totalHoursMargin}% (±
        {marginHours} timmar), d.v.s. som minst {marginHoursMin} och som mest{" "}
        {marginHoursMax}.
      </Typography>
    </ExampleBox>
  );
};

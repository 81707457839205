/**
 * @generated SignedSource<<1663c93898526245be2ed0dac8a762e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingMinDailyRestWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RestBetweenShiftsSettingTeamGroup_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly minDailyRestHours: number;
  readonly minDailyRestSoft: boolean;
  readonly minDailyRestWeight: ApiSettingMinDailyRestWeightChoices;
  readonly " $fragmentType": "RestBetweenShiftsSettingTeamGroup_fragment";
};
export type RestBetweenShiftsSettingTeamGroup_fragment$key = {
  readonly " $data"?: RestBetweenShiftsSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RestBetweenShiftsSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RestBetweenShiftsSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "8a9ff1bf25b86aee4c1e6001bb62aefe";

export default node;

import type { ComponentProps } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  Stack,
} from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { Preset, PresetKey, Presets } from "types/MaterialReactTable";
import { getTablePresets, removeTablePreset } from "utils/localStorage";

import type { Column } from "../Form";
import { CreatePresetForm, EditPresetForm } from "../Form";

type Props = ComponentProps<typeof MuiSelect> & {
  tableKey: string;
  predefinedPresets?: Presets;
  reservedPresetKeys?: string[];
  columns: Column[];
  selectedColumns?: string[];
  setSelectedColumns?: (columns: string[]) => void;
  overridePreset?: string;
};

export const NO_PRESET_KEY = "show-all";
export const NO_PRESET_SELECTED: Preset = {
  key: NO_PRESET_KEY,
  name: "Alla kolumner",
  columns: [],
};

const noop = () => {};

export function Select({
  tableKey,
  columns,
  predefinedPresets = [],
  reservedPresetKeys = [],
  selectedColumns = [],
  overridePreset,
  setSelectedColumns = noop,
  ...props
}: Props) {
  /*
   * Preset is a set of columns in materialReactTable.
   * Presets are stored in localStorage.
   * Presets can be removed.
   * Presets can be overridden by passing overridePreset.
   */
  const [presets, setLocalPresets] = useState<Presets>(predefinedPresets);
  const [preset, setPreset] = useState<string>(NO_PRESET_KEY);
  const [showCreatePreset, setShowCreatePreset] = useState(false);
  const [editPreset, setEditPreset] = useState<Preset | null>(null);

  const reservedKeys = useMemo(() => presets.map((x) => x.key), [presets]);
  const createInitialValues = useMemo(
    () => ({ columns: selectedColumns }),
    [selectedColumns],
  );

  const setPresets = useCallback(
    (newPresets: Presets) =>
      setLocalPresets([...predefinedPresets, ...(newPresets || [])]),
    [predefinedPresets],
  );

  function changePreset(key: string) {
    setPreset(key);
    const foundPreset = presets.find((p) => p.key === key);
    if (foundPreset) {
      setSelectedColumns(foundPreset.columns);
    }
  }

  function handleChange(
    e: SelectChangeEvent<PresetKey>,
    _child: React.ReactNode,
  ) {
    changePreset(e.target.value);
  }

  function openCreate() {
    setShowCreatePreset(true);
  }

  function closeCreate() {
    setShowCreatePreset(false);
    setPresets(getTablePresets(tableKey) || []);
  }

  function closeEdit() {
    setEditPreset(null);
    setPresets(getTablePresets(tableKey) || []);
  }

  function onEdit(preset: Preset) {
    setEditPreset(preset);
  }

  function onRemove(key: PresetKey) {
    const p: Presets = getTablePresets(tableKey);
    const toRemove = p?.find((preset) => preset.key === key);
    if (toRemove) {
      removeTablePreset(tableKey, key);
      setPresets(p.filter((preset) => preset.key !== key));

      if (preset === key) {
        changePreset(NO_PRESET_KEY);
      }
    }
  }

  function renderValue(value: string) {
    return presets.find((p) => p.key === value)?.name || "";
  }

  useEffect(() => {
    const p = getTablePresets(tableKey);
    if (p) {
      setPresets(p);
    }
  }, [setPresets, tableKey]);

  useEffect(() => {
    if (overridePreset) {
      setPreset(overridePreset);
    }
  }, [overridePreset]);

  const label = "Kolumngrupp";
  const labelId = "preset-select-label";
  return (
    <>
      <Stack direction="row" gap={1}>
        <FormControl fullWidth sx={{ m: "5px 0px" }} size="small">
          <InputLabel id={labelId}>{label}</InputLabel>
          <MuiSelect
            {...props}
            value={preset}
            onChange={handleChange}
            displayEmpty
            defaultValue={NO_PRESET_KEY}
            size="small"
            label={label}
            labelId={labelId}
            renderValue={renderValue}
            inputProps={{ id: "preset-select" }}
          >
            {presets.map((p) => (
              <MenuItem key={p.key} value={p.key}>
                <ListItemText primary={p.name} />
                {!reservedPresetKeys.includes(p.key) && (
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onRemove(p.key)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={() => onEdit(p)}>
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        <IconButton onClick={openCreate}>
          <AddIcon />
        </IconButton>
      </Stack>

      <CreatePresetForm
        tableKey={tableKey}
        open={showCreatePreset}
        onClose={closeCreate}
        columns={columns}
        reservedKeys={reservedKeys}
        initialValues={createInitialValues}
      />

      {!!editPreset && (
        <EditPresetForm
          tableKey={tableKey}
          open={!!editPreset}
          onClose={closeEdit}
          preset={editPreset}
          columns={columns}
          reservedKeys={reservedKeys.filter((k) => k !== editPreset.key)}
        />
      )}
    </>
  );
}

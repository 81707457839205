import { useCallback, useMemo } from "react";
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { ErrorMessage } from "formik";
import useFormikState from "hooks/useFormikState";

import type { Column } from "./types";

type Props = {
  name: string;
  columns: Column[];
};

type ColumnSwitchProps = {
  column: Column;
  isChecked: (column: Column) => boolean;
  set: (column: Column, value: boolean) => void;
};

type ColumnsListProps = {
  columns: Column[];
  isChecked: (column: Column) => boolean;
  set: (column: Column, value: boolean) => void;
};

function ColumnSwitch({ column, isChecked, set }: ColumnSwitchProps) {
  const checked = useMemo<boolean>(
    () => isChecked(column),
    [isChecked, column],
  );
  const anyChildChecked = useMemo<boolean>(
    () => (column.columns || []).some(isChecked),
    [isChecked, column],
  );

  function onChange() {
    set(column, !(checked || anyChildChecked));
  }

  return (
    <>
      <FormControlLabel
        control={<Switch />}
        checked={checked || anyChildChecked}
        onChange={onChange}
        label={column.label}
        sx={{ width: 200 }}
      />
      {column.columns && (
        <Stack direction="row" flexWrap="wrap" sx={{ ml: 6 }} gap={0}>
          <ColumnsList
            columns={column.columns}
            isChecked={isChecked}
            set={set}
          />
        </Stack>
      )}
    </>
  );
}

function ColumnsList({ columns, isChecked, set }: ColumnsListProps) {
  return (
    <>
      {columns.map((column) => (
        <ColumnSwitch
          key={column.key}
          column={column}
          isChecked={isChecked}
          set={set}
        />
      ))}
    </>
  );
}

export function ColumnsField({ name, columns }: Props) {
  const { value, setValue } = useFormikState<string[]>(name);

  const isChecked = useCallback((c: Column) => value.includes(c.key), [value]);

  function set(column: Column, b: boolean) {
    let v = [...value];
    const add = (l: string[], c: Column): string[] => {
      if (c.columns) {
        return [...l, ...(c.columns.flatMap((x) => add([], x)) || [])];
      } else {
        return [...l, ...(l.includes(c.key) ? [] : [c.key])];
      }
    };
    const drop = (l: string[], c: Column): string[] => {
      if (c.columns) {
        let l2 = [...l];
        c.columns.forEach((x) => {
          l2 = drop(l2, x);
        });
        return l2;
      } else {
        return l.filter((x) => x !== c.key);
      }
    };

    if (b) {
      v = add(v, column);
    } else {
      v = drop(v, column);
    }

    setValue(v);
  }

  function allOn() {
    const flatten = (columns: Column[]): Column[] =>
      columns.flatMap(({ columns, ...x }) =>
        columns ? [...flatten(columns)] : [x],
      );
    setValue(flatten(columns).map((x) => x.key));
  }

  function allOff() {
    setValue([]);
  }

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" color="text.secondary">
          Visa följande kolumner
        </Typography>
        <Stack direction="row" gap={0.5}>
          <Button onClick={allOn}>Alla på</Button>
          <Button onClick={allOff}>Alla av</Button>
        </Stack>
      </Stack>
      <Stack direction="row" flexWrap="wrap">
        <ColumnsList columns={columns} isChecked={isChecked} set={set} />
      </Stack>
      <ErrorMessage name={name} />
    </Stack>
  );
}

import { Add as AddIcon } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";

type Props = {
  onAddRule: () => void;
  onAddDate: () => void;
};
export default function AddFooter({ onAddRule, onAddDate }: Props) {
  return (
    <Stack direction="row" gap={2}>
      <Button startIcon={<AddIcon />} onClick={onAddRule}>
        Lägg till upprepningsregel
      </Button>
      <Button startIcon={<AddIcon />} onClick={onAddDate}>
        Lägg till datum
      </Button>
    </Stack>
  );
}

import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { Box } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { DateTime } from "luxon";

import { useDemandGraphSpanContext } from "components/demand/DemandGraphSpan";
import { ALL_COMPETENCES, ALL_TEAMS } from "components/demand/form/utils";

import type { DemandProfileChart_fragment$key as FragmentKey } from "./__generated__/DemandProfileChart_fragment.graphql";
import type { DemandProfileChartQuery as Query } from "./__generated__/DemandProfileChartQuery.graphql";
import { DemandProfileRenderer } from "./DemandProfileRenderer";
import { useDemandProfileData } from "./hooks";

const fragment = graphql`
  fragment DemandProfileChart_fragment on DemandProfileViewer {
    viewer {
      team {
        id
        name
        color
      }
      competence {
        id
        name
        color
      }
      data {
        dt
        demand
        dutyShare
      }
    }
  }
`;

export const demandProfileChartQuery = graphql`
  query DemandProfileChartQuery($teamGroupId: ID!, $start: Date!, $end: Date!) {
    demandProfile(teamGroupId: $teamGroupId, startDate: $start, endDate: $end) {
      ...DemandProfileChart_fragment
    }
  }
`;

type CommonProps = {
  teamId: string;
  competenceId: string;
};

type Props = CommonProps & {
  teamGroupId: string;
  weekCount: number;
  uniqueWeeks: boolean;
};

type ContentProps = CommonProps & {
  queryRef: PreloadedQuery<Query>;
};

function Content({ queryRef, teamId, competenceId }: ContentProps) {
  const data = usePreloadedQuery<Query>(demandProfileChartQuery, queryRef);
  const { viewer = [] } = useFragment<FragmentKey>(
    fragment,
    data.demandProfile,
  );
  const { windowStart, windowEnd } = useDemandGraphSpanContext();
  const allTeams = useMemo<boolean>(() => teamId === ALL_TEAMS, [teamId]);
  const allCompetences = useMemo<boolean>(
    () => competenceId === ALL_COMPETENCES,
    [competenceId],
  );
  const { filteredViews, items } = useDemandProfileData({
    viewer,
    teamId,
    competenceId,
    windowStart,
    windowEnd,
    allTeams,
    allCompetences,
  });

  return (
    <DemandProfileRenderer
      filteredViews={filteredViews}
      items={items}
      allTeams={allTeams}
      allCompetences={allCompetences}
      min={windowStart}
      max={windowEnd}
    />
  );
}

export function DemandProfileChart({
  teamGroupId,
  teamId,
  competenceId,
  weekCount,
  uniqueWeeks,
}: Props) {
  const { start: startDate, end: endDate } = useDemandGraphSpanContext();
  const [queryRef, loadQuery] = useQueryLoader<Query>(demandProfileChartQuery);
  const start = useMemo(
    () =>
      startDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : null,
    [startDate],
  );
  const end = useMemo(
    () =>
      endDate ? DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd") : null,
    [endDate],
  );

  useEffect(() => {
    if (!(start && end && teamGroupId)) return;
    loadQuery({ start, end, teamGroupId });
  }, [start, end, teamGroupId, loadQuery, weekCount, uniqueWeeks]);

  return (
    <Suspense fallback={<Box sx={{ height: 220, p: 2 }} />}>
      {!!queryRef && (
        <Content
          queryRef={queryRef}
          teamId={teamId}
          competenceId={competenceId}
        />
      )}
    </Suspense>
  );
}

import { connectionToArray, getId } from "relay-help/arrays";
import { padOrTrunc } from "utils/list";

import { shiftTimelineTypeEnum } from "components/shifts/graphs/ShiftTimeline/types";

import type {
  CreateFormValues,
  CreateMeetingInput,
  EditFormValues,
  EditMeetingForm_meeting$data as EditData,
  EditMeetingInput,
  FormShift,
  MeetingShiftInput,
  StartEndDate,
  SuggestedMeetingShift,
} from "../types";
import { meetingSelectEnum } from "../types";
import { hhmmss2hhmm, sortStartFn } from "../utils";

export function initFormValues(
  teamGroupId: string,
  periodLengthWeeks: number,
): CreateFormValues {
  return {
    teamGroupId,
    name: "",
    start: "",
    end: "",
    users: [],
    selectDaysBy: meetingSelectEnum.STATIC,
    nrMeetings: 0,
    dayMatrix: Array.from({ length: periodLengthWeeks }, () => []),
    shifts: [],
    errors: [],
  };
}

export function meetingToFormValues(
  teamGroupId: string,
  data: EditData,
  periodLengthWeeks: number,
): EditFormValues {
  return {
    teamGroupId,
    id: data.id,
    name: data.name,
    start: hhmmss2hhmm(data.start),
    end: hhmmss2hhmm(data.end),
    selectDaysBy: data.selectDaysBy,
    users: connectionToArray(data.users).map(getId),
    nrMeetings: data.nrMeetings,
    dayMatrix: padOrTrunc(data.dayMatrix, periodLengthWeeks, []),
    shifts: shiftConnectionsToArray(data.shifts).sort(sortStartFn),
    errors: [],
  };
}

export const initialMeetingShift: FormShift = {
  id: null,
  start: "",
  end: "",
  meetingDay: 0,
  breakTime: 0,
  name: "",
  meetings: [],
  shiftParts: [],
};

/** Transform EditData["shifts"] to form field "shifts". */
export function shiftConnectionsToArray(
  shifts: EditData["shifts"],
): FormShift[] {
  return connectionToArray(shifts)
    .map(({ meetings, shiftParts, ...s }) => ({
      ...s,
      shiftParts: connectionToArray(shiftParts),
      otherMeetings: connectionToArray(meetings),
    }))
    .map(suggestedMeetingShiftToFormShift);
}

export function suggestedMeetingShiftToFormShift(
  s: SuggestedMeetingShift,
): FormShift {
  return {
    id: s.id,
    meetingDay: s.meetingDay,
    name: s.name,
    start: hhmmss2hhmm(s.start),
    end: hhmmss2hhmm(s.end),
    breakTime: s.breakTime,
    shiftParts: s.shiftParts.map((sp) => ({
      id: sp.id,
      start: hhmmss2hhmm(sp.start),
      end: hhmmss2hhmm(sp.end),
      partType: sp.partType.toUpperCase(),
    })),
    meetings: (s.otherMeetings || []).map((m) => ({
      id: m.id,
      start: hhmmss2hhmm(m.start),
      end: hhmmss2hhmm(m.end),
    })),
  };
}

/** convert edit form data to mutation input. */
export function editValuesToInput({
  shifts: shiftsIn,
  errors,
  ...otherFields
}: EditFormValues): EditMeetingInput {
  return {
    shifts: shiftsIn.map(formShiftToInput),
    ...otherFields,
  };
}

/** convert create form data to mutation input. */
export function createValuesToInput({
  shifts: shiftsIn,
  errors,
  ...otherFields
}: CreateFormValues): CreateMeetingInput {
  return {
    shifts: shiftsIn.map(formShiftToInput),
    ...otherFields,
  };
}

function formShiftToInput({ meetings, ...s }: FormShift): MeetingShiftInput {
  return { ...s, otherMeetings: meetings.map((m) => m.id) };
}

/** convert query data to form data */
export function meetingShiftToFormShift(
  suggestedShift: SuggestedMeetingShift,
): FormShift {
  return {
    id: suggestedShift.id || null,
    meetingDay: suggestedShift.meetingDay,
    name: suggestedShift.name,
    start: hhmmss2hhmm(suggestedShift.start),
    end: hhmmss2hhmm(suggestedShift.end),
    breakTime: suggestedShift.breakTime,
    shiftParts: suggestedShift.shiftParts.map((sp) => ({
      id: sp.id,
      start: hhmmss2hhmm(sp.start),
      end: hhmmss2hhmm(sp.end),
      partType: sp.partType.toUpperCase(),
    })),
    meetings: suggestedShift.otherMeetings || [],
  };
}

interface StartEndString {
  start: string;
  end: string;
}

/** Add `start` `end` to `shift.shiftParts`.
 * Does NOT sort shiftsParts array. */
export function injectMeetingShiftPart<
  S extends { shiftParts: ReadonlyArray<StartEndString> },
>(shift: S, { start, end }: StartEndString): S {
  return {
    ...shift,
    shiftParts: [
      ...shift.shiftParts,
      { start, end, partType: shiftTimelineTypeEnum.P },
    ],
  };
}

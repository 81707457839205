import type { CSSObject } from "@mui/material";
import { Box } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { SettingBoxHeaderRadios } from "components/common/RadioButtons";

type RadioDisplayType = "none" | "onlySoft" | "onlyHard" | "both";

type EitherOr =
  | { name: string; value?: undefined }
  | { value: boolean; name?: undefined };

type Props = EitherOr & {
  type?: RadioDisplayType;
  disabled?: boolean;
  readOnly?: boolean;
  sx?: CSSObject;
};

const noop = () => {};

export function HeaderRadios({
  name = "",
  value,
  disabled,
  readOnly,
  type = "both",
  sx = {},
}: Props) {
  const { value: formValue, setValue } = useFormikState<boolean>(name);
  const toggle = () => setValue(!formValue);
  return (
    <Box sx={{ flexGrow: 2, justifyContent: "flex-end", ...sx }}>
      <SettingBoxHeaderRadios
        nameSoftRadio={type}
        valueSoftRadio={value || formValue}
        handleSoftRadio={value !== undefined ? noop : toggle}
        disabled={disabled}
        readOnly={readOnly}
      />
    </Box>
  );
}

import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

import type { CardProps } from "./types";

type Props = Pick<CardProps, "addLabel" | "onAddRow">;

export function CardActions({ addLabel, onAddRow }: Props) {
  return (
    <Button onClick={onAddRow} startIcon={<AddIcon />}>
      {addLabel}
    </Button>
  );
}

import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import type { EditActivityMutation } from "./types";

type Variables = EditActivityMutation["variables"];

const mutation = graphql`
  mutation EditActivityMutation($input: EditActivityInput!) {
    editActivity(input: $input) {
      ok
      activity {
        id
        start
        end
        breakTime
        activityType
        activityParts {
          edges {
            node {
              id
              start
              end
              partType
            }
          }
        }
      }
      clonedSchedule {
        id
      }
    }
  }
`;

export async function EditActivity(
  environment: IEnvironment,
  variables: Variables,
) {
  return commitMutation<EditActivityMutation>(environment, {
    mutation,
    variables,
  });
}

import { useMemo } from "react";
import { Box, Checkbox, Chip, Stack } from "@mui/material";

import type { Option } from "components/forms/CustomSelectField";
import CustomSelectField from "components/forms/CustomSelectField";
import { ShiftType } from "components/setting/types";

type Props = {
  name: string;
  options: ReadonlyArray<ShiftType>;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
};

const formatDt = (dt: string) => dt.substring(0, 5);
const getLabel = (shift: ShiftType) => {
  const nbParts = shift?.shiftParts?.length;
  const partsString =
    nbParts && nbParts > 0
      ? ` | ${shift?.shiftParts?.length ?? 0} passdel${
          nbParts === 1 ? "" : "ar"
        }`
      : "";
  return (
    `${shift.name} | ${formatDt(shift.start)}-${formatDt(shift.end)}` +
    partsString
  );
};

const renderValue = (options: ReadonlyArray<ShiftType>) => (value: unknown) => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      rowGap: 1.1,
      columnGap: 0.9,
      ml: -0.7,
      mr: 0.5,
    }}
  >
    {options.filter((x) => (value as string[]).includes(x.id)).map(renderShift)}
  </Box>
);

const renderShift = (shift: ShiftType) => (
  <Chip
    key={shift.id}
    size="small"
    label={getLabel(shift)}
    sx={{ my: -0.15 }}
  />
);

const renderOption = (shift: Option, selected: boolean) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ ml: -1 }}>
      <Checkbox checked={selected} size="small" />
      {shift.label}
    </Stack>
  );
};

export function ShiftSelect({
  name,
  options: optionsProp,
  disabled,
  readOnly,
  placeholder = "Alla passtyper är tillåtna",
}: Props) {
  const options = useMemo<Option[]>(
    () => optionsProp.map((o) => ({ value: o.id, label: getLabel(o) })),
    [optionsProp],
  );

  return (
    <CustomSelectField<string[]>
      name={name}
      options={options}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      multiple
      size="small"
      renderValue={renderValue(optionsProp)}
      renderOption={renderOption}
    />
  );
}

import { useCallback, useMemo } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  calculateTimeDuration,
  derivePartWorktime,
} from "business_logic/Shifts";
import { FieldArray } from "formik";
import { minutes2HHMM } from "helpers/dateFunctions";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import { ListItemWithIcon } from "components/common/ListItemWithIcon";
import { RowMenu } from "components/common/RowMenu";
import CustomField from "components/forms/CustomField";
import { LIcon } from "components/icons";

import type { ShiftPartInput } from "../types";

import { RowTemplate } from "./RowTemplate";
import { FormikShiftPartSelect } from "./ShiftPartSelect";

type FieldArrayProps = FormikFieldArrayFn<ShiftPartInput>;

type RowProps = {
  name: string;
  onRemove: VoidFunction;
  disabled?: boolean;
};

type Props = {
  name: string;
  disabled?: boolean;
};

function RenderErrors({ error }: { error?: string | string[] }) {
  const errorStr = useMemo(() => {
    if (Array.isArray(error)) {
      return error
        .flatMap((x) => (typeof x === "string" ? x : Object.values(x)))
        .join(", ");
    }
    if (typeof error === "string") {
      return error;
    }
    if (typeof error === "object") {
      return Object.values(error).join(", ");
    }
    return "";
  }, [error]);

  return (
    <Typography color="error" variant="caption">
      {errorStr}
    </Typography>
  );
}

function ShiftPartRow({ name, onRemove, disabled }: RowProps) {
  const {
    value,
    meta: { error },
  } = useFormikState(name);
  const { start, end } = value || {};

  const duration = useMemo<string>(
    () => minutes2HHMM(calculateTimeDuration(start, end)),
    [start, end],
  );
  const workTime = useMemo<string>(
    () => minutes2HHMM(derivePartWorktime(value)),
    [value],
  );

  const getName = useCallback((k: string) => `${name}.${k}`, [name]);

  return (
    <>
      {!!error && (
        <TableRow sx={{ "& > td": { borderBottom: "none" } }}>
          <TableCell colSpan={9} sx={{ py: 0, pl: 2, pr: 0, m: 0 }}>
            <RenderErrors error={error} />
          </TableCell>
        </TableRow>
      )}
      <RowTemplate
        toggle={undefined}
        name={
          <Stack direction="row">
            <LIcon />
            <FormikShiftPartSelect
              name={getName("partType")}
              fullWidth
              disabled={disabled}
            />
          </Stack>
        }
        start={
          <CustomField
            type="time"
            name={getName("start")}
            disabled={disabled}
          />
        }
        end={
          <CustomField type="time" name={getName("end")} disabled={disabled} />
        }
        breakTime={undefined}
        duration={<Typography>{duration}</Typography>}
        workTime={<Typography>{workTime}</Typography>}
        dayType={undefined}
        actions={
          <RowMenu disabled={disabled}>
            <ListItemWithIcon
              component={MenuItem}
              onClick={onRemove}
              icon={<DeleteIcon />}
              label="Radera"
              disabled={disabled}
            />
          </RowMenu>
        }
      />
    </>
  );
}

export default function ShiftPartTableRows({ name, disabled }: Props) {
  const { value: parts } = useFormikState<any[]>(name);

  return (
    <FieldArray name={name}>
      {({ remove }: FieldArrayProps) =>
        (parts || []).map((_: unknown, i: number) => (
          <ShiftPartRow
            key={`passdel-${i}`}
            name={`${name}.${i}`}
            onRemove={() => remove(i)}
            disabled={disabled}
          />
        ))
      }
    </FieldArray>
  );
}

import { Divider, Stack } from "@mui/material";

import { UniqueWeeksField } from "./UniqueWeeksField";
import { UseDemandWeightsField } from "./UseDemandWeightsField";
import { UseDutyDemandField } from "./UseDutyDemandField";

type Props = { readOnly?: boolean };

export function DemandProfileForm({ readOnly }: Props) {
  return (
    <Stack gap={2}>
      <UniqueWeeksField readOnly={readOnly} />
      <Divider />
      <UseDutyDemandField readOnly={readOnly} />
      <Divider />
      <UseDemandWeightsField readOnly={readOnly} />
    </Stack>
  );
}

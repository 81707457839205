import { InfoBox } from "components/common/WarningBox";

import type { GroupSettingCardProps } from "./GroupSettingCard";
import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<GroupSettingCardProps, "title" | "action">;

const TITLE = "Kompetenser";

const Box = () => (
  <InfoBox>
    Kontakta din administratör för att lägga till fler kompetenser
  </InfoBox>
);

export function CompetencesCard(props: Props) {
  return <GroupSettingCard title={TITLE} action={<Box />} {...props} />;
}

CompetencesCard.id = "competences" as const;
CompetencesCard.title = TITLE;

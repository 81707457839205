/**
 * @generated SignedSource<<6affc982f387ed76a309b4f0e9f26abf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditBlockedTimeSlotQuery$variables = {
  id: string;
};
export type EditBlockedTimeSlotQuery$data = {
  readonly blockedTimeSlot: {
    readonly " $fragmentSpreads": FragmentRefs<"EditBlockedTimeSlot_fragment">;
  } | null;
};
export type EditBlockedTimeSlotQuery = {
  response: EditBlockedTimeSlotQuery$data;
  variables: EditBlockedTimeSlotQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditBlockedTimeSlotQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BlockedTimeSlotNode",
        "kind": "LinkedField",
        "name": "blockedTimeSlot",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditBlockedTimeSlot_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditBlockedTimeSlotQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BlockedTimeSlotNode",
        "kind": "LinkedField",
        "name": "blockedTimeSlot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeslotType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approvedByAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recurrences",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ShiftNode",
            "kind": "LinkedField",
            "name": "shift",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNodeConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95545768fc36b4ce4a8135a23bc03141",
    "id": null,
    "metadata": {},
    "name": "EditBlockedTimeSlotQuery",
    "operationKind": "query",
    "text": "query EditBlockedTimeSlotQuery(\n  $id: ID!\n) {\n  blockedTimeSlot(id: $id) {\n    ...EditBlockedTimeSlot_fragment\n    id\n  }\n}\n\nfragment EditBlockedTimeSlot_fragment on BlockedTimeSlotNode {\n  id\n  start\n  end\n  timeslotType\n  approvedByAdmin\n  recurrences\n  shift {\n    id\n  }\n  shiftDate\n  users {\n    edges {\n      node {\n        id\n        fullName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb73d6bee013201a7d19071fd2cf7faa";

export default node;

import { Suspense, useEffect, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import AddIcon from "@mui/icons-material/Add";
import { Button, Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useAdminParams } from "hooks";

import {
  CreateCompetenceForm,
  EditCompetenceForm,
  useActivateCompetence,
  useArchiveCompetence,
  useDeleteCompetence,
} from "components/admin";
import Table from "components/admin/tables/CompetencesTable";
import { Competence } from "components/admin/types";
import DeleteModal from "components/common/DeleteModal";
import {
  ErrorBoundaryWithRetry,
  RenderError,
} from "components/layout/ErrorBoundary";
import { AdminCompetencesPlaceholder as Placeholder } from "components/loading/pages";

import { AdminCompetencesQuery as Query } from "./types";

const query = graphql`
  query AdminCompetencesQuery {
    allCompetences {
      ...CompetencesTable_fragment
    }
  }
`;

type RendererProps = {
  queryRef: PreloadedQuery<Query>;
  onDelete: (competence: Competence) => void;
  onEdit: (id: string) => void;
  onActivate: (id: string) => void;
  onArchive: (id: string) => void;
};

function Actions({ onNew }: { onNew: () => void }) {
  return (
    <Button
      variant="primary"
      endIcon={<AddIcon />}
      onClick={onNew}
      sx={{ alignSelf: "flex-end" }}
    >
      Ny kompetens
    </Button>
  );
}

function Renderer({
  queryRef,
  onEdit,
  onDelete,
  onActivate,
  onArchive,
}: RendererProps) {
  const { allCompetences } = usePreloadedQuery<Query>(query, queryRef);

  return (
    <Table
      fragmentRef={allCompetences}
      onEdit={onEdit}
      onDelete={onDelete}
      onActivate={onActivate}
      onArchive={onArchive}
    />
  );
}

export function AdminCompetences() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const [selected, setSelected] = useState<string | null>(null);
  const { newCompetence: newOpen, setNewCompetence: setNewOpen } =
    useAdminParams();
  const [toDelete, setToDelete] = useState<Competence | null>(null);
  const [activateCompetence] = useActivateCompetence();
  const [archiveCompetence] = useArchiveCompetence();
  const [deleteCompetence] = useDeleteCompetence();

  useEffect(() => {
    loadQuery({});
  }, [loadQuery]);

  function closeNew() {
    setNewOpen(false);
  }

  function closeEdit() {
    setSelected(null);
  }
  function closeDelete() {
    setToDelete(null);
  }

  function onDelete(id?: string) {
    if (!id) return;
    deleteCompetence({ variables: { id } }).then(closeDelete);
  }

  function onActivate(id: string) {
    activateCompetence({ variables: { id } });
  }

  function onArchive(id: string) {
    archiveCompetence({ variables: { id } });
  }

  return (
    <>
      <Paper variant="box" sx={{ p: 1.5 }}>
        <Stack gap={2}>
          <Actions onNew={() => setNewOpen(true)} />
          <ErrorBoundaryWithRetry
            onRetry={() => loadQuery({})}
            fallback={({ error, retry }) => (
              <>
                <RenderError error={error} />
                <div>
                  <Button variant="outlined" onClick={retry}>
                    Försök igen
                  </Button>
                </div>
              </>
            )}
          >
            <Suspense fallback={<Placeholder />}>
              {!!queryRef && (
                <Renderer
                  queryRef={queryRef}
                  onEdit={setSelected}
                  onDelete={setToDelete}
                  onActivate={onActivate}
                  onArchive={onArchive}
                />
              )}
            </Suspense>
          </ErrorBoundaryWithRetry>
        </Stack>
      </Paper>
      <CreateCompetenceForm open={newOpen} onClose={closeNew} />
      <Suspense fallback={null}>
        <EditCompetenceForm
          id={selected}
          open={!!selected}
          onClose={closeEdit}
        />
      </Suspense>
      <DeleteModal
        show={!!toDelete}
        onHide={closeDelete}
        onDeleteClick={() => onDelete(toDelete?.id)}
        deleteTitle="Radera kompetens"
        deleteMessage={`Är du säker på att du vill radera kompetensen '${toDelete?.name}'?`}
        buttonText="Radera"
      />
    </>
  );
}

import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import GenericSelect from "components/common/selectors/GenericSelect";
import { nightRotationOptions } from "components/types/NightRotationType";

type Props = {
  readOnly?: boolean;
  disabled?: boolean;
};

function NightRotationSelect({
  disabled,
  readOnly,
}: {
  disabled?: boolean;
  readOnly?: boolean;
}) {
  const { value, setValue } = useFormikState<string>("nightShiftRotation");
  const selectedOption = nightRotationOptions?.find((o) => o.value === value);
  return (
    <GenericSelect
      options={nightRotationOptions}
      value={selectedOption}
      onChange={(_e: any, v: any) => setValue(v?.value)}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export function NightRotationField({ disabled, readOnly }: Props) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Begränsning för nattpassrotationer</Typography>
      <Typography>
        Detta fält styr hur många gånger schemat får rotera från nattpass till
        icke-nattpass (eller vice versa). Nattpass definieras enligt Allmänna
        Bestämmelser.
      </Typography>
      <NightRotationSelect disabled={disabled} readOnly={readOnly} />
    </Stack>
  );
}

import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import AllowedErrorMargin from "./AllowedErrorMargin";
import ShareInput from "./ShareInput";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
  totalShifts?: number;
  who: React.ReactNode;
};

export function Types5A({ disabled, readOnly, totalShifts = 19, who }: Props) {
  // Styling
  const labelSx = { width: 65 };
  const fieldSx = { width: 110 };
  const columnGap = 5;

  // Constraint module selector
  const constraintModuleName = "ShiftDayTypeDistribution";
  const { value: constraintModules } = useFormikState("constraintModules");
  const { value: isSoft } = useFormikState("shiftDayTypeDistributionSoft");
  const isEnabled = (constraintModules || []).includes(constraintModuleName);

  const isActive = !disabled && isEnabled;

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Stack gap={2}>
          <Typography variant="h4">
            Särskild fördelning av Dag / Kväll / Natt
          </Typography>
          <Typography sx={{ mt: -1, mb: 2 }}>
            Här kan du ange balansen mellan Dag-/Kväll-/Natt-pass {who}. Hur
            Dag-/Kväll-/Natt-pass definieras kan du ställa in under{" "}
            <Link to={"/group-settings#definitions"}>
              Enhet &gt; Dygnsdefinition
            </Link>
            .
          </Typography>
        </Stack>

        <Stack direction="row" gap={columnGap} alignItems="center">
          <Stack sx={labelSx} />
          <Typography variant="caption" sx={fieldSx}>
            Andel
          </Typography>
          {isActive && (
            <Typography variant="caption" sx={labelSx}>
              Antal
            </Typography>
          )}
        </Stack>

        <ShareInput
          isActive={isActive}
          totalShifts={totalShifts}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <Stack gap={1}>
        <AllowedErrorMargin
          visible={true}
          disabled={disabled || !isSoft}
          readOnly={readOnly}
        />
      </Stack>
    </Stack>
  );
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import type { SelectChangeEvent } from "@mui/material";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { DateTime } from "luxon";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";
import { useBooleanState } from "utils/useBooleanState";

import { ShiftDayTypeFragmentProvider } from "components/shifts/ShiftDayTypeContext";
import { shiftTimelineTypeEnum } from "components/shifts/types";

import { DayTabs, useDayTabs } from "./DayTabs";
import type { GraphShift, GraphShiftPart } from "./graphs";
import { ShiftGraph as ShiftGraphRenderer } from "./graphs";
import { MeetingGraphScaffold } from "./Scaffolds";
import type {
  MeetingGraph_setting$key as SettingKey,
  MeetingGraph_shifts$data as Data,
  MeetingGraph_shifts$key as Key,
} from "./types";
import {
  hhmmss2Dt,
  sortStartDt,
  START_OF_WEEK,
  startEndStringToDateTime,
} from "./utils";

type Props = {
  fragmentRef: Key;
  settingFragmentRef: SettingKey;
};
type HeaderProps = {
  week: number;
  setWeek: React.Dispatch<React.SetStateAction<number>>;
  periodLength: number;
  showFullWeek: boolean;
  daysInWeek: Array<number>;
  day: number;
  setDay: React.Dispatch<React.SetStateAction<number>>;
  toggleFullWeek: VoidFunction;
};

type Shift = Unwrap<Data>;
type Part = Omit<ExtractNode<Shift["shiftParts"]>, "partType"> & {
  partType: string;
};
type ShiftOut = Omit<Shift, "meetings" | "shiftParts"> & {
  shiftParts: ReadonlyArray<Part>;
};

const fragment = graphql`
  fragment MeetingGraph_shifts on MeetingShiftNode @relay(plural: true) {
    id
    name
    start
    end
    meetingDay
    breakTime
    shiftParts {
      edges {
        node {
          start
          end
          partType
        }
      }
    }
    meetings {
      edges {
        node {
          start
          end
          dayMatrix
        }
      }
    }
  }
`;

const settingFragment = graphql`
  fragment MeetingGraph_setting on SettingNode {
    periodLengthWeeks
    ...ShiftDayTypeContext_fragment
  }
`;

export function MeetingGraph({ fragmentRef, settingFragmentRef }: Props) {
  const {
    shifts,
    setting,
    week,
    setWeek,
    showFullWeek,
    toggleFullWeek,
    day,
    setDay,
    daysInWeek,
    isEmpty,
    periodLength,
  } = useMeetingGraph(fragmentRef, settingFragmentRef);

  if (isEmpty) {
    return null;
  }

  return (
    <ShiftDayTypeFragmentProvider fragmentRef={setting}>
      <MeetingGraphScaffold>
        <Paper variant="box" sx={{ pb: 3 }}>
          <Header
            week={week}
            setWeek={setWeek}
            showFullWeek={showFullWeek}
            toggleFullWeek={toggleFullWeek}
            day={day}
            setDay={setDay}
            periodLength={periodLength}
            daysInWeek={daysInWeek}
          />

          <Divider sx={{ mt: 1 }} />

          <Stack px={2}>
            <ShiftGraphRenderer shifts={shifts} showFullWeek={showFullWeek} />
          </Stack>
        </Paper>
      </MeetingGraphScaffold>
    </ShiftDayTypeFragmentProvider>
  );
}

const Header: React.FC<HeaderProps> = ({
  week,
  setWeek,
  periodLength,
  showFullWeek,
  daysInWeek,
  day,
  setDay,
  toggleFullWeek,
}) => (
  <Stack
    direction="row"
    gap={2}
    alignItems="center"
    justifyContent="space-between"
    pl={2}
    pr={3}
    pt={1.5}
    pb={0.5}
  >
    <WeekSelect week={week} setWeek={setWeek} max={periodLength} />
    {!showFullWeek && (
      <DayTabs availableDays={daysInWeek} tab={day} setTab={setDay} />
    )}
    <FormControlLabel
      label="Veckovy"
      control={<Checkbox checked={showFullWeek} onChange={toggleFullWeek} />}
      sx={{ m: 0 }}
    />
  </Stack>
);

const WeekSelect: React.FC<{
  week: number;
  setWeek: React.Dispatch<React.SetStateAction<number>>;
  max: number;
}> = ({ week, setWeek, max }) => (
  <Select
    value={week}
    onChange={useCallback(
      (e: SelectChangeEvent<number>) => setWeek(Number(e.target.value)),
      [setWeek],
    )}
    size="small"
  >
    {Array.from({ length: max }).map((_, i) => (
      <MenuItem key={i} value={i}>
        Vecka {i + 1}
      </MenuItem>
    ))}
  </Select>
);

function useMeetingGraph(fragmentRef: Key, settingFragmentRef: SettingKey) {
  const shiftsIn = useFragment<Key>(fragment, fragmentRef);
  const setting = useFragment<SettingKey>(settingFragment, settingFragmentRef);
  const periodLength = setting.periodLengthWeeks;
  const [week, setWeek] = useState<number>(0);
  const { day, setDay } = useDayTabs();
  const { value: showFullWeek, toggle: toggleFullWeek } = useBooleanState(true);
  const daysInWeek = useMemo(
    () =>
      Array.from(
        new Set(
          shiftsIn
            .filter((s) =>
              connectionToArray(s.meetings).some(
                (m) => (m.dayMatrix[week] || []).length > 0,
              ),
            )
            .map((s) => s.meetingDay),
        ),
      ),
    [shiftsIn, week],
  );
  const shifts = useFilterFormatShifts(shiftsIn, week, day, showFullWeek);

  const isEmpty = shiftsIn.length < 1;

  useEffect(() => {
    /* Resolve first non-empty week */
    const meetings = shiftsIn.flatMap((s) => connectionToArray(s.meetings));
    const dayMatrices = meetings.map((m) => m.dayMatrix);
    const nonEmptyWeeks = dayMatrices
      .flatMap((dm) => dm.map((w, i) => [i, w.length]))
      .filter(([, weekLength]) => weekLength > 0)
      .map(([i]) => i);
    const minWeek = nonEmptyWeeks.length > 0 ? Math.min(...nonEmptyWeeks) : 0;
    setWeek(minWeek);
  }, [shiftsIn]);

  useEffect(() => {
    /* Resolve first non-empty day */
    setDay(daysInWeek.length > 0 ? Math.min(...daysInWeek) : 0);
  }, [setDay, daysInWeek]);

  return {
    shifts,
    setting,
    week,
    setWeek,
    day,
    setDay,
    daysInWeek,
    showFullWeek,
    toggleFullWeek,
    isEmpty,
    periodLength,
  };
}

/** Filter, format and sort shifts fragment data to use in MeetingGraph. */
function useFilterFormatShifts(
  shifts: Data,
  week: number,
  day: number,
  showFullWeek: boolean,
) {
  /* Purpose: Passes if both are correct:
   *  - a shift's meetings has the shift's day in the selected week,
   *  - either showFullWeek is true, or the shift's day is selected. */
  const filterFn = useCallback(
    (s: Shift) =>
      (showFullWeek || s.meetingDay === day) &&
      connectionToArray(s.meetings).some(
        (m) =>
          m.dayMatrix?.[week]?.length > 0 &&
          m.dayMatrix[week].includes(s.meetingDay),
      ),
    [showFullWeek, week, day],
  );

  const filteredShifts = useMemo(
    () => shifts.filter(filterFn),
    [shifts, filterFn],
  );

  const formattedShifts = useMemo(
    () => filteredShifts.map(shiftToGraphShift).sort(sortStartDt),
    [filteredShifts],
  );
  return formattedShifts;
}

/** Change connections to arrays for shiftParts and meetings. Move meetings to shiftParts.
 *
 * Method does NOT sort shiftParts. */
function formatShift({ meetings, shiftParts, ...s }: Shift): ShiftOut {
  return {
    ...s,
    shiftParts: [
      ...connectionToArray(shiftParts).map((p) => ({
        ...p,
        partType: p.partType.toUpperCase(),
      })),
      ...connectionToArray(meetings).map(({ start, end }) => ({
        start,
        end,
        partType: shiftTimelineTypeEnum.P.toUpperCase(),
      })),
    ],
  };
}

const getFirstMeeting = (s: ShiftOut): Part | undefined =>
  s.shiftParts
    .filter(
      (p) => p.partType.toUpperCase() === shiftTimelineTypeEnum.P.toUpperCase(),
    )
    .at(0);

function shiftToGraphShift(s: Shift) {
  const formattedShift = formatShift(s);
  const { start: shiftStart } = formattedShift;
  const firstMeetingStart = getFirstMeeting(formattedShift)?.start;

  /* Center `base` around meeting start.
   * If base is 'after' start, flip it back one day.
   *
   * meeting day: 3
   * meeting start:  06:00
   * shift start:    22:00
   * => base day:    2
   */
  let base = hhmmss2Dt(
    START_OF_WEEK.plus({ days: s.meetingDay }),
    firstMeetingStart ?? shiftStart,
  );
  if (base < hhmmss2Dt(base, shiftStart)) {
    base = base.minus({ days: 1 });
  }

  return meetingShiftToGraphShift(formattedShift, base);
}

/** Format shift to GraphShift.
 *
 * @param shift
 * @param base meeting start. Used to infer shift and shift part start date. */
function meetingShiftToGraphShift(
  { start, end, shiftParts, meetingDay: day, ...otherFields }: ShiftOut,
  base: DateTime,
): GraphShift {
  const [startDt, endDt] = startEndStringToDateTime(
    { start, end },
    base,
    "HH:mm:ss",
  );

  return {
    ...otherFields,
    day,
    start: startDt.toJSDate(),
    end: endDt.toJSDate(),
    shiftParts: shiftParts.map((p) =>
      meetingShiftPartToGraphShiftPart(p, startDt),
    ),
  };
}

function meetingShiftPartToGraphShiftPart(
  { start, end, ...otherFields }: Part,
  shiftStart: DateTime,
): GraphShiftPart {
  const [pStart, pEnd] = startEndStringToDateTime(
    { start, end },
    shiftStart,
    "HH:mm:ss",
    true,
  );

  return {
    ...otherFields,
    start: pStart.toJSDate(),
    end: pEnd.toJSDate(),
  };
}

/**
 * @generated SignedSource<<1bac98caf5317913748fa4d519274b53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiBlockedTimeSlotTimeslotTypeChoices = "K" | "O" | "V" | "W" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BlockedTimeSlotTimeline_fragment$data = ReadonlyArray<{
  readonly blockedTimeSlots: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly approvedByAdmin: boolean;
        readonly end: string | null;
        readonly id: string;
        readonly recurrences: string | null;
        readonly shift: {
          readonly end: string;
          readonly id: string;
          readonly start: string;
        } | null;
        readonly shiftDate: string | null;
        readonly start: string | null;
        readonly timeslotType: ApiBlockedTimeSlotTimeslotTypeChoices;
        readonly users: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
  readonly fullName: string;
  readonly id: string;
  readonly memberOf: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly group: {
          readonly id: string;
        };
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "BlockedTimeSlotTimeline_fragment";
}>;
export type BlockedTimeSlotTimeline_fragment$key = ReadonlyArray<{
  readonly " $data"?: BlockedTimeSlotTimeline_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockedTimeSlotTimeline_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BlockedTimeSlotTimeline_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BlockedTimeSlotNodeConnection",
      "kind": "LinkedField",
      "name": "blockedTimeSlots",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BlockedTimeSlotNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BlockedTimeSlotNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timeslotType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approvedByAdmin",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShiftNode",
                  "kind": "LinkedField",
                  "name": "shift",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shiftDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recurrences",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserNodeConnection",
                  "kind": "LinkedField",
                  "name": "users",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UserNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserNode",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v3/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamNodeConnection",
      "kind": "LinkedField",
      "name": "memberOf",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamGroupNode",
                  "kind": "LinkedField",
                  "name": "group",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "b9d0bdde14764a463191aeab80501b2f";

export default node;

import type { ReactNode } from "react";
import { InputAdornment, Typography } from "@mui/material";
import { minutes2HHMM } from "helpers/dateFunctions";

import Field from "components/forms/CustomField";
import ExampleBox from "components/setting/common/ExampleBox";

import { Title as TitleTemplate } from "./Title";

type ExampleProps = {
  shiftsPerPeriod: number;
  periodLengthWeeks: number;
  shiftAvg: number;
};

export const Title = () => (
  <TitleTemplate sx={{ flexGrow: 2 }}>Heltidsmått för pass</TitleTemplate>
);

export const Description = ({ who }: { who?: ReactNode }) => (
  <Typography sx={{ flexGrow: 2 }}>
    Detta fält styr antal <i>pass</i> {who} under 1 vecka.
  </Typography>
);

export const ShiftsPerWeek = ({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) => (
  <Field
    name="shiftsPerWeek"
    fullWidth
    size="small"
    type="number"
    InputProps={{
      readOnly,
      endAdornment: <InputAdornment position="end">pass</InputAdornment>,
    }}
    disabled={disabled}
    inputProps={{ step: 0.1 }}
  />
);

export const Example = ({
  shiftsPerPeriod,
  periodLengthWeeks,
  shiftAvg,
}: ExampleProps) => (
  <ExampleBox sx={{ flexGrow: 2, mt: 1 }}>
    <Typography variant="caption">
      Ger {shiftsPerPeriod} pass på en {periodLengthWeeks}
      -veckorsperiod
    </Typography>
    <Typography variant="caption">
      Ger en genomsnittlig passlängd på {minutes2HHMM(60 * shiftAvg)}
    </Typography>
  </ExampleBox>
);

import { useMemo } from "react";
import { DateTime } from "luxon";
import { TeamGroupSettingsQuery$data as TeamGroupData } from "pages/Settings/__generated__/TeamGroupSettingsQuery.graphql";
import { TeamGroupUsers } from "pages/types";

export function formatHmsToHm(time: string) {
  // Time received from server is in format "HH:mm:ss"
  return DateTime.fromFormat(time, "TT").toFormat("T");
}

export function useUsersWithException(
  teamGroupMembers: TeamGroupUsers,
  moduleName: string,
): TeamGroupUsers {
  return useMemo(() => {
    return teamGroupMembers.filter(
      (member) =>
        member?.userSetting?.settingModules.indexOf(moduleName) !== -1,
    );
  }, [teamGroupMembers, moduleName]);
}

export function useRuleGroupsWithException(
  ruleGroups: TeamGroupData["ruleGroups"],
  moduleName: string,
) {
  return useMemo(() => {
    return ruleGroups.filter(
      (ruleGroup) =>
        ruleGroup.ruleGroupSetting?.settingModules.indexOf(moduleName) !== -1,
    );
  }, [ruleGroups, moduleName]);
}

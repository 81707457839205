import type { PropsWithChildren, ReactNode } from "react";
import {
  Check as SuccessIcon,
  Info as InfoIcon,
  WarningRounded as WarningIcon,
} from "@mui/icons-material";
import type { StackProps } from "@mui/material";
import { Stack } from "@mui/material";

type BoxProps = StackProps & {
  icon: ReactNode;
  borderColor: string;
};

type TemplateBoxProps = Omit<BoxProps, "icon" | "borderColor">;
type InfoBoxProps = TemplateBoxProps;
type SuccessBoxProps = TemplateBoxProps;
type WarningBoxProps = TemplateBoxProps;
type ErrorBoxProps = TemplateBoxProps;

export function NoticeBox({
  borderColor,
  icon,
  children,
  ...props
}: PropsWithChildren<BoxProps>) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      px={2}
      py={1.5}
      {...props}
      sx={{
        bgcolor: "background.paper",
        borderRadius: "0.25rem",
        borderColor,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 10,
        ...(props?.sx ?? {}),
      }}
    >
      {icon}
      {children}
    </Stack>
  );
}

export function InfoBox(props: InfoBoxProps) {
  const color = "info.light";

  return (
    <NoticeBox
      borderColor={color}
      icon={<InfoIcon sx={{ color }} />}
      {...props}
    />
  );
}

export function SuccessBox(props: SuccessBoxProps) {
  const color = "success.main";

  return (
    <NoticeBox
      borderColor={color}
      icon={<SuccessIcon sx={{ color }} />}
      {...props}
    />
  );
}

export function WarningBox(props: WarningBoxProps) {
  const color = "warning.main";

  return (
    <NoticeBox
      borderColor={color}
      icon={<WarningIcon sx={{ color }} />}
      {...props}
    />
  );
}

export function ErrorBox(props: ErrorBoxProps) {
  const color = "error.main";

  return (
    <NoticeBox
      borderColor={color}
      icon={<WarningIcon sx={{ color }} />}
      {...props}
    />
  );
}

export default WarningBox;

/**
 * @generated SignedSource<<e15cb1593afe08391daeb3fb022d1516>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DayNightDistributionSettingUserSetting_fragment$data = {
  readonly dayShiftDistributionShare: number;
  readonly eveningShiftDistributionShare: number;
  readonly fullDayShiftDistributionShare: number;
  readonly id: string;
  readonly nightShiftDistributionShare: number;
  readonly settingModules: ReadonlyArray<string>;
  readonly shiftDayTypeAllowedErrorMargin: number;
  readonly shiftDayTypeDistributionSoft: boolean;
  readonly shiftsPerWeek: number;
  readonly " $fragmentType": "DayNightDistributionSettingUserSetting_fragment";
};
export type DayNightDistributionSettingUserSetting_fragment$key = {
  readonly " $data"?: DayNightDistributionSettingUserSetting_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DayNightDistributionSettingUserSetting_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DayNightDistributionSettingUserSetting_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeDistributionSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDayTypeAllowedErrorMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftDistributionShare",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayShiftDistributionShare",
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "096fbd9b48c9c29fe3bf25e6381f143e";

export default node;

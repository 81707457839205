import { Stack } from "@mui/material";

import ColorDot from "components/common/ColorDot";

type Props = {
  label: string;
  color: string;
};

export function OptionLabel({ label, color }: Props) {
  return (
    <Stack gap={0.8} direction="row" alignItems="center">
      <ColorDot color={color} />
      {label}
    </Stack>
  );
}

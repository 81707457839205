/**
 * @generated SignedSource<<d5d9e2e4459665867c804ec271747c83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ApiActivityActivityTypeChoices = "A" | "C" | "D" | "F" | "J" | "M" | "O" | "P" | "Q" | "R" | "S" | "V" | "W" | "Y" | "Z" | "%future added value";
export type ApiActivityPartPartTypeChoices = "B" | "J" | "M" | "P" | "Z" | "%future added value";
export type EditActivityFormQuery$variables = {
  id: string;
};
export type EditActivityFormQuery$data = {
  readonly activity: {
    readonly activityParts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly end: string;
          readonly id: string;
          readonly partType: ApiActivityPartPartTypeChoices;
          readonly start: string;
        } | null;
      } | null>;
    };
    readonly activityType: ApiActivityActivityTypeChoices;
    readonly breakTime: number | null;
    readonly end: string;
    readonly start: string;
    readonly team: {
      readonly id: string;
    } | null;
    readonly user: {
      readonly id: string;
      readonly name: string;
    } | null;
  } | null;
};
export type EditActivityFormQuery = {
  response: EditActivityFormQuery$data;
  variables: EditActivityFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activityType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UserSnapshotNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamSnapshotNode",
  "kind": "LinkedField",
  "name": "team",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ActivityPartNodeConnection",
  "kind": "LinkedField",
  "name": "activityParts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityPartNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityPartNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "partType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditActivityFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivityNode",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditActivityFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivityNode",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5aadac6be748cd22869427821833afc",
    "id": null,
    "metadata": {},
    "name": "EditActivityFormQuery",
    "operationKind": "query",
    "text": "query EditActivityFormQuery(\n  $id: ID!\n) {\n  activity(id: $id) {\n    start\n    end\n    breakTime\n    activityType\n    user {\n      id\n      name\n    }\n    team {\n      id\n    }\n    activityParts {\n      edges {\n        node {\n          id\n          start\n          end\n          partType\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "70c4d4a459c205f310d36f42e707dca5";

export default node;

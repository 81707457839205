import { Link } from "react-router-dom";
import { List, ListItem, Stack, Typography } from "@mui/material";
import { useCurrentTeamGroup } from "hooks";
import { dayNightCadenceSetting } from "settings/DayNightCadence/DayNightCadenceSetting";
import { optimizationRuleUrl } from "utils/urls";

const listStyle = { listStyleType: "disc", pl: 2, pb: 1.5, pt: 0 };
const listItemStyle = { display: "list-item", pb: 1 };

export function WorkDayCadenceInfo() {
  const { id: teamGroupId } = useCurrentTeamGroup();

  return (
    <Stack gap={1}>
      <Typography variant="h4">
        Skapa egna regler för sammanhängande arbetskalenderdagar
      </Typography>
      <Typography>
        I den här regelmodulen kan du skapa egna, särskilda regler, för
        sammanhängande arbetskalenderdagar. Du kan skapa flera regler som
        kompletterar varandra. Här följer 3 exempel på regelformuleringar som vi
        brukar rekommendera:
      </Typography>
      <List sx={listStyle}>
        <ListItem sx={listItemStyle}>
          <Typography fontWeight="bold">
            6 arbetskalenderdagar på 8 rullande kalenderdagar - Hård
          </Typography>
          <Typography>
            6-av-8-regeln brukar vara en effektiv regel för att styra schemat
            mot en hållbar schemarytm. Med en 6-av-8-regel så undviker vi långa
            sjok av arbete som efterföljs av långa ledigheter. Istället får vi
            ett schema med en jämn rytm. För ett dag/kväll-schema rekommenderar
            vi att en 6-av-8-regel bör vara hård för bästa resultat.
          </Typography>
        </ListItem>
        <ListItem sx={listItemStyle}>
          <Typography fontWeight="bold">
            5 arbetskalenderdagar på 7 rullande kalenderdagar - Mjuk
          </Typography>
          <Typography>
            5-av-7-regeln är en ännu tuffare formulering än 6-av-8-regeln, men
            när den uppfylls så innebär det att man har undvikit rullande
            kalenderveckor där 6 pass har schemalagts, vilket oftast brukar
            upplevas som en tung arbetsperiod. Notera dock att 5-av-7-regeln är
            en "dyr" regel och går sällan att få ihop överallt och därmed kan
            resultera i hårda regelkrockar (i synnerhet vid
            varannan-helg-arbete). Vi rekommenderar därför att denna
            regelformulering alltid ska vara mjuk.
          </Typography>
        </ListItem>
      </List>
      <Typography variant="h4">
        Vill du skapa sådana här regler, fast för dag-/kväll-/natt-/dygn-pass?
      </Typography>
      <Typography>
        Då ska du titta närmre på regelmodulen{" "}
        <Link to={optimizationRuleUrl(teamGroupId, dayNightCadenceSetting)}>
          Dag-/Kväll-/Natt-kadens
        </Link>
        ! Där kan du skapa precis sådana här regler särskilt specificerat för en
        viss passtyp. Ett vanligt exempel är max 3 nattpass på rullande 4
        kalenderdagar, vilket alltså innebär max 3 nattpass på raken.
      </Typography>
    </Stack>
  );
}

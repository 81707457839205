import { UnreachableCaseError } from "ts-essentials";

import type { ApiBlockedTimeSlotTimeslotTypeChoices } from "./graphs/types";

export * from "./__generated__/BlockedTimeSlotUserSelect_fragment.graphql";
export * from "./common/__generated__/BlockedTimeSlotFormQuery.graphql";
export * from "./graphs/types";
export * from "./mutations/types";

type TimeslotType = ApiBlockedTimeSlotTimeslotTypeChoices | "H";

export enum BlockedTimeSlotType {
  REQUESTED_VACATION = "V",
  WISHED_DAY = "W",
  FIXED_DAY_OFF = "O",
  FIXED_SHIFT = "K",
}

export const BlockedTimeSlotTimeslotTypeChoices: ApiBlockedTimeSlotTimeslotTypeChoices[] =
  Object.values(BlockedTimeSlotType);

export const TranslateBlockedTimeSlotType = (
  type: TimeslotType | undefined,
): string | undefined => {
  switch (type) {
    case "W":
      return "Önskad fridag";
    case "V":
      return "Semester";
    case "O":
      return "Fast fridag";
    case "K":
      return "Fast pass";
    case "H":
      return "Röd dag";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export const TranslateBlockedTimeSlotCategory = (
  type: ApiBlockedTimeSlotTimeslotTypeChoices | undefined,
): string | undefined => {
  switch (type) {
    case "W":
      return "Ledighet";
    case "V":
      return "Ledighet";
    case "O":
      return "Ledighet";
    case "K":
      return "Arbete";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

export type ShiftType = {
  id: string;
  name: string;
  start: string;
  end: string;
};

import { Paper, Skeleton, Stack, Typography } from "@mui/material";

import { ResultsPlaceholder } from "./ResultsPlaceholder";

function Tab({ label }: { label: string }) {
  return (
    <Paper
      variant="box"
      sx={{
        py: 1.6,
        px: 2.1,
        border: "1px solid divider",
        borderBottom: "none",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      }}
    >
      <Typography fontSize={14} fontWeight={500} color="text.secondary">
        {label}
      </Typography>
    </Paper>
  );
}

export function TabsPlaceholder() {
  return (
    <Stack direction="row" gap={0.4}>
      <Tab label="Resultat" />
      <Tab label="Statistik" />
      <Tab label="Inställningar" />
      <Tab label="Optimeringsregler" />
      <Tab label="Optimeringsstatistik" />
    </Stack>
  );
}

export function TabHeaderPlaceholder() {
  return (
    <Stack
      sx={{
        height: 207,
        pt: 4,
      }}
    >
      <Skeleton id="go-back" height={50} width={160} />
      <Skeleton id="schedule-name" height={140} width={530} sx={{ mb: 3 }} />
      <TabsPlaceholder />
    </Stack>
  );
}

export function ScheduleTabsPlaceholder() {
  return <ResultsPlaceholder />;
}

export function SchedulePlaceholder() {
  return (
    <Stack
      pb={4}
      pl={4}
      pt={0}
      mr={4}
      sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
    >
      <TabHeaderPlaceholder />
      <ScheduleTabsPlaceholder />
    </Stack>
  );
}

import { useEffect } from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {
  refresh as refreshPageQuery,
  usePageQuery,
} from "contexts/PageQueryContext";
import { usePermissions } from "contexts/PermissionContext";

import { Me_me$data as Data, Me_me$key as Key } from "./types";

type UserDataWithPermissions = {
  userData: Data;
  permissions: Record<string, string>;
  fetchPermissions: (userID: string) => void;
  evaluatePermission: (permissionName: string, permission: string) => boolean;
  loading: boolean;
};

const fragment = graphql`
  fragment Me_me on UserNode {
    id
    fullName
    email
    username
    isSuperuser
    memberOf {
      edges {
        node {
          id
        }
      }
    }
    groupMemberOf {
      edges {
        node {
          id
        }
      }
    }
    teamAdminOf {
      edges {
        node {
          id
        }
      }
    }
    groupAdminOf {
      edges {
        node {
          id
        }
      }
    }
  }
`;

/**
 * Returns the currently logged in user, or null otherwise.
 */
export const useMe = () => {
  const query = usePageQuery();
  return useFragment<Key>(fragment, query.me);
};

/**
 * Returns the currently logged in user, or throw an error otherwise.
 */
export const useRequireMe = (): UserDataWithPermissions => {
  const data = useMe();
  if (!data) {
    throw new Error(
      "useRequireMe must be used within PageQueryProvider context",
    );
  }
  const { permissions, fetchPermissions, evaluatePermission, loading } =
    usePermissions();
  useEffect(() => {
    fetchPermissions(data.id);
  }, [fetchPermissions, data.id]);

  return {
    userData: data,
    permissions,
    fetchPermissions,
    evaluatePermission,
    loading,
  };
};

export const refresh = async () => refreshPageQuery();

import { useMemo } from "react";
import type { CSSObject } from "@mui/material";
import { Divider, MenuItem, Stack, Typography } from "@mui/material";

import GenericSelect from "components/common/selectors/GenericSelect";

import { Panel1 } from "./common/Panel";

type Option = {
  label: string;
  value: string;
};

type Props = {
  teamGroupId: string;
  teamGroupName: string;
  levelId: string;
  ruleGroups: Option[];
  users: Option[];
  onSelect: (levelId: string) => void;
  sx?: CSSObject;
};

export function Level1Menu({
  teamGroupId,
  teamGroupName,
  levelId,
  ruleGroups,
  users,
  onSelect,
  sx = {},
}: Props) {
  const teamGroupSelected = useMemo(
    () => levelId === teamGroupId,
    [levelId, teamGroupId],
  );
  const teamGroupLabel = useMemo(
    () =>
      teamGroupName.length > 25
        ? `${teamGroupName.slice(0, 22)}...`
        : teamGroupName,
    [teamGroupName],
  );
  const selectedRuleGroup = useMemo(
    () => ruleGroups.find((o) => o.value === levelId),
    [ruleGroups, levelId],
  );
  const selectedPerson = useMemo(
    () => users.find((o) => o.value === levelId),
    [users, levelId],
  );

  return (
    <Panel1 sx={sx}>
      <Stack gap={2} pt={3} sx={{ "&>*": { px: 2 } }}>
        <Typography variant="h3">Nivå</Typography>
        <Divider sx={{ mx: 2 }} />
        <Typography variant="h5">Enhet</Typography>
        <MenuItem
          onClick={() => onSelect(teamGroupId)}
          selected={teamGroupSelected}
          sx={{ mt: -0.85, mb: -1 }}
        >
          <Typography variant="body1">{teamGroupLabel}</Typography>
        </MenuItem>
        <Divider sx={{ mx: 2 }} />
        <Stack gap={2}>
          <Typography variant="h5">Regelgrupp</Typography>
          <GenericSelect
            options={ruleGroups}
            value={selectedRuleGroup}
            onChange={(_e: any, v: Option | null) => onSelect(v?.value || "")}
          />
        </Stack>
        <Divider sx={{ mx: 2 }} />
        <Stack gap={2}>
          <Typography variant="h5">Person</Typography>
          <GenericSelect
            options={users}
            value={selectedPerson}
            onChange={(_e: any, v: Option | null) => onSelect(v?.value || "")}
          />
        </Stack>
        <Divider sx={{ mx: 2 }} />
      </Stack>
    </Panel1>
  );
}

import { DemandInfo } from "pages/info/DemandInfo";

import { InfoModalProvider } from "components/layout";

import type { GroupSettingCardProps } from "./GroupSettingCard";
import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<GroupSettingCardProps, "title" | "action">;

const TITLE = "Behovsprofil";

const InfoButton = () => (
  <InfoModalProvider>
    <DemandInfo group="" />
  </InfoModalProvider>
);

export function DemandProfileCard(props: Props) {
  return <GroupSettingCard title={TITLE} action={<InfoButton />} {...props} />;
}

DemandProfileCard.id = "demands" as const;
DemandProfileCard.title = TITLE;

import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { connectionToArray, ExtractNode } from "relay-help/arrays";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummySubmit,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
} from "settings/common/optimizationSetting";
import { EMPTY_ARRAY } from "utils/constants";
import * as yup from "yup";

import { useUpdateSettingsMutation } from "components/setting/team_group/setting_boxes/updateSettings";

import { weightChoices } from "../../components/setting/team_group/setting_boxes/constants";

import {
  CompetenceDemandSettingTeamGroup_fragment$data as TeamGroupData,
  CompetenceDemandSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/CompetenceDemandSettingTeamGroup_fragment.graphql";
import { CompetenceDemandForm } from "./CompetenceDemandForm";

const teamGroupFragment = graphql`
  fragment CompetenceDemandSettingTeamGroup_fragment on SettingNode {
    id
    group {
      competenceDemandSettings {
        edges {
          node {
            id
            allowUnderstaffing
            understaffingWeight
            competence {
              name
              color
            }
          }
        }
      }
    }
  }
`;

const SETTING_NAME = "Kompetensbehov";
const MODULE_NAME = "CompetenceDemand";
const SETTING_URL_ID = "competence-demand";

type CompetenceDemandSetting = ExtractNode<
  TeamGroupData["group"]["competenceDemandSettings"]
>;

type CompetenceDemandFormValues = {
  id: string;
  competenceDemandSettings?: ReadonlyArray<CompetenceDemandSetting | null> | null;
};

const weightSchema = yup.string().oneOf(weightChoices, "Ogiltig vikt");
const competenceDemandSettingSchema = yup.object().shape({
  id: yup.string().required("Måste anges"),
  allowUnderstaffing: yup.boolean().required("Måste anges"),
  understaffingWeight: weightSchema.required("Måste anges"),
});

const validationSchema = yup.object().shape({
  id: yup.string().required("Måste anges"),
  competenceDemandSettings: yup
    .array()
    .of(competenceDemandSettingSchema)
    .required("Måste anges"),
});

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): CompetenceDemandFormValues {
  return {
    id: teamGroupData.id,
    competenceDemandSettings: connectionToArray(
      teamGroupData.group.competenceDemandSettings,
    ),
  };
}

function useSubmitFunctions() {
  const [updateSettings] = useUpdateSettingsMutation();

  const onSubmitTeamGroup = useCallback(
    (input: CompetenceDemandFormValues) => {
      const convertedInput = {
        ...input,
        competenceDemandSettings:
          input.competenceDemandSettings?.map((s) => ({
            id: s?.id || "",
            allowUnderstaffing: s?.allowUnderstaffing || false,
            understaffingWeight: s?.understaffingWeight || "M",
          })) ?? EMPTY_ARRAY,
      };
      updateSettings({ variables: { input: convertedInput } });
    },
    [updateSettings],
  );

  return {
    onSubmitTeamGroup,
    onSubmitUser: dummySubmit,
    onSubmitRuleGroup: dummySubmit,
  };
}

function renderComponent(
  initialValues: CompetenceDemandFormValues,
  onSubmit: (formValues: CompetenceDemandFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <CompetenceDemandForm groupName={"groupName"} />
    </BaseSettings>
  );
}

export const competenceDemandSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  CompetenceDemandFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions,
  renderComponent,
};

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { updateSettingsMutation as Mutation } from "./__generated__/updateSettingsMutation.graphql";

const mutation = graphql`
  mutation updateRuleGroupSettingsMutation(
    $input: UpdateRuleGroupSettingInput!
  ) {
    updateRuleGroupSetting(input: $input) {
      ok
      ruleGroupSetting {
        ...HoursAndShiftsSettingRuleGroup_fragment
        ...ConsecutiveWorkSettingRuleGroup_fragment
        ...DayRestSettingRuleGroup_fragment
        ...WeekRestSettingRuleGroup_fragment
        ...WeekendRestSettingRuleGroup_fragment
        ...ConsecutiveRestSettingRuleGroup_fragment
        ...ShiftDistributionSettingRuleGroup_fragment
        ...NightShiftSettingRuleGroup_fragment
        ...CostSettingRuleGroup_fragment
        ...PartTimeSettingRuleGroup_fragment
      }
    }
  }
`;

export const useUpdateRuleGroupSettingsMutation = () =>
  useMutation<Mutation>(mutation);

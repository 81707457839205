import useFormikState from "hooks/useFormikState";

import Select from "components/common/selectors/WeightSelect";

type SelectProps = Omit<
  React.ComponentProps<typeof Select>,
  "value" | "handleChange"
>;

type Props = SelectProps & { label: string; name: string };

export function WeightSelect({ label, name, ...props }: Props) {
  const { value, setValue } = useFormikState(name);

  return (
    <Select
      {...props}
      value={value ? value.toUpperCase() : null}
      label={label}
      handleChange={setValue}
    />
  );
}

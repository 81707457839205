/**
 * @generated SignedSource<<7511f514f1ee964e856387a88f35f3bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRuleGroupsContext_ruleGroups$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly teamGroup: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "UserRuleGroupsContext_ruleGroups";
}>;
export type UserRuleGroupsContext_ruleGroups$key = ReadonlyArray<{
  readonly " $data"?: UserRuleGroupsContext_ruleGroups$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserRuleGroupsContext_ruleGroups">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserRuleGroupsContext_ruleGroups",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamGroupNode",
      "kind": "LinkedField",
      "name": "teamGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "RuleGroupNode",
  "abstractKey": null
};
})();

(node as any).hash = "4cdd0a808cb22fe6fa535e4f73af067a";

export default node;

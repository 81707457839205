import { useMemo } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import useFormikState from "hooks/useFormikState";
import * as yup from "yup";

import { DialogTitle } from "components/common/Dialog";
import type { Option as TeamOption } from "components/common/selectors/TeamSelect";
import { BaseTeamSelect as TeamSelect } from "components/common/selectors/TeamSelect";
import { TeamGroupSelect } from "components/forms/UserForm";

export type MultiEditUsersFormValues = {
  userIds: string[];
  teamIds: string[];
  teamGroupIds: string[];
};

type User = {
  id: string;
  name: string;
};

type Team = {
  id: string;
  name: string;
  color: string;
};

type TeamGroup = {
  id: string;
  name: string;
  teams: Team[];
};

type FormProps = {
  users?: User[];
  teamGroups?: TeamGroup[];
};

type Props = {
  users?: User[];
  teamGroups?: TeamGroup[];
  onClose: () => void;
  title: string;
};

export const multiEditUsersValidationSchema = yup.object().shape({
  userIds: yup.array().of(yup.string()).required(),
  teamIds: yup.array().of(yup.string()).required(),
  teamGroupIds: yup.array().of(yup.string()).required(),
});

type UserListProps = Pick<FormProps, "users">;
function UserList({ users = [] }: UserListProps) {
  return (
    <Paper variant="box" sx={{ py: 1 }}>
      <List
        dense
        disablePadding
        sx={{ overflowY: "auto", borderRadius: "inherit" }}
      >
        <ListSubheader sx={{ py: 0.5, lineHeight: 1 }}>
          <Typography variant="caption">Valda användare</Typography>
        </ListSubheader>
        {users?.map((user) => (
          <ListItem key={user.id} sx={{ py: 0 }}>
            <ListItemText primary={user.name} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}

function Form({ users = [], teamGroups = [] }: FormProps) {
  const { value: teamIds, setValue: setTeamIds } =
    useFormikState<string[]>("teamIds");

  const teams = useMemo<TeamOption[]>(
    () =>
      teamGroups.flatMap((tg) =>
        (tg.teams || []).map((t: Team) => ({
          id: t.id,
          name: t.name,
          color: t.color,
          group: { id: tg.id, name: tg.name },
        })),
      ),
    [teamGroups],
  );
  return (
    <Stack gap={2}>
      <Typography>
        Observera! De enheter och avdelningar som du väljer här kommer att
        skriva över tidigare enhets- och avdelningstillhörigheter för de valda
        användarna.
      </Typography>
      <Stack direction="row" gap={2} width="100%" pt={1}>
        <UserList users={users} />
        <Stack gap={4} flexGrow={2}>
          <TeamGroupSelect name="teamGroupIds" teamGroups={teamGroups} />
          <TeamSelect
            multiple
            value={teamIds}
            onChange={setTeamIds}
            options={teams}
            filterOnGroupIds={teamGroups.map((x) => x.id)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export function MultiEditUsersForm({
  users = [],
  teamGroups = [],
  onClose,
  title,
}: Props) {
  return (
    <Stack>
      <DialogTitle title={title} onClose={onClose} />
      <Divider />

      <DialogContent>
        <Form users={users} teamGroups={teamGroups} />
      </DialogContent>
      <Divider />

      <DialogActions>
        <Button variant="cancel" onClick={onClose}>
          Avbryt
        </Button>
        <Button variant="primary" type="submit">
          Spara
        </Button>
      </DialogActions>
    </Stack>
  );
}

/**
 * @generated SignedSource<<019a52f4187b892e4beda5ae2bfd141b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteRuleGroupMutation$variables = {
  id: string;
};
export type DeleteRuleGroupMutation$data = {
  readonly deleteRuleGroup: {
    readonly deletedId: string | null;
    readonly ok: boolean | null;
  } | null;
};
export type DeleteRuleGroupMutation = {
  response: DeleteRuleGroupMutation$data;
  variables: DeleteRuleGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteRuleGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteRuleGroupPayload",
        "kind": "LinkedField",
        "name": "deleteRuleGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteRuleGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteRuleGroupPayload",
        "kind": "LinkedField",
        "name": "deleteRuleGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedId"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a1fcc088a884ae140a8b1d994ba8455",
    "id": null,
    "metadata": {},
    "name": "DeleteRuleGroupMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteRuleGroupMutation(\n  $id: ID!\n) {\n  deleteRuleGroup(input: {id: $id}) {\n    ok\n    deletedId\n  }\n}\n"
  }
};
})();

(node as any).hash = "245ea29a0c35cd7cb019c42a2892cf8b";

export default node;

import type { ReactNode } from "react";
import { Children } from "react";
import type { TableRowProps } from "@mui/material";
import { TableCell, TableRow } from "@mui/material";

type Props = TableRowProps & {
  toggle: ReactNode;
  name: ReactNode;
  start: ReactNode;
  end: ReactNode;
  breakTime: ReactNode;
  duration: ReactNode;
  workTime: ReactNode;
  dayType: ReactNode;
  actions: ReactNode;
};

/** TableRow which surrounds each child in a TableCell, with no borderBottom. */
function CustomTableRow({ children, ...props }: TableRowProps) {
  return (
    <TableRow {...props}>
      {Children.map(children, (child) => (
        <TableCell sx={{ borderBottom: "none" }}>{child}</TableCell>
      ))}
    </TableRow>
  );
}

/** Common TableRow component to make Shift and ShiftPart fields equally sized. */
export function RowTemplate({
  toggle,
  name,
  start,
  end,
  breakTime,
  duration,
  workTime,
  dayType,
  actions,
  ...rowProps
}: Props) {
  return (
    <CustomTableRow sx={{ "&>td": { borderBottom: "" } }} {...rowProps}>
      {toggle}
      {name}
      {start}
      {end}
      {breakTime}
      {duration}
      {workTime}
      {dayType}
      {actions}
    </CustomTableRow>
  );
}

/**
 * @generated SignedSource<<c85bba82d5f286958a5e534c1330a83b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateMeetingQuery$variables = {
  teamGroupId: string;
};
export type CreateMeetingQuery$data = {
  readonly settingForTeamGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateMeetingForm_setting">;
  } | null;
  readonly usersOnTeamGroup: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ParticipantList_users" | "UserSelect_fragment">;
  }>;
};
export type CreateMeetingQuery = {
  response: CreateMeetingQuery$data;
  variables: CreateMeetingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamGroupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "teamGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMeetingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "usersOnTeamGroup",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSelect_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ParticipantList_users"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateMeetingForm_setting"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMeetingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "usersOnTeamGroup",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "memberOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodLengthWeeks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "morningStartsNightEnds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "eveningStartsMorningEnds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nightStartsEveningEnds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullDayDuration",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e995761ff3b2f180ffbbaa590b2c69c2",
    "id": null,
    "metadata": {},
    "name": "CreateMeetingQuery",
    "operationKind": "query",
    "text": "query CreateMeetingQuery(\n  $teamGroupId: ID!\n) {\n  usersOnTeamGroup(teamGroupId: $teamGroupId) {\n    ...UserSelect_fragment\n    ...ParticipantList_users\n    id\n  }\n  settingForTeamGroup(teamGroupId: $teamGroupId) {\n    ...CreateMeetingForm_setting\n    id\n  }\n}\n\nfragment CreateMeetingForm_setting on SettingNode {\n  periodLengthWeeks\n  ...MeetingForm_setting\n}\n\nfragment MeetingForm_setting on SettingNode {\n  ...ShiftDayTypeContext_fragment\n}\n\nfragment ParticipantList_users on UserNode {\n  id\n  fullName\n  memberOf {\n    edges {\n      node {\n        id\n        name\n        color\n      }\n    }\n  }\n}\n\nfragment ShiftDayTypeContext_fragment on SettingNode {\n  morningStartsNightEnds\n  eveningStartsMorningEnds\n  nightStartsEveningEnds\n  fullDayDuration\n}\n\nfragment UserSelect_fragment on UserNode {\n  id\n  fullName\n}\n"
  }
};
})();

(node as any).hash = "81e406e9acbc0cd591f9fb390949cee5";

export default node;

import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertTeamGroupDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctionsOnlyTeamGroup,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { clockwiseRotationWeightChoices } from "components/setting/team_group/setting_boxes/constants";

import {
  ClockwiseRotationSettingTeamGroup_fragment$data as TeamGroupData,
  ClockwiseRotationSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/ClockwiseRotationSettingTeamGroup_fragment.graphql";
import { ClockwiseShiftRotationForm } from "./form/ClockwiseShiftRotationForm";

const teamGroupFragment = graphql`
  fragment ClockwiseRotationSettingTeamGroup_fragment on SettingNode {
    id
    constraintModules
    clockwiseRotationLimitHours
    clockwiseRotationSoft
    clockwiseRotationWeight
  }
`;

const SETTING_NAME = "Medsols passrotation";
const MODULE_NAME = "ClockwiseRotation";
const SETTING_URL_ID = "clockwise-shift-rotation";

type ClockwiseRotationFormValues = Pick<
  TeamGroupData,
  | "id"
  | "constraintModules"
  | "clockwiseRotationLimitHours"
  | "clockwiseRotationSoft"
  | "clockwiseRotationWeight"
>;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()),
  clockwiseRotationLimitHours: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(10000, "Måste vara högst 10 000")
    .required("Får ej vara tomt"),
  clockwiseRotationSoft: yup.boolean().required(),
  clockwiseRotationWeight: yup
    .string()
    .oneOf(clockwiseRotationWeightChoices)
    .required(),
});

function renderComponent(
  initialValues: ClockwiseRotationFormValues,
  onSubmit: (formValues: ClockwiseRotationFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ClockwiseShiftRotationForm />
    </BaseSettings>
  );
}

export const clockwiseRotationSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  ClockwiseRotationFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctionsOnlyTeamGroup,
  renderComponent,
};

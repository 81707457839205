import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useRequireMe } from "hooks";

import { PageHeader } from "components/layout/PageWrapper";

type Props = {
  onNew: () => void;
};

export function BlockedTimeSlotHeader({ onNew }: Props) {
  const { evaluatePermission } = useRequireMe();

  return (
    <PageHeader
      title="Händelser"
      actions={
        evaluatePermission("events", "WRITE") && (
          <Button variant="primary" onClick={onNew} endIcon={<AddIcon />}>
            Ny händelse
          </Button>
        )
      }
    />
  );
}

import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";

import environment from "../../../../environment";

import { DeleteActivityMutation } from "./__generated__/DeleteActivityMutation.graphql";

const mutation = graphql`
  mutation DeleteActivityMutation($id: ID!) {
    deleteActivity(id: $id) {
      ok
      deletedId
      clonedSchedule {
        id
      }
    }
  }
`;

export function DeleteActivity(id: string) {
  return commitMutation<DeleteActivityMutation>(environment, {
    mutation,
    variables: { id },
    configs: [{ type: "NODE_DELETE", deletedIDFieldName: "deletedId" }],
  }).then((response) => {
    if (!response.deleteActivity?.ok) {
      return Promise.reject("Could not delete activity.");
    }
    return response;
  });
}

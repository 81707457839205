import type { ComponentProps } from "react";

import { SettingBox } from "components/setting/common";
import { Base2Info as Info } from "components/setting/common/forms";
import { FullAndPartTimeMeasureField } from "components/setting/common/forms/Advanced";

type Props = {
  groupName: string;
  periodLengthWeeks: number;
  shiftsPerWeek: number;
  hoursPerWeek: number;
  shifts: ComponentProps<typeof FullAndPartTimeMeasureField>["shifts"];
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  return (
    <>
      Deltidsmått för enhet <b>{name}</b>
    </>
  );
}

export function ParttimesForm({
  groupName,
  periodLengthWeeks,
  shiftsPerWeek,
  hoursPerWeek,
  shifts,
  readOnly,
}: Props) {
  return (
    <SettingBox title={<Title name={groupName} />} infoButton={<Info />}>
      <FullAndPartTimeMeasureField
        name={groupName}
        periodLengthWeeks={periodLengthWeeks}
        shiftsPerWeek={shiftsPerWeek}
        hoursPerWeek={hoursPerWeek}
        shifts={shifts}
        readOnly={readOnly}
      />
    </SettingBox>
  );
}

import { Stack, Unstable_Grid2 as Grid } from "@mui/material";

import {
  Description as TotalMarginHoursDesc,
  Example as TotalMarginHoursExample,
  Title as TotalMarginHoursTitle,
  TotalHoursMarginField,
} from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/TotalMarginHours";
import {
  Description as TotalMarginShiftsDesc,
  Example as TotalMarginShiftsExample,
  Title as TotalMarginShiftsTitle,
  TotalShiftsMarginField,
} from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/TotalMarginShifts";
import type { Shift } from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/useGraphData";

type DisabledAndReadOnlyProps = {
  disabled?: boolean;
  readOnly?: boolean;
};
type Props = DisabledAndReadOnlyProps & {
  periodLengthWeeks: number;
  shiftsPerWeek: number;
  hoursPerWeek: number;
  shifts: ReadonlyArray<Shift>;
};
type TotalMarginsProps = Pick<
  Props,
  "periodLengthWeeks" | "hoursPerWeek" | "shiftsPerWeek"
>;

export function MarginsField({
  periodLengthWeeks,
  hoursPerWeek,
  shiftsPerWeek,
}: TotalMarginsProps) {
  return (
    <Stack gap={1}>
      <Grid id="titles" container spacing={5} columns={2}>
        <Grid xs={1}>
          <TotalMarginHoursTitle />
        </Grid>
        <Grid xs={1}>
          <TotalMarginShiftsTitle />
        </Grid>
      </Grid>
      <Grid id="descs" container spacing={5} columns={2}>
        <Grid xs={1}>
          <TotalMarginHoursDesc />
        </Grid>
        <Grid xs={1}>
          <TotalMarginShiftsDesc />
        </Grid>
      </Grid>
      <Grid id="fields" container spacing={5} columns={2}>
        <Grid xs={1}>
          <TotalHoursMarginField />
        </Grid>
        <Grid xs={1}>
          <TotalShiftsMarginField />
        </Grid>
      </Grid>
      <Grid id="examples" container spacing={5} columns={2}>
        <Grid xs={1}>
          <TotalMarginHoursExample
            periodLengthWeeks={periodLengthWeeks}
            hoursPerWeek={hoursPerWeek}
          />
        </Grid>
        <Grid xs={1}>
          <TotalMarginShiftsExample
            periodLengthWeeks={periodLengthWeeks}
            shiftsPerWeek={shiftsPerWeek}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

import type { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

import Field from "components/forms/CustomField";
import PartTimeGraph from "components/setting/common/components/PartTimeGraph";
import { ShiftScalingLogicSelect } from "components/setting/common/components/ShiftScalingLogicSelect";
import ExampleBox from "components/setting/common/ExampleBox";

import { Title as TitleTemplate } from "./Title";
import type { GraphData } from "./useGraphData";

type DisabledAndReadOnlyProps = {
  disabled?: boolean;
  readOnly?: boolean;
};

export const Title = () => (
  <TitleTemplate sx={{ flexGrow: 2 }}>Deltidsskalning för pass</TitleTemplate>
);

export const Description = ({ part }: { part?: ReactNode }) => (
  <Typography sx={{ flexGrow: 2 }}>
    Detta fält styr hur antalet <i>pass</i> {part} ska skalas. Tips! Hoovra över
    grafen.
  </Typography>
);

const ShiftsEmploymentDegreeFactor = ({
  disabled,
  readOnly,
}: DisabledAndReadOnlyProps) => (
  <Field
    name="shiftsEmploymentDegreeFactor"
    fullWidth
    size="small"
    type="number"
    InputProps={{ readOnly }}
    disabled={disabled}
  />
);

const ShiftsEmploymentDegreeLogic = ({
  disabled,
  readOnly,
}: DisabledAndReadOnlyProps) => (
  <ShiftScalingLogicSelect
    name="shiftsEmploymentDegreeLogic"
    readOnly={readOnly}
    disabled={disabled}
  />
);

export const ShiftsEmploymentDegreeFactorAndLogic = ({
  disabled,
  readOnly,
}: DisabledAndReadOnlyProps) => (
  <Stack direction="row" width="100%" columnGap={1}>
    <ShiftsEmploymentDegreeFactor disabled={disabled} readOnly={readOnly} />
    <ShiftsEmploymentDegreeLogic disabled={disabled} readOnly={readOnly} />
  </Stack>
);

export const Graph = ({ graphData }: { graphData: GraphData }) => (
  <ExampleBox sx={{ flexGrow: 2 }}>
    {PartTimeGraph(graphData, "shifts")}
  </ExampleBox>
);

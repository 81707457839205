/**
 * @generated SignedSource<<a259cd0586d62dd2fa00935a1e4f1c70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlockedTimeSlotQuery$variables = {
  id: string;
};
export type BlockedTimeSlotQuery$data = {
  readonly currentPeriod: {
    readonly endDate: string;
    readonly startDate: string;
  } | null;
  readonly holidays: ReadonlyArray<{
    readonly endTime: string;
    readonly itemProps: {
      readonly className: string;
    };
    readonly startTime: string;
    readonly title: string;
  } | null> | null;
  readonly teams: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TeamSelect2_fragment">;
  }>;
  readonly usersOnTeamGroup: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"BlockedTimeSlotTimeline_fragment" | "BlockedTimeSlotUserSelect_fragment" | "UserSelect_fragment">;
  }>;
};
export type BlockedTimeSlotQuery = {
  response: BlockedTimeSlotQuery$data;
  variables: BlockedTimeSlotQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "groupId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "HolidayNode",
  "kind": "LinkedField",
  "name": "holidays",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemProps",
      "kind": "LinkedField",
      "name": "itemProps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "className",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v9 = [
  (v6/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockedTimeSlotQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "currentPeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "usersOnTeamGroup",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BlockedTimeSlotTimeline_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BlockedTimeSlotUserSelect_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSelect_fragment"
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamSelect2_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlockedTimeSlotQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "currentPeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "usersOnTeamGroup",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BlockedTimeSlotNodeConnection",
            "kind": "LinkedField",
            "name": "blockedTimeSlots",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BlockedTimeSlotNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BlockedTimeSlotNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeslotType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "approvedByAdmin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShiftNode",
                        "kind": "LinkedField",
                        "name": "shift",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shiftDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recurrences",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNodeConnection",
                        "kind": "LinkedField",
                        "name": "users",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v9/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "memberOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamGroupNode",
                        "kind": "LinkedField",
                        "name": "group",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15c28dd1a9fade8ab23ad7df0319de49",
    "id": null,
    "metadata": {},
    "name": "BlockedTimeSlotQuery",
    "operationKind": "query",
    "text": "query BlockedTimeSlotQuery(\n  $id: ID!\n) {\n  currentPeriod(groupId: $id) {\n    startDate\n    endDate\n    id\n  }\n  usersOnTeamGroup(teamGroupId: $id) {\n    ...BlockedTimeSlotTimeline_fragment\n    ...BlockedTimeSlotUserSelect_fragment\n    ...UserSelect_fragment\n    id\n  }\n  holidays {\n    title\n    endTime\n    startTime\n    itemProps {\n      className\n    }\n  }\n  teams {\n    ...TeamSelect2_fragment\n    id\n  }\n}\n\nfragment BlockedTimeSlotTimeline_fragment on UserNode {\n  id\n  fullName\n  blockedTimeSlots {\n    edges {\n      node {\n        id\n        start\n        end\n        timeslotType\n        approvedByAdmin\n        shift {\n          id\n          start\n          end\n        }\n        shiftDate\n        recurrences\n        users {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  memberOf {\n    edges {\n      node {\n        id\n        name\n        group {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment BlockedTimeSlotUserSelect_fragment on UserNode {\n  fullName\n  id\n}\n\nfragment TeamSelect2_fragment on TeamNode {\n  id\n  name\n  color\n  group {\n    id\n    name\n  }\n}\n\nfragment UserSelect_fragment on UserNode {\n  id\n  fullName\n}\n"
  }
};
})();

(node as any).hash = "cc224fbc7d378b06913a9ad38adc75ac";

export default node;

/**
 * @generated SignedSource<<8ed863db60bdfc2f40e3d3adfda31af1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiBlockedTimeSlotTimeslotTypeChoices = "K" | "O" | "V" | "W" | "%future added value";
export type EditBlockedTimeSlotInput = {
  approvedByAdmin: boolean;
  clientMutationId?: string | null;
  end?: string | null;
  id: string;
  recurrences?: string | null;
  shiftDate?: string | null;
  shiftId?: string | null;
  start?: string | null;
  timeslotType: ApiBlockedTimeSlotTimeslotTypeChoices;
  userIds: ReadonlyArray<string | null>;
};
export type EditBlockedTimeSlotMutation$variables = {
  input: EditBlockedTimeSlotInput;
};
export type EditBlockedTimeSlotMutation$data = {
  readonly editBlockedTimeSlot: {
    readonly blockedTimeSlot: {
      readonly " $fragmentSpreads": FragmentRefs<"EditBlockedTimeSlot_fragment">;
    } | null;
    readonly ok: boolean | null;
  } | null;
};
export type EditBlockedTimeSlotMutation = {
  response: EditBlockedTimeSlotMutation$data;
  variables: EditBlockedTimeSlotMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditBlockedTimeSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditBlockedTimeSlotPayload",
        "kind": "LinkedField",
        "name": "editBlockedTimeSlot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BlockedTimeSlotNode",
            "kind": "LinkedField",
            "name": "blockedTimeSlot",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditBlockedTimeSlot_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditBlockedTimeSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditBlockedTimeSlotPayload",
        "kind": "LinkedField",
        "name": "editBlockedTimeSlot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BlockedTimeSlotNode",
            "kind": "LinkedField",
            "name": "blockedTimeSlot",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeslotType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvedByAdmin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recurrences",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShiftNode",
                "kind": "LinkedField",
                "name": "shift",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shiftDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNodeConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f68a552f4037425c4777bd60c97c7fd4",
    "id": null,
    "metadata": {},
    "name": "EditBlockedTimeSlotMutation",
    "operationKind": "mutation",
    "text": "mutation EditBlockedTimeSlotMutation(\n  $input: EditBlockedTimeSlotInput!\n) {\n  editBlockedTimeSlot(input: $input) {\n    ok\n    blockedTimeSlot {\n      ...EditBlockedTimeSlot_fragment\n      id\n    }\n  }\n}\n\nfragment EditBlockedTimeSlot_fragment on BlockedTimeSlotNode {\n  id\n  start\n  end\n  timeslotType\n  approvedByAdmin\n  recurrences\n  shift {\n    id\n  }\n  shiftDate\n  users {\n    edges {\n      node {\n        id\n        fullName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ada47f9ce5d5f8d95a018efbdfbfcbf7";

export default node;

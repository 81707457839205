import { useCallback } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FieldArray } from "formik";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import { DemandWeightSelect } from "components/common/selectors/DemandWeightSelect";
import { FormikSmallSwitch } from "components/common/Switch";
import { useDemandForm } from "components/demand/form/DemandFormContext";
import CustomField from "components/forms/CustomField";

import { CreateDemandsInput as FormValues, DemandDaysInput } from "../types";

function TableHeader() {
  const { showDutyDemand, showDemandWeights } = useDemandForm();
  return (
    <TableHead>
      <TableRow>
        <TableCell width={100}>Start</TableCell>
        <TableCell width={100}>Slut</TableCell>
        <TableCell>Behov</TableCell>
        {showDutyDemand && <TableCell>Varav sovande jour</TableCell>}
        {showDemandWeights && (
          <>
            <TableCell>Minimera underbemanning</TableCell>
            <TableCell>Minimera spilltid</TableCell>
          </>
        )}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

type DemandTableRowProps = {
  name: string;
  onRemove: () => void;
};

function DemandTableRow({ name, onRemove }: DemandTableRowProps) {
  const getName = useCallback((k: string) => `${name}.${k}`, [name]);
  const { showDutyDemand, showDemandWeights } = useDemandForm();
  const { value: enforceDuty = false } = useFormikState<boolean>(
    getName("enforceDuty"),
  );

  return (
    <TableRow>
      <TableCell width={100}>
        <CustomField
          type="time"
          name={getName("start")}
          sx={{ minWidth: 100 }}
        />
      </TableCell>
      <TableCell width={100}>
        <CustomField type="time" name={getName("end")} sx={{ minWidth: 100 }} />
      </TableCell>
      <TableCell>
        <CustomField
          type="number"
          name={getName("demand")}
          sx={{ minWidth: 138 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">person(er)</InputAdornment>
            ),
          }}
        />
      </TableCell>
      {showDutyDemand && (
        <TableCell>
          <Stack direction="row" gap={2} alignItems="center">
            <FormikSmallSwitch name={getName("enforceDuty")} />
            <CustomField
              type="number"
              name={getName("dutyShare")}
              disabled={!enforceDuty}
              sx={{ minWidth: 138 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">person(er)</InputAdornment>
                ),
              }}
            />
          </Stack>
        </TableCell>
      )}
      {showDemandWeights && (
        <>
          <TableCell>
            <DemandWeightSelect
              name={getName("minimizeUnderstaffingWeight")}
              sx={{ width: 170 }}
            />
          </TableCell>
          <TableCell>
            <DemandWeightSelect
              name={getName("minimizeOverstaffingWeight")}
              sx={{ width: 170 }}
            />
          </TableCell>
        </>
      )}
      <TableCell align="right">
        <IconButton
          onClick={onRemove}
          color="primary"
          size="small"
          sx={{ p: 0 }}
        >
          <Stack alignItems="center">
            <DeleteIcon />
            <Typography variant="caption">Radera</Typography>
          </Stack>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

type DemandTableProps = {
  name: string;
};

export default function DemandTable({ name }: DemandTableProps) {
  const { value: demandGroup } = useFormikState<DemandDaysInput>(name);
  const getName = useCallback((k: string) => `${name}.${k}`, [name]);
  return (
    <TableContainer>
      <Table size="small">
        {demandGroup?.demands?.length ? <TableHeader /> : null}
        <TableBody>
          <FieldArray name={getName("demands")}>
            {({ remove }: FormikFieldArrayFn<FormValues>) =>
              (demandGroup?.demands || []).map((_, i) => (
                <DemandTableRow
                  key={`behov-${i}`}
                  name={getName(`demands.${i}`)}
                  onRemove={() => remove(i)}
                />
              ))
            }
          </FieldArray>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import type { TypographyVariantsOptions } from "@mui/material";

export const typography: TypographyVariantsOptions = {
  fontFamily: `"Nunito", Helvetica, Arial, sans-serif`,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: 23,
    fontWeight: 500,
  },
  h2: {
    fontSize: 20,
    fontWeight: 500,
  },
  h3: {
    fontSize: 18,
    fontWeight: 600,
  },
  h4: {
    fontSize: 16,
    fontWeight: 500,
  },
  h5: {
    fontSize: 14,
    fontWeight: 700,
  },
  h6: {
    fontSize: 13,
  },
  body1: {
    fontSize: 14,
  },
  body2: {
    fontSize: 13,
  },
  caption: {
    fontSize: 12,
  },
  subtitle1: {
    fontWeight: 28,
  },
};

/**
 * @generated SignedSource<<7747a4502484aa90bd0415d6c7cfb568>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiUserEmploymentFormChoices = "H" | "P" | "S" | "%future added value";
export type ApiUserEmploymentTitleChoices = "A" | "C" | "N" | "S" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberList_fragment$data = ReadonlyArray<{
  readonly competences: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly color: string;
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly email: string;
  readonly employmentDegree: number;
  readonly employmentForm: ApiUserEmploymentFormChoices;
  readonly employmentTitle: ApiUserEmploymentTitleChoices;
  readonly firstName: string;
  readonly fullName: string;
  readonly id: string;
  readonly lastName: string;
  readonly memberOf: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly color: string;
        readonly id: string;
        readonly name: string;
      } | null;
    } | null>;
  };
  readonly resolvedSettings: {
    readonly adminTimeSettings: {
      readonly adminHoursMax: number | null;
      readonly adminHoursMin: number | null;
      readonly restrictAdminTime: boolean;
    };
    readonly hourlyCost: number;
    readonly hoursPerPeriod: number;
    readonly resourceTimeSettings: {
      readonly resourceHoursMax: number | null;
      readonly resourceHoursMin: number | null;
      readonly restrictResourceTime: boolean;
    };
    readonly shiftsPerPeriod: number;
  } | null;
  readonly ruleGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly teamGroup: {
          readonly id: string;
        };
      } | null;
    } | null>;
  };
  readonly timebankBaseBalance: number;
  readonly userSetting: {
    readonly settingModules: ReadonlyArray<string>;
  } | null;
  readonly " $fragmentType": "MemberList_fragment";
}>;
export type MemberList_fragment$key = ReadonlyArray<{
  readonly " $data"?: MemberList_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberList_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "color",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "teamGroupId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MemberList_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentDegree",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentForm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroupNodeConnection",
      "kind": "LinkedField",
      "name": "ruleGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RuleGroupNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RuleGroupNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamGroupNode",
                  "kind": "LinkedField",
                  "name": "teamGroup",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "teamGroupId",
          "variableName": "teamGroupId"
        }
      ],
      "concreteType": "ResolvedSettings",
      "kind": "LinkedField",
      "name": "resolvedSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftsPerPeriod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hoursPerPeriod",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hourlyCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminTimeSettings",
          "kind": "LinkedField",
          "name": "adminTimeSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "restrictAdminTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "adminHoursMin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "adminHoursMax",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceTimeSettings",
          "kind": "LinkedField",
          "name": "resourceTimeSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "restrictResourceTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "resourceHoursMin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "resourceHoursMax",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CompetenceNodeConnection",
      "kind": "LinkedField",
      "name": "competences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetenceNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timebankBaseBalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSettingNode",
      "kind": "LinkedField",
      "name": "userSetting",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settingModules",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamNodeConnection",
      "kind": "LinkedField",
      "name": "memberOf",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "808859f68df162419e435c0f0e8f0d79";

export default node;

import type { CSSObject } from "@emotion/react";
import { CircularProgress, Stack, Typography as Text } from "@mui/material";

type Props = {
  label?: string;
  sx?: CSSObject;
};

export default function Loading({ label, sx = {} }: Props) {
  return (
    <Stack sx={sx}>
      <CircularProgress />
      {!!label && <Text>{label}</Text>}
    </Stack>
  );
}

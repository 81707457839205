/**
 * @generated SignedSource<<3a70d572790bb64d479c0cf17bff573d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateMeetingForm_setting$data = {
  readonly periodLengthWeeks: number;
  readonly " $fragmentSpreads": FragmentRefs<"MeetingForm_setting">;
  readonly " $fragmentType": "CreateMeetingForm_setting";
};
export type CreateMeetingForm_setting$key = {
  readonly " $data"?: CreateMeetingForm_setting$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateMeetingForm_setting">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateMeetingForm_setting",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MeetingForm_setting"
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "02384e337d0b1221c571f9eb637491be";

export default node;

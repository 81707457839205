import { useCallback } from "react";
import type { TableContainerProps } from "@mui/material";
import { Table, TableBody, TableContainer } from "@mui/material";
import { FieldArray } from "formik";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import type { CreateShiftsInput, ShiftInput } from "../types";

import { ShiftTableHead } from "./ShiftTableHead";
import { ShiftTableRow } from "./ShiftTableRow";

type FieldArrayProps = FormikFieldArrayFn<
  Omit<CreateShiftsInput, "clientMutationId">,
  ShiftInput[]
>;

type Props = TableContainerProps & {
  name: string;
};
type RendererProps = TableContainerProps & {
  header: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
};

export default function ShiftTable({ name, ...props }: Props) {
  const { value: shifts } = useFormikState<ShiftInput[]>(name);

  const renderRows = useCallback(
    ({ remove }: FieldArrayProps) =>
      (shifts || []).map((_, i) => (
        <ShiftTableRow
          key={i}
          name={`${name}.${i}`}
          onRemove={() => remove(i)}
        />
      )),
    [name, shifts],
  );

  return (
    <ShiftTableRenderer
      header={shifts?.length ? <ShiftTableHead /> : null}
      body={<FieldArray name={name}>{renderRows}</FieldArray>}
      {...props}
    />
  );
}

export function ShiftTableRenderer({
  header,
  body,
  footer,
  ...props
}: RendererProps) {
  return (
    <TableContainer {...props}>
      <Table size="small">
        {header}
        <TableBody>{body}</TableBody>
      </Table>
      {footer}
    </TableContainer>
  );
}

import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const Types1InfoTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Delade turer</b>?
  </InfoTitle>
);

const Types1InfoContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export function Types1Info() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal title={<Types1InfoTitle />}>
        <Types1InfoContent />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

import { useMemo } from "react";
import useFormikState from "hooks/useFormikState";

export default function useShiftNumberDistribution(nrShifts: number) {
  const { value: day } = useFormikState("dayShiftDistributionShare");
  const { value: evening } = useFormikState("eveningShiftDistributionShare");
  const { value: night } = useFormikState("nightShiftDistributionShare");
  const { value: fullDay } = useFormikState("fullDayShiftDistributionShare");

  const percentages = useMemo(() => {
    let totalShifts = nrShifts;
    const results = [
      { id: "day", value: Number(day), shifts: 0 },
      { id: "evening", value: Number(evening), shifts: 0 },
      { id: "night", value: Number(night), shifts: 0 },
      { id: "fullday", value: Number(fullDay), shifts: 0 },
    ].sort((a, b) => b.value - a.value);

    for (let i = 0; i < results.length; i++) {
      const remaining = results
        .slice(i)
        .reduce((acc, curr) => acc + curr.value, 0);
      results[i].shifts =
        remaining === 0
          ? 0
          : Math.round((totalShifts * results[i].value) / remaining);
      totalShifts -= results[i].shifts;
    }
    return results;
  }, [nrShifts, day, evening, night, fullDay]);

  return percentages;
}

/**
 * @generated SignedSource<<fdf27cd44899e5c530ea5456a2b1657a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingDayTypeFairnessWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DayNightFairnessSettingTeamGroup_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly dayTypeFairnessWeight: ApiSettingDayTypeFairnessWeightChoices;
  readonly id: string;
  readonly optimizeDayTypeFairness: boolean;
  readonly optimizeDayTypeFairnessWeekend: boolean;
  readonly " $fragmentType": "DayNightFairnessSettingTeamGroup_fragment";
};
export type DayNightFairnessSettingTeamGroup_fragment$key = {
  readonly " $data"?: DayNightFairnessSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DayNightFairnessSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DayNightFairnessSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeDayTypeFairness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeDayTypeFairnessWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayTypeFairnessWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "ea2e5fc860733b61bd3cb64669f69dfb";

export default node;

import graphql from "babel-plugin-relay/macro";
import { connectionToArray, ExtractNode } from "relay-help/arrays";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultTeamGroupSubmitFunction,
} from "settings/common/optimizationSetting";
import { WeightTeamGroupOnlyForm } from "settings/common/TeamGroupOnlyForms";
import { NN } from "types";
import * as yup from "yup";

import { CadenceRestrictionType } from "components/setting/common/forms";
import { dayTypeDistributionWeightChoices } from "components/setting/team_group/setting_boxes/constants";
import { shiftDayTypeChoices } from "components/shifts/types";

import {
  ApiSettingDayTypeCadenceWeightChoices,
  DayNightCadenceSettingTeamGroup_fragment$data as TeamGroupData,
  DayNightCadenceSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/DayNightCadenceSettingTeamGroup_fragment.graphql";
import {
  DayNightCadenceSettingUserSetting_fragment$data as UserSettingData,
  DayNightCadenceSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/DayNightCadenceSettingUserSetting_fragment.graphql";
import { DayNightCadence } from "./DayNightCadenceForm";
import { DayNightCadenceInfo } from "./DayNightCadenceInfo";

const teamGroupFragment = graphql`
  fragment DayNightCadenceSettingTeamGroup_fragment on SettingNode {
    id
    cadenceRestrictions {
      edges {
        node {
          id
          dayType
          maxCadence
          calendarDays
          isSoft
        }
      }
    }
    dayTypeCadenceWeight
    constraintModules
  }
`;
const userSettingFragment = graphql`
  fragment DayNightCadenceSettingUserSetting_fragment on UserSettingNode {
    id
    dayTypeCadenceRestrictions {
      edges {
        node {
          id
          dayType
          maxCadence
          calendarDays
          isSoft
        }
      }
    }
    settingModules
  }
`;

const SETTING_NAME = "Dag-/Kväll-/Natt-kadens";
const MODULE_NAME = "ShiftDayTypeCadence";
const SETTING_URL_ID = "day-eve-night-cadence";

type ShiftTypeCadenceRestrictions = ExtractNode<
  NN<TeamGroupData["cadenceRestrictions"]>
>;
type ShiftTypeCadenceFormValues = {
  id: string;
  cadenceRestrictions: ReadonlyArray<ShiftTypeCadenceRestrictions>;
  dayTypeCadenceWeight?: ApiSettingDayTypeCadenceWeightChoices;
  readonly constraintModules: readonly string[];
};

type ShiftTypeCadenceTeamGroupOnlyFormValues = {
  id: string;
  dayTypeCadenceWeight: ApiSettingDayTypeCadenceWeightChoices;
  readonly constraintModules: readonly string[];
};

const cadenceRestrictionSchema = yup
  .object()
  .shape({
    id: yup.string(),
    dayType: yup
      .string()
      .oneOf(shiftDayTypeChoices)
      .required("Får ej vara tomt"),
    maxCadence: yup
      .number()
      .min(1, "Måste vara minst 1")
      .max(20, "Måste vara högst 20")
      .required("Får ej vara tomt"),
    calendarDays: yup
      .number()
      .min(1, "Måste vara minst 1")
      .max(30, "Måste vara högst 30")
      .required("Får ej vara tomt"),
    isSoft: yup.boolean().required("Får ej vara tomt"),
  })
  .test(
    "calendarDays-less-than-maxCadence",
    "maxkadens måste vara mindre än kalenderdagarna",
    (schema: CadenceRestrictionType, ctx: any) => {
      const { calendarDays, maxCadence } = schema;
      if (calendarDays < maxCadence + 1) {
        return ctx.createError({
          message: "Måste vara mindre än kalenderdagarna",
          path: `${ctx.path}.maxCadence`,
        });
      }
      return true;
    },
  );

const validationSchema = yup.object().shape({
  id: yup.string(),
  cadenceRestrictions: yup.array().of(cadenceRestrictionSchema),
  constraintModules: yup.array().of(yup.string()).required(),
});

const teamGroupValidationSchema = yup.object().shape({
  dayTypeCadenceWeight: yup
    .string()
    .oneOf(dayTypeDistributionWeightChoices)
    .required(),
});

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): ShiftTypeCadenceFormValues {
  return {
    id: teamGroupData.id,
    cadenceRestrictions: connectionToArray<ShiftTypeCadenceRestrictions>(
      teamGroupData.cadenceRestrictions,
    ),
    constraintModules: teamGroupData.constraintModules,
  };
}

function convertUserDataToInitialValues(
  userSettingData: UserSettingData,
): ShiftTypeCadenceFormValues {
  return {
    id: userSettingData?.id,
    cadenceRestrictions: connectionToArray<ShiftTypeCadenceRestrictions>(
      userSettingData.dayTypeCadenceRestrictions,
    ),
    constraintModules: userSettingData.settingModules,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): ShiftTypeCadenceTeamGroupOnlyFormValues {
  return {
    id: teamGroupData.id,
    dayTypeCadenceWeight: teamGroupData.dayTypeCadenceWeight,
    constraintModules: teamGroupData.constraintModules,
  };
}

function renderComponent(
  initialValues: ShiftTypeCadenceFormValues,
  onSubmit: (formValues: ShiftTypeCadenceFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <DayNightCadence />
    </BaseSettings>
  );
}

function renderTeamGroupComponent(
  initialValues: ShiftTypeCadenceTeamGroupOnlyFormValues,
  onSubmit: (formValues: ShiftTypeCadenceTeamGroupOnlyFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <WeightTeamGroupOnlyForm
        weightFieldName="dayTypeCadenceWeight"
        moduleName={MODULE_NAME}
      />
    </BaseSettings>
  );
}

export const dayNightCadenceSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  undefined,
  ShiftTypeCadenceFormValues,
  {},
  ShiftTypeCadenceTeamGroupOnlyFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
    renderComponent: renderTeamGroupComponent,
  },
  InfoComponent: DayNightCadenceInfo,
};

import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";

import environment from "../../../environment";

import { DeleteBlockedTimeSlotMutation } from "./__generated__/DeleteBlockedTimeSlotMutation.graphql";

const mutation = graphql`
  mutation DeleteBlockedTimeSlotMutation($id: ID!) {
    deleteBlockedTimeSlot(id: $id) {
      ok
      deletedId
    }
  }
`;

export async function DeleteBlockedTimeSlot(id: string) {
  return commitMutation<DeleteBlockedTimeSlotMutation>(environment, {
    mutation,
    variables: { id },
    configs: [{ type: "NODE_DELETE", deletedIDFieldName: "deletedId" }],
  }).then((response) => {
    if (!response.deleteBlockedTimeSlot?.ok) {
      return Promise.reject("Could not delete time slot.");
    }

    return response;
  });
}

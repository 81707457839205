import { Suspense, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { Dialog, DialogTitle, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Formik } from "formik";
import { DateTime } from "luxon";

import { useSnackbar } from "components/Snackbar";
import { DraggablePaper } from "components/utils/DraggablePaper";

import BlockedTimeSlotForm, {
  validationSchema,
} from "../common/BlockedTimeSlotForm";
import { prepareBeforeForm, prepareBeforeSubmit } from "../common/Recurrence";
import type { RecurrenceRuleType } from "../common/Recurrence/types";
import { TranslateBlockedTimeSlotType } from "../types";

import type { EditBlockedTimeSlot_fragment$key as Key } from "./__generated__/EditBlockedTimeSlot_fragment.graphql";
import { EditBlockedTimeSlotQuery } from "./__generated__/EditBlockedTimeSlotQuery.graphql";
import { useEditBlockedTimeSlot } from "./EditBlockedTimeSlotMutation";
import type {
  BlockedTimeSlotType,
  EditBlockedTimeSlotInput as MutationInput,
} from "./types";

type Props = {
  open: boolean;
  selected: string | null;
  onClose: () => void;
};
type WithQueryRef<P> = P & {
  queryRef: PreloadedQuery<EditBlockedTimeSlotQuery>;
};

type FormValues = Omit<MutationInput, "recurrences"> & {
  recurrences: RecurrenceRuleType[] | null;
};

const fragment = graphql`
  fragment EditBlockedTimeSlot_fragment on BlockedTimeSlotNode {
    id
    start
    end
    timeslotType
    approvedByAdmin
    recurrences
    shift {
      id
    }
    shiftDate
    users {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

const query = graphql`
  query EditBlockedTimeSlotQuery($id: ID!) {
    blockedTimeSlot(id: $id) {
      ...EditBlockedTimeSlot_fragment
    }
  }
`;

const dtFormat = "yyyy-MM-dd'T'T";

function parseTimeSlotTime(time: string | null | undefined) {
  if (time == null) {
    return null;
  }
  return DateTime.fromISO(time).toFormat(dtFormat);
}

function Content({ queryRef, selected, open, onClose }: WithQueryRef<Props>) {
  const id = selected ?? "";
  const { addSnack } = useSnackbar();
  const data = usePreloadedQuery<EditBlockedTimeSlotQuery>(query, queryRef);
  const blockedTimeSlot = useFragment<Key>(fragment, data.blockedTimeSlot);
  const [commit] = useEditBlockedTimeSlot();

  const initialValues: FormValues = {
    id,
    start: parseTimeSlotTime(blockedTimeSlot?.start),
    end: parseTimeSlotTime(blockedTimeSlot?.end),
    approvedByAdmin: blockedTimeSlot?.approvedByAdmin || false,
    userIds: (blockedTimeSlot?.users?.edges || []).map(
      (u) => u?.node?.id || null,
    ),
    timeslotType: (blockedTimeSlot?.timeslotType as BlockedTimeSlotType) || "",
    shiftId: blockedTimeSlot?.shift?.id,
    shiftDate: blockedTimeSlot?.shiftDate,
    recurrences: blockedTimeSlot?.recurrences
      ? prepareBeforeForm(blockedTimeSlot.recurrences)
      : null,
  };

  async function onSubmit(values: FormValues) {
    try {
      const {
        start,
        end,
        shiftId,
        shiftDate,
        recurrences,
        approvedByAdmin,
        timeslotType,
        userIds,
      } = prepareBeforeSubmit({ ...values });
      const input = {
        id: values.id,
        timeslotType,
        start,
        end,
        shiftId,
        shiftDate,
        recurrences,
        approvedByAdmin,
        userIds,
      };
      await commit({
        variables: { input },
      })
        .then(() => {
          onClose();
          addSnack({ message: "Ändringar sparade", severity: "success" });
        })
        .catch((error) => {
          console.error(error);
          addSnack({ message: "Något gick fel", severity: "error" });
        });
    } catch (e) {
      console.error(e);
    }
  }

  function FormTitle() {
    const type = TranslateBlockedTimeSlotType(blockedTimeSlot?.timeslotType);
    const numUsers = blockedTimeSlot?.users?.edges?.length;
    const formFor =
      numUsers !== 1
        ? `${numUsers || 0} användare`
        : blockedTimeSlot?.users?.edges?.[0]?.node?.fullName;
    return (
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Typography variant="h2">
          Redigera <strong>{type}</strong> för <strong>{formFor}</strong>
        </Typography>
      </DialogTitle>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperComponent={DraggablePaper}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <FormTitle />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <BlockedTimeSlotForm
          submitText="Spara ändringar"
          modalProps={{ open, onClose }}
          deletable={true}
          id={id}
        />
      </Formik>
    </Dialog>
  );
}

export function EditBlockedTimeSlot(props: Props) {
  const id = props.selected;
  const [queryRef, loadQuery, disposeQuery] =
    useQueryLoader<EditBlockedTimeSlotQuery>(query);

  useEffect(() => {
    if (id != null) {
      loadQuery({ id });
    } else {
      disposeQuery();
    }
  }, [id, loadQuery, disposeQuery]);

  return (
    <Suspense fallback={null}>
      {!!queryRef && <Content {...props} queryRef={queryRef} />}
    </Suspense>
  );
}

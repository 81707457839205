import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { Header } from "./Header";
import { NightRestrictionField } from "./NightRestrictionField";
import { NightRotationField } from "./NightRotationField";

const MODULE_NAME = "NightShifts";

type Props = { readOnly?: boolean };

export function NightShiftField({ readOnly }: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <Stack gap={1}>
      <Header
        moduleName={MODULE_NAME}
        readOnly={readOnly}
        disabled={disabled}
      />
      <Stack gap={5}>
        <NightRestrictionField disabled={disabled} readOnly={readOnly} />
        <NightRotationField disabled={disabled} readOnly={readOnly} />
      </Stack>
    </Stack>
  );
}

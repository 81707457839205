import { useMemo } from "react";
import { useField } from "formik";

import { activityList, TranslateActivityType } from "components/schedule/types";

import GenericSelect from "./GenericSelect";

type ActivityTypeSelectProps = {
  name: string;
  label?: string;
};

type Opt = {
  value: string;
  label: string;
};

const options: Opt[] = activityList.map((u) => ({
  value: u,
  label: TranslateActivityType(u) || "",
}));

const ActivityTypeSelect = ({ name, label }: ActivityTypeSelectProps) => {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const opts = useMemo(() => options.map((o) => o.value), []);

  function onChange(_event: any, newValue: string | null) {
    setValue(newValue);
  }

  return (
    <GenericSelect
      options={opts}
      value={value}
      onChange={onChange}
      label={label}
      placeholder="Välj händelsetyp..."
      getOptionLabel={(option) =>
        options.find((o) => o.value === option)?.label || ""
      }
    />
  );
};

export default ActivityTypeSelect;

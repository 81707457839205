import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { MenuItem, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import type { MRT_ColumnDef } from "material-react-table";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";

import ColorDot from "components/common/ColorDot";
import MRTable from "components/common/MRTable";

import type {
  TeamsList_teams$data as Data,
  TeamsList_teams$key as Key,
} from "./types";

type Props = {
  fragmentRef: Key;
  setEdit: (id: string | null) => void;
  onDelete: (team: { id: string; name: string; members: [] }) => void;
};

type Team = Unwrap<Data>;
type Member = ExtractNode<Team["members"]>;
type LocalTeam = Pick<Team, "name" | "color"> & {
  readonly members: ReadonlyArray<Member>;
};
type ContentProps = {
  setEdit?: Props["setEdit"];
  teams: ReadonlyArray<LocalTeam>;
  hideActions?: boolean;
  onDelete?: Props["onDelete"];
};

const fragment = graphql`
  fragment TeamsList_teams on TeamNode @relay(plural: true) {
    id
    name
    color
    members {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export function TeamsListContent({
  teams,
  onDelete,
  setEdit,
  hideActions,
}: ContentProps) {
  const columns = useMemo<MRT_ColumnDef<LocalTeam>[]>(
    () => [
      {
        header: "Namn",
        accessorKey: "name",
        Cell: ({ cell }) => <Typography>{cell.getValue<string>()}</Typography>,
      },
      {
        header: "Färg",
        accessorKey: "color",
        Cell: ({ cell }) => <ColorDot color={cell.getValue<string>()} />,
      },
      {
        header: "Antal medlemmar",
        id: "memberCount",
        accessorFn: (team) => team.members.length,
        Cell: ({ cell }) => <Typography>{cell.getValue<number>()}</Typography>,
      },
    ],
    [],
  );

  const renderRowActionMenuItems = useCallback(
    ({ row }: any) => [
      <MenuItem key="edit" onClick={() => setEdit?.(row.original.id)}>
        Redigera
      </MenuItem>,
      <MenuItem
        key="delete"
        onClick={() => onDelete?.(row.original)}
        sx={{ bgcolor: "error.main" }}
      >
        Radera
      </MenuItem>,
    ],
    [setEdit, onDelete],
  );

  return (
    <MRTable
      columns={columns}
      data={teams}
      renderRowActionMenuItems={renderRowActionMenuItems}
      positionActionsColumn="last"
      enableRowActions={!hideActions}
    />
  );
}

export function TeamsList({ fragmentRef, setEdit, onDelete }: Props) {
  const teams = useFragment<Key>(fragment, fragmentRef);
  const formattedTeams = useMemo(
    () =>
      teams.map((team) => ({
        ...team,
        members: connectionToArray(team.members),
      })),
    [teams],
  );

  return (
    <TeamsListContent
      teams={formattedTeams}
      setEdit={setEdit}
      onDelete={onDelete}
    />
  );
}

import { Paper, Typography } from "@mui/material";
import { SolutionStatus } from "types/Schedule";

const statisticsSx = {
  p: 4,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  height: 150,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

type ErrorOrRunningProps = {
  solutionStatus: string;
};

export function ErrorOrRunning({ solutionStatus }: ErrorOrRunningProps) {
  return (
    <Paper variant="box" sx={statisticsSx}>
      <Typography fontStyle="italic">
        {solutionStatus === SolutionStatus.RUNNING
          ? "Optimering pågår..."
          : "Ingen statistik att visa"}
      </Typography>
    </Paper>
  );
}

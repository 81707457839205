import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import { CreateShiftsMutation as Mutation } from "./__generated__/CreateShiftsMutation.graphql";

const mutation = graphql`
  mutation CreateShiftsMutation($input: CreateShiftsInput!) {
    createShifts(input: $input) {
      ok
    }
  }
`;

export const useCreateShifts = () => useMutation<Mutation>(mutation);

/**
 * @generated SignedSource<<05ee3d20fe58132afd62d1540f144ad3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConstraintModuleSwitch_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly " $fragmentType": "ConstraintModuleSwitch_fragment";
};
export type ConstraintModuleSwitch_fragment$key = {
  readonly " $data"?: ConstraintModuleSwitch_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConstraintModuleSwitch_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConstraintModuleSwitch_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "f9b60a4c0e925939fe77dfb317de714c";

export default node;

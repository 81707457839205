/**
 * @generated SignedSource<<3b5fc3a187579b75dbd83cefa99c0478>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamSelect_fragment$data = ReadonlyArray<{
  readonly color: string;
  readonly group: {
    readonly id: string;
    readonly name: string;
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "TeamSelect_fragment";
}>;
export type TeamSelect_fragment$key = ReadonlyArray<{
  readonly " $data"?: TeamSelect_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamSelect_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TeamSelect_fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamGroupNode",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "TeamNode",
  "abstractKey": null
};
})();

(node as any).hash = "f4b447cf37a4ffaf081462b9cd8f15de";

export default node;

import {
  CheckCircle as Enabled,
  RemoveCircle as Disabled,
} from "@mui/icons-material";

type Props = { active: boolean };

export function Level2Icon({ active }: Props) {
  return active ? (
    <Enabled aria-hidden="true" sx={{ fontSize: 18, color: "primary.light" }} />
  ) : (
    <Disabled aria-hidden="true" sx={{ fontSize: 18, color: "svart.40" }} />
  );
}

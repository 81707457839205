import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Settings } from "luxon";
import moment from "moment";

import "moment/locale/sv";
import "./i18n";

import App from "./App";

import "./styles/index.scss";
//import "./styles/index.css";

moment.locale("sv");

Settings.defaultLocale = "sv-SE";
Sentry.init({
  release: process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV,
  dsn: "https://e51f8b5661fd0051d8e7083f45d18622@o4507904845938688.ingest.de.sentry.io/4507913364373584",
  enabled: process.env.NODE_ENV === "production",
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

import type { CSSObject, SelectChangeEvent } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { UnreachableCaseError } from "ts-essentials";

import type { ApiSettingMinDailyRestWeightChoices } from "components/setting/team_group/setting_boxes/types";

type Props = {
  value: any;
  handleChange: any;
  disabled?: boolean;
  readOnly?: boolean;
  sx?: CSSObject;
  label?: string;
};

type Option = {
  value: ApiSettingMinDailyRestWeightChoices;
  label: string;
};

const TranslateWeightType = (
  type: ApiSettingMinDailyRestWeightChoices | undefined,
): string | undefined => {
  switch (type) {
    case "L":
      return "Låg";
    case "M":
      return "Mellan";
    case "H":
      return "Hög";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

const weightOptions: ApiSettingMinDailyRestWeightChoices[] = ["H", "M", "L"];
const options: Option[] = weightOptions.map((u) => ({
  value: u,
  label: TranslateWeightType(u) || "",
}));

export default function WeightSelect({
  value,
  label,
  handleChange,
  ...props
}: Props) {
  function onChange(e: SelectChangeEvent<ApiSettingMinDailyRestWeightChoices>) {
    handleChange(e.target.value);
  }

  function renderOption(option: Option) {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  return (
    <Select
      value={value}
      label={label}
      onChange={onChange}
      placeholder="Välj prioritet..."
      size="small"
      {...props}
    >
      {options.map(renderOption)}
    </Select>
  );
}

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { Types5A } from "./Types5A";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
  who: React.ReactNode;
  totalShifts?: number;
};

export function Types5({ disabled, readOnly, totalShifts, who }: Props) {
  // Constraint module selector
  const constraintModuleName = "ShiftDayTypeDistribution";
  const { value: constraintModules } = useFormikState("constraintModules");
  const isEnabled = (constraintModules || []).includes(constraintModuleName);

  const isActive = !disabled && isEnabled;

  return (
    <Stack gap={5}>
      <Types5A
        disabled={!isActive}
        readOnly={readOnly}
        totalShifts={totalShifts}
        who={who}
      />
    </Stack>
  );
}

/**
 * @generated SignedSource<<71fc268cb167bc1757a89a4990cc37a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HoursAndShiftsSettingRuleGroup_fragment$data = {
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly shiftsPerWeek: number;
  readonly " $fragmentType": "HoursAndShiftsSettingRuleGroup_fragment";
};
export type HoursAndShiftsSettingRuleGroup_fragment$key = {
  readonly " $data"?: HoursAndShiftsSettingRuleGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HoursAndShiftsSettingRuleGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HoursAndShiftsSettingRuleGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "fb661e9071d9e838f20818121b6908c4";

export default node;

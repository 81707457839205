import type { ComponentProps } from "react";

import { GroupSettingCard } from "./GroupSettingCard";

type Props = ComponentProps<typeof GroupSettingCard>;

const TITLE = "Kompenserande vila";

export function ComprestCard(props: Props) {
  return <GroupSettingCard title={TITLE} {...props} />;
}

ComprestCard.id = "comprest" as const;
ComprestCard.title = TITLE;

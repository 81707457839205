import { useCallback, useEffect, useMemo } from "react";
import type { StackProps } from "@mui/material";
import {
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ErrorMessage, useFormikContext } from "formik";
import { useDebouncedValue, useFormikState } from "hooks";
import { DateTime } from "luxon";
import { useBooleanState } from "utils/useBooleanState";

import { LabeledCheckbox } from "components/common/LabeledCheckbox";

import { FormikDayTabs, useDayTabs } from "../DayTabs";
import type { GraphShift } from "../graphs";
import { ShiftGraph } from "../graphs";
import { hhmm2Dt, START_OF_WEEK, startEndStringToDateTime } from "../utils";

import { useFormShifts, useSuggestAndValidateShifts } from "./hooks";
import { MeetingShiftTable } from "./MeetingShiftTable";
import type { FormShift } from "./types";
import { injectMeetingShiftPart } from "./utils";

type Props = { disabled?: boolean };

export function Shifts({ disabled }: Props) {
  const {
    isEmpty,
    shiftsTouched,
    shifts,
    day,
    setDay,
    isSuggesting,
    showWeek,
    toggleShowWeek,
  } = useShifts();

  if (isEmpty) {
    return (
      <Wrapper>
        <Stack gap={1}>
          <Typography fontSize={16} fontWeight={600}>
            Här är det tomt just nu!
          </Typography>
          <Typography>
            Förslagna passtyper kommer genereras här när all ovanstående data är
            ifylld.
          </Typography>
        </Stack>
        <Typography color="error.main">
          <ErrorMessage name="shifts" />
          {shiftsTouched && isEmpty && (
            <p>
              Måste ha minst ett pass. Försök "Återskapa med aktuell
              passprofil".
            </p>
          )}
        </Typography>
        {isSuggesting && <Loading />}
      </Wrapper>
    );
  }

  return (
    <Wrapper pt={1}>
      <Stack>
        <Stack direction="row-reverse" justifyContent="space-between">
          <LabeledCheckbox
            label="Veckovy"
            value={showWeek}
            onToggle={toggleShowWeek}
            disabled={disabled}
          />
          {!showWeek && (
            <FormikDayTabs
              shiftsName="shifts"
              tab={day}
              setTab={setDay}
              disabled={disabled}
            />
          )}
        </Stack>
        <Divider sx={{ mx: -2 }} />
      </Stack>
      {isSuggesting ? (
        <Loading />
      ) : (
        <>
          <ShiftGraph shifts={shifts} showFullWeek={showWeek} />
          <MeetingShiftTable
            name="shifts"
            day={showWeek ? -1 : day}
            disabled={disabled}
          />
        </>
      )}
    </Wrapper>
  );
}

const Wrapper: React.FC<React.PropsWithChildren & Pick<StackProps, "pt">> = ({
  pt,
  children,
}) => (
  <Stack
    gap={2}
    component={Paper}
    variant="box"
    p={2}
    sx={{ minHeight: 275 }}
    pt={pt}
  >
    {children}
  </Stack>
);

const Loading = () => (
  <CircularProgress sx={{ alignSelf: "center", justifySelf: "center" }} />
);

function useShifts() {
  const [isSuggesting, isValidating] = useSuggestAndValidateShifts();
  const { setFormikState } = useFormikContext();
  const { day, setDay } = useDayTabs();
  const [shiftsIn, , shiftsTouched] = useFormShifts();
  const { value: showWeek, toggle: toggleShowWeek } = useBooleanState();

  const [isSuggestingOrValidating, updatePending] = useDebouncedValue(
    isSuggesting || isValidating,
    250,
  );
  const validationWaiting = isSuggestingOrValidating || updatePending;

  const { value: start } = useFormikState<string>("start");
  const { value: end } = useFormikState<string>("end");
  const startDt = hhmm2Dt(START_OF_WEEK, start || "00:00");
  const isEmpty = shiftsIn.length < 1;

  const injectMeetingShiftPartMapFn = useCallback(
    (s: FormShift): FormShift => {
      // Inject form meeting in shift parts.
      let s2 = injectMeetingShiftPart(s, {
        start,
        end,
      });
      (s.meetings || [])
        .filter((m) => m.start !== start)
        .forEach((m) => {
          // Inject other meetings in shift parts.
          s2 = injectMeetingShiftPart(s2, {
            start: m.start,
            end: m.end,
          });
        });

      return s2;
    },
    [start, end],
  );

  /* Show shifts that are on `day`, or wrap from previous day into `day` */
  const shifts = useMemo(
    () =>
      shiftsIn
        .filter(
          (s) =>
            showWeek ||
            s.meetingDay === day ||
            (s.meetingDay === (day - 1 + 7) % 7 &&
              hhmm2Dt(startDt, s.end) <= hhmm2Dt(startDt, s.start)),
        )
        .map(injectMeetingShiftPartMapFn)
        .map((s) => shiftToGraphShift(s, startDt.plus({ days: s.meetingDay }))),
    [showWeek, day, shiftsIn, startDt, injectMeetingShiftPartMapFn],
  );

  useEffect(() => {
    setFormikState((s) => ({
      ...s,
      isValidating: validationWaiting,
    }));
  }, [setFormikState, validationWaiting]);

  useEffect(() => {
    // select the first day tab when suggested shifts are fetched
    if (isSuggesting) {
      return;
    }
    const daysIn = shiftsIn.map((s) => s.meetingDay);
    setDay(daysIn.length > 0 ? Math.min(...daysIn) : 0);
  }, [isSuggesting]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    day,
    setDay,
    isSuggesting,
    isValidating,
    shifts,
    isEmpty,
    showWeek,
    toggleShowWeek,
    shiftsTouched,
  };
}

function shiftToGraphShift(
  {
    start,
    end,
    meetingDay: day,
    shiftParts,
    meetings,
    ...otherFields
  }: FormShift,
  meetingStart: DateTime,
): GraphShift {
  let [shiftStart, shiftEnd] = startEndStringToDateTime(
    { start, end },
    meetingStart,
    "HH:mm",
  );
  if (shiftStart > meetingStart) {
    shiftStart = shiftStart.minus({ days: 1 });
    shiftEnd = shiftEnd.minus({ days: 1 });
  }

  const parts = shiftParts.map((p) => {
    const [pStart, pEnd] = startEndStringToDateTime(
      p,
      shiftStart,
      "HH:mm",
      true,
    );
    return { ...p, start: pStart.toJSDate(), end: pEnd.toJSDate() };
  });

  return {
    ...otherFields,
    day,
    start: shiftStart.toJSDate(),
    end: shiftEnd.toJSDate(),
    shiftParts: parts,
  };
}

import { Stack, Typography } from "@mui/material";
import { useFormikState } from "hooks";

import { Header } from "./Header";
import { MinDailyRestHours } from "./MinDailyRestHours";

type Props = {
  groupName: string;
  disabled?: boolean;
  readOnly?: boolean;
};

const MODULE_NAME = "DailyRest";

export function RestBetweenShiftsField({
  groupName,
  disabled: moduleDisabled,
  readOnly,
}: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = moduleDisabled || !constraintModules.includes(MODULE_NAME);
  const { value: radioSoft } = useFormikState<boolean>("minDailyRestSoft");

  return (
    <Stack gap={1}>
      <Header
        moduleName={MODULE_NAME}
        groupName={groupName}
        disabled={disabled}
        radioSoft={radioSoft}
        readOnly={readOnly}
      />
      <Typography variant="h4">Vilans längd (timmar)</Typography>
      <Typography>
        Detta fält styr hur lång vilan mellan pass minst ska vara, uttryckt i
        antal timmar.
      </Typography>
      <MinDailyRestHours disabled={disabled} readOnly={readOnly} />
    </Stack>
  );
}

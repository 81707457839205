import { Suspense } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useChangeTitle } from "hooks";

import { PageWrapper } from "components/layout/PageWrapper";
import {
  ChangePasswordPlaceholder,
  EditMePlaceholder,
} from "components/loading/pages";
import { ChangePassword, EditMe, Header } from "components/user_settings";

export function UserSettings() {
  useChangeTitle("Mina inställningar");

  return (
    <PageWrapper header={<Header />}>
      <Stack gap={4} alignItems="left">
        <Stack gap={2}>
          <Typography variant="h3">Ändra användarnamn och e-post</Typography>
          <Box sx={{ width: 400 }}>
            <Suspense fallback={<EditMePlaceholder />}>
              <EditMe />
            </Suspense>
          </Box>
        </Stack>
        <Stack gap={2}>
          <Typography variant="h3">Ändra lösenord</Typography>
          <Box sx={{ width: 400 }}>
            <Suspense fallback={<ChangePasswordPlaceholder />}>
              <ChangePassword />
            </Suspense>
          </Box>
        </Stack>
      </Stack>
    </PageWrapper>
  );
}

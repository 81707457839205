import {
  InputAdornment,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import CustomField from "components/forms/CustomField";

type Props = {
  who: string;
  readOnly?: boolean;
};

export function Worktime1({ who, readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled("AdminTime");

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Typography variant="h4">Begränsa ansvarstid</Typography>
        <Typography>
          Här kan du styra hur många timmar av ansvarstid som ska schemaläggas
          {who}. Om både minsta och mesta antalet timmar är 0 så innebär det att
          ingen ansvartid kommer schemaläggas.
        </Typography>

        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Typography>Minsta antalet ansvarstimmar per period</Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>Mesta antalet ansvarstimmar per period</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <CustomField
              name="adminHoursMin"
              fullWidth
              disabled={disabled}
              size="small"
              type="number"
              inputProps={{ step: 0.5 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">timmar</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={1}>
            <CustomField
              name="adminHoursMax"
              fullWidth
              disabled={disabled}
              size="small"
              type="number"
              inputProps={{ step: 0.5 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">timmar</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

import type { ComponentProps, FC, PropsWithChildren } from "react";
import { TransitionGroup } from "react-transition-group";
import { Collapse, Stack } from "@mui/material";
import { FieldArray } from "formik";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import { CreateDemandsInput as FormValues, DemandDaysInput } from "../types";

import { initialDemand } from "./constants";
import { DemandDayCard } from "./DemandDayCard";
import DemandTable from "./DemandTable";

type Demands = DemandDaysInput["demands"];

type Props = {
  name: string;
  dayIdx: number;
  setDayIdx: (value: number) => void;
};

type ItemProps = Omit<ComponentProps<typeof DemandDayCard>, "onAdd"> & {
  name: string;
};

const DayItem: FC<PropsWithChildren<ItemProps>> = ({ name, ...props }) => (
  <FieldArray name={`${name}.demands`}>
    {({ push }: FormikFieldArrayFn<FormValues>) => (
      <DemandDayCard {...props} name={name} onAdd={() => push(initialDemand)} />
    )}
  </FieldArray>
);

export function DemandDaysForm({ name, dayIdx, setDayIdx }: Props) {
  const { value: demands } = useFormikState<Demands>(name);

  const toggleOpen = (idx: number) => {
    setDayIdx(dayIdx === idx ? -1 : idx);
  };

  return (
    <FieldArray name={name}>
      {({ remove }: FormikFieldArrayFn<Demands>) => (
        <Stack gap={2} component={TransitionGroup}>
          {(demands || []).map((_, i) => (
            <Collapse key={i}>
              <DayItem
                name={`${name}.${i}`}
                onRemove={() => remove(i)}
                expanded={dayIdx === i}
                onExpand={() => toggleOpen(i)}
              >
                <DemandTable name={`${name}.${i}`} />
              </DayItem>
            </Collapse>
          ))}
        </Stack>
      )}
    </FieldArray>
  );
}

import { DateTime } from "luxon";

type TimeFormat = "HH:mm:ss" | "HH:mm";

export const START_OF_WEEK = DateTime.fromObject({
  year: 2024,
  month: 1,
  day: 1,
});

const timeStringToDateTime = (
  timeString: string,
  baseDt: DateTime,
  timeFormat: TimeFormat,
) => {
  const time = DateTime.fromFormat(timeString, timeFormat);
  return baseDt
    .startOf("day")
    .set({ hour: time.hour, minute: time.minute, second: time.second });
};

export const hhmmss2hhmm = (input: string): string => input.substring(0, 5);

export const hhmm2Dt = (base: DateTime, hhmm: string) =>
  timeStringToDateTime(hhmm, base, "HH:mm");

export const hhmmss2Dt = (base: DateTime, hhmmss: string) =>
  timeStringToDateTime(hhmmss, base, "HH:mm:ss");

/** assumes start to be on format 'HH:mm'. */
export function sortStartFn<T extends { start: string }>(
  { start: a }: T,
  { start: b }: T,
) {
  return (
    DateTime.fromFormat(a, "HH:mm").valueOf() -
    DateTime.fromFormat(b, "HH:mm").valueOf()
  );
}

export function sortStartDt<T extends { start: Date }>(
  { start: a }: T,
  { start: b }: T,
) {
  return a.valueOf() - b.valueOf();
}

/** Transform `start` and `end` to DateTime, starting around `base`.
 *
 * Function requires start and end to be strings on the same format `timeFormat`.
 *
 * `isPart` dictates if start should be compared with base, and moved ahead one
 * day if it is less than. This is used for shift parts.
 *
 * ## Example:
 *
 * `startEndStringToDateTime({start: "22:00", end: "08:00"}, DateTime(day:5), "HH:mm")`
 * => `[DateTime(day: 5, hour: 22), DateTime(day: 6, hour: 8)]`
 *
 * `startEndStringToDateTime({start: "06:00:00", end: "07:00:00"}, DateTime(day:6), "HH:mm:ss")`
 * => `[DateTime(day: 6, hour: 6), DateTime(day: 6, hour: 7)]`
 *
 * `startEndStringToDateTime({start: "09:00", end: "10:00"}, DateTime(day:5, hour: 11), "HH:mm", true)`
 * => `[DateTime(day: 6, hour: 9), DateTime(day: 6, hour: 10)]`
 */
export function startEndStringToDateTime<
  T extends { start: string; end: string },
>(
  { start, end }: T,
  base: DateTime,
  timeFormat: TimeFormat,
  isPart = false,
): [DateTime<boolean>, DateTime<boolean>] {
  let startDt = timeStringToDateTime(start, base, timeFormat);
  if (isPart && startDt < base) {
    startDt = startDt.plus({ days: 1 });
  }

  let endDt = timeStringToDateTime(end, startDt, timeFormat);
  if (endDt <= startDt) {
    endDt = endDt.plus({ days: 1 });
  }

  return [startDt, endDt];
}

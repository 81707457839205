import { Stack } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { nightShiftSetting } from "../NightShiftSetting";

import { NightRestrictionField } from "./NightRestrictionField";
import { NightRotationField } from "./NightRotationField";

type Props = { readOnly?: boolean };

export function NightShiftsForm({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(nightShiftSetting.moduleName);

  return (
    <PaperWrapper>
      <Stack gap={3}>
        <NightRestrictionField disabled={disabled} readOnly={readOnly} />
        <NightRotationField disabled={disabled} readOnly={readOnly} />
      </Stack>
    </PaperWrapper>
  );
}

import graphql from "babel-plugin-relay/macro";

import environment from "../environment";
import { commitMutation } from "../libs/commitMutation";

import { LogoutMutation } from "./__generated__/LogoutMutation.graphql";

// Refresh token is sent via cookie
const mutation = graphql`
  mutation LogoutMutation {
    revokeToken {
      revoked
    }
    deleteTokenCookie {
      deleted
    }
    deleteRefreshTokenCookie {
      deleted
    }
  }
`;

export default async function logout() {
  return commitMutation<LogoutMutation>(environment, {
    mutation,
    variables: {},
    updater: (store) => {
      store.invalidateStore();
    },
  }).then((response) => {
    if (!response.revokeToken?.revoked) {
      return Promise.reject("Could not revoke token.");
    }
  });
}

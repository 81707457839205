import { Autocomplete } from "@mui/material";

import type {
  DisableClearable,
  FreeSolo,
  SingleAutocompleteProps,
  SingleProps,
} from "./types";

export function SingleSelect<V = any>(props: SingleProps<V>) {
  return (
    <Autocomplete<V, false, DisableClearable, FreeSolo>
      disableClearable
      {...(props as SingleAutocompleteProps<V>)}
    />
  );
}

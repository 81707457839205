import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Box, Stack } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import graphql from "babel-plugin-relay/macro";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";
import { SecondaryPalette } from "styles/colors";

import GraphTooltip from "../common/GraphTooltip";
import { XOption, XSelect } from "../common/XSelect";

import {
  ShiftNameDistributionChart_fragment$data as Data,
  ShiftNameDistributionChart_fragment$key as Key,
} from "./__generated__/ShiftNameDistributionChart_fragment.graphql";

const fragment = graphql`
  fragment ShiftNameDistributionChart_fragment on ScheduleStats {
    userStats {
      id
      name
      competences {
        edges {
          node {
            id
          }
        }
      }
      teams {
        edges {
          node {
            id
          }
        }
      }
    }
    userShifts {
      user {
        id
      }
      shiftTypes {
        name
        count
        hours
      }
    }
  }
`;

type UserShift = Unwrap<Data["userShifts"]>;
type UserType = Unwrap<Data["userStats"]>;
type Competence = ExtractNode<UserType["competences"]>;
type Team = ExtractNode<UserType["teams"]>;

type RowType = Omit<UserType, "competences" | "teams"> &
  Omit<UserShift, "user"> & {
    competences: ReadonlyArray<Competence>;
    teams: ReadonlyArray<Team>;
  };

type Props = {
  fragmentRef: Key;
  teamId: string | null;
  competence: string | null;
  filterCompetence: boolean;
};

const DEFAULT_OPTION = { value: "count", label: "Antal pass" };
const OPTIONS: ReadonlyArray<XOption> = [
  DEFAULT_OPTION,
  { value: "hours", label: "Antal timmar" },
];

export function ShiftNameDistributionChart({
  fragmentRef,
  teamId,
  competence,
  filterCompetence,
}: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  const teamFilter = useCallback(
    (row: RowType) =>
      !teamId || row.teams.some((team: { id: string }) => team?.id === teamId),
    [teamId],
  );
  const competenceFilter = useCallback(
    (row: RowType) =>
      !filterCompetence || row.competences.some((d) => d.id === competence),
    [filterCompetence, competence],
  );
  const filter = useCallback(
    (row: RowType) => teamFilter(row) && competenceFilter(row),
    [teamFilter, competenceFilter],
  );

  const filteredData = useMemo(
    () =>
      (data.userStats || [])
        .map(({ teams, competences, ...u }) => {
          const { shiftTypes } =
            data.userShifts.find((s) => s.user.id === u.id) || {};
          return {
            ...u,
            shiftTypes: shiftTypes || [],
            teams: connectionToArray(teams),
            competences: connectionToArray(competences),
          };
        })
        .filter(filter),
    [data, filter],
  );

  // Axis switch options
  const [xAxis, setXAxis] = useState(DEFAULT_OPTION);
  // Convert data
  const [dataConverted, keys] = useMemo(() => {
    const uniqueKeys = new Set<string>();
    const converted = {
      count: filteredData.map((row) => {
        row.shiftTypes.forEach((s) => uniqueKeys.add(s.name));
        return Object.assign(
          {
            user_key: `${row.id} ${row.name}`,
            user_name: row.name,
          },
          ...row.shiftTypes.map((s) => ({ [s.name]: s.count })),
        );
      }),
      hours: filteredData.map((row) => {
        return Object.assign(
          {
            user_key: `${row.id} ${row.name}`,
            user_name: row.name,
          },
          ...row.shiftTypes.map((s) => ({ [s.name]: +s.hours.toFixed(2) })),
        );
      }),
    };
    const keys = Array.from(uniqueKeys.values());
    keys.sort();
    return [converted, keys];
  }, [filteredData]);

  // Calculate minimal chart height and margins
  const chartHeightEmp = 75 + dataConverted.hours.length * 21;
  const chartHeightShifts = keys.length * 18;
  const chartHeight = Math.max(chartHeightEmp, chartHeightShifts);
  const leftMargin =
    13 +
    Math.max(...dataConverted.hours.map((u: any) => u.user_name.length)) * 5.6;
  const legendWidth = 30 + Math.max(...keys.map((k: string) => k.length)) * 6;

  return (
    <Stack px={1} alignItems="center">
      <Box sx={{ height: chartHeight, width: "100%" }}>
        <ResponsiveBar
          data={
            xAxis.value === "hours" ? dataConverted.hours : dataConverted.count
          }
          groupMode="stacked"
          layout="horizontal"
          keys={keys}
          indexBy="user_name"
          margin={{
            top: 25,
            right: legendWidth + 20,
            bottom: 50,
            left: leftMargin,
          }}
          colors={SecondaryPalette}
          labelSkipWidth={12}
          labelTextColor="white"
          tooltip={({ id, value, data }) => (
            <GraphTooltip
              keyId={id}
              translateKey={(d) => d}
              value={value}
              totalValue={Object.entries(data)
                .filter(([k, _v]) => k !== "user_key" && k !== "user_name")
                .reduce((acc, [_k, v]) => acc + (v as number), 0)}
              unit={xAxis.value === "count" ? "pass" : "timmar"}
              user={data.user_name}
            />
          )}
          // theme={{ fontFamily: "Nunito" }}
          theme={{ text: { fontFamily: "Nunito" } }}
          borderRadius={3}
          innerPadding={1}
          legends={[
            {
              dataFrom: "keys",
              anchor: "right",
              direction: "column",
              translateX: legendWidth + 20,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: legendWidth,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </Box>
      <Box>
        <XSelect
          value={xAxis}
          onChange={setXAxis}
          options={OPTIONS}
          defaultValue={DEFAULT_OPTION}
        />
      </Box>
    </Stack>
  );
}

import { Stack, Typography } from "@mui/material";

import { FormikSmallSwitch } from "components/common/Switch";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function OptimizeApprovedWishesDistributionBetweenEmployees({
  disabled,
  readOnly,
}: Props) {
  return (
    <Stack gap={1}>
      <Stack direction="row" gap={2}>
        <Typography variant="h4">
          Fördela beviljade fridagar lika mellan medarbetare
        </Typography>
        <FormikSmallSwitch
          name="optimizeApprovedWishesDistributionBetweenEmployees"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <Typography>
        Om denna inställning är påslagen så kommer algoritmen eftersträva att
        alla medarbetare får lika många önskade fridagar beviljade.
      </Typography>
    </Stack>
  );
}

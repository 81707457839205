/**
 * @generated SignedSource<<91858412e85f20ca39d37006b8840fee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTeamGroupInput = {
  clientMutationId?: string | null;
  name: string;
};
export type CreateTeamGroupMutation$variables = {
  input: CreateTeamGroupInput;
};
export type CreateTeamGroupMutation$data = {
  readonly createTeamGroup: {
    readonly teamGroup: {
      readonly archivedAt: string | null;
      readonly deleteable: boolean;
      readonly id: string;
      readonly isActive: boolean;
      readonly name: string;
    } | null;
  } | null;
};
export type CreateTeamGroupMutation = {
  response: CreateTeamGroupMutation$data;
  variables: CreateTeamGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTeamGroupPayload",
    "kind": "LinkedField",
    "name": "createTeamGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamGroupNode",
        "kind": "LinkedField",
        "name": "teamGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleteable",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTeamGroupMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTeamGroupMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f5183fb595693c565a625fe7249407f4",
    "id": null,
    "metadata": {},
    "name": "CreateTeamGroupMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTeamGroupMutation(\n  $input: CreateTeamGroupInput!\n) {\n  createTeamGroup(input: $input) {\n    teamGroup {\n      id\n      name\n      isActive\n      archivedAt\n      deleteable\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "186fc773261f05859347942908881110";

export default node;

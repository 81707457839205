/**
 * @generated SignedSource<<8b3ea160cf824bf66ef68b4c560e73c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScheduleGroupSettingsRuleGroupList_ruleGroups$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly description: string;
      readonly name: string;
    } | null;
  } | null>;
  readonly " $fragmentType": "ScheduleGroupSettingsRuleGroupList_ruleGroups";
};
export type ScheduleGroupSettingsRuleGroupList_ruleGroups$key = {
  readonly " $data"?: ScheduleGroupSettingsRuleGroupList_ruleGroups$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleGroupSettingsRuleGroupList_ruleGroups">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScheduleGroupSettingsRuleGroupList_ruleGroups",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RuleGroupSnapshotNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RuleGroupSnapshotNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RuleGroupSnapshotNodeConnection",
  "abstractKey": null
};

(node as any).hash = "51d3548575d927e5fd1a021259b53e52";

export default node;

import React, { useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import {
  Add as AddIcon,
  Check,
  Search as SearchIcon,
} from "@mui/icons-material";
import type {
  ListItemButtonProps,
  ListItemProps,
  SelectChangeEvent,
  SelectProps,
  TextFieldProps,
} from "@mui/material";
import {
  FormControl,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  styled,
  TextField,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { usePageQuery } from "contexts/PageQueryContext";
import { useTeamGroups } from "contexts/TeamGroupsContext";
import {
  useCurrentTeamGroup,
  useCurrentTeamGroupUpdater,
} from "hooks/CurrentTeamGroup";

import { CurrentTeamGroupSelect_fragment$key as Key } from "./__generated__/CurrentTeamGroupSelect_fragment.graphql";

const fragment = graphql`
  fragment CurrentTeamGroupSelect_fragment on TeamGroupNode
  @relay(plural: true) {
    id
    name
  }
`;

type SearchItemProps = { value: string } & Pick<
  TextFieldProps,
  "onChange" | "onKeyDown"
>;

type AddItemProps = ListItemButtonProps & {
  label: string;
};

type SelectedItemProps = ListItemProps & { label?: string };

const StyledSelect = styled((props: SelectProps) => (
  <MuiSelect
    MenuProps={{
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: -8,
      },
      MenuListProps: { sx: { py: 0 } },
    }}
    inputProps={{ sx: { color: "primary.contrastText" } }}
    {...props}
  />
))(() => ({
  ".MuiSvgIcon-root": { color: "rgba(255, 255, 255, 0.23)" },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.23)",
  },
  "&:hover": {
    ".MuiSvgIcon-root": { color: "rgba(255, 255, 255, 0.75)" },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.75)",
    },
  },
  "&.Mui-focused": {
    ".MuiSvgIcon-root": { color: "rgba(255, 255, 255, 0.56)" },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.56)",
    },
  },
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  color: "rgba(255,255,255,0.7)",
  "&.Mui-focused": {
    color: "rgba(255,255,255,0.7)",
  },
}));

const noop = () => {};

const SearchItem = ({ value, onChange, onKeyDown }: SearchItemProps) => (
  <ListItem divider onChange={noop} onClick={noop}>
    <ListItemIcon sx={{ minWidth: 0, mr: "14px" }}>
      <SearchIcon />
    </ListItemIcon>
    <TextField
      variant="standard"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder="Sök enhet"
    />
  </ListItem>
);

const SelectedItem = ({ label = "", ...props }: SelectedItemProps) => (
  <ListItem {...props}>
    <ListItemText>{label}</ListItemText>
    <ListItemIcon sx={{ minWidth: 0 }}>
      <Check />
    </ListItemIcon>
  </ListItem>
);

const AddItem = ({ label, ...props }: AddItemProps) => (
  <ListItemButton {...props}>
    <ListItemIcon sx={{ color: "primary.main", minWidth: 0, mr: "14px" }}>
      <AddIcon />
    </ListItemIcon>
    <ListItemText
      primary={label}
      primaryTypographyProps={{ color: "primary.main", fontWeight: 700 }}
    />
  </ListItemButton>
);

const EmptyItem = (props: ListItemProps) => (
  <ListItem {...props}>
    <ListItemText
      primary="Inga enheter funna"
      primaryTypographyProps={{ fontStyle: "italic", color: "text.secondary" }}
    />
  </ListItem>
);

export default function CurrentTeamGroupSelect() {
  const current = useCurrentTeamGroup();
  const query = usePageQuery();
  const teamGroups = useFragment<Key>(fragment, query.teamGroups);
  const { openCreateTeamGroup } = useTeamGroups();
  const setCurrentTeamGroup = useCurrentTeamGroupUpdater();
  const [search, setSearch] = useState("");

  const onCreateTeamGroup = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    openCreateTeamGroup();
  };

  const options = useMemo(
    () =>
      teamGroups.map((n) => ({
        value: n.id,
        label: n.name,
      })),
    [teamGroups],
  );

  const selected = useMemo(
    () => options.find((o) => o.value === current?.id),
    [options, current?.id],
  );

  const filteredOptions = useMemo(
    () =>
      options.filter(
        (o) => !search || o.label.toLowerCase().includes(search.toLowerCase()),
      ),
    [search, options],
  );

  function stopPropagation(e: any) {
    switch (e.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Home":
      case "End":
        break;
      default:
        e.stopPropagation();
    }
  }

  function clearSearch() {
    setSearch("");
  }

  function onSelectChange(e: SelectChangeEvent<unknown>, _: React.ReactNode) {
    if (!e.target.value) return;
    setCurrentTeamGroup(e.target.value as string);
    clearSearch();
  }

  function onSearchChange(e: any) {
    setSearch(e.target.value);
  }

  // Select field
  const labelId = "groupId-label";
  const label = "Enhet";
  return (
    <FormControl fullWidth size="small">
      <StyledInputLabel id={labelId}>{label}</StyledInputLabel>
      <StyledSelect
        label={label}
        labelId={labelId}
        name="teamGroups"
        value={selected?.value ?? null}
        onChange={onSelectChange}
        onClose={clearSearch}
      >
        <SearchItem
          value={search}
          onChange={onSearchChange}
          onKeyDown={stopPropagation}
        />
        <SelectedItem label={selected?.label} hidden={!selected} divider />
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            divider={
              !!filteredOptions.length &&
              filteredOptions[filteredOptions.length - 1].value === option.value
            }
            hidden={
              !filteredOptions.includes(option) ||
              selected?.value === option?.value
            }
          >
            {option.label}
          </MenuItem>
        ))}
        <EmptyItem hidden={!!filteredOptions.length} divider />
        <AddItem onClick={onCreateTeamGroup} label="Skapa ny enhet" />
      </StyledSelect>
    </FormControl>
  );
}

import { createContext, useContext } from "react";

import type { TeamSelect2_fragment$key as Key } from "./types";

export const TeamSelect2FragmentRefContext = createContext<Key | null>(null);
export const TeamSelect2FragmentRefProvider =
  TeamSelect2FragmentRefContext.Provider;
export const useTeamSelect2FragmentRef = () => {
  const ref = useContext(TeamSelect2FragmentRefContext);
  if (!ref) {
    throw new Error(
      "useTeamSelect2FragmentRef requires a TeamSelect2FragmentRefProvider",
    );
  }
  return ref;
};

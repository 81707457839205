/**
 * @generated SignedSource<<72ef618a5a4df363cd92e1ada0fae6cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScheduleProfileChartQuery$variables = {
  id: string;
};
export type ScheduleProfileChartQuery$data = {
  readonly schedule: {
    readonly scheduleProfile: {
      readonly viewer: ReadonlyArray<{
        readonly categories: ReadonlyArray<{
          readonly data: string;
          readonly seriesId: string;
        }>;
        readonly competenceId: string | null;
        readonly maxY: number;
        readonly teamId: string | null;
      }>;
    };
  } | null;
};
export type ScheduleProfileChartQuery = {
  response: ScheduleProfileChartQuery$data;
  variables: ScheduleProfileChartQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ScheduleProfileViewer",
  "kind": "LinkedField",
  "name": "scheduleProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleProfile",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "competenceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxY",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ScheduleProfileCategory",
          "kind": "LinkedField",
          "name": "categories",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "seriesId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "data",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleProfileChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleProfileChartQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "706193a1a75d56569e4f2ebfe4273548",
    "id": null,
    "metadata": {},
    "name": "ScheduleProfileChartQuery",
    "operationKind": "query",
    "text": "query ScheduleProfileChartQuery(\n  $id: ID!\n) {\n  schedule(id: $id) {\n    scheduleProfile {\n      viewer {\n        teamId\n        competenceId\n        maxY\n        categories {\n          seriesId\n          data\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3caed50585afdd8fdfa8d8281a6fcb6";

export default node;

import { Paper, Skeleton, Stack } from "@mui/material";

function AdminTablePlaceholder() {
  return (
    <Stack>
      <Skeleton height={300} animation="wave" />
    </Stack>
  );
}

export function AdminCompetencesPlaceholder() {
  return <AdminTablePlaceholder />;
}

export function AdminRuleGroupsPlaceholder() {
  return <AdminTablePlaceholder />;
}

export function AdminTeamGroupsPlaceholder() {
  return <AdminTablePlaceholder />;
}

export function AdminTeamsPlaceholder() {
  return <AdminTablePlaceholder />;
}

export function AdminUsersPlaceholder({
  actions,
}: {
  actions: React.ReactNode;
}) {
  return (
    <Paper variant="box" sx={{ p: "12px" }}>
      <Stack gap={2}>
        {actions}
        <AdminTablePlaceholder />
      </Stack>
    </Paper>
  );
}

import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";

type Props = {
  onEdit: () => void;
};

export default function EditUserItem({ onEdit }: Props) {
  return (
    <Tooltip title="Redigera" placement="left">
      <IconButton onClick={() => onEdit()} size="small">
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}

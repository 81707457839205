import { useCallback } from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  OptimizationSetting,
  useDefaultTeamGroupSubmitFunction,
} from "settings/common/optimizationSetting";
import { WeightTeamGroupOnlyForm } from "settings/common/TeamGroupOnlyForms";
import * as yup from "yup";

import { UpdateSettingInput } from "components/group_settings/types";
import { dayTypeDistributionWeightChoices } from "components/setting/team_group/setting_boxes/constants";
import { useUpdateRuleGroupSettingsMutation } from "components/setting/team_group/setting_boxes/updateRuleGroupSettings";
import { useUpdateSettingsMutation } from "components/setting/team_group/setting_boxes/updateSettings";
import { useUpdateUserSettingsMutation } from "components/setting/team_group/setting_boxes/updateUserSettings";

import {
  DayNightDistributionSettingTeamGroup_fragment$data as TeamGroupData,
  DayNightDistributionSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/DayNightDistributionSettingTeamGroup_fragment.graphql";
import {
  DayNightDistributionSettingUserSetting_fragment$data as UserSettingData,
  DayNightDistributionSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/DayNightDistributionSettingUserSetting_fragment.graphql";
import { DayNightDist } from "./form/DayNightDistributionForm";

const teamGroupFragment = graphql`
  fragment DayNightDistributionSettingTeamGroup_fragment on SettingNode {
    id
    dayTypeDistributionWeight
    shiftDayTypeDistributionSoft
    shiftDayTypeAllowedErrorMargin
    dayShiftDistributionShare
    eveningShiftDistributionShare
    nightShiftDistributionShare
    fullDayShiftDistributionShare
    shiftsPerWeek
    constraintModules
    periodLengthWeeks
  }
`;

const userSettingFragment = graphql`
  fragment DayNightDistributionSettingUserSetting_fragment on UserSettingNode {
    id
    shiftsPerWeek
    settingModules
    shiftDayTypeDistributionSoft
    shiftDayTypeAllowedErrorMargin
    dayShiftDistributionShare
    eveningShiftDistributionShare
    nightShiftDistributionShare
    fullDayShiftDistributionShare
  }
`;

const SETTING_NAME = "Dag-/Kväll-/Natt-fördelning";
const MODULE_NAME = "ShiftDayTypeDistribution";
const SETTING_URL_ID = "day-eve-night-distri";

type DayNightDistributionFormValues = Omit<
  TeamGroupData,
  | " $fragmentType"
  | "periodLengthWeeks"
  | "constraintModules"
  | "shiftDayTypeDistributionSoft"
  | "dayTypeDistributionWeight"
>;

type DayNightDistributionTeamGroupFormValues = Pick<
  TeamGroupData,
  "id" | "shiftDayTypeDistributionSoft" | "dayTypeDistributionWeight"
>;

type AdditionalData = {
  periodLengthWeeks: number;
};

const teamGroupValidationSchema = yup.object().shape({
  dayTypeDistributionWeight: yup
    .string()
    .oneOf(dayTypeDistributionWeightChoices)
    .required(),
  shiftDayTypeDistributionSoft: yup.boolean().required(),
});

const validationSchema = yup.object().shape({
  id: yup.string(),
  dayShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  eveningShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  nightShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  fullDayShiftDistributionShare: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(100, "Måste vara högst 100")
    .required("Får ej vara tomt"),
  customValidation: yup
    .boolean()
    .test(
      "share-equal-to-100",
      "the shifts do not add upp to 100 percent",
      function (val) {
        const {
          dayShiftDistributionShare,
          eveningShiftDistributionShare,
          nightShiftDistributionShare,
          fullDayShiftDistributionShare,
        } = this.parent;
        return (
          dayShiftDistributionShare +
            eveningShiftDistributionShare +
            nightShiftDistributionShare +
            fullDayShiftDistributionShare ===
          100
        );
      },
    ),
});

function convertDataToInitialValues(data: TeamGroupData | UserSettingData) {
  return {
    id: data.id,
    shiftsPerWeek: data.shiftsPerWeek,
    shiftDayTypeDistributionSoft: data.shiftDayTypeDistributionSoft || false,
    shiftDayTypeAllowedErrorMargin: data.shiftDayTypeAllowedErrorMargin || 0,
    dayShiftDistributionShare:
      Math.round((data.dayShiftDistributionShare ?? 0.5) * 1000) / 10,
    eveningShiftDistributionShare:
      Math.round((data.eveningShiftDistributionShare ?? 0.5) * 1000) / 10,
    nightShiftDistributionShare:
      Math.round((data.nightShiftDistributionShare ?? 0) * 1000) / 10,
    fullDayShiftDistributionShare:
      Math.round((data.fullDayShiftDistributionShare ?? 0) * 1000) / 10,
    constraintModules:
      "settingModules" in data ? data.settingModules : data.constraintModules,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): DayNightDistributionTeamGroupFormValues {
  return {
    ...teamGroupData,
  };
}

function useAdditionalDataForTeamGroup(fragmentRef: TeamGroupKey) {
  const teamGroupData = useFragment(teamGroupFragment, fragmentRef);
  const periodLengthWeeks = teamGroupData.periodLengthWeeks;
  return {
    periodLengthWeeks,
  };
}

function useSubmitFunctions<SettingFormValues extends UpdateSettingInput>() {
  const [updateSettings] = useUpdateSettingsMutation();
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const [updateRuleGroupSettings] = useUpdateRuleGroupSettingsMutation();

  const modifyInput = useCallback(
    ({ shiftsPerWeek, ...input }: SettingFormValues) => ({
      ...input,
      shiftDayTypeDistributionSoft:
        input.shiftDayTypeAllowedErrorMargin === -1 ? false : true,
      dayShiftDistributionShare: (input.dayShiftDistributionShare ?? 0) / 100,
      eveningShiftDistributionShare:
        (input.eveningShiftDistributionShare ?? 0) / 100,
      nightShiftDistributionShare:
        (input.nightShiftDistributionShare ?? 0) / 100,
      fullDayShiftDistributionShare:
        (input.fullDayShiftDistributionShare ?? 0) / 100,
    }),
    [],
  );

  const onSubmitTeamGroup = useCallback(
    (input: SettingFormValues) => {
      updateSettings({ variables: { input: modifyInput(input) } });
    },
    [modifyInput, updateSettings],
  );

  const onSubmitUser = useCallback(
    (input: SettingFormValues) => {
      updateUserSettings({ variables: { input: modifyInput(input) } });
    },
    [modifyInput, updateUserSettings],
  );

  const onSubmitRuleGroup = useCallback(
    (input: SettingFormValues) => {
      updateRuleGroupSettings({ variables: { input: modifyInput(input) } });
    },
    [modifyInput, updateRuleGroupSettings],
  );

  return { onSubmitTeamGroup, onSubmitUser, onSubmitRuleGroup };
}

function renderComponent(
  initialValues: DayNightDistributionFormValues,
  onSubmit: (formValues: DayNightDistributionFormValues) => void,
  additionalData: AdditionalData,
) {
  const totalShifts =
    additionalData.periodLengthWeeks * initialValues.shiftsPerWeek;
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <DayNightDist totalShifts={totalShifts} />
    </BaseSettings>
  );
}

function renderTeamGroupComponent(
  initialValues: DayNightDistributionTeamGroupFormValues,
  onSubmit: (formValues: DayNightDistributionTeamGroupFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <WeightTeamGroupOnlyForm
        moduleName={MODULE_NAME}
        weightFieldName="dayTypeDistributionWeight"
      />
    </BaseSettings>
  );
}

export const dayNightDistributionSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  undefined,
  DayNightDistributionFormValues,
  AdditionalData,
  DayNightDistributionTeamGroupFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues: convertDataToInitialValues,
  convertUserDataToInitialValues: convertDataToInitialValues,
  useAdditionalDataForTeamGroup,
  useSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    renderComponent: renderTeamGroupComponent,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
  },
};

import React from "react";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { InputAdornment, styled, TextField, Typography } from "@mui/material";
import type { TextFieldProps } from "@mui/material/TextField";

type CustomTypographyLikeTextFieldProps = {
  value: string;
  label?: string;
  errorMessage?: string;
} & TextFieldProps;

const ReadOnlyTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "errorMessage",
})<{
  errorMessage: string | undefined;
}>(({ theme, errorMessage }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: errorMessage ? "#fdecea" : "#f0f8f8",
  },
}));

const ErrorMessage = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  // minHeight: "1em",    // TODO: a solution to keep the error messages for forms and fields to shifting the layout.
}));

const CalculatedField: React.FC<CustomTypographyLikeTextFieldProps> = ({
  value,
  label,
  errorMessage,
  ...props
}) => {
  return (
    <div>
      <ReadOnlyTextField
        value={value}
        label={label}
        variant="outlined"
        size={"small"}
        type={"text"}
        errorMessage={errorMessage}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <SettingsSuggestIcon
                sx={{ color: errorMessage ? "red" : "gray", opacity: 0.5 }}
              />
            </InputAdornment>
          ),
        }}
        {...props}
      />
      <ErrorMessage>
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      </ErrorMessage>
    </div>
  );
};

export default CalculatedField;

import { createContext, useContext } from "react";

import type { TeamSelect_fragment$key as Key } from "./types";

export const TeamSelectFragmentRefContext = createContext<Key | null>(null);
export const TeamSelectFragmentRefProvider =
  TeamSelectFragmentRefContext.Provider;
export const useTeamSelectFragmentRef = () => {
  const ref = useContext(TeamSelectFragmentRefContext);
  if (!ref) {
    throw new Error(
      "useTeamSelectFragmentRef requires a TeamSelectFragmentRefProvider",
    );
  }
  return ref;
};

import { FormikSwitchWithTitleAndDesc } from "./common";

type Props = {
  readOnly?: boolean;
};

export function WrapAroundPeriodEndField({ readOnly }: Props) {
  return (
    <FormikSwitchWithTitleAndDesc
      name="wrapAroundPeriodEnd"
      title="Gör schemat rullande"
      description={
        <>
          Om detta alternativ är påslaget så kommer alla regler att vara
          rullande. Det innebär att man skulle kunna använda samma schema 2
          eller fler perioder i rad utan att bryta mot någon regel.
        </>
      }
      readOnly={readOnly}
    />
  );
}

import type { CSSObject } from "@mui/material";
import {
  Box,
  FormGroup,
  FormLabel,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import useFormikState from "hooks/useFormikState";
import { primaryColors, secondaryColors } from "styles/mui/light-palette";

type PaletteVariant = "primary" | "secondary" | "primary_and_secondary";
type CommonProps = {
  label: string;
  variant?: PaletteVariant;
  sx?: CSSObject;
};

function getVariant(variant: PaletteVariant) {
  switch (variant) {
    case "primary":
      return primaryColors;
    case "secondary":
      return secondaryColors;
    case "primary_and_secondary":
      return { ...primaryColors, ...secondaryColors };
  }
}

type Props = CommonProps & {
  value: string;
  onChange: (value: string) => void;
};

type FormikProps = CommonProps & {
  name: string;
};

export default function ColorPicker({
  value,
  onChange,
  label,
  variant = "primary",
  sx = {},
}: Props) {
  const colors = Object.values(getVariant(variant));

  return (
    <FormGroup>
      <FormLabel>
        <Typography variant="caption">{label}</Typography>
      </FormLabel>
      <Grid container columns={4} spacing={1} sx={sx}>
        {colors.map((color) => (
          <Grid xs={1} key={color}>
            <Box
              key={color}
              sx={{
                width: "1.5rem",
                height: "1.5rem",
                borderRadius: "50%",
                backgroundColor: color,
                display: "inline-block",
                border: value === color ? "3px solid black" : "none",
              }}
              onClick={() => onChange(color)}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
}

export function FormikColorPicker({ name, label, variant, sx }: FormikProps) {
  const { value, setValue } = useFormikState(name);

  return (
    <ColorPicker
      value={value}
      onChange={setValue}
      label={label}
      variant={variant}
      sx={sx}
    />
  );
}

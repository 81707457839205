import { Box, Checkbox, FormControlLabel } from "@mui/material";

type Props = {
  label: string;
  value: boolean;
  onToggle: VoidFunction;
  disabled?: boolean;
};

export const LabeledCheckbox: React.FC<Props> = ({
  label,
  value,
  onToggle,
  disabled,
}) => (
  <Box>
    <FormControlLabel
      label={label}
      control={<Checkbox checked={value} onClick={onToggle} />}
      disabled={disabled}
    />
  </Box>
);

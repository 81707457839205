import type { SelectChangeEvent } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import type { PriorityWeight } from "types/Demand";

import {
  priorityWeightOptions,
  translateDemandWeight,
} from "components/demand/types";

type Props = Omit<React.ComponentProps<typeof Select>, "value" | "onChange"> & {
  name: string;
};

type Option = {
  value: PriorityWeight;
  label: string;
};

const options: Option[] = priorityWeightOptions.map((x) => ({
  value: x,
  label: translateDemandWeight(x),
}));

/**
 * Use in formik form.
 *
 * value: PriorityWeight
 */
export function DemandWeightSelect({ name, ...props }: Props) {
  const { value, setValue } = useFormikState<PriorityWeight>(name);

  function onChange(e: SelectChangeEvent<unknown>) {
    setValue(e.target.value as PriorityWeight);
  }

  return (
    <Select {...props} value={value} onChange={onChange} size="small">
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

import { Stack, Typography } from "@mui/material";

import { SettingHelpButton } from "./SettingHelpButton";

type Props = {
  name: string;
  InfoComponent?: React.ComponentType;
  hideInfo?: boolean;
};

export function SettingViewHeader({ name, InfoComponent, hideInfo }: Props) {
  return (
    <Stack direction="row" justifyContent="space-between" flexGrow={1}>
      <Typography variant="h1" component="span" sx={{ color: "text.primary" }}>
        {name}
      </Typography>
      {!hideInfo && (
        <SettingHelpButton name={name} InfoComponent={InfoComponent} />
      )}
    </Stack>
  );
}

/**
 * @generated SignedSource<<584ff606787986bc8ef06f1594b3b4d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiScheduleSolutionStatusChoices = "ERROR" | "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "QUEUED" | "RUNNING" | "RUNNING_WITH_INFEASIBILITY_VENTS" | "%future added value";
export type ApiScheduleTerminationConditionChoices = "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "INFEASIBLE_NOW_RERUN" | "MAX_STALL_TIME" | "MAX_TIME_LIMIT" | "OPTIMAL" | "OTHER" | "UNBOUNDED" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScheduleRunsTable_fragment$data = ReadonlyArray<{
  readonly copiedFrom: {
    readonly id: string;
  } | null;
  readonly created: string;
  readonly createdBy: {
    readonly fullName: string;
    readonly id: string;
  } | null;
  readonly id: string;
  readonly manuallyEdited: boolean;
  readonly masterForPeriod: {
    readonly id: string;
  } | null;
  readonly mipgap: number | null;
  readonly solutionStatus: ApiScheduleSolutionStatusChoices;
  readonly terminationCondition: ApiScheduleTerminationConditionChoices | null;
  readonly timeLimit: number | null;
  readonly totalSolvingTime: number | null;
  readonly " $fragmentType": "ScheduleRunsTable_fragment";
}>;
export type ScheduleRunsTable_fragment$key = ReadonlyArray<{
  readonly " $data"?: ScheduleRunsTable_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScheduleRunsTable_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ScheduleRunsTable_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNode",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mipgap",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSolvingTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationCondition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "solutionStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manuallyEdited",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleNode",
      "kind": "LinkedField",
      "name": "copiedFrom",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodNode",
      "kind": "LinkedField",
      "name": "masterForPeriod",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ScheduleNode",
  "abstractKey": null
};
})();

(node as any).hash = "c35e961ce807fb8142af2e80cb436423";

export default node;

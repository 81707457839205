import { Stack, Typography } from "@mui/material";

export const OptimizationInfo = () => (
  <Stack gap={1}>
    <Typography variant="h4">Mer om mipgap-gränsen</Typography>
    <Typography>
      Begreppet <i>mip-gap</i> dyker upp på flera ställen på Konvojs plattform
      och bör vara obekant sedan tidigare för de flesta användare. <i>MIP</i> är
      en förkortning och står för Mixed Integer Programming, vilket är ett
      samlingsnamn för den typen av optimeringsproblem som innehåller både
      heltalsvariabler (på engelska <i>integer</i>) och kontinuerliga variabler.
      Schemaoptimeringsproblem är av typen MIP.
    </Typography>
    <Typography>
      Utan att gå in för djupt på den matematiska definitionen av mip-gap, så
      kan vi kort konstatera att det är ett mått på hur bra lösningen är. Ett
      mip-gap på 0% innebär en helt perfekt optimal lösning. Men eftersom
      schemaoptimering inte är en exakt vetanskap (man kan inte sätta en exakt
      vikt på svårfångade paramterar såsom rättvisa, hälsa och medbestämmande),
      så brukar ofta 5-10% mip-gap ge ett mycket bra schemaresultat.
    </Typography>
  </Stack>
);

import type { SelectProps } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export type Value = "settings" | "exceptions" | "ranking";

type Props = Pick<SelectProps<Value>, "value" | "onChange">;

const label = "Visa";
const labelId = "view-select-label";

export function ViewSelect(selectProps: Props) {
  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        mt: 1,
      }}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select<Value> {...selectProps} label={label} labelId={labelId}>
        <MenuItem value="settings">Optimeringsregler</MenuItem>
        <MenuItem value="exceptions">Undantag</MenuItem>
        {/* <MenuItem value="ranking">Ranking</MenuItem> */}
      </Select>
    </FormControl>
  );
}

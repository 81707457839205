import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
} from "@mui/material";
import type { FormikErrors } from "formik";
import { Form as FormikForm, Formik } from "formik";

import { DialogTitle } from "components/common/Dialog";
import CustomField from "components/forms/CustomField";

import { ColumnsField } from "./ColumnsField";
import type { Column, FormValues, PresetFormProps } from "./types";

type FormProps = {
  columns: Column[];
};

function Form({ columns }: FormProps) {
  return (
    <Stack gap={2} pt={1}>
      <CustomField name="name" label="Namn på kolumngrupp" required />
      <ColumnsField name="columns" columns={columns} />
    </Stack>
  );
}

export function PresetForm({
  open,
  onClose,
  initialValues: overrideValues = {},
  onSubmit,
  title,
  buttonLabel,
  columns,
  reservedKeys,
}: PresetFormProps) {
  const initialValues: FormValues = {
    key: "",
    name: "",
    columns: [],
    ...overrideValues,
  };

  function validate(values: FormValues) {
    const errors: FormikErrors<FormValues> = {};
    const key = values.name.toLowerCase();

    if (reservedKeys.includes(key)) {
      errors.name = "Namnet är reserverat";
    } else if (columns.map((x) => x.key).includes(key)) {
      errors.name = "Namnet är upptaget";
    }

    if (!values.name) {
      errors.name = "Namn är obligatoriskt";
    }

    if (!values.columns.length) {
      errors.columns = "Du måste välja minst en kolumn";
    }

    return errors;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty }) => {
          return (
            <FormikForm autoComplete="off">
              <DialogTitle onClose={onClose} title={title} />
              <Divider />
              <DialogContent>
                <Form columns={columns} />
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button variant="cancel" onClick={onClose}>
                  Avbryt
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  {buttonLabel}
                </Button>
              </DialogActions>
            </FormikForm>
          );
        }}
      </Formik>
    </Dialog>
  );
}

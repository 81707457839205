import { Stack, Typography } from "@mui/material";
import { useFormikState } from "hooks";

import { HeaderWeight } from "components/setting/common";

import { HardSoftChoice } from "./HardSoftChoice";
import PaperWrapper from "./PaperWrapper";
import { useIsConstraintModuleEnabled } from "./useIsConstraintModuleEnabled";

type Props = {
  moduleName: string;
  readOnly?: boolean;
};

type WithWeight<T> = T & { weightFieldName: string };

type WeightProps = Props & {
  weightFieldName: string;
  disabled?: boolean;
};

type HardSoftProps = Props & {
  softFieldName: string;
};

type TeamGroupOnlyProps = WithWeight<HardSoftProps>;
export function TeamGroupOnlyForm(props: TeamGroupOnlyProps) {
  return (
    <PaperWrapper>
      <WeightAndHardSoftFields {...props} />
    </PaperWrapper>
  );
}

export function WeightAndHardSoftFields({
  softFieldName,
  weightFieldName,
  ...props
}: TeamGroupOnlyProps) {
  const { value: radioSoft } = useFormikState<boolean>(softFieldName);

  return (
    <Stack gap={2}>
      <Typography variant="h4">Regelvikt</Typography>
      <HardSoftFieldInternal softFieldName={softFieldName} {...props} />
      <WeightFieldInternal
        weightFieldName={weightFieldName}
        disabled={!radioSoft}
        {...props}
      />
    </Stack>
  );
}

export function WeightTeamGroupOnlyForm(props: WeightProps) {
  return (
    <PaperWrapper>
      <WeightField {...props} />
    </PaperWrapper>
  );
}

export function WeightField(props: WeightProps) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Regelvikt</Typography>
      <WeightFieldInternal {...props} />
    </Stack>
  );
}
function WeightFieldInternal({
  weightFieldName,
  disabled: disabledProp,
  moduleName,
  readOnly,
}: WeightProps) {
  const disabled = !useIsConstraintModuleEnabled(moduleName);

  return (
    <HeaderWeight
      name={weightFieldName}
      readOnly={readOnly}
      disabled={disabled || disabledProp}
    />
  );
}

function HardSoftFieldInternal({
  softFieldName,
  moduleName,
  readOnly,
}: HardSoftProps) {
  const disabled = !useIsConstraintModuleEnabled(moduleName);

  return (
    <HardSoftChoice
      name={softFieldName}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function sortListBy<T>(
  a: ReadonlyArray<T>,
  b: ReadonlyArray<T>,
  keyFn: (item: T) => string,
) {
  const lenDiff = b.length - a.length;
  if (lenDiff !== 0) {
    return lenDiff;
  }
  for (let i = 0; i < a.length; i++) {
    const itemA = keyFn(a[i]);
    const itemB = keyFn(b[i]);
    const diff = itemB.localeCompare(itemA);
    if (diff !== 0) {
      return diff;
    }
  }
  return 0;
}

import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import sv from "./locales/sv/sv.json";

const resources = {
  sv: {
    translation: sv,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "sv",
  debug: true,
});

import type { FC, PropsWithChildren } from "react";
import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import { TeamGroupsProvider } from "contexts/TeamGroupsContext";
import { CurrentTeamGroupProvider } from "hooks/CurrentTeamGroup";
import { useMe } from "hooks/Me";

import { FullPageLoading, LoggedInPageLoading } from "components/loading";

import ErrorScreen from "./layout/ErrorScreen";
import { DRAWER_WIDTH, SideMenu } from "./navigation/SideMenu";

type RightSideProps = {
  width?: string;
};

function RightSide({
  width: widthProp = "81vw",
  children,
}: PropsWithChildren<RightSideProps>) {
  return (
    <Stack id="right-side" width={widthProp} height="100vh" flexBasis="100%">
      <Box sx={{ height: "100vh", overflowY: "auto" }}>
        <Sentry.ErrorBoundary fallback={ErrorScreen}>
          <Suspense fallback={<FullPageLoading />}>{children}</Suspense>
        </Sentry.ErrorBoundary>
      </Box>
    </Stack>
  );
}

function Frame({ children }: PropsWithChildren) {
  const rightSideWidth = `calc(100vw - ${DRAWER_WIDTH}px)`;

  return (
    <Stack direction="row">
      <SideMenu />
      <RightSide width={rightSideWidth}>{children}</RightSide>
    </Stack>
  );
}

/**
 * Only shows the content if user is logged in.
 */
const AuthWrapper: FC<PropsWithChildren<{ redirectTo: string }>> = ({
  redirectTo,
  children,
}: PropsWithChildren<{ redirectTo: string }>) => {
  const me = useMe();

  return me?.id ? <>{children}</> : <Navigate to={redirectTo} />;
};

export const ProtectedRoute: FC<{ redirectTo: string }> = ({ redirectTo }) => {
  return (
    <Suspense fallback={<FullPageLoading />}>
      <AuthWrapper redirectTo={redirectTo}>
        <Suspense fallback={<LoggedInPageLoading />}>
          <CurrentTeamGroupProvider>
            <TeamGroupsProvider>
              <Frame>
                <Outlet />
              </Frame>
            </TeamGroupsProvider>
          </CurrentTeamGroupProvider>
        </Suspense>
      </AuthWrapper>
    </Suspense>
  );
};

import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

import { FormikSwitchWithTitleAndDesc } from "./common";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function UniqueWeeksField({ disabled, readOnly }: Props) {
  return (
    <FormikSwitchWithTitleAndDesc
      name="uniqueWeeks"
      title="Unika veckor på behovsprofilen"
      description={
        <Typography>
          Om detta alternativ är påslaget så kan du specificera{" "}
          <Link to={`/demand`}>Behovsprofilen</Link> på unika veckor. Om
          alternativet är avstängt, så specificeras behovet endast för en
          enskild typvecka. Notera! Om detta alternativ är påslaget kommer alla
          enhetens behovsprofiler (för alla avdelningar och alla kompetenser) få
          unika veckor.
        </Typography>
      }
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import { isNonNull } from "relay-help/arrays";
import type { RecordProxy, SelectorStoreUpdater } from "relay-runtime";

import {
  CreateRuleGroupMutation as Mutation,
  CreateRuleGroupMutation$data as Data,
} from "./types";

const mutation = graphql`
  mutation CreateRuleGroupMutation($input: CreateRuleGroupInput!) {
    createRuleGroup(input: $input) {
      ruleGroup {
        id
        name
        description
        teamGroup {
          id
        }
      }
    }
  }
`;

function update(
  r: RecordProxy,
  key: string,
  newRecord: any,
  linkedRecordArgs = {},
) {
  const list = r.getLinkedRecords(key, linkedRecordArgs);
  const newList = [...(list || []).filter(isNonNull), newRecord];
  r.setLinkedRecords(newList, key, linkedRecordArgs);
}

const updater: SelectorStoreUpdater<Data> = (store, res) => {
  const newRecord = store
    .getRootField("createRuleGroup")
    .getLinkedRecord("ruleGroup");
  if (!newRecord) return;

  const teamGroupId = res?.createRuleGroup?.ruleGroup?.teamGroup.id;
  const root = store.getRoot();
  update(root, "ruleGroups", newRecord, { teamGroupId });
};

export const useCreateRuleGroup = () =>
  useMutation<Mutation>(mutation, { updater });

import type { PropsWithChildren } from "react";
import Box from "@mui/material/Box";

type Props = PropsWithChildren<{}>;

export function Level3Box({ children }: Props) {
  return (
    <Box
      sx={{
        p: 2,
        minHeight: "88vh",
        maxHeight: "100vh",
        height: "100%",
        width: "100%",
        overflowY: "auto",
      }}
    >
      {children}
    </Box>
  );
}

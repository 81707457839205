import { useCallback, useMemo } from "react";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandIcon,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import {
  calculateTimeDuration,
  deriveShiftDayType,
  deriveWorktime,
} from "business_logic/Shifts";
import type { ArrayHelpers } from "formik";
import { ErrorMessage, FieldArray } from "formik";
import { minutes2HHMM } from "helpers/dateFunctions";
import useFormikState from "hooks/useFormikState";
import { useBooleanState } from "utils/useBooleanState";

import { ListItemWithIcon } from "components/common/ListItemWithIcon";
import { RowMenu } from "components/common/RowMenu";
import CustomField from "components/forms/CustomField";

import { useShiftDayType } from "../ShiftDayTypeContext";
import type { ShiftInput } from "../types";

import { initialShiftPart } from "./constants";
import { RowTemplate } from "./RowTemplate";
import ShiftPartTable from "./ShiftPartTable";

type Props = {
  name: string;
  onRemove: () => void;
  hidden?: boolean;
  disabled?: boolean;
};
type RenderArrayProps = Pick<ArrayHelpers<ShiftInput[]>, "push"> &
  Pick<Props, "name" | "onRemove" | "disabled">;

export function ShiftTableRow({
  name,
  onRemove,
  hidden = false,
  disabled = false,
}: Props) {
  if (hidden) return null;

  return (
    <FieldArray
      name={`${name}.shiftParts`}
      render={({ push }) => (
        <RenderArray
          push={push}
          name={name}
          onRemove={onRemove}
          disabled={disabled}
        />
      )}
    />
  );
}

function RenderArray({ push, name, onRemove, disabled }: RenderArrayProps) {
  const {
    value: showParts,
    setValue: setShowParts,
    toggle: toggleShowParts,
  } = useBooleanState();
  const { value: shift } = useFormikState<ShiftInput>(name);

  const getName = useCallback((k: string) => `${name}.${k}`, [name]);

  const workTime = minutes2HHMM(deriveWorktime(shift));
  const duration = minutes2HHMM(calculateTimeDuration(shift.start, shift.end));

  const dayTypeBreakpoints = useShiftDayType();
  const dayType = useMemo(
    () => deriveShiftDayType(shift, dayTypeBreakpoints),
    [shift, dayTypeBreakpoints],
  );

  const onAddPart = useCallback(() => {
    const newPart = { ...initialShiftPart };
    newPart.start = shift.start;
    newPart.end = shift.end;

    const partsLength = shift.shiftParts.length;
    if (partsLength > 0) {
      newPart.start = shift.shiftParts[partsLength - 1].end;
    }

    push(newPart);
    setShowParts(true);
  }, [push, shift, setShowParts]);

  return (
    <>
      <RowTemplate
        toggle={
          <ExpandButton
            onClick={toggleShowParts}
            showParts={showParts}
            disabled={disabled}
          />
        }
        name={
          <NameField
            name={getName("name")}
            partCount={shift.shiftParts.length}
            disabled={disabled}
          />
        }
        start={<TimeField name={getName("start")} disabled={disabled} />}
        end={<TimeField name={getName("end")} disabled={disabled} />}
        breakTime={
          <BreakTimeField name={getName("breakTime")} disabled={disabled} />
        }
        duration={<Text>{duration}</Text>}
        workTime={<Text>{workTime}</Text>}
        dayType={<Text>{dayType}</Text>}
        actions={
          <Actions add={onAddPart} remove={onRemove} disabled={disabled} />
        }
      />
      <ErrorMessage
        name={name}
        render={(msg) => (
          <>
            {typeof msg === "string" && (
              <MuiTableRow>
                <TableCell colSpan={9}>
                  <Typography color="error">
                    {typeof msg === "string" ? msg : undefined}
                  </Typography>
                </TableCell>
              </MuiTableRow>
            )}
          </>
        )}
      />

      {showParts && (
        <>
          <ShiftPartTable name={getName("shiftParts")} disabled={disabled} />
          <MuiTableRow>
            <TableCell />
            <TableCell colSpan={9}>
              <Button
                onClick={onAddPart}
                startIcon={<AddIcon />}
                disabled={disabled}
              >
                Lägg till passdel
              </Button>
            </TableCell>
          </MuiTableRow>
        </>
      )}
    </>
  );
}

const ExpandButton = ({
  onClick,
  showParts,
  disabled,
}: {
  onClick: VoidFunction;
  showParts: boolean;
  disabled?: boolean;
}) => (
  <IconButton onClick={onClick} disabled={disabled}>
    <ExpandIcon sx={{ transform: showParts ? "rotate(180deg)" : undefined }} />
  </IconButton>
);

const NameField = ({
  name,
  partCount,
  disabled,
}: {
  name: string;
  partCount: number;
  disabled?: boolean;
}) => (
  <CustomField
    name={name}
    fullWidth
    disabled={disabled}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end" hidden={!partCount}>
          <Chip label={partCount} size="small" />
        </InputAdornment>
      ),
    }}
  />
);

const TimeField = ({
  name,
  disabled,
}: {
  name: string;
  disabled?: boolean;
}) => (
  <CustomField
    type="time"
    name={name}
    disabled={disabled}
    sx={{ minWidth: 100 }}
  />
);

const BreakTimeField = ({
  name,
  disabled,
}: {
  name: string;
  disabled?: boolean;
}) => (
  <CustomField
    type="number"
    name={name}
    disabled={disabled}
    sx={{ minWidth: 68 }}
  />
);

const Text = ({ children }: React.PropsWithChildren) => (
  <Typography sx={{ fontSize: 14 }}>{children}</Typography>
);

const Actions = ({
  add,
  remove,
  disabled,
}: {
  add: VoidFunction;
  remove: VoidFunction;
  disabled?: boolean;
}) => (
  <RowMenu disabled={disabled}>
    <ListItemWithIcon
      component={MenuItem}
      onClick={add}
      icon={<AddIcon />}
      label="Lägg till passdel"
      disabled={disabled}
    />
    <ListItemWithIcon
      component={MenuItem}
      onClick={remove}
      icon={<DeleteIcon />}
      label="Ta bort"
      disabled={disabled}
    />
  </RowMenu>
);

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import {
  Types6Info as Info,
  Types6User,
} from "components/setting/common/forms";

const MODULE_NAME = "ShiftDayTypeFairness";

type TitleProps = { name: string };
type FormProps = TitleProps & {
  name: string;
  groupName: string;
  readOnly?: boolean;
};

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande Dag-/Kväll-/Natt-rättvisa för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}
function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      type="onlySoft"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Types6Form({ name, groupName, readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title name={name} />}
        />
        <Types6User disabled={disabled} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

import type { SkeletonProps } from "@mui/material";
import { Button, Skeleton } from "@mui/material";

import {
  DateGridScaffold,
  FormFieldsScaffold,
  FormHeaderScaffold,
  HeaderActionsScaffold,
  MeetingFormScaffold,
  MeetingGraphScaffold,
  MeetingListScaffold,
  MeetingsScaffold,
  ShiftSectionScaffold,
} from "components/meetings";
import { HeaderInfoScaffold } from "components/meetings/form/HeaderInfo";

export function MeetingsPlaceholder() {
  return (
    <MeetingsScaffold>
      <MeetingListScaffold>
        <MeetingListElement />
        <MeetingListElement />
        <MeetingListElement />
      </MeetingListScaffold>
      <MeetingGraphScaffold>
        <RectSkeleton height={200} />
      </MeetingGraphScaffold>
    </MeetingsScaffold>
  );
}

export function CreateMeetingPlaceholder() {
  return <MeetingFormPlaceholder />;
}

export function EditMeetingPlaceholder() {
  return (
    <MeetingFormPlaceholder
      cancelButtonText="Avbryt redigering"
      submitButtonText="Spara ändringar"
    />
  );
}

/** Default values of `cancelButtonWidth` and `submitButtonWidth` fit CreateMeetingPlaceholder widths. */
function MeetingFormPlaceholder({
  cancelButtonText = "Förkasta",
  submitButtonText = "Skapa möte",
}: {
  cancelButtonText?: string;
  submitButtonText?: string;
}) {
  return (
    <MeetingFormScaffold>
      <FormHeaderScaffold
        headerInfo={
          <HeaderInfoScaffold
            title={<Skeleton width={100} sx={{ fontSize: 24 }} />}
            description={<Skeleton width={100} sx={{ fontSize: 30 }} />}
            duration={<Skeleton width={70} />}
            userCount={<Skeleton width={30} />}
          />
        }
        formInfo={<RectSkeleton width={64} height={26.25} />}
        actions={
          <HeaderActionsScaffold
            cancelButton={<Button variant="loading">{cancelButtonText}</Button>}
            submitButton={<Button variant="loading">{submitButtonText}</Button>}
          />
        }
      />
      <FormFieldsScaffold
        titleField={<RectSkeleton height={38.56} />}
        startField={<RectSkeleton height={38.56} />}
        endField={<RectSkeleton height={38.56} />}
        dateGridSection={
          <DateGridScaffold matrix={<RectSkeleton height={200} />} />
        }
        userField={<RectSkeleton height={54.56} />}
        userList={<RectSkeleton height={200} />}
        shiftsSection={<ShiftSectionPlaceholder />}
      />
    </MeetingFormScaffold>
  );
}

const MeetingListElement = () => <RectSkeleton height={48} />;

export const ShiftSectionPlaceholder = () => (
  <ShiftSectionScaffold inFlight shiftsField={<RectSkeleton height={300} />} />
);

const RectSkeleton: React.FC<SkeletonProps> = (p) => (
  <Skeleton variant="rectangular" {...p} />
);

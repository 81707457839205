/**
 * @generated SignedSource<<e85fbd65638722f07b3064a0b8354b32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SettingsQuery$variables = {
  teamGroupId: string;
};
export type SettingsQuery$data = {
  readonly ruleGroups: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly ruleGroupSetting: {
      readonly settingModules: ReadonlyArray<string>;
    } | null;
  }>;
  readonly teamGroup: {
    readonly members: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly fullName: string;
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly setting: {
      readonly constraintModules: ReadonlyArray<string>;
      readonly showAdvancedSettings: boolean;
    } | null;
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        } | null;
      } | null>;
    };
  } | null;
  readonly usersOnTeamGroup: ReadonlyArray<{
    readonly fullName: string;
    readonly id: string;
    readonly userSetting: {
      readonly id: string;
      readonly settingModules: ReadonlyArray<string>;
    } | null;
  }>;
};
export type SettingsQuery = {
  response: SettingsQuery$data;
  variables: SettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamGroupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "teamGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settingModules",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "teamGroupId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "constraintModules",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showAdvancedSettings",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamNodeConnection",
  "kind": "LinkedField",
  "name": "teams",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNodeConnection",
  "kind": "LinkedField",
  "name": "members",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  (v4/*: any*/),
  (v2/*: any*/)
],
v12 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "usersOnTeamGroup",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSettingNode",
      "kind": "LinkedField",
      "name": "userSetting",
      "plural": false,
      "selections": (v11/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "ruleGroups",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupSettingNode",
            "kind": "LinkedField",
            "name": "ruleGroupSetting",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "TeamGroupNode",
        "kind": "LinkedField",
        "name": "teamGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingNode",
            "kind": "LinkedField",
            "name": "setting",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      (v12/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "ruleGroups",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupSettingNode",
            "kind": "LinkedField",
            "name": "ruleGroupSetting",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "TeamGroupNode",
        "kind": "LinkedField",
        "name": "teamGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingNode",
            "kind": "LinkedField",
            "name": "setting",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v10/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v12/*: any*/)
    ]
  },
  "params": {
    "cacheID": "01beccdea737f5c6a3637571220b5d74",
    "id": null,
    "metadata": {},
    "name": "SettingsQuery",
    "operationKind": "query",
    "text": "query SettingsQuery(\n  $teamGroupId: ID!\n) {\n  ruleGroups(teamGroupId: $teamGroupId) {\n    id\n    name\n    ruleGroupSetting {\n      settingModules\n      id\n    }\n  }\n  teamGroup(id: $teamGroupId) {\n    setting {\n      constraintModules\n      showAdvancedSettings\n      id\n    }\n    teams {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    members {\n      edges {\n        node {\n          id\n          fullName\n        }\n      }\n    }\n    id\n  }\n  usersOnTeamGroup(teamGroupId: $teamGroupId) {\n    fullName\n    id\n    userSetting {\n      settingModules\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "47525b89cf526c0fc4981570bf7433ed";

export default node;

import AdvancedGroupIcon from "@mui/icons-material/AdminPanelSettings";

import type { MenuItem } from "../types";

export const menuItems: MenuItem[] = [
  {
    sectionTitle: "Grundinställningar",
    sectionID: "b",
    menuRows: [
      {
        itemID: 6,
        title: "Kostnadsinställningar",
        constraintModule: "HourlyCost",
      },
    ],
  },
  {
    sectionTitle: "Behov",
    sectionID: "d",
    menuRows: [
      {
        itemID: 1,
        title: "Timmar & Pass",
        constraintModule: "HoursAndShifts",
      },
    ],
  },
  {
    sectionTitle: "Allmänna Bestämmelser",
    sectionID: "ab",
    menuRows: [
      {
        itemID: 0,
        title: "Dygnsvila",
        constraintModule: "DailyRestAB",
      },
      {
        itemID: 1,
        title: "Veckovila",
        constraintModule: "WeeklyRestAB",
      },
    ],
  },
  {
    sectionTitle: "Schemarytm",
    sectionID: "r",
    menuRows: [
      {
        itemID: 1,
        title: "Sammanhängande ledighet",
        constraintModule: "ConsecutiveFreeDays",
      },
      {
        itemID: 2,
        title: "Sammanhängande arbete",
        constraintModule: "ConsecutiveWorkDays",
      },
    ],
  },
  {
    sectionTitle: "Ledighet",
    sectionID: "w",
    menuRows: [
      { itemID: 0, title: "Helgvila", constraintModule: "FreeWeekends" },
    ],
  },
  {
    sectionTitle: "Särskilda passtyper",
    sectionID: "s",
    menuRows: [
      {
        itemID: 2,
        title: "Passtypsfördelning",
        constraintModule: "ShiftTypeDistribution",
      },
    ],
  },
  {
    sectionTitle: "Avancerat",
    sectionID: "advanced",
    icon: <AdvancedGroupIcon sx={{ color: "text.secondary" }} />,
    menuRows: [
      { itemID: 1, title: "Deltidsmått", constraintModule: "HoursAndShifts" },
      { itemID: 6, title: "Nattpass", constraintModule: "NightShifts" },
    ],
  },
];

/**
 * @generated SignedSource<<980ca206434307cf60af832e6734386c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateRuleGroupInput = {
  clientMutationId?: string | null;
  description: string;
  name: string;
  teamGroupId: string;
};
export type CreateRuleGroupMutation$variables = {
  input: CreateRuleGroupInput;
};
export type CreateRuleGroupMutation$data = {
  readonly createRuleGroup: {
    readonly ruleGroup: {
      readonly description: string;
      readonly id: string;
      readonly name: string;
      readonly teamGroup: {
        readonly id: string;
      };
    } | null;
  } | null;
};
export type CreateRuleGroupMutation = {
  response: CreateRuleGroupMutation$data;
  variables: CreateRuleGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRuleGroupPayload",
    "kind": "LinkedField",
    "name": "createRuleGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "ruleGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "teamGroup",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateRuleGroupMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateRuleGroupMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c0b1c57a836a8a1a49bec4b6ec435652",
    "id": null,
    "metadata": {},
    "name": "CreateRuleGroupMutation",
    "operationKind": "mutation",
    "text": "mutation CreateRuleGroupMutation(\n  $input: CreateRuleGroupInput!\n) {\n  createRuleGroup(input: $input) {\n    ruleGroup {\n      id\n      name\n      description\n      teamGroup {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91ad5f7930d16c46fb3037c21224ae06";

export default node;

import { Box, Stack, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { minutes2HHMM } from "helpers/dateFunctions";
import { Colors } from "styles/colors";

function lineStyle(id: string) {
  const dashed = {
    strokeWidth: 1,
    strokeDasharray: "2,1",
  };
  const normal = {
    strokeWidth: 2,
  };
  const solidBig = {
    strokeWidth: 3,
  };
  switch (id) {
    case "durationMax":
      return dashed;
    case "durationMin":
      return dashed;
    case "shiftMax":
      return solidBig;
    case "shiftMin":
      return solidBig;
    case "duration":
      return normal;
    case "hours":
      return normal;
    case "shifts":
      return normal;
  }
}

const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }: any) => {
  return series.map(({ id, data, color }: any, index: any) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d: any) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        })),
      )}
      fill="none"
      stroke={color}
      style={lineStyle(id)}
    />
  ));
};

export default function PartTimeGraph(data: any, label: string) {
  return (
    <Box sx={{ height: 200 }}>
      <ResponsiveLine
        data={
          label === "duration"
            ? data.filter((o: any) =>
                [
                  "duration",
                  "durationMax",
                  "durationMin",
                  "shiftMax",
                  "shiftMin",
                ].includes(o.id),
              )
            : data.filter((o: any) => o.id === label)
        }
        margin={{ top: 25, right: 25, bottom: 50, left: 50 }}
        curve={label === "shifts" ? "stepAfter" : "cardinal"}
        xScale={{ type: "linear" }}
        enableSlices="x"
        yFormat=" >-.2f"
        enablePoints={false}
        axisBottom={{
          legend: "Tjänstgöringsgrad (%)",
          legendPosition: "middle",
          legendOffset: 40,
        }}
        axisLeft={{
          legend: `Antal ${label === "shifts" ? "pass" : "timmar"}`,
          legendPosition: "middle",
          legendOffset: -42,
        }}
        colors={
          label === "duration"
            ? [
                Colors.GREEN,
                Colors.LILAC,
                Colors.BLUE,
                Colors.BLUE,
                Colors.BLUE,
              ]
            : [Colors.BLUE]
        }
        theme={{ text: { fontFamily: "Nunito" } }}
        layers={[
          // includes all default layers
          "grid",
          "markers",
          "axes",
          "areas",
          "crosshair",
          "slices",
          "points",
          "mesh",
          "legends",
          DashedSolidLine, // add the custome layer here
        ]}
        sliceTooltip={({ slice }) => {
          const degree = Number(slice.points[0].data.xFormatted);
          const duration = Math.round(100 * data[4].data[degree].y) / 100;
          const toolTipSx = {
            bgcolor: "white",
            py: 1,
            px: 1.5,
            borderRadius: "4px",
            border: "1px solid",
            borderColor: "divider",
          };

          if (label !== "duration") {
            return (
              <Stack sx={toolTipSx}>
                <Typography variant="caption">
                  <b>Tjänstgöringsgrad:</b> {degree}%
                  <br />
                  <b>Antal {label === "hours" ? "timmar" : "pass"}:</b>{" "}
                  {label === "hours"
                    ? minutes2HHMM(
                        60 * (slice.points[0].data.yFormatted as number),
                      )
                    : Math.round(slice.points[0].data.yFormatted as number)}
                  <br />
                  <b>Timmar per pass:</b> {minutes2HHMM(60 * duration)}
                </Typography>
              </Stack>
            );
          } else {
            const outside =
              slice.points[2].data.yFormatted >
                slice.points[4].data.yFormatted ||
              slice.points[2].data.yFormatted < slice.points[3].data.yFormatted;
            return (
              <Stack sx={toolTipSx} gap={1}>
                <Typography variant="caption">
                  <b>Tjänstgöringsgrad:</b> {degree}%
                </Typography>

                <Stack>
                  <Typography variant="caption" sx={{ color: Colors.GREEN }}>
                    <Stack direction="row">
                      <Stack width={210}>
                        Längsta tillgängliga passlängd:{" "}
                      </Stack>
                      <Stack>
                        {minutes2HHMM(
                          60 * (slice.points[4].data.yFormatted as number),
                        )}
                      </Stack>
                    </Stack>
                  </Typography>

                  <Typography
                    variant="caption"
                    sx={{ color: outside ? Colors.ROUGE : Colors.TEXTCOLOR }}
                  >
                    <Stack direction="row">
                      <Stack width={210}>
                        Förväntad genomsnittlig passlängd:{" "}
                      </Stack>
                      <Stack>
                        {minutes2HHMM(
                          60 * (slice.points[2].data.yFormatted as number),
                        )}
                      </Stack>
                    </Stack>
                  </Typography>

                  <Typography variant="caption" sx={{ color: Colors.LILAC }}>
                    <Stack direction="row">
                      <Stack width={210}>
                        Kortaste tillgängliga passlängd:{" "}
                      </Stack>
                      <Stack>
                        {minutes2HHMM(
                          60 * (slice.points[3].data.yFormatted as number),
                        )}
                      </Stack>
                    </Stack>
                  </Typography>
                </Stack>
              </Stack>
            );
          }
        }}
      />
    </Box>
  );
}

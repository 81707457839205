import { useCallback, useMemo } from "react";
import { ListItem, ListItemText } from "@mui/material";

import type { GenericRenderGroupParams } from "../GenericSelect";

import { MultiSelect } from "./MultiSelect";
import { SingleSelect } from "./SingleSelect";
import type { MultiProps, Option, Props, SingleProps } from "./types";

function sortOptions(a: Option, b: Option) {
  return (
    a.group.name.localeCompare(b.group.name) || a.name.localeCompare(b.name)
  );
}

export function BaseTeamSelect({
  multiple,
  groupByGroup = false,
  filterOnGroupIds = [],
  options: optionsProp,
  insert,
  noOptionsText = "Inga ytterligare avdelningar finns tillagda",
  getOptionLabel = (option: Option) => option?.name || "",
  ...props
}: Props) {
  const alternativeNoOptionsText = "Välj enhet för att visa avdelningar";
  const optFilter = useCallback(
    (o: Option | null) =>
      !!o &&
      (filterOnGroupIds.length === 0 ||
        filterOnGroupIds.includes(o?.group?.id || "")),
    [filterOnGroupIds],
  );

  const groupBy = useMemo(
    () => (groupByGroup ? (o: Option) => o.group.id ?? "" : undefined),
    [groupByGroup],
  );

  const options = useMemo(
    () => [
      ...(insert?.before ?? []),
      ...(optionsProp ?? []).filter(optFilter).sort(sortOptions),
      ...(insert?.after ?? []),
    ],
    [optFilter, optionsProp, insert],
  );

  const groupNames = useMemo(
    () =>
      options.reduce(
        (acc, o) => {
          const { id, name } = o.group || {};
          if (id && !acc[id]) {
            acc[id] = name;
          }
          return acc;
        },
        {} as Record<string, string>,
      ),
    [options],
  );

  const getGroupLabel = useCallback(
    (group: string) => groupNames[group],
    [groupNames],
  );

  const renderGroup = useCallback(
    (params: GenericRenderGroupParams) => [
      <ListItem key={params.key} sx={{ py: 0.2 }}>
        <ListItemText primary={getGroupLabel(params.group)} />
      </ListItem>,
      params.children,
    ],
    [getGroupLabel],
  );

  if (multiple) {
    return (
      <MultiSelect
        groupBy={groupBy}
        renderGroup={renderGroup}
        {...(props as MultiProps)}
        options={options}
        noOptionsText={
          filterOnGroupIds.length ? noOptionsText : alternativeNoOptionsText
        }
        getOptionLabel={getOptionLabel}
      />
    );
  }
  return (
    <SingleSelect
      groupBy={groupBy}
      renderGroup={renderGroup}
      {...(props as SingleProps)}
      options={options}
      noOptionsText={
        filterOnGroupIds.length ? noOptionsText : alternativeNoOptionsText
      }
      getOptionLabel={getOptionLabel}
    />
  );
}

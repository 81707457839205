import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  connectionToArray,
  isNonNull,
  useConnectionToArray,
} from "relay-help/arrays";

import {
  ScheduleGroupSettingsCompetenceList,
  ScheduleGroupSettingsRuleGroupList,
  ScheduleGroupSettingsTeamList,
  ScheduleGroupSettingsViewer,
} from "components/schedule/schedule_group_settings";

import type { ScheduleGroupSettingsQuery as Query, ShiftPart } from "./types";

type Props = {
  scheduleId: string;
};

type ContentProps = {
  queryRef: PreloadedQuery<Query>;
};

const query = graphql`
  query ScheduleGroupSettingsQuery($id: ID!) {
    schedule(id: $id) {
      teamgroupsettingsnapshot {
        ...ScheduleGroupSettingsViewer_fragment
      }
      competences {
        ...ScheduleGroupSettingsCompetenceList_competences
      }
      rulegroupsnapshots {
        ...ScheduleGroupSettingsRuleGroupList_ruleGroups
      }
      teams {
        ...ScheduleGroupSettingsTeamList_teams
      }
      shifts {
        edges {
          node {
            id
            name
            start
            end
            shiftParts {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Content({ queryRef }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);

  const shifts = useConnectionToArray(data.schedule?.shifts);
  const shiftTypes = useMemo(
    () =>
      shifts.filter(isNonNull).map((x) => ({
        ...x,
        shiftParts: connectionToArray<ShiftPart>(x.shiftParts),
      })),
    [shifts],
  );

  if (!data.schedule?.teamgroupsettingsnapshot) {
    return <p>Laddar...</p>;
  }

  return (
    <Stack gap={1}>
      <ScheduleGroupSettingsTeamList fragmentRef={data.schedule.teams} />
      <ScheduleGroupSettingsCompetenceList
        fragmentRef={data.schedule.competences}
      />
      <ScheduleGroupSettingsRuleGroupList
        fragmentRef={data.schedule.rulegroupsnapshots}
      />
      <ScheduleGroupSettingsViewer
        fragmentRef={data.schedule.teamgroupsettingsnapshot}
        shiftTypes={shiftTypes}
      />
    </Stack>
  );
}

export function ScheduleGroupSettings({ scheduleId: id }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    if (!id) return;
    loadQuery({ id });
  }, [loadQuery, id]);

  return <Suspense>{!!queryRef && <Content queryRef={queryRef} />}</Suspense>;
}

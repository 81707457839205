import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { BaseCompetenceSelect as BaseSelect } from "./BaseCompetenceSelect";
import { useCompetenceSelectFragmentRef } from "./CompetenceSelectFragmentRefContext";
import type {
  CompetenceSelect_fragment$key as Key,
  Option,
  Props as SelectProps,
} from "./types";

const fragment = graphql`
  fragment CompetenceSelect_fragment on CompetenceNode @relay(plural: true) {
    id
    name
    color
  }
`;

type Props = Omit<SelectProps, "options">;

export function CompetenceSelect(props: Props) {
  const fragmentRef = useCompetenceSelectFragmentRef();
  const competences = useFragment<Key>(fragment, fragmentRef);

  const options = useMemo<Option[]>(
    () =>
      (competences || []).map((c) => ({
        id: c?.id || "",
        name: c?.name || "",
        color: c?.color || "#616161",
      })),
    [competences],
  );
  const sx = useMemo(
    () => ({ minWidth: 170, ...(props.sx ?? {}) }),
    [props.sx],
  );

  return <BaseSelect {...(props as SelectProps)} options={options} sx={sx} />;
}

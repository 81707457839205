import { useMemo } from "react";
import { Typography } from "@mui/material";
import { calculateShiftDayType } from "business_logic/Shifts";
import { DateTime } from "luxon";

import type { Group } from "components/shifts/graphs/ShiftTimeline/TimelineRenderer";
import { TimelineRenderer } from "components/shifts/graphs/ShiftTimeline/TimelineRenderer";
import type {
  ShiftDayType,
  ShiftTimelineType,
} from "components/shifts/graphs/ShiftTimeline/types";
import { shiftTimelineTypeEnum } from "components/shifts/graphs/ShiftTimeline/types";
import type { DayTypeBreakpoints } from "components/shifts/ShiftDayTypeContext";
import { useShiftDayType } from "components/shifts/ShiftDayTypeContext";

import { START_OF_WEEK } from "../utils";

export type Item = {
  id: number;
  group: string;
  start: Date;
  end: Date;
  dayType: ShiftDayType;
  shiftType: ShiftTimelineType;
  breakTime: number;
};

export type GraphData = {
  groups: Group[];
  items: Item[];
};

export interface GraphShiftPart {
  start: Date;
  end: Date;
  partType: string;
}
export interface GraphShift {
  name: string;
  start: Date;
  end: Date;
  day: number;
  breakTime: number;
  shiftParts: ReadonlyArray<GraphShiftPart>;
}
type Props = {
  shifts: ReadonlyArray<GraphShift>;
  showFullWeek?: boolean;
  startOfWeek?: DateTime;
};

/** Plot shift profile for days in shifts[*].day. */
export function ShiftGraph({
  shifts,
  startOfWeek = START_OF_WEEK,
  showFullWeek = false,
}: Props) {
  const { groups, items, start, end } = useShiftGraph(
    startOfWeek,
    shifts,
    showFullWeek,
  );

  if (items.length < 1) {
    return (
      <Typography pt={3} fontSize={13}>
        Denna period saknar mötespass!
      </Typography>
    );
  }

  return (
    <TimelineRenderer groups={groups} items={items} start={start} end={end} />
  );
}

/** Resolve data to use in ShiftGraph. */
export const useShiftGraph = (
  startOfWeek: DateTime,
  shifts: ReadonlyArray<GraphShift>,
  showFullWeek: boolean,
) => {
  const dayTypeBreakpoints = useShiftDayType();
  const shiftDays = useMemo(
    () => Array.from(new Set(shifts.map((s) => s.day))),
    [shifts],
  );
  const minDay = useMemo(
    () => (shiftDays.length < 1 || showFullWeek ? 0 : Math.min(...shiftDays)),
    [showFullWeek, shiftDays],
  );
  const maxDay = useMemo(() => {
    if (showFullWeek) return 6;
    return shiftDays.length < 1 ? 0 : Math.max(...shiftDays);
  }, [showFullWeek, shiftDays]);
  const start = useMemo(
    () => startOfWeek.plus({ days: minDay }).startOf("day").toJSDate(),
    [startOfWeek, minDay],
  );
  const end = useMemo(
    () => startOfWeek.plus({ days: maxDay }).endOf("day").toJSDate(),
    [startOfWeek, maxDay],
  );

  const { groups, items } = useShiftsToGraphData(shifts, dayTypeBreakpoints);

  return {
    groups,
    items,
    start,
    end,
  };
};

function useShiftsToGraphData(
  shifts: ReadonlyArray<GraphShift>,
  dayTypeBreakpoints: DayTypeBreakpoints,
) {
  return useMemo(
    () => shiftsToGraphData(shifts, dayTypeBreakpoints),
    [shifts, dayTypeBreakpoints],
  );
}

/** Transform `shifts` to use in `ShiftGraph`. */
function shiftsToGraphData(
  shifts: ReadonlyArray<GraphShift>,
  dayTypeBreakpoints: DayTypeBreakpoints,
): GraphData {
  const groups: Group[] = [];
  const items: Item[] = [];

  const shiftsSorted = (shifts?.slice() || []).sort(
    (a, b) => a.start.valueOf() - b.start.valueOf(),
  );

  for (let shift of shiftsSorted) {
    const { group, items: shiftItems } = shiftToGraphElements(
      shift,
      String(groups.length + 1),
      items.length,
      dayTypeBreakpoints,
    );
    groups.push(group);
    items.push(...shiftItems);
  }

  return { groups, items };
}

function shiftToGraphElements(
  shift: GraphShift,
  groupId: string,
  itemIdOffset: number,
  dayTypeBreakpoints: DayTypeBreakpoints,
): { group: Group; items: ReadonlyArray<Item> } {
  const group: Group = { id: groupId, title: shift.name };
  const items: Item[] = [];
  const { start: shiftStart, end: shiftEnd } = shift;

  const dayType = (calculateShiftDayType(
    {
      start: DateTime.fromJSDate(shiftStart).toFormat("HH:mm"),
      end: DateTime.fromJSDate(shiftEnd).toFormat("HH:mm"),
    },
    dayTypeBreakpoints,
  ) || "D") as ShiftDayType;

  // add start [check if shift.end and last part.end overlap]
  //
  // iterate through parts
  // - check if prev part end is same as next part start
  // - - if there is a gap, add shift inbetween
  //
  // add end [check if shift.end and last part.end overlap]

  /** Helper function to create timeline item since several fields are common */
  const toItem = ({
    start,
    end,
    breakTime = 0,
    shiftType = shiftTimelineTypeEnum.S,
  }: {
    start: Date;
    end: Date;
    breakTime?: number;
    shiftType?: ShiftTimelineType;
  }) => ({
    start,
    end,
    breakTime,
    shiftType,
    id: itemIdOffset + items.length,
    group: group.id,
    dayType,
  });

  const { shiftParts } = shift;

  if (shiftParts.length < 1) {
    // No shift parts, shift is one item
    items.push(
      toItem({
        start: shiftStart,
        end: shiftEnd,
        breakTime: shift.breakTime,
      }),
    );
    return { group, items };
  }

  let currentTime = shift.start;

  // The following algorithm require shift parts to be in order.
  // We assume that all parts are within shift start-end.
  const parts = shiftParts
    .slice()
    .sort((a, b) => a.start.valueOf() - b.start.valueOf());

  for (let part of parts) {
    const { start: partStart, end: partEnd } = part;
    // Fill gap before part with shift
    if (partStart > currentTime) {
      items.push(
        toItem({
          start: currentTime,
          end: partStart,
        }),
      );
    }

    // Add part
    items.push(
      toItem({
        start: partStart,
        end: partEnd,
        shiftType: part.partType as ShiftTimelineType,
      }),
    );

    currentTime = partEnd;
  }

  if (currentTime < shiftEnd) {
    // Part did not cover shift end. Fill end
    items.push(
      toItem({
        start: currentTime,
        end: shiftEnd,
        breakTime: shift.breakTime,
      }),
    );
  }
  return { group, items };
}

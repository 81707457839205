/**
 * @generated SignedSource<<b216d828489d7f4298eef59d4c3f2820>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminUsersQuery$variables = {};
export type AdminUsersQuery$data = {
  readonly allRuleGroups: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UserRuleGroupsContext_ruleGroups">;
  }>;
  readonly allUsers: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UsersTable_fragment">;
  }>;
  readonly competences: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CompetenceSelect_fragment">;
  }>;
  readonly teams: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TeamSelect_fragment">;
  }>;
};
export type AdminUsersQuery = {
  response: AdminUsersQuery$data;
  variables: AdminUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  (v0/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUsersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "allUsers",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UsersTable_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "competences",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetenceSelect_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "allRuleGroups",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserRuleGroupsContext_ruleGroups"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamSelect_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminUsersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "allUsers",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentDegree",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentForm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetenceNodeConnection",
            "kind": "LinkedField",
            "name": "competences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetenceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetenceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timebankBaseBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSettingNode",
            "kind": "LinkedField",
            "name": "userSetting",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNodeConnection",
            "kind": "LinkedField",
            "name": "groupMemberOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamGroupNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamGroupNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "memberOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "teamAdminOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNodeConnection",
            "kind": "LinkedField",
            "name": "groupAdminOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamGroupNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamGroupNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "competences",
        "plural": true,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "allRuleGroups",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "teamGroup",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8136ebcc1240e09f7d7a9cda23637673",
    "id": null,
    "metadata": {},
    "name": "AdminUsersQuery",
    "operationKind": "query",
    "text": "query AdminUsersQuery {\n  allUsers {\n    ...UsersTable_fragment\n    id\n  }\n  competences {\n    ...CompetenceSelect_fragment\n    id\n  }\n  allRuleGroups {\n    ...UserRuleGroupsContext_ruleGroups\n    id\n  }\n  teams {\n    ...TeamSelect_fragment\n    id\n  }\n}\n\nfragment CompetenceSelect_fragment on CompetenceNode {\n  id\n  name\n  color\n}\n\nfragment TeamSelect_fragment on TeamNode {\n  id\n  name\n  color\n  group {\n    id\n    name\n  }\n}\n\nfragment UserRuleGroupsContext_ruleGroups on RuleGroupNode {\n  id\n  name\n  teamGroup {\n    id\n    name\n  }\n}\n\nfragment UsersTable_fragment on UserNode {\n  id\n  fullName\n  username\n  isActive\n  archivedAt\n  email\n  firstName\n  lastName\n  employmentDegree\n  employmentForm\n  employmentTitle\n  competences {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  timebankBaseBalance\n  userSetting {\n    id\n  }\n  groupMemberOf {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  memberOf {\n    edges {\n      node {\n        id\n        name\n        color\n      }\n    }\n  }\n  teamAdminOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  groupAdminOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c24f2301387f271f90352ae0d27fbe46";

export default node;

import { useMemo } from "react";
import {
  DateOrTimeView,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";

type DatePickerWithWeekNumbersProps = {
  onChange: (e: DateTime | null) => void;
  value?: DateTime | null;
  label?: string;
  size?: any;
  locale?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  views?: DateOrTimeView[];
};

export default function DatePickerWithWeekNumbers({
  onChange,
  value,
  label,
  size = "small",
  locale = "sv",
  disabled = false,
  minDate,
  maxDate,
  views = ["year", "month", "day", "hours", "minutes"],
}: DatePickerWithWeekNumbersProps) {
  const minDateTime = useMemo(
    () => (minDate ? DateTime.fromJSDate(minDate) : undefined),
    [minDate],
  );

  const maxDateTime = useMemo(
    () => (maxDate ? DateTime.fromJSDate(maxDate) : undefined),
    [maxDate],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
      <DateTimePicker
        label={label}
        onChange={onChange}
        value={value}
        disabled={disabled}
        displayWeekNumber={true}
        showDaysOutsideCurrentMonth={true}
        minDate={minDateTime}
        maxDate={maxDateTime}
        views={views}
        slotProps={{
          textField: {
            size: size,
            sx: {
              "& .MuiInputBase-root": {
                backgroundColor: "white",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { useSnackbar } from "components/Snackbar";

import {
  editValuesToInput,
  MeetingFormikForm,
  meetingToFormValues,
} from "./form";
import { useEditMeeting } from "./mutations";
import type {
  EditFormValues,
  EditMeetingForm_meeting$key as Key,
  EditMeetingForm_setting$key as SettingKey,
} from "./types";

type Props = {
  teamGroupId: string;
  fragmentRef: Key;
  settingFragmentRef: SettingKey;
  onClose: () => void;
};

const fragment = graphql`
  fragment EditMeetingForm_meeting on MeetingNode {
    id
    name
    start
    end
    dayMatrix
    selectDaysBy
    nrMeetings
    users {
      edges {
        node {
          id
          fullName
        }
      }
    }
    shifts {
      edges {
        node {
          id
          name
          start
          end
          meetingDay
          breakTime
          shiftParts {
            edges {
              node {
                id
                start
                end
                partType
              }
            }
          }
          meetings {
            edges {
              node {
                id
                start
                end
              }
            }
          }
        }
      }
    }
  }
`;

const settingFragment = graphql`
  fragment EditMeetingForm_setting on SettingNode {
    periodLengthWeeks
    ...MeetingForm_setting
  }
`;

export function EditMeetingForm({
  teamGroupId,
  fragmentRef,
  settingFragmentRef,
  onClose,
}: Props) {
  const { initialValues, onSubmit, setting, catchError } = useEditMeetingForm(
    teamGroupId,
    fragmentRef,
    settingFragmentRef,
  );

  return (
    <MeetingFormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      catchError={catchError}
      submitText="Spara ändringar"
      cancelText="Avbryt redigering"
      settingFragmentRef={setting}
    />
  );
}

function useEditMeetingForm(
  teamGroupId: string,
  fragmentRef: Key,
  settingFragmentRef: SettingKey,
) {
  const setting = useFragment<SettingKey>(settingFragment, settingFragmentRef);
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useEditMeeting();
  const { addSnack } = useSnackbar();

  const initialValues = useMemo(
    () => meetingToFormValues(teamGroupId, data, setting.periodLengthWeeks),
    [teamGroupId, data, setting.periodLengthWeeks],
  );
  const onSubmit = useCallback(
    async (values: EditFormValues) => {
      const input = editValuesToInput(values);
      return commit({ variables: { input } });
    },
    [commit],
  );
  const catchError = useCallback(
    (e: Error) => {
      console.error(e);
      addSnack({ message: "Kunde inte spara möte", severity: "error" });
    },
    [addSnack],
  );

  return { initialValues, onSubmit, setting, catchError };
}

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  HeaderWeight,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { AB2, AB2Info as Info } from "components/setting/common/forms";

type FormProps = { groupName: string; readOnly?: boolean };

function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      name="minWeeklyRestAbSoft"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}
function Switch({ readOnly }: { readOnly?: boolean }) {
  return <HeaderSwitch name="WeeklyRestAB" readOnly={readOnly} />;
}
function Title({ name }: { name: string }) {
  return (
    <HeaderTitle
      infoButton={<Info />}
      title={
        <>
          Veckovila för enhet <b>{name}</b>
        </>
      }
    />
  );
}
function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="minWeeklyRestAbWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function AB2Form({ groupName, readOnly = false }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes("WeeklyRestAB");
  const { value: radioSoft } = useFormikState<boolean>("minWeeklyRestAbSoft");

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={<Switch readOnly={readOnly} />}
          titleComponent={<Title name={groupName} />}
          weightComponent={
            <Weight readOnly={readOnly} disabled={disabled || !radioSoft} />
          }
        />
        <AB2 disabled={disabled} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

import { Grid, Typography } from "@mui/material";

type IconLabelProps = {
  icon: React.ReactNode;
  label: string;
};

function IconAndLabel({ icon, label }: IconLabelProps) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      gap={1}
      xs={4}
      spacing={0}
    >
      {icon}
      <Typography>{label}</Typography>
    </Grid>
  );
}

export default IconAndLabel;

import type { PropsWithChildren } from "react";
import { Card as MuiCard, CardHeader, Divider } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderColor: theme.palette.divider,
  borderStyle: "solid",
  borderWidth: 1,
}));

export default function CardWithTitle({
  children,
  title,
  sx = {},
}: PropsWithChildren<{ title?: string; sx?: SxProps<Theme> }>) {
  return (
    <StyledCard elevation={0} sx={sx}>
      {!!title && (
        <>
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: "h5" }}
            sx={{ py: 1.5 }}
          />
          <Divider sx={{ mx: -2 }} />
        </>
      )}
      {children}
    </StyledCard>
  );
}

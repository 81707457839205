import { Stack } from "@mui/material";

import { Shift } from "../../components/setting/common/forms/common/ShiftSelect";

import { ShiftDistributionForm } from "./ShiftDistributionForm";
import { ShiftDistributionTeamGroupOnlyForm } from "./ShiftDistributionTeamGroupOnlyForm";

type Props = {
  shiftTypes: ReadonlyArray<Shift>;
  disabled?: boolean;
  readOnly?: boolean;
};

export function ShiftDistributionFullForm({
  shiftTypes,
  disabled,
  readOnly,
}: Props) {
  return (
    <Stack gap={3}>
      <ShiftDistributionForm
        shiftTypes={shiftTypes}
        disabled={disabled}
        readOnly={readOnly}
      />
      <ShiftDistributionTeamGroupOnlyForm
        disabled={disabled}
        readOnly={readOnly}
      />
    </Stack>
  );
}

import type {
  ApiSettingClockwiseRotationWeightChoices,
  ApiSettingConsecutiveFreeDaysWeightChoices,
  ApiSettingConsecutiveWorkDaysWeightChoices,
  ApiSettingDayTypeDistributionWeightChoices,
  ApiSettingMinDailyRestWeightChoices,
  ApiSettingMinWeeklyRestAbWeightChoices,
  ApiSettingNightShiftRestrictionChoices,
  ApiSettingNightShiftRotationChoices,
  ApiSettingResourceShiftsWeightChoices,
  ApiSettingShiftsEmploymentDegreeLogicChoices,
  ApiSettingShiftTypeDistributionWeightChoices,
  ApiSettingSplitShiftsWeightChoices,
} from "./types";

type HML = "H" | "M" | "L";
export const weightChoices: HML[] = ["H", "M", "L"];

export const minWeeklyRestAbWeightChoices: ApiSettingMinWeeklyRestAbWeightChoices[] =
  ["H", "L", "M"];
export const minDailyRestWeightChoices: ApiSettingMinDailyRestWeightChoices[] =
  ["H", "L", "M"];
export const consecutiveFreeDaysWeightChoices: ApiSettingConsecutiveFreeDaysWeightChoices[] =
  ["H", "L", "M"];
export const consecutiveWorkDaysWeightChoices: ApiSettingConsecutiveWorkDaysWeightChoices[] =
  ["H", "L", "M"];
export const clockwiseRotationWeightChoices: ApiSettingClockwiseRotationWeightChoices[] =
  ["H", "L", "M"];
export const splitShiftsWeightChoices: ApiSettingSplitShiftsWeightChoices[] = [
  "H",
  "L",
  "M",
];
export const resourceShiftsWeightChoices: ApiSettingResourceShiftsWeightChoices[] =
  ["H", "L", "M"];
export const shiftTypeDistributionWeightChoices: ApiSettingShiftTypeDistributionWeightChoices[] =
  ["H", "L", "M"];
export const nightShiftRestrictionChoices: ApiSettingNightShiftRestrictionChoices[] =
  ["L0", "L1", "L2", "L3", "N"];
export const nightShiftRotationChoices: ApiSettingNightShiftRotationChoices[] =
  ["N", "R1", "R2", "R3"];
export const dayTypeDistributionWeightChoices: ApiSettingDayTypeDistributionWeightChoices[] =
  ["H", "L", "M"];

export const shiftsEmploymentDegreeLogicChoices: ApiSettingShiftsEmploymentDegreeLogicChoices[] =
  ["E", "O"];

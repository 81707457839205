import type { ComponentProps, FC, PropsWithChildren } from "react";
import { TransitionGroup } from "react-transition-group";
import { Collapse, Stack } from "@mui/material";
import { FieldArray } from "formik";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import { CreateDemandsInput as FormValues, DemandWeeksInput } from "../types";

import { initialDemandDay } from "./constants";
import { DemandDaysForm } from "./DemandDaysForm";
import { DemandWeekCard } from "./DemandWeekCard";

type Props = {
  name: string;
  weekIdx: number;
  setWeekIdx: (value: number) => void;
  dayIdx: number;
  setDayIdx: (value: number) => void;
};
type ItemProps = Omit<ComponentProps<typeof DemandWeekCard>, "onAdd"> & {
  name: string;
  setDayIdx: (value: number) => void;
};

const UNSELECTED = -1;

const WeekItem: FC<PropsWithChildren<ItemProps>> = ({
  setDayIdx,
  name,
  ...props
}) => (
  <FieldArray name={`${name}.demands`}>
    {({ push: pushRow }: FormikFieldArrayFn<FormValues>) => (
      <DemandWeekCard
        {...props}
        name={name}
        onAdd={() => {
          setDayIdx(UNSELECTED);
          pushRow(initialDemandDay);
        }}
      />
    )}
  </FieldArray>
);

export function DemandWeeksForm({
  name,
  weekIdx,
  setWeekIdx,
  dayIdx,
  setDayIdx,
}: Props) {
  const { value: demands } = useFormikState<DemandWeeksInput[]>(name);

  const toggleOpen = (idx: number) => {
    setWeekIdx(weekIdx === idx ? UNSELECTED : idx);
  };

  return (
    <FieldArray name={name}>
      {({ remove }: FormikFieldArrayFn<FormValues>) => (
        <Stack gap={2} component={TransitionGroup}>
          {(demands || []).map((_, i) => (
            <Collapse key={i}>
              <WeekItem
                name={`${name}.${i}`}
                expanded={weekIdx === i}
                onRemove={() => remove(i)}
                onExpand={() => toggleOpen(i)}
                setDayIdx={() => setDayIdx(demands[i].demands.length)}
                children={
                  <DemandDaysForm
                    name={`${name}.${i}.demands`}
                    dayIdx={dayIdx}
                    setDayIdx={setDayIdx}
                  />
                }
              />
            </Collapse>
          ))}
        </Stack>
      )}
    </FieldArray>
  );
}

import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import type { StackProps } from "@mui/material";
import { Stack } from "@mui/material";
import { useIntersectionObserver } from "hooks";

import { PageHeader } from "components/layout";

import type { TocItem } from "./TableOfContents";
import { TableOfContents } from "./TableOfContents";

type Props<Id extends string = string> = StackProps & {
  items: ReadonlyArray<TocItem<Id>>;
};

export function SidePanel<Id extends string = string>({
  items,
  ...props
}: Props<Id>) {
  const { hash } = useLocation();
  const [selected, setSelected] = useState(() => (hash ? hash.slice(1) : ""));
  const sections = useMemo(() => items.map((i) => i.id), [items]);
  useIntersectionObserver(sections, setSelected);

  return (
    <Stack {...props}>
      <Stack>
        <PageHeader title="Enhetsinställningar" />
      </Stack>
      <TableOfContents<Id> items={items} selected={selected} gap={1} />
    </Stack>
  );
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { UpdateAdvancedMutation as Mutation } from "./types";

const mutation = graphql`
  mutation UpdateAdvancedMutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      ok
      setting {
        ...Advanced_fragment
      }
    }
  }
`;

export const useUpdateAdvancedMutation = () => useMutation<Mutation>(mutation);

import { Navigate, Outlet } from "react-router-dom";
import { useCurrentTeamGroup } from "hooks";

type Props = { redirectTo: string };

export function RequireTeamGroupRoute({ redirectTo }: Props) {
  const teamGroup = useCurrentTeamGroup();

  if (!teamGroup) {
    return <Navigate to={redirectTo} />;
  }

  return <Outlet />;
}

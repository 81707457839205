import { HourglassTop as QueuedIcon } from "@mui/icons-material";
import { CircularProgress as Loading } from "@mui/material";
import { SolutionStatus } from "types/Schedule";

import { Fail as ErrorIcon, Success as SuccessIcon } from "components/icons";

import type { ApiScheduleSolutionStatusChoices as SolutionStatusType } from "./types";

type ScheduleStatusIconProps = {
  status?: SolutionStatusType;
};

export default function ScheduleStatusIcon({
  status,
}: ScheduleStatusIconProps) {
  if (status === "RUNNING") {
    return <Loading size={22} thickness={6} color="secondary" />;
  }
  if (status === SolutionStatus.RUNNING_WITH_INFEASIBILITY_VENTS) {
    return <Loading size={22} thickness={6} sx={{ color: "hjarta.main" }} />;
  }
  if (status === SolutionStatus.QUEUED) {
    return <QueuedIcon />;
  }
  if (status === "HARD_CONSTRAINT_COLLISION") {
    return <ErrorIcon sx={{ color: "hjarta.main" }} />;
  }
  if (status === "INFEASIBLE") {
    return <ErrorIcon sx={{ color: "secondary" }} />;
  }
  if (status === "ERROR") {
    return <ErrorIcon sx={{ color: "secondary" }} />;
  }

  return <SuccessIcon sx={{ color: "ture.80" }} />;
}

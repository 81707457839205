import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { Header } from "./Header";
import { Example, Formulas, VacationCalculation } from "./Information";

type Props = { groupId: string; groupName: string; readOnly?: boolean };

const MODULE_NAME = "Vacation";

export function VacationField({ groupId, groupName, readOnly }: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <Stack gap={1}>
      <Header
        groupName={groupName}
        moduleName={MODULE_NAME}
        readOnly={readOnly}
        disabled={disabled}
      />
      <Stack gap={5}>
        <VacationCalculation />
        <Formulas teamGroupId={groupId} />
        <Example />
      </Stack>
    </Stack>
  );
}

/**
 * @generated SignedSource<<e7938cf0120fd86d99530a3b2cbaf276>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditTeamInput = {
  clientMutationId?: string | null;
  color: string;
  id: string;
  name: string;
};
export type EditTeamMutation$variables = {
  input: EditTeamInput;
};
export type EditTeamMutation$data = {
  readonly editTeam: {
    readonly team: {
      readonly archivedAt: string | null;
      readonly color: string;
      readonly id: string;
      readonly isActive: boolean;
      readonly name: string;
    } | null;
  } | null;
};
export type EditTeamMutation = {
  response: EditTeamMutation$data;
  variables: EditTeamMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditTeamPayload",
    "kind": "LinkedField",
    "name": "editTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditTeamMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditTeamMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "76f2d13e6864b9df6ad967bd8693f324",
    "id": null,
    "metadata": {},
    "name": "EditTeamMutation",
    "operationKind": "mutation",
    "text": "mutation EditTeamMutation(\n  $input: EditTeamInput!\n) {\n  editTeam(input: $input) {\n    team {\n      id\n      name\n      color\n      isActive\n      archivedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8dcefa6da5b103a785b62461fb564be9";

export default node;

import type { CSSObject } from "@mui/material";
import { Collapse, Stack } from "@mui/material";

import ErrorMarginSelect from "./ErrorMarginSelect";

type Props = {
  visible: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  sx?: CSSObject;
};

export default function AllowedErrorMargin({
  visible,
  disabled,
  readOnly,
  sx = {},
}: Props) {
  return (
    <Collapse in={visible}>
      <Stack sx={sx}>
        <ErrorMarginSelect
          name="shiftDayTypeAllowedErrorMargin"
          disabled={disabled || !visible}
          readOnly={readOnly}
        />
      </Stack>
    </Collapse>
  );
}

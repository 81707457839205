/**
 * @generated SignedSource<<3658c98fbb6f505e09c7109e79ce351a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileTotalsChart_fragment$data = {
  readonly scheduleProfile: {
    readonly viewerTotals: ReadonlyArray<{
      readonly competenceId: string | null;
      readonly kpiTypes: ReadonlyArray<{
        readonly hours: number | null;
        readonly kpiName: string;
      }>;
      readonly teamId: string | null;
    }>;
  };
  readonly " $fragmentType": "ProfileTotalsChart_fragment";
};
export type ProfileTotalsChart_fragment$key = {
  readonly " $data"?: ProfileTotalsChart_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileTotalsChart_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileTotalsChart_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleProfileViewer",
      "kind": "LinkedField",
      "name": "scheduleProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScheduleTotals",
          "kind": "LinkedField",
          "name": "viewerTotals",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "teamId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "competenceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ScheduleKPIs",
              "kind": "LinkedField",
              "name": "kpiTypes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kpiName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleNode",
  "abstractKey": null
};

(node as any).hash = "c7730209aa25db3622bfcc1da1da4230";

export default node;

import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const AB1InfoTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Dygnsvilan</b>?
  </InfoTitle>
);

const AB1InfoContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export function AB1Info() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal title={<AB1InfoTitle />}>
        <AB1InfoContent />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultTeamGroupSubmitFunction,
  useDefaultUserSubmitFunction,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { dayTypeDistributionWeightChoices } from "../../components/setting/team_group/setting_boxes/constants";

import {
  DayNightFairnessSettingTeamGroup_fragment$data as TeamGroupData,
  DayNightFairnessSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/DayNightFairnessSettingTeamGroup_fragment.graphql";
import {
  DayNightFairnessSettingUserSetting_fragment$data as UserSettingData,
  DayNightFairnessSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/DayNightFairnessSettingUserSetting_fragment.graphql";
import { DayNightFairnessTeamGroupOnlyForm } from "./DayNightFairnessTeamGroupOnlyForm";
import { DayNightFairnessUserOnlyForm } from "./DayNightFairnessUserOnlyForm";

const teamGroupFragment = graphql`
  fragment DayNightFairnessSettingTeamGroup_fragment on SettingNode {
    id
    optimizeDayTypeFairness
    optimizeDayTypeFairnessWeekend
    dayTypeFairnessWeight
    constraintModules
  }
`;

const userSettingFragment = graphql`
  fragment DayNightFairnessSettingUserSetting_fragment on UserSettingNode {
    id
    settingModules
    includeInDayTypeWeekendFairness
  }
`;

const SETTING_NAME = "Dag-/Kväll-/Natt-rättvisa";
const MODULE_NAME = "ShiftDayTypeFairness";
const SETTING_URL_ID = "day-eve-night-fairness";

type DayNightFairnessFormValues = Pick<TeamGroupData, "id">;

type DayNightFairnessTeamGroupFormValues = Pick<
  TeamGroupData,
  | "id"
  | "constraintModules"
  | "dayTypeFairnessWeight"
  | "optimizeDayTypeFairness"
  | "optimizeDayTypeFairnessWeekend"
>;

type DayNightFairnessUserFormValues = Pick<
  UserSettingData,
  "id" | "includeInDayTypeWeekendFairness"
> &
  Pick<TeamGroupData, "constraintModules">;

const teamGroupValidationSchema = yup.object().shape({
  optimizeDayTypeFairness: yup.boolean().required(),
  optimizeDayTypeFairnessWeekend: yup.boolean().required(),
  dayTypeFairnessWeight: yup
    .string()
    .oneOf(dayTypeDistributionWeightChoices)
    .required(),
});

const userValidationSchema = yup.object().shape({
  id: yup.string().required(),
  includeInDayTypeWeekendFairness: yup.boolean().required(),
});

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): DayNightFairnessFormValues {
  return {
    id: teamGroupData.id,
  };
}
function convertUserDataToInitialValues(
  userSettingData: UserSettingData,
): DayNightFairnessFormValues {
  return {
    id: userSettingData.id,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): DayNightFairnessTeamGroupFormValues {
  return {
    id: teamGroupData.id,
    constraintModules: teamGroupData.constraintModules,
    optimizeDayTypeFairness: teamGroupData.optimizeDayTypeFairness ?? false,
    optimizeDayTypeFairnessWeekend:
      teamGroupData.optimizeDayTypeFairnessWeekend ?? false,
    dayTypeFairnessWeight: teamGroupData.dayTypeFairnessWeight ?? "M",
  };
}

function convertUserDataToUserSpecificInitialValues(
  userSettingData: UserSettingData,
): DayNightFairnessUserFormValues {
  return {
    id: userSettingData.id,
    includeInDayTypeWeekendFairness:
      userSettingData.includeInDayTypeWeekendFairness,
    constraintModules: userSettingData.settingModules,
  };
}

function renderTeamGroupComponent(
  initialValues: DayNightFairnessTeamGroupFormValues,
  onSubmit: (formValues: DayNightFairnessTeamGroupFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <DayNightFairnessTeamGroupOnlyForm />
    </BaseSettings>
  );
}

function renderUserComponent(
  initialValues: DayNightFairnessUserFormValues,
  onSubmit: (formValues: DayNightFairnessUserFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={userValidationSchema}
    >
      <DayNightFairnessUserOnlyForm />
    </BaseSettings>
  );
}

export const dayNightFairnessSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  undefined,
  DayNightFairnessFormValues,
  {},
  DayNightFairnessTeamGroupFormValues,
  DayNightFairnessUserFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent: () => null,
  teamGroupOnly: {
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
    renderComponent: renderTeamGroupComponent,
  },
  userOnly: {
    convertUserDataToInitialValues: convertUserDataToUserSpecificInitialValues,
    useSubmitUserOnly: useDefaultUserSubmitFunction,
    renderComponent: renderUserComponent,
  },
};

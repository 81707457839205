import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { AdminEditUserMutation as Mutation } from "./types";

const mutation = graphql`
  mutation AdminEditUserMutation($input: AdminEditUserInput!) {
    adminEditUser(input: $input) {
      user {
        ...AdminEditUserForm_fragment
      }
    }
  }
`;

export const useAdminEditUser = () => useMutation<Mutation>(mutation);

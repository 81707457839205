/**
 * @generated SignedSource<<5d0095f5be8b167a7b27b99dc1230bf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeetingList_meetings$data = ReadonlyArray<{
  readonly dayMatrix: ReadonlyArray<ReadonlyArray<number>>;
  readonly end: string;
  readonly id: string;
  readonly name: string;
  readonly start: string;
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fullName: string;
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "MeetingList_meetings";
}>;
export type MeetingList_meetings$key = ReadonlyArray<{
  readonly " $data"?: MeetingList_meetings$data;
  readonly " $fragmentSpreads": FragmentRefs<"MeetingList_meetings">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MeetingList_meetings",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "end",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayMatrix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNodeConnection",
      "kind": "LinkedField",
      "name": "users",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MeetingNode",
  "abstractKey": null
};
})();

(node as any).hash = "9a8973ea0acc9034c966978d1869e917";

export default node;

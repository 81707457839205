import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import type { Theme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { Colors } from "styles/colors";

type StylesProps = {
  theme: Theme;
};

export const components: any = {
  MuiDialogActions: {
    styleOverrides: {
      root: {
        margin: "5px 5px",
      },
    },
  },
  MuiPaper: {
    variants: [
      {
        props: { variant: "box" },
        style: ({ theme }: StylesProps) => ({
          borderRadius: theme.spacing(0.5),
          elevation: 0,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: "white",
        }),
      },
      {
        props: { variant: "panel" },
        style: ({ theme }: StylesProps) => ({
          backgroundColor: "white",
          width: "100%",
          boxShadow: "none",
          borderRadius: 0,
          elevation: 0,
          borderColor: theme.palette.divider,
          borderStyle: "solid",
          borderWidth: 0,
        }),
      },
    ],
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
    variants: [
      {
        props: { variant: "primary" },
        style: ({ theme }: StylesProps) => ({
          textTransform: "none",
          border: "none",
          backgroundColor: theme.palette.ture[100],
          color: Colors.PAPER,
          ":hover": {
            backgroundColor: theme.palette.ture[80],
          },
          ":disabled": {
            backgroundColor: theme.palette.ture[15],
          },
        }),
      },
      {
        props: { variant: "secondary" },
        style: ({ theme }: StylesProps) => ({
          textTransform: "none",
          border: "none",
          backgroundColor: theme.palette.annkatrin[100],
          color: Colors.PAPER,
          ":hover": {
            backgroundColor: theme.palette.annkatrin[80],
          },
          ":disabled": {
            backgroundColor: theme.palette.annkatrin[40],
          },
        }),
      },
      {
        props: { variant: "cancel" },
        style: {
          textTransform: "none",
          border: "none",
          backgroundColor: Colors.BLACK60,
          color: Colors.PAPER,
          ":hover": {
            backgroundColor: Colors.BLACK40,
          },
        },
      },
      {
        props: { variant: "delete" },
        style: {
          textTransform: "none",
          border: "none",
          backgroundColor: Colors.PINK,
          color: Colors.PAPER,
          ":hover": {
            backgroundColor: Colors.PINK80,
          },
        },
      },
      {
        props: { variant: "selectOption" },
        style: {
          border: "none",
          backgroundColor: "transparent",
          color: Colors.BLUE,
          fontSize: 14,
          fontWeight: 450,
          margin: 0,
          padding: 0,
          ":hover": {
            color: Colors.BLUE120,
            backgroundColor: "transparent",
          },
        },
      },
      {
        props: { variant: "toolbar" },
        style: {
          fontWeight: 550,
          border: "none",
          backgroundColor: "transparent",
          color: Colors.BLUE,
          ":hover": {
            color: Colors.BLUE80,
            backgroundColor: "transparent",
          },
        },
      },
      {
        props: { variant: "icon" },
        style: {
          paddingLeft: 7,
          paddingRight: 7,
          paddingTop: 2,
          paddingBottom: 2,
          border: "none",
          backgroundColor: "transparent",
          borderRadius: 20,
          color: Colors.BLUE,
          "&:hover": {
            color: Colors.BLUE120,
            backgroundColor: "transparent",
          },
        },
      },
      {
        props: { variant: "loading" },
        style: {
          backgroundColor: "#eaebeb",
          color: alpha("#000", 0),
        },
      },
    ],
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: "var(--TextField-brandBorderColor)",
      },
      input: {
        color: "var(--TextField-brandTextColor)",
        fontSize: 15,
        fontWeight: 400,
      },
      root: {
        "--TextField-brandBorderColor": Colors.FORMBORDERCOLOR,
        "--TextField-brandBorderHoverColor": Colors.FORMBORDERCOLOR,
        "--TextField-brandBorderFocusedColor": Colors.BLUE,
        "--TextField-brandDisabledColor": Colors.DISABLED,
        "--TextField-brandTextColor": Colors.TEXTCOLOR,
        "& label.Mui-focused": {
          color: "var(--TextField-brandBorderFocusedColor)",
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "var(--TextField-brandBorderHoverColor)",
        },
        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "var(--TextField-brandBorderFocusedColor)",
        },
        [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: "var(--TextField-brandDisabledColor)",
          backgroundColor: "var(--TextField-brandDisabledColor)",
          textColor: "var(--TextField-brandTextColor)",
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        "&:before, &:after": {
          borderBottom: "2px solid var(--TextField-brandBorderColor)",
        },
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
        },
        "&.Mui-focused:after": {
          borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        "&:before": {
          borderBottom: "2px solid var(--TextField-brandBorderColor)",
        },
        "&:hover:not(.Mui-disabled, .Mui-error):before": {
          borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
        },
        "&.Mui-focused:after": {
          borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }: StylesProps) => ({
        "&.MuiTableRow-head": {
          backgroundColor: theme.palette.ture[10],
          borderTop: `1px solid ${theme.palette.divider}`,
        },
        "&.MuiTableRow-footer": {
          backgroundColor: theme.palette.ture[10],
        },
        "&:hover:not(.MuiTableRow-head):not(.MuiTableRow-footer)": {
          backgroundColor: theme.palette.ture[10],
        },
      }),
    },
  },
};

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Types7, Types7Info as Info } from "components/setting/common/forms";

const MODULE_NAME = "ShiftDayTypeCadence";

type FormProps = {
  name: string;
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  const title = (
    <>
      Avvikande Dag-/Kväll-/Natt-kadens för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

function Switch({ readOnly }: { readOnly?: boolean }) {
  return <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />;
}

export function Types7Form({ name, readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  const who = <b>{name}</b>;

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={<Switch readOnly={readOnly} />}
          titleComponent={<Title name={name} />}
          // weightComponent={<Weight readOnly={readOnly} disabled={disabled} />}
        />
        <Types7 who={who} readOnly={readOnly} disabled={disabled} />
      </Stack>
    </SettingBox>
  );
}

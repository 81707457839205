import { useMemo, useState } from "react";
import { Divider, Paper, Stack, styled } from "@mui/material";
import { SettingPanelType } from "pages";
import { toLower } from "utils/string";

import type { Value as ViewMode } from "components/setting/SettingsPanel/ViewSelect";

import { exceptionsPane } from "../exceptionPane";
import { ruleSections } from "../ruleSections";

import { SearchField } from "./SearchField";
import { SectionList } from "./SectionList";
import type { ContextType } from "./SettingsPanelContext";
import { SettingsPanelProvider } from "./SettingsPanelContext";
import type { Section } from "./types";
import type { Value as ViewValue } from "./ViewSelect";
import { ViewSelect } from "./ViewSelect";

export type ExceptionPaneEntry = {
  value: string;
  label: string;
  ruleExceptions: number | undefined;
};

type Props = {
  users: ExceptionPaneEntry[];
  ruleGroups: ExceptionPaneEntry[];
  modules: string[];
  showAdvanced?: boolean;
  ruleId: string;
  settingPanelType: SettingPanelType;
  setRuleId: ContextType["setSelected"];
};

export function SettingsPanel({
  settingPanelType,
  users,
  ruleGroups,
  ruleId,
  setRuleId,
  showAdvanced = false,
  modules = [],
}: Props) {
  const [query, setQuery] = useState<string>("");
  const queryLower = useMemo(() => query.toLowerCase(), [query]);
  const [mode, setMode] = useState<ViewMode>(
    settingPanelType === SettingPanelType.SETTINGS ? "settings" : "exceptions",
  );

  const settingsSection = useMemo<Section[]>(
    () =>
      ruleSections(modules, showAdvanced).map((section) => {
        if (section.elements.find((element) => element.id === ruleId) != null) {
          return {
            ...section,
            defaultExpanded: true,
          };
        }
        return section;
      }),
    [modules, showAdvanced, ruleId],
  );
  const exceptionsSections: Section[] = useMemo<Section[]>(
    () =>
      exceptionsPane(users, queryLower, ruleGroups).map((section) => {
        if (section.elements.find((element) => element.id === ruleId) != null) {
          return {
            ...section,
            defaultExpanded: true,
          };
        }
        return section;
      }),
    [users, queryLower, ruleGroups, ruleId],
  );

  const sections = useMemo<Section[]>(() => {
    switch (mode) {
      case "settings":
        return settingsSection;
      case "exceptions":
        return exceptionsSections;
      case "ranking":
        return []; // TODO Populate?
      default:
        return [];
    }
  }, [mode, settingsSection, exceptionsSections]);

  const filteredSections = useMemo(() => {
    const filteredSections: Section[] = [];
    sections.forEach((section) => {
      if (section.title.toLowerCase().includes(queryLower)) {
        filteredSections.push(section);
        return;
      }
      const filteredElements = section.elements.filter(
        (element) =>
          toLower(element.title).includes(queryLower) ||
          element.tags?.map(toLower).includes(queryLower),
      );
      if (filteredElements.length > 0) {
        filteredSections.push({ ...section, elements: filteredElements });
      }
    });
    return filteredSections;
  }, [queryLower, sections]);

  return (
    <SettingsPanelProvider selected={ruleId} setSelected={setRuleId}>
      <SettingPanelSkeleton
        select={
          <ViewSelect
            value={mode}
            onChange={(e) => setMode(e.target.value as ViewValue)}
          />
        }
        search={<SearchField query={query} setQuery={setQuery} />}
        list={<SectionList sections={filteredSections} />}
      />
    </SettingsPanelProvider>
  );
}

const SettingPanelContainer = styled(Paper)(() => ({
  variant: "box",
  width: 260,
  flexShrink: 0,
  borderRadius: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
}));

export const SettingPanelSkeleton: React.FC<{
  select: JSX.Element;
  search: JSX.Element;
  list: JSX.Element;
}> = ({ select, search, list }) => (
  <SettingPanelContainer>
    <Stack gap={2} p={1} pt={2} px={1.5} height="100vh">
      {select}
      <Divider />
      {search}
      {list}
    </Stack>
  </SettingPanelContainer>
);

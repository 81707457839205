import { Stack, Typography as Text } from "@mui/material";

import type { WarningModuleType } from "components/types/ConstraintModuleType";
import { TranslateWarningModule } from "components/types/ConstraintModuleType";

import type { Warning } from "./types";

type Props = {
  warning: Warning;
};

export function BaseWarningItem({ warning }: Props) {
  const moduleLabel = TranslateWarningModule(
    warning.module as WarningModuleType,
  );
  return (
    <Stack gap={0.5}>
      <Text variant="body1" fontWeight={600}>
        {moduleLabel}
      </Text>
      <Text variant="body2">{warning.user?.name}</Text>
      <Text variant="body2" color="text.secondary">
        {warning.message}
      </Text>
      {warning.activity && (
        <Text variant="body2" color="text.secondary">
          {new Date(Date.parse(warning.activity.start)).toLocaleString()} -{" "}
          {new Date(Date.parse(warning.activity.end)).toLocaleString()}
        </Text>
      )}
    </Stack>
  );
}

export function GroupWarningItem({ warning }: Props) {
  return (
    <Stack gap={0.5}>
      <Text variant="body1" fontWeight={600}>
        {warning.user?.name || "<inget namn>"}
      </Text>
      <Text variant="body2" color="text.secondary">
        {warning.message}
      </Text>
      {warning.activity && (
        <Text variant="body2" color="text.secondary">
          {new Date(Date.parse(warning.activity.start)).toLocaleString()} -{" "}
          {new Date(Date.parse(warning.activity.end)).toLocaleString()}
        </Text>
      )}
    </Stack>
  );
}

export function UserWarningItem({ warning }: Props) {
  const moduleLabel = TranslateWarningModule(
    warning.module as WarningModuleType,
  );
  return (
    <Stack gap={0.5}>
      <Text variant="body1" fontWeight={600}>
        {moduleLabel}
      </Text>
      <Text variant="body2" color="text.secondary">
        {warning.message}
      </Text>
      {warning.activity && (
        <Text variant="body2" color="text.secondary">
          {new Date(Date.parse(warning.activity.start)).toLocaleString()} -{" "}
          {new Date(Date.parse(warning.activity.end)).toLocaleString()}
        </Text>
      )}
    </Stack>
  );
}

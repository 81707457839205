import { Suspense } from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { ShiftSectionPlaceholder } from "components/loading";
import { ShiftDayTypeFragmentProvider } from "components/shifts/ShiftDayTypeContext";

import {
  FormFieldsScaffold,
  MeetingFormScaffold,
  ShiftSectionScaffold,
} from "../Scaffolds";

import { DateGridSection } from "./DateGridSection";
import { EndField } from "./EndField";
import { FormHeader } from "./FormHeader";
import { useFormHeaderActions } from "./HeaderActions";
import { MeetingErrorsBox } from "./MeetingErrorsBox";
import { FormikParticipantList } from "./ParticipantList";
import { useRefetchShiftsButton } from "./RefetchShiftsButton";
import { SelectParticipantsField } from "./SelectParticipantsField";
import { Shifts } from "./Shifts";
import { StartField } from "./StartField";
import { TitleField } from "./TitleField";
import type { MeetingForm_setting$key as SettingKey } from "./types";

export type Props = {
  submitText: string;
  cancelText: string;
  settingFragmentRef: SettingKey;
};

const settingFragment = graphql`
  fragment MeetingForm_setting on SettingNode {
    ...ShiftDayTypeContext_fragment
  }
`;

export function MeetingForm({
  submitText,
  cancelText,
  settingFragmentRef,
}: Props) {
  const setting = useFragment<SettingKey>(settingFragment, settingFragmentRef);
  const { reset, submit, submitDisabled } = useFormHeaderActions();

  return (
    <ShiftDayTypeFragmentProvider fragmentRef={setting}>
      <MeetingFormScaffold>
        <FormHeader
          submitProps={{
            text: submitText,
            onClick: submit,
            disabled: submitDisabled,
          }}
          cancelProps={{
            text: cancelText,
            onClick: reset,
          }}
        />
        <FormFields />
      </MeetingFormScaffold>
    </ShiftDayTypeFragmentProvider>
  );
}

function FormFields() {
  return (
    <FormFieldsScaffold
      titleField={<TitleField />}
      startField={<StartField />}
      endField={<EndField />}
      dateGridSection={<DateGridSection />}
      userField={<SelectParticipantsField />}
      userList={<FormikParticipantList name="users" />}
      shiftsSection={<ShiftSection />}
    />
  );
}

function ShiftSection() {
  const [refetch, inFlight] = useRefetchShiftsButton();

  return (
    <ShiftSectionScaffold
      refetch={refetch}
      inFlight={inFlight}
      shiftsField={
        <>
          <MeetingErrorsBox name="errors" />
          <Suspense fallback={<ShiftSectionPlaceholder />}>
            <Shifts disabled={inFlight} />
          </Suspense>
        </>
      }
    />
  );
}

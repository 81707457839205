import { useCallback, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import type { FormikHelpers } from "formik";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
import * as Yup from "yup";

import {
  AdditionalSettingsAccordion,
  TimebankAccordion,
} from "components/forms/UserForm";
import { BasicUserInput } from "components/forms/UserForm/BasicUserInput";
import type { CreateUserInput, EditUserInput } from "components/organisation";
import { DraggablePaper } from "components/utils/DraggablePaper";

import type {
  AdminCreateUserInput,
  AdminEditUserInput,
  TeamGroupType,
} from "../../types";

type ValidFormValues =
  | AdminCreateUserInput
  | AdminEditUserInput
  | CreateUserInput
  | EditUserInput;

type Props<FormValues extends ValidFormValues> = {
  open: boolean;
  onClose: () => void;
  title: string;
  teamGroups: TeamGroupType[];
  initialValues: FormValues;
  onSubmit: (input: FormValues) => Promise<void>;
  showPass?: boolean;
  name?: string;
  isAdminForm?: boolean;
  isCreate?: boolean;
};

function Footer() {
  const { isSubmitting, resetForm } = useFormikContext<ValidFormValues>();
  const onCancel = useCallback(() => resetForm(), [resetForm]);

  return (
    <DialogActions>
      <Button variant="primary" type="submit" disabled={isSubmitting}>
        Spara
      </Button>
      <Button variant="cancel" onClick={onCancel}>
        Avbryt
      </Button>
    </DialogActions>
  );
}

export function UserForm<V extends ValidFormValues>({
  open,
  onClose,
  title,
  teamGroups,
  initialValues,
  onSubmit,
  name,
  isAdminForm = false,
  isCreate = false,
}: Props<V>) {
  const [openIdx, setOpenIdx] = useState(0);
  const memberName = useMemo(() => name || "den nya personen", [name]);
  const handleAccordion = useCallback(
    (idx: number) => () => {
      setOpenIdx((prev: number) => (prev === idx ? -1 : idx));
    },
    [],
  );

  const handleSubmit = useCallback(
    async (values: V) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.error(error);
      }
    },
    [onSubmit],
  );

  const handleReset = useCallback(
    (_: V, formikBag: FormikHelpers<V>) => {
      formikBag.setValues(initialValues);
      onClose();
    },
    [initialValues, onClose],
  );
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Måste fyllas i"),
  });

  return (
    <Dialog open={open} maxWidth="md" fullWidth PaperComponent={DraggablePaper}>
      <DialogTitle
        variant="h3"
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        {!!title && <Typography variant="h3">{title}</Typography>}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <FormikForm autoComplete="off">
          <DialogContent sx={{ p: 0 }}>
            <BasicUserInput
              isAdminForm={isAdminForm}
              expanded={openIdx === 0}
              onChange={handleAccordion(0)}
              isCreate={isCreate}
              teamGroups={teamGroups}
            />

            <TimebankAccordion
              expanded={openIdx === 5}
              onChange={handleAccordion(5)}
              name="timebankBaseBalance"
              memberName={memberName}
            />
            {isAdminForm && (
              <AdditionalSettingsAccordion
                expanded={openIdx === 2}
                onChange={handleAccordion(2)}
                hideRuleGroup={isAdminForm}
              />
            )}
          </DialogContent>
          <Footer />
        </FormikForm>
      </Formik>
    </Dialog>
  );
}

/**
 * @generated SignedSource<<5b35c2b54eb0d31f1bfdb7ecbf2aa2f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteTeamGroupMutation$variables = {
  id: string;
};
export type DeleteTeamGroupMutation$data = {
  readonly deleteTeamGroup: {
    readonly deletedId: string | null;
    readonly ok: boolean | null;
  } | null;
};
export type DeleteTeamGroupMutation = {
  response: DeleteTeamGroupMutation$data;
  variables: DeleteTeamGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTeamGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteTeamGroupPayload",
        "kind": "LinkedField",
        "name": "deleteTeamGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteTeamGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteTeamGroupPayload",
        "kind": "LinkedField",
        "name": "deleteTeamGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedId"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b01c3311b31911503534f5f8f78156a9",
    "id": null,
    "metadata": {},
    "name": "DeleteTeamGroupMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteTeamGroupMutation(\n  $id: ID!\n) {\n  deleteTeamGroup(input: {id: $id}) {\n    ok\n    deletedId\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3b7e4b03d3869ab5d9be9b2c37d7229";

export default node;

/**
 * @generated SignedSource<<363f220952a5e763d9fd86be9ba53a20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PeriodTimeline_fragment$data = ReadonlyArray<{
  readonly endDate: string;
  readonly id: string;
  readonly masterSchedule: {
    readonly id: string;
  } | null;
  readonly name: string;
  readonly numberOfWeeks: number;
  readonly schedules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null>;
  };
  readonly startDate: string;
  readonly " $fragmentType": "PeriodTimeline_fragment";
}>;
export type PeriodTimeline_fragment$key = ReadonlyArray<{
  readonly " $data"?: PeriodTimeline_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PeriodTimeline_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PeriodTimeline_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleNode",
      "kind": "LinkedField",
      "name": "masterSchedule",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScheduleNodeConnection",
      "kind": "LinkedField",
      "name": "schedules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScheduleNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ScheduleNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PeriodNode",
  "abstractKey": null
};
})();

(node as any).hash = "3bf48836d6e907e087b23ada5edcc145";

export default node;

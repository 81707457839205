import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultTeamGroupSubmitFunction,
} from "settings/common/optimizationSetting";
import { WeightTeamGroupOnlyForm } from "settings/common/TeamGroupOnlyForms";
import * as yup from "yup";

import { consecutiveWorkDaysWeightChoices } from "components/setting/team_group/setting_boxes/constants";

import {
  ConsecutiveWorkSettingRuleGroup_fragment$data as RuleGroupData,
  ConsecutiveWorkSettingRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/ConsecutiveWorkSettingRuleGroup_fragment.graphql";
import {
  ConsecutiveWorkSettingTeamGroup_fragment$data as TeamGroupData,
  ConsecutiveWorkSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/ConsecutiveWorkSettingTeamGroup_fragment.graphql";
import {
  ConsecutiveWorkSettingUserSetting_fragment$data as UserSettingData,
  ConsecutiveWorkSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/ConsecutiveWorkSettingUserSetting_fragment.graphql";
import { ConsecutiveWork } from "./ConsecutiveWorkForm";

const teamGroupFragment = graphql`
  fragment ConsecutiveWorkSettingTeamGroup_fragment on SettingNode {
    id
    constraintModules
    consecutiveWorkDaysWeight
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
  }
`;
const userSettingFragment = graphql`
  fragment ConsecutiveWorkSettingUserSetting_fragment on UserSettingNode {
    id
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
    settingModules
  }
`;
const ruleGroupFragment = graphql`
  fragment ConsecutiveWorkSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
    settingModules
  }
`;

const SETTING_NAME = "Sammanhängande arbete";
const MODULE_NAME = "ConsecutiveWorkDays";
const SETTING_URL_ID = "cons-work";

type ConsecutiveWorkFormValues = Omit<
  TeamGroupData,
  " $fragmentType" | "consecutiveWorkDaysWeight"
>;
type ConsecutiveWorkTeamGroupFormValues = Pick<
  TeamGroupData,
  "id" | "constraintModules" | "consecutiveWorkDaysWeight"
>;

const validationSchema = yup.object().shape({
  id: yup.string(),
  maxConsecutiveWorkDays: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(1000, "Måste vara högst 1000")
    .required("Får ej vara tomt"),
  maxConsecutiveWorkDaysSoft: yup.boolean().required(),
  minConsecutiveWorkDays: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(1000, "Måste vara högst 1000")
    .required("Får ej vara tomt"),
  minConsecutiveWorkDaysSoft: yup.boolean().required(),
});

const teamGroupValidationSchema = yup.object().shape({
  id: yup.string(),
  consecutiveWorkDaysWeight: yup
    .string()
    .oneOf(consecutiveWorkDaysWeightChoices)
    .required(),
});

function baseConvertDataToInitialValues(
  data: TeamGroupData | UserSettingData | RuleGroupData,
) {
  return {
    id: data.id,
    maxConsecutiveWorkDays: data.maxConsecutiveWorkDays || 2,
    maxConsecutiveWorkDaysSoft: data.maxConsecutiveWorkDaysSoft !== false, // default true
    minConsecutiveWorkDays: data.minConsecutiveWorkDays || 2,
    minConsecutiveWorkDaysSoft: data.minConsecutiveWorkDaysSoft !== false, // default true
  };
}

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): ConsecutiveWorkFormValues {
  return {
    ...baseConvertDataToInitialValues(teamGroupData),
    constraintModules: teamGroupData.constraintModules,
  };
}

function convertUserOrRuleGroupDataToInitialValues(
  data: UserSettingData | RuleGroupData,
): ConsecutiveWorkFormValues {
  return {
    ...baseConvertDataToInitialValues(data),
    constraintModules: data.settingModules,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): ConsecutiveWorkTeamGroupFormValues {
  return {
    id: teamGroupData.id,
    constraintModules: teamGroupData.constraintModules,
    consecutiveWorkDaysWeight: teamGroupData.consecutiveWorkDaysWeight,
  };
}

function renderComponent(
  initialValues: ConsecutiveWorkFormValues,
  onSubmit: (formValues: ConsecutiveWorkFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ConsecutiveWork />
    </BaseSettings>
  );
}
function renderTeamGroupComponent(
  initialValues: ConsecutiveWorkTeamGroupFormValues,
  onSubmit: (formValues: ConsecutiveWorkTeamGroupFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <WeightTeamGroupOnlyForm
        moduleName={MODULE_NAME}
        weightFieldName="consecutiveWorkDaysWeight"
      />
    </BaseSettings>
  );
}

export const consecutiveWorkSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  RuleGroupKey,
  ConsecutiveWorkFormValues,
  {},
  ConsecutiveWorkTeamGroupFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
    renderComponent: renderTeamGroupComponent,
  },
};

/**
 * @generated SignedSource<<79cc15cb83545ea7c2a9a626fdbe55db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConsecutiveWorkSettingUserSetting_fragment$data = {
  readonly id: string;
  readonly maxConsecutiveWorkDays: number;
  readonly maxConsecutiveWorkDaysSoft: boolean;
  readonly minConsecutiveWorkDays: number;
  readonly minConsecutiveWorkDaysSoft: boolean;
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "ConsecutiveWorkSettingUserSetting_fragment";
};
export type ConsecutiveWorkSettingUserSetting_fragment$key = {
  readonly " $data"?: ConsecutiveWorkSettingUserSetting_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConsecutiveWorkSettingUserSetting_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConsecutiveWorkSettingUserSetting_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveWorkDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "33bb16dc4fe04b0d3ab3204ba7d819e0";

export default node;

import { alpha, darken, lighten, styled } from "@mui/material/styles";

const important = (s: string) => `${s} !important`;

const reduceImportant = (o: Record<string, string>) =>
  Object.entries(o).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: important(v),
    }),
    {} as Record<string, string>,
  );

const repLinGradStr = (color1: string, color2: string) =>
  `repeating-linear-gradient(45deg, ${color1}, ${color1} 3px, ${color2} 3px, ${color2} 8px)`;

const repeatingLinearGradient = (
  color1: string,
  color2: string,
  isImportant = true,
) => {
  const o = {
    backgroundColor: color2,
    backgroundImage: repLinGradStr(color1, color2),
    borderColor: color1,
    borderWidth: "2px",
    borderStyle: "solid",
  };
  return isImportant ? reduceImportant(o) : o;
};

const bgColorWithBorder = (
  color: string,
  addOpacity = false,
  isImportant = true,
) => {
  const o = {
    borderColor: color,
    backgroundColor: alpha(color, addOpacity ? 0.8 : 1),
    borderWidth: "2px",
    borderStyle: "solid",
  };
  return isImportant ? reduceImportant(o) : o;
};

export const BlockedTimeSlotItem = styled("div")(({ theme }) => {
  const hassan = theme.palette.hassan[100];
  const annkatrin = theme.palette.annkatrin[100];
  const hav = theme.palette.hav.main;
  const skum = theme.palette.skum.main;
  const hjarta = theme.palette.hjarta.main;
  const shift = lighten(theme.palette.svart[100], 0.45);
  return {
    border: important("none"),
    overflow: "hidden",
    borderRadius: 4,
    "&.va": bgColorWithBorder(hassan, true),
    "&.vw": repeatingLinearGradient(hassan, darken(hjarta, 0.1)),
    "&.wa": bgColorWithBorder(annkatrin, true),
    "&.ww": repeatingLinearGradient(annkatrin, darken(annkatrin, 0.15)),
    "&.oa": bgColorWithBorder(hav, true),
    "&.ow": repeatingLinearGradient(hav, darken(hav, 0.2)),
    "&.pa": bgColorWithBorder(skum),
    "&.pw": repeatingLinearGradient(skum, darken(skum, 0.2)),
    "&.ka": bgColorWithBorder(shift),
    "&.kw": repeatingLinearGradient(shift, darken(shift, 0.2)),
    "&.rct-item-blocked_time_slot-h": bgColorWithBorder(hjarta),
  };
});

import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Stack, Typography } from "@mui/material";

import type { CardProps } from "./types";

type Props = Pick<
  CardProps,
  "onRemove" | "countLabel" | "error" | "helperText" | "removeLabel" | "chips"
>;

export function CardHeader({
  onRemove,
  countLabel,
  error,
  helperText,
  removeLabel = "Ta bort block",
  chips,
}: Props) {
  function handleRemove(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    onRemove && onRemove();
  }

  return (
    <Stack
      gap={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={2}
      width="100%"
    >
      <Stack>
        <Stack direction="row" gap={0.5}>
          {chips}
        </Stack>
        {!!helperText && (
          <Typography color={error ? "error" : undefined}>
            {helperText}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        <Typography variant="body2">{countLabel}</Typography>
        {!!onRemove && (
          <Button
            variant="primary"
            onClick={handleRemove}
            endIcon={<DeleteIcon />}
          >
            {removeLabel}
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

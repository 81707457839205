import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { minutes2HHMM } from "helpers/dateFunctions";
import { DateTime } from "luxon";
import { Colors } from "styles/colors";

import { TranslateActivityColor } from "components/schedule/types";
import type { ShiftDayType } from "components/shifts/graphs/ShiftTimeline/types";
import { TranslateShiftDayType } from "components/shifts/types";

import { GanttItem } from "./GanttItem";
import type { TimelineItem } from "./types";

type TooltipTitleProps = {
  item: TimelineItem;
};

function TooltipTitle({ item }: TooltipTitleProps) {
  const {
    itemTitle: title,
    shiftDayType,
    shiftName,
    startAlteration,
    endAlteration,
    start_time,
    end_time,
  } = item;
  const teamName = item?.team?.name || "";
  var shiftString = shiftName;
  if (
    (startAlteration && startAlteration !== 0) ||
    (endAlteration && endAlteration !== 0)
  ) {
    shiftString += "*";
  }

  const startStr =
    DateTime.fromMillis(start_time).toFormat("HH:mm cccc y-MM-dd");
  const endStr = DateTime.fromMillis(end_time).toFormat("HH:mm cccc y-MM-dd");
  const totalAlteration = (startAlteration || 0) + (endAlteration || 0);

  return (
    <Stack>
      <Typography variant="caption">
        <strong>Start:</strong> {startStr}
      </Typography>
      <Typography variant="caption" sx={{ mb: 1 }}>
        <strong>Slut:</strong> {endStr}
      </Typography>

      {["R", "S", "J", "M"].includes(item.titleCode) && item.worktime && (
        <Typography variant="caption">
          <strong>Arbetstid:</strong> {minutes2HHMM(item.worktime)}
        </Typography>
      )}
      {item.breakTime && item.breakTime > 0 ? (
        <Typography variant="caption">
          <strong>Paustid:</strong> {item.breakTime} minuter
        </Typography>
      ) : null}
      {!!item.duration && (
        <Typography variant="caption" sx={{ mb: 1 }}>
          <strong>Längd:</strong> {minutes2HHMM(item.duration)}
        </Typography>
      )}

      <Typography variant="caption">
        <strong>Aktivitetstyp:</strong> {title}
      </Typography>
      {!!shiftDayType && (
        <Typography variant="caption">
          <strong>Dagtyp:</strong>{" "}
          {TranslateShiftDayType(shiftDayType as ShiftDayType)}
        </Typography>
      )}
      {shiftString && (
        <Typography variant="caption">
          <strong>Passnamn:</strong> {shiftString}
        </Typography>
      )}
      {!!teamName && (
        <Typography variant="caption">
          <strong>Avdelning:</strong> {teamName}
        </Typography>
      )}

      {(!!startAlteration || !!endAlteration) && (
        <Stack mt={1}>
          {!!startAlteration && (
            <Typography variant="caption">
              <strong>Justerad starttid:</strong>{" "}
              {startAlteration > 0 ? "+" : ""}
              {startAlteration} minuter
            </Typography>
          )}
          {!!endAlteration && (
            <Typography variant="caption">
              <strong>Justerad sluttid:</strong> {endAlteration > 0 ? "+" : ""}
              {endAlteration} minuter
            </Typography>
          )}
          {!!totalAlteration && (
            <Typography variant="caption">
              <strong>Total justerad tid:</strong>{" "}
              {totalAlteration > 0 ? "+" : ""}
              {totalAlteration} minuter
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export function TimelineItemRenderer({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}: any) {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  let textColor = TranslateActivityColor(item.titleCode);
  if (["S", "R", "P", "Z", "J", "M"].includes(item.titleCode)) {
    textColor = Colors.PAPER;
  }
  let fontSize = 12;
  let marginTop = 0;
  let marginBottom = 0;
  let marginLeft = 1;
  if (["B", "E"].includes(item.titleCode)) {
    fontSize = 9;
    marginLeft = -0.4;
    if (item.titleCode === "B") {
      marginTop = -0.32;
    } else {
      marginBottom = -0.32;
    }
  }

  let itemTitle = item.itemTitle || "";
  if (["S", "R"].includes(item.titleCode)) {
    itemTitle = item.shiftName || "";
    if (item.startAlteration !== 0 || item.endAlteration !== 0) {
      itemTitle = `${itemTitle}*`;
    }
  }

  const { key, ...itemProps } = getItemProps(item.itemProps);

  return (
    <Tooltip placement="right" title={<TooltipTitle item={item} />}>
      <GanttItem key={key} {...item.itemProps} {...itemProps} title={undefined}>
        {itemContext.useResizeHandle ? <Box {...leftResizeProps} /> : ""}

        <Typography
          variant="caption"
          fontSize={fontSize}
          color={textColor}
          mt={marginTop}
          mb={marginBottom}
          ml={marginLeft}
        >
          {itemContext.width - (1 + itemTitle.length) * 7 > 0 ? itemTitle : ""}
        </Typography>

        {itemContext.useResizeHandle ? <Box {...rightResizeProps} /> : ""}
      </GanttItem>
    </Tooltip>
  );
}

import { GenericOptimizationSetting } from "settings/common/optimizationSetting";

const SCHEDULE_SETTINGS_BASE_PATH = "/schedule-settings";

export function optimizationRulesUrl(teamGroupId?: string) {
  return teamGroupId != null
    ? `${SCHEDULE_SETTINGS_BASE_PATH}/${teamGroupId}`
    : SCHEDULE_SETTINGS_BASE_PATH;
}

export function optimizationRuleUrl(
  teamGroupId: string | undefined,
  setting: GenericOptimizationSetting,
): string;
export function optimizationRuleUrl(
  teamGroupId: string | undefined,
  ruleId: string,
): string;
export function optimizationRuleUrl(
  teamGroupId: string | undefined,
  rule: string | GenericOptimizationSetting,
) {
  if (teamGroupId == null) {
    return optimizationRulesUrl(teamGroupId);
  }
  const ruleId = typeof rule === "string" ? rule : rule.urlId;
  return `${SCHEDULE_SETTINGS_BASE_PATH}/${teamGroupId}/${ruleId}`;
}

export function userExceptionsUrl(teamGroupId: string, userId: string) {
  return `${SCHEDULE_SETTINGS_BASE_PATH}/${teamGroupId}/${userId}`;
}

export function ruleGroupExceptionsUrl(teamGroupId: string, ruleGroup: string) {
  return `${SCHEDULE_SETTINGS_BASE_PATH}/${teamGroupId}/${ruleGroup}`;
}

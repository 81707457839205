/**
 * @generated SignedSource<<9729b9f35eaef992ccdc9acd6b0ddd60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentTeamGroupSelect_fragment$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "CurrentTeamGroupSelect_fragment";
}>;
export type CurrentTeamGroupSelect_fragment$key = ReadonlyArray<{
  readonly " $data"?: CurrentTeamGroupSelect_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentTeamGroupSelect_fragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CurrentTeamGroupSelect_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "TeamGroupNode",
  "abstractKey": null
};

(node as any).hash = "e72d17a8caee14649a8a98d0f3a9f7e3";

export default node;

import type { SelectChangeEvent } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { Frequency } from "rrule";

import {
  DAILY,
  MONTHLY,
  WEEKLY,
  YEARLY,
} from "components/blocked_time_slot/common/Recurrence/types";

type Props = {
  value: Frequency;
  onChange: (value: Frequency) => void;
  disableDay: boolean;
  disableWeek: boolean;
  disableMonth: boolean;
  disableYear: boolean;
};

export default function FrequencySelect({
  value,
  onChange,
  disableDay,
  disableWeek,
  disableMonth,
  disableYear,
}: Props) {
  function handleChange(event: SelectChangeEvent<number>) {
    onChange(event.target.value as Frequency);
  }
  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ width: 140 }}
    >
      <MenuItem disabled={disableYear} value={YEARLY}>
        Årligen
      </MenuItem>
      <MenuItem disabled={disableMonth} value={MONTHLY}>
        Månadsvis
      </MenuItem>
      <MenuItem disabled={disableWeek} value={WEEKLY}>
        Veckovis
      </MenuItem>
      <MenuItem disabled={disableDay} value={DAILY}>
        Dagligen
      </MenuItem>
    </Select>
  );
}

import { useEffect } from "react";

/** Workaround for a zoom bug in react-calendar-timeline.
 * Send a resize event when component has first loaded.
 * See https://github.com/namespace-ee/react-calendar-timeline/issues/688
 */
export function useRCTResizer(delay = 100) {
  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event("resize")), delay);
  }, [delay]);
}

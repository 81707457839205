/**
 * @generated SignedSource<<09617b5c59358d105ab7b52ad8c99dd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlockedTimeSlotFormQuery$variables = {
  teamGroupId: string;
};
export type BlockedTimeSlotFormQuery$data = {
  readonly shiftTypes: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"BlockedTimeSlotShiftSelect_fragment">;
  }>;
};
export type BlockedTimeSlotFormQuery = {
  response: BlockedTimeSlotFormQuery$data;
  variables: BlockedTimeSlotFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamGroupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "teamGroupId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockedTimeSlotFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ShiftNode",
        "kind": "LinkedField",
        "name": "shiftTypes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BlockedTimeSlotShiftSelect_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlockedTimeSlotFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ShiftNode",
        "kind": "LinkedField",
        "name": "shiftTypes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d8a1d99089cf0306101e8ecfcf51f0d",
    "id": null,
    "metadata": {},
    "name": "BlockedTimeSlotFormQuery",
    "operationKind": "query",
    "text": "query BlockedTimeSlotFormQuery(\n  $teamGroupId: ID!\n) {\n  shiftTypes(teamGroupId: $teamGroupId) {\n    ...BlockedTimeSlotShiftSelect_fragment\n    id\n  }\n}\n\nfragment BlockedTimeSlotShiftSelect_fragment on ShiftNode {\n  id\n  name\n  start\n  end\n}\n"
  }
};
})();

(node as any).hash = "2926a0420a4ec2c8aa43ef0d1c38f956";

export default node;

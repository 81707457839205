import { Code } from "react-content-loader";
import { useTheme } from "@mui/material";

type LoadingCodeProps = {
  height?: string;
};

export function LoadingCode({ height = "190px" }: LoadingCodeProps) {
  const theme = useTheme();
  return (
    <Code
      speed={3}
      height={height}
      backgroundColor={theme.palette.ture[10]}
      foregroundColor={theme.palette.ture[15]}
      className="d-block"
    />
  );
}

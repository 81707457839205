import { InputAdornment, Stack, Typography } from "@mui/material";

import CustomField from "components/forms/CustomField";
import { HardSoftRadios } from "components/setting/common/components/HardSoftRadios";

import { WorkDayCadenceRestrictions } from "./WorkDayCadenceRestrictions";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function Rhythm3A({ disabled, readOnly }: Props) {
  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Typography variant="h4">
          Maximum antal arbetskalenderdagar i följd
        </Typography>
        <Typography>
          Detta fält styr hur många sammanhängande arbetskalenderdagar i följd{" "}
          <i>som mest</i> får lov att schemaläggas. Notera! Ett nattpass räknas
          endast till den kalenderdag då passet startar.
        </Typography>
        <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
          <CustomField
            name="maxConsecutiveWorkDays"
            size="small"
            type="number"
            disabled={disabled}
            sx={{ minWidth: 200 }}
            InputProps={{
              readOnly,
              endAdornment: (
                <InputAdornment position="end">dagar</InputAdornment>
              ),
            }}
          />
          <HardSoftRadios
            name="maxConsecutiveWorkDaysSoft"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h4">
          Minimum antal arbetskalenderdagar i följd
        </Typography>
        <Typography>
          Detta fält styr hur många sammanhängande arbetskalenderdagar i följd{" "}
          <i>som minst</i> får lov att schemaläggas. Notera! Ett nattpass räknas
          endast till den kalenderdag då passet startar.
        </Typography>
        <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
          <CustomField
            name="minConsecutiveWorkDays"
            size="small"
            type="number"
            disabled={disabled}
            sx={{ minWidth: 200 }}
            InputProps={{
              readOnly,
              endAdornment: (
                <InputAdornment position="end">dagar</InputAdornment>
              ),
            }}
          />
          <HardSoftRadios
            name="minConsecutiveWorkDaysSoft"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export function Rhythm3B({ disabled, readOnly }: Props) {
  return (
    <Stack gap={4}>
      <Stack gap={1}>
        <Typography variant="h4">Regler för sammanhängande arbete</Typography>
        <Typography>
          Här kan du skapa egna, särskilda regler, för sammanhängande
          arbetskalenderdagar. Ett vanligt exempel är begränsning av 6
          arbetskalenderdagar på 8 rullande kalenderdagar. Notera! 6-av-8-regeln
          är en "dyr" regel och går sällan att få ihop överallt (i synnerhet vid
          varannan-helg-arbete). Vi rekommenderar därför att dessa regler alltid
          ska vara mjuka.
        </Typography>
      </Stack>
      <WorkDayCadenceRestrictions
        name="workDayCadenceRestrictions"
        disabled={disabled}
        readOnly={readOnly}
      />
    </Stack>
  );
}

export function Rhythm3({ disabled, readOnly }: Props) {
  return (
    <Stack gap={5}>
      <Rhythm3A disabled={disabled} readOnly={readOnly} />
      <Rhythm3B disabled={disabled} readOnly={readOnly} />
    </Stack>
  );
}

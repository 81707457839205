import { Divider, Stack } from "@mui/material";
import { Duration } from "luxon";

import type { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";
import {
  MONTHLY,
  WEEKLY,
  YEARLY,
} from "components/blocked_time_slot/common/Recurrence/types";

import FrequencyForm from "./FrequencyForm";
import MonthlyOptions from "./MonthlyOptions";
import UntilForm from "./UntilForm";
import WeeklyOptions from "./WeeklyOptions";
import YearlyOptions from "./YearlyOptions";

const oneMonth = Duration.fromObject({ months: 1 }).valueOf();
const oneDay = Duration.fromObject({ days: 1 }).valueOf();

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
  durationMs: number;
};

export default function RuleItem({ value, onChange, durationMs }: Props) {
  const { freq } = value;

  const disableDay = durationMs > oneDay;
  const disableWeek = durationMs > oneDay;
  const disableMonth = durationMs > oneDay;
  const disableYear = durationMs > oneMonth;

  return (
    <Stack gap={2}>
      <FrequencyForm
        value={value}
        onChange={onChange}
        {...{ disableDay, disableWeek, disableMonth, disableYear }}
      />
      {freq === YEARLY && (
        <YearlyOptions
          value={value}
          onChange={onChange}
          disabled={disableYear}
        />
      )}
      {freq === MONTHLY && (
        <MonthlyOptions
          value={value}
          onChange={onChange}
          disabled={disableMonth}
        />
      )}
      {freq === WEEKLY && (
        <WeeklyOptions
          value={value}
          onChange={onChange}
          disabled={disableWeek}
        />
      )}
      <Divider sx={{ mt: 2 }} />
      <UntilForm value={value} onChange={onChange} />
    </Stack>
  );
}

import { memo, useCallback, useMemo } from "react";
import { alpha, Box, Stack, styled, Switch, Typography } from "@mui/material";
import { useFeatureFlags } from "contexts/FeatureFlagContext";

const RedGreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FF0000",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#11FF11",
    "&:hover": {
      backgroundColor: alpha("#11FF11", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FF0000",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#11FF11",
  },
}));

export const FeatureFlagToggles = memo(function FeatureFlagTogglesFn() {
  const { flags, updateFeatureFlag } = useFeatureFlags();

  const getToggleFunction = useCallback(
    (flagName: keyof typeof flags) => {
      return () => updateFeatureFlag(flagName, !flags[flagName]);
    },
    [updateFeatureFlag, flags],
  );

  const onToggleScheduleService = useMemo(
    () => getToggleFunction("useScheduleService"),
    [getToggleFunction],
  );
  const onTogglePermissions = useMemo(
    () => getToggleFunction("usePermissions"),
    [getToggleFunction],
  );

  return (
    <Stack>
      <Typography sx={{ alignSelf: "center" }} color={"darkgray"}>
        Feature Toggles
      </Typography>

      <Box display="flex" alignItems="center">
        <RedGreenSwitch
          checked={flags.useScheduleService}
          onChange={onToggleScheduleService}
        />
        <Typography color="white">Use schedule service</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <RedGreenSwitch
          checked={flags.usePermissions}
          onChange={onTogglePermissions}
        />
        <Typography color="white">Use permissions</Typography>
      </Box>
    </Stack>
  );
});

import { useCallback } from "react";
import { Stack } from "@mui/material";

import { LabeledWeightSelect } from "../common";

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export function Weights({ name, disabled, readOnly }: Props) {
  const getName = useCallback(
    (fieldName: string) => `${name}.${fieldName}`,
    [name],
  );

  return (
    <Stack columnGap={5} rowGap={2} direction="row" flexWrap="wrap">
      <Stack columnGap={5} rowGap={2} direction="row" flexWrap="wrap">
        <LabeledWeightSelect
          name={getName("understaffingWeight")}
          label="Prioritet för minimering av total underbemanning"
          disabled={disabled}
          readOnly={readOnly}
          sx={{ width: 150 }}
        />
        <LabeledWeightSelect
          name={getName("weekendUnderstaffingWeight")}
          label="Prioritet för minimering av underbemanning på helg"
          disabled={disabled}
          readOnly={readOnly}
          sx={{ width: 150 }}
        />
      </Stack>
      <Stack columnGap={5} rowGap={2} direction="row" flexWrap="wrap">
        <LabeledWeightSelect
          name={getName("overstaffingWeight")}
          label="Prioritet för minimering av total spilltid"
          disabled={disabled}
          readOnly={readOnly}
          sx={{ width: 150 }}
        />
        <LabeledWeightSelect
          name={getName("weekendOverstaffingWeight")}
          label="Prioritet för minimering av spilltid på helg"
          disabled={disabled}
          readOnly={readOnly}
          sx={{ width: 150 }}
        />
      </Stack>
    </Stack>
  );
}

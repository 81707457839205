import {
  HeaderRadios,
  HeaderSwitch,
  SettingBoxHeader,
} from "components/setting/common";

type Props = {
  moduleName: string;
  readOnly?: boolean;
  disabled?: boolean;
};

type RadiosProps = Pick<Props, "readOnly" | "disabled">;

function Radios({ readOnly, disabled }: RadiosProps) {
  return (
    <HeaderRadios
      type="onlyHard"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Header({ moduleName, readOnly, disabled }: Props) {
  return (
    <SettingBoxHeader
      radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
      switchComponent={<HeaderSwitch name={moduleName} readOnly={readOnly} />}
    />
  );
}

import type { TextFieldProps } from "@mui/material";
import { DateTime } from "luxon";

import DatePickerWithWeekNumbers from "components/schedule/DatePickerWithWeekNumbers";

type Props = Omit<TextFieldProps, "value" | "onChange" | "type"> & {
  value: DateTime;
  onChange: (date: DateTime | null) => void;
};

export function CurrentDate({ value, onChange, size = "small" }: Props) {
  return (
    <DatePickerWithWeekNumbers
      onChange={onChange}
      value={value}
      size={size}
      views={["year", "month", "day"]}
    />
  );
}

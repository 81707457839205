import type { TextFieldProps } from "@mui/material";
import { TextField } from "@mui/material";
import { Field, FieldProps, FieldValidator, getIn } from "formik";

type Props = TextFieldProps & {
  validate?: FieldValidator;
  name: string;
  style?: any;
  min?: number;
  max?: number;
};

/**
 * Custom field handles styling for form validation.
 */
const CustomField = ({
  name,
  validate,
  size = "small",
  type = "text",
  ...props
}: Props) => (
  <Field name={name} validate={validate}>
    {({ field, form: { touched, errors } }: FieldProps) => (
      <TextField
        {...field}
        {...props}
        size={size}
        type={type}
        error={getIn(touched, name) && !!getIn(errors, name)}
        helperText={getIn(touched, name) && getIn(errors, name)}
        InputProps={{
          ...(props?.InputProps ?? {}),
          sx: {
            bgcolor: "background.default",
            ...(props?.InputProps?.sx ?? {}),
          },
        }}
      />
    )}
  </Field>
);

export default CustomField;

import { useCallback } from "react";
import {
  InputAdornment,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { FormikSmallSwitch, SmallSwitch } from "components/common/Switch";
import CustomField from "components/forms/CustomField";

import { HardSoftRadios } from "../components/HardSoftRadios";
import { WeekdaySelect } from "../components/WeekdaySelect";

type Props = {
  periodLengthWeeks: number;
  disabled?: any;
  readOnly?: boolean;
  who?: any;
};

export function Free1A({ periodLengthWeeks, disabled, readOnly }: Props) {
  const { value: freeEveryXOfYWeekends } = useFormikState(
    "freeEveryXOfYWeekends",
  );
  const { value: workEveryXWeekend } = useFormikState("workEveryXWeekend");
  const { value: xOfY, setValue: setXOfY } = useFormikState(
    "freeWeekendConstraintTypeXOfY",
  );
  const { value: everyX, setValue: setEveryX } = useFormikState(
    "freeWeekendConstraintTypeEveryX",
  );

  const handleXOfY = useCallback(() => {
    setXOfY(!xOfY);
    setEveryX(xOfY);
  }, [xOfY, setEveryX, setXOfY]);

  const handleEveryX = useCallback(() => {
    setEveryX(!everyX);
    setXOfY(everyX);
  }, [everyX, setEveryX, setXOfY]);

  // Help text for switch 2
  let everyXLabel = `Arbete var ${workEveryXWeekend}:e helg`;
  if (workEveryXWeekend === 0) {
    everyXLabel = "Ingen ledig helg";
  } else if (workEveryXWeekend === 1) {
    everyXLabel = "Arbete varje helg";
  } else if (workEveryXWeekend === 2) {
    everyXLabel = "Arbete varannan helg";
  }

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="h4">
                {`Ledig ${freeEveryXOfYWeekends} av ${periodLengthWeeks} helger`}
              </Typography>
              <SmallSwitch
                checked={xOfY}
                onChange={handleXOfY}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          </Grid>
          <Grid xs={1}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="h4">{everyXLabel}</Typography>
              <SmallSwitch
                checked={everyX}
                onChange={handleEveryX}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <Typography>
              Detta fält styr hur många helger under schemaperioden som ska
              innehålla fridagar. Detta alternativ tar ingen särskild hänsyn
              till de fria helgernas ordning.
            </Typography>
          </Grid>
          <Grid xs={1}>
            <Typography>
              Detta fält styr hur många helger under schemaperioden som ska
              innehålla fridagar. Detta alternativ styr dessutom de fria
              helgernas ordning.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={2}>
          <Grid xs={1}>
            <CustomField
              name="freeEveryXOfYWeekends"
              fullWidth
              size="small"
              type="number"
              disabled={!xOfY || disabled}
              sx={{ flexGrow: 2 }}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    av {periodLengthWeeks} helger
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={1}>
            <CustomField
              name="workEveryXWeekend"
              fullWidth
              size="small"
              type="number"
              disabled={!everyX || disabled}
              InputProps={{ readOnly }}
              sx={{ flexGrow: 2 }}
            />
          </Grid>
        </Grid>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h4">
          Lediga helger får inte innehålla arbete
        </Typography>
        <Stack gap={3} direction={"row"}>
          <Typography>
            Detta fält styr hur huruvida det är ett mjukt eller hårt krav att
            lediga helger inte ska innehålla arbete. Vi rekommenderar att denna
            regel alltid ska vara hård.
          </Typography>
          <Stack gap={1} minWidth={140} justifyContent={"center"}>
            <HardSoftRadios
              name="freeWeekendSoft"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

type Free1BProps = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function Free1B({ disabled = false, readOnly }: Free1BProps) {
  const { value: weekendStartsAtDay, setValue: setWeekendStartsAtDay } =
    useFormikState("weekendStartsAtDay");
  const { value: weekendEndsAtDay, setValue: setWeekendEndsAtDay } =
    useFormikState("weekendEndsAtDay");

  return (
    <Stack gap={1}>
      <Typography variant="h4">Lediga helgens start och slut</Typography>

      <Stack direction="row" gap={2} alignItems="center" flexWrap={"wrap"}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography>Den lediga helgen startar på</Typography>
          <Stack direction="row" gap={1}>
            <WeekdaySelect
              readOnly={readOnly}
              onChange={(_e: any, v: any) =>
                setWeekendStartsAtDay(v?.props.value)
              }
              value={weekendStartsAtDay}
              options={[4, 5]}
              disabled={disabled}
            />
            <CustomField
              type="time"
              name="weekendStartsAt"
              validate={(value) => (value ? undefined : "Fyll i tid")}
              disabled={disabled}
              InputProps={{ readOnly }}
              sx={{ minWidth: 100 }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography>och slutar på</Typography>
          <Stack direction="row" gap={1}>
            <WeekdaySelect
              readOnly={readOnly}
              onChange={(_e: any, v: any) =>
                setWeekendEndsAtDay(v?.props.value)
              }
              value={weekendEndsAtDay}
              options={[6, 0]}
              disabled={disabled}
            />
            <CustomField
              type="time"
              name="weekendEndsAt"
              validate={(value) => (value ? undefined : "Fyll i tid")}
              disabled={disabled}
              InputProps={{ readOnly }}
              sx={{ minWidth: 100 }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export function Free1C({ disabled, readOnly }: Props) {
  const { value: workEveryUnfreeWeekend } = useFormikState(
    "workEveryUnfreeWeekend",
  );
  return (
    <Stack gap={1}>
      <Stack direction="row" gap={2} alignItems="center">
        <Typography variant="h4">
          Arbetshelger måste innehålla 2 arbetskalenderdagar
        </Typography>
        <FormikSmallSwitch
          name="workEveryUnfreeWeekend"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <Stack gap={3} direction={"row"}>
        <Typography>
          Detta fält styr hur huruvida helger som inte är lediga (det vill säga,
          arbetshelger) ska kunna innehålla fridagar. Om detta val är påslaget,
          så kommer arbetshelger (lördag och söndag) alltid att innehålla 2
          arbetskalenderdagar oavsett hur bemanningsbehovet ser ut.
        </Typography>
        <Stack gap={1} minWidth={140} justifyContent={"center"}>
          <HardSoftRadios
            name="workEveryUnfreeWeekendSoft"
            disabled={!workEveryUnfreeWeekend || disabled}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export function Free1({ periodLengthWeeks, disabled, readOnly, who }: Props) {
  return (
    <Stack gap={5}>
      <Free1A
        periodLengthWeeks={periodLengthWeeks}
        disabled={disabled}
        readOnly={readOnly}
        who={who}
      />
      <Free1C
        periodLengthWeeks={periodLengthWeeks}
        disabled={disabled}
        readOnly={readOnly}
        who={who}
      />
      <Free1B disabled={disabled} readOnly={readOnly} />
    </Stack>
  );
}

import { MenuItem, Select, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

type Props = {
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
};

const options = [
  { value: "E", label: "procent" },
  { value: "O", label: "procentenheter" },
];

export function ShiftScalingLogicSelect({ name, readOnly, disabled }: Props) {
  const { value: scalingLogic, setValue: setScalingLogic } =
    useFormikState<string>(name);

  return (
    <Select
      value={scalingLogic.toUpperCase()}
      onChange={(_e: any, v: any) => setScalingLogic(v?.props.value)}
      readOnly={readOnly}
      disabled={disabled}
      size="small"
      sx={{ width: 245, backgroundColor: "white" }}
    >
      {options.map((o) => (
        <MenuItem key={o.value} value={o.value}>
          <Typography>{o.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

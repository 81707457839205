import type { Dispatch, FC, PropsWithChildren, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

type Context = { isOpen: boolean; setOpen: Dispatch<SetStateAction<boolean>> };
const InfoModalContext = createContext<Context>({
  isOpen: false,
  setOpen: () => {},
});
InfoModalContext.displayName = "InfoModalContext";
export const InfoModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <InfoModalContext.Provider value={{ isOpen, setOpen }}>
      {children}
    </InfoModalContext.Provider>
  );
};
export const useInfoModal = () => {
  const context = useContext(InfoModalContext);
  if (!context) {
    throw new Error("Illegal use of useInfoModal outside InfoModalProvider");
  }
  return context;
};

import { useMemo } from "react";
import { Chip, ChipProps, useTheme } from "@mui/material";

type Competence = {
  id: string;
  name: string;
  color: string;
};

type Props = ChipProps & {
  competence: Competence;
  getLabel?: (competence: Competence) => string;
};

export function CompetenceChip({
  competence,
  getLabel = (competence: Competence) => competence.name,
  size = "small",
  ...p
}: Props) {
  const theme = useTheme();
  const label = getLabel(competence);

  const colorSx = useMemo(
    () => ({
      backgroundColor: competence?.color || "lightgray",
      color: competence?.color
        ? theme.palette.getContrastText(competence.color)
        : "black",
    }),
    [competence, theme],
  );
  const sx = useMemo(
    () => ({
      ...colorSx,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.light,
      ...(p.sx ?? {}),
      "& .MuiChip-deleteIcon": {
        color: colorSx.color,
      },
    }),
    [colorSx, theme, p?.sx],
  );

  return <Chip {...p} size={size} label={label} sx={sx} />;
}

import { Paper, Stack, Unstable_Grid2 as Grid } from "@mui/material";

import Ibox from "components/layout/Ibox";
import BarChartPlaceholder from "components/loading/BarChartPlaceholder";
import { LoadingCode } from "components/loading/LoadingCode";
import { LoadingSelector } from "components/loading/LoadingSelector";

function SelectPlaceholders() {
  return (
    <Stack direction="row" gap={1}>
      <LoadingSelector width={210} />
      <LoadingSelector width={210} />
    </Stack>
  );
}

type Props = {
  users: number;
};

export function StatisticsPlaceholder({ users }: Props) {
  return (
    <Stack gap={2} pt={2}>
      <SelectPlaceholders />
      <Paper variant="box" sx={{ p: 2 }}>
        <LoadingCode height="205px" />
      </Paper>
      <Paper variant="box" sx={{ p: 2 }}>
        <LoadingCode height="205px" />
      </Paper>
      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <Ibox>
            <BarChartPlaceholder
              style={{ height: `${60 + users * 22}px`, width: "100%" }}
            />
          </Ibox>
        </Grid>
        <Grid xs={12} md={6}>
          <Ibox>
            <BarChartPlaceholder
              style={{ height: `${60 + users * 22}px`, width: "100%" }}
            />
          </Ibox>
        </Grid>
        <Grid xs={12} md={6}>
          <Ibox>
            <BarChartPlaceholder
              style={{ height: `${60 + users * 22}px`, width: "100%" }}
            />
          </Ibox>
        </Grid>
        <Grid xs={12} md={6}>
          <Ibox>
            <BarChartPlaceholder
              style={{ height: `${60 + users * 22}px`, width: "100%" }}
            />
          </Ibox>
        </Grid>
      </Grid>
    </Stack>
  );
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { EditTeamMutation as Mutation } from "./types";

const mutation = graphql`
  mutation EditTeamMutation($input: EditTeamInput!) {
    editTeam(input: $input) {
      team {
        id
        name
        color
        isActive
        archivedAt
      }
    }
  }
`;

export const useEditTeam = () => useMutation<Mutation>(mutation);

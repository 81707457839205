import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const Rhythm3InfoTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Sammanhängande arbete</b>?
  </InfoTitle>
);

const Rhythm3InfoContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export function Rhythm3Info() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal title={<Rhythm3InfoTitle />}>
        <Rhythm3InfoContent />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

/**
 * @generated SignedSource<<48171fbcf1c3e882956211a462847ff2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingSplitShiftsWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SplitShiftsSettingTeamGroup_fragment$data = {
  readonly allowSplitShifts: boolean;
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly optimizeSplitShiftsDistributionBetweenEmployees: boolean;
  readonly splitShiftsWeight: ApiSettingSplitShiftsWeightChoices;
  readonly " $fragmentType": "SplitShiftsSettingTeamGroup_fragment";
};
export type SplitShiftsSettingTeamGroup_fragment$key = {
  readonly " $data"?: SplitShiftsSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SplitShiftsSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SplitShiftsSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowSplitShifts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeSplitShiftsDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "splitShiftsWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "f385d477956ff54b2b81c731f0e7c676";

export default node;

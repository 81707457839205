import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { listUserPermissions } from "api/api";
import { globalIdToIncrementalId } from "utils/object";

import { useFeatureFlags } from "./FeatureFlagContext";

type PermissionSetter = {
  permissions: Record<string, string>;
  fetchPermissions: (userID: string) => void;
  evaluatePermission: (permissionName: string, permission: string) => boolean;
  loading: boolean;
};

const permission_hierarchy: Record<string, number> = {
  ADMIN: 0,
  WRITE: 1,
  READ: 2,
};

const PermissionContext = createContext<PermissionSetter | null>(null);

export const usePermissions = () => {
  const context = useContext<PermissionSetter | null>(PermissionContext);

  if (context == null) {
    throw new Error("usePermissions must be used within PermissionProvider");
  }

  return context;
};

export function PermissionProvider({ children }: PropsWithChildren) {
  const [permissions, setPermissions] = useState<Record<string, string>>({
    init: "init",
  });
  const { flags } = useFeatureFlags();

  const fetchPermissions = useCallback(
    async (userID: string) => {
      const permissions =
        flags.usePermissions &&
        (await listUserPermissions(globalIdToIncrementalId(userID)));
      if (permissions) {
        const permissionRecord = permissions.reduce(
          (acc: any, permission: any) => {
            acc[permission.permission_name] = permission.permission;
            return acc;
          },
          {} as Record<string, string>,
        );
        setPermissions(permissionRecord);
      }
    },
    [setPermissions, flags.usePermissions],
  );

  const evaluatePermission = useCallback(
    (permissionName: string, permission: string): boolean => {
      return (
        !flags.usePermissions ||
        permission_hierarchy[permission] >=
          permission_hierarchy[permissions[permissionName]]
      );
    },
    [permissions, flags.usePermissions],
  );

  const loading = !!permissions["init"];

  const contextValue: PermissionSetter = useMemo(
    () => ({
      permissions,
      fetchPermissions,
      evaluatePermission,
      loading,
    }),
    [permissions, fetchPermissions, evaluatePermission, loading],
  );

  return (
    <PermissionContext.Provider value={contextValue}>
      {children}
    </PermissionContext.Provider>
  );
}

import type { ComponentProps, PropsWithChildren } from "react";
import { useMemo } from "react";
import useFormikState from "hooks/useFormikState";

import { FormikWeekCard } from "components/common/WeekCard";

import type { DemandWeeksInput } from "../types";

import { useDemandForm } from "./DemandFormContext";

type FormikWeekCardProps = ComponentProps<typeof FormikWeekCard>;

type Props = Omit<
  FormikWeekCardProps,
  "addLabel" | "countLabel" | "onAddRow" | "weekCount"
> & {
  name: string;
  onAdd: FormikWeekCardProps["onAddRow"];
};

const useCountLabel = (name: string) => {
  const { value: demand } = useFormikState<DemandWeeksInput>(name);

  return useMemo(() => {
    const count = (demand?.demands || []).length;
    return `${count} dagblock`;
  }, [demand?.demands]);
};

export function DemandWeekCard({
  name,
  onAdd,
  ...props
}: PropsWithChildren<Props>) {
  const { weekCount } = useDemandForm();
  const countLabel = useCountLabel(name);

  return (
    <FormikWeekCard
      {...props}
      name={`${name}.weeks`}
      addLabel="Lägg till dagblock"
      countLabel={countLabel}
      onAddRow={onAdd}
      weekCount={weekCount}
    />
  );
}

import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
} from "@mui/material";
import { Form as FormikForm, useFormikContext } from "formik";

import { DialogTitle } from "components/common/Dialog";
import CustomField from "components/forms/CustomField";

type Props = {
  title: string;
  submitText: string;
  onClose: () => void;
};

export type TeamGroupFormValues = {
  name: string;
};
export type EditTeamGroupFormValues = TeamGroupFormValues & {
  id: string;
};

export function TeamGroupForm({ title, submitText, onClose }: Props) {
  const { isSubmitting } = useFormikContext<TeamGroupFormValues>();
  return (
    <FormikForm autoComplete="off">
      <DialogTitle title={title} />

      <Divider />

      <DialogContent>
        <Stack pb={1}>
          <CustomField name="name" label="Namn" />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {submitText}
        </Button>
        <Button variant="cancel" onClick={onClose}>
          Avbryt
        </Button>
      </DialogActions>
    </FormikForm>
  );
}

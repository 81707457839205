import { useMemo } from "react";
import useFormikState from "hooks/useFormikState";

export default function DayShiftDistributionSum() {
  const { value: day } = useFormikState("dayShiftDistributionShare");
  const { value: evening } = useFormikState("eveningShiftDistributionShare");
  const { value: night } = useFormikState("nightShiftDistributionShare");
  const { value: fullDay } = useFormikState("fullDayShiftDistributionShare");
  const sum = useMemo(
    () => day + evening + night + fullDay,
    [day, evening, night, fullDay],
  );
  return sum;
}

import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress as Loading,
  DialogActions,
  DialogContent,
  Divider,
  FormGroup,
  Stack,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Form as FormikForm, useFormikContext } from "formik";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import useFormikState from "hooks/useFormikState";

import { DialogTitle } from "components/common/Dialog";
import ActivityTypeSelect from "components/common/selectors/ActivityTypeSelect";
import type { Option as TeamOption } from "components/common/selectors/TeamSelect";
import { BaseTeamSelect } from "components/common/selectors/TeamSelect";
import type { Opt as UserOption } from "components/common/selectors/UserSelect";
import { BaseUserSelect } from "components/common/selectors/UserSelect";
import CustomField from "components/forms/CustomField";
import { useScheduleContext } from "components/schedule";
import type { ActivityFormValues } from "components/schedule/activities";
import { DeleteActivity } from "components/schedule/activities/mutations";
import type { ValidationResultState } from "components/schedule/activities/validations";
import { ValidationResult } from "components/schedule/activities/validations";

import { ActivityPartsForm } from "./ActivityPartForm";

type ActionsProps = {
  submitText: any;
  onClose: () => void;
  deletable?: boolean;
  activityId?: string;
  validationLoading: boolean;
};

type FormProps = {
  validationState: ValidationResultState;
};

type Props = {
  title: any;
  submitText: any;
  deletable?: boolean;
  activityId?: string;
  validationState: ValidationResultState;
  onClose: () => void;
};

type TeamSelectProps = {
  name: string;
  disabled?: boolean;
  options: TeamOption[];
};
function LocalTeamSelect({ name, disabled, options }: TeamSelectProps) {
  const { value, setValue } = useFormikState<string>(name);
  return (
    <BaseTeamSelect
      value={value}
      onChange={(v: string | null) => setValue(v || "")}
      disabled={disabled}
      options={options}
    />
  );
}

type UserSelectProps = {
  name: string;
  label: string;
  options: UserOption[];
};
function LocalUserSelect({ name, label, options }: UserSelectProps) {
  const teamGroup = useCurrentTeamGroup();
  return (
    <BaseUserSelect
      name={name}
      label={label}
      options={options}
      teamGroup={teamGroup}
    />
  );
}

function Form({ validationState }: FormProps) {
  const { teams: teamsP, users: usersP } = useScheduleContext();
  const teams = useMemo(
    () =>
      teamsP.map((t) => ({
        ...t,
        group: { id: "", name: "" },
      })),
    [teamsP],
  );
  const users = useMemo(
    () =>
      usersP.map((u) => ({
        label: u.name,
        value: u.id,
      })),
    [usersP],
  );
  const { values } = useFormikContext<ActivityFormValues>();
  const { activityType } = values;
  const isShiftOrResource = useMemo(
    () => ["S", "R"].includes(activityType),
    [activityType],
  );

  return (
    <Grid container spacing={3} pt={1}>
      <Grid md={6}>
        <FormGroup>
          <CustomField type="datetime-local" name="start" label="Start" />
        </FormGroup>
      </Grid>
      <Grid md={6}>
        <FormGroup>
          <CustomField type="datetime-local" name="end" label="Slut" />
        </FormGroup>
      </Grid>
      <Grid md={6}>
        <FormGroup>
          <LocalUserSelect name="userId" label="Person" options={users} />
        </FormGroup>
      </Grid>
      <Grid md={6}>
        <FormGroup>
          <ActivityTypeSelect name="activityType" label="Händelsetyp" />
        </FormGroup>
      </Grid>
      <Grid md={6}>
        <FormGroup>
          <LocalTeamSelect
            name="teamId"
            disabled={!isShiftOrResource}
            options={teams}
          />
        </FormGroup>
      </Grid>
      <Grid md={6}>
        <FormGroup>
          <CustomField
            type="number"
            name="breakTime"
            label="Paustid (minuter)"
            disabled={!isShiftOrResource}
          />
        </FormGroup>
      </Grid>
      {isShiftOrResource ? (
        <Grid md={12}>
          <ActivityPartsForm name="activityParts" />
        </Grid>
      ) : null}
      <Grid xs={12}>
        <ValidationResult state={validationState} />
      </Grid>
    </Grid>
  );
}

function Actions({
  deletable,
  activityId,
  submitText,
  onClose,
  validationLoading,
}: ActionsProps) {
  const navigate = useNavigate();
  const { isSubmitting, isValid, isValidating, dirty } =
    useFormikContext<ActivityFormValues>();

  return (
    <Stack
      gap={0}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={1}
      width="100%"
    >
      {deletable ? (
        <Button
          variant="delete"
          disabled={isValidating}
          onClick={() =>
            DeleteActivity(activityId || "").then((response) => {
              onClose();
              const clonedId = response.deleteActivity?.clonedSchedule?.id;
              if (clonedId) {
                navigate(`/schedules/${clonedId}`);
              }
            })
          }
        >
          Radera händelse
        </Button>
      ) : (
        <div />
      )}

      <Stack direction="row" gap={1}>
        {isSubmitting && <Loading sx={{ p: 1 }} />}
        <Button variant="cancel" onClick={onClose}>
          Avbryt
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={
            !isValid ||
            isSubmitting ||
            !dirty ||
            isValidating ||
            validationLoading
          }
        >
          {submitText}
        </Button>
      </Stack>
    </Stack>
  );
}

export function ActivityForm({
  title,
  submitText,
  onClose,
  deletable,
  activityId,
  validationState,
}: Props) {
  return (
    <FormikForm autoComplete="off">
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Form validationState={validationState} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Actions
          submitText={submitText}
          deletable={deletable}
          activityId={activityId}
          onClose={onClose}
          validationLoading={validationState.loading}
        />
      </DialogActions>
    </FormikForm>
  );
}

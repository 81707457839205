import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { ArchiveCompetenceMutation as Mutation } from "./types";

const mutation = graphql`
  mutation ArchiveCompetenceMutation($id: ID!) {
    archiveCompetence(input: { id: $id }) {
      ok
      competence {
        id
        isActive
        archivedAt
      }
    }
  }
`;

export const useArchiveCompetence = () => useMutation<Mutation>(mutation);

import { useEffect, useRef } from "react";
import { useFormikContext } from "formik";

type Props = {
  delay?: number;
};

// Used with formik forms
export function useAutoSubmit(delay = 250) {
  const valueRef = useRef<any>();
  const { values, dirty, submitForm } = useFormikContext();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (dirty && valueRef.current && valueRef.current !== values) {
        submitForm();
      }
      valueRef.current = values;
    }, delay);
    return () => clearTimeout(timeout);
  }, [dirty, submitForm, values, delay]);

  return null;
}

// Used with formik forms
export function AutoSubmit({
  delay = 250,
  children,
}: React.PropsWithChildren<Props>) {
  useAutoSubmit(delay);
  return <>{children}</>;
}

import { FormInfo } from "../info";
import { FormHeaderScaffold } from "../Scaffolds";

import type { HeaderActionsProps } from "./HeaderActions";
import { HeaderActions } from "./HeaderActions";
import { HeaderInfo } from "./HeaderInfo";

export type FormHeaderProps = Pick<
  HeaderActionsProps,
  "submitProps" | "cancelProps"
>;

export function FormHeader({ submitProps, cancelProps }: FormHeaderProps) {
  return (
    <FormHeaderScaffold
      headerInfo={<HeaderInfo />}
      formInfo={<FormInfo />}
      actions={
        <HeaderActions submitProps={submitProps} cancelProps={cancelProps} />
      }
    />
  );
}

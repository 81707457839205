import type { PropsWithChildren, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

type Props = {
  title?: string;
  actions?: ReactNode;
  info?: ReactNode;
};

export function PageHeader({
  title,
  actions,
  info,
  children,
}: PropsWithChildren<Props>) {
  const showTitle = !!title;
  const showActions = !!actions;
  const showTitleOrActions = showTitle || showActions;

  return (
    <Stack gap={2}>
      {showTitleOrActions && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {showTitle && (
            <Typography variant="h1" color="svart.100">
              {title}
            </Typography>
          )}
          {info}
          {showActions && (
            <Stack direction="row" gap={2}>
              {actions}
            </Stack>
          )}
        </Stack>
      )}
      {children}
    </Stack>
  );
}

import { useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { Collapse, Stack } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import type { FormikFieldArrayFn } from "types/formik";
import { SHORT_DAYS } from "utils/day";

import { getDaysLeft } from "../functions";
import type { CreateShiftsInput as FormType } from "../types";

import { ShiftGroupDayCard } from "./ShiftGroupDayCard";
import ShiftTable from "./ShiftTable";

const UNSELECTED = -1;

type Props = {
  name: string;
};

export default function ShiftFormDays({ name }: Props) {
  const [openIdx, setOpenIdx] = useState<number>(UNSELECTED);
  const { values } = useFormikContext<FormType>();
  const daysLeft = getDaysLeft(SHORT_DAYS, values);

  const toggleOpen = (idx: number) =>
    setOpenIdx((prev) => (prev === idx ? UNSELECTED : idx));

  return (
    <FieldArray name={name}>
      {({ remove, form }: FormikFieldArrayFn<FormType>) => (
        <Stack gap={2} component={TransitionGroup}>
          {(form.values.shiftGroups || []).map((_, i) => (
            <Collapse key={i}>
              <ShiftGroupDayCard
                key={i}
                name={`${name}.${i}`}
                onRemove={() => remove(i)}
                expanded={openIdx === i}
                onExpand={() => toggleOpen(i)}
                daysLeft={daysLeft}
              >
                <ShiftTable name={`${name}.${i}.shifts`} />
              </ShiftGroupDayCard>
            </Collapse>
          ))}
        </Stack>
      )}
    </FieldArray>
  );
}

import { Link } from "react-router-dom";
import { Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";

import PartTimeGraph from "components/setting/common/components/PartTimeGraph";
import ExampleBox from "components/setting/common/ExampleBox";

import { Title } from "./Title";
import type { GraphData } from "./useGraphData";

type Props = {
  graphData: GraphData;
  disabled?: boolean;
};

export function AverageShiftLength({ graphData, disabled }: Props) {
  return (
    <Stack gap={1}>
      <Grid id="row1" container spacing={2} columns={1}>
        <Grid xs={1}>
          <Stack gap={1}>
            <Title sx={{ flexGrow: 2 }}>Genomsnittlig passlängd</Title>
            <Typography sx={{ flexGrow: 2 }}>
              Denna graf visar huruvida passtyperna i{" "}
              <Link to="/shifts">
                <b>passprofilen</b>
              </Link>{" "}
              är möjliga att kombinera för att uppfylla de hel- och deltidsmått
              som du har valt.
            </Typography>
            <Typography sx={{ flexGrow: 2 }}>
              Den gröna linjen visar längsta passlängd, den lila visar kortaste
              passlängd, den blå kurvan visar förväntad genomsnittlig passlängd
              för respektive tjänstgöringsgrad (0-100%). Försök alltid att
              konstruera din passprofil så att den blå kurvan stannar innanför
              den gröna linjen och den lila linjen. Tips! Pröva att ändra
              marginalen för timmar och pass (nedan) för att se vad som händer
              med grafen.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      {!disabled && (
        <>
          <Grid id="row4" container spacing={2} columns={1}>
            <Grid xs={1}>
              <ExampleBox sx={{ flexGrow: 2 }}>
                {PartTimeGraph(graphData, "duration")}
              </ExampleBox>
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
}

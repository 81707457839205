import elementResizeDetectorMaker from "element-resize-detector";

/* Since the container resize detector is not exposed by the react-calendar-timeline
 * library, we need to copy the code from the library and expose the functions
 * we need.
 *
 * The code is copied from the following file:
 * https://github.com/namespace-ee/react-calendar-timeline/blob/master/src/resize-detector/container.js
 */

function addListener(component) {
  component._erd = elementResizeDetectorMaker({
    strategy: "scroll",
  });

  component._erdWidth = component.container.offsetWidth;

  component._erd.listenTo(component.container, (element) => {
    const width = element.offsetWidth;

    if (component._erdWidth !== width) {
      component.resize(component.props);
      component._erdWidth = width;
    }
  });
}

function removeListener(component) {
  component._erd.removeAllListeners(component.container);
}

const functions = {
  addListener,
  removeListener,
};

export default functions;

import { Dialog } from "@mui/material";
import { Formik } from "formik";
import { TeamGroupOption } from "types/TeamGroupsContextQueryTypes";
import * as yup from "yup";

import { useCreateTeam } from "../mutations";
import type { CreateTeamInput as FormValues } from "../types";

import { TeamForm } from "./common";

const colorString = yup.string().matches(/^#[0-9a-fA-F]{6}$/, "Ogiltig färg");

const validationSchema = yup.object({
  name: yup.string().required("Fyll i ett namn"),
  groupId: yup.string().required("Välj en grupp"),
  color: colorString,
});

const DEFAULT_COLOR = "#000000";

type Props = {
  open: boolean;
  onClose: () => void;
  initialValues?: Partial<FormValues>;
  teamGroupOptions?: TeamGroupOption[];
};

export function CreateTeamForm({
  open,
  onClose,
  initialValues: initValues = {},
  teamGroupOptions = [],
}: Props) {
  const [commit] = useCreateTeam();

  const initialValues: FormValues = {
    name: "",
    groupId: "",
    color: DEFAULT_COLOR,
    ...initValues,
  };

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } })
      .then(onClose)
      .catch((error) => console.error(error));
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <TeamForm
          title="Skapa ny avdelning"
          submitText="Spara"
          onClose={onClose}
          teamGroupOptions={teamGroupOptions}
        />
      </Formik>
    </Dialog>
  );
}

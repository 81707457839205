import { useCallback } from "react";
import { Link } from "react-router-dom";
import type { StackProps } from "@mui/material";
import { Chip, Stack } from "@mui/material";

export type TocItem<Id extends string = string> = {
  id: Id;
  label: string;
};

type Props<ItemId extends string = string> = StackProps & {
  selected?: string;
  items: ReadonlyArray<TocItem<ItemId>>;
};

export function TableOfContents<ValidItems extends string = string>({
  items,
  selected,
  ...props
}: Props<ValidItems>) {
  const isActive = useCallback((id: string) => selected === id, [selected]);
  const renderItem = useCallback(
    (item: TocItem) => (
      <TableChip
        key={item.id}
        label={item.label}
        to={`#${item.id}`}
        active={isActive(item.id)}
      />
    ),
    [isActive],
  );

  return (
    <Stack alignItems="flex-start" {...props}>
      {items.map(renderItem)}
    </Stack>
  );
}

const TableChip: React.FC<{ to: string; active: boolean; label: string }> = ({
  label,
  to,
  active,
}) => (
  <Chip
    label={label}
    color="primary"
    variant="outlined"
    size="small"
    clickable
    component={Link}
    to={to}
    className={active ? "active" : ""}
    sx={{
      "&.active": { borderColor: "primary" },
      "&:not(.active)": { borderColor: "transparent" },
      fontSize: 14,
    }}
  />
);

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Base2, Base2Info as Info } from "components/setting/common/forms";

const MODULE_NAME = "HoursAndShifts";

type TitleProps = { name: string };
type Props = TitleProps & {
  periodLengthWeeks: number;
  readOnly?: boolean;
};

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande hel- och deltidsmått för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

export function Base2Form({ name, readOnly, periodLengthWeeks }: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title name={name} />}
        />
        <Base2
          periodLengthWeeks={periodLengthWeeks}
          disabled={disabled}
          readOnly={readOnly}
          who={name}
        />
      </Stack>
    </SettingBox>
  );
}

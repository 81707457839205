import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { FeatureFlagProvider } from "contexts/FeatureFlagContext";
import { PageQueryProvider } from "contexts/PageQueryContext";
import { PermissionProvider } from "contexts/PermissionContext";

import ErrorScreen from "components/layout/ErrorScreen";
import FullPageLoading from "components/loading/FullPageLoading";
import PageContainer from "components/PageContainer";
import { RelayEnvironmentProvider } from "components/RelayProvider";
import { SnackbarProvider } from "components/Snackbar";
import ThemeProvider from "components/ThemeProvider";

import Routes from "./routes";

const App = () => (
  <BrowserRouter>
    <RelayEnvironmentProvider>
      <ThemeProvider>
        <Sentry.ErrorBoundary fallback={ErrorScreen}>
          <SnackbarProvider>
            <PageQueryProvider>
              <FeatureFlagProvider>
                <PermissionProvider>
                  <PageContainer>
                    <Suspense fallback={<FullPageLoading />}>
                      <Routes />
                    </Suspense>
                  </PageContainer>
                </PermissionProvider>
              </FeatureFlagProvider>
            </PageQueryProvider>
          </SnackbarProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </RelayEnvironmentProvider>
  </BrowserRouter>
);

export default App;

import { CompetenceDemandSettings } from "../../components/setting/common/forms/CompetenceDemand/CompetenceDemandSettings";

type Props = {
  groupName: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export function CompetenceDemandForm({ groupName, disabled, readOnly }: Props) {
  return (
    <CompetenceDemandSettings
      name="competenceDemandSettings"
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

import { useCallback, useMemo } from "react";
import { TextField } from "@mui/material";
import type { TextFieldProps } from "@mui/material/TextField";

import { MultiSelect } from "./MultiSelect";
import { SingleSelect } from "./SingleSelect";
import type {
  GenericRenderInputParams,
  MultiProps,
  Props,
  SingleProps,
} from "./types";

export function GenericSelect<V = any>({
  multiple,
  hideLabel = false,
  label: labelProp,
  insert,
  options: optionsProp,
  error,
  helperText,
  placeholder = "Välj...",
  size = "small",
  ...props
}: Props<V>) {
  const label = useMemo(
    () => (hideLabel ? "" : labelProp),
    [hideLabel, labelProp],
  );

  const options = useMemo(
    () => [
      ...(insert?.before ?? []),
      ...(optionsProp ?? []),
      ...(insert?.after ?? []),
    ],
    [optionsProp, insert],
  );

  const renderInput = useCallback(
    (params: GenericRenderInputParams) => (
      <TextField
        {...(params as TextFieldProps)}
        label={label}
        helperText={helperText}
        error={error}
        sx={{ bgcolor: "white", borderRadius: 1 }}
      />
    ),
    [label, error, helperText],
  );

  if (multiple) {
    return (
      <MultiSelect<V>
        renderInput={renderInput}
        {...(props as MultiProps<V>)}
        options={options}
        placeholder={placeholder}
        size={size}
      />
    );
  }
  return (
    <SingleSelect<V>
      renderInput={renderInput}
      {...(props as SingleProps<V>)}
      options={options}
      placeholder={placeholder}
      size={size}
    />
  );
}

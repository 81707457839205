import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { FormikModulesBridgeSelect } from "components/common/selectors/ModulesBridgeSelect";

import { SwitchWithTitleAndDesc } from "./common";

export function ConstraintModuleBridgeField({
  readOnly,
}: {
  readOnly?: boolean;
}) {
  const constraintName = "BridgeWithPreviousSchedule";
  const { value: constraintModules, setValue: setConstraintModules } =
    useFormikState<string[]>("constraintModules");
  const active = useMemo(
    () => (constraintModules || []).includes(constraintName),
    [constraintModules],
  );

  function toggleModule() {
    let newList = constraintModules.slice();
    if (active) {
      newList = newList.filter((item) => item !== constraintName);
    } else {
      newList.push(constraintName);
    }
    setConstraintModules(newList);
  }

  return (
    <SwitchWithTitleAndDesc
      checked={active}
      setChecked={toggleModule}
      title="Tillämpa schemaregler i skarven mellan perioder"
      description={
        <Stack gap={1}>
          <Typography>
            Här nedan kan du ange vilka regelmoduler som ska tillämpas i skarven
            mellan det nya schemat och det fastställda schemat i den föregående
            perioden.
          </Typography>
          <FormikModulesBridgeSelect
            name="constraintModulesBridge"
            disabled={!active}
            readOnly={readOnly}
          />
        </Stack>
      }
      readOnly={readOnly}
    />
  );
}

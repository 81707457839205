import { FormControl, InputLabel } from "@mui/material";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";

export default function StyledInputField(props: OutlinedInputProps) {
  return (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel>{props.label}</InputLabel>
      <OutlinedInput {...props} />
    </FormControl>
  );
}

import useFormikState from "hooks/useFormikState";

import { BigSwitch, SmallSwitch } from "components/common/Switch";

type CommonProps = {
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
  styleVariant?: string;
};

type Props = CommonProps & {
  checked: boolean;
  onChange: (newValue: string[]) => void;
  value: string[];
};

type FormikProps = CommonProps & {
  constraintModulesName?: string;
};

function ConstraintSwitch({
  name,
  onChange,
  value,
  readOnly,
  styleVariant,
  ...props
}: Props) {
  function handleChange() {
    let newValue = [...(value || [])];
    const checked = newValue.includes(name);
    if (checked) {
      newValue = newValue.filter((item) => item !== name);
    } else {
      newValue.push(name);
    }
    onChange(newValue);
  }
  if (styleVariant === "SmallSwitch") {
    return (
      <SmallSwitch
        {...props}
        name={name}
        onChange={readOnly ? () => {} : handleChange}
        readOnly={readOnly}
      />
    );
  } else {
    return (
      <BigSwitch
        {...props}
        name={name}
        onChange={readOnly ? () => {} : handleChange}
        readOnly={readOnly}
      />
    );
  }
}

export function FormikConstraintSwitch({
  name,
  constraintModulesName = "constraintModules",
  styleVariant = "BigSwitch",
  ...props
}: FormikProps) {
  const { value: constraintModules = [], setValue: setConstraintModules } =
    useFormikState(constraintModulesName);
  const checked = constraintModules.includes(name);

  function handleChange() {
    let newValue = [...constraintModules];
    const checked = newValue.includes(name);
    if (checked) {
      newValue = newValue.filter((item) => item !== name);
    } else {
      newValue.push(name);
    }
    setConstraintModules(newValue);
  }

  return (
    <ConstraintSwitch
      name={name}
      checked={checked}
      value={constraintModules}
      onChange={handleChange}
      styleVariant={styleVariant}
      {...props}
    />
  );
}

import { menuItems as ruleGroupItems } from "components/setting/rule_group/MenuItems";
import { menuItems as groupItems } from "components/setting/team_group/MenuItems";
import type { SelectedType } from "components/setting/types";
import { menuItems as userItems } from "components/setting/user/MenuItems";

export function getSelectedMenuItems(selectedType: SelectedType) {
  switch (selectedType) {
    case "group":
      return groupItems;
    case "ruleGroup":
      return ruleGroupItems;
    case "person":
      return userItems;
    default:
      return [];
  }
}

type Obj = Record<string | number, any> | any[] | any;

export function snakeToCamel(obj: Obj): Obj {
  if (Array.isArray(obj)) {
    return obj.map(snakeToCamel);
  } else if (typeof obj === "object") {
    const camelObj: Obj = {};
    Object.keys(obj).forEach((key) => {
      const camelKey = key.replace(/(_\w)/g, (k) => k[1].toUpperCase());
      let val = obj[key];
      camelObj[camelKey] = snakeToCamel(val);
    });
    return camelObj;
  }

  return obj;
}

import type { PropsWithChildren } from "react";
import CloseIcon from "@mui/icons-material/Close";
import type { CSSObject } from "@mui/material";
import {
  Box,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

type Props = {
  title?: string;
  onClose?: () => void;
  sx?: CSSObject;
};

export default function DialogTitle({
  title,
  children,
  onClose,
  sx = {},
}: PropsWithChildren<Props>) {
  return (
    <MuiDialogTitle variant="h2" sx={sx}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {!!title && <Typography variant="h2">{title}</Typography>}
        {!!children && <Box>{children}</Box>}
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Stack>
    </MuiDialogTitle>
  );
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { EditCompetenceMutation as Mutation } from "./types";

const mutation = graphql`
  mutation EditCompetenceMutation($input: EditCompetenceInput!) {
    editCompetence(input: $input) {
      competence {
        ...EditCompetenceForm_fragment
      }
    }
  }
`;

export const useEditCompetence = () => useMutation<Mutation>(mutation);

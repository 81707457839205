import { Skeleton, SxProps } from "@mui/material";

type LoadingSelectorProps = {
  width?: number;
  height?: number;
  sx?: SxProps;
};

export function LoadingSelector({
  width = 200,
  height = 38.56,
  sx,
}: LoadingSelectorProps) {
  return (
    <Skeleton
      animation="wave"
      variant="rounded"
      width={width}
      height={height}
      sx={{ my: 0, backgroundColor: "ture.15", ...sx }}
    />
  );
}

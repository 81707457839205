import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { FieldArray } from "formik";
import { formatDateTime } from "helpers/dateFunctions";
import useFormikState from "hooks/useFormikState";
import { toDatetimeLocal } from "libs/toDatetimeLocal";
import type { FormikFieldArrayFn } from "types/formik";

import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FloatingAccordion";
import CustomField from "components/forms/CustomField";

import {
  ActivityPartFormValues,
  activityParts,
  ActivityPartTypeType,
  TranslateActivityPartType,
} from "../../types";

import { ActivityPartSelect } from "./ActivityPartSelect";

type Props = {
  name: string;
};

type PartProps = {
  name: string;
  onRemove: () => void;
};

const options = activityParts.map((ap: ActivityPartTypeType) => ({
  value: ap,
  label: TranslateActivityPartType(ap as ActivityPartTypeType),
}));

export default function ActivityPartForm({ name, onRemove }: PartProps) {
  return (
    <Stack direction="row" gap={2} alignItems="flex-end">
      <ActivityPartSelect
        name={`${name}.partType`}
        label="Deltyp"
        options={options}
        sx={{ width: 230 }}
      />
      <CustomField
        type="datetime-local"
        name={`${name}.start`}
        label="Starttid"
      />
      <CustomField type="datetime-local" name={`${name}.end`} label="Sluttid" />
      <IconButton onClick={onRemove} size="small">
        <DeleteIcon />
      </IconButton>
    </Stack>
  );
}

export function ActivityPartsForm({ name }: Props) {
  const { value, setValue } = useFormikState(name);
  const { value: activityEnd } = useFormikState<string>("end");
  const { value: activityStart } = useFormikState<string>("start");
  const count = value?.length || 0;

  function addPart() {
    const start = toDatetimeLocal(
      formatDateTime(
        new Date(value?.length ? value[value.length - 1].end : activityStart),
      ),
    );
    const newPart = {
      start,
      end: activityEnd,
      partType: "B" as ActivityPartTypeType,
    };
    setValue([...(value || []), newPart]);
  }

  return (
    <Accordion>
      <AccordionSummary sx={{ pl: 2 }}>
        <Stack direction="row" gap={0.5}>
          <Typography color="text">
            {count} {`passdel${count !== 1 ? "ar" : ""}`}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2} pt={2}>
          <FieldArray name={name}>
            {({ remove }: FormikFieldArrayFn<ActivityPartFormValues>) =>
              value.map((_: any, index: number) => (
                <ActivityPartForm
                  key={index}
                  name={`${name}.${index}`}
                  onRemove={() => remove(index)}
                />
              ))
            }
          </FieldArray>
        </Stack>
      </AccordionDetails>
      <AccordionActions>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={addPart}>
          Ny passdel
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

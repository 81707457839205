/**
 * @generated SignedSource<<eeff73326caf79f2915a81fdfacd9339>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamSelect2_fragment$data = ReadonlyArray<{
  readonly color: string;
  readonly group: {
    readonly id: string;
    readonly name: string;
  };
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "TeamSelect2_fragment";
}>;
export type TeamSelect2_fragment$key = ReadonlyArray<{
  readonly " $data"?: TeamSelect2_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamSelect2_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TeamSelect2_fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamGroupNode",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "TeamNode",
  "abstractKey": null
};
})();

(node as any).hash = "c787697811c4eec57e0c9d20b52e7fc8";

export default node;

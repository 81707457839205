import type { ApiMeetingSelectDaysByChoices } from "./__generated__/EditMeetingForm_meeting.graphql";

export * from "./__generated__/CreateMeetingForm_setting.graphql";
export type {
  EditMeetingForm_meeting$data,
  EditMeetingForm_meeting$key,
} from "./__generated__/EditMeetingForm_meeting.graphql";
export * from "./__generated__/EditMeetingForm_setting.graphql";
export * from "./__generated__/MeetingGraph_setting.graphql";
export * from "./__generated__/MeetingGraph_shifts.graphql";
export * from "./__generated__/MeetingList_meetings.graphql";
export * from "./form/types";
export * from "./mutations/types";

export interface StartEndDate {
  start: Date;
  end: Date;
}

export enum meetingSelectEnum {
  FLEXIBLE = "FLEXIBLE",
  STATIC = "STATIC",
}

export const meetingSelectChoices: ApiMeetingSelectDaysByChoices[] =
  Object.values(meetingSelectEnum);

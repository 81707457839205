import { useWeeks } from "components/demand/form";

import {
  CardWithIndices,
  Chips,
  useIndicesFormikField,
} from "./CardWithIndices";

type Props = Omit<React.ComponentProps<typeof CardWithIndices>, "chips"> & {
  checked: number[];
  setChecked: (idx: number[]) => void;
  weekCount: number;
};

type WeekChipsProps = Omit<React.ComponentProps<typeof Chips>, "options"> & {
  weekCount: number;
};

type FormikProps = Omit<Props, "checked" | "setChecked"> & {
  name: string;
};

function WeekChips({ weekCount, ...props }: WeekChipsProps) {
  const weeks = useWeeks(weekCount);
  return <Chips {...props} options={weeks} />;
}

function WeekCard({ checked, weekCount, setChecked, ...props }: Props) {
  return (
    <CardWithIndices
      {...props}
      chips={
        <WeekChips
          checked={checked}
          setChecked={setChecked}
          weekCount={weekCount}
        />
      }
    />
  );
}

export function FormikWeekCard({ name, ...props }: FormikProps) {
  const { checked, setChecked } = useIndicesFormikField(name);
  return (
    <WeekCard {...props} checked={checked || []} setChecked={setChecked} />
  );
}

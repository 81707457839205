/**
 * @generated SignedSource<<4db1b8fd72a9c6e9a38edb89366459fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTeamInput = {
  clientMutationId?: string | null;
  color: string;
  groupId: string;
  name: string;
};
export type CreateTeamMutation$variables = {
  input: CreateTeamInput;
};
export type CreateTeamMutation$data = {
  readonly createTeam: {
    readonly team: {
      readonly archivedAt: string | null;
      readonly color: string;
      readonly group: {
        readonly id: string;
        readonly name: string;
      };
      readonly id: string;
      readonly isActive: boolean;
      readonly name: string;
    } | null;
  } | null;
};
export type CreateTeamMutation = {
  response: CreateTeamMutation$data;
  variables: CreateTeamMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateTeamPayload",
    "kind": "LinkedField",
    "name": "createTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTeamMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTeamMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "f3697758314b6829616cb9579b09e351",
    "id": null,
    "metadata": {},
    "name": "CreateTeamMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTeamMutation(\n  $input: CreateTeamInput!\n) {\n  createTeam(input: $input) {\n    team {\n      id\n      name\n      color\n      isActive\n      archivedAt\n      group {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc7472683cc40e1748a3ac98888c6ad8";

export default node;

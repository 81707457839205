import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { updateSettingsMutation as Mutation } from "./__generated__/updateSettingsMutation.graphql";

const mutation = graphql`
  mutation updateUserSettingsMutation($input: UpdateUserSettingInput!) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        ...HoursAndShiftsSettingUserSetting_fragment
        ...ConsecutiveWorkSettingUserSetting_fragment
        ...ConsecutiveRestSettingUserSetting_fragment
        ...DayNightFairnessSettingUserSetting_fragment
        ...DayNightDistributionSettingUserSetting_fragment
        ...DayNightCadenceSettingUserSetting_fragment
        ...WeekRestSettingUserSetting_fragment
        ...WeekendRestSettingUserSetting_fragment
        ...ShiftDistributionSettingUserSetting_fragment
        ...ResourceTimeSettingUserSetting_fragment
        ...ResponsibilityTimeSettingUserSetting_fragment
        ...NightShiftSettingUserSetting_fragment
        ...WorkDayCadenceSettingUserSetting_fragment
      }
    }
  }
`;

export const useUpdateUserSettingsMutation = () =>
  useMutation<Mutation>(mutation);

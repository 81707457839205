import { useState } from "react";
import {
  Close as CloseIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Typography as Text } from "@mui/material";

import { GROUP_BY_MODULE } from "./constants";
import DrawerFooter from "./DrawerFooter";
import DrawerHeader from "./DrawerHeader";
import type { Warnings } from "./types";
import { WarningsList } from "./WarningsList";

type DrawerContentsProps = {
  onClose: () => void;
  warnings: Warnings;
  refresh: () => void;
  fetching: boolean;
};

export function DrawerContents({
  onClose,
  warnings,
  refresh,
  fetching,
}: DrawerContentsProps) {
  const [displayIdx, setDisplayIdx] = useState(GROUP_BY_MODULE);
  return (
    <>
      <DrawerHeader>
        <Stack direction="row" alignItems="center">
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Text variant="h4">Utvärdera schemaregler</Text>
        </Stack>
        <IconButton onClick={refresh} disabled={fetching}>
          <RefreshIcon />
        </IconButton>
      </DrawerHeader>

      <Stack flexGrow={2} p={2} gap={2}>
        <DrawerFooter idx={displayIdx} setIdx={setDisplayIdx} width={220} />
        <WarningsList warnings={warnings} idx={displayIdx} />
      </Stack>
    </>
  );
}

import { Box, Grid, Typography } from "@mui/material";
import { ErrorMessage } from "formik";

import CalculatedField from "../../components/CalculatedField";

import DayShiftDistributionSum from "./DayShiftDistributionSum";
import PercentInputField from "./PercentInputField";
import useShiftNumberDistribution from "./useShiftNumberDistribution";

type Props = {
  isActive: boolean;
  totalShifts: number;
  disabled?: boolean;
  readOnly?: boolean;
  allowedMargin: number;
};

type Percentage = {
  id: string;
  shifts: number;
};

const getRangeWithMargin = (
  percentages: Percentage[],
  id: string,
  margin: number,
  totalShifts: number,
): string => {
  const { shifts = 0 } = percentages.find((p) => p.id === id) || {};
  const effectiveMargin = Math.min(margin || 1000, totalShifts); // Set 1000 as default margin if margin is 0

  if (effectiveMargin === -1) {
    return `${shifts}`;
  }

  const minValue = Math.max(shifts - effectiveMargin, 0);
  const maxValue = Math.min(shifts + effectiveMargin, totalShifts);

  return `${minValue}-${maxValue}`;
};

const ShiftRow = ({
  label,
  shiftId,
  isActive,
  percentages,
  allowedMargin,
  disabled,
  readOnly,
  totalShifts,
}: {
  label: string;
  shiftId: string;
  isActive: boolean;
  percentages: any[];
  allowedMargin: number;
  disabled?: boolean;
  readOnly?: boolean;
  totalShifts: number;
}) => (
  <Grid item container columns={10} spacing={0} alignItems="center">
    <Grid item xs={1}>
      <Typography variant="h4">{label}</Typography>
    </Grid>
    <Grid item xs={3}>
      <PercentInputField
        name={`${shiftId}ShiftDistributionShare`}
        disabled={disabled}
        readOnly={readOnly}
      />
    </Grid>
    {isActive && (
      <>
        <Grid item xs={3}>
          <CalculatedField
            disabled={disabled}
            value={
              (percentages.find((i) => i.id === shiftId)?.shifts ?? 0) + " pass"
            }
          />
        </Grid>
        <Grid item xs={3}>
          <CalculatedField
            disabled={disabled}
            value={getRangeWithMargin(
              percentages,
              shiftId,
              allowedMargin,
              Math.round(totalShifts),
            )}
          />
        </Grid>
      </>
    )}
  </Grid>
);

export default function ShareInput({
  isActive,
  totalShifts,
  readOnly,
  disabled,
  allowedMargin,
}: Props) {
  const percentages = useShiftNumberDistribution(totalShifts);

  const shifts = [
    { label: "Dag", id: "day" },
    { label: "Kväll", id: "evening" },
    { label: "Natt", id: "night" },
    { label: "Dygns", id: "fullDay" },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {shifts.map(({ label, id }) => (
          <ShiftRow
            key={id}
            label={label}
            shiftId={id}
            isActive={isActive}
            percentages={percentages}
            allowedMargin={allowedMargin}
            readOnly={readOnly}
            disabled={disabled}
            totalShifts={totalShifts}
          />
        ))}
        {isActive && (
          <Grid item container columns={10} spacing={0} alignItems="center">
            <Grid item xs={1}>
              <Typography variant="h4">Totalt</Typography>
            </Grid>
            <Grid item xs={3}>
              <CalculatedField
                disabled={disabled}
                value={DayShiftDistributionSum()}
                errorMessage={
                  DayShiftDistributionSum() === 100 ? "" : "Måste vara 100"
                }
                name={"totalPercentage"}
              />
            </Grid>
            <Grid item xs={3}>
              <CalculatedField
                disabled={disabled}
                value={Math.round(totalShifts) + " pass"}
              />
            </Grid>
            <Grid item xs={3}>
              <CalculatedField
                disabled={disabled}
                value={Math.round(totalShifts) + " pass"}
              />
            </Grid>
          </Grid>
        )}
        {isActive && (
          <Grid item container spacing={0} alignItems="center">
            <Grid item xs={3}>
              <ErrorMessage name="id">
                {(msg) => (
                  <Typography variant="caption" color="error">
                    {msg}
                  </Typography>
                )}
              </ErrorMessage>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

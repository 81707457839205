import { useField } from "formik";

import { TeamSelect } from "./TeamSelect";
import type { FormikProps } from "./types";

type V = string | string[] | null;

export function FormikTeamSelect({ name, ...props }: FormikProps) {
  const [, meta, helpers] = useField<V>(name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <TeamSelect
      {...props}
      value={value}
      onChange={(v: V) => {
        setValue(v);
      }}
    />
  );
}

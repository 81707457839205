import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import Field from "components/forms/CustomField";
import { WeekdaySelect } from "components/setting/common/components/WeekdaySelect";

type Props = {
  disabled?: any;
  readOnly?: boolean;
};

export function FreeWeekendStartEndField({ disabled, readOnly }: Props) {
  const { value: weekendStartsAtDay, setValue: setWeekendStartsAtDay } =
    useFormikState("weekendStartsAtDay");
  const { value: weekendEndsAtDay, setValue: setWeekendEndsAtDay } =
    useFormikState("weekendEndsAtDay");

  return (
    <Stack gap={2}>
      <Typography variant="h4">Lediga helgens start och slut</Typography>
      <Stack direction="row" rowGap={2} columnGap={4} flexWrap="wrap">
        <Stack gap={1}>
          <Typography>Den lediga helgen startar på</Typography>
          <Stack direction="row" gap={1}>
            <WeekdaySelect
              readOnly={readOnly}
              onChange={(_e: any, v: any) =>
                setWeekendStartsAtDay(v?.props.value)
              }
              value={weekendStartsAtDay}
              options={[4, 5]}
              disabled={disabled}
            />
            <Field
              type="time"
              name="weekendStartsAt"
              validate={(value) => (value ? undefined : "Fyll i tid")}
              disabled={disabled}
              InputProps={{ readOnly }}
              sx={{ minWidth: 100 }}
            />
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Typography>Den lediga helgen slutar på</Typography>
          <Stack direction="row" gap={1}>
            <WeekdaySelect
              readOnly={readOnly}
              onChange={(_e: any, v: any) =>
                setWeekendEndsAtDay(v?.props.value)
              }
              value={weekendEndsAtDay}
              options={[6, 0]}
              disabled={disabled}
            />
            <Field
              type="time"
              name="weekendEndsAt"
              validate={(value) => (value ? undefined : "Fyll i tid")}
              disabled={disabled}
              InputProps={{ readOnly }}
              sx={{ minWidth: 100 }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { useState } from "react";
import { Button, Stack, Unstable_Grid2 as Grid } from "@mui/material";
import { Weekday } from "rrule";

import {
  RadioOption,
  SelectAB,
} from "components/blocked_time_slot/common/Recurrence/common";
import type {
  MonthdayType,
  RecurrenceRuleType,
} from "components/blocked_time_slot/common/Recurrence/types";
import {
  days,
  translateWeekday,
} from "components/blocked_time_slot/common/Recurrence/types";
import { nthOptions } from "components/blocked_time_slot/common/Recurrence/utils/functions";

const EACH = "each";
const ON_THE_NTH_DAY_OF_MONTH = "on-the-nth-day-of-month";

const weekdayOptions = days.map((day) => ({
  value: day,
  label: translateWeekday(day, true),
}));

const monthDays: MonthdayType[] = Array(31)
  .fill(0)
  .map((_, i) => (i + 1) as MonthdayType);

type Props = {
  value: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
  disabled?: boolean;
};

function EachMonthdaySelect({ value, onChange, disabled }: Props) {
  const { bymonthday = [] } = value ?? {};
  function isSelected(monthDay: MonthdayType) {
    return bymonthday?.includes(monthDay);
  }

  function toggleMonthDay(monthDay: MonthdayType) {
    const newValue = { ...value };
    if (!newValue.bymonthday) newValue.bymonthday = [];

    if (isSelected(monthDay)) {
      newValue.bymonthday = newValue.bymonthday.filter((m) => m !== monthDay);
    } else {
      newValue.bymonthday = [...(newValue.bymonthday ?? []), monthDay];
    }
    onChange(newValue);
  }
  return (
    <Grid container columns={7} width={445} gap={0.5}>
      {monthDays.map((monthDay) => (
        <Grid key={monthDay} xs={1}>
          <Button
            variant={
              isSelected(monthDay as MonthdayType) ? "primary" : "outlined"
            }
            onClick={() => toggleMonthDay(monthDay)}
            disabled={disabled}
          >
            {monthDay}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

function OnTheNthDayOfMonthSelect({ value, onChange, disabled }: Props) {
  const { bysetpos, byweekday } = value ?? {};
  const a = nthOptions.find((nth) => nth.value === bysetpos)?.value || "";
  const b =
    weekdayOptions.find((day) => day.value === byweekday?.[0])?.value || "";
  const aOptions = nthOptions.map((x) => x.value);
  const bOptions = weekdayOptions.map((x) => x.value);

  function onNthChange(newpos: number) {
    onChange({ ...value, bysetpos: newpos });
  }
  function onWeekdayChange(newByweekday: Weekday) {
    onChange({ ...value, byweekday: [newByweekday] });
  }

  return (
    <SelectAB
      a={a}
      b={b}
      renderA={(a) => nthOptions.find((nth) => nth.value === a)?.label}
      renderB={(b) => weekdayOptions.find((day) => day.value === b)?.label}
      aOptions={aOptions}
      bOptions={bOptions}
      onAChange={onNthChange}
      onBChange={onWeekdayChange}
      disabled={disabled}
      asx={{ width: 200 }}
      bsx={{ width: 200 }}
    />
  );
}

export default function MonthlyOptions({ value, onChange, disabled }: Props) {
  const [selected, setSelected] = useState<string>(EACH);

  function handleChange(changes: RecurrenceRuleType) {
    onChange({ ...value, ...changes });
  }

  return (
    <Stack gap={1}>
      <RadioOption
        checked={selected === EACH}
        onChange={() => setSelected(EACH)}
        disabled={disabled}
      >
        <EachMonthdaySelect
          value={value}
          onChange={handleChange}
          disabled={disabled || selected !== EACH}
        />
      </RadioOption>
      <RadioOption
        checked={selected === ON_THE_NTH_DAY_OF_MONTH}
        onChange={() => setSelected(ON_THE_NTH_DAY_OF_MONTH)}
        disabled={disabled}
      >
        <OnTheNthDayOfMonthSelect
          value={value}
          onChange={handleChange}
          disabled={disabled || selected !== ON_THE_NTH_DAY_OF_MONTH}
        />
      </RadioOption>
    </Stack>
  );
}

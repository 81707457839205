import type { ComponentProps } from "react";
import { useCallback, useMemo } from "react";

import { useAdminCreateUser } from "../mutations";
import type { AdminCreateUserInput as FormValues } from "../types";

import { UserForm } from "./common";

type Props = Pick<
  ComponentProps<typeof UserForm>,
  "open" | "onClose" | "teamGroups"
> & {
  initialValues?: Partial<FormValues>;
};

export function AdminCreateUserForm({
  open,
  onClose,
  teamGroups,
  initialValues: initVals = {},
}: Props) {
  const [commit] = useAdminCreateUser();
  const title = "Skapa ny användare";
  const initialValues: FormValues = useMemo<FormValues>(
    () => ({
      teamGroups: [],
      teams: [],
      firstName: "",
      lastName: "",
      email: "",
      employmentDegree: 100,
      ruleGroups: [],
      employmentForm: "p",
      employmentTitle: "a",
      competences: [],
      timebankBaseBalance: 0,
      invite: false,
      ...initVals,
    }),
    [initVals],
  );

  const onSubmit = useCallback(
    async (input: FormValues) =>
      commit({ variables: { input } })
        .then(onClose)
        .catch((error) => console.error(error)),
    [commit, onClose],
  );

  return (
    <UserForm<FormValues>
      open={open}
      onClose={onClose}
      title={title}
      teamGroups={teamGroups}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isCreate
      isAdminForm
    />
  );
}

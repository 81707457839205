import { useMemo } from "react";
import { Typography } from "@mui/material";
import { useBooleanState } from "utils/useBooleanState";

import { InfoButton, InfoModal } from "components/layout";

type Props = {
  name: string;
  InfoComponent?: React.ComponentType;
};

export function SettingHelpButton({ name, InfoComponent }: Props) {
  const {
    value: isOpen,
    setTrue: openModal,
    setFalse: closeModal,
  } = useBooleanState();

  const title = useMemo(
    () => (
      <Typography variant="h3">
        Hur fungerar <b>{name}</b>?
      </Typography>
    ),
    [name],
  );

  const InfoContent = useMemo(
    () => (InfoComponent ? InfoComponent : FallbackInfoText),
    [InfoComponent],
  );

  return (
    <>
      <InfoButton onClick={openModal}>{`Vad är ${name}?`}</InfoButton>
      <InfoModal title={title} open={isOpen} onClose={closeModal}>
        <InfoContent />
      </InfoModal>
    </>
  );
}

function FallbackInfoText() {
  return <Typography>Här kommer fyllas på med förklarande text.</Typography>;
}

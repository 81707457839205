import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray } from "relay-help/arrays";
import { NightShiftsOldForm } from "settings/NightShift/NightShiftsOldForm";
import { toCamelCase } from "utils/object";

import { Visible } from "components/common/Visible";
import { FormikMock } from "components/setting/common";
import type { Shift } from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/useGraphData";
import {
  AB1Form,
  AB2Form,
  Base2Form,
  Free1Form,
  Rhythm2Form,
  Rhythm3Form,
  Types3Form,
  Types5Form,
  Types6Form,
  Types7Form,
  Worktime1Form,
  Worktime2Form,
} from "components/setting/user/setting_boxes/forms";

import type {
  UserSettings_fragment$key as Key,
  UserSettingsFormValues,
} from "./types";

const fragment = graphql`
  fragment UserSettings_fragment on UserSettingSnapshotNode {
    id
    settingModules
    hoursPerWeek
    shiftsPerWeek
    dayBreakAlternatives
    weekBreakHourAlternatives
    weekBreakDaysAlternatives
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
    maxConsecutiveFreeDays
    maxConsecutiveFreeDaysSoft
    minConsecutiveFreeDays
    minConsecutiveFreeDaysSoft
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendSoft
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    includeInDayTypeWeekendFairness
    shiftDayTypeDistributionSoft
    shiftDayTypeAllowedErrorMargin
    dayShiftDistributionShare
    eveningShiftDistributionShare
    nightShiftDistributionShare
    fullDayShiftDistributionShare
    nightShiftRestriction
    nightShiftRotation
    adminHoursMin
    adminHoursMax
    resourceHoursMin
    resourceHoursMax
    prohibitedResourceWeekDays
    dayTypeCadenceRestrictions
    workDayCadenceRestrictions
    user {
      name
    }
    prohibitedShiftTypes {
      edges {
        node {
          id
        }
      }
    }
  }
`;

type Props = {
  fragmentRef: Key;
  ruleId: string;
  periodLengthWeeks: number;
  shiftsPerWeek: number;
  shifts: ReadonlyArray<Shift>;
};

type GroupAdvancedProps = Pick<Props, "ruleId">;

function GroupAdvanced({ ruleId }: GroupAdvancedProps) {
  return (
    <>
      <Visible visible={ruleId === "advanced-6"}>
        <NightShiftsOldForm readOnly />
      </Visible>
    </>
  );
}

export function UserSettings({
  fragmentRef,
  ruleId,
  periodLengthWeeks,
  shiftsPerWeek,
  shifts,
}: Props) {
  const teamGroup = useCurrentTeamGroup();
  const groupName = teamGroup?.name || "";
  const { user, ...settings } = useFragment<Key>(fragment, fragmentRef);
  const { name } = user || {};
  const initialValues: UserSettingsFormValues = useMemo(
    () => ({
      ...settings,
      currentTeamGroupId: teamGroup?.id ?? "",
      constraintModules: settings.settingModules,
      dayShiftDistributionShare: settings.dayShiftDistributionShare * 100,
      eveningShiftDistributionShare:
        settings.eveningShiftDistributionShare * 100,
      nightShiftDistributionShare: settings.nightShiftDistributionShare * 100,
      fullDayShiftDistributionShare:
        settings.fullDayShiftDistributionShare * 100,
      prohibitedShiftTypes: connectionToArray(
        settings.prohibitedShiftTypes,
      ).map((s) => s.id),
      cadenceRestrictions: JSON.parse(
        settings?.dayTypeCadenceRestrictions ?? "[]",
      ).map(toCamelCase),
      workDayCadenceRestrictions: JSON.parse(
        settings?.workDayCadenceRestrictions ?? "[]",
      ).map(toCamelCase),
    }),
    [settings, teamGroup?.id],
  );

  const totalShifts = Math.round(periodLengthWeeks * shiftsPerWeek);

  return (
    <FormikMock initialValues={initialValues}>
      <Visible visible={ruleId === "ab-0"}>
        <AB1Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "ab-1"}>
        <AB2Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "d-1"}>
        <Base2Form
          readOnly
          name={`för ${name}`}
          periodLengthWeeks={periodLengthWeeks}
        />
      </Visible>
      <Visible visible={ruleId === "w-0"}>
        <Free1Form readOnly name={name} periodLengthWeeks={periodLengthWeeks} />
      </Visible>
      <Visible visible={ruleId === "r-1"}>
        <Rhythm2Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "r-2"}>
        <Rhythm3Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "s-2"}>
        <Types3Form readOnly name={name} shiftTypes={shifts} />
      </Visible>
      <Visible visible={ruleId === "n-0"}>
        <Types5Form
          readOnly
          name={name}
          groupName={groupName}
          totalShifts={totalShifts}
        />
      </Visible>
      <Visible visible={ruleId === "n-1"}>
        <Types6Form readOnly name={name} groupName={groupName} />
      </Visible>
      <Visible visible={ruleId === "n-2"}>
        <Types7Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "t-0"}>
        <Worktime1Form readOnly name={name} />
      </Visible>
      <Visible visible={ruleId === "t-1"}>
        <Worktime2Form readOnly name={name} />
      </Visible>
      <GroupAdvanced ruleId={ruleId} />
    </FormikMock>
  );
}

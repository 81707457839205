import { Permission, UserPermission } from "pages/Admin/AdminPermissions";

import axiosInstance from "./axiosInstance";

export const getSchedule = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/schedule`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching schedule:", error);
    throw error;
  }
};

// permissions

export const listPermissions = async (): Promise<Permission[]> => {
  try {
    const response = await axiosInstance.get(`/permissions/list-permissions`);
    return response.data;
  } catch (error) {
    console.error("Error fetching permissions:", error);
    throw error;
  }
};

export const listUserPermissions = async (
  userID: string,
): Promise<UserPermission[]> => {
  try {
    const response = await axiosInstance.get(
      `/permissions/list-user-permissions`,
      {
        params: { userID },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user permissions:", error);
    throw error;
  }
};

export const updateUserPermissions = async (
  userID: string,
  updatedPermissions: UserPermission[] | undefined,
): Promise<void> => {
  if (!updatedPermissions || updatedPermissions.length === 0) {
    throw new Error("No permissions to update.");
  }

  const payload = {
    permissions: updatedPermissions.map((perm) => ({
      userID,
      permissionName: perm.permission_name,
      permission: perm.permission.toUpperCase(),
    })),
  };

  try {
    const response = await axiosInstance.post(
      `/permissions/upsertBatch`,
      payload,
    );
    return response.data;
  } catch (error) {
    console.error("Error upserting permission:", error);
    throw error;
  }
};

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import type { SelectorStoreUpdater } from "relay-runtime";

import { DeleteCompetenceMutation as Mutation } from "./types";

const mutation = graphql`
  mutation DeleteCompetenceMutation($id: ID!) {
    deleteCompetence(input: { id: $id }) {
      ok
      deletedId @deleteRecord
    }
  }
`;

const updater: SelectorStoreUpdater<Mutation["response"]> = (store) => {
  // with the @deleteRecord directive, the record is deleted from the store and be null in the list
  const root = store.getRoot();
  const records = root.getLinkedRecords("allCompetences");
  if (records) {
    // remove null records
    root.setLinkedRecords(records.filter(Boolean), "allCompetences");
  }
};

export const useDeleteCompetence = () =>
  useMutation<Mutation>(mutation, { updater });

/**
 * @generated SignedSource<<6d18b340485b315054a97b9cf913499f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandProfileChart_fragment$data = {
  readonly viewer: ReadonlyArray<{
    readonly competence: {
      readonly color: string;
      readonly id: string;
      readonly name: string;
    } | null;
    readonly data: ReadonlyArray<{
      readonly demand: number;
      readonly dt: string;
      readonly dutyShare: number | null;
    }>;
    readonly team: {
      readonly color: string;
      readonly id: string;
      readonly name: string;
    };
  }>;
  readonly " $fragmentType": "DemandProfileChart_fragment";
};
export type DemandProfileChart_fragment$key = {
  readonly " $data"?: DemandProfileChart_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandProfileChart_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "color",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandProfileChart_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandProfileNode",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamNode",
          "kind": "LinkedField",
          "name": "team",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceNode",
          "kind": "LinkedField",
          "name": "competence",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DemandProfileEventNode",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "demand",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dutyShare",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DemandProfileViewer",
  "abstractKey": null
};
})();

(node as any).hash = "68dc865cfe4b50b78c3018c70b838e71";

export default node;

import { useCallback } from "react";
import { useFragment } from "react-relay";
import { KeyType, KeyTypeData } from "react-relay/relay-hooks/helpers";
import { Chip, Divider, Stack } from "@mui/material";
import { TeamGroupSettingsQuery$data as TeamGroupData } from "pages/Settings/__generated__/TeamGroupSettingsQuery.graphql";
import { TeamGroupUsers } from "pages/types";
import { ConstraintModuleSwitch_fragment$key as ConstraintModuleSwitchKey } from "settings/common/__generated__/ConstraintModuleSwitch_fragment.graphql";
import {
  OptimizationSetting,
  TeamGroupOnly,
  UserOnly,
} from "settings/common/optimizationSetting";

import {
  useRuleGroupsWithException,
  useUsersWithException,
} from "../team_group/setting_boxes/utils";

import { SettingBoxNew } from "./SettingBoxNew";
import {
  RuleGroupSettingExceptions,
  UserSettingExceptions,
} from "./SettingExceptions";
import { SettingViewHeader } from "./SettingViewHeader";

type Props<
  TeamGroupKey extends KeyType,
  UserSettingsKey extends KeyType | undefined,
  RuleGroupKey extends KeyType | undefined,
  FormValues,
  AddionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues,
> = {
  optimizationSetting: OptimizationSetting<
    TeamGroupKey,
    UserSettingsKey,
    RuleGroupKey,
    FormValues,
    AddionalData,
    TeamGroupSettingFormValues,
    UserSettingFormValues
  >;
  teamGroupFragmentRef: TeamGroupKey;
  teamGroupMembers: TeamGroupUsers;
  constraintModulesRef: ConstraintModuleSwitchKey;
  ruleGroups: TeamGroupData["ruleGroups"];
};

export function SettingView<
  TeamGroupKey extends KeyType,
  UserSettingsKey extends KeyType | undefined,
  RuleGroupKey extends KeyType | undefined,
  FormValues,
  AddionalData = {},
  TeamGroupSettingFormValues = undefined,
  UserSettingFormValues = undefined,
>({
  optimizationSetting,
  teamGroupFragmentRef,
  teamGroupMembers,
  constraintModulesRef,
  ruleGroups,
}: Props<
  TeamGroupKey,
  UserSettingsKey,
  RuleGroupKey,
  FormValues,
  AddionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues
>) {
  const teamGroupData = useFragment(
    optimizationSetting.teamGroupFragment,
    teamGroupFragmentRef,
  );

  const additionalData =
    optimizationSetting.useAdditionalDataForTeamGroup(teamGroupFragmentRef);

  const { onSubmitTeamGroup, onSubmitUser, onSubmitRuleGroup } =
    optimizationSetting.useSubmitFunctions();

  const renderUserSetting = useCallback(
    (exception: KeyTypeData<NonNullable<UserSettingsKey>>) => {
      return (
        <>
          {optimizationSetting.renderComponent(
            optimizationSetting.convertUserDataToInitialValues!(exception),
            onSubmitUser,
            additionalData,
          )}
          {optimizationSetting.userOnly && (
            <UserOnlySettingView
              userSettingData={exception}
              userSettingMetadata={optimizationSetting.userOnly}
            />
          )}
        </>
      );
    },
    [optimizationSetting, onSubmitUser, additionalData],
  );

  const renderRuleGroupSetting = useCallback(
    (exception: KeyTypeData<NonNullable<RuleGroupKey>>) => {
      if (optimizationSetting.ruleGroups == null) {
        return null;
      }
      return optimizationSetting.renderComponent(
        optimizationSetting.ruleGroups.convertDataToInitialValues(exception),
        onSubmitRuleGroup,
        additionalData,
      );
    },
    [optimizationSetting, onSubmitRuleGroup, additionalData],
  );

  const userExceptions = useUsersWithException(
    teamGroupMembers,
    optimizationSetting.moduleName,
  );

  const ruleGroupExceptions = useRuleGroupsWithException(
    ruleGroups,
    optimizationSetting.moduleName,
  );

  return (
    <Stack
      gap={2}
      sx={{
        p: {
          //this need to be fine tuned further
          xs: 0, // padding for extra-small screens
          sm: 0, // padding for small screens
          md: 0, // padding for medium screens
          lg: 3, // padding for large screens
          xl: 4, // padding for extra-large screens
        },
      }}
    >
      <SettingViewHeader
        name={optimizationSetting.name}
        InfoComponent={optimizationSetting.InfoComponent}
      />
      <SettingBoxNew
        settingModule={optimizationSetting.moduleName}
        fragmentRef={constraintModulesRef}
        settingName={optimizationSetting.name}
      >
        {optimizationSetting.renderComponent(
          optimizationSetting.convertTeamGroupDataToInitialValues(
            teamGroupData,
          ),
          onSubmitTeamGroup,
          additionalData,
        )}
        {optimizationSetting.teamGroupOnly && (
          <TeamGroupOnlySettingView
            teamGroupData={teamGroupData}
            teamGroupMetadata={optimizationSetting.teamGroupOnly}
            additionalData={additionalData}
          />
        )}
      </SettingBoxNew>

      {(optimizationSetting.userSettingFragment ||
        optimizationSetting.ruleGroups) && (
        <Divider>
          <Chip label="Undantag" variant="outlined" size="small" />
        </Divider>
      )}
      {optimizationSetting.ruleGroups && (
        <RuleGroupSettingExceptions
          exceptions={ruleGroupExceptions}
          renderSettingComponent={renderRuleGroupSetting}
          ruleGroupFragment={optimizationSetting.ruleGroups.ruleGroupFragment}
          settingName={optimizationSetting.name}
          settingModule={optimizationSetting.moduleName}
          ruleGroupFragmentRef={ruleGroups}
        />
      )}
      {optimizationSetting.userSettingFragment && (
        <UserSettingExceptions
          exceptions={userExceptions}
          renderSettingComponent={renderUserSetting}
          userFragment={optimizationSetting.userSettingFragment}
          settingName={optimizationSetting.name}
          settingModule={optimizationSetting.moduleName}
          userFragmentRef={teamGroupMembers}
        />
      )}
    </Stack>
  );
}

type TeamGroupOnlySettingViewProps<
  TeamGroupKey extends KeyType,
  TeamGroupSettingsFormValues,
  AdditionalData,
> = {
  teamGroupData: KeyTypeData<TeamGroupKey>;
  teamGroupMetadata: TeamGroupOnly<
    TeamGroupKey,
    TeamGroupSettingsFormValues,
    AdditionalData
  >;
  additionalData: AdditionalData;
};

function TeamGroupOnlySettingView<
  TeamGroupKey extends KeyType,
  TeamGroupSettingsFormValues,
  AdditionalData,
>({
  teamGroupData,
  teamGroupMetadata,
  additionalData,
}: TeamGroupOnlySettingViewProps<
  TeamGroupKey,
  TeamGroupSettingsFormValues,
  AdditionalData
>) {
  const onSubmit = teamGroupMetadata.useSubmitTeamGroupOnly();
  return teamGroupMetadata.renderComponent(
    teamGroupMetadata.convertTeamGroupDataToInitialValues(teamGroupData),
    onSubmit,
    additionalData,
  );
}

type UserOnlySettingViewProps<
  UserSettingKey extends KeyType,
  UserSettingsFormValues,
> = {
  userSettingData: KeyTypeData<UserSettingKey>;
  userSettingMetadata: UserOnly<UserSettingKey, UserSettingsFormValues>;
};

function UserOnlySettingView<
  UserSettingKey extends KeyType,
  UserSettingsFormValues,
>({
  userSettingData,
  userSettingMetadata,
}: UserOnlySettingViewProps<UserSettingKey, UserSettingsFormValues>) {
  const onSubmit = userSettingMetadata.useSubmitUserOnly();
  return userSettingMetadata.renderComponent(
    userSettingMetadata.convertUserDataToInitialValues(userSettingData),
    onSubmit,
  );
}

import type { ComponentProps } from "react";
import { Edit as EditIcon } from "@mui/icons-material";

import { IconButtonWithText } from "./IconButtonWithText";

type ButtonProps = Omit<ComponentProps<typeof IconButtonWithText>, "icon">;

export function EditButton(p: ButtonProps) {
  return <IconButtonWithText icon={<EditIcon />} text="Redigera" {...p} />;
}

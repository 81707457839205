import { CircularProgress, Paper } from "@mui/material";

export function EditMePlaceholder() {
  return (
    <Paper sx={{ p: 4 }}>
      <CircularProgress />
    </Paper>
  );
}
export function ChangePasswordPlaceholder() {
  return (
    <Paper sx={{ p: 4 }}>
      <CircularProgress />
    </Paper>
  );
}

import { PropsWithChildren, useMemo } from "react";
import { Box, Divider, Stack } from "@mui/material";

import { Fail as ErrorIcon, Warning as WarningIcon } from "components/icons";

import { Warning } from "./types";

type Props = {
  warning: Warning;
};

export function WarningListEntry({
  warning,
  children,
}: PropsWithChildren<Props>) {
  const icon = useMemo(() => {
    return warning.hardError ? (
      <ErrorIcon sx={{ color: "error.main" }} />
    ) : (
      <WarningIcon sx={{ color: "warning.main" }} />
    );
  }, [warning]);

  return (
    <Box display="flex" marginY={2}>
      <Stack display="flex" alignItems="center" paddingRight={2} gap={1}>
        {icon}
        <Box flexGrow={1}>
          <Divider
            orientation="vertical"
            sx={{
              borderRightWidth: 2,
            }}
          />
        </Box>
      </Stack>
      {children}
    </Box>
  );
}

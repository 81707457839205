/**
 * @generated SignedSource<<5470817aa6f11b64cc5194c8d0baf095>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SuggestMeetingShiftsInput = {
  clientMutationId?: string | null;
  dayMatrix: ReadonlyArray<ReadonlyArray<number>>;
  end: string;
  id?: string | null;
  name: string;
  refreshRelatedMeetingShifts?: boolean | null;
  start: string;
  teamGroupId: string;
  users: ReadonlyArray<string>;
};
export type SuggestMeetingShiftsMutation$variables = {
  input: SuggestMeetingShiftsInput;
};
export type SuggestMeetingShiftsMutation$data = {
  readonly suggestMeetingShifts: {
    readonly ok: boolean;
    readonly shifts: ReadonlyArray<{
      readonly breakTime: number;
      readonly end: string;
      readonly id: string | null;
      readonly meetingDay: number;
      readonly name: string;
      readonly otherMeetings: ReadonlyArray<{
        readonly end: string;
        readonly id: string;
        readonly start: string;
      }> | null;
      readonly shiftParts: ReadonlyArray<{
        readonly end: string;
        readonly id: string | null;
        readonly partType: string;
        readonly start: string;
      }>;
      readonly start: string;
    }>;
  } | null;
};
export type SuggestMeetingShiftsMutation = {
  response: SuggestMeetingShiftsMutation$data;
  variables: SuggestMeetingShiftsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SuggestMeetingShiftsPayload",
    "kind": "LinkedField",
    "name": "suggestMeetingShifts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SuggestedMeetingShift",
        "kind": "LinkedField",
        "name": "shifts",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "meetingDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SuggestedMeetingShiftPart",
            "kind": "LinkedField",
            "name": "shiftParts",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "partType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingNode",
            "kind": "LinkedField",
            "name": "otherMeetings",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SuggestMeetingShiftsMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SuggestMeetingShiftsMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "137040da6ba23c1719ee2b09cccb70c0",
    "id": null,
    "metadata": {},
    "name": "SuggestMeetingShiftsMutation",
    "operationKind": "mutation",
    "text": "mutation SuggestMeetingShiftsMutation(\n  $input: SuggestMeetingShiftsInput!\n) {\n  suggestMeetingShifts(input: $input) {\n    ok\n    shifts {\n      id\n      name\n      start\n      end\n      meetingDay\n      breakTime\n      shiftParts {\n        id\n        start\n        end\n        partType\n      }\n      otherMeetings {\n        id\n        start\n        end\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3280c3135f5b3b2d05f3dd57597c2b09";

export default node;

/**
 * @generated SignedSource<<1f292c19a970bde48facea4f099f52a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiScheduleSolutionStatusChoices = "ERROR" | "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "QUEUED" | "RUNNING" | "RUNNING_WITH_INFEASIBILITY_VENTS" | "%future added value";
export type ApiScheduleTerminationConditionChoices = "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "INFEASIBLE_NOW_RERUN" | "MAX_STALL_TIME" | "MAX_TIME_LIMIT" | "OPTIMAL" | "OTHER" | "UNBOUNDED" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InfeasibleResults_fragment$data = {
  readonly id: string;
  readonly solutionStatus: ApiScheduleSolutionStatusChoices;
  readonly terminationCondition: ApiScheduleTerminationConditionChoices | null;
  readonly " $fragmentType": "InfeasibleResults_fragment";
};
export type InfeasibleResults_fragment$key = {
  readonly " $data"?: InfeasibleResults_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InfeasibleResults_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InfeasibleResults_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationCondition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "solutionStatus",
      "storageKey": null
    }
  ],
  "type": "ScheduleNode",
  "abstractKey": null
};

(node as any).hash = "85bf754525962962dbbb4d8ea54fe848";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import type { SelectorStoreUpdater } from "relay-runtime";

import { DeleteMeetingMutation as Mutation } from "./types";

const mutation = graphql`
  mutation DeleteMeetingMutation($input: DeleteMeetingInput!) {
    deleteMeeting(input: $input) {
      ok
      deletedId @deleteRecord
    }
  }
`;

const updater: (
  teamGroupId: string,
) => SelectorStoreUpdater<Mutation["response"]> = (teamGroupId) => (store) => {
  const root = store.getRoot();
  const listKey = "meetings";
  const args = { teamGroupId };
  const records = root.getLinkedRecords(listKey, args);
  if (records) {
    root.setLinkedRecords(records.filter(Boolean), listKey, args);
  }
};

export const useDeleteMeeting = (teamGroupId: string) =>
  useMutation<Mutation>(mutation, {
    updater: (store, res) => updater(teamGroupId)(store, res),
  });

import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import { InfoButtonAndModal, InfoTitle } from "components/layout/InfoModal";

const Title = () => <InfoTitle>Hur fungerar Behovsprofilen?</InfoTitle>;

const Content = ({ group }: { group: string }) => (
  <Stack gap={6}>
    <Stack gap={2}>
      <Typography variant="h3">Grundförutsättningar</Typography>
      <Stack gap={4}>
        <Stack gap={1}>
          <Typography variant="h4">
            Olika bemanningsbehov för olika avdelningar och olika kompetenser
          </Typography>
          <Typography>
            I <Link to={`/demand`}>Behovsprofilen</Link> kan du både ange det
            totala bemanningsbehovet för respektive avdelning och det särskilda
            bemanningsbehovet för respektive kompetens på respektive avdelning.
            Bemanningsbehovet för en kompetens behandlas enligt principen
            "varav", d.v.s. om det totala bemanningsbehovet är 4 och
            bemanningsbehovet för kompetensen <i>undersköterska</i> är 2, då
            tolkar vi detta som att 4 personer behövs, varav 2 ska inneha
            kompetensen.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">Behovsspann slås samman</Typography>
          <Typography>
            Om två behovsspann byter av varandra på samma tidpunkt och har samma
            behov så kommer de automatiskt slås samman till ett behovsspann.
            Exempelvis kommer alltså ett behovsspann mellan 10:00 - 11:00 med
            behov 1 och ett behovsspann mellan 11:00 - 12:00 med behov 1 slås
            samman till ett behovsspann mellan 10:00 - 12:00 med behov 1.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
    <Stack gap={2}>
      <Typography variant="h3">Avancerade inställningar</Typography>
      <Stack gap={4}>
        <Stack gap={1}>
          <Typography variant="h4">Olika behov på olika veckor</Typography>
          <Typography>
            I verksamheter med ett rörligt bemanningsbehov kan det finnas
            anledning att använda olika behovsprofiler på olika veckor. Du kan
            välja att aktivera unika veckor för behovsprofilen på sidan{" "}
            <Link to={`/group-settings#demands`}>
              Enhetsinställningar/Behovsprofil
            </Link>
            .
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">Differentiera vaken/sovande natt</Typography>
          <Typography>
            I verksamheter med olika typer av jour-pass (exempelvis
            LSS-verksamheter) kan det finnas anledning att differentiera ett
            bemanningsbehov på vaken (vanligt nattpass) eller sovande (nattpass
            med en jour-del) Du kan välja att aktivera differentiering av
            vaken/sovande natt för behovsprofilen på sidan{" "}
            <Link to={`/group-settings#demands`}>
              Enhetsinställningar/Behovsprofil
            </Link>
            .
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h4">
            Vissa tidsspann är viktigare än andra
          </Typography>
          <Typography>
            Ibland kan det finnas anledning att ge vissa tidsspann i
            behovsprofilen en tyngre vikt än andra tidsspann. Ett exempel kan
            vara helgen. Ett annat exempel kan vara tiden runt överlappet mellan
            nattpassen och dagpassen. Vissa verksamheter väljer att låta några
            dagpass börja lite tidigare än de andra, exempelvis 06:45 respektive
            07:00. Här kan det finnas anledning att ge behovsspannet 06:45-07:00
            en extra hög prioritet för dels minimerad underbemanning, dels
            minimerad överbemannad spilltid. Du kan välja att aktivera viktade
            behovsspann för behovsprofilen på sidan{" "}
            <Link to={`/group-settings#demands`}>
              Enhetsinställningar/Behovsprofil
            </Link>
            .
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);

export function DemandInfo({ group }: { group: string }) {
  return (
    <InfoButtonAndModal
      title={<Title />}
      buttonLabel="Hur fungerar Behovsprofilen?"
    >
      <Content group={group} />
    </InfoButtonAndModal>
  );
}

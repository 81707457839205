import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { RerunPostprocessingMutation as Mutation } from "./__generated__/RerunPostprocessingMutation.graphql";

const mutation = graphql`
  mutation RerunPostprocessingMutation($scheduleId: ID!) {
    rerunPostprocessing(input: { scheduleId: $scheduleId }) {
      ok
    }
  }
`;

export const useRerunPostprocessing = () => useMutation<Mutation>(mutation);

import { InputAdornment, Stack, Typography } from "@mui/material";

import CustomField from "components/forms/CustomField";

import { DayBreakAlternativeSelect } from "../components/DayBreakAlternativeSelect";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

function AB1A({ disabled, readOnly = false }: Props) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Dygnsvilans längd (timmar)</Typography>
      <Typography>
        Detta fält styr hur lång dygnsvilan minst ska vara, uttryckt i antal
        timmar.
      </Typography>
      <CustomField
        fullWidth
        size="small"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">timmar</InputAdornment>,
          readOnly,
        }}
        name="minDailyRestAbHours"
        disabled={disabled}
      />
    </Stack>
  );
}

export function AB1B({ disabled, readOnly = false }: Props) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Dygnsbryt</Typography>
      <Typography>Detta fält styr när dygnsbrytet ska infalla.</Typography>
      <DayBreakAlternativeSelect
        name="dayBreakAlternatives"
        disabled={disabled}
        readOnly={readOnly}
      />
    </Stack>
  );
}

export function AB1({ disabled, readOnly = false }: Props) {
  return (
    <Stack gap={5}>
      <AB1A disabled={disabled} readOnly={readOnly} />
      <AB1B disabled={disabled} readOnly={readOnly} />
    </Stack>
  );
}

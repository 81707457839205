import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertTeamGroupDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctionsOnlyTeamGroup,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { minDailyRestWeightChoices } from "components/setting/team_group/setting_boxes/constants";

import {
  RestBetweenShiftsSettingTeamGroup_fragment$data as TeamGroupData,
  RestBetweenShiftsSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/RestBetweenShiftsSettingTeamGroup_fragment.graphql";
import { RestBetweenShifts } from "./form/RestBetweenShiftsForm";

const teamGroupFragment = graphql`
  fragment RestBetweenShiftsSettingTeamGroup_fragment on SettingNode {
    id
    minDailyRestSoft
    minDailyRestHours
    minDailyRestWeight
    constraintModules
  }
`;

const SETTING_NAME = "Vila mellan pass";
const MODULE_NAME = "DailyRest";
const SETTING_URL_ID = "rest-between-shifts";

type RestBetweenShiftsFormValues = Pick<
  TeamGroupData,
  | "id"
  | "minDailyRestSoft"
  | "minDailyRestHours"
  | "minDailyRestWeight"
  | "constraintModules"
>;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()),
  minDailyRestHours: yup
    .number()
    .min(0, "Måste vara minst 0")
    .max(24, "Måste vara högst 24")
    .required("Får ej vara tomt"),
  minDailyRestSoft: yup.boolean(),
  minDailyRestWeight: yup.string().oneOf(minDailyRestWeightChoices),
});

function renderComponent(
  initialValues: RestBetweenShiftsFormValues,
  onSubmit: (formValues: RestBetweenShiftsFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <RestBetweenShifts />
    </BaseSettings>
  );
}

export const restBetweenShiftsSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  RestBetweenShiftsFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctionsOnlyTeamGroup,
  renderComponent,
};

import { PropsWithChildren } from "react";
import { FormControlLabel, Radio, Stack } from "@mui/material";

type Props = {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
};

export default function RadioOption({
  checked,
  onChange,
  disabled,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Stack>
      <FormControlLabel
        checked={checked}
        onChange={onChange}
        control={<Radio />}
        label="Varje:"
        disabled={disabled}
      />
      {children}
    </Stack>
  );
}

/**
 * @generated SignedSource<<37cfd4178121785d5678e933211b6e20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApiActivityActivityTypeChoices = "A" | "C" | "D" | "F" | "J" | "M" | "O" | "P" | "Q" | "R" | "S" | "V" | "W" | "Y" | "Z" | "%future added value";
export type ApiActivityPartPartTypeChoices = "B" | "J" | "M" | "P" | "Z" | "%future added value";
export type ValidateCreateActivityInput = {
  activityParts?: ReadonlyArray<ActivityPartInput | null> | null;
  activityType: ApiActivityActivityTypeChoices;
  breakTime: number;
  clientMutationId?: string | null;
  end: string;
  scheduleId: string;
  start: string;
  teamId?: string | null;
  userId: string;
};
export type ActivityPartInput = {
  end: string;
  id?: string | null;
  partType: ApiActivityPartPartTypeChoices;
  start: string;
};
export type ValidateCreateActivityMutation$variables = {
  input: ValidateCreateActivityInput;
};
export type ValidateCreateActivityMutation$data = {
  readonly validateCreateActivity: {
    readonly failedToFetch: boolean | null;
    readonly results: {
      readonly errors: ReadonlyArray<{
        readonly activity: {
          readonly activityType: ApiActivityActivityTypeChoices;
          readonly end: string;
          readonly id: string;
          readonly start: string;
        } | null;
        readonly message: string;
        readonly module: string;
        readonly user: {
          readonly name: string;
        } | null;
      }> | null;
      readonly ok: boolean;
    };
  } | null;
};
export type ValidateCreateActivityMutation = {
  response: ValidateCreateActivityMutation$data;
  variables: ValidateCreateActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "failedToFetch",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "module",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ActivityNode",
  "kind": "LinkedField",
  "name": "activity",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "end",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateCreateActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ValidateCreateActivityPayload",
        "kind": "LinkedField",
        "name": "validateCreateActivity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidationResult",
            "kind": "LinkedField",
            "name": "results",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ValidationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserSnapshotNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateCreateActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ValidateCreateActivityPayload",
        "kind": "LinkedField",
        "name": "validateCreateActivity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidationResult",
            "kind": "LinkedField",
            "name": "results",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ValidationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserSnapshotNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bff7d8910ab5c28b64800c7e7f5f8ef8",
    "id": null,
    "metadata": {},
    "name": "ValidateCreateActivityMutation",
    "operationKind": "mutation",
    "text": "mutation ValidateCreateActivityMutation(\n  $input: ValidateCreateActivityInput!\n) {\n  validateCreateActivity(input: $input) {\n    failedToFetch\n    results {\n      ok\n      errors {\n        module\n        message\n        activity {\n          id\n          activityType\n          start\n          end\n        }\n        user {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d98154a664561aa49f071f5ba844db5b";

export default node;

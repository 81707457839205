import { ListItemText, MenuItem } from "@mui/material";

type PanelItemProps = {
  icon: React.ReactNode;
  selected: boolean;
  title: string;
  onClick: () => void;
};

export default function PanelItem({
  icon,
  selected,
  title,
  onClick,
}: PanelItemProps) {
  return (
    <MenuItem onClick={onClick} selected={selected}>
      <ListItemText primary={title} sx={{ textWrap: "wrap" }} />
      {icon}
    </MenuItem>
  );
}

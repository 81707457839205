/**
 * @generated SignedSource<<39eccbae0ffe16d90914ff1defbda418>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingResourceShiftsWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SmoothResourcesSettingTeamGroup_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly id: string;
  readonly optimizeResourceDistributionBetweenEmployees: boolean;
  readonly optimizeResourceDistributionBetweenTeams: boolean;
  readonly optimizeResourceDistributionWithinTeams: boolean;
  readonly resourceShiftsWeight: ApiSettingResourceShiftsWeightChoices;
  readonly " $fragmentType": "SmoothResourcesSettingTeamGroup_fragment";
};
export type SmoothResourcesSettingTeamGroup_fragment$key = {
  readonly " $data"?: SmoothResourcesSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SmoothResourcesSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SmoothResourcesSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionWithinTeams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionBetweenTeams",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optimizeResourceDistributionBetweenEmployees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceShiftsWeight",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "4f298f2ef3a4e1bc265f492b86f86573";

export default node;

/**
 * @generated SignedSource<<253d9fe494a7c81241450ba97821525f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RunSchedulingInput = {
  clientMutationId?: string | null;
  periodId: string;
  saveLog?: boolean | null;
  saveLp?: boolean | null;
  saveMps?: boolean | null;
  timeLimit?: number | null;
};
export type RunScheduleMutation$variables = {
  input: RunSchedulingInput;
};
export type RunScheduleMutation$data = {
  readonly runScheduling: {
    readonly ok: boolean | null;
  } | null;
};
export type RunScheduleMutation = {
  response: RunScheduleMutation$data;
  variables: RunScheduleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RunSchedulingPayload",
    "kind": "LinkedField",
    "name": "runScheduling",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RunScheduleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RunScheduleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "374a3a772c3b27a2e2e5edcb8a4ea300",
    "id": null,
    "metadata": {},
    "name": "RunScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation RunScheduleMutation(\n  $input: RunSchedulingInput!\n) {\n  runScheduling(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e0a33811be71792a5cb87cc63a383be";

export default node;

import React from "react";
import Paper from "@mui/material/Paper";
import { SxProps, Theme } from "@mui/system";

interface PaperProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>; // Optional additional styles
  padding?: number | string; // Optional padding prop for more customization
  mt?: number | string; // Optional margin-top prop for more customization
  borderRadius?: number | string; // Optional border-radius prop for more customization
}

const PaperWrapper: React.FC<PaperProps> = ({
  children,
  sx,
  padding = 2,
  mt = 1,
  borderRadius = "10px",
}) => {
  return (
    <Paper
      sx={{
        padding,
        mt,
        borderRadius,
        ...sx,
      }}
      elevation={0}
    >
      {children}
    </Paper>
  );
};

export default PaperWrapper;

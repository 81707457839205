import { useCallback, useMemo, useState } from "react";
import type { SelectChangeEvent } from "@mui/material";
import type { DurationLike } from "luxon";
import { DateTime, Duration } from "luxon";

import type { SpanType } from "./types";

type LogicProps = {
  start: Date;
  end: Date;
  windowStart: Date;
  windowEnd: Date;
  setStart: (date: Date) => void;
  setEnd: (date: Date) => void;
  setWindowStart: (date: Date) => void;
  setWindowEnd: (date: Date) => void;
};
export function useDemandGraphSpanLogic({
  start,
  end,
  windowStart,
  windowEnd,
  setStart,
  setEnd,
  setWindowStart,
  setWindowEnd,
}: LogicProps) {
  const [span, setSpan] = useState<SpanType>("period");
  const minDt = useMemo(() => DateTime.fromJSDate(start), [start]);
  const maxDt = useMemo(() => DateTime.fromJSDate(end), [end]);
  const fromDt = useMemo(() => DateTime.fromJSDate(windowStart), [windowStart]);
  const toDt = useMemo(() => DateTime.fromJSDate(windowEnd), [windowEnd]);

  const getDuration = useCallback(
    (spanVal: SpanType): DurationLike => {
      switch (spanVal) {
        case "week":
          return Duration.fromObject({
            weeks: 1,
          });
        case "2weeks":
          return Duration.fromObject({ weeks: 2 });
        case "period":
          return Duration.fromMillis(maxDt.valueOf() - minDt.valueOf());
        default:
          return 0;
      }
    },
    [minDt, maxDt],
  );

  const backDisabled = useMemo(
    () => minDt > fromDt.minus(getDuration(span)),
    [minDt, fromDt, span, getDuration],
  );
  const forwardDisabled = useMemo(
    () => maxDt < toDt.plus(getDuration(span)),
    [maxDt, toDt, span, getDuration],
  );

  const setFrom = useCallback(
    (dt: DateTime) => {
      setWindowStart(dt.toJSDate());
    },
    [setWindowStart],
  );
  const setTo = useCallback(
    (dt: DateTime) => {
      setWindowEnd(dt.toJSDate());
    },
    [setWindowEnd],
  );

  const goBack = useCallback(() => {
    if (backDisabled) {
      console.warn("'from' value out of range");
      return;
    }
    setFrom(fromDt.minus(getDuration(span)));
    setTo(toDt.minus(getDuration(span)));
  }, [backDisabled, setFrom, setTo, fromDt, toDt, getDuration, span]);

  const goForward = useCallback(() => {
    if (forwardDisabled) {
      console.warn("'to' value out of range");
      return;
    }
    setFrom(fromDt.plus(getDuration(span)));
    setTo(toDt.plus(getDuration(span)));
  }, [forwardDisabled, setFrom, setTo, fromDt, toDt, getDuration, span]);

  const changeSpan = useCallback(
    (e: SelectChangeEvent<SpanType>) => {
      const newSpan = e.target.value as SpanType;
      setSpan(newSpan);
      if (newSpan === "period") {
        setFrom(minDt);
        setTo(maxDt);
      } else {
        setTo(fromDt.plus(getDuration(newSpan)));
      }
    },
    [fromDt, getDuration, minDt, maxDt, setFrom, setTo],
  );

  const changeStart = useCallback(
    (newStart: Date) => {
      /** Changing start till trigger a new query.
       * Thus, we want end to be a period's length ahead. */
      const newStartDt = DateTime.fromJSDate(newStart);
      const newWindowEnd = DateTime.fromJSDate(newStart).plus(
        getDuration(span),
      );
      const newEnd = newStartDt.plus(getDuration("period")).toJSDate();

      setStart(newStart);
      setEnd(newEnd);
      setFrom(newStartDt);
      setTo(newWindowEnd);
    },
    [getDuration, span, setStart, setEnd, setFrom, setTo],
  );

  return useMemo(
    () => ({
      span,
      changeSpan,
      changeStart,
      backDisabled,
      forwardDisabled,
      goForward,
      goBack,
    }),
    [
      span,
      backDisabled,
      forwardDisabled,
      goForward,
      goBack,
      changeSpan,
      changeStart,
    ],
  );
}

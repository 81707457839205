import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import type { RecordProxy, SelectorStoreUpdater } from "relay-runtime";

import { CreateCompetenceMutation as Mutation } from "./__generated__/CreateCompetenceMutation.graphql";

const mutation = graphql`
  mutation CreateCompetenceMutation($input: CreateCompetenceInput!) {
    createCompetence(input: $input) {
      competence {
        id
        name
        description
        color
        isActive
        archivedAt
      }
    }
  }
`;

function update(r: RecordProxy, key: string, newRecord: any) {
  const list = r.getLinkedRecords(key);
  const newList = [...(list || []), newRecord];
  r.setLinkedRecords(newList, key);
}

const updater: SelectorStoreUpdater<Mutation["response"]> = (store, res) => {
  const newCompetence = store
    .getRootField("createCompetence")
    .getLinkedRecord("competence");
  const isActive = res?.createCompetence?.competence?.isActive;
  if (!newCompetence) return;
  const root = store.getRoot();
  update(root, "allCompetences", newCompetence);
  if (isActive) {
    update(root, "competences", newCompetence);
  }
};

export const useCreateCompetence = () =>
  useMutation<Mutation>(mutation, { updater });

import type { Weekday } from "rrule";
import { Frequency, RRule } from "rrule";

export type MonthType = number;

export type MonthdayType = number;

export const days: Weekday[] = [
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR,
  RRule.SA,
  RRule.SU,
];
export const months: MonthType[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

// We don't need hourly, minutely or secondly
export const YEARLY = Frequency.YEARLY;
export const MONTHLY = Frequency.MONTHLY;
export const WEEKLY = Frequency.WEEKLY;
export const DAILY = Frequency.DAILY;

export type RecurrenceRuleType = {
  exclude: boolean;
  isDate: boolean;

  dtstart: Date | null;

  freq: Frequency;
  interval: number;
  bysetpos: number | number[] | null;

  bymonth: MonthType[] | null;
  bymonthday: MonthdayType[] | null;
  byweekday: Weekday[] | null;

  until: Date | null;
  count: number | null;
};

export function translateFrequency(freq: Frequency, isPlural: boolean) {
  if (freq === YEARLY) {
    return "år";
  }
  if (freq === MONTHLY) {
    return isPlural ? "månader" : "månad";
  }
  if (freq === WEEKLY) {
    return isPlural ? "veckor" : "vecka";
  }
  if (freq === DAILY) {
    return isPlural ? "dagar" : "dag";
  }
  return "";
}

export function translateWeekday(day: Weekday, longform = false) {
  let prefix = "";
  switch (day) {
    case RRule.MO:
      prefix = "Mån";
      break;
    case RRule.TU:
      prefix = "Tis";
      break;
    case RRule.WE:
      prefix = "Ons";
      break;
    case RRule.TH:
      prefix = "Tors";
      break;
    case RRule.FR:
      prefix = "Fre";
      break;
    case RRule.SA:
      prefix = "Lör";
      break;
    case RRule.SU:
      prefix = "Sön";
      break;
    default:
      break;
  }
  return `${prefix}${longform ? "dag" : ""}`;
}

export function numberToWeekday(weekday: number): Weekday {
  switch (weekday) {
    case 0:
      return RRule.MO;
    case 1:
      return RRule.TU;
    case 2:
      return RRule.WE;
    case 3:
      return RRule.TH;
    case 4:
      return RRule.FR;
    case 5:
      return RRule.SA;
    case 6:
      return RRule.SU;
    default:
      return RRule.MO;
  }
}

export function numbersToWeekdays(weekdays: number[]): Weekday[] {
  return weekdays.map(numberToWeekday);
}

export function translateMonth(monthNumber: MonthType, longform = false) {
  let ret = "";
  switch (monthNumber) {
    case 1:
      ret = longform ? "Januari" : "Jan";
      break;
    case 2:
      ret = longform ? "Februari" : "Feb";
      break;
    case 3:
      ret = longform ? "Mars" : "Mar";
      break;
    case 4:
      ret = longform ? "April" : "Apr";
      break;
    case 5:
      ret = longform ? "Maj" : "Maj";
      break;
    case 6:
      ret = longform ? "Juni" : "Jun";
      break;
    case 7:
      ret = longform ? "Juli" : "Jul";
      break;
    case 8:
      ret = longform ? "Augusti" : "Aug";
      break;
    case 9:
      ret = longform ? "September" : "Sep";
      break;
    case 10:
      ret = longform ? "Oktober" : "Oct";
      break;
    case 11:
      ret = longform ? "November" : "Nov";
      break;
    case 12:
      ret = longform ? "December" : "Dec";
      break;
    default:
      break;
  }
  return ret;
}

import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const Base5InfoTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Dygnsdefinitionen</b>?
  </InfoTitle>
);

const Base5InfoContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export function Base5Info() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal title={<Base5InfoTitle />}>
        <Base5InfoContent />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

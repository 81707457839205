import { useMemo } from "react";
import { EMPTY_ARRAY } from "utils/constants";

/** Get the element of an array in a Query Response. */
export type Unwrap<T> = T extends ReadonlyArray<infer U> ? U : T;

type TConnection<TNode> = {
  readonly edges: ReadonlyArray<{
    readonly node: TNode | null;
  } | null> | null;
} | null;

export type ExtractNode<T extends { edges: any }> = NonNullable<
  NonNullable<T["edges"][number]>["node"]
>;

export function isNonNull<T extends unknown>(arg: T): arg is NonNullable<T> {
  return arg !== null && arg !== undefined;
}

// transform a relay connection to an array
export function connectionToArray<TNode>(
  connection?: TConnection<TNode>,
): ReadonlyArray<TNode> {
  if (!connection || !connection.edges)
    return EMPTY_ARRAY as ReadonlyArray<TNode>;

  return connection.edges
    .filter(isNonNull)
    .map((edge) => edge.node)
    .filter(isNonNull);
}

// hook to transform a relay connection to an array
export function useConnectionToArray<TNode>(
  connection?: TConnection<TNode>,
): ReadonlyArray<TNode> {
  return useMemo<ReadonlyArray<TNode>>(
    () => connectionToArray<TNode>(connection),
    [connection],
  );
}

export function getId<T extends { id: string }>(o: T): string {
  return o.id;
}

import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const Worktime1InfoTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Ansvarstid</b>?
  </InfoTitle>
);

const Worktime1InfoContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export function Worktime1Info() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal title={<Worktime1InfoTitle />}>
        <Worktime1InfoContent />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

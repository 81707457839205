import { Component } from "react";
import { Stack, Typography } from "@mui/material";

type Props = {
  fallback?: React.ReactNode;
};

type State = {
  error: Error | null;
};

const DEFAULT_FALLBACK =
  "Något har gått fel här. Testa att ladda om sidan! Om problemet kvarstår, kontakta din Konvoj-kontakt.";

export class ErrorBoundary extends Component<
  React.PropsWithChildren<Props>,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo.componentStack);
  }

  render() {
    if (this.state.error) {
      return (
        <Stack gap={2} sx={{ p: 4 }}>
          <Typography variant="h3">Ajdå! Något gick fel.</Typography>
          <Typography>{this.props.fallback ?? DEFAULT_FALLBACK}</Typography>
        </Stack>
      );
    }

    return this.props.children;
  }
}

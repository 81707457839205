import Drawer from "@mui/material/Drawer";

import { DRAWER_WIDTH } from "../SideMenu";

export default function SideMenu() {
  return (
    <Drawer
      open
      variant="permanent"
      anchor="left"
      sx={{ width: DRAWER_WIDTH, height: "100%", bgcolor: "ture.100" }}
    />
  );
}

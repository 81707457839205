import { useFragment } from "react-relay/hooks";
import { Box, Stack, Typography } from "@mui/material";
import { ResponsiveLine, Serie } from "@nivo/line";
import graphql from "babel-plugin-relay/macro";
import { minutes2HHMM, seconds2HHMMSS } from "helpers/dateFunctions";
import { Colors } from "styles/colors";

import TooltipBox from "components/common/TooltipBox";

import { MipLogChart_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment MipLogChart_fragment on ScheduleNode {
    jsonString
    mipgapLimit
  }
`;

type Props = {
  fragmentRef: Key;
};

function xFormat(maxX: number, seconds: number) {
  if (maxX < 60) {
    return Math.round(seconds);
  } else {
    return minutes2HHMM(seconds / 60);
  }
}

function xTitle(maxX: number) {
  if (maxX < 60) {
    return "Förfluten tid (antal sekunder)";
  } else {
    return "Förfluten tid (hh:mm)";
  }
}

function xGrids(maxX: number) {
  if (maxX < 60) {
    return Array.from(Array(30).keys())
      .map((n) => n * (60 / 12))
      .filter((m) => m < maxX);
  } else if (maxX < 60 * 15) {
    return Array.from(Array(30).keys())
      .map((n) => n * 60)
      .filter((m) => m < maxX);
  } else if (maxX < 60 * 30) {
    return Array.from(Array(30).keys())
      .map((n) => (n * 60 * 60) / 12)
      .filter((m) => m < maxX);
  } else if (maxX < 60 * 60 * 2) {
    return Array.from(Array(30).keys())
      .map((n) => (n * 60 * 60) / 6)
      .filter((m) => m < maxX + 30);
  } else if (maxX < 60 * 60 * 5) {
    return Array.from(Array(30).keys())
      .map((n) => (n * 60 * 60) / 2)
      .filter((m) => m < maxX + 60);
  } else {
    return Array.from(Array(40).keys())
      .map((n) => n * 60 * 60)
      .filter((m) => m < maxX + 60 * 2);
  }
}

export function MipLogChart({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  var dataParsed: Serie[] = [];
  if (data.jsonString !== "") {
    dataParsed = JSON.parse(data.jsonString);
  }

  // Ensure no zero value so that we can use a log scale
  const dataConverted = dataParsed.map((v) => ({
    id: v.id,
    data: v.data.map((d) => ({ x: d.x, y: Math.max(Number(d.y), 0.001) })),
  }));

  // Calculate Y max value and Y grid values
  const base = 2;
  const allYArray = dataConverted.map((d) =>
    d.data.map((p) => Number(p.y) || 0),
  );
  const maxY = Math.max(...allYArray.reduce((acc, v) => acc.concat(v), []));
  const gridsY = Array.from(Array(40).keys())
    .map((n) => Math.pow(base, n))
    .filter((n) => n < maxY);

  // Calculate X max value and X grid values
  const allXArray = dataConverted.map((d) =>
    d.data.map((p) => Number(p.x) || 0),
  );
  const maxX = allXArray.length > 0 ? Math.max(...allXArray[0]) : 0;
  const gridsX = xGrids(maxX);

  return (
    <Box sx={{ height: 400 }}>
      <ResponsiveLine
        data={dataConverted}
        colors={[Colors.ANEMON, Colors.BLUE, Colors.BLUE40]}
        margin={{
          top: 25,
          right: 30,
          bottom: 60,
          left: 10 + maxY.toString().length * 6,
        }}
        yFormat=" >-.2f"
        yScale={{
          type: "log",
          base: base,
          max: maxY,
          min: 1,
        }}
        gridYValues={gridsY}
        enableSlices="x"
        theme={{ text: { fontFamily: "Nunito" } }}
        markers={[
          {
            axis: "y",
            value: data.mipgapLimit,
            lineStyle: {
              stroke: `${Colors.ANEMON}`,
              strokeWidth: 2,
              strokeDasharray: "5,2",
            },
          },
        ]}
        xScale={{ type: "linear" }}
        gridXValues={gridsX}
        axisBottom={{
          legend: xTitle(maxX),
          legendPosition: "middle",
          legendOffset: 40,
          tickValues: gridsX,
          format: function (value) {
            return xFormat(maxX, value);
          },
        }}
        sliceTooltip={({ slice }) => {
          return (
            <TooltipBox>
              <Stack gap={0}>
                <Typography pb={1}>
                  Förfluten tid{" "}
                  <strong>
                    {seconds2HHMMSS(
                      Number(slice.points[0].data.xFormatted),
                      false,
                    )}
                  </strong>
                </Typography>
                {slice.points.map((point) => (
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box
                      sx={{
                        backgroundColor: point.serieColor,
                        borderRadius: "100%",
                        height: 10,
                        width: 10,
                      }}
                    />
                    <Typography key={point.id}>
                      {point.serieId} <strong>{point.data.yFormatted}</strong>
                    </Typography>
                  </Stack>
                ))}
                <Stack direction="row" gap={1} alignItems="center">
                  <Box
                    sx={{
                      width: 10,
                      border: `1px dashed ${Colors.ANEMON}`,
                    }}
                  />
                  <Typography key={4}>
                    MipGap-mål <strong>{data.mipgapLimit}</strong>
                  </Typography>
                </Stack>
              </Stack>
            </TooltipBox>
          );
        }}
      />
    </Box>
  );
}

/**
 * @generated SignedSource<<4a9d63e88d502c8974cf2b32fedb2f6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiRuleGroupSettingNightShiftRestrictionChoices = "L0" | "L1" | "L2" | "L3" | "N" | "%future added value";
export type ApiRuleGroupSettingNightShiftRotationChoices = "N" | "R1" | "R2" | "R3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NightShiftSettingRuleGroup_fragment$data = {
  readonly id: string;
  readonly nightShiftRestriction: ApiRuleGroupSettingNightShiftRestrictionChoices;
  readonly nightShiftRotation: ApiRuleGroupSettingNightShiftRotationChoices;
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "NightShiftSettingRuleGroup_fragment";
};
export type NightShiftSettingRuleGroup_fragment$key = {
  readonly " $data"?: NightShiftSettingRuleGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NightShiftSettingRuleGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NightShiftSettingRuleGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRestriction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightShiftRotation",
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "73a54dc1b7766db9930fff8c2e89e6ef";

export default node;

import type { PropsWithChildren } from "react";
import type { CSSObject } from "@mui/material";
import { Typography } from "@mui/material";

type Props = PropsWithChildren<{ sx?: CSSObject }>;

export function Title({ children, sx = {} }: Props) {
  return (
    <Typography variant="h4" sx={sx}>
      {children}
    </Typography>
  );
}

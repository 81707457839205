import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import type { ExtractNode } from "relay-help/arrays";
import { useConnectionToArray } from "relay-help/arrays";

import type {
  ScheduleContext_fragment$data as Data,
  ScheduleContext_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment ScheduleContext_fragment on ScheduleNode {
    competences {
      edges {
        node {
          id
          name
          color
        }
      }
    }
    teams {
      edges {
        node {
          id
          name
          color
        }
      }
    }
    users {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export type Competence = ExtractNode<Data["competences"]>;
export type Team = ExtractNode<Data["teams"]>;
export type User = ExtractNode<Data["users"]>;

type ScheduleContextType = {
  competences: ReadonlyArray<Competence>;
  teams: ReadonlyArray<Team>;
  users: ReadonlyArray<User>;
};

type Props = {
  fragmentRef: Key;
};

const Context = createContext<ScheduleContextType>({
  competences: [],
  teams: [],
  users: [],
});
Context.displayName = "ScheduleContext";

export const useScheduleContext = () => {
  const schedule = useContext(Context);
  if (!schedule) {
    throw new Error("useSchedule must be used within ScheduleProvider");
  }
  return schedule;
};

export const ScheduleProvider = ({
  fragmentRef,
  children,
}: PropsWithChildren<Props>) => {
  const schedule = useFragment<Key>(fragment, fragmentRef);

  const competences = useConnectionToArray(schedule.competences);
  const teams = useConnectionToArray(schedule.teams);
  const users = useConnectionToArray(schedule.users);
  const value = useMemo(
    () => ({ competences, teams, users }),
    [competences, teams, users],
  );

  return <Context.Provider value={value} children={children} />;
};

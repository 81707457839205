import type { PropsWithChildren, ReactNode } from "react";
import type { CSSObject } from "@mui/material";
import { Box, Stack } from "@mui/material";

type Props = {
  header?: ReactNode;
  gap?: number;
  sx?: CSSObject;
};

export function PageWrapper({
  header,
  gap = 4,
  children,
  sx = {},
}: PropsWithChildren<Props>) {
  return (
    <Box sx={{ p: 4, ...sx }}>
      <Stack gap={gap}>
        {header}
        {children}
      </Stack>
    </Box>
  );
}

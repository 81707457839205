import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

import { FormikSwitchWithTitleAndDesc } from "./common";

type Props = { readOnly?: boolean; disabled?: boolean };

export function UseDutyDemandField({ readOnly, disabled }: Props) {
  return (
    <FormikSwitchWithTitleAndDesc
      name="useDutyDemand"
      title="Differentiera behov för vaken/sovande natt"
      description={
        <Typography>
          Med denna inställning påslagen kan du ange hur stor andel av ett
          behovsspann i <Link to={`/demand`}>Behovsprofilen</Link> som ska vara
          sovande jour.
        </Typography>
      }
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

import type { ListItemButtonProps } from "@mui/material";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

type Props = {
  icon: React.ReactNode;
  label: string;
} & ListItemButtonProps;

export const ListItemWithIcon: React.FC<Props> = ({
  icon,
  label,
  ...props
}) => (
  <ListItemButton {...props}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText>{label}</ListItemText>
  </ListItemButton>
);

import type { Dispatch } from "react";
import { useCallback, useMemo } from "react";
import type { QueryParamsReturnType } from "hooks";
import { useQueryParams } from "hooks";

type State = { newRun: boolean };
type Actions = { setNewRun: Dispatch<boolean> };
type PeriodParamsType = State & Actions & QueryParamsReturnType;

export function usePeriodParams() {
  const [state, setState, clear] = useQueryParams<State>((query) => ({
    newRun: query.has("newRun"),
  }));

  const setNewRun = useCallback(
    (value: boolean) => setState("newRun", value),
    [setState],
  );

  return useMemo<PeriodParamsType>(
    () => ({
      ...state,
      setNewRun,
      clear,
    }),
    [state, setNewRun, clear],
  );
}

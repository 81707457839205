import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { useRequireMe } from "hooks";

import { PageHeader } from "components/layout/PageWrapper";

type Props = {
  onAdd: () => void;
  addDisabled?: boolean;
};

export default function PeriodHeader({ onAdd, addDisabled }: Props) {
  const { evaluatePermission } = useRequireMe();
  return (
    <PageHeader>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h2">Schemaoptimeringar</Typography>
        <Tooltip title={addDisabled ? "Körning pågår" : undefined}>
          <span>
            {evaluatePermission("generate-schedule", "WRITE") && (
              <Button
                variant="primary"
                endIcon={<AddIcon />}
                onClick={onAdd}
                disabled={addDisabled}
              >
                Ny schemaoptimering
              </Button>
            )}
          </span>
        </Tooltip>
      </Stack>
    </PageHeader>
  );
}

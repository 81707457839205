import { Stack, TextField, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import { Duration } from "luxon";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

type InputProps = Omit<
  React.ComponentProps<typeof TextField>,
  "value" | "onChange" | "type"
> & {
  name: string;
};

function FullDayDurationInput({ name, ...props }: InputProps) {
  // fullDayDuration is minutes, we want to display it as HH:mm
  const { value, setValue } = useFormikState<number>(name);
  const formattedValue = Duration.fromObject({ minutes: value }).toFormat(
    "hh:mm",
  );

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: newValue } = event.target;
    const [hours, minutes] = newValue.split(":").map(Number);
    setValue(hours * 60 + minutes);
  }

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Typography sx={{ width: 200 }}>Dygnspass är pass längre än</Typography>
      <TextField
        {...props}
        name={name}
        type="time"
        value={formattedValue}
        onChange={onChange}
        size="small"
        sx={{ width: 100 }}
      />
      <Typography>timmar</Typography>
    </Stack>
  );
}

export function FullDayDurationField({ disabled, readOnly }: Props) {
  return (
    <FullDayDurationInput
      name="fullDayDuration"
      disabled={disabled}
      InputProps={{ readOnly }}
    />
  );
}

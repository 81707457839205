import { alpha, styled } from "@mui/material/styles";
import { linearGradient } from "helpers/stylingFunctions";

const purple = "#bb00bb";

export const important = (s: string | number) => `${s} !important`;

const bgColor = (
  color: string,
  isImportant = true,
): Record<"backgroundColor", string> => ({
  backgroundColor: isImportant ? important(color) : color,
});

const bgWithBorder = (color: string) => ({
  background: important(alpha(color, 0.2)),
  borderColor: important(alpha(color, 0.3)),
  borderWidth: important("2px"),
  borderStyle: important("solid"),
});

export const GanttItem = styled("div")(({ theme }) => ({
  border: important("none"),
  borderRadius: 4,
  "&.altered_minus": bgColor(theme.palette.hjarta.main),
  "&.altered_plus": bgColor(theme.palette.svart[70]),
  "&.inconspicuous": bgColor("lightgray"),
  "&.rct-shift-pass-day": bgColor(theme.palette.klippa.main),
  "&.rct-shift-pass-evening": bgColor(theme.palette.manet.main),
  "&.rct-shift-pass-night": bgColor(theme.palette.ture[100]),
  "&.rct-shift-pass-full": bgColor(theme.palette.fisk.main),
  "&.inconspicuous-rest": {
    backgroundColor: important("lightgray"),
    opacity: 0.3,
    borderWidth: important("2px"),
    borderStyle: important("solid"),
    borderColor: important("rgb(187,187,187)"),
  },

  "&.rct-item-s": {
    backgroundColor: important(theme.palette.shift.main),
    zIndex: important(100), // default 80
  },
  "&.rct-item-r": bgColor(theme.palette.resource.main),
  "&.rct-item-r-fixed": {
    background: important(
      linearGradient(
        [theme.palette.kerstin[100], theme.palette.resource.main],
        -45,
      ),
    ),
    zIndex: important(100), // default 80
  },
  "&.rct-item-w": bgWithBorder(theme.palette.kind.main),
  "&.rct-item-y": bgWithBorder(theme.palette.fisk.main),
  "&.rct-item-q": bgWithBorder(theme.palette.svart[70]),
  "&.rct-item-e": {
    background: important("transparent"),
    borderWidth: important("0"),
    borderLeftWidth: important("2px"),
    borderStyle: important("solid"),
    borderColor: important(theme.palette.svart[70]),
    borderRadius: important(0),
    marginTop: -4.5,
    height: important("27px"),
    zIndex: important(30), // default 80
    fontSize: 6,
  },
  "&.rct-item-b": {
    background: important("transparent"),
    borderWidth: important("0"),
    borderLeftWidth: important("1px"),
    borderStyle: important("dashed"),
    borderColor: important(theme.palette.svart[70]),
    borderRadius: important(0),
    marginTop: -4.5,
    height: important("27px"),
    zIndex: important(30), // default 80
  },
  "&.rct-item-c": {
    ...bgWithBorder(theme.palette.svart[70]),
    opacity: 0.4,
  },
  "&.rct-item-f": bgWithBorder(theme.palette.kerstin[100]),
  "&.rct-item-o": bgWithBorder(theme.palette.hav.main),
  "&.rct-item-v": bgWithBorder(theme.palette.hassan[100]),
  "&.rct-item-a": bgWithBorder(purple),
  "&.rct-item-d": {
    opacity: 0.5,
    backgroundImage: important(
      `repeating-linear-gradient(45deg, #ede3ee, #ede3ee 5px, ${purple} 5px, ${purple} 10px)`,
    ),
    borderColor: important(purple),
    borderWidth: important("2px"),
    borderStyle: important("solid"),
  },
  "&.rct-item-z, &.rct-item-part-z": {
    background: important(theme.palette.manet.main),
    borderColor: important(theme.palette.manet.main),
    borderWidth: important("2px"),
    borderStyle: important("solid"),
  },
  "&.rct-item-p, &.rct-item-part-p": {
    background: important(theme.palette.skum.main),
    borderColor: important(theme.palette.skum.main),
    borderWidth: important("2px"),
    borderStyle: important("solid"),
  },
  "&.rct-item-js, &.rct-item-part-js": {
    background: important(alpha(theme.palette.shift.main, 0.7)),
    borderColor: important(theme.palette.shift.main),
    borderWidth: important("2px"),
    borderStyle: important("solid"),
  },
  "&.rct-item-jr, &.rct-item-part-jr": {
    background: important(alpha(theme.palette.resource.main, 0.7)),
    borderColor: important(theme.palette.resource.main),
    borderWidth: important("2px"),
    borderStyle: important("solid"),
  },
  "&.rct-item-ms, &.rct-item-part-ms": {
    background: important(alpha(theme.palette.shift.main, 0.7)),
    borderColor: important(theme.palette.shift.main),
    borderWidth: important("2px"),
    borderStyle: important("solid"),
  },
  "&.rct-item-mr, &.rct-item-part-mr": {
    background: important(alpha(theme.palette.resource.main, 0.7)),
    borderColor: important(theme.palette.resource.main),
    borderWidth: important("2px"),
    borderStyle: important("solid"),
  },
  "&.rct-item-prev": {
    opacity: 0.5,
  },
}));

import { useMemo } from "react";
import type { Writeable } from "types";
import type { PriorityWeight } from "types/Demand";
import { SHORT_DAYS } from "utils/day";

import type { CreateDemandsInput as FormValues } from "../types";

import type { DemandForm_fragment$data as FormDemandData } from "./types";
import { getSelectedDemandGroup } from "./utils";

const dayIndices = SHORT_DAYS.map((_, i) => i);

export function useDaysLeft(formValues: FormValues) {
  const selectedDays = useMemo(
    () =>
      new Set(
        formValues.demands
          .flatMap((d) => d?.demands || [])
          .flatMap((d) => d.days),
      ),
    [formValues.demands],
  );
  return useMemo(
    () => dayIndices.filter((i) => !selectedDays.has(i)),
    [selectedDays],
  );
}

export const useWeeks = (count: number) =>
  useMemo(
    () => Array.from({ length: count }).map((_, i) => `Vecka ${i + 1}`),
    [count],
  );

export const useDemandFormInitialValues = (
  teamId: string,
  competenceId: string | null,
  formDemands: FormDemandData,
) =>
  useMemo<Writeable<FormValues>>(() => {
    const selectedFormWeeks = getSelectedDemandGroup(
      formDemands,
      teamId,
      competenceId,
    );

    return {
      teamId: teamId,
      competenceId: competenceId || "",
      demands: selectedFormWeeks.map((w) => ({
        weeks: [...w.weeks],
        demands: w.demands.map((g) => ({
          days: [...g.days],
          demands: g.demands.map((d) => ({
            start: d.start,
            end: d.end,
            demand: d.demand,
            dutyShare: d.dutyShare,
            enforceDuty: d.enforceDuty,
            minimizeUnderstaffingWeight:
              d.minimizeUnderstaffingWeight as PriorityWeight,
            minimizeOverstaffingWeight:
              d.minimizeOverstaffingWeight as PriorityWeight,
          })),
        })),
      })),
    };
  }, [teamId, competenceId, formDemands]);

import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { FormikConstraintSwitch as ConstraintSwitch } from "../components/ConstraintSwitch";

type Props = {
  name: string;
  constraintModulesName?: string;
  readOnly?: boolean;
};

export function HeaderSwitch({
  name,
  readOnly,
  constraintModulesName = "constraintModules",
}: Props) {
  const { value: constraintModules = [] } = useFormikState<string[]>(
    constraintModulesName,
  );
  const checked = constraintModules.includes(name);

  return (
    <Stack gap={0.4} direction="row" flexGrow={1} alignItems="center">
      <ConstraintSwitch
        name={name}
        constraintModulesName={constraintModulesName}
        readOnly={readOnly}
      />
      <Typography>{checked ? "På" : "Av"}</Typography>
    </Stack>
  );
}

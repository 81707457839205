import { SettingBox } from "components/setting/common";
import { VacationField } from "components/setting/common/forms/Advanced";

type Props = { groupId: string; groupName: string; readOnly?: boolean };

export function VacationForm({ groupId, groupName, readOnly }: Props) {
  return (
    <SettingBox>
      <VacationField
        groupId={groupId}
        groupName={groupName}
        readOnly={readOnly}
      />
    </SettingBox>
  );
}

import {
  EmailOutlined,
  LabelOutlined,
  LayersOutlined,
  MeetingRoomOutlined,
  PasswordOutlined,
  Percent,
  PersonOutlined,
  StarOutline,
} from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { useField } from "formik";

import { FormikCompetenceSelect } from "components/common/selectors/CompetenceSelect/FormikCompetenceSelect";
import { RuleGroupSelect } from "components/common/selectors/RuleGroupSelect/RuleGroupSelect";
import { FormikTeamSelect as TeamSelect } from "components/common/selectors/TeamSelect";
import { FormikPasswordField } from "components/forms/PasswordField";
import { MemberTeamGroupType } from "components/organisation/types";

import EmploymentDegreeInput from "./EmploymentDegreeInput";
import { FormSection, FormSectionDouble } from "./FormSelection";
import { TeamGroupSelect } from "./TeamGroupSelect";

type Props = {
  isAdminForm: boolean;
  expanded: boolean;
  onChange: () => void;
  isCreate?: boolean;
  teamGroups: MemberTeamGroupType[];
};

export function BasicUserInput({ isAdminForm, isCreate, teamGroups }: Props) {
  const [field, meta] = useField("firstName");
  return (
    <Stack gap={1} p={3}>
      <Divider component="div" role="presentation">
        <Typography p={1} variant="h4">
          Användarinformation
        </Typography>
      </Divider>
      <FormSectionDouble
        icon={<PersonOutlined />}
        label="Namn"
        name="firstName"
        label2="Efternamn"
        name2="lastName"
        error={meta.touched && Boolean(meta.error)}
      />
      {isAdminForm && (
        <>
          <FormSection
            icon={<EmailOutlined />}
            label="E-postadress"
            name="email"
            disabled={!isCreate}
          />
          <FormSection
            icon={<PasswordOutlined />}
            label="Lösenord"
            name="password"
            component={
              <FormikPasswordField name="password" label="Lösenord" fullWidth />
            }
          />
        </>
      )}
      <FormSection
        icon={<MeetingRoomOutlined />}
        label="Enheter"
        name="teamGroups"
        component={
          <TeamGroupSelect
            name="teamGroups"
            teamGroups={teamGroups}
            disableCloseOnSelect
          />
        }
      />
      <FormSection
        icon={<LayersOutlined />}
        label="Avdelningar"
        name="teams"
        component={
          <TeamSelect multiple name="teams" groupByGroup disableCloseOnSelect />
        }
      />
      <FormSection
        icon={<Percent />}
        label="Tjänstgöringsgrad"
        name="employmentDegree"
        component={<EmploymentDegreeInput name="employmentDegree" />}
      />
      <Divider component="div" role="presentation">
        <Typography p={2} variant="h4">
          Övrig information
        </Typography>
      </Divider>
      {!isAdminForm && (
        <FormSection
          icon={<LabelOutlined />}
          label="Regelgrupp"
          name="ruleGroup"
          component={<RuleGroupSelect name="ruleGroup" label="Regelgrupp" />}
        />
      )}
      <FormSection
        icon={<StarOutline />}
        label="Kompetenser"
        name="competences"
        component={
          <FormikCompetenceSelect
            name="competences"
            multiple
            disableCloseOnSelect
          />
        }
      />
    </Stack>
  );
}

import { useEffect, useRef } from "react";

type Section = { isIntersecting: boolean; target: { id: string } };

/** Hook to detect which component IDs are visible on screen. */
export function useIntersectionObserver(
  elementIds: string[],
  setActive: (id: string) => void,
) {
  const sectionElementsRef = useRef<Record<string, Section>>({});

  useEffect(() => {
    const cb = (elements: Section[]) => {
      sectionElementsRef.current = elements.reduce(
        (acc, el) => ({ ...acc, [el.target.id]: el }),
        sectionElementsRef.current,
      );

      const visibleSections: Section[] = [];
      Object.keys(sectionElementsRef.current).forEach((k) => {
        const el = sectionElementsRef.current[k];
        if (el.isIntersecting) visibleSections.push(el);
      });

      if (visibleSections.length === 1) {
        setActive(visibleSections[0].target.id);
      } else if (visibleSections.length > 1) {
        const sorted = visibleSections.sort(getSortSectionFn(elementIds));
        setActive(sorted[0].target.id);
      }
    };

    const observer = new IntersectionObserver(cb, {
      rootMargin: "0px 0px -40% 0px",
    });
    elementIds.forEach((id) => {
      const el = document.getElementById(id);
      if (el) {
        observer.observe(el);
      }
    });

    return () => observer.disconnect();
  }, [setActive, elementIds]);
}

/** Returns a sort function for `elements` */
const getSortSectionFn =
  (elements: string[]) =>
  ({ target: { id: a } }: Section, { target: { id: b } }: Section) =>
    elements.findIndex((i) => i === a) - elements.findIndex((i) => i === b);

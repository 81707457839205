import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { FormikDayChips } from "components/common/DayCard";

import { resourceTimeSetting } from "../ResourceTimeSetting";

type Props = {
  readOnly?: boolean;
};

export function ResourceTimeUserOnlyForm({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    resourceTimeSetting.moduleName,
  );

  return (
    <PaperWrapper>
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography variant="h4">
            Begränsa resurspass på givna dagar
          </Typography>
          <Typography>
            De veckodagar du väljer kommer inte få några resurspass tilldelade.
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <FormikDayChips
            name="prohibitedResourceWeekDays"
            readOnly={readOnly}
            disabled={disabled}
          />
        </Stack>
      </Stack>
    </PaperWrapper>
  );
}

import type { SelectChangeEvent } from "@mui/material";
import { MenuItem, Select } from "@mui/material";

import { GROUP_BY_MODULE, GROUP_BY_USER, NO_GROUPING } from "./constants";

type Props = {
  width?: number;
  idx: number;
  setIdx: (idx: number) => void;
};

export default function DrawerFooter({ idx, setIdx, width }: Props) {
  const renderValue = (value: number) => {
    switch (value) {
      case NO_GROUPING:
        return "Visa alla";
      case GROUP_BY_USER:
        return "Gruppera på användare";
      case GROUP_BY_MODULE:
        return "Gruppera på modul";
      default:
        return "Visa alla";
    }
  };
  const onChange = (event: SelectChangeEvent<number>) => {
    setIdx(event.target.value as number);
  };
  return (
    <Select
      value={idx}
      onChange={onChange}
      renderValue={renderValue}
      size="small"
      fullWidth
      sx={{ width }}
    >
      <MenuItem value={-1}>{renderValue(-1)}</MenuItem>
      <MenuItem value={0}>{renderValue(0)}</MenuItem>
      <MenuItem value={1}>{renderValue(1)}</MenuItem>
    </Select>
  );
}

import type { Dispatch } from "react";
import { useCallback, useMemo } from "react";
import type { QueryParamsReturnType } from "hooks";
import { useQueryParams } from "hooks";

type State = { new: boolean; team: string; user: string };
type Actions = {
  setNew: Dispatch<boolean>;
  setTeam: Dispatch<string>;
  setUser: Dispatch<string>;
};
type ParamsType = State & Actions & QueryParamsReturnType;

export function useBlockedTimeSlotParams() {
  const [state, setState, clear] = useQueryParams<State>((query) => ({
    new: query.has("new"),
    team: query.get("team") ?? "",
    user: query.get("user") ?? "",
  }));
  const setNew = useCallback(
    (value: boolean) => setState("new", value),
    [setState],
  );
  const setTeam = useCallback(
    (value: string) => setState("team", value),
    [setState],
  );
  const setUser = useCallback(
    (value: string) => setState("user", value),
    [setState],
  );
  return useMemo<ParamsType>(
    () => ({
      ...state,
      setNew,
      setTeam,
      setUser,
      clear,
    }),
    [state, setNew, setTeam, setUser, clear],
  );
}

import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

export function VacationCalculation() {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Semesterberäkning</Typography>
      <Typography>
        Du lägger in semesterdagar i{" "}
        <Link to={"/vacation"}>Händelsekalendern</Link>. Givet dessa
        semesterhändelser beräknas det justerade antalet arbetade timmar och
        pass automatiskt. Notera att den här regelmodulen hjälper dig att
        beräkna semester <i>innan</i> schemat är lagt. Algoritmen kommer högst
        troligen kunna lägga ett "bättre" schema om den känner till semestrar
        och alltså kan ta hänsyn till och också parera dem.
      </Typography>
    </Stack>
  );
}

import { MenuItem, Select } from "@mui/material";

type Props = {
  onChange: any;
  value: any;
  options: number[];
  readOnly?: boolean;
  disabled: boolean;
};

const defaultOptions = [
  { value: 0, label: "Måndag" },
  { value: 1, label: "Tisdag" },
  { value: 2, label: "Onsdag" },
  { value: 3, label: "Torsdag" },
  { value: 4, label: "Fredag" },
  { value: 5, label: "Lördag" },
  { value: 6, label: "Söndag" },
];

export function WeekdaySelect({
  readOnly,
  onChange,
  value,
  options,
  disabled,
}: Props) {
  const filteredOptions = defaultOptions.filter((o) =>
    options.includes(o.value),
  );
  return (
    <Select
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      disabled={disabled}
      size="small"
      sx={{ width: 101 }}
    >
      {filteredOptions.map((o) => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  );
}

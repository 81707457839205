import { memo, Suspense, useCallback, useMemo } from "react";
import Timeline, { DateHeader, TimelineHeaders } from "react-calendar-timeline";
import { Typography } from "@mui/material";
import { useGetSchedule } from "api";
import resizeDetector from "libs/react-calendar-timeline/container-resize-detector";
import type { ExtractNode } from "relay-help/arrays";
import { useConnectionToArray } from "relay-help/arrays";
import { EMPTY_ARRAY } from "utils/constants";

import type { ColorFocusType, NullableString } from "../../types";

import {
  useGroups,
  useItemsToTimelineItems,
  useRemapReferences,
} from "./hooks";
import { TimelineItemRenderer } from "./TimelineItemRenderer";
import type {
  ActivityGanttTimelineQuery$data as Data,
  PrevTimelineItem,
  TimelineItem,
  UserItem,
} from "./types";
import { activityTypes } from "./types";
import { useUserBreaksToTimelineItem } from "./useUserBreaksToTimelineItem";

const HOUR = 60 * 60 * 1000;

type Activity = ExtractNode<NonNullable<Data["schedule"]>["activities"]>;

type CommonProps = {
  scheduleId: string;
  onItemSelect: (activityId: string) => void;
  minDate: string;
  maxDate: string;
  selectedTeam: NullableString;
  selectedActivities: string[];
  selectedCompetence: NullableString;
  colorFocus: ColorFocusType;
  refreshData: boolean;
};

type ContentProps = CommonProps & {
  apiData: Data;
};

type Props = CommonProps & {};

function ActivityGanttTimelineContent({
  apiData,
  onItemSelect: onActivitySelect,
  minDate,
  maxDate,
  selectedTeam,
  selectedActivities,
  selectedCompetence,
  colorFocus,
}: ContentProps) {
  const groups = useGroups(apiData, { selectedTeam, selectedCompetence });
  const schedule = useMemo(() => apiData?.schedule, [apiData]);
  const items: TimelineItem[] = useItemsToTimelineItems(
    useConnectionToArray<Activity>(schedule?.activities),
    {
      groups,
      colorFocus,
      selectedActivities,
      selectedTeam,
    },
  );

  const prevPeriodItems = useRemapReferences(
    useItemsToTimelineItems(schedule?.previousWeekActivities?.slice() ?? [], {
      groups,
      colorFocus,
      selectedActivities,
      selectedTeam,
      itemProps: { style: { opacity: 0.75 } },
    }) as PrevTimelineItem[],
    groups,
  );

  const start = useMemo(() => Date.parse(minDate || ""), [minDate]);
  const end = useMemo(() => Date.parse(maxDate || ""), [maxDate]);
  const periodStart = useMemo(() => new Date(start), [start]);
  const periodEnd = useMemo(() => new Date(end), [end]);

  const showDayBreak = useMemo(
    () => selectedActivities.includes(activityTypes.DAY_BREAK as string),
    [selectedActivities],
  );
  const showWeekBreak = useMemo(
    () => selectedActivities.includes(activityTypes.WEEK_BREAK as string),
    [selectedActivities],
  );

  const { dayBreakItems, weekBreakItems } = useUserBreaksToTimelineItem(
    groups,
    {
      periodStart,
      periodEnd,
    },
  );

  const renderSidebar = useCallback(
    ({ group: { title } }: { group: UserItem }) => {
      return <Typography variant="caption">{title}</Typography>;
    },
    [],
  );

  const onItemSelect = useCallback(
    (itemId: string) => {
      const item = items.find((i) => i.id === itemId);
      if (item) {
        onActivitySelect(item.activityId);
      }
    },
    [items, onActivitySelect],
  );

  const timelineItems = useMemo(
    () => [
      ...prevPeriodItems,
      ...items,
      ...(showDayBreak ? dayBreakItems : EMPTY_ARRAY),
      ...(showWeekBreak ? weekBreakItems : EMPTY_ARRAY),
    ],
    [
      prevPeriodItems,
      items,
      showDayBreak,
      dayBreakItems,
      showWeekBreak,
      weekBreakItems,
    ],
  );

  return (
    <Timeline
      groups={groups}
      items={timelineItems}
      visibleTimeStart={start}
      visibleTimeEnd={end}
      sidebarWidth={170}
      groupRenderer={renderSidebar}
      canChangeGroup={false}
      canMove={false}
      canResize={false}
      itemHeightRatio={0.6}
      minZoom={24 * HOUR}
      maxZoom={end - start}
      onItemSelect={onItemSelect}
      onItemClick={onItemSelect}
      itemRenderer={TimelineItemRenderer}
      resizeDetector={resizeDetector as any}
    >
      <TimelineHeaders>
        <DateHeader unit="year" height={0} />
      </TimelineHeaders>
    </Timeline>
  );
}

export const ActivityGanttTimelineSeparateService = memo((props: Props) => {
  const scheduleDBID = useMemo(
    () => atob(props.scheduleId).split(":")[1],
    [props?.scheduleId],
  );
  const data = useGetSchedule(scheduleDBID);

  return (
    <Suspense fallback={null}>
      {!!data && <ActivityGanttTimelineContent {...props} apiData={data} />}
    </Suspense>
  );
});

import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import type { Unwrap } from "relay-help/arrays";

import GenericSelect from "components/common/selectors/GenericSelect";

import type {
  BlockedTimeSlotUserSelect_fragment$data as Data,
  BlockedTimeSlotUserSelect_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment BlockedTimeSlotUserSelect_fragment on UserNode @relay(plural: true) {
    fullName
    id
  }
`;

type User = Unwrap<Data>;

type Option = {
  value: string;
  label: string;
};

type Props = {
  fragmentRef: Key;
  value: string | null;
  onChange: (value: string | null) => void;
};

export function BlockedTimeSlotUserSelect({
  fragmentRef,
  value,
  onChange,
}: Props) {
  const users = useFragment<Key>(fragment, fragmentRef);
  const options = useMemo<Option[]>(
    () => [
      { value: "", label: "Alla" },
      ...users.map((x: User) => ({ value: x.id, label: x.fullName })),
    ],
    [users],
  );
  const selected = useMemo<Option | undefined>(
    () => options.find((u) => u.value === value),
    [options, value],
  );

  function handleChange(_e: any, v?: Option | null) {
    onChange(v?.value || "");
  }

  return (
    <GenericSelect<Option>
      value={selected}
      onChange={handleChange}
      getOptionLabel={(o) => o?.label || ""}
      options={options}
      placeholder="Välj användare"
      label="Användare"
      sx={{ width: 200 }}
    />
  );
}

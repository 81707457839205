import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Rhythm2, Rhythm2Info as Info } from "components/setting/common/forms";

const MODULE_NAME = "ConsecutiveFreeDays";

type TitleProps = { name: string };
type Props = TitleProps & {
  readOnly?: boolean;
};

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande sammanhängande ledighet för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

export function Rhythm2Form({ readOnly, ...titleProps }: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes("ConsecutiveFreeDays");

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title {...titleProps} />}
        />
        <Rhythm2 disabled={disabled} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

/**
 * @generated SignedSource<<52589c8925333ccbc8bf98371bd03603>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Definitions_fragment$data = {
  readonly eveningStartsMorningEnds: string;
  readonly fullDayDuration: number;
  readonly id: string;
  readonly morningStartsNightEnds: string;
  readonly nightStartsEveningEnds: string;
  readonly weekendEndsAt: string;
  readonly weekendEndsAtDay: number;
  readonly weekendStartsAt: string;
  readonly weekendStartsAtDay: number;
  readonly " $fragmentType": "Definitions_fragment";
};
export type Definitions_fragment$key = {
  readonly " $data"?: Definitions_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Definitions_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Definitions_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "morningStartsNightEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eveningStartsMorningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nightStartsEveningEnds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullDayDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendStartsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendStartsAtDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weekendEndsAtDay",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "636017b4c8a2c5b95cc283b8010b473c";

export default node;

/**
 * @generated SignedSource<<cbabcc5b32767bda5f480889b2033db9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApiScheduleSolutionStatusChoices = "ERROR" | "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "QUEUED" | "RUNNING" | "RUNNING_WITH_INFEASIBILITY_VENTS" | "%future added value";
export type ScheduleRunsQuery$variables = {
  periodId: string;
};
export type ScheduleRunsQuery$data = {
  readonly period: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"RunSchedule_period">;
  } | null;
  readonly schedules: ReadonlyArray<{
    readonly solutionStatus: ApiScheduleSolutionStatusChoices;
    readonly " $fragmentSpreads": FragmentRefs<"ScheduleRunsTable_fragment">;
  }>;
};
export type ScheduleRunsQuery = {
  response: ScheduleRunsQuery$data;
  variables: ScheduleRunsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "periodId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "periodId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "periodId",
    "variableName": "periodId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solutionStatus",
  "storageKey": null
},
v6 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleRunsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "period",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RunSchedule_period"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedules",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleRunsTable_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleRunsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "period",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedules",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNode",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mipgap",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeLimit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalSolvingTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terminationCondition",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "manuallyEdited",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleNode",
            "kind": "LinkedField",
            "name": "copiedFrom",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PeriodNode",
            "kind": "LinkedField",
            "name": "masterForPeriod",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef32baab2cbf9705ecab8814fee4ffff",
    "id": null,
    "metadata": {},
    "name": "ScheduleRunsQuery",
    "operationKind": "query",
    "text": "query ScheduleRunsQuery(\n  $periodId: ID!\n) {\n  period(id: $periodId) {\n    id\n    name\n    ...RunSchedule_period\n  }\n  schedules(periodId: $periodId) {\n    solutionStatus\n    ...ScheduleRunsTable_fragment\n    id\n  }\n}\n\nfragment RunSchedule_period on PeriodNode {\n  id\n  name\n}\n\nfragment ScheduleRunsTable_fragment on ScheduleNode {\n  id\n  created\n  createdBy {\n    id\n    fullName\n  }\n  mipgap\n  timeLimit\n  totalSolvingTime\n  terminationCondition\n  solutionStatus\n  manuallyEdited\n  copiedFrom {\n    id\n  }\n  masterForPeriod {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cc31e14919ef51153b6824b7cc988d8";

export default node;

import { Stack, Typography } from "@mui/material";

const ErrorScreen = () => (
  <Stack gap={2} sx={{ p: 4 }}>
    <Typography variant="h3">Ajdå! Något gick fel.</Typography>
    <Typography>
      Något har gått fel här. Testa att ladda om sidan! Om problemet kvarstår,
      kontakta din Konvoj-kontakt.
    </Typography>
  </Stack>
);

export default ErrorScreen;

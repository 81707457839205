import { SHORT_DAYS } from "utils/day";

import {
  CardWithIndices,
  Chips,
  useIndicesFormikField,
} from "./CardWithIndices";

type Props = Omit<React.ComponentProps<typeof CardWithIndices>, "chips"> & {
  checked: number[];
  setChecked: (newValue: number[]) => void;
};

type ChipsProps = React.ComponentProps<typeof Chips>;
type DayChipsProps = Omit<ChipsProps, "options">;
type FormikDayChipsProps = Omit<DayChipsProps, "checked" | "setChecked"> & {
  name: string;
};

type FormikProps = Omit<Props, "checked" | "setChecked"> & {
  name: string;
};

export const DayChips = (props: DayChipsProps) => (
  <Chips {...props} options={SHORT_DAYS} />
);

export function FormikDayChips({ name, ...props }: FormikDayChipsProps) {
  const { checked, setChecked } = useIndicesFormikField(name);

  return <DayChips checked={checked} setChecked={setChecked} {...props} />;
}

export function DayCard({ checked, setChecked, ...props }: Props) {
  return (
    <CardWithIndices
      {...props}
      chips={<DayChips checked={checked} setChecked={setChecked} />}
    />
  );
}

export function FormikDayCard({ name, ...props }: FormikProps) {
  const { checked, setChecked, error } = useIndicesFormikField(name);

  return (
    <DayCard
      {...props}
      error={!!error}
      helperText={error}
      checked={checked || []}
      setChecked={setChecked}
    />
  );
}

import { Dialog } from "@mui/material";
import { Form as FormikForm, Formik } from "formik";

import { useCreateRuleGroup } from "../mutations";
import type { CreateRuleGroupInput as FormValues } from "../types";

import {
  RuleGroupForm,
  ruleGroupFormValidationSchema as validationSchema,
} from "./common";

type Props = {
  open: boolean;
  onClose: () => void;
  teamGroupId: string;
};

export function CreateRuleGroupForm({ open, onClose, teamGroupId }: Props) {
  const [commit] = useCreateRuleGroup();
  const Title = () => <>Skapa ny regelgrupp</>;
  const initialValues: FormValues = {
    name: "",
    description: "",
    teamGroupId,
  };

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } })
      .then(onClose)
      .catch((error) => console.error(error));
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <FormikForm autoComplete="off">
          <RuleGroupForm
            title={<Title />}
            submitText="Skapa regelgrupp"
            onClose={onClose}
          />
        </FormikForm>
      </Formik>
    </Dialog>
  );
}

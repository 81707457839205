import type { FC } from "react";
import { useCallback, useMemo } from "react";
import type { StackProps } from "@mui/material";
import { Button } from "@mui/material";
import { useFormikContext } from "formik";

import { HeaderActionsScaffold } from "../Scaffolds";

import type { FormValues } from "./types";

type CancelButtonProps = { onClick: VoidFunction; text: string };
type SubmitButtonProps = {
  onClick: VoidFunction;
  disabled?: boolean;
  text: string;
};
type Props = StackProps & {
  cancelProps: CancelButtonProps;
  submitProps: SubmitButtonProps;
};
export type HeaderActionsProps = Props;

export function HeaderActions({ cancelProps, submitProps, ...props }: Props) {
  return (
    <HeaderActionsScaffold
      {...props}
      submitButton={<SubmitButton {...submitProps} />}
      cancelButton={<CancelButton {...cancelProps} />}
    />
  );
}

const CancelButton: FC<CancelButtonProps> = ({ onClick, text }) => (
  <Button variant="outlined" onClick={onClick}>
    {text}
  </Button>
);
const SubmitButton: FC<SubmitButtonProps> = ({ onClick, disabled, text }) => (
  <Button variant="primary" onClick={onClick} disabled={disabled}>
    {text}
  </Button>
);

export function useFormHeaderActions() {
  const { submitForm, resetForm, dirty, isValidating, values, errors } =
    useFormikContext<FormValues>();
  const { errors: errorsVal, shifts } = values;
  // FieldArray does not track touched/errors as we need it to.
  // Checking shifts length manually is our solution.
  const submitDisabled = useMemo(
    () =>
      !dirty ||
      isValidating ||
      !!errorsVal.length ||
      !!Object.keys(errors).length ||
      !shifts.length,
    [dirty, isValidating, errors, errorsVal, shifts],
  );

  const reset = useCallback(() => resetForm(), [resetForm]);
  const submit = useCallback(() => submitForm(), [submitForm]);

  return {
    reset,
    submit,
    submitDisabled,
  };
}

/**
 * @generated SignedSource<<b8bfb7549e46a98a4f7d8a6ca7eb8a61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PriorityWeight = "ELEVATED" | "NORMAL" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DemandForm_fragment$data = {
  readonly demandCompetences: ReadonlyArray<{
    readonly competence: {
      readonly color: string;
      readonly id: string;
      readonly name: string;
    };
    readonly demands: ReadonlyArray<{
      readonly demands: ReadonlyArray<{
        readonly days: ReadonlyArray<number>;
        readonly demands: ReadonlyArray<{
          readonly demand: number;
          readonly dutyShare: number | null;
          readonly end: string;
          readonly minimizeOverstaffingWeight: PriorityWeight;
          readonly minimizeUnderstaffingWeight: PriorityWeight;
          readonly start: string;
        }>;
      }>;
      readonly weeks: ReadonlyArray<number>;
    }>;
    readonly team: {
      readonly color: string;
      readonly id: string;
      readonly name: string;
    };
  }>;
  readonly demands: ReadonlyArray<{
    readonly demands: ReadonlyArray<{
      readonly demands: ReadonlyArray<{
        readonly days: ReadonlyArray<number>;
        readonly demands: ReadonlyArray<{
          readonly demand: number;
          readonly dutyShare: number | null;
          readonly end: string;
          readonly enforceDuty: boolean | null;
          readonly minimizeOverstaffingWeight: PriorityWeight;
          readonly minimizeUnderstaffingWeight: PriorityWeight;
          readonly start: string;
        }>;
      }>;
      readonly weeks: ReadonlyArray<number>;
    }>;
    readonly team: {
      readonly color: string;
      readonly id: string;
      readonly name: string;
    };
  }>;
  readonly " $fragmentType": "DemandForm_fragment";
};
export type DemandForm_fragment$key = {
  readonly " $data"?: DemandForm_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandForm_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "color",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamNode",
  "kind": "LinkedField",
  "name": "team",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weeks",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "days",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "demand",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dutyShare",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimizeUnderstaffingWeight",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimizeOverstaffingWeight",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandForm_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandTeamNode",
      "kind": "LinkedField",
      "name": "demands",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DemandWeeksNode",
          "kind": "LinkedField",
          "name": "demands",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DemandDaysNode",
              "kind": "LinkedField",
              "name": "demands",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DemandItemNode",
                  "kind": "LinkedField",
                  "name": "demands",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "enforceDuty",
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandCompetenceTeamNode",
      "kind": "LinkedField",
      "name": "demandCompetences",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceNode",
          "kind": "LinkedField",
          "name": "competence",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DemandWeeksNode",
          "kind": "LinkedField",
          "name": "demands",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DemandDaysNode",
              "kind": "LinkedField",
              "name": "demands",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DemandItemNode",
                  "kind": "LinkedField",
                  "name": "demands",
                  "plural": true,
                  "selections": [
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DemandFormViewer",
  "abstractKey": null
};
})();

(node as any).hash = "99ab67fc5f1649fec88b59f6da51ca18";

export default node;

import { Suspense, useCallback, useEffect, useMemo, useRef } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  useChangeTitle,
  useCurrentTeamGroup,
  useCurrentTeamGroupUpdater,
} from "hooks";

import PageWrapper from "components/layout/PageWrapper";
import {
  SchedulePlaceholder,
  TabHeaderPlaceholder,
} from "components/loading/pages";
import { ScheduleProvider, ScheduleTabs, TabHeader } from "components/schedule";

import { OptimiserStatistics } from "./OptimiserStatistics";
import { Results } from "./Results";
import { ScheduleGroupSettings } from "./ScheduleGroupSettings";
import { ScheduleSettings } from "./ScheduleSettings";
import { Statistics } from "./Statistics";
import type { ScheduleQuery as Query } from "./types";

const query = graphql`
  query ScheduleQuery($id: ID!) {
    schedule(id: $id) {
      period {
        group {
          id
        }
        name
      }
      ...OptimiserStatistics_fragment
      ...Results_fragment
      ...ScheduleTabs_fragment
      ...Statistics_fragment
      ...TabHeader_fragment
      ...ScheduleContext_fragment
    }
  }
`;

type TParams = { id: string; index?: string };
type InnerParams = {
  scheduleId: string;
  queryRef: PreloadedQuery<Query>;
  index?: number;
  refresh: () => Promise<void>;
};

function InnerSchedule({ scheduleId, index, queryRef, refresh }: InnerParams) {
  const { schedule } = usePreloadedQuery<Query>(query, queryRef);
  const groupId = schedule?.period.group.id ?? "";

  useNavigateOnGroupChange("/periods", groupId);

  useChangeTitle(
    `Schema${schedule?.period.name ? ` v. ${schedule?.period.name}` : ""}`,
  );

  const tabs = useMemo(
    () => [
      {
        label: "Resultat",
        content: !!schedule && (
          <Results
            fragmentRef={schedule}
            scheduleId={scheduleId}
            refresh={refresh}
          />
        ),
        id: "result",
        index: 0,
      },
      {
        label: "Statistik",
        content: !!schedule && (
          <Statistics fragmentRef={schedule} scheduleId={scheduleId} />
        ),
        id: "statistics",
        index: 1,
      },
      {
        label: "Inställningar",
        content: <ScheduleGroupSettings scheduleId={scheduleId} />,
        id: "group-settings",
        index: 2,
      },
      {
        label: "Optimeringsregler",
        content: !!schedule && <ScheduleSettings scheduleId={scheduleId} />,
        id: "settings",
        index: 3,
      },
      {
        label: "Optimeringsstatistik",
        content: !!schedule && (
          <OptimiserStatistics fragmentRef={schedule} scheduleId={scheduleId} />
        ),
        id: "optimiser-statistics",
        index: 4,
      },
    ],
    [schedule, refresh, scheduleId],
  );

  return (
    <PageWrapper sx={{ overflowX: "hidden" }}>
      {!!schedule && (
        <ScheduleProvider fragmentRef={schedule}>
          <Stack>
            <Suspense fallback={<TabHeaderPlaceholder />}>
              <TabHeader fragmentRef={schedule} />
            </Suspense>
            <ScheduleTabs tabs={tabs} index={index} fragmentRef={schedule} />
          </Stack>
        </ScheduleProvider>
      )}
    </PageWrapper>
  );
}

export function Schedule() {
  const { id, index } = useParams<TParams>();
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const env = useRelayEnvironment();

  const refresh = useCallback(async () => {
    await fetchQuery(env, query, { id }).toPromise();
  }, [env, id]);

  useEffect(() => {
    if (!id) return;
    loadQuery({ id });
  }, [loadQuery, id]);

  return (
    <Suspense fallback={<SchedulePlaceholder />}>
      {!!id && !!queryRef && (
        <InnerSchedule
          queryRef={queryRef}
          scheduleId={id}
          index={index ? parseInt(index) : undefined}
          refresh={refresh}
        />
      )}
    </Suspense>
  );
}

/** Redirect user when currentTeamGroup changes.
 *
 * If `scheduleGroupId` differs from currentTeamGroup.id,
 * and it is the first render, `scheduleGroupId` will bypass. */
function useNavigateOnGroupChange(redirectTo: string, scheduleGroupId: string) {
  const currentGroup = useCurrentTeamGroup();
  const idRef = useRef(currentGroup.id);
  const firstRender = useRef(true);
  const navigate = useNavigate();
  const updateTeamGroup = useCurrentTeamGroupUpdater();

  useEffect(() => {
    // reset firstRender
    firstRender.current = true;
  }, [scheduleGroupId]);

  useEffect(() => {
    // override scheduleGroupId
    if (scheduleGroupId !== idRef.current && firstRender.current) {
      updateTeamGroup(scheduleGroupId);
      idRef.current = scheduleGroupId;
    }
    // navigate
    if (!firstRender.current && idRef.current !== currentGroup.id) {
      idRef.current = currentGroup.id;
      navigate(redirectTo);
    }
    firstRender.current = false;
  }, [currentGroup.id, redirectTo, navigate, scheduleGroupId, updateTeamGroup]);
}

import type { StackProps } from "@mui/material";
import { Stack, Typography } from "@mui/material";

import HardSoftRadios from "components/forms/HardSoftRadios";

type Props = StackProps & {
  name: string;
  label: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export function LabeledHardSoftRadios({
  name,
  label,
  disabled,
  readOnly,
  ...stackProps
}: Props) {
  return (
    <Stack
      {...stackProps}
      gap={3}
      sx={{
        alignItems: "stretch",
        justifyContent: "flex-end",
        ...(stackProps?.sx ?? {}),
      }}
      direction="row"
    >
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
      <Stack sx={{ alignItems: "start" }}>
        <HardSoftRadios
          name={name}
          softLabel="Ja"
          hardLabel="Nej"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
    </Stack>
  );
}

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
} from "@mui/material";
import { useFormikContext } from "formik";
import * as yup from "yup";

import { FormikColorPicker } from "components/common/ColorPicker";
import { DialogTitle } from "components/common/Dialog";
import CustomField from "components/forms/CustomField";

export const competenceFormValidationSchema = yup.object({
  name: yup.string().required("Fyll i ett namn"),
  description: yup.string().required("Fyll i en beskrivning"),
  color: yup.string(),
});

type Props = {
  title: any;
  submitText: any;
  onClose: () => void;
};

export type CompetenceFormValues = {
  id?: string;
  name: string;
  description: string;
  color: string;
};

export function CompetenceForm({ onClose, title, submitText }: Props) {
  const { isSubmitting } = useFormikContext<CompetenceFormValues>();

  return (
    <>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <Divider />

      <DialogContent>
        <Stack gap={3} pb={1} pt={1}>
          <Stack gap={3}>
            <CustomField label="Namn" name="name" />
            <CustomField label="Beskrivning" name="description" />
          </Stack>
          <Box sx={{ display: "flex" }}>
            <FormikColorPicker
              name="color"
              label="Färg"
              variant="primary_and_secondary"
              sx={{ width: "9rem" }}
            />
          </Box>
        </Stack>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Stack direction="row" gap={1}>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
          <Button variant="cancel" onClick={onClose}>
            Avbryt
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}

import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useConnectionToArray } from "relay-help/arrays";

import {
  RuleGroupsCard,
  RuleGroupsListContent,
} from "components/group_settings";

import type { ScheduleGroupSettingsRuleGroupList_ruleGroups$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment ScheduleGroupSettingsRuleGroupList_ruleGroups on RuleGroupSnapshotNodeConnection {
    edges {
      node {
        name
        description
      }
    }
  }
`;

export function ScheduleGroupSettingsRuleGroupList({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const ruleGroups = useConnectionToArray(data);

  return (
    <RuleGroupsCard noAction>
      <RuleGroupsListContent ruleGroups={ruleGroups} hideActions />
    </RuleGroupsCard>
  );
}

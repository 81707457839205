import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { FormikSmallSwitch } from "components/common/Switch";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function Types1({ disabled, readOnly }: Props) {
  const allowSplitShifts = useFormikState<boolean>("allowSplitShifts")?.value;

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h4">
            Tillåt att delade turer förekommer
          </Typography>
          <FormikSmallSwitch
            name="allowSplitShifts"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <Typography>
          Schemats olika passtyper anges under fliken som heter{" "}
          <Link to="/shifts">
            <b>Passprofil</b>
          </Link>
          . Härifrån, med denna knapp, har du möjlighet att köra över de
          inställningarna genom att stänga av möjligheten för schemat att
          innehålla delade turer.
        </Typography>
      </Stack>

      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h4">
            Fördela delade turer lika mellan medarbetare
          </Typography>
          <FormikSmallSwitch
            name="optimizeSplitShiftsDistributionBetweenEmployees"
            disabled={disabled || !allowSplitShifts}
            readOnly={readOnly}
          />
        </Stack>
        <Typography>
          Om denna inställning är påslagen så kommer algoritmen eftersträva en
          jämn fördelning av delade turer mellan medarbetare.
        </Typography>
      </Stack>
    </Stack>
  );
}

import PaperWrapper from "settings/common/PaperWrapper";

import { VacationCalculation } from "./VacationFormInformation";

type Props = { readOnly?: boolean };

export function VacationForm({ readOnly }: Props) {
  return (
    <PaperWrapper>
      <VacationCalculation />
    </PaperWrapper>
  );
}

import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertTeamGroupDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import {
  nightShiftRestrictionChoices,
  nightShiftRotationChoices,
} from "../../components/setting/team_group/setting_boxes/constants";

import {
  NightShiftSettingRuleGroup_fragment$data as RuleGroupData,
  NightShiftSettingRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/NightShiftSettingRuleGroup_fragment.graphql";
import {
  NightShiftSettingTeamGroup_fragment$data as TeamGroupData,
  NightShiftSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/NightShiftSettingTeamGroup_fragment.graphql";
import {
  NightShiftSettingUserSetting_fragment$data as UserSettingData,
  NightShiftSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/NightShiftSettingUserSetting_fragment.graphql";
import { NightShiftsForm } from "./form/NightShiftsForm";

const teamGroupFragment = graphql`
  fragment NightShiftSettingTeamGroup_fragment on SettingNode {
    id
    constraintModules
    nightShiftRestriction
    nightShiftRotation
  }
`;

const userSettingFragment = graphql`
  fragment NightShiftSettingUserSetting_fragment on UserSettingNode {
    id
    settingModules
    nightShiftRestriction
    nightShiftRotation
  }
`;

const ruleGroupFragment = graphql`
  fragment NightShiftSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    settingModules
    nightShiftRestriction
    nightShiftRotation
  }
`;

const SETTING_NAME = "Nattpass";
const MODULE_NAME = "NightShifts";
const SETTING_URL_ID = "night-shifts";

type NightShiftFormValues = Pick<
  TeamGroupData,
  "id" | "constraintModules" | "nightShiftRestriction" | "nightShiftRotation"
>;

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  constraintModules: yup.array().of(yup.string()),
  nightShiftRestriction: yup
    .string()
    .oneOf(nightShiftRestrictionChoices)
    .required(),
  nightShiftRotation: yup.string().oneOf(nightShiftRotationChoices).required(),
});

function convertUserOrRuleGroupDataToInitialValues(
  data: UserSettingData | RuleGroupData,
): NightShiftFormValues {
  const { settingModules, ...partialInput } = data;
  return {
    ...partialInput,
    constraintModules: settingModules,
  };
}

function renderComponent(
  initialValues: NightShiftFormValues,
  onSubmit: (formValues: NightShiftFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <NightShiftsForm />
    </BaseSettings>
  );
}

export const nightShiftSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  RuleGroupKey,
  NightShiftFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues:
    defaultConvertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
};

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderSwitch,
  HeaderTitle,
  HeaderWeight,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Free1, Free1Info as Info } from "components/setting/common/forms";

const MODULE_NAME = "FreeWeekends";

type FormProps = {
  groupName: string;
  readOnly?: boolean;
  periodLengthWeeks: number;
};

function Title({ name }: { name: string }) {
  const title = (
    <>
      Helgvila för enhet <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

function Weight({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderWeight
      name="freeWeekendWeight"
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Free1Form({
  groupName,
  periodLengthWeeks,
  readOnly,
}: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title name={groupName} />}
          weightComponent={<Weight readOnly={readOnly} disabled={disabled} />}
        />
        <Free1
          periodLengthWeeks={periodLengthWeeks || 0}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
    </SettingBox>
  );
}

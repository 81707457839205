import type { Serie } from "@nivo/line";

import { ALL_TEAMS } from "components/demand/form/utils";

import type { View } from "./types";

export function resolveId(v: View) {
  return (
    [v?.team?.id, v?.competence?.id].filter(Boolean).join(".") || ALL_TEAMS
  );
}

export function resolveColor(v: View) {
  return v?.competence?.color ?? v?.team?.color ?? "lightgrey";
}

export function resolveName(v: View) {
  return [v?.team?.name, v?.competence?.name || "Totalt"]
    .filter(Boolean)
    .join(" | ");
}

export function addMissingTimestamps(items: Serie[]): Serie[] {
  const allTimestamps: string[] = Array.from(
    new Set(items.flatMap((i) => i.data.map((d) => d?.x as string))),
  ).sort((a, b) => (a < b ? -1 : 1));

  return items.map((i) => {
    const dataMap = i.data.reduce(
      (acc, d) => ({ ...acc, [d.x as string]: d.y }),
      {} as Record<string, number>,
    );

    const newData: { x: string; y: number }[] = [];
    let currentValue = 0;
    for (const ts of allTimestamps) {
      if (ts in dataMap) {
        currentValue = dataMap[ts];
      }
      newData.push({ x: ts, y: currentValue });
    }
    return { ...i, data: newData };
  });
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { EditTeamGroupMutation as Mutation } from "./__generated__/EditTeamGroupMutation.graphql";

const mutation = graphql`
  mutation EditTeamGroupMutation($input: EditTeamGroupInput!) {
    editTeamGroup(input: $input) {
      teamGroup {
        ...EditTeamGroupForm_fragment
      }
    }
  }
`;

export const useEditTeamGroup = () => useMutation<Mutation>(mutation);

import { useMemo } from "react";

import { SelectedType } from "components/setting/types";

interface Option {
  id: string;
}

type SettingTypeProps<C extends Option, U extends Option> = {
  groupId: string;
  levelId: string;
  userOptions: ReadonlyArray<U>;
  ruleGroupOptions: ReadonlyArray<C>;
};

export function useSelectedSetting<
  RuleGroup extends Option,
  User extends Option,
>({
  groupId,
  levelId,
  userOptions,
  ruleGroupOptions,
}: SettingTypeProps<RuleGroup, User>) {
  const teamGroupSelected = useMemo(
    () => levelId === groupId,
    [levelId, groupId],
  );
  const selectedRuleGroup = useMemo(
    () => ruleGroupOptions.find((x) => x.id === levelId) ?? null,
    [levelId, ruleGroupOptions],
  );
  const selectedUser = useMemo(
    () => userOptions.find((x) => x.id === levelId) ?? null,
    [levelId, userOptions],
  );
  const selectedType = useMemo<SelectedType | null>(() => {
    if (teamGroupSelected) return "group";
    if (selectedUser) return "person";
    if (selectedRuleGroup) return "ruleGroup";
    return null;
  }, [teamGroupSelected, selectedRuleGroup, selectedUser]);

  return useMemo(
    () => ({
      teamGroupSelected,
      selectedRuleGroup,
      selectedUser,
      selectedType,
    }),
    [teamGroupSelected, selectedRuleGroup, selectedUser, selectedType],
  );
}

import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import { useSnackbar } from "components/Snackbar";

import { createValuesToInput, initFormValues, MeetingFormikForm } from "./form";
import { useCreateMeeting } from "./mutations";
import type {
  CreateFormValues,
  CreateMeetingForm_setting$key as SettingKey,
} from "./types";

type Props = {
  teamGroupId: string;
  settingFragmentRef: SettingKey;
  onClose: VoidFunction;
};

const settingFragment = graphql`
  fragment CreateMeetingForm_setting on SettingNode {
    periodLengthWeeks
    ...MeetingForm_setting
  }
`;

export function CreateMeetingForm({
  teamGroupId,
  settingFragmentRef,
  onClose,
}: Props) {
  const { initialValues, onSubmit, setting, catchError } = useCreateMeetingForm(
    teamGroupId,
    settingFragmentRef,
  );

  return (
    <MeetingFormikForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      catchError={catchError}
      submitText="Skapa möte"
      cancelText="Förkasta"
      settingFragmentRef={setting}
    />
  );
}

function useCreateMeetingForm(
  teamGroupId: string,
  settingFragmentRef: SettingKey,
) {
  const [commit] = useCreateMeeting();
  const setting = useFragment<SettingKey>(settingFragment, settingFragmentRef);
  const { addSnack } = useSnackbar();

  const initialValues = useMemo(
    () => initFormValues(teamGroupId, setting.periodLengthWeeks),
    [setting.periodLengthWeeks, teamGroupId],
  );
  const onSubmit = useCallback(
    async (values: CreateFormValues) => {
      const input = createValuesToInput(values);
      return commit({ variables: { input } });
    },
    [commit],
  );
  const catchError = useCallback(
    (e: Error) => {
      console.error(e);
      addSnack({ message: "Kunde inte skapa möte", severity: "error" });
    },
    [addSnack],
  );

  return { initialValues, onSubmit, setting, catchError };
}

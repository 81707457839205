import { Suspense } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import {
  Button,
  CircularProgress as Loading,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormGroup,
  FormLabel,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
// import { useMeQuery } from "hooks/Me";
import { commitMutation } from "libs/commitMutation";

import { DialogTitle } from "components/common/Dialog";
import StyledInputField from "components/forms/material/StyledInputField";

import { RunSchedule_period$key } from "./__generated__/RunSchedule_period.graphql";
import {
  RunScheduleMutation,
  RunSchedulingInput as FormValues,
} from "./__generated__/RunScheduleMutation.graphql";
// import { LabeledCheckbox } from "./common";

// FOR THE TIME BEING WE WILL HIDE THE CHECKBOXES TO SAVE FILES

type Props = {
  onClose: () => void;
  open: boolean;
  period: RunSchedule_period$key | null;
};

type FormProps = {
  periodName: string;
};

function Form({ periodName }: FormProps) {
  const { values, handleChange, isSubmitting, resetForm } =
    useFormikContext<FormValues>();
  // const { me } = useMeQuery();
  // const { isSuperuser = false } = me || {};
  return (
    <>
      <DialogTitle onClose={() => resetForm()}>
        <Typography variant="h2">Ny schemaoptimering</Typography>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <Suspense fallback={null}>
          <Stack gap={4}>
            <Stack>
              <FormLabel>Schemaperiod</FormLabel>
              <Typography sx={{ mb: 3 }}>Vecka {periodName}</Typography>

              <FormGroup>
                <FormLabel>Tidsbegränsning (minuter)</FormLabel>
                <StyledInputField
                  fullWidth
                  size="small"
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">
                      {values.timeLimit === 1 ? "minut" : "minuter"}
                    </InputAdornment>
                  }
                  name="timeLimit"
                  value={values.timeLimit}
                  onChange={handleChange}
                />
              </FormGroup>
            </Stack>
            {/*isSuperuser && (
              <FormGroup>
                <LabeledCheckbox name="saveLog" label="Spara logg" />
                <LabeledCheckbox name="saveMps" label="Spara MPS" />
                <LabeledCheckbox name="saveLp" label="Spara LP" />
              </FormGroup>
              )*/}
          </Stack>
        </Suspense>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Stack direction="row" gap={1}>
          {isSubmitting && <Loading />}
          <Button variant="cancel" onClick={() => resetForm()}>
            Avbryt
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Optimera schema
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
}

const RunSchedule = ({ open, onClose, period: periodFragment }: Props) => {
  const environment = useRelayEnvironment();
  const mutation = graphql`
    mutation RunScheduleMutation($input: RunSchedulingInput!) {
      runScheduling(input: $input) {
        ok
      }
    }
  `;

  const period = useFragment<RunSchedule_period$key>(
    graphql`
      fragment RunSchedule_period on PeriodNode {
        id
        name
      }
    `,
    periodFragment,
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      fullWidth
      maxWidth="xs"
    >
      <Formik
        initialValues={{
          timeLimit: 1,
          saveMps: false,
          saveLp: false,
          saveLog: false,
        }}
        onReset={() => onClose()}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          commitMutation<RunScheduleMutation>(environment, {
            mutation,
            variables: { input: { ...values, periodId: period?.id || "" } },
          })
            .then((response) => {
              setSubmitting(false);
              if (response.runScheduling?.ok) {
                onClose();
              }
            })
            .catch(() => setSubmitting(false));
        }}
      >
        <FormikForm autoComplete="off">
          <Form periodName={period?.name || ""} />
        </FormikForm>
      </Formik>
    </Dialog>
  );
};

export default RunSchedule;

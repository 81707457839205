import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Divider, List, Stack } from "@mui/material";
import { useRequireMe } from "hooks";
import type { Route } from "types/Route";

import CurrentTeamGroupSelect from "./CurrentTeamGroupSelect";
import {
  adminRoute,
  demandRoute,
  groupSettingRoute,
  homeRoute,
  meetingRoute,
  memberRoute,
  periodRoute,
  settingRoute,
  shiftRoute,
  vacationRoute,
} from "./navigation_routes";
import { NavListItem } from "./NavLink";

export default function NavList() {
  const { evaluatePermission } = useRequireMe();

  const location = useLocation();

  const renderRoute = useCallback(
    (route: Route) => (
      <NavListItem key={route.to} pathname={location.pathname} route={route} />
    ),
    [location.pathname],
  );

  return (
    <List dense>
      {renderRoute(homeRoute)}
      {evaluatePermission("admin", "READ") && renderRoute(adminRoute)}
      <Divider sx={{ my: 0.5, borderColor: "ture.60" }} />
      <Stack sx={{ pt: 2, pb: 1, px: 3 }}>
        <CurrentTeamGroupSelect />
      </Stack>
      {evaluatePermission("group-settings", "READ") &&
        renderRoute(groupSettingRoute)}
      {evaluatePermission("members", "READ") && renderRoute(memberRoute)}
      {evaluatePermission("schedule-settings", "READ") &&
        renderRoute(settingRoute)}
      {evaluatePermission("shifts", "READ") && renderRoute(shiftRoute)}
      {evaluatePermission("demands", "READ") && renderRoute(demandRoute)}
      {evaluatePermission("meetings", "READ") && renderRoute(meetingRoute)}
      {evaluatePermission("events", "READ") && renderRoute(vacationRoute)}
      {evaluatePermission("periods", "READ") && renderRoute(periodRoute)}
      <Divider sx={{ my: 0.5, borderColor: "ture.60" }} />
    </List>
  );
}

/**
 * @generated SignedSource<<aa27d7a3ab89725f934b7b5988687b48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApiActivityActivityTypeChoices = "A" | "C" | "D" | "F" | "J" | "M" | "O" | "P" | "Q" | "R" | "S" | "V" | "W" | "Y" | "Z" | "%future added value";
export type ApiActivityPartPartTypeChoices = "B" | "J" | "M" | "P" | "Z" | "%future added value";
export type ValidateEditActivityInput = {
  activityId: string;
  activityParts?: ReadonlyArray<ActivityPartInput | null> | null;
  activityType: ApiActivityActivityTypeChoices;
  breakTime: number;
  clientMutationId?: string | null;
  end: string;
  start: string;
  teamId?: string | null;
  userId: string;
};
export type ActivityPartInput = {
  end: string;
  id?: string | null;
  partType: ApiActivityPartPartTypeChoices;
  start: string;
};
export type ValidateEditActivityMutation$variables = {
  input: ValidateEditActivityInput;
};
export type ValidateEditActivityMutation$data = {
  readonly validateEditActivity: {
    readonly failedToFetch: boolean | null;
    readonly results: {
      readonly errors: ReadonlyArray<{
        readonly activity: {
          readonly activityParts: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly end: string;
                readonly id: string;
                readonly partType: ApiActivityPartPartTypeChoices;
                readonly start: string;
              } | null;
            } | null>;
          };
          readonly activityType: ApiActivityActivityTypeChoices;
          readonly end: string;
          readonly id: string;
          readonly start: string;
        } | null;
        readonly message: string;
        readonly module: string;
        readonly user: {
          readonly name: string;
        } | null;
      }> | null;
      readonly ok: boolean;
    };
  } | null;
};
export type ValidateEditActivityMutation = {
  response: ValidateEditActivityMutation$data;
  variables: ValidateEditActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "failedToFetch",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "module",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ActivityNode",
  "kind": "LinkedField",
  "name": "activity",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activityType",
      "storageKey": null
    },
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityPartNodeConnection",
      "kind": "LinkedField",
      "name": "activityParts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityPartNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ActivityPartNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partType",
                  "storageKey": null
                },
                (v7/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateEditActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ValidateEditActivityPayload",
        "kind": "LinkedField",
        "name": "validateEditActivity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidationResult",
            "kind": "LinkedField",
            "name": "results",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ValidationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserSnapshotNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateEditActivityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ValidateEditActivityPayload",
        "kind": "LinkedField",
        "name": "validateEditActivity",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidationResult",
            "kind": "LinkedField",
            "name": "results",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ValidationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserSnapshotNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a1cd2aaba383d13e9ae82f4ebc9a996",
    "id": null,
    "metadata": {},
    "name": "ValidateEditActivityMutation",
    "operationKind": "mutation",
    "text": "mutation ValidateEditActivityMutation(\n  $input: ValidateEditActivityInput!\n) {\n  validateEditActivity(input: $input) {\n    failedToFetch\n    results {\n      ok\n      errors {\n        module\n        message\n        activity {\n          id\n          activityType\n          start\n          end\n          activityParts {\n            edges {\n              node {\n                id\n                partType\n                start\n                end\n              }\n            }\n          }\n        }\n        user {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e3a6f8e4670f224fbf30a6d12bdc6d8";

export default node;

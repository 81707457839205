import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { modifyRuleGroupSettingModulesMutation as Mutation } from "./__generated__/modifyRuleGroupSettingModulesMutation.graphql";

const mutation = graphql`
  mutation modifyRuleGroupSettingModulesMutation(
    $input: UpdateRuleGroupSettingInput!
  ) {
    updateRuleGroupSetting(input: $input) {
      ok
      ruleGroupSetting {
        settingModules
      }
    }
  }
`;

export const useModifyRuleGroupSettingModulesMutation = () =>
  useMutation<Mutation>(mutation);

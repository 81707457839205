import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import { Success as HasMasterScheduleIcon } from "components/icons";

import { PeriodTimelineItem } from "./PeriodTimelineItem";

type PeriodStatus = "past" | "active" | "future";

function resolvePeriodTitle(periodStatus: PeriodStatus) {
  switch (periodStatus) {
    case "past":
      return "Passerad period";
    case "active":
      return "Aktuell period";
    case "future":
      return "Framtida period";
    default:
      return "Okänd period";
  }
}

type TimelineItemHeaderProps = {
  className?: string;
  periodStatus: PeriodStatus;
  isMaster: boolean;
  schedules: number;
  onDelete: () => void;
};

function TimelineItemHeader({
  className,
  periodStatus,
  isMaster,
  schedules,
  onDelete,
}: TimelineItemHeaderProps) {
  const theme = useTheme();
  function resolvePeriodColor(periodStatus: PeriodStatus) {
    switch (periodStatus) {
      case "past":
        return theme.palette.grey[500];
      case "active":
        return theme.palette.primary.main;
      case "future":
        return theme.palette.secondary.main;
    }
  }

  function handleDelete(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    e.preventDefault();
    onDelete();
  }

  const title = resolvePeriodTitle(periodStatus);
  const subtitle = isMaster
    ? "Fastställd"
    : schedules > 0
      ? `${schedules} utkast`
      : "Tomt";

  const color = resolvePeriodColor(periodStatus);
  const iconSx = { color };

  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      sx={{ bgcolor: alpha(color, 0.4) }}
    >
      {isMaster ? (
        <Tooltip title="Perioden har ett fastställt schema">
          <HasMasterScheduleIcon sx={iconSx} />
        </Tooltip>
      ) : (
        <Box width={23.5} />
      )}

      <Stack sx={{ px: "2px" }}>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Stack>

      <IconButton onClick={handleDelete} sx={{ p: 0 }}>
        <DeleteIcon className="delete-icon" sx={iconSx} />
      </IconButton>
    </Stack>
  );
}

type TimelineItemBodyProps = {
  className?: string;
  title: string;
  item: any;
};

function TimelineItemBody({ className, title, item }: TimelineItemBodyProps) {
  const { start_time, end_time, nr_weeks } = item;
  const startString = DateTime.fromJSDate(start_time).toFormat("dd/MM");
  const endString = DateTime.fromJSDate(end_time).toFormat("dd/MM");
  const durationString = `${startString} - ${endString} (${nr_weeks} veckor)`;

  return (
    <Stack className={className} gap={1} flexGrow={2} py={1}>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body2">{durationString}</Typography>
    </Stack>
  );
}

export function TimelineItemRenderer({ item, itemContext, getItemProps }: any) {
  const {
    title,
    dimensions: { height },
  } = itemContext;

  function onDelete() {
    if (!item?.onDelete) return;
    item.onDelete();
  }

  function resolvePeriodStatus(item: { start_time: number; end_time: number }) {
    const { start_time, end_time } = item;
    const now = Date.now();
    if (now < start_time) {
      return "future";
    }
    if (now > end_time) {
      return "past";
    }
    return "active";
  }

  const { key, ...itemProps } = getItemProps(item.itemProps);

  return (
    <PeriodTimelineItem key={key} {...itemProps} sx={{ maxHeight: height }}>
      <TimelineItemHeader
        className="timeline-item-header"
        periodStatus={resolvePeriodStatus(item)}
        isMaster={item.master}
        schedules={item.schedules}
        onDelete={onDelete}
      />
      <TimelineItemBody
        className="timeline-item-body"
        title={title}
        item={item}
      />
    </PeriodTimelineItem>
  );
}

import type { ReactNode } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";

import type { RecurrenceRuleType } from "components/blocked_time_slot/common/Recurrence/types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FloatingAccordion";

import RuleOrDateItem from "./RuleOrDateItem/RuleOrDateItem";

type Props = {
  expanded: boolean;
  onExpand: () => void;
  onDelete: () => void;
  summary: ReactNode;
  item: RecurrenceRuleType;
  onChange: (value: RecurrenceRuleType) => void;
  durationMs: number;
};

export default function RulesAndDatesAccordion({
  expanded,
  onExpand,
  onDelete,
  summary,
  item,
  onChange,
  durationMs,
}: Props) {
  return (
    <Accordion
      expanded={expanded}
      onChange={onExpand}
      sx={{ borderColor: "ture.20" }}
    >
      <AccordionSummary sx={{ bgcolor: "ture.10" }}>
        <Stack
          gap={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          width="100%"
        >
          {summary}
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <RuleOrDateItem
          value={item}
          onChange={onChange}
          durationMs={durationMs}
        />
      </AccordionDetails>
    </Accordion>
  );
}

import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const DemandCompetenceTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Kompetensbehov</b>?
  </InfoTitle>
);

const DemandCompetenceContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export const DemandCompetenceInfo = () => (
  <InfoModalProvider>
    <InfoButtonAndModal title={<DemandCompetenceTitle />}>
      <DemandCompetenceContent />
    </InfoButtonAndModal>
  </InfoModalProvider>
);

import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import {
  Free1A,
  Free1C,
  Free1Info as Info,
} from "components/setting/common/forms";

const MODULE_NAME = "FreeWeekends";

type TitleProps = { name: string };
type Props = TitleProps & {
  periodLengthWeeks: number;
  readOnly?: boolean;
};

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande helgvila för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

export function Free1Form({
  periodLengthWeeks,
  readOnly,
  ...titleProps
}: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title {...titleProps} />}
        />
        <Stack gap={5}>
          <Free1A
            periodLengthWeeks={periodLengthWeeks || 0}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Free1C
            periodLengthWeeks={periodLengthWeeks || 0}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
      </Stack>
    </SettingBox>
  );
}

import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useConnectionToArray } from "relay-help/arrays";

import {
  CompetencesCard,
  CompetencesListContent,
} from "components/group_settings";

import type { ScheduleGroupSettingsCompetenceList_competences$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment ScheduleGroupSettingsCompetenceList_competences on CompetenceSnapshotNodeConnection {
    edges {
      node {
        name
        color
      }
    }
  }
`;

export function ScheduleGroupSettingsCompetenceList({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const competences = useConnectionToArray(data);

  return (
    <CompetencesCard>
      <CompetencesListContent competences={competences} />
    </CompetencesCard>
  );
}

import { addTablePreset } from "utils/localStorage";

import { PresetForm } from "./PresetForm";
import type { CreateProps, FormValues } from "./types";

export function CreatePresetForm({ tableKey, ...props }: CreateProps) {
  const initialValues: FormValues = {
    key: "",
    name: "",
    columns: [],
    ...(props.initialValues || {}),
  };
  async function onSubmit(values: FormValues) {
    const newPreset = {
      key: values.name.toLowerCase(),
      name: values.name,
      columns: values.columns,
    };
    addTablePreset(tableKey, newPreset);
    props.onClose();
  }

  return (
    <PresetForm
      {...props}
      initialValues={initialValues}
      onSubmit={onSubmit}
      title="Skapa ny kolumngrupp"
      buttonLabel="Skapa"
    />
  );
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { ArchiveTeamMutation as Mutation } from "./types";

const mutation = graphql`
  mutation ArchiveTeamMutation($id: ID!) {
    archiveTeam(input: { id: $id }) {
      ok
      team {
        id
        isActive
        archivedAt
      }
    }
  }
`;

export const useArchiveTeam = () => useMutation<Mutation>(mutation);

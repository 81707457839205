import type { ActivityFormValues, ActivityPartFormValues } from "../types";

export function checkIsPopulated(values: any) {
  // Check that values are populated before calling server validation
  // (to save some requests).
  const isPopulated =
    values.start && values.end && values.userId && values.activityType;
  return isPopulated;
}

export function checkStartEnd(values: any) {
  // Start and end times
  return Date.parse(values.end || "") < Date.parse(values.start || "");
}

export function activityPartsHaveChanged(
  prevActivityParts: ActivityPartFormValues[],
  activityParts: ActivityPartFormValues[],
) {
  // Check that activity parts have changed
  if (!prevActivityParts) return true;
  if (prevActivityParts?.length !== activityParts?.length) {
    return true;
  }
  for (let i = 0; i < prevActivityParts.length; i++) {
    const prevPart = prevActivityParts[i];
    const part = activityParts[i];
    if (
      prevPart.start !== part.start ||
      prevPart.end !== part.end ||
      prevPart.partType !== part.partType
    ) {
      return true;
    }
  }
  return false;
}

export function checkHasChanged(
  lastValidatedValues: ActivityFormValues,
  values: ActivityFormValues,
) {
  // Check that values have changed from last validation
  const hasChanged =
    !lastValidatedValues ||
    lastValidatedValues?.start !== values.start ||
    lastValidatedValues?.end !== values.end ||
    lastValidatedValues?.breakTime !== values.breakTime ||
    lastValidatedValues?.userId !== values.userId ||
    lastValidatedValues?.activityType !== values.activityType;
  const partsChanged = activityPartsHaveChanged(
    lastValidatedValues?.activityParts,
    values?.activityParts,
  );
  return hasChanged || partsChanged;
}

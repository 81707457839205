import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const Rhythm2InfoTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Sammanhängande ledighet</b>?
  </InfoTitle>
);

const Rhythm2InfoContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export function Rhythm2Info() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal title={<Rhythm2InfoTitle />}>
        <Rhythm2InfoContent />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { weekendRestSetting } from "settings/WeekendRest/WeekendRestSetting";
import { optimizationRuleUrl } from "utils/urls";

import { InfoButtonAndModal, InfoModalProvider } from "components/layout";

import type { GroupSettingCardProps } from "./GroupSettingCard";
import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<GroupSettingCardProps, "title" | "action">;

const TITLE = "Definitioner";

const Info = () => {
  const currentTeamGroup = useCurrentTeamGroup();
  const settingUrl = optimizationRuleUrl(
    currentTeamGroup.id,
    weekendRestSetting,
  );
  return (
    <InfoModalProvider>
      <InfoButtonAndModal
        title="Hur fungerar definitionerna?"
        buttonLabel="Hur fungerar definitionerna?"
      >
        <Stack gap={3}>
          <Stack gap={1}>
            <Typography variant="h4">
              Definition av Dag/Kväll/Natt/Dygn
            </Typography>
            <Typography>
              Här kan du styra dag/kväll/natt-klassificeringen av passtyper. Ett
              pass klassas som ett dagspass om majoriteten av passets timmar
              infaller under dagen (och vice versa för kvällspass och nattpass).
              Om lika många timmar infaller under dag, kväll eller natt, så
              kommer passet i första hand klassas till den dygnsdel där passet
              startar, därefter i turordning dag, kväll och sist natt. Passen i{" "}
              <Link to={"/shifts"}>Passprofilen</Link> klassificeras automatiskt
              utifrån denna definition.
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Typography variant="h4">Lediga helgens start och slut</Typography>
            <Typography>
              Här kan du styra när den lediga helgen ska starta respektive
              sluta. Optimeringsreglerna som styr hur ofta och när de lediga
              helgerna ska infalla hittar du <Link to={settingUrl}>här</Link>.
            </Typography>
          </Stack>
        </Stack>
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
};

export function DefinitionsCard(props: Props) {
  return <GroupSettingCard title={TITLE} action={<Info />} {...props} />;
}

DefinitionsCard.id = "definitions" as const;
DefinitionsCard.title = TITLE;

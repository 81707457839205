import type { Preset } from "types/MaterialReactTable";
import { addTablePreset, removeTablePreset } from "utils/localStorage";

import { PresetForm } from "./PresetForm";
import type { EditProps, FormValues } from "./types";

export function EditPresetForm({
  tableKey,
  preset,
  columns,
  ...props
}: EditProps) {
  const initialValues: FormValues = {
    key: preset.key ?? "",
    name: preset.name ?? "",
    columns: preset.columns || [],
  };

  async function onSubmit(values: FormValues) {
    const newPreset: Preset = {
      key: values.name.toLowerCase(),
      name: values.name,
      columns: values.columns,
    };
    removeTablePreset(tableKey, initialValues.key);
    addTablePreset(tableKey, newPreset);
    props.onClose();
  }

  return (
    <PresetForm
      {...props}
      initialValues={initialValues}
      onSubmit={onSubmit}
      title={`Redigera vyn "${initialValues.name}"`}
      buttonLabel="Spara"
      columns={columns.filter((x) => x.key !== preset.key)}
    />
  );
}

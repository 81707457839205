import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultTeamGroupSubmitFunction,
} from "settings/common/optimizationSetting";
import { WeightTeamGroupOnlyForm } from "settings/common/TeamGroupOnlyForms";
import * as yup from "yup";

import { consecutiveFreeDaysWeightChoices } from "../../components/setting/team_group/setting_boxes/constants";

import {
  ConsecutiveRestSettingRuleGroup_fragment$data as RuleGroupData,
  ConsecutiveRestSettingRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/ConsecutiveRestSettingRuleGroup_fragment.graphql";
import {
  ConsecutiveRestSettingTeamGroup_fragment$data as TeamGroupData,
  ConsecutiveRestSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/ConsecutiveRestSettingTeamGroup_fragment.graphql";
import {
  ConsecutiveRestSettingUserSetting_fragment$data as UserSettingData,
  ConsecutiveRestSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/ConsecutiveRestSettingUserSetting_fragment.graphql";
import { ConsecutiveRestForm } from "./ConsecutiveRestForm";

const teamGroupFragment = graphql`
  fragment ConsecutiveRestSettingTeamGroup_fragment on SettingNode {
    id
    maxConsecutiveFreeDays
    maxConsecutiveFreeDaysSoft
    minConsecutiveFreeDays
    minConsecutiveFreeDaysSoft
    consecutiveFreeDaysWeight
    constraintModules
  }
`;

const userSettingFragment = graphql`
  fragment ConsecutiveRestSettingUserSetting_fragment on UserSettingNode {
    id
    maxConsecutiveFreeDays
    maxConsecutiveFreeDaysSoft
    minConsecutiveFreeDays
    minConsecutiveFreeDaysSoft
    settingModules
  }
`;

const ruleGroupFragment = graphql`
  fragment ConsecutiveRestSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    maxConsecutiveFreeDays
    maxConsecutiveFreeDaysSoft
    minConsecutiveFreeDays
    minConsecutiveFreeDaysSoft
    settingModules
  }
`;

const SETTING_NAME = "Sammanhängande ledighet";
const MODULE_NAME = "ConsecutiveFreeDays";
const SETTING_URL_ID = "cons-rest";

type WeekendRestFormValues = Omit<
  TeamGroupData,
  " $fragmentType" | "consecutiveFreeDaysWeight"
>;

type WeekendRestTeamGroupFormValues = Pick<
  TeamGroupData,
  "id" | "consecutiveFreeDaysWeight" | "constraintModules"
>;

const validationSchema = yup.object().shape({
  id: yup.string(),
  maxConsecutiveFreeDays: yup
    .number()
    .min(1, "Måste vara minst 1")
    .max(20, "Måste vara högst 20")
    .required("Får ej vara tomt"),
  maxConsecutiveFreeDaysSoft: yup.boolean().required("Får ej vara tomt"),
  minConsecutiveFreeDays: yup
    .number()
    .min(1, "Måste vara minst 1")
    .max(20, "Måste vara högst 20")
    .required("Får ej vara tomt"),
  minConsecutiveFreeDaysSoft: yup.boolean().required("Får ej vara tomt"),
  constraintModules: yup.array().of(yup.string()),
});

const teamGroupValidationSchema = yup.object().shape({
  consecutiveFreeDaysWeight: yup
    .string()
    .oneOf(consecutiveFreeDaysWeightChoices)
    .required(),
});

function baseConvertDataToInitialValues(
  data: TeamGroupData | UserSettingData | RuleGroupData,
) {
  return {
    id: data.id,
    maxConsecutiveFreeDays: data.maxConsecutiveFreeDays ?? 2,
    maxConsecutiveFreeDaysSoft: data.maxConsecutiveFreeDaysSoft ?? true,
    minConsecutiveFreeDays: data.minConsecutiveFreeDays ?? 2,
    minConsecutiveFreeDaysSoft: data.minConsecutiveFreeDaysSoft ?? true,
  };
}

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): WeekendRestFormValues {
  return {
    ...baseConvertDataToInitialValues(teamGroupData),
    constraintModules: teamGroupData.constraintModules,
  };
}
function convertUserOrRuleGroupDataToInitialValues(
  data: UserSettingData | RuleGroupData,
): WeekendRestFormValues {
  return {
    ...baseConvertDataToInitialValues(data),
    constraintModules: data.settingModules,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): WeekendRestTeamGroupFormValues {
  return {
    id: teamGroupData.id,
    consecutiveFreeDaysWeight: teamGroupData.consecutiveFreeDaysWeight,
    constraintModules: teamGroupData.constraintModules,
  };
}

function renderComponent(
  initialValues: WeekendRestFormValues,
  onSubmit: (formValues: WeekendRestFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ConsecutiveRestForm />
    </BaseSettings>
  );
}

function renderTeamGroupComponent(
  initialValues: WeekendRestTeamGroupFormValues,
  onSubmit: (formValues: WeekendRestTeamGroupFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <WeightTeamGroupOnlyForm
        weightFieldName="consecutiveFreeDaysWeight"
        moduleName={MODULE_NAME}
      />
    </BaseSettings>
  );
}

export const consecutiveRestSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  RuleGroupKey,
  WeekendRestFormValues,
  {},
  WeekendRestTeamGroupFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
    renderComponent: renderTeamGroupComponent,
  },
};

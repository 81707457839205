import { useMemo } from "react";
import { Chip, ChipProps, useTheme } from "@mui/material";

type Team = {
  id: string;
  name: string;
  color: string;
};

type Props = ChipProps & {
  team: Team;
  getLabel?: (team: Team) => string;
};

export function TeamChip({
  team,
  getLabel = (team: Team) => team.name,
  size = "small",
  ...p
}: Props) {
  const theme = useTheme();
  const label = getLabel(team);

  const colorSx = useMemo(
    () => ({
      backgroundColor: team?.color || "lightgray",
      color: team?.color ? theme.palette.getContrastText(team.color) : "black",
    }),
    [team, theme],
  );

  const sx = useMemo(
    () => ({
      ...colorSx,
      ...(p?.sx ?? {}),
      "& .MuiChip-deleteIcon": {
        color: colorSx.color,
      },
    }),
    [colorSx, p?.sx],
  );

  return <Chip {...p} size={size} label={label} sx={sx} />;
}

import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { DayBreakAlternativeSelect } from "components/setting/common/components/DayBreakAlternativeSelect";

import { dayRestSetting } from "../DayRestSetting";

type Props = {
  readOnly?: boolean;
};

export function DayRest({ readOnly = false }: Props) {
  const disabled = !useIsConstraintModuleEnabled(dayRestSetting.moduleName);
  return (
    <PaperWrapper>
      <Stack gap={1}>
        <Typography variant="h4">Dygnsbryt</Typography>
        <Typography>Detta fält styr när dygnsbrytet ska infalla.</Typography>
        <DayBreakAlternativeSelect
          name="dayBreakAlternatives"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

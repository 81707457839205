import { useMemo } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ResponsivePie as Pie } from "@nivo/pie";
import useFormikState from "hooks/useFormikState";
import type { DurationLikeObject } from "luxon";
import { DateTime } from "luxon";

import TooltipBox from "components/common/TooltipBox";

import type { PieProps } from "./types";

export default function DurationPieChart({
  height,
  width,
  position,
  hideCount,
  sx,
}: PieProps & { hideCount?: boolean }) {
  const theme = useTheme();
  const { value: morningStartsNightEnds } = useFormikState(
    "morningStartsNightEnds",
  );
  const { value: eveningStartsMorningEnds } = useFormikState(
    "eveningStartsMorningEnds",
  );
  const { value: nightStartsEveningEnds } = useFormikState(
    "nightStartsEveningEnds",
  );
  function diff(
    start: string,
    end: string,
    as: keyof DurationLikeObject = "hours",
  ): number {
    const s = DateTime.fromFormat(start, "T");
    let e = DateTime.fromFormat(end, "T");
    if (e < s) {
      e = e.plus({ days: 1 });
    }
    return Math.round(e.diff(s).as(as));
  }
  const data = [
    {
      id: "Dag",
      label: "Dag",
      value: diff(morningStartsNightEnds, eveningStartsMorningEnds) || 0,
      color: theme.palette.klippa.main,
    },
    {
      id: "Kväll",
      label: "Kväll",
      value: diff(eveningStartsMorningEnds, nightStartsEveningEnds) || 0,
      color: theme.palette.manet.main,
    },
    {
      id: "Natt",
      label: "Natt",
      value: diff(nightStartsEveningEnds, morningStartsNightEnds) || 0,
      color: theme.palette.primary.main,
    },
  ];
  const angleOffset = useMemo(
    () =>
      (-DateTime.now()
        .startOf("day")
        .diff(DateTime.fromFormat(morningStartsNightEnds, "T"))
        .as("hours") /
        24) *
      360,
    [morningStartsNightEnds, eveningStartsMorningEnds, nightStartsEveningEnds], // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <Box position={position} height={height} width={width} sx={sx}>
      <Pie
        data={data}
        colors={data.map((d) => d.color)}
        startAngle={angleOffset}
        endAngle={angleOffset + 360}
        margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
        enableArcLinkLabels={false}
        arcLabelsTextColor={{
          from: "textColor",
          modifiers: [["darker", 1.6]],
        }}
        theme={{ text: { fontFamily: "Nunito" } }}
        layers={
          hideCount
            ? ["arcs", "arcLinkLabels", "legends"]
            : ["arcs", "arcLinkLabels", "arcLabels", "legends"]
        }
        tooltip={(point) => {
          return (
            <TooltipBox>
              <Stack direction="row" gap={1} minWidth={130}>
                <Box
                  width={15}
                  height={15}
                  sx={{
                    my: "2px",
                    borderRadius: "100%",
                    backgroundColor: point.datum.color,
                  }}
                />
                <Typography>
                  {point.datum.data.label}: {point.datum.formattedValue} timmar
                </Typography>
              </Stack>
            </TooltipBox>
          );
        }}
      />
    </Box>
  );
}

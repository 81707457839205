import Typography from "@mui/material/Typography";

import { PageHeader, PageWrapper } from "components/layout/PageWrapper";

function Header() {
  return <PageHeader title="Sidan finns ej" />;
}

export function Page404() {
  return (
    <PageWrapper header={<Header />}>
      <Typography>
        Kontrollera att du skrev in URL:en korrekt och att du har behörighet
        till innehållet.
      </Typography>
    </PageWrapper>
  );
}

import type { ComponentProps } from "react";
import { Suspense, useCallback, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { Box, Container, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  useChangeTitle,
  useCurrentTeamGroup,
  useGroupSettingsParams,
  useScrollToHash,
} from "hooks";
import {
  connectionToArray,
  ExtractNode,
  isNonNull,
  Unwrap,
} from "relay-help/arrays";

import DeleteModal, { useDeleteModal } from "components/common/DeleteModal";
import {
  Advanced,
  AdvancedCard,
  CompetencesCard,
  CompetencesList,
  Comprest,
  ComprestCard,
  CreateRuleGroupForm,
  CreateTeamForm,
  Definitions,
  DefinitionsCard,
  DemandProfile,
  DemandProfileCard,
  EditRuleGroupForm,
  EditTeamForm,
  ResourcePool,
  ResourcePoolCard,
  RuleGroupsCard,
  RuleGroupsList,
  SchedulePeriod,
  SchedulePeriodCard,
  SidePanel,
  TeamsCard,
  TeamsList,
  Timebank,
  TimebankCard,
  useDeleteRuleGroup,
  useDeleteTeam,
} from "components/group_settings";
import type { TocItem } from "components/group_settings/TableOfContents";
import { PageWrapper } from "components/layout/PageWrapper";
import { useSnackbar } from "components/Snackbar";

import type {
  GroupSettingsQuery as Query,
  GroupSettingsQuery$data as Data,
} from "./types";

type QueryRef = PreloadedQuery<Query>;

type ContentProps = {
  queryRef: QueryRef;
  onRuleGroupDelete: ComponentProps<typeof RuleGroupsList>["onDelete"];
  onTeamDelete: ComponentProps<typeof TeamsList>["onDelete"];
};

type ShiftPart = ExtractNode<
  NonNullable<Unwrap<Data["shiftTypes"]>>["shiftParts"]
>;

const query = graphql`
  query GroupSettingsQuery($teamGroupId: ID!) {
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      ...Definitions_fragment
      ...DemandProfile_fragment
      ...SchedulePeriod_fragment
      ...Timebank_fragment
      ...ResourcePool_fragment
      ...Comprest_fragment
      ...Advanced_fragment
    }
    teams(groupId: $teamGroupId) {
      ...TeamsList_teams
    }
    competences {
      ...CompetencesList_competences
    }
    ruleGroups(teamGroupId: $teamGroupId) {
      ...RuleGroupsList_ruleGroups
    }
    shiftTypes(teamGroupId: $teamGroupId) {
      id
      name
      start
      end
      shiftParts {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export type ValidSettingItem =
  | typeof TeamsCard.id
  | typeof CompetencesCard.id
  | typeof RuleGroupsCard.id
  | typeof DemandProfileCard.id
  | typeof SchedulePeriodCard.id
  | typeof DefinitionsCard.id
  | typeof TimebankCard.id
  | typeof ResourcePoolCard.id
  | typeof ComprestCard.id
  | typeof AdvancedCard.id;

const GROUP_SETTING_ITEMS: TocItem<ValidSettingItem>[] = [
  { id: TeamsCard.id, label: TeamsCard.title },
  { id: CompetencesCard.id, label: CompetencesCard.title },
  { id: RuleGroupsCard.id, label: RuleGroupsCard.title },
  { id: DemandProfileCard.id, label: DemandProfileCard.title },
  { id: SchedulePeriodCard.id, label: SchedulePeriodCard.title },
  { id: DefinitionsCard.id, label: DefinitionsCard.title },
  { id: TimebankCard.id, label: TimebankCard.title },
  { id: ResourcePoolCard.id, label: ResourcePoolCard.title },
  { id: ComprestCard.id, label: ComprestCard.title },
  { id: AdvancedCard.id, label: AdvancedCard.title },
];

export function GroupSettings() {
  const {
    queryRef,
    teamToDelete,
    clearTeamToDelete,
    onTeamDelete,
    confirmTeamDelete,
    ruleGroupToDelete,
    clearRuleGroupToDelete,
    onRuleGroupDelete,
    confirmRuleGroupDelete,
    newTeam,
    closeCreateTeam,
    teamGroupId,
    editTeam,
    closeEditTeam,
    newRuleGroup,
    closeCreateRuleGroup,
    editRuleGroup,
    closeEditRuleGroup,
  } = useGroupSettings();

  return (
    <>
      <PageWrapper>
        <Stack direction="row" gap={1}>
          <Suspense fallback={<p>Laddar...</p>}>
            {!!queryRef && (
              <>
                <Box sx={{ width: 200, mr: 4 }}>
                  {/*
                  Sidepanel needs to be suspended until Card elements have been rendered
                  in order for useInteresctionObserver() to work.
                  */}
                  <SidePanel
                    items={GROUP_SETTING_ITEMS}
                    gap={3}
                    position="fixed"
                    top={32}
                  />
                </Box>
                <Stack component={Container} gap={5}>
                  <Content
                    queryRef={queryRef}
                    onRuleGroupDelete={onRuleGroupDelete}
                    onTeamDelete={onTeamDelete}
                  />
                </Stack>
              </>
            )}
          </Suspense>
        </Stack>
      </PageWrapper>
      <CreateTeamForm
        open={newTeam}
        onClose={closeCreateTeam}
        initialValues={{ groupId: teamGroupId }}
      />
      {!!editTeam && (
        <EditTeamForm open={!!editTeam} onClose={closeEditTeam} id={editTeam} />
      )}
      {!!teamToDelete && (
        <DeleteModal
          show={!!teamToDelete}
          onHide={clearTeamToDelete}
          buttonText="Radera"
          deleteTitle="Radera avdelning?"
          deleteMessage={`Är du säker på att du vill radera avdelningen "${teamToDelete?.name}"?
            ${
              (teamToDelete?.members?.length ?? 0) > 0
                ? ` Den har ${teamToDelete.members.length} personer kopplade till sig. En person utan avdelning kommer inte att schemaläggas.`
                : ""
            }`}
          onDeleteClick={confirmTeamDelete}
        />
      )}
      <CreateRuleGroupForm
        open={newRuleGroup}
        onClose={closeCreateRuleGroup}
        teamGroupId={teamGroupId}
      />
      {!!editRuleGroup && (
        <EditRuleGroupForm
          open={!!editRuleGroup}
          onClose={closeEditRuleGroup}
          id={editRuleGroup}
        />
      )}
      {!!ruleGroupToDelete && (
        <DeleteModal
          show={!!ruleGroupToDelete}
          onHide={clearRuleGroupToDelete}
          buttonText="Radera"
          deleteTitle="Radera regelgrupp?"
          deleteMessage={`Är du säker på att du vill radera regelgruppen "${ruleGroupToDelete?.name}"?`}
          onDeleteClick={confirmRuleGroupDelete}
        />
      )}
    </>
  );
}

function Content({ queryRef, onRuleGroupDelete, onTeamDelete }: ContentProps) {
  const {
    data,
    createTeam,
    setEditTeam,
    createRuleGroup,
    setEditRuleGroup,
    shifts,
  } = useContent(queryRef);
  const setting = data.settingForTeamGroup;

  return (
    <>
      <TeamsCard id={TeamsCard.id} openCreateTeam={createTeam}>
        {!!data.teams && (
          <TeamsList
            fragmentRef={data.teams}
            setEdit={setEditTeam}
            onDelete={onTeamDelete}
          />
        )}
      </TeamsCard>
      <CompetencesCard id={CompetencesCard.id}>
        {!!data.competences && (
          <CompetencesList fragmentRef={data.competences} />
        )}
      </CompetencesCard>
      <RuleGroupsCard id={RuleGroupsCard.id} openCreate={createRuleGroup}>
        {!!data.ruleGroups && data.ruleGroups[0] && (
          <RuleGroupsList
            fragmentRef={data.ruleGroups}
            setEdit={setEditRuleGroup}
            onDelete={onRuleGroupDelete}
          />
        )}
      </RuleGroupsCard>
      <DemandProfileCard id={DemandProfileCard.id}>
        {!!setting && <DemandProfile fragmentRef={setting} />}
      </DemandProfileCard>
      <SchedulePeriodCard id={SchedulePeriodCard.id}>
        {!!setting && <SchedulePeriod fragmentRef={setting} />}
      </SchedulePeriodCard>
      <DefinitionsCard id={DefinitionsCard.id}>
        {!!setting && <Definitions fragmentRef={setting} />}
      </DefinitionsCard>
      <TimebankCard id={TimebankCard.id}>
        {!!setting && <Timebank fragmentRef={setting} shiftTypes={shifts} />}
      </TimebankCard>
      <ResourcePoolCard id={ResourcePoolCard.id}>
        {!!setting && <ResourcePool fragmentRef={setting} />}
      </ResourcePoolCard>
      <ComprestCard id={ComprestCard.id}>
        {!!setting && <Comprest fragmentRef={setting} />}
      </ComprestCard>
      <AdvancedCard id={AdvancedCard.id}>
        {!!setting && <Advanced fragmentRef={setting} />}
      </AdvancedCard>
    </>
  );
}

function useGroupSettings() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id ?? "";
  const {
    newRuleGroup,
    newTeam,
    editRuleGroup,
    editTeam,
    setNewRuleGroup,
    setNewTeam,
    setEditRuleGroup,
    setEditTeam,
  } = useGroupSettingsParams();
  const [deleteRuleGroup] = useDeleteRuleGroup();
  const [deleteTeam] = useDeleteTeam();
  const { addSnack } = useSnackbar();

  const deleteTeamFn = useCallback(
    async (id: string) => {
      await deleteTeam({ variables: { id } });
    },
    [deleteTeam],
  );
  const deleteRuleGroupFn = useCallback(
    async (id: string) => {
      await deleteRuleGroup({ variables: { id } });
    },
    [deleteRuleGroup],
  );
  const catchError = useCallback(
    (msg: string) => (e: Error) => {
      console.error(e);
      addSnack({ message: msg, severity: "error" });
    },
    [addSnack],
  );
  const {
    toDelete: teamToDelete,
    onHide: clearTeamToDelete,
    setToDelete: onTeamDelete,
    confirmDelete: confirmTeamDelete,
  } = useDeleteModal<{ id: string; name: string; members: [] }>(
    deleteTeamFn,
    catchError("Kunde inte radera avdelningen"),
  );
  const {
    toDelete: ruleGroupToDelete,
    onHide: clearRuleGroupToDelete,
    setToDelete: onRuleGroupDelete,
    confirmDelete: confirmRuleGroupDelete,
  } = useDeleteModal<{ id: string; name: string }>(
    deleteRuleGroupFn,
    catchError("Kunde inte radera regelgruppen"),
  );

  const closeCreateTeam = useCallback(() => setNewTeam(false), [setNewTeam]);
  const closeEditTeam = useCallback(() => setEditTeam(null), [setEditTeam]);
  const closeCreateRuleGroup = useCallback(
    () => setNewRuleGroup(false),
    [setNewRuleGroup],
  );
  const closeEditRuleGroup = useCallback(
    () => setEditRuleGroup(null),
    [setEditRuleGroup],
  );

  useEffect(() => {
    if (!teamGroupId) return;
    loadQuery({ teamGroupId });
  }, [teamGroupId, loadQuery]);

  useChangeTitle("Enhet");

  return {
    queryRef,
    teamToDelete,
    clearTeamToDelete,
    onTeamDelete,
    confirmTeamDelete,
    ruleGroupToDelete,
    clearRuleGroupToDelete,
    onRuleGroupDelete,
    confirmRuleGroupDelete,
    newTeam,
    closeCreateTeam,
    teamGroupId,
    editTeam,
    closeEditTeam,
    newRuleGroup,
    closeCreateRuleGroup,
    editRuleGroup,
    closeEditRuleGroup,
  };
}

function useContent(queryRef: QueryRef) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const { setNewRuleGroup, setEditRuleGroup, setNewTeam, setEditTeam } =
    useGroupSettingsParams();
  const createTeam = useCallback(() => setNewTeam(true), [setNewTeam]);
  const createRuleGroup = useCallback(
    () => setNewRuleGroup(true),
    [setNewRuleGroup],
  );

  useScrollToHash();

  const shifts = useMemo(
    () =>
      data.shiftTypes.filter(isNonNull).map((x) => ({
        ...x,
        shiftParts: connectionToArray<ShiftPart>(x.shiftParts),
      })),
    [data.shiftTypes],
  );

  return {
    data,
    createTeam,
    createRuleGroup,
    setEditRuleGroup,
    setEditTeam,
    shifts,
  };
}

import { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  listPermissions,
  listUserPermissions,
  updateUserPermissions,
} from "api/api";
import graphql from "babel-plugin-relay/macro";
import { useRequireMe } from "hooks/Me";

import { useSnackbar } from "components/Snackbar";

import { AdminPermissionsQuery } from "./__generated__/AdminPermissionsQuery.graphql";

export interface UserPermission {
  permission_name: string;
  permission: string;
}

export interface Permission {
  permission_name: string;
  allowed_states: string[];
}

const query = graphql`
  query AdminPermissionsQuery {
    allUsers {
      id
      fullName
      username
    }
  }
`;

type ContentProps = { queryRef: PreloadedQuery<AdminPermissionsQuery> };

function Content({ queryRef }: ContentProps) {
  const { t } = useTranslation();
  const { addSnack } = useSnackbar();
  const { userData: me } = useRequireMe();
  const myID = atob(me.id).split(":")[1];
  const data = usePreloadedQuery<AdminPermissionsQuery>(query, queryRef);
  const [selectedUser, setSelectedUser] = useState(me?.id || "");
  const [allPermissions, setAllPermissions] = useState<Permission[]>([]);
  const [currentUserAdminList, setcurrentUserAdminList] = useState<string[]>(
    [],
  );
  const [updatedPermissions, setUpdatedPermissions] = useState<
    UserPermission[]
  >([]);

  const fetchUserAdminList = useCallback(async (id: string) => {
    try {
      const userList = await listUserPermissions(id);

      setcurrentUserAdminList(
        userList
          .filter((permission) => permission.permission === "ADMIN")
          .map((permission) => permission.permission_name),
      );
    } catch (error) {
      console.error("Error fetching the users permissions:", error);
    }
  }, []);

  const fetchAllPermissions = useCallback(async () => {
    try {
      const permissions = await listPermissions();
      setAllPermissions(permissions);
    } catch (error) {
      console.error("Error fetching all permissions:", error);
    }
  }, []);

  const fetchUserPermissions = useCallback(async (userId: string) => {
    try {
      const userDBID = atob(userId).split(":")[1];
      const userPermissions = await listUserPermissions(userDBID);
      setUpdatedPermissions(userPermissions);
    } catch (error) {
      console.error("Error fetching user permissions:", error);
    }
  }, []);

  useEffect(() => {
    fetchAllPermissions();
    fetchUserPermissions(me.id);
    fetchUserAdminList(myID);
  }, [
    fetchAllPermissions,
    fetchUserPermissions,
    fetchUserAdminList,
    myID,
    me.id,
  ]);

  function handleChange(e: SelectChangeEvent) {
    setSelectedUser(e.target.value as string);
    fetchUserPermissions(e.target.value);
  }

  const handlePermissionChange = (permissionName: string, newValue: string) => {
    setUpdatedPermissions((prevPermissions) =>
      prevPermissions?.map((perm) =>
        perm.permission_name === permissionName
          ? { ...perm, permission: newValue }
          : perm,
      ),
    );
    const exists = updatedPermissions?.some(
      (perm) => perm.permission_name === permissionName,
    );
    if (!exists) {
      setUpdatedPermissions((prevPermissions) => [
        ...(prevPermissions || []),
        { permission_name: permissionName, permission: newValue },
      ]);
    }
  };

  const handleSave = async () => {
    try {
      const userDBID = atob(selectedUser).split(":")[1];
      await updateUserPermissions(userDBID, updatedPermissions);
      addSnack({ message: "Ändringarna sparades", severity: "success" });
    } catch (error) {
      console.error("Error saving user permissions:", error);
      fetchUserPermissions(selectedUser);
      addSnack({
        message: "Det gick inte att spara ändringarna",
        severity: "error",
      });
    }
  };

  const handleCancel = async () => {
    try {
      fetchUserPermissions(selectedUser);
    } catch (error) {
      fetchUserPermissions(selectedUser);
      addSnack({
        message: "Det gick inte att återställa ändringarna",
        severity: "error",
      });
    }
  };

  function getMergedPermissions() {
    if (!allPermissions) return [];

    return allPermissions.map((perm) => {
      const userPerm = updatedPermissions?.find(
        (up) => up.permission_name === perm.permission_name,
      );
      return {
        permission_name: perm.permission_name,
        permission: userPerm ? userPerm.permission : "NO", // Default to "No"
      };
    });
  }

  const mergedPermissions = getMergedPermissions();

  return (
    <>
      <Paper variant="box" sx={{ p: 1.5 }}>
        <Stack gap={2}>
          <Grid
            container
            pt={0.75}
            pl={1.5}
            direction="row"
            justifyContent="start"
            alignItems={"center"}
          >
            <Grid item xs={3}>
              <Typography variant={"h2"}>Ställ in behörigheter för:</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: 540 }}>
                <Select value={selectedUser} onChange={handleChange}>
                  {data.allUsers.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <Stack gap={1.5}>
            {mergedPermissions.map((permission) => (
              <>
                <Grid
                  container
                  pl={1.5}
                  direction={"row"}
                  justifyContent="start"
                  alignItems={"center"}
                >
                  <Grid item xs={3}>
                    <Typography variant={"h4"}>
                      {t(permission.permission_name)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl key={permission.permission_name} fullWidth>
                      <Select
                        value={permission.permission}
                        readOnly={
                          !currentUserAdminList.includes(
                            permission.permission_name,
                          )
                        }
                        onChange={(e) =>
                          handlePermissionChange(
                            permission.permission_name,
                            e.target.value,
                          )
                        }
                        sx={{
                          width: 240,
                          "&.Mui-readOnly": {
                            backgroundColor: "whitesmoke",
                            pointerEvents: "none",
                            "& .MuiInputBase-input": {
                              color: "gray",
                            },
                          },
                        }}
                      >
                        <MenuItem value="NO">{t("no-access")}</MenuItem>
                        <MenuItem value="READ">{t("read")}</MenuItem>
                        <MenuItem value="WRITE">{t("write")}</MenuItem>
                        <MenuItem value="ADMIN">
                          {t("admin-permission")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ))}
          </Stack>
          <Grid container direction={"row"} justifyContent={"end"} spacing={3}>
            <Grid item xs={2}>
              <Button fullWidth variant="contained" onClick={handleCancel}>
                {t("abort")}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth variant="contained" onClick={handleSave}>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </>
  );
}

export function AdminPermissions() {
  const [queryRef, loadQuery] = useQueryLoader<AdminPermissionsQuery>(query);

  useEffect(() => {
    loadQuery({});
  }, [loadQuery]);

  return (
    <Suspense fallback={"Loading..."}>
      {!!queryRef && <Content queryRef={queryRef} />}
    </Suspense>
  );
}

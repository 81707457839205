import { useCallback, useMemo } from "react";
import type { CSSObject } from "@mui/material";

import type { GenericRenderGetTagProps } from "components/common/selectors/GenericSelect";
import { Chip, GenericSelect } from "components/common/selectors/GenericSelect";
import {
  ganttItemTitleCodeOptions,
  TranslateActivityCategory,
  TranslateActivityColor,
  TranslateActivityType,
} from "components/schedule/types";

type Option = {
  value: string;
  label: string;
  color: string;
  category: string;
};

const activityOptions: Option[] = ganttItemTitleCodeOptions.map((u) => ({
  value: (u as string) || "",
  label: TranslateActivityType(u) || "",
  color: TranslateActivityColor(u) || "#AAA",
  category: TranslateActivityCategory(u) || "",
}));

type Props = {
  value: string[];
  onChange: (activities: string[]) => void;
  sx?: CSSObject;
  disabled?: boolean;
};

export function ActivityListSelect({
  value,
  onChange,
  disabled = false,
  sx = {},
}: Props) {
  const selected = useMemo<Option[]>(
    () => activityOptions.filter((x) => value.includes(x.value)),
    [value],
  );

  const select = useCallback(
    (v: Option[]) => onChange(v.map((x) => x.value)),
    [onChange],
  );

  function renderTags(value: Option[], getTagProps: GenericRenderGetTagProps) {
    return value.map((option: any, index: number) => {
      const { key, ...tagProps } = getTagProps({ index });
      return (
        <Chip
          key={key}
          {...tagProps}
          label={option.label}
          sx={{ color: "white", backgroundColor: option.color }}
          size="small"
        />
      );
    });
  }

  const isOptionEqualToValue = useCallback(
    (option: Option, value: Option | undefined) =>
      option.value === value?.value,
    [],
  );

  return (
    <GenericSelect<Option>
      multiple
      value={selected}
      onChange={useCallback(
        (_e: any, options: Option[] | null) => select(options || []),
        [select],
      )}
      options={activityOptions}
      getOptionLabel={useCallback((option: Option) => option.label, [])}
      isOptionEqualToValue={isOptionEqualToValue}
      filterSelectedOptions={true}
      label="Aktivitetstyper"
      groupBy={useCallback((option: any) => option.category, [])}
      renderTags={useCallback(renderTags, [])}
      sx={sx}
      disabled={disabled}
      noOptionsText="Inga fler aktivitetstyper"
      disableCloseOnSelect
    />
  );
}

import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  useBlockedTimeSlotParams,
  useChangeTitle,
  useCurrentTeamGroup,
} from "hooks";

import {
  BlockedTimeSlotHeader,
  BlockedTimeSlotLabels,
  BlockedTimeSlotTimeline,
  CreateBlockedTimeSlot,
  EditBlockedTimeSlot,
} from "components/blocked_time_slot";
import { BlockedTimeSlotUserSelect as UserSelect } from "components/blocked_time_slot/BlockedTimeSlotUserSelect";
import type { BlockedTimeSlotWithOriginalBlockedTimeSlotId as BlockedTimeSlotTimelineItem } from "components/blocked_time_slot/graphs/BlockedTimeSlotTimeline/types";
import {
  TeamSelect2,
  TeamSelect2FragmentRefProvider,
} from "components/common/selectors/TeamSelect";
import { UserSelectFragmentRefProvider } from "components/common/selectors/UserSelect/UserSelectFragmentRefContext";
import PageWrapper from "components/layout/PageWrapper";
import { BlockedTimeSlotPlaceholder as Placeholder } from "components/loading/pages";

import type { BlockedTimeSlotQuery as Query } from "./types";

const query = graphql`
  query BlockedTimeSlotQuery($id: ID!) {
    currentPeriod(groupId: $id) {
      startDate
      endDate
    }
    usersOnTeamGroup(teamGroupId: $id) {
      ...BlockedTimeSlotTimeline_fragment
      ...BlockedTimeSlotUserSelect_fragment
      ...UserSelect_fragment
    }
    holidays {
      title
      endTime
      startTime
      itemProps {
        className
      }
    }
    teams {
      ...TeamSelect2_fragment
    }
  }
`;

type ContentProps = {
  queryRef: PreloadedQuery<Query>;
};

function Content({ queryRef }: ContentProps) {
  const env = useRelayEnvironment();
  const data = usePreloadedQuery<Query>(query, queryRef);
  const {
    team: teamId,
    setTeam: setTeamId,
    user: userId,
    setUser: setUserId,
    new: create,
    setNew: setCreate,
  } = useBlockedTimeSlotParams();
  const [editingBlockedTimeSlotId, setEditingBlockedTimeSlotId] = useState<
    string | null
  >(null);
  const [copyFrom, setCopyFrom] = useState<BlockedTimeSlotTimelineItem | null>(
    null,
  );
  const id = useCurrentTeamGroup()?.id ?? "";
  const periodStart = useMemo(
    () =>
      data.currentPeriod?.startDate
        ? new Date(data.currentPeriod?.startDate)
        : null,
    [data.currentPeriod?.startDate],
  );
  const periodEnd = useMemo(
    () =>
      data.currentPeriod?.endDate
        ? new Date(data.currentPeriod?.endDate)
        : null,
    [data.currentPeriod?.endDate],
  );

  const onCreateCopyFrom = useCallback(
    (copy: any) => {
      setCopyFrom(copy);
      setCreate(true);
    },
    [setCreate],
  );

  const refresh = useCallback(async () => {
    await fetchQuery(env, query, { id }).toPromise();
  }, [env, id]);

  function onOpenOriginalTimeSlot() {
    const originalId = copyFrom?.originalTimeslotId;
    if (originalId) {
      setCreate(false);
      setCopyFrom(null);
      setEditingBlockedTimeSlotId(originalId);
    }
  }

  const onCloseEditDialog = useCallback(() => {
    setEditingBlockedTimeSlotId(null);
    refresh();
  }, [refresh]);

  return (
    <UserSelectFragmentRefProvider value={data.usersOnTeamGroup}>
      <Stack gap={2}>
        <Stack id="filters" direction="row" gap={2} flexWrap="wrap">
          <TeamSelect2FragmentRefProvider value={data.teams}>
            <TeamSelect2
              onChange={setTeamId}
              filterOnGroupIds={[id]}
              sx={{ minWidth: 200 }}
            />
          </TeamSelect2FragmentRefProvider>
          <UserSelect
            fragmentRef={data.usersOnTeamGroup}
            value={userId}
            onChange={(id) => setUserId(id || "")}
          />
        </Stack>
        <Paper variant="box" sx={{ p: 1.5 }}>
          <BlockedTimeSlotTimeline
            teamGroupId={id}
            fragmentRef={data.usersOnTeamGroup}
            periodStart={periodStart}
            periodEnd={periodEnd}
            holidays={data.holidays?.slice() || []}
            teamId={teamId}
            userId={userId}
            onItemSelect={setEditingBlockedTimeSlotId}
            onCreateCopyFrom={onCreateCopyFrom}
          />
        </Paper>
        <Paper variant="box">
          <BlockedTimeSlotLabels />
        </Paper>
      </Stack>
      <Suspense fallback={null}>
        <CreateBlockedTimeSlot
          open={create}
          onClose={() => {
            setEditingBlockedTimeSlotId(null);
            setCreate(false);
            setCopyFrom(null);
            refresh();
          }}
          copyFrom={copyFrom}
          onOpenOriginalTimeSlot={onOpenOriginalTimeSlot}
        />
        <EditBlockedTimeSlot
          open={!!editingBlockedTimeSlotId}
          onClose={onCloseEditDialog}
          selected={editingBlockedTimeSlotId}
        />
      </Suspense>
    </UserSelectFragmentRefProvider>
  );
}

export function BlockedTimeSlot() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const { setNew: setCreate, clear } = useBlockedTimeSlotParams();
  const id = useCurrentTeamGroup()?.id ?? "";

  useEffect(() => {
    if (!id) return;
    loadQuery({ id });
    clear();
  }, [id, loadQuery, clear]);

  useChangeTitle("Händelser");

  return (
    <PageWrapper
      header={<BlockedTimeSlotHeader onNew={() => setCreate(true)} />}
    >
      <Suspense fallback={<Placeholder />}>
        {!!queryRef && <Content queryRef={queryRef} />}
      </Suspense>
    </PageWrapper>
  );
}

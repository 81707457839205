import { baseDemandSetting } from "./BaseDemand/BaseDemandSetting";
import { clockwiseRotationSetting } from "./ClockwiseRotations/ClockwiseRotationSetting";
import { GenericOptimizationSetting } from "./common/optimizationSetting";
import { competenceDemandSetting } from "./CompetenceDemand/CompetenceDemandSetting";
import { consecutiveRestSetting } from "./ConsecutiveRest/ConsecutiveRestSetting";
import { consecutiveWorkSetting } from "./ConsecutiveWork/ConsecutiveWorkSetting";
import { costSetting } from "./Cost/CostSetting";
import { dayNightCadenceSetting } from "./DayNightCadence/DayNightCadenceSetting";
import { dayNightDistributionSetting } from "./DayNightDistribution/DayNightDistributionSetting";
import { dayNightFairnessSetting } from "./DayNightFairness/DayNightFairnessSetting";
import { dayRestSetting } from "./DayRest/DayRestSetting";
import { hoursAndShiftsSetting } from "./HoursAndShifts/HoursAndShiftsSetting";
import { nightShiftSetting } from "./NightShift/NightShiftSetting";
import { optimizationSetting } from "./Optimization/OptimizationSetting";
import { partTimeSetting } from "./PartTime/PartTimeSetting";
import { requestedDaysOffSetting } from "./RequestedDaysOff/RequestedDaysOffSetting";
import { resourceTimeSetting } from "./ResourceTime/ResourceTimeSetting";
import { responsibilityTimeSetting } from "./ResponsibilityTime/ResponsibilityTimeSetting";
import { restBetweenShiftsSetting } from "./RestBetweenShifts/RestBetweenShiftsSetting";
import { shiftDistributionSetting } from "./ShiftDistribution/ShiftDistributionSetting";
import { smoothResourcesSetting } from "./SmoothResources/SmoothResourcesSetting";
import { splitShiftsSetting } from "./SplitShifts/SplitShiftsSetting";
import { vacationSetting } from "./Vacation/VacationSetting";
import { weekendRestSetting } from "./WeekendRest/WeekendRestSetting";
import { weekRestSetting } from "./WeekRest/WeekRestSetting";
import { workdayCadenceSetting } from "./WorkDayCadence/WorkDayCadenceSetting";

export const ALL_SETTINGS: ReadonlyArray<GenericOptimizationSetting> = [
  hoursAndShiftsSetting,
  baseDemandSetting,
  competenceDemandSetting,
  costSetting,
  consecutiveWorkSetting,
  workdayCadenceSetting,
  dayNightDistributionSetting,
  dayNightFairnessSetting,
  dayNightCadenceSetting,
  consecutiveRestSetting,
  dayRestSetting,
  weekRestSetting,
  weekendRestSetting,
  shiftDistributionSetting,
  resourceTimeSetting,
  responsibilityTimeSetting,
  splitShiftsSetting,
  optimizationSetting,
  partTimeSetting,
  smoothResourcesSetting,
  restBetweenShiftsSetting,
  clockwiseRotationSetting,
  requestedDaysOffSetting,
  vacationSetting,
  nightShiftSetting,
];

export const ALLOWED_USER_EXCEPTIONS: ReadonlyArray<GenericOptimizationSetting> =
  [
    hoursAndShiftsSetting,
    consecutiveWorkSetting,
    workdayCadenceSetting,
    dayNightDistributionSetting,
    dayNightFairnessSetting,
    dayNightCadenceSetting,
    consecutiveRestSetting,
    dayRestSetting,
    weekRestSetting,
    weekendRestSetting,
    shiftDistributionSetting,
    resourceTimeSetting,
    responsibilityTimeSetting,
    nightShiftSetting,
  ];

export const ALLOWED_RULE_GROUP_EXCEPTIONS: ReadonlyArray<GenericOptimizationSetting> =
  [
    hoursAndShiftsSetting,
    consecutiveWorkSetting,
    consecutiveRestSetting,
    dayRestSetting,
    weekRestSetting,
    weekendRestSetting,
    shiftDistributionSetting,
    resourceTimeSetting,
    responsibilityTimeSetting,
    nightShiftSetting,
    partTimeSetting,
  ];

import { useCallback, useMemo, useState } from "react";
import { styled, Tab as MuiTab, Tabs } from "@mui/material";
import { useFormikState } from "hooks";
import { LONG_DAYS } from "utils/day";

import type { FormShift } from "./types";

type Props = {
  availableDays: ReadonlyArray<number>;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
};
type FormikProps = Omit<Props, "availableDays"> & { shiftsName: string };

const days = LONG_DAYS;

const Tab = styled(MuiTab)(() => ({
  minWidth: 0,
  paddingLeft: 4,
  paddingRight: 4,
  textTransform: "capitalize",
}));

export function DayTabs({ tab, setTab, disabled, availableDays }: Props) {
  const selectedDays = useMemo(() => new Set(availableDays), [availableDays]);

  const changeTab = useCallback(
    (_: React.SyntheticEvent, newTab: number) => setTab(newTab),
    [setTab],
  );

  return (
    <Tabs value={tab} onChange={changeTab}>
      {days.map((day, d) => (
        <Tab
          key={d}
          label={day}
          hidden={!selectedDays.has(d)}
          disabled={disabled}
        />
      ))}
    </Tabs>
  );
}

export function FormikDayTabs({ shiftsName, ...props }: FormikProps) {
  const { value } = useFormikState<ReadonlyArray<FormShift>>(shiftsName);
  const availableDays = useMemo(() => value.map((s) => s.meetingDay), [value]);

  return <DayTabs {...props} availableDays={availableDays} />;
}

export function useDayTabs() {
  const [day, setDay] = useState<number>(0);
  return { day, setDay };
}

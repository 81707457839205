import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Worktime1 } from "components/setting/common/forms";

const MODULE_NAME = "AdminTime";

type FormProps = { readOnly?: boolean };

function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      type="onlyHard"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Worktime1Form({ readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);
  const who = " per person på enheten";

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
        />
        <Worktime1 who={who} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { splitShiftsWeightChoices } from "components/setting/team_group/setting_boxes/constants";

import {
  SplitShiftsSettingTeamGroup_fragment$data as TeamGroupData,
  SplitShiftsSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/SplitShiftsSettingTeamGroup_fragment.graphql";
import { SplitShiftsForm } from "./form/SplitShiftsForm";

const teamGroupFragment = graphql`
  fragment SplitShiftsSettingTeamGroup_fragment on SettingNode {
    id
    allowSplitShifts
    optimizeSplitShiftsDistributionBetweenEmployees
    splitShiftsWeight
    constraintModules
  }
`;

const SETTING_NAME = "Delade turer";
const MODULE_NAME = "SplitShifts";
const SETTING_URL_ID = "split-shifts";

type FullTimeHoursFormValues = Omit<TeamGroupData, " $fragmentType">;

const validationSchema = yup.object().shape({
  id: yup.string(),
  allowSplitShifts: yup.boolean().required(),
  optimizeSplitShiftsDistributionBetweenEmployees: yup.boolean().required(),
  splitShiftsWeight: yup.string().oneOf(splitShiftsWeightChoices).required(),
  constraintModules: yup.array().of(yup.string()),
});

function renderComponent(
  initialValues: FullTimeHoursFormValues,
  onSubmit: (formValues: FullTimeHoursFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <SplitShiftsForm />
    </BaseSettings>
  );
}

export const splitShiftsSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  FullTimeHoursFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues: defaultConvertDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
};

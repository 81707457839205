import { forwardRef } from "react";
import { ReactComponent } from "assets/svg/warning.svg";

import CustomIcon from "./CustomIcon";

function Warning(p: any, ref: any) {
  return <CustomIcon ref={ref} {...p} component={ReactComponent} />;
}

export default forwardRef(Warning);

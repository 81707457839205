import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { MenuItem, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import type { MRT_ColumnDef } from "material-react-table";
import type { Unwrap } from "relay-help/arrays";

import MRTable from "components/common/MRTable";

import type {
  RuleGroupsList_ruleGroups$data as Data,
  RuleGroupsList_ruleGroups$key as Key,
} from "./types";

type RuleGroup = Unwrap<Data>;

type Props = {
  fragmentRef: Key;
  setEdit: (id: string | null) => void;
  onDelete: (ruleGroup: { id: string; name: string }) => void;
};

type ContentProps = {
  readonly ruleGroups: ReadonlyArray<{
    name: string;
    description: string;
  }>;
  hideActions?: boolean;
  setEdit?: Props["setEdit"];
  onDelete?: Props["onDelete"];
};

const fragment = graphql`
  fragment RuleGroupsList_ruleGroups on RuleGroupNode @relay(plural: true) {
    id
    name
    description
  }
`;

export function RuleGroupsListContent({
  ruleGroups,
  hideActions,
  onDelete,
  setEdit,
}: ContentProps) {
  const columns = useMemo<MRT_ColumnDef<RuleGroup>[]>(
    () => [
      {
        header: "Namn",
        accessorKey: "name",
        Cell: ({ cell }) => <Typography>{cell.getValue<string>()}</Typography>,
      },
      {
        header: "Beskrivning",
        accessorKey: "description",
        Cell: ({ cell }) => <Typography>{cell.getValue<string>()}</Typography>,
      },
    ],
    [],
  );
  const renderRowActionMenuItems = useCallback(
    ({ row }: any) => [
      <MenuItem key="edit" onClick={() => setEdit?.(row.original.id)}>
        Redigera
      </MenuItem>,
      <MenuItem
        key="delete"
        onClick={() => onDelete?.(row.original)}
        sx={{ bgcolor: "error.main" }}
      >
        Radera
      </MenuItem>,
    ],
    [setEdit, onDelete],
  );

  return (
    <MRTable
      columns={columns}
      data={ruleGroups}
      renderRowActionMenuItems={renderRowActionMenuItems}
      positionActionsColumn="last"
      enableRowActions={!hideActions}
    />
  );
}

export function RuleGroupsList({ fragmentRef, setEdit, onDelete }: Props) {
  const ruleGroups = useFragment<Key>(fragment, fragmentRef);

  return (
    <RuleGroupsListContent
      ruleGroups={ruleGroups.map((r) => r)}
      setEdit={setEdit}
      onDelete={onDelete}
    />
  );
}

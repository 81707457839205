import { useCallback, useMemo } from "react";
import type { UseMutationConfig } from "react-relay";
import { useMutation as useRelayMutation } from "react-relay";
import type { GraphQLTaggedNode, MutationParameters } from "relay-runtime";

type Config<T extends MutationParameters> = Omit<
  UseMutationConfig<T>,
  "onCompleted" | "onError"
>;

/** Custom useMutation implementation.
 *   `commit` is redefined to return a Promise.
 *   This useMutation optionally accepts initConfig to provide for example updater.
 */
export function useMutation<T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  initConfig?: Omit<Config<T>, "variables">,
): [(config: Config<T>) => Promise<T["response"]>, boolean] {
  const [commit, areMutationsInFlight] = useRelayMutation(mutation);

  const asyncCommit = useCallback(
    (config: Config<T>): Promise<T["response"]> => {
      return new Promise((res, rej) => {
        const asyncConfig = Object.assign(initConfig ?? {}, config, {
          onError: rej,
          onCompleted: (payload: any, errors: any) => {
            if (errors) {
              rej(errors[0]);
              return;
            }
            res(payload);
          },
        });
        commit(asyncConfig);
      });
    },
    [initConfig, commit],
  );

  return useMemo(
    () => [asyncCommit, areMutationsInFlight],
    [asyncCommit, areMutationsInFlight],
  );
}

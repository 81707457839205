import { Stack, Typography } from "@mui/material";

import { CadenceRestrictions } from "./CadenceRestrictions";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
  who: React.ReactNode;
};

export function Types7({ disabled, readOnly }: Props) {
  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Typography variant="h4">Kadensregler för Dag/Kväll/Natt</Typography>
        <Typography>
          Här kan du skapa egna regler för att styra kadensen av dags-, kvälls-
          och nattpass. Du kan exempelvis begränsa att det endast får förekomma
          5 kvällspass över ett spann på 10 kalenderdagar. Ett annat vanligt
          fall är att begränsa antalet nattpass till 3 nattpass över ett spann
          på 4 kalenderdagar, vilket alltså innebär en begränsning på max 3
          nattpass på raken.
        </Typography>
      </Stack>
      <CadenceRestrictions
        name="cadenceRestrictions"
        disabled={disabled}
        readOnly={readOnly}
      />
    </Stack>
  );
}

import { InputAdornment, Stack, Typography } from "@mui/material";

import CustomField from "components/forms/CustomField";

type Props = {
  readOnly?: boolean;
  disabled?: boolean;
};

function Base7A({ readOnly, disabled }: Props) {
  return (
    <Stack gap={4}>
      <Stack gap={1}>
        <Typography variant="h4">Timkostnad</Typography>
        <Typography>
          Detta fält styr schablonvärdet för en arbetad timme. Fältet påverkar
          inte hur schemat optimeras, utan används endast för att beräkna
          kostnaden för ett färdigt schema. Denna kostnad kan sättas på
          regelgrupps- och enhetsnivå.
        </Typography>
        <CustomField
          name="hourlyCost"
          disabled={disabled}
          fullWidth
          size="small"
          type="number"
          InputProps={{
            readOnly,
            endAdornment: (
              <InputAdornment position="end">kr/timme</InputAdornment>
            ),
            style: { maxWidth: "150px" },
          }}
          inputProps={{ step: 0.5 }}
        />
      </Stack>
    </Stack>
  );
}

export function Base7({ readOnly, disabled }: Props) {
  return (
    <Stack gap={5}>
      <Base7A readOnly={readOnly} disabled={disabled} />
    </Stack>
  );
}

import {
  ExitToApp as ImportIcon,
  IosShare as ExportIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useRequireMe } from "hooks";
import type { TeamType } from "types/TeamGroupsContextQueryTypes";

import { TeamChip } from "components/common/TeamChip";
import { PageHeader } from "components/layout/PageWrapper";

type Props = {
  openCreateMember: () => void;
  groupName: string;
  readonly teams: ReadonlyArray<TeamType>;
  loading?: boolean;
};

type ActionProps = Pick<Props, "openCreateMember"> & {
  disabled?: boolean;
};

function OrganisationActions({ openCreateMember, disabled }: ActionProps) {
  const { evaluatePermission } = useRequireMe();
  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {evaluatePermission("create-user", "WRITE") && (
        <Button
          variant="primary"
          onClick={openCreateMember}
          endIcon={<PersonIcon />}
          disabled={disabled}
        >
          Ny person
        </Button>
      )}
      <Button variant="outlined" disabled endIcon={<ImportIcon />}>
        Importera
      </Button>
      <Button variant="outlined" disabled endIcon={<ExportIcon />}>
        Exportera
      </Button>
    </Stack>
  );
}

export function OrganisationHeader({
  openCreateMember,
  groupName,
  teams,
  loading,
}: Props) {
  const Wrap = ({ children }: React.PropsWithChildren<{}>) => (
    <PageHeader
      title="Personallista"
      actions={
        <OrganisationActions
          openCreateMember={openCreateMember}
          disabled={loading}
        />
      }
    >
      {children}
    </PageHeader>
  );
  if (loading) {
    return (
      <Wrap>
        <Typography sx={{ py: 0.18 }}>
          Avdelningar på enhet {groupName}:
        </Typography>
      </Wrap>
    );
  }

  return (
    <Wrap>
      {teams.length ? (
        <Stack direction="row" gap={1} flexWrap="wrap" alignItems="center">
          <Typography>Avdelningar på enhet {groupName}:</Typography>
          {teams.map((t) => (
            <TeamChip team={t} key={t.id} />
          ))}
        </Stack>
      ) : (
        <Typography>
          <i>På denna enhet finns inga avdelningar.</i>
        </Typography>
      )}
    </Wrap>
  );
}

import { UnreachableCaseError } from "ts-essentials";

type NightRestrictType =
  | "N"
  | "L0"
  | "L1"
  | "L2"
  | "L3"
  | "%future added value";

const TranslateNightRestrictType = (
  type: NightRestrictType | undefined,
): string | undefined => {
  switch (type) {
    case "N":
      return "Ingen begränsning";
    case "L0":
      return "0% nattpass";
    case "L1":
      return "0% - 19% nattpass";
    case "L2":
      return "20% - 29% nattpass";
    case "L3":
      return "30% - 100% nattpass";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

const nightRestrictList: NightRestrictType[] = ["N", "L0", "L1", "L2", "L3"];

export const nightRestrictOptions = nightRestrictList.map((u) => ({
  value: u,
  label: TranslateNightRestrictType(u),
}));

/**
 * @generated SignedSource<<bcbd5fbdadbcc034cc704f6bc0944451>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserAdminFormQuery$variables = {
  id: string;
};
export type UserAdminFormQuery$data = {
  readonly teams: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TeamSelect_fragment">;
  }>;
  readonly user: {
    readonly fullName: string;
    readonly groupAdminOf: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly id: string;
    readonly teamAdminOf: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
  } | null;
};
export type UserAdminFormQuery = {
  response: UserAdminFormQuery$data;
  variables: UserAdminFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamGroupNodeConnection",
      "kind": "LinkedField",
      "name": "groupAdminOf",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamGroupNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamGroupNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamNodeConnection",
      "kind": "LinkedField",
      "name": "teamAdminOf",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAdminFormQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamSelect_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserAdminFormQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "125e44bb36b418c3e51d2208114c20f2",
    "id": null,
    "metadata": {},
    "name": "UserAdminFormQuery",
    "operationKind": "query",
    "text": "query UserAdminFormQuery(\n  $id: ID!\n) {\n  user(id: $id) {\n    id\n    fullName\n    groupAdminOf {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    teamAdminOf {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n  teams {\n    ...TeamSelect_fragment\n    id\n  }\n}\n\nfragment TeamSelect_fragment on TeamNode {\n  id\n  name\n  color\n  group {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "1da9b9b01ff766d70794ff2bc9d08346";

export default node;

import { Suspense, useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Paper, Stack, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { connectionToArray, useConnectionToArray } from "relay-help/arrays";

import { Level1Menu } from "components/schedule/schedule_settings/Level1Menu";
import { Level2Menu } from "components/schedule/schedule_settings/Level2Menu";
import { SettingBoxSection } from "components/schedule/schedule_settings/SettingBoxSection";
import { Panel1, Panel2, SettingBox } from "components/setting/common";

import type { ScheduleSettingsQuery as Query } from "./types";

const query = graphql`
  query ScheduleSettingsQuery($id: ID!) {
    schedule(id: $id) {
      teamgroupsettingsnapshot {
        ...TeamGroupSettings_fragment
        constraintModules
        showAdvancedSettings
        periodLengthWeeks
        shiftsPerWeek
      }
      rulegroupsnapshots {
        edges {
          node {
            id
            name
            ruleGroupSetting {
              ...RuleGroupSettings_fragment
              settingModules
            }
          }
        }
      }
      users {
        edges {
          node {
            id
            name
            userSetting {
              ...UserSettings_fragment
              settingModules
            }
          }
        }
      }
      shifts {
        edges {
          node {
            id
            name
            start
            end
            worktimeHours
            shiftParts {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

type Props = { scheduleId: string };

export function ScheduleSettings({ scheduleId: id }: Props) {
  const teamGroupId = useCurrentTeamGroup()?.id ?? "";
  const [levelId, setLevelId] = useState<string>(teamGroupId);
  const [ruleId, setRuleId] = useState<string>("b-0");
  const data = useLazyLoadQuery<Query>(query, { id });

  const { schedule } = data;
  const ruleGroups = useConnectionToArray(schedule?.rulegroupsnapshots);
  const shifts = useMemo(
    () =>
      connectionToArray(schedule?.shifts).map((s) => ({
        ...s,
        shiftParts: connectionToArray(s.shiftParts),
      })),
    [schedule],
  );
  const users = useConnectionToArray(schedule?.users);

  if (!data) {
    return (
      <Paper
        variant="box"
        sx={{
          p: 2,
          mr: 4,
          minHeight: 300,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Typography fontStyle={"italic"}>Inga inställningar funna</Typography>
      </Paper>
    );
  }

  const boxSx = {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
  };

  return (
    <Paper variant="box" sx={boxSx}>
      <Stack direction="row" flexGrow={2}>
        <Suspense fallback={<Panel1 />}>
          <Level1Menu
            levelId={levelId}
            onSelect={setLevelId}
            ruleGroups={ruleGroups}
            users={users}
          />
        </Suspense>
        <Suspense fallback={<Panel2 />}>
          <Level2Menu
            levelId={levelId}
            ruleId={ruleId}
            setRuleId={setRuleId}
            groupModules={(
              schedule?.teamgroupsettingsnapshot?.constraintModules ?? []
            ).slice()}
            ruleGroups={ruleGroups}
            users={users}
            showAdvanced={
              schedule?.teamgroupsettingsnapshot?.showAdvancedSettings
            }
          />
        </Suspense>
        <Stack p={2} flexGrow={2} minHeight={200}>
          <Suspense fallback={<SettingBox />}>
            {!!schedule && (
              <SettingBoxSection
                levelId={levelId}
                ruleId={ruleId}
                teamGroupSettingSnapshot={schedule.teamgroupsettingsnapshot}
                ruleGroups={ruleGroups}
                shifts={shifts}
                users={users}
              />
            )}
          </Suspense>
        </Stack>
      </Stack>
    </Paper>
  );
}

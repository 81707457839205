import { Typography } from "@mui/material";

import { FormikSwitchWithTitleAndDesc } from "./common";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function ComprestCanCutDaybreakBilagajField({
  disabled,
  readOnly,
}: Props) {
  return (
    <FormikSwitchWithTitleAndDesc
      name="comprestCanCutDaybreakBilagaj"
      title="Kompenserande dygnsvila får korsa dygnsbryt vid Bilaga J-jour"
      description={
        <Typography>
          Ett pass som är 13 timmar eller längre och innehåller en passdel av
          typen "Jour (Bilaga J)" anses vara ett jourpass enligt Bilaga J. Ett
          sådant pass ska alltid efterföljas av en kompenserande vila lika lång
          som passet är. Detta fält styr huruvida de första 11 timmarna av denna
          kompensationsvila får lova att korsa ett dygnsbryt eller ej.
        </Typography>
      }
      readOnly={readOnly}
      labelOn="Ja"
      labelOff="Nej"
    />
  );
}

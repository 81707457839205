import { TextField } from "@mui/material";
import useFormikState from "hooks/useFormikState";

type InputProps = {
  label: string;
  name: string;
  disabled?: boolean;
  error?: boolean;
};

function Input({ label, name, disabled, error = false }: InputProps) {
  const { value, setValue } = useFormikState(name);
  return (
    <TextField
      size="small"
      label={label}
      error={error}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      disabled={disabled}
      helperText={error && "Måste fyllas i"}
      fullWidth
    />
  );
}

export default Input;

/**
 * @generated SignedSource<<a4a65e07a590f08d72cc47b83be65f53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchedulePeriod_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly constraintModulesBridge: ReadonlyArray<string>;
  readonly id: string;
  readonly periodLengthWeeks: number;
  readonly wrapAroundPeriodEnd: boolean;
  readonly " $fragmentType": "SchedulePeriod_fragment";
};
export type SchedulePeriod_fragment$key = {
  readonly " $data"?: SchedulePeriod_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchedulePeriod_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchedulePeriod_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "periodLengthWeeks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wrapAroundPeriodEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModulesBridge",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "287939be062706eb5a8cc995ba521375";

export default node;

import { Logout as LogoutIcon } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { refresh as refreshMe } from "hooks/Me";
import logout from "mutations/Logout";

const LogoutButton = () => (
  <Stack gap={0.5} direction={"row"} alignItems={"center"}>
    <LogoutIcon sx={{ color: "text.secondary" }} />
    <Button
      onClick={() => logout().then(() => refreshMe())}
      disableRipple
      sx={{ color: "text.primary", "&:hover": { bgcolor: "transparent" } }}
    >
      Logga ut
    </Button>
  </Stack>
);

export default LogoutButton;

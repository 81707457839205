import { Box, Stack, Typography } from "@mui/material";

import Field from "components/forms/CustomField";
import DurationPie from "components/setting/common/forms/Base5/DurationPie";
import EvenSplitPie from "components/setting/common/forms/Base5/EvenSplitPie";

import { FullDayDurationField } from "./FullDayDurationField";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

type InputProps = {
  label: string;
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

function DayEveNightInput({ label, name, disabled, readOnly }: InputProps) {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Typography sx={{ width: 200 }}>{label}</Typography>
      <Field
        type="time"
        name={name}
        fullWidth
        disabled={disabled}
        InputProps={{ readOnly }}
        sx={{ width: 100 }}
      />
    </Stack>
  );
}

export function DayEveNightFields({ disabled, readOnly }: Props) {
  const pieRadius = 220;
  const pieRadiusSmall = 145;
  const piePadding = 4.65;
  return (
    <Stack gap={1}>
      <Stack
        direction="row"
        flexWrap="wrap"
        rowGap={2}
        columnGap={1}
        alignItems="center"
      >
        <Stack gap={3}>
          <Typography variant="h4">
            Definition av Dag/Kväll/Natt/Dygn
          </Typography>
          <Stack gap={1}>
            <DayEveNightInput
              label="Dagen börjar och natten slutar"
              name="morningStartsNightEnds"
              disabled={disabled}
              readOnly={readOnly}
            />
            <DayEveNightInput
              label="Kvällen börjar och dagen slutar"
              name="eveningStartsMorningEnds"
              disabled={disabled}
              readOnly={readOnly}
            />
            <DayEveNightInput
              label="Natten börjar och kvällen slutar"
              name="nightStartsEveningEnds"
              disabled={disabled}
              readOnly={readOnly}
            />
            <FullDayDurationField readOnly={readOnly} />
          </Stack>
        </Stack>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={2}
        >
          <Box height={pieRadius} width={pieRadius} position="relative">
            <EvenSplitPie
              width={pieRadiusSmall}
              height={pieRadiusSmall}
              sx={{ mt: piePadding, ml: piePadding }}
              position="absolute"
              variant="links"
            />
            <EvenSplitPie
              width={pieRadius}
              height={pieRadius}
              position="absolute"
              variant="labels"
            />
            <DurationPie
              width={pieRadiusSmall}
              height={pieRadiusSmall}
              sx={{ mt: piePadding, ml: piePadding }}
              position="absolute"
            />
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}

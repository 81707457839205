import { useEffect } from "react";
import { Drawer as MuiDrawer, Stack, Typography } from "@mui/material";
import type { CSSObject, Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { useBooleanState } from "utils/useBooleanState";

import { FeatureFlagToggles } from "components/admin/FeatureFlagToggles";

import Logo from "../Logo";

import NavList from "./NavList";
import UserMenu from "./UserMenu";

export const DRAWER_WIDTH = 240;
const DRAWER_CLOSED_WIDTH = 112;
const version = process.env.REACT_APP_VERSION;
const devMode = process.env.REACT_APP_DEVMODE === "true";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  border: 0,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_CLOSED_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Content() {
  const { value: showFeatureFlagToggles, toggle: toggleFeatureFlagToggles } =
    useBooleanState(process.env.NODE_ENV === "development" || devMode);

  useEffect(() => {
    (window as any).toggleFeatureFlagToggles = toggleFeatureFlagToggles;
  }, [toggleFeatureFlagToggles]);

  const sx = {
    bgcolor: "ture.100",
  };
  return (
    <Stack
      gap={1}
      flexGrow={2}
      justifyContent="space-between"
      alignItems="center"
      sx={sx}
    >
      <Stack width="100%">
        <Logo
          variant="expanded"
          height={38}
          sx={{
            mt: 6.5,
            mb: 4,
            alignSelf: "flex-start",
            px: 3,
          }}
        />
        <NavList />
      </Stack>
      {showFeatureFlagToggles && <FeatureFlagToggles />}
      <Stack sx={{ px: 3, pb: 2 }} gap={1} width="100%">
        <UserMenu />
        <Typography sx={{ alignSelf: "center" }} color={"darkgray"}>
          {version}
        </Typography>
      </Stack>
    </Stack>
  );
}

export function SideMenu() {
  const width = DRAWER_WIDTH;

  const sx = {
    width,
    border: 19,
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width,
      boxSizing: "border-box",
    },
  };

  return (
    <Drawer variant="permanent" anchor="left" open role="navigation" sx={sx}>
      <Content />
    </Drawer>
  );
}

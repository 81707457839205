/**
 * @generated SignedSource<<d0b213bed967f3a993d8a51357b7da21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTeamGroupForm_fragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "EditTeamGroupForm_fragment";
};
export type EditTeamGroupForm_fragment$key = {
  readonly " $data"?: EditTeamGroupForm_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditTeamGroupForm_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTeamGroupForm_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "TeamGroupNode",
  "abstractKey": null
};

(node as any).hash = "da64545b4d573b23d94de032afbf7d0b";

export default node;

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { ActivateCompetenceMutation as Mutation } from "./types";

const mutation = graphql`
  mutation ActivateCompetenceMutation($id: ID!) {
    activateCompetence(input: { id: $id }) {
      ok
      competence {
        id
        isActive
        archivedAt
      }
    }
  }
`;

export const useActivateCompetence = () => useMutation<Mutation>(mutation);

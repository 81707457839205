import { Link } from "react-router-dom";
import {
  InputAdornment,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FormAccordion";
import StyledInputField from "components/forms/material/StyledInputField";

import SignSelect from "./SignSelect";

type Props = {
  expanded: boolean;
  onChange: () => void;
  name: string;
  memberName: string;
};

type ContentProps = {
  name: string;
  memberName: string;
};

function TimebankContent({ name, memberName }: ContentProps) {
  const { value, setValue } = useFormikState(name);

  const selectedSign = Math.sign(value) || 1;
  const handleChange = (newValue: number) => {
    setValue(selectedSign * newValue);
  };

  function handleHourChange(h: number) {
    handleChange((Math.abs(value) % 60) + h * 60);
  }

  function handleMinuteChange(m: number) {
    handleChange(60 * Math.floor(Math.abs(value) / 60) + m);
  }

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography>
          Här kan du justera bassaldot för {memberName}. Bassaldot är det
          timbankssaldo som ärvs från tidigare schemaperioder lagda innan ni
          började använda Konvojs plattform.
        </Typography>
        <Typography>
          Det totala timbankssaldot beräknas automatiskt från fastställda
          scheman. Du kan ställa in övre och lägre gräns för timbank under{" "}
          <Link to={"/group-settings#timebank"}>Enhet/Timbank</Link>.
        </Typography>
      </Stack>
      <Grid container>
        <Grid xs={2}>
          <SignSelect
            value={selectedSign}
            onChange={(s) => handleChange(s * Math.abs(value))}
          />
        </Grid>
        <Grid xs={5} sx={{ px: 1 }}>
          <StyledInputField
            fullWidth
            size="small"
            type="number"
            endAdornment={
              <InputAdornment position="end">timmar</InputAdornment>
            }
            name="timebankHours"
            value={Math.floor(Math.abs(value) / 60)}
            onChange={(v: any) =>
              handleHourChange(v?.target.valueAsNumber || 0)
            }
          />
        </Grid>
        <Grid xs={5}>
          <StyledInputField
            fullWidth
            size="small"
            type="number"
            endAdornment={
              <InputAdornment position="end">minuter</InputAdornment>
            }
            value={Math.abs(value) % 60}
            name="timebankMinutes"
            onChange={(v: any) =>
              handleMinuteChange(v?.target.valueAsNumber || 0)
            }
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export function TimebankAccordion({
  expanded,
  onChange,
  name,
  memberName,
}: Props) {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Typography variant="h4">Timbank Bassaldo</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TimebankContent name={name} memberName={memberName} />
      </AccordionDetails>
    </Accordion>
  );
}

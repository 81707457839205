import { Grid } from "@mui/material";

import CustomField from "../CustomField";

import IconAndLabel from "./IconAndLabel";
import Input from "./Input";

type FormSectionProps = {
  icon: React.ReactNode;
  label: string;
  name: string;
  disabled?: boolean;
  isCreate?: boolean;
  error?: boolean;
  component?: React.ReactNode; // Optional, for cases where a different component is used instead of Input
};

type FormSectionDoubleProps = {
  icon: React.ReactNode;
  label: string;
  label2: string;
  name: string;
  name2: string;
  disabled?: boolean;
  isCreate?: boolean;
  error?: boolean;
  component?: React.ReactNode; // Optional, for cases where a different component is used instead of Input
  component2?: React.ReactNode; // Second component for the second field
};

export function FormSection({
  icon,
  label,
  name,
  disabled,
  error,
  component,
}: FormSectionProps) {
  return (
    <Grid
      container
      spacing={0}
      pt={1}
      justifyContent="start"
      alignItems="center"
    >
      <IconAndLabel icon={icon} label={label} />
      <Grid xs={8}>
        {component ? (
          component
        ) : (
          <CustomField
            name={name}
            label={label}
            disabled={disabled}
            fullWidth
            autoComplete="off"
          />
        )}
      </Grid>
    </Grid>
  );
}

export function FormSectionDouble({
  icon,
  label,
  label2,
  name,
  name2,
  disabled,
  error,
  component,
  component2,
}: FormSectionDoubleProps) {
  if (!name2 || !label2) {
    return null;
  }

  return (
    <Grid
      container
      spacing={0}
      pt={1}
      justifyContent="start"
      alignItems="center"
    >
      <IconAndLabel icon={icon} label={label} />
      <Grid xs={4} pr={1}>
        {component ? (
          component
        ) : (
          <Input name={name} label={label} disabled={disabled} error={error} />
        )}
      </Grid>
      <Grid xs={4} pl={1}>
        {component2 ? component2 : <Input name={name2} label={label2} />}
      </Grid>
    </Grid>
  );
}

import { useCallback, useMemo } from "react";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { Select } from "@mui/material";

export type XOption<T = string> = {
  value: T;
  label: string;
};

type XSelectProps<T extends XOption> = {
  value: T;
  onChange: (value: T) => void;
  defaultValue?: T;
  options: ReadonlyArray<T>;
};

export function XSelect<T extends XOption>({
  value,
  onChange,
  defaultValue,
  options,
}: XSelectProps<T>) {
  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const selected = options.find((o) => o.value === e.target.value);
      if (selected != null) {
        onChange(selected);
      }
    },
    [onChange, options],
  );

  const renderedOptions = useMemo(
    () =>
      options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      )),
    [options],
  );

  return (
    <Select
      value={value.value}
      defaultValue={defaultValue?.value}
      onChange={handleChange}
      variant="standard"
    >
      {renderedOptions}
    </Select>
  );
}

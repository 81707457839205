import type { DurationLikeObject } from "luxon";
import { DateTime, Duration } from "luxon";

const durationObjToMillis = (obj: DurationLikeObject) =>
  Duration.fromObject(obj).as("milliseconds");

/** Get Swedish string used for Nivo charts. */
export const swedishWeekday = (value: Date, isLong = true) => {
  const weekdayString = value.toLocaleString("sv", {
    weekday: isLong ? "long" : "short",
  });
  return weekdayString[0].toUpperCase() + weekdayString.slice(1);
};

/** Get format string used for Nivo charts' `axis*.format`. */
export const yformat = (e: number | string | Date) => {
  if (typeof e === "number") {
    if (Number.isInteger(e)) {
      return Math.floor(e);
    } else {
      return "";
    }
  } else {
    throw new Error("Unexpected value");
  }
};

/** Get format string used for Nivo charts' `axis*.tickValues`. */
export const xticks: (maxDate: string, minDate: string) => string = (
  maxDate: string,
  minDate: string,
) => {
  const delta = Date.parse(maxDate) - Date.parse(minDate);
  if (delta >= durationObjToMillis({ weeks: 4 })) {
    return "Every 7 days";
  } else if (delta >= durationObjToMillis({ weeks: 2 })) {
    return "Every 2 days";
  } else if (delta >= durationObjToMillis({ days: 3 })) {
    return "Every 24 hours";
  } else if (delta >= durationObjToMillis({ days: 2 })) {
    return "Every 6 hours";
  } else if (delta >= durationObjToMillis({ days: 1 })) {
    return "Every 3 hours";
  } else {
    return "Every hour";
  }
};

/** Get format string used for Nivo charts' `gridXValues`. */
export const xGrids: (maxDate: string, minDate: string) => string = (
  maxDate: string,
  minDate: string,
) => {
  const delta = Date.parse(maxDate) - Date.parse(minDate);
  if (delta >= durationObjToMillis({ weeks: 4 })) {
    return "Every day";
  } else if (delta >= durationObjToMillis({ weeks: 2 })) {
    return "Every day";
  } else if (delta >= durationObjToMillis({ days: 3 })) {
    return "Every day";
  } else if (delta >= durationObjToMillis({ days: 2 })) {
    return "Every 6 hours";
  } else if (delta >= durationObjToMillis({ days: 1 })) {
    return "Every hour";
  } else {
    return "Every hour";
  }
};

/** Get format string used for Nivo charts' `axis*.format`. */
export const xticksFormat: (
  maxDate: string,
  minDate: string,
) => (d: Date) => string =
  (maxDate: string, minDate: string) => (date: Date) => {
    const delta = Date.parse(maxDate) - Date.parse(minDate);
    const dt = DateTime.fromJSDate(date);

    if (delta >= durationObjToMillis({ weeks: 4 })) {
      return dt.toFormat("ccc dd LLL");
    } else if (delta >= durationObjToMillis({ weeks: 2 })) {
      return dt.toFormat("ccc dd LLL");
    } else if (delta >= durationObjToMillis({ days: 3 })) {
      return dt.toFormat("ccc dd LLL");
    } else if (delta >= durationObjToMillis({ days: 2 })) {
      return dt.toFormat("ccc HH:00");
    } else if (delta >= durationObjToMillis({ days: 1 })) {
      return dt.toFormat("HH:00");
    } else {
      return dt.toFormat("HH:00");
    }
  };

const dtFormat = "yyyy-MM-dd TT";
// Date text format
export function dateText(dateNumber: number) {
  return DateTime.fromMillis(dateNumber).toFormat(dtFormat);
}

export function date2string(date: number) {
  return DateTime.fromMillis(date).toFormat(dtFormat);
}

import useFormikState from "hooks/useFormikState";
import PaperWrapper from "settings/common/PaperWrapper";

import { MarginsField } from "./MarginsField";

type Props = {
  periodLengthWeeks: number;
  disabled?: boolean;
  readOnly?: boolean;
};

export function HoursAndShiftsTeamGroupForm({
  periodLengthWeeks,
  disabled,
  readOnly,
}: Props) {
  const { value: hoursPerWeek } = useFormikState<number>("hoursPerWeek");
  const { value: shiftsPerWeek } = useFormikState<number>("shiftsPerWeek");

  return (
    <PaperWrapper>
      <MarginsField
        periodLengthWeeks={periodLengthWeeks}
        hoursPerWeek={hoursPerWeek}
        shiftsPerWeek={shiftsPerWeek}
      />
    </PaperWrapper>
  );
}

import type { CSSObject } from "@mui/material";
import { Box } from "@mui/material";
import { ReactComponent as CompactLogo } from "assets/svg/Konvoj_logo_icon_white.svg";
import { ReactComponent as ColorLogo } from "assets/svg/Konvoj_logo_original_color_blue.svg";
import { ReactComponent as ExpandedLogo } from "assets/svg/Konvoj_logo_original_white.svg";

type LogoVariant = "expanded" | "compact" | "color";

type Props = {
  variant: LogoVariant;
  height?: number;
  sx?: CSSObject;
};

function getLogo(variant: LogoVariant) {
  switch (variant) {
    case "expanded":
      return ExpandedLogo;
    case "compact":
      return CompactLogo;
    case "color":
      return ColorLogo;
  }
}

export default function Logo({ variant, height = 42, sx = {} }: Props) {
  const Logo = getLogo(variant);
  return <Box component={Logo} height={height} sx={sx} />;
}

import { CircularProgress, Stack, Typography as Text } from "@mui/material";

const Loading = () => (
  <Stack gap={1}>
    <CircularProgress role="status" />
    <Text>Loading...</Text>
  </Stack>
);

export default Loading;

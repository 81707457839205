import { Divider, Stack } from "@mui/material";

import { DayEveNightFields } from "./DayEveNightFields";
import { FreeWeekendStartEndField } from "./FreeWeekendStartEndField";

type Props = { readOnly?: boolean };

export function DefinitionsForm({ readOnly }: Props) {
  return (
    <Stack gap={3}>
      <DayEveNightFields readOnly={readOnly} />
      <Divider />
      <FreeWeekendStartEndField readOnly={readOnly} />
    </Stack>
  );
}

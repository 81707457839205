import { useMemo } from "react";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { minutes2HHMM } from "helpers/dateFunctions";
import useFormikState from "hooks/useFormikState";
import PaperWrapper from "settings/common/PaperWrapper";

import Field from "components/forms/CustomField";
import ExampleBox from "components/setting/common/ExampleBox";

import { round } from "../../components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/utils";

type Props = {
  periodLengthWeeks: number;
  disabled?: boolean;
  readOnly?: boolean;
};

export function HoursAndShiftsForm({
  periodLengthWeeks,
  disabled,
  readOnly,
}: Props) {
  const { value: hoursPerWeek } = useFormikState<number>("hoursPerWeek");
  const { value: shiftsPerWeek } = useFormikState<number>("shiftsPerWeek");
  const hoursPerPeriod: number = round(hoursPerWeek * periodLengthWeeks, 6);
  const shiftsPerPeriod: number = round(shiftsPerWeek * periodLengthWeeks, 0);
  const shiftAvg = useMemo(
    () => (shiftsPerPeriod > 0 ? hoursPerPeriod / shiftsPerPeriod : 0),
    [hoursPerPeriod, shiftsPerPeriod],
  );

  return (
    <PaperWrapper>
      <Grid container rowSpacing={0} columnSpacing={3}>
        <Grid item xs={6} mb={1}>
          <Typography variant="h4">Arbetstimmar per vecka</Typography>
        </Grid>
        <Grid item xs={6} mb={1}>
          <Typography variant="h4">Arbetspass per vecka</Typography>
        </Grid>
        <Grid item xs={6}>
          <HoursPerWeek disabled={disabled} readOnly={readOnly} />
        </Grid>
        <Grid item xs={6}>
          <ShiftsPerWeek disabled={disabled} readOnly={readOnly} />
        </Grid>
        {!disabled && (
          <Grid item xs={6}>
            <HoursExample
              periodLengthWeeks={periodLengthWeeks}
              hoursPerPeriod={hoursPerPeriod}
              shiftAvg={shiftAvg}
            />
          </Grid>
        )}
        {!disabled && (
          <Grid item xs={6}>
            <ShiftsExample
              periodLengthWeeks={periodLengthWeeks}
              shiftsPerPeriod={shiftsPerPeriod}
              shiftAvg={shiftAvg}
            />
          </Grid>
        )}
      </Grid>
    </PaperWrapper>
  );
}

const HoursPerWeek = ({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) => (
  <Field
    name="hoursPerWeek"
    fullWidth
    size="small"
    type="number"
    InputProps={{
      readOnly,
      endAdornment: <InputAdornment position="end">timmar</InputAdornment>,
    }}
    disabled={disabled}
    inputProps={{ step: 0.1 }}
  />
);

const ShiftsPerWeek = ({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) => (
  <Field
    name="shiftsPerWeek"
    fullWidth
    size="small"
    type="number"
    InputProps={{
      readOnly,
      endAdornment: <InputAdornment position="end">pass</InputAdornment>,
    }}
    disabled={disabled}
    inputProps={{ step: 0.1 }}
  />
);

type HoursExampleProps = {
  hoursPerPeriod: number;
  periodLengthWeeks: number;
  shiftAvg: number;
};

const HoursExample = ({
  hoursPerPeriod,
  periodLengthWeeks,
  shiftAvg,
}: HoursExampleProps) => (
  <ExampleBox
    sx={{
      flexGrow: 2,
      mt: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Stack>
      <Typography variant="caption">
        Ger <strong>{minutes2HHMM(60 * hoursPerPeriod || 0) + " "}</strong>
        timmar på en{" "}
        <strong>
          {periodLengthWeeks}
          -veckorsperiod
        </strong>
      </Typography>
      <Typography variant="caption">
        Ger en genomsnittlig passlängd på{" "}
        <strong>{minutes2HHMM(60 * shiftAvg)}</strong>
      </Typography>
    </Stack>
    <SettingsSuggestIcon sx={{ color: "gray", opacity: 0.5 }} />
  </ExampleBox>
);

type ShiftsExampleProps = {
  shiftsPerPeriod: number;
  periodLengthWeeks: number;
  shiftAvg: number;
};
const ShiftsExample = ({
  shiftsPerPeriod,
  periodLengthWeeks,
  shiftAvg,
}: ShiftsExampleProps) => (
  <ExampleBox
    sx={{
      flexGrow: 2,
      mt: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Stack>
      <Typography variant="caption">
        Ger <strong>{shiftsPerPeriod}</strong> pass på en{" "}
        <strong>
          {periodLengthWeeks}
          -veckorsperiod
        </strong>
      </Typography>
      <Typography variant="caption">
        Ger en genomsnittlig passlängd på{" "}
        <strong>{minutes2HHMM(60 * shiftAvg)}</strong>
      </Typography>
    </Stack>
    <SettingsSuggestIcon sx={{ color: "gray", opacity: 0.5 }} />
  </ExampleBox>
);

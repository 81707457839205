/**
 * @generated SignedSource<<1a02174bbf85ee85d2667d53679a12f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DemandQuery$variables = {
  now: string;
  teamGroupId: string;
};
export type DemandQuery$data = {
  readonly competences: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CompetenceSelect_fragment">;
  }>;
  readonly formDemands: {
    readonly " $fragmentSpreads": FragmentRefs<"DemandForm_fragment">;
  };
  readonly nextPeriod: {
    readonly endDate: string;
    readonly startDate: string;
  } | null;
  readonly settingForTeamGroup: {
    readonly periodLengthWeeks: number;
    readonly uniqueWeeks: boolean;
    readonly useDemandWeights: boolean;
    readonly useDutyDemand: boolean;
  } | null;
  readonly teams: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TeamSelect2_fragment">;
  }>;
};
export type DemandQuery = {
  response: DemandQuery$data;
  variables: DemandQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "now"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamGroupId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "teamGroupId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "groupId",
    "variableName": "teamGroupId"
  },
  {
    "kind": "Variable",
    "name": "now",
    "variableName": "now"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodLengthWeeks",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uniqueWeeks",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useDutyDemand",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useDemandWeights",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v13 = [
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamNode",
  "kind": "LinkedField",
  "name": "team",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weeks",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "days",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "demand",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dutyShare",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimizeUnderstaffingWeight",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minimizeOverstaffingWeight",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DemandQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DemandFormViewer",
        "kind": "LinkedField",
        "name": "formDemands",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DemandForm_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "competences",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetenceSelect_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamSelect2_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "nextPeriod",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DemandQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DemandFormViewer",
        "kind": "LinkedField",
        "name": "formDemands",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandTeamNode",
            "kind": "LinkedField",
            "name": "demands",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandWeeksNode",
                "kind": "LinkedField",
                "name": "demands",
                "plural": true,
                "selections": [
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandDaysNode",
                    "kind": "LinkedField",
                    "name": "demands",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DemandItemNode",
                        "kind": "LinkedField",
                        "name": "demands",
                        "plural": true,
                        "selections": [
                          (v17/*: any*/),
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "enforceDuty",
                            "storageKey": null
                          },
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DemandCompetenceTeamNode",
            "kind": "LinkedField",
            "name": "demandCompetences",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetenceNode",
                "kind": "LinkedField",
                "name": "competence",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DemandWeeksNode",
                "kind": "LinkedField",
                "name": "demands",
                "plural": true,
                "selections": [
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DemandDaysNode",
                    "kind": "LinkedField",
                    "name": "demands",
                    "plural": true,
                    "selections": [
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DemandItemNode",
                        "kind": "LinkedField",
                        "name": "demands",
                        "plural": true,
                        "selections": [
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "competences",
        "plural": true,
        "selections": [
          (v11/*: any*/),
          (v10/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          (v11/*: any*/),
          (v10/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "nextPeriod",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9cd1d2b81d7f9b19a32f0d3a9605f759",
    "id": null,
    "metadata": {},
    "name": "DemandQuery",
    "operationKind": "query",
    "text": "query DemandQuery(\n  $teamGroupId: ID!\n  $now: Date!\n) {\n  formDemands(teamGroupId: $teamGroupId) {\n    ...DemandForm_fragment\n  }\n  competences {\n    ...CompetenceSelect_fragment\n    id\n  }\n  teams {\n    ...TeamSelect2_fragment\n    id\n  }\n  nextPeriod(groupId: $teamGroupId, now: $now) {\n    startDate\n    endDate\n    id\n  }\n  settingForTeamGroup(teamGroupId: $teamGroupId) {\n    periodLengthWeeks\n    uniqueWeeks\n    useDutyDemand\n    useDemandWeights\n    id\n  }\n}\n\nfragment CompetenceSelect_fragment on CompetenceNode {\n  id\n  name\n  color\n}\n\nfragment DemandForm_fragment on DemandFormViewer {\n  demands {\n    team {\n      name\n      id\n      color\n    }\n    demands {\n      weeks\n      demands {\n        days\n        demands {\n          demand\n          dutyShare\n          enforceDuty\n          start\n          end\n          minimizeUnderstaffingWeight\n          minimizeOverstaffingWeight\n        }\n      }\n    }\n  }\n  demandCompetences {\n    team {\n      name\n      id\n      color\n    }\n    competence {\n      name\n      id\n      color\n    }\n    demands {\n      weeks\n      demands {\n        days\n        demands {\n          demand\n          dutyShare\n          start\n          end\n          minimizeUnderstaffingWeight\n          minimizeOverstaffingWeight\n        }\n      }\n    }\n  }\n}\n\nfragment TeamSelect2_fragment on TeamNode {\n  id\n  name\n  color\n  group {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4260eba6d93ab4cd9ecc44eeddf3d66";

export default node;

import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useFormikState } from "hooks";
import { connectionToArray } from "relay-help/arrays";
import { ClockwiseShiftRotationOldForm } from "settings/ClockwiseRotations/form/ClockwiseShiftRotationOldForm";
import { NightShiftsOldForm } from "settings/NightShift/NightShiftsOldForm";
import { WishedDaysOffOldForm } from "settings/RequestedDaysOff/form/WishedDaysOffOldForm";
import { Worktime2OldForm } from "settings/ResourceTime/form/ResourceTimeOldForm";
import { toCamelCase } from "utils/object";

import { Visible } from "components/common/Visible";
import { FormikMock } from "components/setting/common";
import type { Shift as ParttimeShift } from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField/useGraphData";
import type { Shift as Base3Shift } from "components/setting/common/forms/common/ShiftSelect";
import {
  AB1Form,
  AB2Form,
  Base2Form,
  Base3Form,
  Base4Form,
  Base5Form,
  Base7Form,
  Base8Form,
  formatHmsToHm,
  Free1Form,
  OptimizationForm,
  ParttimesForm,
  RestBetweenShiftsForm,
  Rhythm2Form,
  Rhythm3Form,
  Types1Form,
  Types2Form,
  Types3Form,
  Types5Form,
  Types6Form,
  Types7Form,
  VacationForm,
  Worktime1Form,
} from "components/setting/team_group/setting_boxes";

import type {
  TeamGroupSettings_fragment$key as Key,
  TeamGroupSettingsFormValues,
} from "./types";

const fragment = graphql`
  fragment TeamGroupSettings_fragment on TeamGroupSettingSnapshotNode {
    id
    constraintModules
    mipgapLimit
    periodLengthWeeks
    uniqueWeeks
    wrapAroundPeriodEnd
    constraintModulesBridge
    hourlyCost
    hoursPerWeek
    shiftsPerWeek
    shiftsEmploymentDegreeFactor
    shiftsEmploymentDegreeLogic
    totalHoursMargin
    totalShiftsMargin
    timebankMode
    offsetTimebankImbalance
    maxTimebankPeriod
    minTimebankPeriod
    maxTimebankTotal
    minTimebankTotal
    dayTypeCadenceWeight
    optimizeDayTypeFairness
    optimizeDayTypeFairnessWeekend
    dayTypeFairnessWeight
    morningStartsNightEnds
    eveningStartsMorningEnds
    nightStartsEveningEnds
    shiftDayTypeDistributionSoft
    shiftDayTypeAllowedErrorMargin
    dayShiftDistributionShare
    eveningShiftDistributionShare
    nightShiftDistributionShare
    fullDayShiftDistributionShare
    dayTypeDistributionWeight
    fullDayDuration
    minDailyRestAbHours
    minDailyRestAbSoft
    minDailyRestAbWeight
    dayBreakAlternatives
    minWeeklyRestAbHours
    weeklyDailyCanOverlap
    minWeeklyRestAbSoft
    minWeeklyRestAbWeight
    weekBreakHourAlternatives
    weekBreakDaysAlternatives
    minDailyRestHours
    minDailyRestSoft
    minDailyRestWeight
    minWeeklyRestHours
    maxConsecutiveWorkDays
    maxConsecutiveWorkDaysSoft
    minConsecutiveWorkDays
    minConsecutiveWorkDaysSoft
    consecutiveWorkDaysWeight
    maxConsecutiveFreeDays
    maxConsecutiveFreeDaysSoft
    minConsecutiveFreeDays
    minConsecutiveFreeDaysSoft
    consecutiveFreeDaysWeight
    clockwiseRotationLimitHours
    clockwiseRotationSoft
    clockwiseRotationWeight
    weekendStartsAt
    weekendStartsAtDay
    weekendEndsAt
    weekendEndsAtDay
    workEveryXWeekend
    freeEveryXOfYWeekends
    freeWeekendConstraintTypeEveryX
    freeWeekendConstraintTypeXOfY
    freeWeekendSoft
    freeWeekendWeight
    workEveryUnfreeWeekend
    workEveryUnfreeWeekendSoft
    maximizeApprovedWishes
    optimizeApprovedWishesDistributionBetweenEmployees
    wishedFreeDaysWeight
    adminHoursMin
    adminHoursMax
    resourceHoursMin
    resourceHoursMax
    allowSplitShifts
    optimizeSplitShiftsDistributionBetweenEmployees
    splitShiftsWeight
    optimizeResourceDistributionWithinTeams
    optimizeResourceDistributionBetweenTeams
    optimizeResourceDistributionBetweenEmployees
    resourceShiftsWeight
    optimizeEvenShiftTypeDistributionBetweenEmployees
    shiftTypeDistributionWeight
    nightShiftRestriction
    nightShiftRotation
    comprestCanCutDaybreakBilagaj
    comprestCanCutDaybreakAbjour
    prohibitedShiftTypes {
      edges {
        node {
          id
        }
      }
    }
    shiftsLaterEndNotOk {
      edges {
        node {
          id
        }
      }
    }
    shiftsLaterStartNotOk {
      edges {
        node {
          id
        }
      }
    }
    shiftsEarlierStartNotOk {
      edges {
        node {
          id
        }
      }
    }
    shiftsEarlierEndNotOk {
      edges {
        node {
          id
        }
      }
    }
    schedule {
      teams {
        edges {
          node {
            demandSetting {
              id
              allowUnderstaffing
              understaffingWeight
              weekendUnderstaffingWeight
              overstaffingWeight
              weekendOverstaffingWeight
              team {
                id
                name
                color
              }
            }
          }
        }
      }
      competences {
        edges {
          node {
            demandSetting {
              id
              allowUnderstaffing
              understaffingWeight
              competence {
                name
                color
              }
            }
          }
        }
      }
    }
    cadenceRestrictions
    workDayCadenceRestrictions
  }
`;

type Shift = ParttimeShift & Base3Shift;

type Props = {
  fragmentRef: Key;
  ruleId: string;
  groupName: string;
  groupId: string;
  shifts: ReadonlyArray<Shift>;
};

type GroupAdvancedProps = Pick<Props, "groupId" | "groupName" | "ruleId"> & {
  shifts: ReadonlyArray<ParttimeShift>;
};

function GroupAdvanced({
  ruleId,
  groupId,
  groupName,
  shifts,
}: GroupAdvancedProps) {
  const periodLengthWeeks =
    useFormikState<number>("periodLengthWeeks")?.value ?? 0;
  const hoursPerWeek = useFormikState<number>("hoursPerWeek")?.value ?? 0;
  const shiftsPerWeek = useFormikState<number>("shiftsPerWeek")?.value ?? 0;

  return (
    <>
      <Visible visible={ruleId === "advanced-0"}>
        <OptimizationForm groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "advanced-1"}>
        <ParttimesForm
          shifts={shifts}
          groupName={groupName}
          periodLengthWeeks={periodLengthWeeks}
          shiftsPerWeek={shiftsPerWeek}
          hoursPerWeek={hoursPerWeek}
          readOnly
        />
      </Visible>
      <Visible visible={ruleId === "advanced-2"}>
        <RestBetweenShiftsForm groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "advanced-3"}>
        <ClockwiseShiftRotationOldForm readOnly />
      </Visible>
      <Visible visible={ruleId === "advanced-4"}>
        <WishedDaysOffOldForm readOnly />
      </Visible>
      <Visible visible={ruleId === "advanced-5"}>
        <VacationForm groupId={groupId} groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "advanced-6"}>
        <NightShiftsOldForm readOnly />
      </Visible>
    </>
  );
}

export function TeamGroupSettings({
  fragmentRef,
  ruleId,
  groupId,
  groupName,
  shifts,
}: Props) {
  const settings = useFragment<Key>(fragment, fragmentRef);
  const { periodLengthWeeks, shiftsPerWeek } = settings;
  const totalShifts = shiftsPerWeek * periodLengthWeeks;

  const initialValues = useMemo<TeamGroupSettingsFormValues>(
    () => ({
      ...settings,
      morningStartsNightEnds: formatHmsToHm(settings.morningStartsNightEnds),
      eveningStartsMorningEnds: formatHmsToHm(
        settings.eveningStartsMorningEnds,
      ),
      nightStartsEveningEnds: formatHmsToHm(settings.nightStartsEveningEnds),
      dayShiftDistributionShare: settings.dayShiftDistributionShare * 100,
      eveningShiftDistributionShare:
        settings.eveningShiftDistributionShare * 100,
      nightShiftDistributionShare: settings.nightShiftDistributionShare * 100,
      fullDayShiftDistributionShare:
        settings.fullDayShiftDistributionShare * 100,
      prohibitedShiftTypes: connectionToArray(
        settings.prohibitedShiftTypes,
      ).map((s) => s.id),
      shiftsLaterStartNotOk: connectionToArray(
        settings.shiftsLaterStartNotOk,
      ).map((s) => s.id),
      shiftsLaterEndNotOk: connectionToArray(settings.shiftsLaterEndNotOk).map(
        (s) => s.id || "",
      ),
      shiftsEarlierStartNotOk: connectionToArray(
        settings.shiftsEarlierStartNotOk,
      ).map((s) => s.id),
      shiftsEarlierEndNotOk: connectionToArray(
        settings.shiftsEarlierEndNotOk,
      ).map((s) => s.id),
      teamDemandSettings: connectionToArray(settings.schedule.teams).map(
        (t) => t.demandSetting,
      ),
      competenceDemandSettings: connectionToArray(
        settings.schedule.competences,
      ).map((c) => c.demandSetting),
      cadenceRestrictions: JSON.parse(
        settings?.cadenceRestrictions ?? "[]",
      ).map(toCamelCase),
      workDayCadenceRestrictions: JSON.parse(
        settings?.workDayCadenceRestrictions ?? "[]",
      ).map(toCamelCase),
    }),
    [settings],
  );

  return (
    <FormikMock initialValues={initialValues}>
      <Visible visible={ruleId === "ab-0"}>
        <AB1Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "ab-1"}>
        <AB2Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "d-1"}>
        <Base2Form
          groupName={groupName}
          periodLengthWeeks={periodLengthWeeks}
          readOnly
        />
      </Visible>
      <Visible visible={ruleId === "d-2"}>
        <Base3Form groupName={groupName} readOnly shiftTypes={shifts} />
      </Visible>
      <Visible visible={ruleId === "d-3"}>
        <Base4Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "b-4"}>
        <Base5Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "b-6"}>
        <Base7Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "d-4"}>
        <Base8Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "w-0"}>
        <Free1Form
          groupName={groupName}
          readOnly
          periodLengthWeeks={periodLengthWeeks}
        />
      </Visible>
      <Visible visible={ruleId === "r-1"}>
        <Rhythm2Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "r-2"}>
        <Rhythm3Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "s-0"}>
        <Types1Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "s-1"}>
        <Types2Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "s-2"}>
        <Types3Form groupName={groupName} readOnly shiftTypes={shifts} />
      </Visible>
      <Visible visible={ruleId === "n-0"}>
        <Types5Form groupName={groupName} readOnly totalShifts={totalShifts} />
      </Visible>
      <Visible visible={ruleId === "n-1"}>
        <Types6Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "n-2"}>
        <Types7Form groupName={groupName} readOnly />
      </Visible>
      <Visible visible={ruleId === "t-0"}>
        <Worktime1Form readOnly />
      </Visible>
      <Visible visible={ruleId === "t-1"}>
        <Worktime2OldForm readOnly />
      </Visible>
      <GroupAdvanced
        ruleId={ruleId}
        groupId={groupId}
        groupName={groupName}
        shifts={shifts}
      />
    </FormikMock>
  );
}

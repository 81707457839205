import { Stack, Typography } from "@mui/material";
import { alpha, lighten, styled } from "@mui/material/styles";

type BlockedTimeSlotLabelProps = {
  className?: string;
  name: string;
};

const ColorBox = styled("div")(({ theme }) => {
  const hassan = theme.palette.hassan[100];
  const annKatrin = theme.palette.annkatrin[100];
  const hav = theme.palette.hav.main;
  const skum = theme.palette.skum.main;
  const shift = lighten(theme.palette.svart[100], 0.45);
  return {
    width: 64,
    height: 13,
    borderRadius: 4,
    "&.va": {
      backgroundColor: alpha(hassan, 0.7),
      borderColor: alpha(hassan, 1),
      borderWidth: 2,
      borderStyle: "solid",
    },
    "&.wa": {
      backgroundColor: alpha(annKatrin, 0.7),
      borderColor: alpha(annKatrin, 1),
      borderWidth: 2,
      borderStyle: "solid",
    },
    "&.oa": {
      backgroundColor: alpha(hav, 0.7),
      borderColor: alpha(hav, 1),
      borderWidth: 2,
      borderStyle: "solid",
    },
    "&.pa": {
      backgroundColor: alpha(skum, 1),
      borderColor: alpha(skum, 1),
      borderWidth: 2,
      borderStyle: "solid",
    },
    "&.ka": {
      backgroundColor: alpha(shift, 1),
      borderColor: alpha(shift, 1),
      borderWidth: 2,
      borderStyle: "solid",
    },
  };
});

export function BlockedTimeSlotLabel({
  className,
  name,
}: BlockedTimeSlotLabelProps) {
  return (
    <Stack direction="row" gap={0.75} alignItems="center">
      <ColorBox className={className} sx={{ width: 40 }} />
      <Typography fontSize={13}>{name}</Typography>
    </Stack>
  );
}

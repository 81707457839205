import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

/** Adds scroll-into-view logic on hash. */
export function useScrollToHash() {
  const { hash } = useLocation();
  const id = useMemo(() => (hash ? hash.slice(1) : ""), [hash]);
  useScrollToAnchor(id);
}

/** Adds scroll-into-view logic. */
export function useScrollToAnchor(id: string) {
  useEffect(() => {
    if (id && document.getElementById(id)) {
      setTimeout(() => {
        scrollIntoView(id);
      }, 100);
    }
  }, [id]);
}

export function scrollIntoView(
  elementId: string,
  opts?: ScrollIntoViewOptions,
) {
  document
    .getElementById(elementId)
    ?.scrollIntoView(opts ?? { behavior: "smooth", block: "start" });
}

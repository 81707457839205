import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import {
  Shift,
  ShiftSelect,
} from "../../components/setting/common/forms/common/ShiftSelect";

import { shiftDistributionSetting } from "./ShiftDistributionSetting";

type Props = {
  shiftTypes: ReadonlyArray<Shift>;
  disabled?: boolean;
  readOnly?: boolean;
};

export function ShiftDistributionForm({
  shiftTypes: options,
  disabled: disabledProp,
  readOnly,
}: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    shiftDistributionSetting.moduleName,
  );
  const formDisabled = disabled || disabledProp;

  return (
    <PaperWrapper>
      <Stack gap={1}>
        <Typography variant="h4">Otillåtna passtyper</Typography>
        <Typography>
          Schemats olika passtyper anges under fliken som heter{" "}
          <Link to="/shifts">
            <b>Passprofil</b>
          </Link>
          . Här nedan kan du köra över de inställningarna genom att ange
          otillåtna passtyper. Detta är alltid en hård regel.
        </Typography>
        <ShiftSelect
          name="prohibitedShiftTypes"
          options={options}
          disabled={formDisabled}
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

/**
 * @generated SignedSource<<28454bc27e236c3ecf19997d64b16f1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiScheduleSolutionStatusChoices = "ERROR" | "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "QUEUED" | "RUNNING" | "RUNNING_WITH_INFEASIBILITY_VENTS" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Statistics_fragment$data = {
  readonly id: string;
  readonly solutionStatus: ApiScheduleSolutionStatusChoices;
  readonly " $fragmentType": "Statistics_fragment";
};
export type Statistics_fragment$key = {
  readonly " $data"?: Statistics_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Statistics_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Statistics_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "solutionStatus",
      "storageKey": null
    }
  ],
  "type": "ScheduleNode",
  "abstractKey": null
};

(node as any).hash = "ee9af05fc0a2e93d0aec1bb4b8d99f6b";

export default node;

/**
 * @generated SignedSource<<aca6cfddd7c228259b9ecdd982be4147>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCompetenceInput = {
  clientMutationId?: string | null;
  color: string;
  description: string;
  name: string;
};
export type CreateCompetenceMutation$variables = {
  input: CreateCompetenceInput;
};
export type CreateCompetenceMutation$data = {
  readonly createCompetence: {
    readonly competence: {
      readonly archivedAt: string | null;
      readonly color: string;
      readonly description: string;
      readonly id: string;
      readonly isActive: boolean;
      readonly name: string;
    } | null;
  } | null;
};
export type CreateCompetenceMutation = {
  response: CreateCompetenceMutation$data;
  variables: CreateCompetenceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCompetencePayload",
    "kind": "LinkedField",
    "name": "createCompetence",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "competence",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCompetenceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCompetenceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "42ca78a9ba9d82d97104817ee5dd4733",
    "id": null,
    "metadata": {},
    "name": "CreateCompetenceMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCompetenceMutation(\n  $input: CreateCompetenceInput!\n) {\n  createCompetence(input: $input) {\n    competence {\n      id\n      name\n      description\n      color\n      isActive\n      archivedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a87b34ba373d483e015e7141819b711e";

export default node;

import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import type {
  UserRuleGroupsContext_ruleGroups$data as Data,
  UserRuleGroupsContext_ruleGroups$key as Key,
} from "./types";

export type ContextType = Data;

const fragment = graphql`
  fragment UserRuleGroupsContext_ruleGroups on RuleGroupNode
  @relay(plural: true) {
    id
    name
    teamGroup {
      id
      name
    }
  }
`;

const UserRuleGroupsContext = createContext<ContextType>([]);

export const UserRuleGroupsProvider = ({
  fragmentRef,
  children,
}: PropsWithChildren<{ fragmentRef: Key }>) => {
  const value = useFragment<Key>(fragment, fragmentRef);
  return <UserRuleGroupsContext.Provider value={value} children={children} />;
};

export const useUserRuleGroupsContext = () => {
  const data = useContext(UserRuleGroupsContext);
  if (!data) {
    throw new Error(
      "useUserRuleGroupsContext must be surrounded by UserRuleGroupsProvider",
    );
  }

  return data;
};

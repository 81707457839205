/**
 * @generated SignedSource<<3bb3d3507a62df4afcfc6e31a47ca07d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserAdminFormMutation$variables = {
  groupIds: ReadonlyArray<string>;
  teamIds: ReadonlyArray<string>;
  userId: string;
};
export type UserAdminFormMutation$data = {
  readonly setUserAdminOf: {
    readonly ok: boolean | null;
    readonly user: {
      readonly groupAdminOf: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null;
        } | null>;
      };
      readonly id: string;
      readonly teamAdminOf: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type UserAdminFormMutation = {
  response: UserAdminFormMutation$data;
  variables: UserAdminFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "groupAdminOf",
            "variableName": "groupIds"
          },
          {
            "kind": "Variable",
            "name": "teamAdminOf",
            "variableName": "teamIds"
          },
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "SetUserAdminOfPayload",
    "kind": "LinkedField",
    "name": "setUserAdminOf",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNodeConnection",
            "kind": "LinkedField",
            "name": "groupAdminOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamGroupNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamGroupNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "teamAdminOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAdminFormMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserAdminFormMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "686d2b083808ebf1a5caa800ee3dabad",
    "id": null,
    "metadata": {},
    "name": "UserAdminFormMutation",
    "operationKind": "mutation",
    "text": "mutation UserAdminFormMutation(\n  $userId: ID!\n  $groupIds: [ID!]!\n  $teamIds: [ID!]!\n) {\n  setUserAdminOf(input: {userId: $userId, groupAdminOf: $groupIds, teamAdminOf: $teamIds}) {\n    ok\n    user {\n      id\n      groupAdminOf {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      teamAdminOf {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5e191dd957b602447db7c6ff4754c39";

export default node;

import { Dialog, Typography as Text } from "@mui/material";
import { Form as FormikForm, Formik } from "formik";

import { useCreateCompetence } from "../mutations";
import type { CreateCompetenceInput as FormValues } from "../types";

import {
  CompetenceForm,
  competenceFormValidationSchema as validationSchema,
} from "./common";

type Props = {
  open: boolean;
  onClose: () => void;
};

const DEFAULT_COLOR = "#000000";

function Title() {
  return <Text variant="h2">Skapa ny kompetens</Text>;
}

export function CreateCompetenceForm({ open, onClose }: Props) {
  const [commit] = useCreateCompetence();
  const initialValues: FormValues = {
    name: "",
    description: "",
    color: DEFAULT_COLOR,
  };

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } })
      .then(onClose)
      .catch((error) => console.error(error));
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <FormikForm autoComplete="off">
          <CompetenceForm
            title={<Title />}
            submitText="Skapa kompetens"
            onClose={onClose}
          />
        </FormikForm>
      </Formik>
    </Dialog>
  );
}

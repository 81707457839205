/**
 * @generated SignedSource<<8b273fc512c19bd1160ebb09ee4923fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PeriodPageQuery$variables = {
  groupId: string;
};
export type PeriodPageQuery$data = {
  readonly currentPeriod: {
    readonly id: string;
  } | null;
  readonly periods: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"PeriodTimeline_fragment">;
  }>;
  readonly settingForTeamGroup: {
    readonly periodLengthWeeks: number;
  } | null;
};
export type PeriodPageQuery = {
  response: PeriodPageQuery$data;
  variables: PeriodPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "groupId",
    "variableName": "groupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "PeriodNode",
  "kind": "LinkedField",
  "name": "currentPeriod",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "groupId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodLengthWeeks",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PeriodPageQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "periods",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PeriodTimeline_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PeriodPageQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PeriodNode",
        "kind": "LinkedField",
        "name": "periods",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfWeeks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleNode",
            "kind": "LinkedField",
            "name": "masterSchedule",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleNodeConnection",
            "kind": "LinkedField",
            "name": "schedules",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b210782154738a2463ea623c2c1b6d6c",
    "id": null,
    "metadata": {},
    "name": "PeriodPageQuery",
    "operationKind": "query",
    "text": "query PeriodPageQuery(\n  $groupId: ID!\n) {\n  currentPeriod(groupId: $groupId) {\n    id\n  }\n  periods(groupId: $groupId) {\n    ...PeriodTimeline_fragment\n    id\n  }\n  settingForTeamGroup(teamGroupId: $groupId) {\n    periodLengthWeeks\n    id\n  }\n}\n\nfragment PeriodTimeline_fragment on PeriodNode {\n  id\n  name\n  startDate\n  endDate\n  numberOfWeeks\n  masterSchedule {\n    id\n  }\n  schedules {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ebab20c7d44811c7a66dec37a5241b4";

export default node;

import { Paper, Skeleton, Stack } from "@mui/material";

import { BlockedTimeSlotLabels } from "components/blocked_time_slot";

export function BlockedTimeSlotFiltersPlaceholder() {
  return (
    <Stack direction="row" gap={2} flexWrap="wrap">
      <Skeleton width={200} height={38.56} />
      <Skeleton width={200} height={38.56} />
    </Stack>
  );
}

export function BlockedTimeslotTimelinePlaceholder() {
  return <Stack gap={1}></Stack>;
}

export function BlockedTimeSlotPlaceholder() {
  return (
    <Stack gap={2}>
      <BlockedTimeSlotFiltersPlaceholder />
      <BlockedTimeslotTimelinePlaceholder />
      <Paper variant="box">
        <BlockedTimeSlotLabels />
      </Paper>
    </Stack>
  );
}

import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const Types6InfoTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Dag-/Kväll-/Natt-rättvisa</b>?
  </InfoTitle>
);

const Types6InfoContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export function Types6Info() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal title={<Types6InfoTitle />}>
        <Types6InfoContent />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

import {
  InfoButtonAndModal,
  InfoModalProvider,
  InfoText,
  InfoTitle,
} from "components/layout/InfoModal";

const Free1InfoTitle = () => (
  <InfoTitle>
    Hur fungerar <b>Sammanhängande ledighet</b>?
  </InfoTitle>
);

const Free1InfoContent = () => (
  <InfoText>Här kommer fyllas på med förklarande text.</InfoText>
);

export function Free1Info() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal title={<Free1InfoTitle />}>
        <Free1InfoContent />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

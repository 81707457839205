import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";

export type VerifyCbProps = {
  editOne?: boolean | null;
  editFutureRecurrences?: boolean | null;
};

type ConfirmCallback = ({
  editOne,
  editFutureRecurrences,
}: VerifyCbProps) => void;

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: ConfirmCallback;
};

export default function VerifyDialog({ open, onClose, onConfirm }: Props) {
  function handleConfirm({
    editOne = false,
    editFutureRecurrences = false,
  }: Partial<VerifyCbProps>) {
    onConfirm({ editOne, editFutureRecurrences });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant="h2">Är du säker?</DialogTitle>
      <Divider />

      <DialogContent>
        <DialogContentText>
          Du har ändrat datumet för en händelse som upprepas. Vill du ändra
          datumet för bara den här händelsen eller för alla upprepningar?
        </DialogContentText>
      </DialogContent>
      <Divider />

      <DialogActions>
        <Button variant="cancel" onClick={onClose}>
          Avbryt
        </Button>
        <Button
          variant="primary"
          onClick={() => handleConfirm({ editOne: true })}
        >
          Ändra bara den här händelsen
        </Button>
        <Button
          variant="primary"
          onClick={() => handleConfirm({ editFutureRecurrences: true })}
        >
          Ändra alla upprepningar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

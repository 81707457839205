import type { Unwrap } from "relay-help/arrays";

import type { ShiftTimeline_fragment$data as Data } from "./__generated__/ShiftTimeline_fragment.graphql";

export type {
  ApiShiftDayTypeChoices as ShiftDayType,
  ShiftTimeline_fragment$data,
  Type as ShiftTimelineType,
} from "./__generated__/ShiftTimeline_fragment.graphql";

export type ShiftTimelineItem = Unwrap<Data["shifts"]>;

export enum shiftTimelineTypeEnum {
  B = "B",
  J = "J",
  M = "M",
  P = "P",
  S = "S",
  Z = "Z",
}

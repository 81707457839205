import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import { ShiftTimeline_fragment$key as Key } from "./__generated__/ShiftTimeline_fragment.graphql";
import { useShiftTimeline } from "./hooks";
import { TimelineRenderer } from "./TimelineRenderer";

const fragment = graphql`
  fragment ShiftTimeline_fragment on ShiftTimeline {
    startDate
    endDate
    shifts {
      shiftId
      startTime
      endTime
      breakTime
      shiftType
      dayType
    }
    shiftTypes {
      id
      name
    }
  }
`;

type Props = {
  fragmentRef: Key;
};

export function ShiftTimeline({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const { groups, items } = useShiftTimeline(data);
  const start = useMemo(() => new Date(data.startDate), [data.startDate]);
  const end = useMemo(() => new Date(data.endDate), [data.endDate]);

  return (
    <TimelineRenderer
      groups={groups}
      items={items}
      start={start}
      end={end}
      sx={{ pr: 3.25 }}
    />
  );
}

/**
 * @generated SignedSource<<4220832740c266c95f11d9893cda7068>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DayRestSettingRuleGroup_fragment$data = {
  readonly dayBreakAlternatives: ReadonlyArray<number>;
  readonly id: string;
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "DayRestSettingRuleGroup_fragment";
};
export type DayRestSettingRuleGroup_fragment$key = {
  readonly " $data"?: DayRestSettingRuleGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DayRestSettingRuleGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DayRestSettingRuleGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayBreakAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "0c14bf1b7060ab8a206e02f35df25572";

export default node;

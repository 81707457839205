import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FieldArray } from "formik";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import { shiftDayTypes } from "components/shifts/types";

import { CadenceRestriction } from "./CadenceRestriction";
import type { CadenceRestrictionType } from "./types";

type FormValues = {
  cadenceRestrictions: CadenceRestrictionType[];
};

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

type FooterProps = {
  disabled?: boolean;
  readOnly?: boolean;
  onAdd: () => void;
};

const defaultItem: CadenceRestrictionType = {
  dayType: shiftDayTypes.EVENING,
  maxCadence: 5,
  calendarDays: 10,
  isSoft: true,
};

function Footer({ disabled, readOnly, onAdd }: FooterProps) {
  if (readOnly) {
    return null;
  }

  return (
    <Box sx={{ alignSelf: "flex-end" }}>
      <Button
        variant="primary"
        onClick={onAdd}
        disabled={disabled}
        endIcon={<AddIcon />}
      >
        Ny regel
      </Button>
    </Box>
  );
}

export function CadenceRestrictions({ name, disabled, readOnly }: Props) {
  const { value } = useFormikState<CadenceRestrictionType[]>(name);
  if (readOnly && value.length === 0) {
    return (
      <Typography fontStyle="italic">
        Här fanns inga regler tillagda!
      </Typography>
    );
  }
  return (
    <Stack gap={2}>
      <FieldArray name={name}>
        {({ push, remove }: FormikFieldArrayFn<FormValues>) => (
          <>
            {(value || []).map((_: any, index: number) => (
              <CadenceRestriction
                key={index}
                name={`${name}.${index}`}
                onRemove={() => remove(index)}
                disabled={disabled}
                readOnly={readOnly}
              />
            ))}
            <Footer
              disabled={disabled}
              readOnly={readOnly}
              onAdd={() => push(defaultItem)}
            />
          </>
        )}
      </FieldArray>
    </Stack>
  );
}

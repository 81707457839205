import type { ComponentProps } from "react";

import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<ComponentProps<typeof GroupSettingCard>, "title">;

const TITLE = "Resurspass";

export function ResourcePoolCard(props: Props) {
  return <GroupSettingCard title={TITLE} {...props} />;
}

ResourcePoolCard.id = "resourcepool" as const;
ResourcePoolCard.title = TITLE;

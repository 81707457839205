/**
 * @generated SignedSource<<6f9491224cb7954e1b471e548283aba3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminTeamsQuery$variables = {};
export type AdminTeamsQuery$data = {
  readonly allTeams: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TeamsTable_fragment">;
  }>;
};
export type AdminTeamsQuery = {
  response: AdminTeamsQuery$data;
  variables: AdminTeamsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminTeamsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "allTeams",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamsTable_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminTeamsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "allTeams",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58752b5fcad55231ea8940f83e153292",
    "id": null,
    "metadata": {},
    "name": "AdminTeamsQuery",
    "operationKind": "query",
    "text": "query AdminTeamsQuery {\n  allTeams {\n    ...TeamsTable_fragment\n    id\n  }\n}\n\nfragment TeamsTable_fragment on TeamNode {\n  id\n  name\n  color\n  isActive\n  archivedAt\n  group {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9fd232bf1eb17447fa7f1201e54e218";

export default node;

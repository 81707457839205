import { PropsWithChildren } from "react";
import { Box } from "@mui/material";

const PageContainer = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      id="page"
      minWidth="100vw"
      minHeight="100vh"
      width="100%"
      height="100%"
      sx={{ bgcolor: "ture.10" }}
    >
      {children}
    </Box>
  );
};

export default PageContainer;

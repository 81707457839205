import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { optimizationRulesUrl } from "utils/urls";

import { FormikSwitchWithTitleAndDesc } from "./common";

type Props = {
  readOnly?: boolean;
};

export function ShowAdvancedSettingsField({ readOnly }: Props) {
  return (
    <FormikSwitchWithTitleAndDesc
      name="showAdvancedSettings"
      title="Visa avancerade optimeringsregler"
      description={
        <Typography>
          Om du slår på de Avancerade optimeringsinställningar så kommer
          ytterligare en sektion av optimeringsregler ("Avancerat") visas på
          sidan <Link to={optimizationRulesUrl()}>Optimeringsregler</Link>.
        </Typography>
      }
      readOnly={readOnly}
    />
  );
}

/**
 * @generated SignedSource<<f106a5da3f956d95e1b97f1a1a764abd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LogoutMutation$variables = {};
export type LogoutMutation$data = {
  readonly deleteRefreshTokenCookie: {
    readonly deleted: boolean;
  } | null;
  readonly deleteTokenCookie: {
    readonly deleted: boolean;
  } | null;
  readonly revokeToken: {
    readonly revoked: number;
  } | null;
};
export type LogoutMutation = {
  response: LogoutMutation$data;
  variables: LogoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deleted",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Revoke",
    "kind": "LinkedField",
    "name": "revokeToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "revoked",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DeleteJSONWebTokenCookie",
    "kind": "LinkedField",
    "name": "deleteTokenCookie",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DeleteRefreshTokenCookie",
    "kind": "LinkedField",
    "name": "deleteRefreshTokenCookie",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LogoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LogoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "25f877bb381b3f1c448dd235c1e97252",
    "id": null,
    "metadata": {},
    "name": "LogoutMutation",
    "operationKind": "mutation",
    "text": "mutation LogoutMutation {\n  revokeToken {\n    revoked\n  }\n  deleteTokenCookie {\n    deleted\n  }\n  deleteRefreshTokenCookie {\n    deleted\n  }\n}\n"
  }
};
})();

(node as any).hash = "49cb28eba6cce2a421730a4f79423a2c";

export default node;

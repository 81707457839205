import { useCallback } from "react";
import type { SelectChangeEvent, SelectProps } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import type { ApiShiftPartPartTypeChoices as ShiftPartType } from "../types";
import { shiftPartTypeChoices, TranslateShiftPartType } from "../types";

export type ShiftPartTypeOption = {
  value: ShiftPartType;
  label: string;
};

type Props = SelectProps<ShiftPartType>;
type FormikProps = Omit<Props, "value" | "onChange"> & {
  name: string;
};

const partTypeOptions: ShiftPartTypeOption[] = shiftPartTypeChoices.map(
  (t) => ({
    value: t as ShiftPartType,
    label: TranslateShiftPartType(t) || "",
  }),
);

export function ShiftPartSelect(props: Props) {
  return (
    <Select<ShiftPartType> size="small" {...props}>
      {partTypeOptions.map((o: ShiftPartTypeOption) => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export function FormikShiftPartSelect({ name, ...props }: FormikProps) {
  const { value, setValue } = useFormikState<ShiftPartType>(name);
  const onChange = useCallback(
    (e: SelectChangeEvent<ShiftPartType>) =>
      setValue(e.target.value as ShiftPartType),
    [setValue],
  );

  return <ShiftPartSelect {...props} value={value} onChange={onChange} />;
}

import type { PropsWithChildren, ReactNode } from "react";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import type { CSSObject } from "@mui/material/styles";

type Props = {
  title?: ReactNode;
  infoButton?: ReactNode;
  sx?: CSSObject;
};

export default function SettingBox({
  title,
  children,
  infoButton,
  sx = {},
}: PropsWithChildren<Props>) {
  return (
    <Paper variant="box" sx={{ width: "100%", p: 2.5, ...sx }}>
      <Stack gap={2}>
        {!!title && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h3">{title}</Typography>
              {infoButton}
            </Stack>
            <Divider sx={{ mb: 1 }} />
          </>
        )}
        {children}
      </Stack>
    </Paper>
  );
}

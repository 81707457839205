import type { SvgIconProps } from "@mui/material";
import { SvgIcon } from "@mui/material";

export const LIcon = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 18"
    strokeWidth={1}
    {...props}
    sx={{ fill: "none", width: 9, height: 18, ...(props?.sx ?? {}) }}
  >
    <path
      d="M1 0L1 17H9"
      stroke="#263238"
      strokeOpacity="0.6"
      strokeLinejoin="round"
      strokeDasharray="2 2"
    />
  </SvgIcon>
);

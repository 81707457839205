import type { PropsWithChildren, ReactNode } from "react";
import type { StackProps } from "@mui/material";
import { Box, Stack } from "@mui/material";

type Props = PropsWithChildren<
  StackProps & {
    Left?: ReactNode;
    leftWidth?: number;
  }
>;

export const LeftSideAndContent = ({
  Left,
  children,
  leftWidth = 90,
  ...props
}: Props) => (
  <Stack direction="row" gap={2} {...props}>
    <Box sx={{ width: leftWidth }}>{Left}</Box>
    {children}
  </Stack>
);

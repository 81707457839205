import { Stack, Typography } from "@mui/material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FormAccordion";
import type { MemberTeamGroupType } from "components/organisation/types";

import { TeamGroupSelect } from "./TeamGroupSelect";

type Props = {
  expanded: boolean;
  onChange: () => void;
  teamGroups: MemberTeamGroupType[];
};

type ContentProps = {
  teamGroups: MemberTeamGroupType[];
  groupsField: string;
};

export function TeamAccordionContent({
  teamGroups,
  groupsField,
}: ContentProps) {
  return (
    <Stack gap={1}>
      <TeamGroupSelect
        name={groupsField}
        teamGroups={teamGroups}
        disableCloseOnSelect
      />
    </Stack>
  );
}

export function TeamAccordion({ expanded, onChange, teamGroups }: Props) {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Typography variant="h4">Enhets- och avdelningstillhörighet</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TeamAccordionContent
          teamGroups={teamGroups}
          groupsField="teamGroups"
        />
      </AccordionDetails>
    </Accordion>
  );
}

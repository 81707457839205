import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { DateTime } from "luxon";
import { connectionToArray } from "relay-help/arrays";

import { useCreateShifts } from "components/shifts/mutations/CreateShifts";
import { useSnackbar } from "components/Snackbar";

import type { CreateShiftsInput, ShiftPartInput } from "../types";

import { Footer } from "./Footer";
import ShiftFormDays from "./ShiftFormDays";
import type { ShiftForm_fragment$key as Key } from "./types";
import validationSchema from "./validator";

const fragment = graphql`
  fragment ShiftForm_fragment on ShiftGroup @relay(plural: true) {
    days
    shifts {
      id
      name
      start
      end
      breakTime
      shiftParts {
        edges {
          node {
            id
            start
            end
            partType
          }
        }
      }
    }
  }
`;

type Props = {
  fragmentRef: Key;
  teamGroupId: string;
  afterSubmit: () => Promise<void>;
};

function Form() {
  return (
    <FormikForm autoComplete="off">
      <Stack gap={2}>
        <ShiftFormDays name="shiftGroups" />
        <Footer />
      </Stack>
    </FormikForm>
  );
}

export function ShiftForm({ teamGroupId, fragmentRef, afterSubmit }: Props) {
  const { addSnack } = useSnackbar();
  const shifts = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useCreateShifts();

  const initialValues = useMemo<CreateShiftsInput>(
    () => ({
      teamGroupId,
      shiftGroups: (shifts || []).map((g) => ({
        days: [...g.days],
        shifts: g.shifts.map((s) => ({
          id: s.id,
          name: s.name,
          start: s.start.substring(0, 5),
          end: s.end.substring(0, 5),
          breakTime: s.breakTime,
          shiftParts: connectionToArray(s.shiftParts).map((p) => ({
            id: p.id,
            start: p.start.substring(0, 5),
            end: p.end.substring(0, 5),
            partType: p.partType,
          })),
        })),
      })),
    }),
    [teamGroupId, shifts],
  );

  async function onSubmit(values: CreateShiftsInput) {
    try {
      const hhmmToMillis = (hhmm: string) =>
        DateTime.fromFormat(hhmm, "HH:mm").toMillis();
      const sortShiftParts = (a: ShiftPartInput, b: ShiftPartInput) =>
        hhmmToMillis(a.start) - hhmmToMillis(b.start);

      const data = {
        teamGroupId,
        shiftGroups: values.shiftGroups.map((g) => ({
          days: g.days,
          shifts: g?.shifts.map((s) => ({
            id: s.id,
            name: s.name,
            start: s.start,
            end: s.end,
            breakTime: s.breakTime,
            shiftParts: [...(s.shiftParts || [])]
              .sort(sortShiftParts)
              .map((p) => ({
                id: p.id,
                start: p.start,
                end: p.end,
                partType: p.partType,
              })),
          })),
        })),
      };

      const res = await commit({ variables: { input: data } });
      if (res.createShifts?.ok) {
        addSnack({
          message: "Ändringarna sparades",
          severity: "success",
          closeAfterMs: 2000,
        });
        await afterSubmit();
      }
    } catch (e) {
      addSnack({
        message: "Något gick fel. Kunde inte spara ändringar",
        severity: "error",
        closeAfterMs: 2000,
      });
      console.error(e);
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <Form />
    </Formik>
  );
}

import { Suspense, useEffect, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import AddIcon from "@mui/icons-material/Add";
import { Button, Paper, Stack, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useTeamGroups } from "contexts/TeamGroupsContext";
import { useAdminParams } from "hooks";

import {
  CreateTeamGroupForm,
  useActivateTeamGroup,
  useArchiveTeamGroup,
  useDeleteTeamGroup,
} from "components/admin";
import Table from "components/admin/tables/TeamGroupsTable";
import type { TeamGroup } from "components/admin/types";
import DeleteModal from "components/common/DeleteModal";
import { AdminTeamGroupsPlaceholder as Placeholder } from "components/loading/pages";

import { AdminTeamGroupsQuery as Query } from "./__generated__/AdminTeamGroupsQuery.graphql";

const query = graphql`
  query AdminTeamGroupsQuery {
    allTeamGroups {
      ...TeamGroupsTable_fragment
    }
  }
`;

type RendererProps = {
  queryRef: PreloadedQuery<Query>;
  onDelete: (teamGroup: TeamGroup) => void;
  onActivate: (id: string) => void;
  onArchive: (id: string) => void;
};

function Actions({ onNew }: { onNew: () => void }) {
  return (
    <Button
      variant="primary"
      endIcon={<AddIcon />}
      onClick={onNew}
      sx={{ alignSelf: "flex-end" }}
    >
      Ny enhet
    </Button>
  );
}

function Renderer({
  queryRef,
  onDelete,
  onActivate,
  onArchive,
}: RendererProps) {
  const { allTeamGroups } = usePreloadedQuery<Query>(query, queryRef);
  const { setTeamGroupToEdit } = useTeamGroups();

  function onEdit(id: string) {
    setTeamGroupToEdit(id);
  }

  return (
    <Table
      fragmentRef={allTeamGroups}
      onEdit={onEdit}
      onArchive={onArchive}
      onActivate={onActivate}
      onDelete={onDelete}
    />
  );
}

export function AdminTeamGroups() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const { newTeamGroup: newOpen, setNewTeamGroup: setNewOpen } =
    useAdminParams();
  const [toDelete, setToDelete] = useState<TeamGroup | null>(null);
  const [activateTeamGroup] = useActivateTeamGroup();
  const [archiveTeamGroup] = useArchiveTeamGroup();
  const [deleteTeamGroup] = useDeleteTeamGroup();

  useEffect(() => {
    loadQuery({});
  }, [loadQuery]);

  const closeCreate = () => setNewOpen(false);
  const closeDelete = () => setToDelete(null);

  function onDelete(id?: string) {
    if (!id) return;
    deleteTeamGroup({ variables: { id } }).then(closeDelete);
  }

  function onArchive(id?: string) {
    if (!id) return;
    archiveTeamGroup({ variables: { id } });
  }

  function onActivate(id?: string) {
    if (!id) return;
    activateTeamGroup({ variables: { id } });
  }

  return (
    <>
      <Paper variant="box" sx={{ p: 1.5 }}>
        <Stack gap={2}>
          <Actions onNew={() => setNewOpen(true)} />
          <Suspense fallback={<Placeholder />}>
            {!!queryRef && (
              <Renderer
                queryRef={queryRef}
                onDelete={setToDelete}
                onActivate={onActivate}
                onArchive={onArchive}
              />
            )}
          </Suspense>
        </Stack>
      </Paper>
      <Suspense fallback={null}>
        <CreateTeamGroupForm open={newOpen} onClose={closeCreate} />
      </Suspense>
      <DeleteModal
        show={!!toDelete}
        onHide={closeDelete}
        onDeleteClick={() => onDelete(toDelete?.id)}
        deleteTitle="Radera enhet"
        deleteMessage={
          <Stack gap={2}>
            <Typography>
              Är du säker på att du vill radera enhet {toDelete?.name}? Detta
              går inte att ångra!
            </Typography>
            {!toDelete?.deleteable && (
              <Typography fontWeight="bold">
                Enheten kan inte raderas eftersom den är kopplad till{" "}
                {toDelete?.teams?.edges?.length} avdelning(ar). Du behöver först
                radera alla kopplade avdelningar innan du kan radera enheten.
              </Typography>
            )}
          </Stack>
        }
        buttonText="Radera"
        deleteDisabled={!toDelete?.deleteable}
      />
    </>
  );
}

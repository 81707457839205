import { MenuItem, Select } from "@mui/material";

type Props = {
  value: number;
  onChange: (value: number) => void;
};

export default function SignSelect({ value, onChange }: Props) {
  return (
    <Select
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      size="small"
      fullWidth
    >
      <MenuItem value={-1}>-</MenuItem>
      <MenuItem value={1}>+</MenuItem>
    </Select>
  );
}

import { DateTime } from "luxon";

// https://webreflection.medium.com/using-the-input-datetime-local-9503e7efdce
export function toDatetimeLocal(dateString: string | undefined) {
  if (!dateString) {
    return undefined;
  }
  return DateTime.fromJSDate(new Date(dateString)).toFormat(
    "yyyy-MM-dd'T'HH:mm:ss",
  );
}

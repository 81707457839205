import { useCallback, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Link } from "react-router-dom";
import {
  AccessTime as ClockIcon,
  CalendarMonth as CalendarIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Groups as ParticipantIcon,
} from "@mui/icons-material";
import type { IconProps } from "@mui/material";
import {
  List,
  ListItem,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks";
import { DateTime } from "luxon";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { isNonNull, useConnectionToArray } from "relay-help/arrays";

import DeleteModal from "components/common/DeleteModal";
import { ListItemWithIcon } from "components/common/ListItemWithIcon";
import { RowMenu } from "components/common/RowMenu";

import { useMeetingCadenceDescription } from "./hooks";
import { useDeleteMeeting } from "./mutations";
import { MeetingListScaffold } from "./Scaffolds";
import {
  MeetingList_meetings$data as Data,
  MeetingList_meetings$key as Key,
} from "./types";

type MeetingType = Unwrap<Data>;
type User = ExtractNode<MeetingType["users"]>;
type ToDelete = { id: string; name: string };

type Props = {
  fragmentRef: Key;
  refresh: () => Promise<void>;
};

type MeetingListRendererProps = {
  meetings: ReadonlyArray<MeetingType>;
  onDelete: (props: ToDelete) => void;
};

const fragment = graphql`
  fragment MeetingList_meetings on MeetingNode @relay(plural: true) {
    id
    name
    start
    end
    dayMatrix
    users {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;

const Title = ({ name }: Pick<MeetingType, "name">) => (
  <Typography fontWeight={400}>{name}</Typography>
);

const IconAndText: React.FC<{
  icon: React.ReactElement<IconProps>;
  text: string;
}> = ({ icon, text }) => (
  <Stack direction="row" gap={0.5} alignItems="center">
    {icon}
    <Typography color="text.secondary">{text}</Typography>
  </Stack>
);

const DisplayDescriptor = ({ dayMatrix }: Pick<MeetingType, "dayMatrix">) => (
  <IconAndText
    icon={<CalendarIcon color="action" />}
    text={useMeetingCadenceDescription(dayMatrix)}
  />
);

const formatTimeStr = (time: string) =>
  DateTime.fromISO(time).toFormat("HH:mm");

const DisplayTime = ({ start, end }: Pick<MeetingType, "start" | "end">) => (
  <IconAndText
    icon={<ClockIcon color="action" />}
    text={`${formatTimeStr(start)}-${formatTimeStr(end)}`}
  />
);

const DisplayParticipants = ({
  users,
}: {
  readonly users: ReadonlyArray<User>;
}) => (
  <Tooltip
    title={
      <List disablePadding>
        {users.map((u) => (
          <ListItem key={u.id}>{u.fullName}</ListItem>
        ))}
      </List>
    }
  >
    <span>
      <IconAndText
        icon={<ParticipantIcon color="action" />}
        text={`${users.length} deltagare`}
      />
    </span>
  </Tooltip>
);

const ActionMenu: React.FC<{ id: string; onDelete: VoidFunction }> = ({
  id,
  onDelete,
}) => (
  <RowMenu>
    <ListItemWithIcon
      component={(p) => (
        <MenuItem
          {...p}
          component={Link}
          to={id}
          sx={{ ...(p?.sx || {}), "&:hover": { color: "text.primary" } }}
        />
      )}
      icon={<EditIcon color="action" />}
      label="Redigera"
    />
    <ListItemWithIcon
      component={MenuItem}
      onClick={onDelete}
      icon={<DeleteIcon color="action" />}
      label="Radera"
    />
  </RowMenu>
);

function MeetingItem({
  meeting,
  onDelete,
}: {
  meeting: MeetingType;
  onDelete: VoidFunction;
}) {
  const { id, name, start, end, users, dayMatrix } = meeting;

  return (
    <Paper variant="box" key={id}>
      <Stack
        direction="row"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
        ml={2}
        mr={1}
        my={0.5}
      >
        <Title name={name} />
        <Stack direction="row" gap={3} alignItems="center">
          <DisplayDescriptor dayMatrix={dayMatrix} />
          <DisplayTime start={start} end={end} />
          <DisplayParticipants users={useConnectionToArray(users)} />
          <ActionMenu id={id} onDelete={onDelete} />
        </Stack>
      </Stack>
    </Paper>
  );
}

function MeetingListRenderer({ meetings, onDelete }: MeetingListRendererProps) {
  const renderRow = useCallback(
    (meeting: MeetingType) => (
      <MeetingItem
        key={meeting.id}
        meeting={meeting}
        onDelete={() => onDelete({ id: meeting.id, name: meeting.name })}
      />
    ),
    [onDelete],
  );

  return (
    <MeetingListScaffold>
      {meetings.filter(isNonNull).map(renderRow)}
    </MeetingListScaffold>
  );
}

export function MeetingList({ fragmentRef, refresh }: Props) {
  const { id: teamGroupId } = useCurrentTeamGroup();
  const { meetings, toDelete, setToDelete, clearToDelete, confirmDelete } =
    useMeetingList(teamGroupId, fragmentRef, refresh);

  if (meetings.length < 1) {
    return null;
  }

  return (
    <>
      <MeetingListRenderer meetings={meetings} onDelete={setToDelete} />
      {!!toDelete && (
        <DeleteModal
          show
          onHide={clearToDelete}
          deleteTitle={`Radera  ${toDelete.name}?`}
          deleteMessage={`Är du säker på att du vill radera ${toDelete.name}?`}
          onDeleteClick={confirmDelete}
          buttonText="Radera"
        />
      )}
    </>
  );
}

function useMeetingList(
  teamGroupId: string,
  fragmentRef: Key,
  refresh: () => Promise<void>,
) {
  const [toDelete, setToDelete] = useState<ToDelete>();
  const meetings = useFragment<Key>(fragment, fragmentRef);
  const [deleteMeeting] = useDeleteMeeting(teamGroupId);

  const clearToDelete = useCallback(
    () => setToDelete(undefined),
    [setToDelete],
  );
  const confirmDelete = useCallback(async () => {
    if (!toDelete?.id) return;
    await deleteMeeting({ variables: { input: { id: toDelete.id } } })
      .then(clearToDelete)
      .then(refresh);
  }, [toDelete?.id, deleteMeeting, clearToDelete, refresh]);

  return { toDelete, setToDelete, clearToDelete, confirmDelete, meetings };
}

import { Suspense, useCallback, useEffect, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import AddIcon from "@mui/icons-material/Add";
import { Button, Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useTeamGroups } from "contexts/TeamGroupsContext";
import { useAdminParams } from "hooks";

import { AdminCreateUserForm, AdminEditUserForm } from "components/admin/forms";
import {
  MultiEditUsers,
  useActivateUser,
  useArchiveUser,
  useDeleteUser,
  UserAdminForm,
} from "components/admin/mutations";
import Table from "components/admin/tables/UsersTable";
import type { User } from "components/admin/types";
import DeleteModal from "components/common/DeleteModal";
import { CompetenceSelectFragmentRefProvider } from "components/common/selectors/CompetenceSelect";
import { TeamSelectFragmentRefProvider } from "components/common/selectors/TeamSelect";
import { UserRuleGroupsProvider } from "components/forms/UserForm/UserRuleGroupsField";
import { AdminUsersPlaceholder } from "components/loading/pages";

import type { AdminUsersQuery as Query } from "./__generated__/AdminUsersQuery.graphql";

const query = graphql`
  query AdminUsersQuery {
    allUsers {
      ...UsersTable_fragment
    }
    competences {
      ...CompetenceSelect_fragment
    }
    allRuleGroups {
      ...UserRuleGroupsContext_ruleGroups
    }
    teams {
      ...TeamSelect_fragment
    }
  }
`;

type ContentProps = { queryRef: PreloadedQuery<Query> };

function Actions({ onNew }: { onNew: () => void }) {
  return (
    <Button
      variant="primary"
      endIcon={<AddIcon />}
      onClick={onNew}
      sx={{ alignSelf: "flex-end" }}
    >
      Ny användare
    </Button>
  );
}

function Content({ queryRef }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const { teamGroupOptions } = useTeamGroups();
  const [userToEdit, setUserToEdit] = useState<string | null>(null);
  const [editLeaderOf, setEditLeaderOf] = useState<string | null>(null);
  const { newUser: newOpen, setNewUser: setNewOpen } = useAdminParams();
  const [toDelete, setToDelete] = useState<User | null>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [activateUser] = useActivateUser();
  const [archiveUser] = useArchiveUser();
  const [deleteUser] = useDeleteUser();

  const closeDelete = useCallback(() => setToDelete(null), [setToDelete]);
  const closeCreate = useCallback(() => setNewOpen(false), [setNewOpen]);
  const closeEdit = useCallback(() => setUserToEdit(null), [setUserToEdit]);
  const closeMultiEdit = useCallback(
    () => setSelectedIds([]),
    [setSelectedIds],
  );
  const closeUserAdmin = useCallback(
    () => setEditLeaderOf(null),
    [setEditLeaderOf],
  );

  const onDelete = useCallback(
    (id?: string) => {
      if (!id) return;
      deleteUser({ variables: { id } }).then(closeDelete);
    },
    [deleteUser, closeDelete],
  );

  const onDeleteClick = useCallback(
    () => onDelete(toDelete?.id),
    [onDelete, toDelete?.id],
  );

  function onArchive(id: string) {
    archiveUser({ variables: { id } });
  }

  function onActivate(id: string) {
    activateUser({ variables: { id } });
  }

  return (
    <>
      <Paper variant="box" sx={{ p: "12px" }}>
        <Stack gap={2}>
          <Actions onNew={() => setNewOpen(true)} />
          <Table
            fragmentRef={data.allUsers}
            onEdit={setUserToEdit}
            onEditAdminOf={setEditLeaderOf}
            onDelete={setToDelete}
            onArchive={onArchive}
            onActivate={onActivate}
            onEditMulti={setSelectedIds}
          />
        </Stack>
      </Paper>
      <CompetenceSelectFragmentRefProvider value={data.competences}>
        <UserRuleGroupsProvider fragmentRef={data.allRuleGroups}>
          <TeamSelectFragmentRefProvider value={data.teams}>
            <UserAdminForm userId={editLeaderOf} onClose={closeUserAdmin} />
            <Suspense fallback={null}>
              <AdminCreateUserForm
                open={newOpen}
                onClose={closeCreate}
                teamGroups={teamGroupOptions}
              />
            </Suspense>
            <Suspense fallback={null}>
              {!!userToEdit && (
                <AdminEditUserForm
                  open={!!userToEdit}
                  onClose={closeEdit}
                  userId={userToEdit}
                  teamGroups={teamGroupOptions}
                />
              )}
            </Suspense>
            <MultiEditUsers
              userIds={selectedIds}
              open={selectedIds.length > 0}
              onClose={closeMultiEdit}
            />
          </TeamSelectFragmentRefProvider>
        </UserRuleGroupsProvider>
      </CompetenceSelectFragmentRefProvider>
      <DeleteModal
        show={!!toDelete}
        onHide={closeDelete}
        onDeleteClick={onDeleteClick}
        deleteTitle="Radera användare"
        deleteMessage={`Är du säker på att du vill radera användare '${toDelete?.fullName}'? Detta går inte att ångra!`}
        buttonText="Radera"
      />
    </>
  );
}

export function AdminUsers() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    loadQuery({});
  }, [loadQuery]);

  return (
    <Suspense
      fallback={
        <AdminUsersPlaceholder actions={<Actions onNew={() => {}} />} />
      }
    >
      {!!queryRef && <Content queryRef={queryRef} />}
    </Suspense>
  );
}

import { useMemo } from "react";
import type { ListItemProps } from "@mui/material";
import { alpha, ListItem, ListItemText, styled } from "@mui/material";

import { useSettingsPanel } from "./SettingsPanelContext";
import type { Element } from "./types";

type Props = {
  element: Element;
};

type StyledListItemProps = ListItemProps & { active?: boolean };

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (p) => p !== "active",
})<StyledListItemProps>(({ theme, active }) => ({
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.08) : "none",
  borderRadius: 5,
  borderLeftColor: active ? theme.palette.primary.light : "transparent",
  borderLeftWidth: 4,
  borderLeftStyle: "solid",
  cursor: "pointer",
  paddingTop: 7,
  paddingBottom: 7,
  "&:hover": {
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.hoverOpacity,
    ),
  },
}));

export function ElementItem({ element }: Props) {
  const { selected, setSelected } = useSettingsPanel();
  const active = useMemo(() => selected === element.id, [element, selected]);

  return (
    <StyledListItem
      active={active}
      onClick={() => setSelected(element.id)}
      sx={{ px: 1 }}
    >
      <>
        <ListItemText
          primary={element.title}
          primaryTypographyProps={{
            variant: "body2",
            fontWeight: active ? 700 : undefined,
          }}
          sx={{ margin: 0 }}
        />
        {element.endAdornment}
      </>
    </StyledListItem>
  );
}

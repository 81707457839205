/**
 * @generated SignedSource<<011bb4368ad129ab9c3eda7fd4ae003f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftNameDistributionChart_fragment$data = {
  readonly userShifts: ReadonlyArray<{
    readonly shiftTypes: ReadonlyArray<{
      readonly count: number;
      readonly hours: number;
      readonly name: string;
    }>;
    readonly user: {
      readonly id: string;
    };
  }>;
  readonly userStats: ReadonlyArray<{
    readonly competences: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly id: string;
    readonly name: string;
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
  }>;
  readonly " $fragmentType": "ShiftNameDistributionChart_fragment";
};
export type ShiftNameDistributionChart_fragment$key = {
  readonly " $data"?: ShiftNameDistributionChart_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftNameDistributionChart_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftNameDistributionChart_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSnapshotNode",
      "kind": "LinkedField",
      "name": "userStats",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "competences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetenceSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompetenceSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserShiftStats",
      "kind": "LinkedField",
      "name": "userShifts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSnapshotNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShiftTypeDistributionCount",
          "kind": "LinkedField",
          "name": "shiftTypes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hours",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleStats",
  "abstractKey": null
};
})();

(node as any).hash = "d18f343f0a0c731d77a98345873927ae";

export default node;

/**
 * @generated SignedSource<<85f02871c1e6b6547e4a210f93e80294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShiftProfileChart_fragment$data = {
  readonly profile: ReadonlyArray<{
    readonly dateTime: string;
    readonly minStaffed: number;
  }>;
  readonly " $fragmentType": "ShiftProfileChart_fragment";
};
export type ShiftProfileChart_fragment$key = {
  readonly " $data"?: ShiftProfileChart_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftProfileChart_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftProfileChart_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftProfileEvent",
      "kind": "LinkedField",
      "name": "profile",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dateTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minStaffed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShiftProfileViewer",
  "abstractKey": null
};

(node as any).hash = "6a8f3d34cf06b76bc545c1c616448b68";

export default node;

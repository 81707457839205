import { Stack } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { Free1A, Free1C } from "components/setting/common/forms";

import { weekendRestSetting } from "./WeekendRestSetting";

type FormProps = {
  readOnly?: boolean;
  periodLengthWeeks: number;
};

export function WeekendRestForm({ periodLengthWeeks, readOnly }: FormProps) {
  const disabled = !useIsConstraintModuleEnabled(weekendRestSetting.moduleName);

  return (
    <PaperWrapper>
      <Stack gap={5}>
        <Free1A
          periodLengthWeeks={periodLengthWeeks}
          disabled={disabled}
          readOnly={readOnly}
        />
        <Free1C
          periodLengthWeeks={periodLengthWeeks}
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

/**
 * @generated SignedSource<<aa4a901cfcd32fec3e02949565a3edeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WeekendRestSettingRuleGroup_fragment$data = {
  readonly freeEveryXOfYWeekends: number;
  readonly freeWeekendConstraintTypeEveryX: boolean;
  readonly freeWeekendConstraintTypeXOfY: boolean;
  readonly freeWeekendSoft: boolean;
  readonly id: string;
  readonly settingModules: ReadonlyArray<string>;
  readonly workEveryUnfreeWeekend: boolean;
  readonly workEveryUnfreeWeekendSoft: boolean;
  readonly workEveryXWeekend: number;
  readonly " $fragmentType": "WeekendRestSettingRuleGroup_fragment";
};
export type WeekendRestSettingRuleGroup_fragment$key = {
  readonly " $data"?: WeekendRestSettingRuleGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WeekendRestSettingRuleGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WeekendRestSettingRuleGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryXWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeEveryXOfYWeekends",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeEveryX",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendConstraintTypeXOfY",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "freeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekend",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workEveryUnfreeWeekendSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "ea0d5839009a7f4e0bcc94d1f758f2f4";

export default node;

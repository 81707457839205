import { forwardRef } from "react";
import { ReactComponent } from "assets/svg/success.svg";

import CustomIcon from "./CustomIcon";

function Success(p: any, ref: any) {
  return <CustomIcon ref={ref} {...p} component={ReactComponent} />;
}

export default forwardRef(Success);

import { useField } from "formik";

import {
  EmploymentForm,
  TranslateEmploymentForm,
} from "components/organisation/types";

import GenericSelect from "./GenericSelect";

type EmploymentFormSelectProps = {
  name: string;
  label?: string;
};

const typeOptions: EmploymentForm[] = ["P", "H", "S"];
const options = typeOptions.map((u) => ({
  value: u.toLowerCase(),
  label: TranslateEmploymentForm(u),
}));

const EmploymentFormSelect = ({ name, label }: EmploymentFormSelectProps) => {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  const selected = options?.find((o) => o.value === value);

  return (
    <GenericSelect
      label={label}
      options={options}
      value={selected}
      onChange={(_e: any, newValue: any) => setValue(newValue.value)}
      placeholder={"Välj anställningsform..."}
      error={meta.touched && !!meta.error}
      helperText={meta.touched ? meta.error : undefined}
    />
  );
};

export default EmploymentFormSelect;

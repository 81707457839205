/**
 * @generated SignedSource<<149c21227b1baf89003908d2a403d84b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiShiftDayTypeChoices = "D" | "E" | "F" | "N" | "%future added value";
export type Type = "B" | "J" | "M" | "P" | "S" | "Z" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShiftTimeline_fragment$data = {
  readonly endDate: string;
  readonly shiftTypes: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly shifts: ReadonlyArray<{
    readonly breakTime: number;
    readonly dayType: ApiShiftDayTypeChoices;
    readonly endTime: string;
    readonly shiftId: string;
    readonly shiftType: Type;
    readonly startTime: string;
  }>;
  readonly startDate: string;
  readonly " $fragmentType": "ShiftTimeline_fragment";
};
export type ShiftTimeline_fragment$key = {
  readonly " $data"?: ShiftTimeline_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShiftTimeline_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShiftTimeline_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftActivityType",
      "kind": "LinkedField",
      "name": "shifts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "breakTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dayType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShiftNode",
      "kind": "LinkedField",
      "name": "shiftTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShiftTimeline",
  "abstractKey": null
};

(node as any).hash = "86e90d3c5a55d567d491b0ca40c28bd9";

export default node;

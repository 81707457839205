/**
 * @generated SignedSource<<4eef872cac335aa94f695dc378bd21c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditMeMutation$variables = {
  email: string;
  username: string;
};
export type EditMeMutation$data = {
  readonly editMe: {
    readonly error: {
      readonly emailTaken: boolean | null;
      readonly error: string | null;
      readonly usernameTaken: boolean | null;
    } | null;
    readonly ok: boolean | null;
  } | null;
};
export type EditMeMutation = {
  response: EditMeMutation$data;
  variables: EditMeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "EditMe",
    "kind": "LinkedField",
    "name": "editMe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EditMeError",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "usernameTaken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailTaken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMeMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditMeMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a2ded98bc653ac4e3a067b543e7d357d",
    "id": null,
    "metadata": {},
    "name": "EditMeMutation",
    "operationKind": "mutation",
    "text": "mutation EditMeMutation(\n  $username: String!\n  $email: String!\n) {\n  editMe(username: $username, email: $email) {\n    ok\n    error {\n      usernameTaken\n      emailTaken\n      error\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f9fdaa87ff0c1e7185088997c9a4a24";

export default node;

/**
 * @generated SignedSource<<ffc9efcb3b3ed6af92a0f89cb9f466d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConsecutiveRestSettingUserSetting_fragment$data = {
  readonly id: string;
  readonly maxConsecutiveFreeDays: number;
  readonly maxConsecutiveFreeDaysSoft: boolean;
  readonly minConsecutiveFreeDays: number;
  readonly minConsecutiveFreeDaysSoft: boolean;
  readonly settingModules: ReadonlyArray<string>;
  readonly " $fragmentType": "ConsecutiveRestSettingUserSetting_fragment";
};
export type ConsecutiveRestSettingUserSetting_fragment$key = {
  readonly " $data"?: ConsecutiveRestSettingUserSetting_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConsecutiveRestSettingUserSetting_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConsecutiveRestSettingUserSetting_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minConsecutiveFreeDaysSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settingModules",
      "storageKey": null
    }
  ],
  "type": "UserSettingNode",
  "abstractKey": null
};

(node as any).hash = "e082510fd07944129bdeacf7fa7f80bf";

export default node;

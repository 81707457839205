/**
 * @generated SignedSource<<6240ef2a77993237f4722534ba29ef3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetencesList_competences$data = ReadonlyArray<{
  readonly color: string;
  readonly description: string;
  readonly name: string;
  readonly " $fragmentType": "CompetencesList_competences";
}>;
export type CompetencesList_competences$key = ReadonlyArray<{
  readonly " $data"?: CompetencesList_competences$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetencesList_competences">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CompetencesList_competences",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "CompetenceNode",
  "abstractKey": null
};

(node as any).hash = "62f8fe1377b7a9ae25363dc0d33bbb7a";

export default node;

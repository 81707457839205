import { Link } from "react-router-dom";
import { List, ListItem, Stack, Typography } from "@mui/material";
import { optimizationRulesUrl } from "utils/urls";

import { InfoButtonAndModal, InfoModalProvider } from "components/layout";

import type { GroupSettingCardProps } from "./GroupSettingCard";
import { GroupSettingCard } from "./GroupSettingCard";

type Props = Omit<GroupSettingCardProps, "title" | "action">;

const TITLE = "Avancerat";
const TITLE_LONG = "Avancerade optimeringsinställningar";
const listStyle = { listStyleType: "disc", pl: 2, pb: 0 };
const listItemStyle = { display: "list-item", py: 0.5 };

const Info = () => (
  <InfoModalProvider>
    <InfoButtonAndModal
      title={`Vad är ${TITLE_LONG}?`}
      buttonLabel={`Vad är ${TITLE_LONG}?`}
    >
      <Stack gap={1}>
        <Typography variant="h4">
          Avancerade optimeringsinställningar
        </Typography>
        <Typography>
          Om du slår på de Avancerade optimeringsinställningar så kommer
          ytterligare en sektion av optimeringsregler visas på sidan{" "}
          <Link to={optimizationRulesUrl()}>Optimeringsregler</Link>. Om de
          däremot är avstängda, så kommer denna sektion att döljas.
        </Typography>
        <Typography>Sektionen innefattar följande regelgrupper:</Typography>
      </Stack>
      <Typography>
        <List sx={listStyle}>
          <ListItem sx={listItemStyle}>Optimering (gräns för MIP-gap)</ListItem>
          <ListItem sx={listItemStyle}>Skalning av deltider</ListItem>
          <ListItem sx={listItemStyle}>Vila mellan pass</ListItem>
          <ListItem sx={listItemStyle}>Medsols passrotation</ListItem>
          <ListItem sx={listItemStyle}>Önskade fridagar</ListItem>
          <ListItem sx={listItemStyle}>Semester</ListItem>
          <ListItem sx={listItemStyle}>Nattpass</ListItem>
        </List>
      </Typography>
    </InfoButtonAndModal>
  </InfoModalProvider>
);

export function AdvancedCard(props: Props) {
  return <GroupSettingCard title={TITLE_LONG} action={<Info />} {...props} />;
}

AdvancedCard.id = "advanced" as const;
AdvancedCard.title = TITLE;

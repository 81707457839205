import { Suspense } from "react";
import { KeyType } from "react-relay/relay-hooks/helpers";
import { TeamGroupSettingsQuery$data as TeamGroupData } from "pages/Settings/__generated__/TeamGroupSettingsQuery.graphql";
import { TeamGroupUsers } from "pages/types";
import { ConstraintModuleSwitch_fragment$key as ConstraintModuleSwitchKey } from "settings/common/__generated__/ConstraintModuleSwitch_fragment.graphql";
import { OptimizationSetting } from "settings/common/optimizationSetting";

import { Loading } from "components/loading";
import { SettingView } from "components/setting/common/SettingView";

type Props<
  TeamGroupKey extends KeyType,
  UserSettingsKey extends KeyType | undefined,
  RuleGroupKey extends KeyType | undefined,
  FormValues,
  AddionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues,
> = {
  optimizationSetting: OptimizationSetting<
    TeamGroupKey,
    UserSettingsKey,
    RuleGroupKey,
    FormValues,
    AddionalData,
    TeamGroupSettingFormValues,
    UserSettingFormValues
  >;
  fragmentRef: TeamGroupKey;
  teamGroupMembers: TeamGroupUsers;
  constraintModulesRef: ConstraintModuleSwitchKey;
  ruleGroups: TeamGroupData["ruleGroups"];
};

export function TeamGroupSettingView<
  UserSettingsKey extends KeyType | undefined,
  TeamGroupKey extends KeyType,
  RuleGroupKey extends KeyType | undefined,
  FormValues,
  AddionalData = {},
  TeamGroupSettingFormValues = undefined,
  UserSettingFormValues = undefined,
>({
  fragmentRef,
  ...props
}: Props<
  TeamGroupKey,
  UserSettingsKey,
  RuleGroupKey,
  FormValues,
  AddionalData,
  TeamGroupSettingFormValues,
  UserSettingFormValues
>) {
  return (
    <Suspense fallback={<Loading sx={{ p: 3 }} />}>
      {!!fragmentRef && (
        <SettingView teamGroupFragmentRef={fragmentRef} {...props} />
      )}
    </Suspense>
  );
}

type MyWindow = Window & { API_URL?: string };

const windowApiUrl = (window as MyWindow)?.API_URL;
const reactAppApiUrl = process.env.REACT_APP_API_BASE_URL;
const entryPointUrl = "http://localhost:8080";

export const BASE_URL = windowApiUrl ?? reactAppApiUrl ?? entryPointUrl;
export const API_BASE_URL = `${BASE_URL}/api/`;
export const GRAPHQL_URL = `${BASE_URL}/graphql/`;

export const EMPTY_ARRAY: ReadonlyArray<any> = [];

import { Stack } from "@mui/material";

import LoadingPage from "components/layout/loading/PageWrapper";
import { SideMenu as LoadingSideMenu } from "components/navigation/loading";

export default function FullPageLoading() {
  return (
    <Stack width="100vw" height="100vh">
      <Stack direction="row" flexGrow={2}>
        <LoadingSideMenu />
        <Stack flexGrow={2}>
          <LoadingPage />
        </Stack>
      </Stack>
    </Stack>
  );
}

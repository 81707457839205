import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const important = (s: string | number) => `${s} !important`;

export const PeriodTimelineItem = styled(Stack)(({ theme }) => ({
  backgroundColor: important(theme.palette.common.white),
  color: important(theme.palette.text.primary),
  borderWidth: important("1px"),
  borderColor: important(theme.palette.divider),
  borderStyle: important("solid"),
  borderRadius: 8,
  lineHeight: "normal",
  textAlign: "center",
  paddingLeft: important(0),
  paddingRight: important(0),
  width: "100%",

  transition: "border-width 0.2s linear",

  "&:hover": {
    borderBottomWidth: important("3px"),
  },

  "&.selected": {
    borderBottomWidth: important("8px"),
  },
  "&.pastPeriod": {
    borderColor: important(theme.palette.divider),
  },
  "&.currentPeriod": {
    borderColor: important(theme.palette.primary.main),
  },
  "&.futurePeriod": {
    borderColor: important(theme.palette.secondary.main),
  },
  "& .timeline-item-header": {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },

  "&.create-new": {
    backgroundColor: important("transparent"),
    color: important("transparent"),
    borderColor: important(theme.palette.divider),
    borderWidth: important("3.5px"),
    borderStyle: important("dashed"),
    transition: "background-color 0.2s linear",

    "&:hover": {
      backgroundColor: important(theme.palette.action.hover),
    },
    "&:before": {
      content: '"+"',
      color: important(theme.palette.divider),
      fontSize: 40,
      lineHeight: 3,
    },
    "& .timeline-item-header": {
      display: "none",
      "& .delete-icon": {
        display: "none",
      },
    },
    "& .timeline-item-body": {
      display: "none",
    },
  },
}));

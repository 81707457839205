import type { SxProps, Theme } from "@mui/material";
import { MenuItem, Select as MuiSelect, Stack } from "@mui/material";

type OptionAvalue = any;
type OptionBvalue = any;

type OptionA = OptionAvalue;
type OptionB = OptionBvalue;

type SelectWithOptionsProps = {
  value: unknown;
  renderValue: (value: any) => React.ReactNode;
  onChange: (value: any) => void;
  options: OptionA[] | OptionB[];
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

function SelectWithOptions({
  value,
  renderValue,
  onChange,
  options,
  disabled,
  sx,
}: SelectWithOptionsProps) {
  return (
    <MuiSelect
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      size="small"
      sx={sx}
    >
      {options.map((x) => (
        <MenuItem key={x} value={x}>
          {renderValue(x)}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

type Props = {
  aOptions: OptionA[];
  bOptions: OptionB[];
  a: OptionAvalue;
  b: OptionBvalue;
  renderA: (value: OptionAvalue) => React.ReactNode;
  renderB: (value: OptionBvalue) => React.ReactNode;
  onAChange: (value: OptionAvalue) => void;
  onBChange: (value: OptionBvalue) => void;
  disabled?: boolean;
  asx?: SxProps<Theme>;
  bsx?: SxProps<Theme>;
};

export default function SelectAB({
  aOptions,
  bOptions,
  a,
  b,
  renderA,
  renderB,
  onAChange,
  onBChange,
  disabled,
  asx,
  bsx,
}: Props) {
  return (
    <Stack direction="row" gap={1}>
      <SelectWithOptions
        value={a}
        renderValue={renderA}
        onChange={onAChange}
        disabled={disabled}
        options={aOptions}
        sx={asx}
      />
      <SelectWithOptions
        value={b}
        renderValue={renderB}
        onChange={onBChange}
        disabled={disabled}
        options={bOptions}
        sx={bsx}
      />
    </Stack>
  );
}

import type { PriorityWeight } from "types";

export * from "./mutations/types";

export enum priorityWeight {
  ELEVATED = "ELEVATED",
  NORMAL = "NORMAL",
}

export const priorityWeightOptions: PriorityWeight[] = ["ELEVATED", "NORMAL"];

export function translateDemandWeight(weight: PriorityWeight): string {
  switch (weight) {
    case "NORMAL":
      return "Normal prioritet";
    case "ELEVATED":
      return "Extra hög prioritet";
    default:
      return "";
  }
}

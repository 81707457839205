import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { TranslateBlockedTimeSlotType } from "components/blocked_time_slot/types";

import { BlockedTimeSlotItem } from "./BlockedTimeSlotItem";

export function TimelineItemRenderer({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}: any) {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const fmtTs = (ts: number) => DateTime.fromMillis(ts).toFormat("T DD");
  const startTime = fmtTs(item.start_time);
  const endTime = fmtTs(item.end_time);
  const itemTitleRaw =
    item.timeslotType === "H"
      ? item.title
      : TranslateBlockedTimeSlotType(item.timeslotType) || "";
  const trimmer =
    itemContext.width > 20 ? Math.floor(itemContext.width / 6.5) : 0;
  const itemTitle = itemTitleRaw.slice(0, trimmer);

  const { key, ...itemProps } = getItemProps(item.itemProps);

  return (
    <Tooltip
      placement="right"
      title={
        <Stack gap={0.2}>
          <Typography>
            <strong>{itemContext.title}</strong>
          </Typography>
          <Typography>
            <strong>Start:</strong> {startTime}
          </Typography>
          <Typography>
            <strong>Slut:</strong> {endTime}
          </Typography>
        </Stack>
      }
    >
      <BlockedTimeSlotItem key={key} {...itemProps} title={undefined}>
        {itemContext.useResizeHandle ? <Box {...leftResizeProps} /> : ""}

        <Box
          sx={{
            maxHeight: `${itemContext.dimensions.height}`,
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            px: 0.25,
          }}
        >
          <Typography variant="caption" lineHeight={1.2} noWrap>
            {itemTitle}
          </Typography>

          {itemContext.useResizeHandle ? <Box {...rightResizeProps} /> : ""}
        </Box>
      </BlockedTimeSlotItem>
    </Tooltip>
  );
}

import { Suspense, useEffect, useMemo, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
//import { ReactComponent as MicrosoftTeamsLogo } from "assets/microsoft-teams-logo.svg";
import graphql from "babel-plugin-relay/macro";
import { usePeriodParams } from "hooks";

import DeleteModal from "components/common/DeleteModal";
import PageWrapper from "components/layout/PageWrapper";
import { ScheduleRunsPlaceholder } from "components/loading/pages";
import DeletePeriod from "components/period/mutations/DeletePeriod";
import PeriodHeader from "components/period/PeriodHeader";
import RunSchedule from "components/period/schedule_runs/RunSchedule";
import ScheduleRunTables from "components/period/schedule_runs/ScheduleRunsTable";

import { ScheduleRunsQuery as Query } from "./__generated__/ScheduleRunsQuery.graphql";

const query = graphql`
  query ScheduleRunsQuery($periodId: ID!) {
    period(id: $periodId) {
      id
      name
      ...RunSchedule_period
    }
    schedules(periodId: $periodId) {
      solutionStatus
      ...ScheduleRunsTable_fragment
    }
  }
`;

/*
type IboxToolsScheduleRunsTableProps = {
  onPeriodDeleteClick: () => void;
};

const IboxToolsScheduleRunsTable = ({
  onPeriodDeleteClick,
}: IboxToolsScheduleRunsTableProps) => (
  <>
    <a
      title="Exportera till Teams"
      href="#/"
      onClick={(e) => {
        e.preventDefault();
      }}
      className="mr-2"
    >
      <MicrosoftTeamsLogo
        style={{ height: "18px", width: "18px", marginTop: "-5px" }}
      />
    </a>
    <a
      title="Radera schemaperiod"
      href="#/"
      onClick={(e) => {
        e.preventDefault();
        onPeriodDeleteClick();
      }}
      style={{ marginRight: "4px" }}
    >
      <i className="fas fa-trash fa-lg" />
    </a>
  </>
);
*/

type QueryRefProps = {
  queryRef: PreloadedQuery<Query>;
  periodId: string;
  refresh: () => Promise<void>;
};

function Content({ periodId, queryRef, refresh }: QueryRefProps) {
  const navigate = useNavigate();
  const { newRun: showCreate, setNewRun: setShowCreate } = usePeriodParams();
  const [showDelete, setShowDelete] = useState(false);

  const data = usePreloadedQuery<Query>(query, queryRef);

  const isAnyScheduleRunning = useMemo(
    () => (data?.schedules || []).some((s) => s?.solutionStatus === "RUNNING"),
    [data?.schedules],
  );

  return (
    <PageWrapper
      header={
        <PeriodHeader
          onAdd={() => setShowCreate(true)}
          addDisabled={isAnyScheduleRunning}
        />
      }
    >
      <ScheduleRunTables
        fragmentRef={data.schedules}
        refresh={refresh}
        periodId={periodId}
        periodName={data?.period?.name || ""}
      />

      <RunSchedule
        onClose={() => {
          setShowCreate(false);
          refresh();
        }}
        open={showCreate}
        period={data.period}
      />

      <DeleteModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        deleteTitle="Radera schemaperiod?"
        deleteMessage={
          <>
            <Typography>
              Är du säker på att du vill radera schemaperiod{" "}
              <strong>vecka {data?.period?.name}</strong>? Alla schemakörningar
              för perioden kommer att gå förlorade.
            </Typography>
            <Typography>Denna åtgärd kan inte ångras.</Typography>
          </>
        }
        buttonText="Radera schemaperiod"
        onDeleteClick={() => {
          DeletePeriod(periodId || "");
          setShowDelete(false);
          navigate("/periods");
        }}
      />
    </PageWrapper>
  );
}

type Props = { periodId: string };

export function ScheduleRuns({ periodId }: Props) {
  const env = useRelayEnvironment();
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    if (!periodId) return;
    loadQuery({ periodId });
  }, [periodId, loadQuery]);

  async function refresh() {
    await fetchQuery(env, query, { periodId }).toPromise();
  }

  return (
    <Suspense fallback={<ScheduleRunsPlaceholder />}>
      {!!queryRef && (
        <Content periodId={periodId} queryRef={queryRef} refresh={refresh} />
      )}
    </Suspense>
  );
}

import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { FormikSmallSwitch } from "components/common/Switch";

type Props = {
  groupName: string;
  readOnly?: boolean;
};

export function Types2({ groupName, readOnly }: Props) {
  const constraintModules =
    useFormikState<string[]>("constraintModules")?.value || [];
  const disabled = !constraintModules.includes("SmoothResources");

  return (
    <Stack gap={5}>
      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h4">
            Fördela resurspass jämnt <i>inom</i> avdelningar
          </Typography>
          <FormikSmallSwitch
            name="optimizeResourceDistributionWithinTeams"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <Typography>
          Om denna inställning är påslagen så kommer algoritmen eftersträva en
          jämn fördelning av resurspass inom var och en av varje avdelning.
          Detta innebär att eventuell överbemanning i form av resurspass inom en
          avdelning kommer (i den mån det är möjligt) fördelas jämnt över hela
          schemaperioden.
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h4">
            Fördela resurspass jämnt <i>mellan</i> avdelningar
          </Typography>
          <FormikSmallSwitch
            name="optimizeResourceDistributionBetweenTeams"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <Typography>
          Om denna inställning är påslagen så kommer algoritmen eftersträva en
          jämn fördelning av resurspass mellan avdelningarna i enhet {groupName}
          . Detta innebär att eventuell överbemanning i form av resurspass på
          avdelning A kommer (i den mån det är möjligt) att täcka eventuell
          underbemanning på avdelning B, och vice versa.
        </Typography>
      </Stack>

      <Stack gap={1}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h4">
            Fördela resurspass jämnt mellan medarbetare
          </Typography>
          <FormikSmallSwitch
            name="optimizeResourceDistributionBetweenEmployees"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <Typography>
          Om denna inställning är påslagen så kommer algoritmen eftersträva en
          jämn fördelning av resurspass mellan medarbetare.
        </Typography>
      </Stack>
    </Stack>
  );
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { ActivateTeamMutation as Mutation } from "./types";

const mutation = graphql`
  mutation ActivateTeamMutation($id: ID!) {
    activateTeam(input: { id: $id }) {
      ok
      team {
        id
        isActive
        archivedAt
      }
    }
  }
`;

export const useActivateTeam = () => useMutation<Mutation>(mutation);

import { InfoButton } from "./InfoButton";
import { InfoModal } from "./InfoModal";
import { useInfoModal } from "./InfoModalContext";

type Props = {
  title: React.ReactNode;
  buttonLabel?: string;
};

export function InfoButtonAndModal({
  title,
  buttonLabel = "Info",
  children,
}: React.PropsWithChildren<Props>) {
  const { isOpen, setOpen } = useInfoModal();
  return (
    <>
      <InfoButton onClick={() => setOpen(true)}>{buttonLabel}</InfoButton>
      <InfoModal title={title} open={isOpen} onClose={() => setOpen(false)}>
        {children}
      </InfoModal>
    </>
  );
}

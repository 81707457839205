import { Typography } from "@mui/material";

import { FormikSwitchWithTitleAndDesc } from "./common";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function ComprestCanCutDaybreakAbjourField({
  disabled,
  readOnly,
}: Props) {
  return (
    <FormikSwitchWithTitleAndDesc
      name="comprestCanCutDaybreakAbjour"
      title="Kompenserande dygnsvila får korsa dygnsbryt vid AB-jour"
      description={
        <Typography>
          Ett pass som är 13 timmar eller längre och innehåller en passdel av
          typen "Jour (Allmänna Bestämmelser)" anses vara ett jourpass enligt
          Allmänna Bestämmelser. Ett sådant pass ska alltid efterföljas av en
          kompenserande vila lika lång som passet är. Detta fält styr huruvida
          de första 11 timmarna av denna kompensationsvila får lova att korsa
          ett dygnsbryt eller ej.
        </Typography>
      }
      readOnly={readOnly}
      labelOn="Ja"
      labelOff="Nej"
    />
  );
}

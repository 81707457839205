import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import CustomRadio from "./CustomRadio";

const StyledFormControl = styled(FormControl)(() => ({
  marginTop: "-8px",
  marginRight: "-6px",
  marginBottom: "-10px",
}));

const StyledRadioGroup = styled(RadioGroup)(() => ({
  marginBottom: "-10px",
}));

type RadioButtonHardSoftProps = {
  handleChange: any;
  value: boolean;
  softLabel?: string;
  hardLabel?: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export const RadioButtonHardSoft = ({
  handleChange,
  value,
  softLabel = "Mjuk",
  hardLabel = "Hård",
  disabled,
  readOnly,
}: RadioButtonHardSoftProps) => (
  <StyledFormControl margin="none" disabled={disabled}>
    <StyledRadioGroup
      row
      aria-labelledby="setting-header-radio-buttons-group"
      name="setting-header-radio-buttons-group"
      value={value}
      onChange={readOnly ? () => {} : handleChange}
    >
      <Stack direction="row" gap={1}>
        <FormControlLabel
          disabled={disabled}
          value={false}
          control={<CustomRadio />}
          label={hardLabel}
        />
        <FormControlLabel
          disabled={disabled}
          value={true}
          control={<CustomRadio />}
          label={softLabel}
        />
      </Stack>
    </StyledRadioGroup>
  </StyledFormControl>
);

type SettingBoxHeaderRadiosProps = {
  handleSoftRadio: any;
  valueSoftRadio: boolean;
  nameSoftRadio: string;
  hardLabel?: string;
  softLabel?: string;
  disabled?: boolean;
  readOnly?: boolean;
};

export const SettingBoxHeaderRadios = ({
  nameSoftRadio,
  valueSoftRadio,
  handleSoftRadio,
  softLabel = "Mjuk",
  hardLabel = "Hård",
  disabled,
  readOnly,
}: SettingBoxHeaderRadiosProps) => {
  // No radio buttons are returned
  if (nameSoftRadio === "none") {
    return <Stack sx={{ height: 2 }} />;
  }

  // Only the soft radio button option is return and it's always set to true
  if (nameSoftRadio === "onlySoft") {
    return (
      <Stack direction="row" gap={2} justifyContent="flex-end">
        <StyledFormControl margin="none">
          <StyledRadioGroup
            row
            aria-labelledby="setting-header-radio-button-soft"
            name="setting-header-radio-button-soft"
            value={true}
          >
            <FormControlLabel
              disabled={disabled}
              value={true}
              control={<CustomRadio />}
              label={softLabel}
            />
          </StyledRadioGroup>
        </StyledFormControl>
      </Stack>
    );
  }

  // Only the hard radio button option is return and it's always set to true
  if (nameSoftRadio === "onlyHard") {
    return (
      <Stack direction="row" gap={2} justifyContent="flex-end">
        <StyledFormControl margin="none">
          <StyledRadioGroup
            row
            aria-labelledby="setting-header-radio-button-hard"
            name="setting-header-radio-button-hard"
            value={true}
          >
            <FormControlLabel
              disabled={disabled}
              value={true}
              control={<CustomRadio />}
              label={hardLabel}
            />
          </StyledRadioGroup>
        </StyledFormControl>
      </Stack>
    );
  }

  // Both soft and hard options are returned
  return (
    <Stack direction="row" gap={2} justifyContent="flex-end">
      <RadioButtonHardSoft
        value={valueSoftRadio}
        handleChange={handleSoftRadio}
        softLabel={softLabel}
        hardLabel={hardLabel}
        disabled={disabled}
        readOnly={readOnly}
      />
    </Stack>
  );
};

import { PropsWithChildren } from "react";
import { Form, Formik, FormikProps, FormikValues } from "formik";

import { AutoSubmit } from "components/setting/common";

type Props<V extends FormikValues> = PropsWithChildren<{
  initialValues: V;
  validationSchema?: any;
  onSubmit: (data: V) => void;
}>;
export function BaseSettings<V extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit,
  children,
}: Props<V>) {
  const isDev = process.env.NODE_ENV === "development";
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ errors }: FormikProps<V>) => {
        if (isDev && Object.keys(errors).length > 0) {
          console.log(JSON.stringify(errors));
        }
        return (
          <Form autoComplete="off">
            <AutoSubmit>{children}</AutoSubmit>
            {isDev && Object.keys(errors).length > 0 && (
              <div style={{ paddingTop: 10, color: "red" }}>
                DEV: Kan inte spara!
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

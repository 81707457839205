import type { ComponentProps } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";

import { IconButtonWithText } from "./IconButtonWithText";

type Props = Omit<ComponentProps<typeof IconButtonWithText>, "icon"> & {
  text?: string;
};

export function DeleteButton({ text, ...props }: Props) {
  return <IconButtonWithText {...props} icon={<DeleteIcon />} text={text} />;
}

import { useCallback } from "react";
import type { FieldHookConfig } from "formik";
import { useField } from "formik";

export default function useFormikState<T = any>(
  name: string | FieldHookConfig<T>,
) {
  const [field, meta, helpers] = useField<T>(name);
  const { value } = meta;
  const { setValue } = helpers;

  return {
    value,
    setValue: useCallback(
      (v: T, shouldValidate = true) => setValue(v, shouldValidate),
      [setValue],
    ),
    field,
    meta,
    helpers,
  };
}

/**
 * @generated SignedSource<<3bbe88b4073b9d5323476c7acad52e21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiRuleGroupSettingShiftsEmploymentDegreeLogicChoices = "E" | "O" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PartTimeSettingRuleGroup_fragment$data = {
  readonly hoursPerWeek: number;
  readonly id: string;
  readonly shiftsEmploymentDegreeFactor: number;
  readonly shiftsEmploymentDegreeLogic: ApiRuleGroupSettingShiftsEmploymentDegreeLogicChoices;
  readonly shiftsPerWeek: number;
  readonly " $fragmentType": "PartTimeSettingRuleGroup_fragment";
};
export type PartTimeSettingRuleGroup_fragment$key = {
  readonly " $data"?: PartTimeSettingRuleGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PartTimeSettingRuleGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PartTimeSettingRuleGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsEmploymentDegreeFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsEmploymentDegreeLogic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftsPerWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hoursPerWeek",
      "storageKey": null
    }
  ],
  "type": "RuleGroupSettingNode",
  "abstractKey": null
};

(node as any).hash = "5e1710d5f1874e4a1d75a21ba05746d1";

export default node;

/**
 * @generated SignedSource<<e1639c8a89f185867077f3cf50ba6156>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRuleGroupFormQuery$variables = {
  id: string;
};
export type EditRuleGroupFormQuery$data = {
  readonly ruleGroup: {
    readonly " $fragmentSpreads": FragmentRefs<"EditRuleGroupForm_fragment">;
  } | null;
};
export type EditRuleGroupFormQuery = {
  response: EditRuleGroupFormQuery$data;
  variables: EditRuleGroupFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditRuleGroupFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "ruleGroup",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditRuleGroupForm_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditRuleGroupFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "ruleGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7110739297ff84c03994614b2bd439f",
    "id": null,
    "metadata": {},
    "name": "EditRuleGroupFormQuery",
    "operationKind": "query",
    "text": "query EditRuleGroupFormQuery(\n  $id: ID!\n) {\n  ruleGroup(id: $id) {\n    ...EditRuleGroupForm_fragment\n    id\n  }\n}\n\nfragment EditRuleGroupForm_fragment on RuleGroupNode {\n  id\n  name\n  description\n}\n"
  }
};
})();

(node as any).hash = "a9b1a4b140afc31a9fb3d607cecf8343";

export default node;

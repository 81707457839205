import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { HeaderWeight } from "components/setting/common";

import { weekendRestSetting } from "./WeekendRestSetting";

type Props = {
  readOnly?: boolean;
};

export function WeekendRestTeamgroupOnlyForm({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(weekendRestSetting.moduleName);

  return (
    <PaperWrapper>
      <Stack gap={1}>
        <Typography variant="h4">Regelvikt</Typography>
        <HeaderWeight
          name="freeWeekendWeight"
          readOnly={readOnly}
          disabled={disabled}
        />
      </Stack>
    </PaperWrapper>
  );
}

import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";

import {
  DisableClearable,
  SingleAutoSelectProps,
} from "../GenericSelect/SingleAutoSelect";

import { BaseTeamSelect2 } from "./BaseTeamSelect2";
import { useTeamSelect2FragmentRef } from "./TeamSelect2FragmentRefContext";
import type { Option, TeamSelect2_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment TeamSelect2_fragment on TeamNode @relay(plural: true) {
    id
    name
    color
    group {
      id
      name
    }
  }
`;

type Props<Value> = Pick<
  SingleAutoSelectProps<Value, DisableClearable>,
  "sx"
> & {
  onChange: (value: string) => void;
  filterOnGroupIds?: string[];
};

export function TeamSelect2({
  onChange: onChangeProp,
  sx: sxProp,
  ...props
}: Props<Option>) {
  const fragmentRef = useTeamSelect2FragmentRef();
  const teams = useFragment<Key>(fragment, fragmentRef);

  const options = useMemo<Option[]>(
    () =>
      teams.map((t) => ({
        id: t.id,
        name: t.name,
        color: t.color,
        group: { id: t.group.id, name: t.group.name },
      })),
    [teams],
  );

  const onChange = useCallback(
    (value: Option) => onChangeProp(value.id),
    [onChangeProp],
  );

  const sx = useMemo(() => ({ minWidth: 170, ...(sxProp ?? {}) }), [sxProp]);

  return (
    <BaseTeamSelect2
      {...props}
      options={options}
      onChange={onChange}
      allowSelectAll={true}
      sx={sx}
    />
  );
}

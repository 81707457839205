/**
 * @generated SignedSource<<fa6acf752315f67ae05326ed6476c4f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiTeamDemandSettingOverstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingWeekendOverstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
export type ApiTeamDemandSettingWeekendUnderstaffingWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BaseDemandSettingTeamGroup_fragment$data = {
  readonly group: {
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly teamDemandSetting: {
            readonly allowUnderstaffing: boolean;
            readonly id: string;
            readonly overstaffingWeight: ApiTeamDemandSettingOverstaffingWeightChoices;
            readonly team: {
              readonly color: string;
              readonly id: string;
              readonly name: string;
            };
            readonly understaffingWeight: ApiTeamDemandSettingUnderstaffingWeightChoices;
            readonly weekendOverstaffingWeight: ApiTeamDemandSettingWeekendOverstaffingWeightChoices;
            readonly weekendUnderstaffingWeight: ApiTeamDemandSettingWeekendUnderstaffingWeightChoices;
          } | null;
        } | null;
      } | null>;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "BaseDemandSettingTeamGroup_fragment";
};
export type BaseDemandSettingTeamGroup_fragment$key = {
  readonly " $data"?: BaseDemandSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseDemandSettingTeamGroup_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BaseDemandSettingTeamGroup_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamGroupNode",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamNodeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TeamDemandSettingNode",
                      "kind": "LinkedField",
                      "name": "teamDemandSetting",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "allowUnderstaffing",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "understaffingWeight",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "weekendUnderstaffingWeight",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "overstaffingWeight",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "weekendOverstaffingWeight",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamNode",
                          "kind": "LinkedField",
                          "name": "team",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "color",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};
})();

(node as any).hash = "329d10c6373d98eb75025c95844b2e44";

export default node;

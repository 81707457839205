import { useCallback } from "react";
import type { FormikHelpers } from "formik";
import { Form as FormikForm, Formik } from "formik";

import type {
  CreateMeetingMutation$data as CreateData,
  EditMeetingMutation$data as EditData,
  MeetingError,
} from "../types";

import type { Props as MeetingFormProps } from "./MeetingForm";
import { MeetingForm } from "./MeetingForm";
import type { FormValues as AllFormValues } from "./types";
import { meetingFormValidationSchema } from "./validator";

type Props<
  V extends AllFormValues,
  Out extends CreateData | EditData,
> = MeetingFormProps & {
  initialValues: V;
  onSubmit: (args: V) => Promise<Out>;
  onClose: VoidFunction;
  catchError: (e: Error) => void;
};

export function MeetingFormikForm<
  FormValues extends AllFormValues,
  Out extends CreateData | EditData,
>({
  initialValues,
  onSubmit,
  onClose,
  catchError,
  ...formProps
}: Props<FormValues, Out>) {
  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        const handleResponse = (
          ok?: boolean | null,
          errors?: ReadonlyArray<MeetingError> | null,
        ) => {
          if (!ok) {
            actions.setFieldValue("errors", errors);
          } else {
            onClose();
          }
        };

        const r = await onSubmit(values);
        if ("createMeeting" in r) {
          const { ok, errors } = r.createMeeting || {};
          handleResponse(ok, errors);
        } else if ("editMeeting" in r) {
          const { ok, errors } = r.editMeeting || {};
          handleResponse(ok, errors);
        }
      } catch (e) {
        catchError(e as Error);
      }
    },
    [onSubmit, onClose, catchError],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={meetingFormValidationSchema}
      onReset={onClose}
      onSubmit={handleSubmit}
    >
      <FormikForm autoComplete="false">
        <MeetingForm {...formProps} />
      </FormikForm>
    </Formik>
  );
}

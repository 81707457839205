import type { Dispatch } from "react";
import { useCallback, useMemo } from "react";
import { useQueryParams } from "hooks";

import type { QueryParamsReturnType } from "./useQueryParams";

type State = {
  newTeam: boolean;
  editTeam: string | null;
  newRuleGroup: boolean;
  editRuleGroup: string | null;
};
type Actions = {
  setNewTeam: Dispatch<boolean>;
  setEditTeam: Dispatch<string | null>;
  setNewRuleGroup: Dispatch<boolean>;
  setEditRuleGroup: Dispatch<string | null>;
};
type OrganisationParamsType = State & Actions & QueryParamsReturnType;

export function useGroupSettingsParams() {
  const [state, setState, clear] = useQueryParams<State>((query) => ({
    newTeam: query.has("newTeam"),
    editTeam: query.get("editTeam"),
    newRuleGroup: query.has("newRuleGroup"),
    editRuleGroup: query.get("editRuleGroup"),
  }));

  const setNewTeam = useCallback(
    (value: boolean) => setState("newTeam", value),
    [setState],
  );
  const setEditTeam = useCallback(
    (value: string | null) => setState("editTeam", value),
    [setState],
  );
  const setNewRuleGroup = useCallback(
    (value: boolean) => setState("newRuleGroup", value),
    [setState],
  );
  const setEditRuleGroup = useCallback(
    (value: string | null) => setState("editRuleGroup", value),
    [setState],
  );

  return useMemo<OrganisationParamsType>(
    () => ({
      ...state,
      setNewTeam,
      setEditTeam,
      setNewRuleGroup,
      setEditRuleGroup,
      clear,
    }),
    [state, setNewTeam, setEditTeam, setNewRuleGroup, setEditRuleGroup, clear],
  );
}

import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { WeightField } from "settings/common/TeamGroupOnlyForms";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { FormikSmallSwitch } from "components/common/Switch";

import { smoothResourcesSetting } from "../SmoothResourcesSetting";

type Props = {
  readOnly?: boolean;
};

export function SmoothResourcesForm({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    smoothResourcesSetting.moduleName,
  );

  return (
    <PaperWrapper>
      <Stack gap={3}>
        <Stack gap={1}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h4">
              Fördela resurspass jämnt <i>inom</i> avdelningar
            </Typography>
            <FormikSmallSwitch
              name="optimizeResourceDistributionWithinTeams"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
          <Typography>
            Om denna inställning är påslagen så kommer algoritmen eftersträva en
            jämn fördelning av resurspass inom var och en av varje avdelning.
            Detta innebär att eventuell överbemanning i form av resurspass inom
            en avdelning kommer (i den mån det är möjligt) fördelas jämnt över
            hela schemaperioden.
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h4">
              Fördela resurspass jämnt <i>mellan</i> avdelningar
            </Typography>
            <FormikSmallSwitch
              name="optimizeResourceDistributionBetweenTeams"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
          <Typography>
            Om denna inställning är påslagen så kommer algoritmen eftersträva en
            jämn fördelning av resurspass mellan avdelningarna i enheten. Detta
            innebär att eventuell överbemanning i form av resurspass på
            avdelning A kommer (i den mån det är möjligt) att täcka eventuell
            underbemanning på avdelning B, och vice versa.
          </Typography>
        </Stack>

        <Stack gap={1}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h4">
              Fördela resurspass jämnt mellan medarbetare
            </Typography>
            <FormikSmallSwitch
              name="optimizeResourceDistributionBetweenEmployees"
              disabled={disabled}
              readOnly={readOnly}
            />
          </Stack>
          <Typography>
            Om denna inställning är påslagen så kommer algoritmen eftersträva en
            jämn fördelning av resurspass mellan medarbetare.
          </Typography>
        </Stack>
        <WeightField
          moduleName={smoothResourcesSetting.moduleName}
          weightFieldName="resourceShiftsWeight"
        />
      </Stack>
    </PaperWrapper>
  );
}

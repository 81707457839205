import { DateGridScaffold } from "../Scaffolds";

import { DayMatrixField } from "./DayMatrixField";

export function DateGridSection() {
  return (
    <DateGridScaffold
      selectDaysBy={
        undefined
        // Hidden until we implement 'possible_days'
        // <SelectDaysBy />
      }
      matrix={<DayMatrixField />}
    />
  );
}

/* OBS This component will be used when we support 'possible_days'
const SelectDaysBy = () => (
  <>
    <DateRadiosField value={dateOption} setValue={setDateOption} />
    {dateOption === "specific_days" && (
      <Typography>
        Kryssa i de dagar du vill ha mötet. Vanligt för APT.
      </Typography>
    )}
    {dateOption === "possible_days" && (
      <>
        <Typography>
          Kryssa i möjliga dagar så väljer Konvoj ut de {nrMeetings} förekomster
          som passar bäst. Vanligt för samverkansmöten.
        </Typography>
        <OccurSelectField />
      </>
    )}
  </>
);
*/

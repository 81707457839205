import { useCallback, useEffect } from "react";

const APP_NAME = "Konvoj";

export function useChangeTitle(title: string, addPrefix = true) {
  const getTitle = useCallback(
    (title: string) => `${addPrefix ? `${APP_NAME} | ` : ""}${title}`,
    [addPrefix],
  );
  useEffect(() => {
    document.title = getTitle(title);
    return () => {
      document.title = APP_NAME;
    };
  }, [getTitle, title]);
}

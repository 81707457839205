import type { SelectChangeEvent } from "@mui/material";
import { MenuItem, Select } from "@mui/material";

type Props = {
  value: number;
  onChange: (value: number) => void;
};

const opts = [
  { value: 1, label: "varje" },
  { value: 2, label: "varannan" },
  { value: 3, label: "var 3:e" },
  { value: 4, label: "var 4:e" },
  { value: 5, label: "var 5:e" },
  { value: 6, label: "var 6:e" },
  { value: 7, label: "var 7:e" },
  { value: 8, label: "var 8:e" },
  { value: 9, label: "var 9:e" },
  { value: 10, label: "var 10:e" },
  { value: 11, label: "var 11:e" },
  { value: 12, label: "var 12:e" },
];

export default function IntervalSelect({ value, onChange }: Props) {
  function handleChange(e: SelectChangeEvent<number>) {
    onChange(Number(e.target.value));
  }

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ width: 120 }}
    >
      {opts.map((opt) => (
        <MenuItem key={opt.value} value={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
    </Select>
  );
}

/**
 * @generated SignedSource<<3c538d85ab14a87ee738a5a0dfd65104>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityCharts_fragment$data = {
  readonly id: string;
  readonly period: {
    readonly endDate: string;
    readonly startDate: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Header_fragment">;
  readonly " $fragmentType": "ActivityCharts_fragment";
};
export type ActivityCharts_fragment$key = {
  readonly " $data"?: ActivityCharts_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityCharts_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityCharts_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodNode",
      "kind": "LinkedField",
      "name": "period",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Header_fragment"
    }
  ],
  "type": "ScheduleNode",
  "abstractKey": null
};

(node as any).hash = "70c44fcf3fe14a006b708c2581a86613";

export default node;

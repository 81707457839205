import type { ReactNode } from "react";
import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  title?: ReactNode;
  tools?: ReactNode;
  children?: ReactNode;
  className?: string;
  // Fill full height when parent has class "d-flex". Can be used to give equal height to multiple
  // iboxes in a row.
  fillFlexHeight?: boolean;
  style?: React.CSSProperties;
};

const Title = ({ title, tools }: { title: ReactNode; tools: ReactNode }) => {
  return (
    <Box className="ibox-title">
      {typeof title === "string" ? (
        <Typography variant="h5">{title}</Typography>
      ) : (
        title
      )}
      <Tools>{tools}</Tools>
    </Box>
  );
};

const Tools = ({ children }: { children: ReactNode }) =>
  children ? <Box className="ibox-tools">{children}</Box> : null;

const Ibox = (props: Props) => {
  const iboxClasses =
    "ibox" +
    (props.fillFlexHeight ? " d-flex flex-column w-100" : "") +
    (props.className ? " " + props.className : "");
  const contentClasses =
    "ibox-content" + (props.fillFlexHeight ? " flex-grow-1" : "");

  return (
    <Box className={iboxClasses} style={props?.style || {}}>
      {props.title && <Title title={props.title} tools={props.tools} />}

      {props.children && <Box className={contentClasses}>{props.children}</Box>}
    </Box>
  );
};

export default Ibox;

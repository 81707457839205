import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";

type DemandGraphSpanContextType = {
  start: Date;
  setStart: (date: Date) => void;
  end: Date;
  setEnd: (date: Date) => void;
  windowStart: Date;
  setWindowStart: (date: Date) => void;
  windowEnd: Date;
  setWindowEnd: (date: Date) => void;
};
type ProviderProps = {
  start: Date;
  end: Date;
};

const DemandGraphSpanContext = createContext<DemandGraphSpanContextType>({
  start: DateTime.now().startOf("week").toJSDate(),
  setStart: () => {},
  end: DateTime.now().endOf("week").toJSDate(),
  setEnd: () => {},
  windowStart: DateTime.now().startOf("week").toJSDate(),
  setWindowStart: () => {},
  windowEnd: DateTime.now().endOf("week").toJSDate(),
  setWindowEnd: () => {},
});
DemandGraphSpanContext.displayName = "DemandGraphSpanContext";

export const useDemandGraphSpanContext = () =>
  useContext(DemandGraphSpanContext);

export const DemandGraphSpanProvider = ({
  start: initStart,
  end: initEnd,
  children,
}: PropsWithChildren<ProviderProps>) => {
  const [start, setStart] = useState(initStart);
  const [end, setEnd] = useState(initEnd);
  const [windowStart, setWindowStart] = useState(initStart);
  const [windowEnd, setWindowEnd] = useState(end);

  useEffect(() => {
    setStart(initStart);
    setWindowStart(initStart);
  }, [initStart]);
  useEffect(() => {
    setEnd(initEnd);
    setWindowEnd(initEnd);
  }, [initEnd]);

  const value = useMemo(
    () => ({
      start,
      end,
      windowStart,
      windowEnd,
      setStart,
      setEnd,
      setWindowStart,
      setWindowEnd,
    }),
    [start, end, windowStart, windowEnd, setWindowStart, setWindowEnd],
  );

  return (
    <DemandGraphSpanContext.Provider value={value}>
      {children}
    </DemandGraphSpanContext.Provider>
  );
};

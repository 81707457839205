import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { WeightAndHardSoftFields } from "settings/common/TeamGroupOnlyForms";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { ClockwiseShiftRotationLimitHours } from "components/setting/common/forms/Advanced/ClockwiseShiftRotationField/ClockwiseRotationLimitHours";

import { clockwiseRotationSetting } from "../ClockwiseRotationSetting";

type Props = { readOnly?: boolean };

export function ClockwiseShiftRotationForm({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    clockwiseRotationSetting.moduleName,
  );

  return (
    <PaperWrapper>
      <Stack gap={4}>
        <Stack gap={1}>
          <Typography variant="h4">
            Antal timmar vila tills beräkningen nollställs
          </Typography>
          <Typography>
            Detta fält styr hur många timmar vila som behöver passera innan
            beräkningen för medsols passrotation nollställs.
          </Typography>
          <ClockwiseShiftRotationLimitHours
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <WeightAndHardSoftFields
          moduleName={clockwiseRotationSetting.moduleName}
          softFieldName="clockwiseRotationSoft"
          weightFieldName="clockwiseRotationWeight"
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

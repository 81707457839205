import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctions,
  useDefaultTeamGroupSubmitFunction,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { minWeeklyRestAbWeightChoices } from "../../components/setting/team_group/setting_boxes/constants";

import {
  WeekRestSettingRuleGroup_fragment$data as RuleGroupData,
  WeekRestSettingRuleGroup_fragment$key as RuleGroupKey,
} from "./__generated__/WeekRestSettingRuleGroup_fragment.graphql";
import {
  WeekRestSettingTeamGroup_fragment$data as TeamGroupData,
  WeekRestSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/WeekRestSettingTeamGroup_fragment.graphql";
import {
  WeekRestSettingUserSetting_fragment$data as UserSettingData,
  WeekRestSettingUserSetting_fragment$key as UserSettingKey,
} from "./__generated__/WeekRestSettingUserSetting_fragment.graphql";
import { WeekRestForm } from "./WeekRestForm";
import { WeekRestTeamGroupOnlyForm } from "./WeekRestTeamGroupOnlyForm";

const teamGroupFragment = graphql`
  fragment WeekRestSettingTeamGroup_fragment on SettingNode {
    id
    minWeeklyRestAbHours
    weeklyDailyCanOverlap
    minWeeklyRestAbSoft
    minWeeklyRestAbWeight
    weekBreakHourAlternatives
    weekBreakDaysAlternatives
    constraintModules
  }
`;

const userSettingFragment = graphql`
  fragment WeekRestSettingUserSetting_fragment on UserSettingNode {
    id
    weekBreakHourAlternatives
    weekBreakDaysAlternatives
    settingModules
  }
`;

const ruleGroupFragment = graphql`
  fragment WeekRestSettingRuleGroup_fragment on RuleGroupSettingNode {
    id
    weekBreakHourAlternatives
    weekBreakDaysAlternatives
    settingModules
  }
`;

const SETTING_NAME = "Veckovila";
const MODULE_NAME = "WeeklyRestAB";
const SETTING_URL_ID = "week-rest-ab";

type WeekendRestFormValues = Pick<
  TeamGroupData,
  | "id"
  | "weekBreakHourAlternatives"
  | "weekBreakDaysAlternatives"
  | "constraintModules"
>;

type WeekendRestTeamGroupFormValues = Pick<
  TeamGroupData,
  | "id"
  | "minWeeklyRestAbHours"
  | "weeklyDailyCanOverlap"
  | "minWeeklyRestAbSoft"
  | "minWeeklyRestAbWeight"
  | "constraintModules"
>;

const validationSchema = yup.object().shape({
  weekBreakHourAlternatives: yup
    .array()
    .of(yup.number().min(0, "Måste vara minst 0 minuter"))
    .required("Måste anges"),
  weekBreakDaysAlternatives: yup
    .array()
    .of(yup.number().min(0, "Måste vara minst 0 dagar"))
    .required("Måste anges"),
  constraintModules: yup.array().of(yup.string()).required("Måste anges"),
});

const teamGroupValidationSchema = yup.object().shape({
  minWeeklyRestAbHours: yup
    .number()
    .min(0, "Måste vara minst 0 timmar")
    .max(24 * 7, "Måste vara högst 168 timmar")
    .required("Får ej vara tomt"),
  weeklyDailyCanOverlap: yup.boolean(),
  minWeeklyRestAbSoft: yup.boolean(),
  minWeeklyRestAbWeight: yup
    .string()
    .oneOf(minWeeklyRestAbWeightChoices, "Felaktig vikt")
    .required("Måste anges"),
});

function baseConvertDataToInitialValues(
  data: TeamGroupData | UserSettingData | RuleGroupData,
) {
  return {
    id: data.id,
    weekBreakHourAlternatives: data.weekBreakHourAlternatives ?? [15],
    weekBreakDaysAlternatives: data.weekBreakDaysAlternatives ?? [0],
  };
}

function convertTeamGroupDataToInitialValues(
  teamGroupData: TeamGroupData,
): WeekendRestFormValues {
  return {
    ...baseConvertDataToInitialValues(teamGroupData),
    constraintModules: teamGroupData.constraintModules,
  };
}
function convertUserOrRuleGroupDataToInitialValues(
  data: UserSettingData | RuleGroupData,
): WeekendRestFormValues {
  return {
    ...baseConvertDataToInitialValues(data),
    constraintModules: data.settingModules,
  };
}

function convertTeamGroupDataToTeamGroupSpecificInitialValues(
  teamGroupData: TeamGroupData,
): WeekendRestTeamGroupFormValues {
  return {
    id: teamGroupData.id,
    minWeeklyRestAbHours: teamGroupData.minWeeklyRestAbHours ?? 0,
    minWeeklyRestAbSoft: teamGroupData.minWeeklyRestAbSoft ?? true,
    minWeeklyRestAbWeight: teamGroupData.minWeeklyRestAbWeight ?? "M",
    weeklyDailyCanOverlap: teamGroupData.weeklyDailyCanOverlap ?? true,
    constraintModules: teamGroupData.constraintModules,
  };
}

function renderComponent(
  initialValues: WeekendRestFormValues,
  onSubmit: (formValues: WeekendRestFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <WeekRestForm />
    </BaseSettings>
  );
}

function renderTeamGroupComponent(
  initialValues: WeekendRestTeamGroupFormValues,
  onSubmit: (formValues: WeekendRestTeamGroupFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={teamGroupValidationSchema}
    >
      <WeekRestTeamGroupOnlyForm />
    </BaseSettings>
  );
}

export const weekRestSetting: OptimizationSetting<
  TeamGroupKey,
  UserSettingKey,
  RuleGroupKey,
  WeekendRestFormValues,
  {},
  WeekendRestTeamGroupFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment,
  ruleGroups: {
    ruleGroupFragment,
    convertDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  },
  convertTeamGroupDataToInitialValues,
  convertUserDataToInitialValues: convertUserOrRuleGroupDataToInitialValues,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctions,
  renderComponent,
  teamGroupOnly: {
    convertTeamGroupDataToInitialValues:
      convertTeamGroupDataToTeamGroupSpecificInitialValues,
    useSubmitTeamGroupOnly: useDefaultTeamGroupSubmitFunction,
    renderComponent: renderTeamGroupComponent,
  },
};

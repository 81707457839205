import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Box, Stack } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import graphql from "babel-plugin-relay/macro";
import type { ExtractNode, Unwrap } from "relay-help/arrays";
import { connectionToArray } from "relay-help/arrays";

import GraphTooltip from "../common/GraphTooltip";
import { XOption, XSelect } from "../common/XSelect";
import { TranslateAlteredKey } from "../types";

import {
  AlteredShiftsChart_fragment$data as Data,
  AlteredShiftsChart_fragment$key as Key,
} from "./__generated__/AlteredShiftsChart_fragment.graphql";
import { chartColor } from "./constants";

const fragment = graphql`
  fragment AlteredShiftsChart_fragment on ScheduleStats {
    userStats {
      id
      name
      nrShiftsAltered
      shiftsMinutesAltered
      shiftsMinutesAlteredAbsolute
      competences {
        edges {
          node {
            id
          }
        }
      }
      teams {
        edges {
          node {
            id
          }
        }
      }
    }
    userShifts {
      user {
        id
      }
      alteredShifts {
        netAlteration
      }
    }
  }
`;

type UserType = Unwrap<Data["userStats"]>;
type ShiftStats = Unwrap<Data["userShifts"]>;
type Competence = ExtractNode<NonNullable<UserType>["competences"]>;
type Team = ExtractNode<NonNullable<UserType>["teams"]>;
type RowType = Omit<UserType, "competences" | "teams"> &
  Omit<ShiftStats, "user"> & {
    teams: ReadonlyArray<Team>;
    competences: ReadonlyArray<Competence>;
  };

type Props = {
  fragmentRef: Key;
  teamId: string | null;
  competence: string | null;
  filterCompetence: boolean;
};

type xAxisValue = "total_count" | "total_hours" | "minutes_per_shifts";
const DEFAULT_OPTION = { value: "total_count", label: "Justerade pass" };
const OPTIONS: ReadonlyArray<XOption> = [
  DEFAULT_OPTION,
  { value: "total_hours", label: "Justerade timmar" },
  { value: "minutes_per_shifts", label: "Justeringar per pass" },
];

export function AlteredShiftsChart({
  fragmentRef,
  teamId,
  competence,
  filterCompetence,
}: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  const teamFilter = useCallback(
    (row: RowType) => !teamId || row.teams.some((e) => e.id === teamId),
    [teamId],
  );
  const competenceFilter = useCallback(
    (row: RowType) =>
      !filterCompetence || row.competences.some((e) => e.id === competence),
    [filterCompetence, competence],
  );
  const filter = useCallback(
    (r: RowType) => teamFilter(r) && competenceFilter(r),
    [teamFilter, competenceFilter],
  );

  const dataConverted = useMemo(
    () =>
      (data.userStats || [])
        .map(({ competences, teams, ...u }) => {
          const { alteredShifts } =
            data.userShifts.find((s) => s.user.id === u.id) || {};
          return {
            ...u,
            alteredShifts: alteredShifts || [],
            teams: connectionToArray(teams),
            competences: connectionToArray(competences),
          };
        })
        .filter(filter)
        .map((row) => {
          const userArray = {
            userKey: `${row.id} ${row.name}`,
            userName: row.name || "",
            userId: row.id || "",
            altered: row.nrShiftsAltered,
            alteredHours: (row.shiftsMinutesAltered / 60).toFixed(2),
            alteredMinutesAbsolute:
              row.shiftsMinutesAlteredAbsolute?.toFixed(2),
          };
          const shiftArray = (row.alteredShifts || []).map((s, i) => ({
            [String(i + 1)]: s?.netAlteration,
          }));
          return Object.assign(userArray, ...shiftArray);
        }),
    [data, filter],
  );
  console.log(dataConverted);
  const maxShiftCount = Math.max(
    ...dataConverted.map((u) => Object.keys(u).length - 5),
    0,
  );
  const shiftKeys = Array.from(Array(maxShiftCount).keys()).map((i) =>
    String(i + 1),
  );

  const totalValues = useMemo<Record<string, Record<xAxisValue, number>>>(
    () =>
      dataConverted.reduce(
        (acc, row) => ({
          ...acc,
          [row.userId]: {
            total_count: row.altered,
            total_hours: row.alteredHours,
            minutes_per_shifts: row.alteredMinutesAbsolute,
          },
        }),
        {},
      ),
    [dataConverted],
  );

  // Axis switch options
  const [xAxis, setXAxis] = useState(DEFAULT_OPTION);

  // Calculate minimal chart height and margins
  const height = 75 + dataConverted.length * 21;
  const leftMargin =
    13 + Math.max(...dataConverted.map((u: any) => u.userName.length)) * 5.6;
  const legendWidth = 30 + 8 * 6;

  // Legend data
  const legendsData = [
    {
      id: "altered",
      label: "Justerad",
      color: chartColor.ALTERED,
    },
  ];

  function TranslateAxisKey(xAxisValue: xAxisValue) {
    switch (xAxisValue) {
      case "total_count":
        return ["altered"];
      case "total_hours":
        return ["alteredHours"];
      case "minutes_per_shifts":
        return shiftKeys;
      default:
        return ["altered"];
    }
  }

  function TranslateGraphLabel(xAxisValue: xAxisValue) {
    switch (xAxisValue) {
      case "total_count":
        return "pass";
      case "total_hours":
        return "timmar";
      case "minutes_per_shifts":
        return "minuter";
      default:
        return "minuter";
    }
  }

  return (
    <Stack px={1} alignItems="center">
      <Box style={{ height, width: "100%" }}>
        {dataConverted.length > 0 && (
          <ResponsiveBar //This would have been nice: https://github.com/plouc/nivo/issues/535
            data={dataConverted}
            groupMode="stacked"
            layout="horizontal"
            keys={TranslateAxisKey(xAxis.value as xAxisValue)}
            indexBy="userName"
            colors={[chartColor.ALTERED]}
            margin={{
              top: 25,
              right: legendWidth + 20,
              bottom: 50,
              left: leftMargin,
            }}
            labelSkipWidth={12}
            labelTextColor="white"
            theme={{ text: { fontFamily: "Nunito" } }}
            innerPadding={1}
            tooltip={({ id, value, data }) => (
              <GraphTooltip
                keyId={id}
                value={value}
                totalValue={totalValues[data.userId][xAxis.value as xAxisValue]}
                translateKey={TranslateAlteredKey}
                unit={TranslateGraphLabel(xAxis.value as xAxisValue)}
                user={data.userName}
              />
            )}
            axisBottom={{
              legendPosition: "middle",
              legendOffset: 40,
            }}
            markers={[
              {
                axis: "x",
                value: 0,
                lineStyle: {
                  stroke: "lightgray",
                  strokeWidth: 1,
                },
              },
            ]}
            borderRadius={3}
            legends={[
              {
                data: legendsData,
                dataFrom: "keys",
                anchor: "right",
                direction: "column",
                translateX: legendWidth + 37,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </Box>
      <Box>
        <XSelect
          value={xAxis}
          onChange={setXAxis}
          options={OPTIONS}
          defaultValue={DEFAULT_OPTION}
        />
      </Box>
    </Stack>
  );
}

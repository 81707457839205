import { RRule, RRuleSet, rrulestr, Weekday } from "rrule";

import type { RecurrenceRuleType } from "../types";
import {
  MONTHLY,
  numbersToWeekdays,
  numberToWeekday,
  translateWeekday,
  WEEKLY,
  YEARLY,
} from "../types";

function adjustWeekday(dayDiff: number): (w: Weekday) => Weekday {
  // higher-order function, shift weekday by dayDiff and wrap around
  return (w: Weekday): Weekday => {
    const idx = w.weekday;
    const newIdx = (idx + dayDiff + 7) % 7;
    return numberToWeekday(newIdx);
  };
}
function adjustMonth(monthDiff: number): (m: number) => number {
  // higher-order function, shift month by monthDiff and wrap around
  return (m: number): number => {
    const newMonth = (m + monthDiff + 12) % 12;
    return newMonth === 0 ? 12 : newMonth;
  };
}
function adjustMonthday(dayDiff: number): (d: number) => number {
  // higher-order function, shift monthday by dayDiff and wrap around
  return (d: number): number => {
    const newDay = (d + dayDiff + 31) % 31;
    return newDay === 0 ? 31 : newDay;
  };
}

function adjustFields(
  rule: RecurrenceRuleType,
  hoursToShift: number,
): RecurrenceRuleType {
  /* usdd by adjustLocalToUtc and adjustUtcToLocal */
  // bysetpos is relative and should not need to change

  const { freq, bymonth, bymonthday, byweekday } = rule;
  if (hoursToShift === 0) return rule;

  const newRule: RecurrenceRuleType = { ...rule };
  const hourDiffSign = Math.sign(hoursToShift);

  if (freq === YEARLY) {
    newRule.bymonth = bymonth && bymonth.map(adjustMonth(hourDiffSign));
  } else if (freq === MONTHLY) {
    newRule.bymonthday =
      bymonthday && bymonthday.map(adjustMonthday(hourDiffSign));
  } else if (freq === WEEKLY) {
    newRule.byweekday = byweekday && byweekday.map(adjustWeekday(hourDiffSign));
  }

  return newRule;
}

export function adjustLocalToUtc(rule: RecurrenceRuleType): RecurrenceRuleType {
  /* Correct recurrences when local time-to-utc flips day. */

  const { dtstart } = rule;
  if (!dtstart) return rule;

  const dateDiff = dtstart.getDate() - dtstart.getUTCDate();
  if (!dateDiff) return rule;

  const hourDiff = dtstart.getTimezoneOffset() / 60;
  const newRule = adjustFields(rule, hourDiff);
  return newRule;
}

export function adjustUtcToLocal(rule: RecurrenceRuleType): RecurrenceRuleType {
  /* Correct recurrences when utc time-to-local flips day. */

  const { dtstart } = rule;
  if (!dtstart) return rule;

  const dateDiff = dtstart.getUTCDate() - dtstart.getDate();
  if (!dateDiff) return rule;

  const hourDiff = (-1 * dtstart.getTimezoneOffset()) / 60;
  const newRule = adjustFields(rule, hourDiff);
  return newRule;
}

function rruleToRecurrenceRule(rule: RRule): RecurrenceRuleType {
  const {
    freq,
    dtstart,
    interval,
    count,
    until,
    bysetpos,
    bymonth,
    bymonthday,
    byweekday,
  } = rule.options || {};
  return {
    exclude: false,
    isDate: false,
    freq,
    dtstart,
    interval,
    count,
    until,
    bysetpos,
    bymonth,
    bymonthday,
    byweekday: byweekday ? numbersToWeekdays(byweekday) : [],
  };
}
function rdateToRecurrenceRule(date: Date): RecurrenceRuleType {
  return {
    exclude: false,
    isDate: true,
    dtstart: date,
    freq: YEARLY,
    interval: 1,
    bysetpos: null,
    bymonth: null,
    bymonthday: null,
    byweekday: null,
    until: null,
    count: null,
  };
}

export function recurrenceRuleToRrule(rule: RecurrenceRuleType): RRule {
  const {
    freq,
    dtstart,
    interval,
    count,
    until,
    bysetpos,
    bymonth,
    bymonthday,
    byweekday,
  } = rule;
  return new RRule({
    freq,
    dtstart,
    interval,
    count,
    until,
    bysetpos,
    bymonth,
    bymonthday,
    byweekday,
  });
}

function recurrenceRuleToRdate(rule: RecurrenceRuleType): Date {
  return rule.dtstart || new Date();
}

export function toRfc2445String(rlist: RecurrenceRuleType[]) {
  if (rlist.length === 0) {
    return "";
  }

  const set = new RRuleSet();
  rlist.forEach((rule: RecurrenceRuleType) => {
    if (rule.exclude && rule.dtstart) {
      set.exdate(recurrenceRuleToRdate(rule));
    } else if (rule.exclude && !rule.isDate) {
      set.exrule(recurrenceRuleToRrule(rule));
    } else if (!rule.exclude && rule.isDate) {
      set.rdate(recurrenceRuleToRdate(rule));
    } else {
      set.rrule(recurrenceRuleToRrule(rule));
    }
  });

  return set.toString();
}

export function fromRfc2445String(rstring: string) {
  const set: RRuleSet = rrulestr(rstring, { forceset: true }) as RRuleSet;
  const rlist: RecurrenceRuleType[] = [];

  set.rrules().forEach((rule: RRule) => {
    rlist.push(rruleToRecurrenceRule(rule));
  });
  set.rdates().forEach((date: Date) => {
    rlist.push(rdateToRecurrenceRule(date));
  });
  set.exrules().forEach((rule: RRule) => {
    rlist.push({ ...rruleToRecurrenceRule(rule), exclude: true });
  });
  set.exdates().forEach((date: Date) => {
    rlist.push({ ...rdateToRecurrenceRule(date), exclude: true });
  });

  return rlist;
}

interface Language {
  dayNames: string[];
  monthNames: string[];
  tokens: {
    [k: string]: RegExp;
  };
}

export const SWEDISH: Language = {
  dayNames: [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
  ],
  monthNames: [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
  ],
  tokens: {
    SKIP: /^[ \r\n\t]+|^\.$/,
    number: /^[1-9][0-9]*/,
    numberAsText: /^(en|två|tre)/i,
    every: /^varje/i,
    "day(s)": /^dag(ar)?/i,
    "weekday(s)": /^veckodag(ar)?/i,
    "week(s)": /^veck(or)?/i,
    "hour(s)": /^timm(e|ar)/i,
    "minute(s)": /^minut(er)?/i,
    "month(s)": /^månad(er)?/i,
    "year(s)": /^år/i,
    on: /^(på|i)/i,
    at: /^(vid)/i,
    the: /^den/i,
    first: /^första/i,
    second: /^andra/i,
    third: /^tredje/i,
    nth: /^([1-9][0-9]*)(\.|a|de|dje|te)/i,
    last: /^sista/i,
    for: /^för/i,
    "time(s)": /^gång(er)?/i,
    until: /^till/i,
    monday: /^må(n(dag)?)?/i,
    tuesday: /^ti(s(dag)?)?/i,
    wednesday: /^on(s(dag)?)?/i,
    thursday: /^to(r(s(dag)?)?)?/i,
    friday: /^fr(e(dag)?)?/i,
    saturday: /^lö(r(dag)?)?/i,
    sunday: /^sö(n(dag)?)?/i,
    january: /^jan(uari)?/i,
    february: /^feb(ruari)?/i,
    march: /^mar(s)?/i,
    april: /^apr(il)?/i,
    may: /^maj/i,
    june: /^juni?/i,
    july: /^juli?/i,
    august: /^aug(usti)?/i,
    september: /^sep(t(ember)?)?/i,
    october: /^okt(ober)?/i,
    november: /^nov(ember)?/i,
    december: /^dec(ember)?/i,
    comma: /^(,\s*|(och|eller)\s*)+/i,
  },
};
const engToSwe: { [k: string]: string } = {
  monday: "måndag",
  tuesday: "tisdag",
  wednesday: "onsdag",
  thursday: "torsdag",
  friday: "fredag",
  saturday: "lördag",
  sunday: "söndag",
  january: "januari",
  february: "februari",
  march: "mars",
  april: "april",
  may: "maj",
  june: "juni",
  july: "juli",
  august: "augusti",
  september: "september",
  october: "oktober",
  november: "november",
  december: "december",
  day: "dag",
  days: "dagar",
  week: "vecka",
  weeks: "veckor",
  every: "varje",
  and: "och",
  or: "eller",
  on: "på",
  the: "den",
  "on the": "den",
  month: "månad",
  months: "månader",
  year: "år",
  years: "år",
  at: "vid",
  first: "första",
  second: "andra",
  third: "tredje",
  last: "sista",
  for: "för",
  until: "till",
  time: "gång",
  times: "gånger",
  st: "a",
  nd: "a",
  rd: "e",
  th: "e",
};
export function getText(id: string | number | Weekday) {
  if (typeof id === "number") {
    return id.toString();
  }
  if (typeof id === "string") {
    return engToSwe[id];
  }
  return translateWeekday(id, true);
}

export const dateFormatter = (y: number, mo: string, day: number) =>
  `${day} ${mo}, ${y}`;

import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import type { UserTimebanks_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment UserTimebanks_fragment on UserTimebankViewer {
    users {
      user {
        id
      }
      timebank
    }
  }
`;

export const useUserTimebanks = (fragmentRef: Key) =>
  useFragment<Key>(fragment, fragmentRef);

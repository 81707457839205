import { commitMutation as commitMutation_ } from "react-relay";
import type {
  IEnvironment,
  MutationConfig,
  MutationParameters,
} from "relay-runtime";

export const commitMutation = <T extends MutationParameters>(
  environment: IEnvironment,
  config: Omit<MutationConfig<T>, "onError" | "onCompleted">,
): Promise<T["response"]> =>
  new Promise((resolve, reject) => {
    commitMutation_<T>(
      environment,
      Object.assign({}, config, {
        onCompleted: (payload: any, errors: any) => {
          if (errors) {
            reject(errors[0]);
            return;
          }
          resolve(payload);
        },
        onError: reject,
      }),
    );
  });

import { useMemo, useState } from "react";
import { useFragment } from "react-relay/hooks";
import { Box, Stack, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import graphql from "babel-plugin-relay/macro";

import {
  TranslateVariableGroup,
  TranslateVariableType,
} from "components/schedule/types";

import { XOption, XSelect } from "../common/XSelect";
import { TranslateVariableKey, VariableColorSwitch } from "../types";

import { chartColor } from "./constants";
import { VariableSolutionsChart_fragment$key as Key } from "./types";

const fragment = graphql`
  fragment VariableSolutionsChart_fragment on ScheduleNode {
    variableSolutions {
      variableName
      variableGroup
      variableWeight
      variableSolution
      variableContribution
    }
  }
`;

type Props = {
  fragmentRef: Key;
};

const DEFAULT_OPTION = { value: "contribution", label: "Bidrag" };
const OPTIONS: ReadonlyArray<XOption> = [
  DEFAULT_OPTION,
  { value: "weight", label: "Vikt" },
  { value: "solution", label: "Värde" },
];

function Tooltip({
  id,
  value,
  suffix,
}: {
  id: string | number;
  value: number;
  suffix: string;
}) {
  return (
    <Stack
      sx={{
        bgcolor: "white",
        opacity: 0.9,
        p: "5px 10px",
        borderRadius: 0.5,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "divider",
      }}
    >
      <Typography>
        {TranslateVariableKey(id)} <strong>{`${value}${suffix}`}</strong>
      </Typography>
    </Stack>
  );
}

export function VariableSolutionsChart({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);

  const dataConverted = useMemo(
    () =>
      (data.variableSolutions || []).map((row) => ({
        name: TranslateVariableType(row.variableName) || row.variableName,
        group: TranslateVariableGroup(row.variableGroup) || row.variableGroup,
        weight: row.variableWeight,
        solution: row.variableSolution,
        contribution: row.variableContribution,
      })),
    [data],
  );

  // Axis switch options
  const [xAxis, setXAxis] = useState(DEFAULT_OPTION);

  const nrTicks = 10;
  const xAxisLimits = {
    contribution: Math.max(...dataConverted.map((d) => d.contribution)),
    weight: Math.max(...dataConverted.map((d) => d.weight)),
    solution: Math.max(...dataConverted.map((d) => d.solution)),
  };

  //const height = 60 + data.viewer.length * 30;
  const leftMargin =
    13 + Math.max(...dataConverted.map((d) => d.name.length)) * 6.5;

  // Legend data
  const legendsData = [
    {
      id: "contribution",
      label: "Bidrag",
      color: chartColor.CONTRIBUTION,
    },
    {
      id: "weight",
      label: "Vikt",
      color: chartColor.WEIGHT,
    },
    {
      id: "solution",
      label: "Värde",
      color: chartColor.SOLUTION,
    },
  ];
  const legendWidth = 30 + 8 * 6;

  // Group data
  const grouped = dataConverted.reduce((acc: any, curr: any) => {
    const group = curr.group;
    (acc[group] = acc[group] || []).push(curr);
    return acc;
  }, {});
  const groupedData = Object.values(grouped);

  return (
    <Stack p={2} gap={4} alignItems="center">
      {groupedData.map((d: any, i: number) => {
        return (
          <Box
            key={`var-sol-data-${i}`}
            sx={{ height: d.length * 24, width: "100%" }}
          >
            <Typography
              variant="h5"
              width={leftMargin}
              sx={{ textAlign: "right", pr: 1.2 }}
            >
              {Object.keys(grouped)[i]}
            </Typography>
            {BarChart({
              data: d,
              xAxis: xAxis.value,
              legendWidth: legendWidth,
              leftMargin: leftMargin,
              nrTicks: nrTicks,
              legendsData: legendsData,
              xAxisLimits: xAxisLimits,
            })}
          </Box>
        );
      })}
      <Box>
        <XSelect
          value={xAxis}
          onChange={setXAxis}
          options={OPTIONS}
          defaultValue={DEFAULT_OPTION}
        />
      </Box>
    </Stack>
  );
}

type BarChartProps = {
  data: any;
  xAxis: string;
  legendWidth: number;
  leftMargin: number;
  nrTicks: number;
  legendsData: any;
  xAxisLimits: any;
};

export function BarChart(data: BarChartProps) {
  return (
    <ResponsiveBar
      data={data.data}
      groupMode="stacked"
      layout="horizontal"
      keys={[data.xAxis]}
      indexBy="name"
      colors={VariableColorSwitch(data.xAxis)}
      margin={{
        top: 0,
        right: 5,
        bottom: 0,
        left: data.leftMargin,
      }}
      labelSkipWidth={20}
      labelTextColor="white"
      labelFormat={
        data.xAxis === "contribution" ? (v) => `${v}%` : (v) => `${v}`
      }
      tooltip={(input) => (
        <Tooltip
          id={input.id}
          value={input.value}
          suffix={data.xAxis === "contribution" ? "%" : ""}
        />
      )}
      valueScale={{
        type: "linear",
        min: 0,
        max: data.xAxisLimits[data.xAxis],
        clamp: true,
      }}
      axisBottom={null}
      theme={{
        text: { fontFamily: "Nunito" },
        axis: {
          ticks: {
            text: {
              fontSize: 12.5,
              fontFamily: "Nunito",
            },
          },
        },
      }}
      borderRadius={3}
      innerPadding={1}
    />
  );
}

import { createContext, useContext, useMemo } from "react";
import { useFragment } from "react-relay";
import type { ListProps } from "@mui/material";
import {
  alpha,
  Chip,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFormikState } from "hooks";
import { connectionToArray } from "relay-help/arrays";

import type { ParticipantList_users$key as Key } from "./types";

type Team = {
  id: string;
  name: string;
  color: string;
};
type Participant = {
  id: string;
  fullName: string;
  teams: ReadonlyArray<Team>;
};
type RendererProps = {
  selected: ReadonlyArray<string>;
  options: ReadonlyArray<Participant>;
} & ListProps;
type Props = Omit<RendererProps, "options">;
type FormikProps = Omit<Props, "name" | "selected" | "options"> & {
  name: string;
};

const fragment = graphql`
  fragment ParticipantList_users on UserNode @relay(plural: true) {
    id
    fullName
    memberOf {
      edges {
        node {
          id
          name
          color
        }
      }
    }
  }
`;

export function ParticipantList(props: Props) {
  const { participants } = useParticipantList();
  return <ParticipantListRenderer options={participants} {...props} />;
}

export function FormikParticipantList({ name, ...props }: FormikProps) {
  const { value } = useFormikState<ReadonlyArray<string>>(name);
  return <ParticipantList selected={value} {...props} />;
}

function ParticipantListRenderer({
  selected,
  options,
  ...props
}: RendererProps) {
  const selectedSet = useMemo(() => new Set(selected), [selected]);
  const filteredOptions = useMemo(
    () => options.filter((o) => selectedSet.has(o.id)),
    [options, selectedSet],
  );

  return (
    <List {...props}>
      {filteredOptions.map((p) => (
        <ListItem key={p.id} disableGutters divider>
          <Stack
            direction="row"
            gap={1}
            justifyContent="space-between"
            alignItems="center"
            flexBasis="100%"
          >
            <ListItemText>{p.fullName}</ListItemText>
            <Stack
              direction="row"
              gap={0.5}
              flexWrap="wrap"
              justifyContent="flex-end"
            >
              <Teams teams={p.teams} />
            </Stack>
          </Stack>
        </ListItem>
      ))}
    </List>
  );
}

const Teams: React.FC<{ teams: ReadonlyArray<Team> }> = ({ teams }) => (
  <>
    {teams.map((t) => (
      <TeamChip key={t.id} team={t} />
    ))}
  </>
);

const TeamChip: React.FC<{ team: Team }> = ({ team }) => (
  <Chip
    size="small"
    label={team.name}
    variant="outlined"
    sx={{ bgcolor: alpha(team.color, 0.04), borderColor: team.color }}
  />
);

function useParticipantList() {
  const fragmentRef = useParticipantListContext();
  const users = useFragment<Key>(fragment, fragmentRef);
  const participants = useMemo(
    () =>
      users.map(({ memberOf, ...u }) => ({
        ...u,
        teams: connectionToArray(memberOf),
      })),
    [users],
  );

  return { participants };
}

const ParticipantListContext = createContext<Key | null>(null);
export const ParticipantListProvider: React.FC<
  React.PropsWithChildren<{ fragmentRef: Key }>
> = ({ fragmentRef, children }) => (
  <ParticipantListContext.Provider value={fragmentRef} children={children} />
);
const useParticipantListContext = (): Key => {
  const c = useContext(ParticipantListContext);

  if (!c) {
    throw new Error(
      "useParticipantListContext must be used within ParticipantListProvider",
    );
  }

  return c as Key;
};

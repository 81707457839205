import { Suspense, useState } from "react";
import {
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay/hooks";
import type { SxProps, Theme } from "@mui/material";
import { Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import graphql from "babel-plugin-relay/macro";
import { scheduleWarningsQuery } from "pages/Schedule/Results";
import { ResultsScheduleWarningsContextQuery } from "pages/types";
import { EMPTY_ARRAY } from "utils/constants";

import { drawerWidth } from "./constants";
import { DrawerContents } from "./DrawerContents";
import type { ScheduleWarningsDrawer_fragment$key as Key } from "./types";

export const fragment = graphql`
  fragment ScheduleWarningsDrawer_fragment on ScheduleValidationWarnings {
    failedToFetch
    viewer {
      errors {
        module
        message
        activity {
          start
          end
        }
        user {
          id
          name
        }
        hardError
      }
    }
  }
`;

type CommonProps = {
  refresh: () => Promise<void>;
  open: boolean;
  onClose: () => void;
  scheduleWarningsQueryRef: PreloadedQuery<ResultsScheduleWarningsContextQuery>;
  sx?: SxProps<Theme>;
  paperSx?: SxProps<Theme>;
};

type Props = CommonProps;

type ContentProps = CommonProps & {
  fetching: boolean;
};

function Content({
  open,
  onClose,
  refresh,
  fetching,
  scheduleWarningsQueryRef,
  sx = {},
  paperSx = {},
}: ContentProps) {
  const scheduleWarningsData = usePreloadedQuery(
    scheduleWarningsQuery,
    scheduleWarningsQueryRef,
  );
  const data = useFragment<Key>(
    fragment,
    scheduleWarningsData.scheduleValidationWarnings,
  );
  const { viewer, failedToFetch } = data || {};
  const theme = useTheme();

  const drawerSx = {
    width: open ? drawerWidth : 0,
    flexShrink: 0,
    "&  .MuiDrawer-root": {
      position: "absolute",
    },
    "& .MuiDrawer-paper": {
      position: "absolute",
      width: open ? drawerWidth : 0,
      boxSizing: "border-box",
      borderColor: theme.palette.divider,
      borderStyle: "solid",
      borderWidth: "1px",
      ...paperSx,
    },
    ...sx,
  };

  if (failedToFetch) return null;

  if (!viewer) return null;

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={drawerSx}
    >
      <DrawerContents
        onClose={onClose}
        warnings={viewer.errors ?? EMPTY_ARRAY}
        refresh={refresh}
        fetching={fetching}
      />
    </Drawer>
  );
}

export function ScheduleWarningsDrawer({
  refresh: refreshQuery,
  open,
  onClose,
  scheduleWarningsQueryRef,
  sx = {},
  paperSx = {},
}: Props) {
  const [fetching, setFetching] = useState(false);

  async function refresh() {
    if (fetching) return;
    setFetching(true);
    await refreshQuery().finally(() => setFetching(false));
  }

  return (
    <Suspense fallback={null}>
      <Content
        open={open}
        onClose={onClose}
        refresh={refresh}
        fetching={fetching}
        scheduleWarningsQueryRef={scheduleWarningsQueryRef}
        sx={sx}
        paperSx={paperSx}
      />
    </Suspense>
  );
}

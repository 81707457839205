/**
 * @generated SignedSource<<30bb87b802afd687c846ef30b5a0c5ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserTimebanks_fragment$data = {
  readonly users: ReadonlyArray<{
    readonly timebank: number;
    readonly user: {
      readonly id: string;
    };
  }>;
  readonly " $fragmentType": "UserTimebanks_fragment";
};
export type UserTimebanks_fragment$key = {
  readonly " $data"?: UserTimebanks_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserTimebanks_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserTimebanks_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTimebankNode",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserNode",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timebank",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserTimebankViewer",
  "abstractKey": null
};

(node as any).hash = "3ec8622db99068c47183e479c4647635";

export default node;

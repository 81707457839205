import { CircularProgress as Loading, Stack, Typography } from "@mui/material";

import {
  NoticeBox,
  SuccessBox,
  WarningBox,
} from "components/common/WarningBox";
import { ValidationResultState } from "components/schedule/activities/validations/types";
import {
  TranslateWarningModule,
  WarningModuleType,
} from "components/types/ConstraintModuleType";

type Props = {
  state: ValidationResultState;
};

export const ValidationResult = ({ state }: Props) => {
  if (state.result === undefined) return null;

  if (state.loading) {
    return (
      <NoticeBox icon={<Loading />} borderColor="success.main">
        <Stack justifyContent="center">
          <Typography>Validerar...</Typography>
        </Stack>
      </NoticeBox>
    );
  }

  if (state.failedToFetch) {
    return (
      <WarningBox>
        <Typography>Kunde inte validera schemat</Typography>
      </WarningBox>
    );
  }

  if (state.result?.ok) {
    return (
      <SuccessBox>
        <Typography>Schemat uppfyller alla regler</Typography>
      </SuccessBox>
    );
  }

  // Validation not OK
  // TODO: Make module string an enum on server side
  return (
    <WarningBox>
      <Stack>
        {state.result?.errors?.map((e, i) => {
          const name = e.user?.name ?? "Okänd användare";
          const { message } = e;
          const activityString = e.activity
            ? new Date(Date.parse(e.activity.start)).toLocaleString()
            : "";
          const str = ` - ${name}: ${message} ${activityString}`;
          return (
            <Typography key={i}>
              <strong>
                {TranslateWarningModule(e.module as WarningModuleType)}
              </strong>
              {str}
            </Typography>
          );
        })}
      </Stack>
    </WarningBox>
  );
};

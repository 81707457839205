/**
 * @generated SignedSource<<d035f4c150fbcbb328e8aad7f04008fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApiActivityActivityTypeChoices = "A" | "C" | "D" | "F" | "J" | "M" | "O" | "P" | "Q" | "R" | "S" | "V" | "W" | "Y" | "Z" | "%future added value";
export type ApiActivityPartPartTypeChoices = "B" | "J" | "M" | "P" | "Z" | "%future added value";
export type EditActivityInput = {
  activityId: string;
  activityParts?: ReadonlyArray<ActivityPartInput | null> | null;
  activityType: ApiActivityActivityTypeChoices;
  breakTime: number;
  clientMutationId?: string | null;
  end: string;
  start: string;
  teamId?: string | null;
  userId: string;
};
export type ActivityPartInput = {
  end: string;
  id?: string | null;
  partType: ApiActivityPartPartTypeChoices;
  start: string;
};
export type EditActivityMutation$variables = {
  input: EditActivityInput;
};
export type EditActivityMutation$data = {
  readonly editActivity: {
    readonly activity: {
      readonly activityParts: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly end: string;
            readonly id: string;
            readonly partType: ApiActivityPartPartTypeChoices;
            readonly start: string;
          } | null;
        } | null>;
      };
      readonly activityType: ApiActivityActivityTypeChoices;
      readonly breakTime: number | null;
      readonly end: string;
      readonly id: string;
      readonly start: string;
    } | null;
    readonly clonedSchedule: {
      readonly id: string;
    } | null;
    readonly ok: boolean | null;
  } | null;
};
export type EditActivityMutation = {
  response: EditActivityMutation$data;
  variables: EditActivityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditActivityPayload",
    "kind": "LinkedField",
    "name": "editActivity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ActivityNode",
        "kind": "LinkedField",
        "name": "activity",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activityType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityPartNodeConnection",
            "kind": "LinkedField",
            "name": "activityParts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityPartNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityPartNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "partType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "clonedSchedule",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditActivityMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditActivityMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "fcdbd447555b7a5215b5c34f48a55b9d",
    "id": null,
    "metadata": {},
    "name": "EditActivityMutation",
    "operationKind": "mutation",
    "text": "mutation EditActivityMutation(\n  $input: EditActivityInput!\n) {\n  editActivity(input: $input) {\n    ok\n    activity {\n      id\n      start\n      end\n      breakTime\n      activityType\n      activityParts {\n        edges {\n          node {\n            id\n            start\n            end\n            partType\n          }\n        }\n      }\n    }\n    clonedSchedule {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "868746ec6aed934e27a949980f049444";

export default node;

import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useChangeTitle, useCurrentTeamGroup, useDemandParams } from "hooks";
import { DateTime } from "luxon";

import {
  CompetenceSelect,
  CompetenceSelectFragmentRefProvider,
} from "components/common/selectors/CompetenceSelect";
import {
  TeamSelect2,
  TeamSelect2FragmentRefProvider,
} from "components/common/selectors/TeamSelect";
import {
  DemandGraphSpan,
  DemandGraphSpanProvider,
} from "components/demand/DemandGraphSpan";
import DemandHeader from "components/demand/DemandHeader";
import { DemandForm } from "components/demand/form";
import { ALL_COMPETENCES, ALL_TEAMS } from "components/demand/form/utils";
import {
  DemandProfileChart,
  demandProfileChartQuery,
} from "components/demand/graphs/DemandProfileChart";
import { InfoModalProvider } from "components/layout/InfoModal/InfoModalContext";
import PageWrapper from "components/layout/PageWrapper";
import { DemandPlaceholder as Placeholder } from "components/loading/pages";

import { DemandQuery as Query } from "./__generated__/DemandQuery.graphql";
import { DemandInfo as Info } from "./info";

const query = graphql`
  query DemandQuery($teamGroupId: ID!, $now: Date!) {
    formDemands(teamGroupId: $teamGroupId) {
      ...DemandForm_fragment
    }
    competences {
      ...CompetenceSelect_fragment
    }
    teams {
      ...TeamSelect2_fragment
    }
    nextPeriod(groupId: $teamGroupId, now: $now) {
      startDate
      endDate
    }
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      periodLengthWeeks
      uniqueWeeks
      useDutyDemand
      useDemandWeights
    }
  }
`;

type Props = {
  teamGroupId: string;
  queryRef: PreloadedQuery<Query>;
};

function Content({ teamGroupId, queryRef }: Props) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const {
    team: teamId,
    setTeam: setTeamId,
    competence: competenceId,
    setCompetence: setCompetenceId,
  } = useDemandParams();
  const environment = useRelayEnvironment();

  const allCompetences = useMemo(
    () => ({ id: ALL_COMPETENCES, name: "Totalt behov", color: "" }),
    [],
  );
  const periodLengthWeeks = useMemo(
    () => data.settingForTeamGroup?.periodLengthWeeks ?? 1,
    [data.settingForTeamGroup],
  );
  const uniqueWeeks = useMemo(
    () => data?.settingForTeamGroup?.uniqueWeeks ?? false,
    [data.settingForTeamGroup],
  );
  const showDutyDemand = !!data.settingForTeamGroup?.useDutyDemand;
  const initStart = useMemo<Date>(
    () =>
      data.nextPeriod?.startDate
        ? DateTime.fromISO(data.nextPeriod.startDate).toJSDate()
        : DateTime.now().startOf("week").plus({ weeks: 1 }).toJSDate(),
    [data.nextPeriod?.startDate],
  );
  const initEnd = useMemo(
    () =>
      DateTime.fromJSDate(initStart)
        .plus({ weeks: uniqueWeeks ? periodLengthWeeks : 1 })
        .toJSDate(),
    [initStart, uniqueWeeks, periodLengthWeeks],
  );

  // Refresh data if saved
  async function refresh() {
    const start = DateTime.fromJSDate(initStart).toFormat("yyyy-MM-dd");
    const end = DateTime.fromJSDate(initEnd).toFormat("yyyy-MM-dd");
    const now = DateTime.now().toFormat("yyyy-MM-dd");
    await Promise.all([
      fetchQuery(environment, demandProfileChartQuery, {
        teamGroupId,
        start,
        end,
      }).toPromise(),
      fetchQuery(environment, query, { teamGroupId, now }).toPromise(),
    ]);
  }

  function onTeamChange(newTeam: string | null) {
    const t = newTeam || ALL_TEAMS;
    setTeamId(t);
    if (t === ALL_TEAMS) {
      setCompetenceId(ALL_COMPETENCES);
    }
  }

  function onCompetenceChange(newCompetence: string | null) {
    const c = newCompetence || ALL_COMPETENCES;
    setCompetenceId(c);
  }

  return (
    <DemandGraphSpanProvider start={initStart} end={initEnd}>
      <Stack gap={2}>
        <Stack direction="row" gap={2}>
          <TeamSelect2FragmentRefProvider value={data.teams}>
            <TeamSelect2
              onChange={onTeamChange}
              filterOnGroupIds={[teamGroupId]}
              sx={{ minWidth: 200 }}
            />
          </TeamSelect2FragmentRefProvider>
          <CompetenceSelectFragmentRefProvider value={data.competences}>
            <CompetenceSelect
              value={competenceId}
              onChange={onCompetenceChange}
              insert={{ before: [allCompetences] }}
              disabled={teamId === ALL_TEAMS}
              sx={{ minWidth: 200 }}
            />
          </CompetenceSelectFragmentRefProvider>
          {uniqueWeeks && <DemandGraphSpan />}
        </Stack>
        <Stack>
          <Paper variant="box">
            <DemandProfileChart
              teamGroupId={teamGroupId}
              teamId={teamId || ALL_TEAMS}
              competenceId={competenceId || ALL_COMPETENCES}
              weekCount={periodLengthWeeks}
              uniqueWeeks={uniqueWeeks}
            />
          </Paper>
        </Stack>
        {teamId !== ALL_TEAMS && (
          <DemandForm
            fragmentRef={data.formDemands}
            teamId={teamId}
            competenceId={
              competenceId === ALL_COMPETENCES ? null : competenceId
            }
            weekCount={periodLengthWeeks}
            uniqueWeeks={uniqueWeeks}
            showDutyDemand={showDutyDemand}
            showDemandWeights={
              data?.settingForTeamGroup?.useDemandWeights ?? false
            }
            afterSubmit={refresh}
          />
        )}
      </Stack>
    </DemandGraphSpanProvider>
  );
}

export function Demand() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const { clear } = useDemandParams();
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id || "";

  useEffect(() => {
    if (!teamGroupId) return;
    const now = DateTime.now().toFormat("yyyy-MM-dd");
    loadQuery({ teamGroupId, now });
    clear();
  }, [teamGroupId, loadQuery, clear]);

  useChangeTitle("Behovsprofil");

  return (
    <InfoModalProvider>
      <PageWrapper
        header={
          <DemandHeader
            teamGroupName={teamGroup?.name || ""}
            info={<Info group={teamGroupId} />}
          />
        }
      >
        <Suspense fallback={<Placeholder />}>
          {!!queryRef && !!teamGroupId && (
            <Content teamGroupId={teamGroupId} queryRef={queryRef} />
          )}
        </Suspense>
      </PageWrapper>
    </InfoModalProvider>
  );
}

/**
 * @generated SignedSource<<06d71cc30288a7e917a807aebebc7a33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiSettingMinDailyRestAbWeightChoices = "H" | "L" | "M" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DayRestSettingTeamGroup_fragment$data = {
  readonly constraintModules: ReadonlyArray<string>;
  readonly dayBreakAlternatives: ReadonlyArray<number>;
  readonly id: string;
  readonly minDailyRestAbHours: number;
  readonly minDailyRestAbSoft: boolean;
  readonly minDailyRestAbWeight: ApiSettingMinDailyRestAbWeightChoices;
  readonly " $fragmentType": "DayRestSettingTeamGroup_fragment";
};
export type DayRestSettingTeamGroup_fragment$key = {
  readonly " $data"?: DayRestSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DayRestSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DayRestSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestAbHours",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayBreakAlternatives",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestAbSoft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minDailyRestAbWeight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "constraintModules",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "5b9f8dac7ccd9371b2ab58326838afbb";

export default node;

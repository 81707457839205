import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FieldArray } from "formik";
import useFormikState from "hooks/useFormikState";
import type { FormikFieldArrayFn } from "types/formik";

import type { WorkDayCadenceRestrictionInput } from "components/setting/types";

import { WorkDayCadenceRestriction } from "./WorkDayCadenceRestriction";

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
};

type FormValues = WorkDayCadenceRestrictionInput[];

type FieldArrayFn = FormikFieldArrayFn<FormValues>;

type FooterProps = {
  disabled?: boolean;
  readOnly?: boolean;
  onAdd: () => void;
};

const defaultItem: WorkDayCadenceRestrictionInput = {
  maxCadence: 6,
  calendarDays: 8,
  isSoft: true,
};

function Footer({ disabled, readOnly, onAdd }: FooterProps) {
  if (readOnly) {
    return null;
  }

  return (
    <Box sx={{ alignSelf: "flex-end" }}>
      <Button
        variant="primary"
        onClick={onAdd}
        disabled={disabled}
        endIcon={<AddIcon />}
      >
        Ny regel
      </Button>
    </Box>
  );
}

export function WorkDayCadenceRestrictions({
  name,
  disabled,
  readOnly,
}: Props) {
  const { value } = useFormikState<WorkDayCadenceRestrictionInput[]>(name);
  if (readOnly && value.length === 0) {
    return (
      <Typography fontStyle="italic">
        Här fanns inga regler tillagda!
      </Typography>
    );
  }
  return (
    <Stack gap={2}>
      <FieldArray name={name}>
        {({ push, remove }: FieldArrayFn) => (
          <>
            {(value || []).map((_: any, index: number) => (
              <WorkDayCadenceRestriction
                key={index}
                name={`${name}.${index}`}
                onRemove={() => remove(index)}
                disabled={disabled}
                readOnly={readOnly}
              />
            ))}
            <Footer
              disabled={disabled}
              readOnly={readOnly}
              onAdd={() => push(defaultItem)}
            />
          </>
        )}
      </FieldArray>
    </Stack>
  );
}

import { useMemo } from "react";
import { InputAdornment, Typography } from "@mui/material";
import { useFormikState } from "hooks";

import Field from "components/forms/CustomField";
import ExampleBox from "components/setting/common/ExampleBox";

import { round } from "./utils";

type HookProps = {
  periodLengthWeeks: number;
  shiftsPerWeek: number;
  totalShiftsMargin: number;
};

function useTotalMarginShifts({
  periodLengthWeeks,
  shiftsPerWeek,
  totalShiftsMargin,
}: HookProps) {
  const shiftsPerPeriod: number = useMemo(
    () => round(shiftsPerWeek * periodLengthWeeks, 0),
    [shiftsPerWeek, periodLengthWeeks],
  );
  const marginShifts = useMemo(
    () => round(0.01 * totalShiftsMargin * shiftsPerPeriod, 0),
    [totalShiftsMargin, shiftsPerPeriod],
  );
  const marginShiftsMin = useMemo(
    () => round(0.01 * (100 - totalShiftsMargin) * shiftsPerPeriod, 0),
    [totalShiftsMargin, shiftsPerPeriod],
  );
  const marginShiftsMax = useMemo(
    () => round(0.01 * (100 + totalShiftsMargin) * shiftsPerPeriod, 0),
    [totalShiftsMargin, shiftsPerPeriod],
  );

  return {
    shiftsPerPeriod,
    marginShifts,
    marginShiftsMin,
    marginShiftsMax,
  };
}

export const Title = () => (
  <Typography variant="h4">Marginal för pass (%)</Typography>
);
export const Description = () => (
  <Typography>
    Detta fält styr vilken marginal optimeringsalgoritmen tillåts röra sig inom
    för antalet schemalagda pass.
  </Typography>
);

export const TotalShiftsMarginField = ({
  disabled,
  readOnly,
}: {
  disabled?: boolean;
  readOnly?: boolean;
}) => (
  <Field
    name="totalShiftsMargin"
    fullWidth
    size="small"
    type="number"
    InputProps={{
      readOnly,
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
    disabled={disabled}
    inputProps={{ step: 0.5 }}
  />
);
export const Example = ({
  periodLengthWeeks,
  shiftsPerWeek,
}: {
  periodLengthWeeks: number;
  shiftsPerWeek: number;
}) => {
  const totalShiftsMargin = useFormikState<number>("totalShiftsMargin")?.value;
  const { shiftsPerPeriod, marginShifts, marginShiftsMin, marginShiftsMax } =
    useTotalMarginShifts({
      periodLengthWeeks,
      shiftsPerWeek,
      totalShiftsMargin,
    });
  return (
    <ExampleBox>
      <Typography variant="caption">
        För en heltidare som arbetar {shiftsPerPeriod} pass per schemaperiod (om{" "}
        {periodLengthWeeks} veckor) kommer algoritmen att schemalägga mellan ±
        {totalShiftsMargin}% (±{marginShifts} pass), d.v.s. som minst{" "}
        {marginShiftsMin} pass och som mest {marginShiftsMax} pass.
      </Typography>
    </ExampleBox>
  );
};

import type { ChangeEvent, ReactNode } from "react";
import { useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { useFormikState } from "hooks";

import { BigSwitch as Switch } from "components/common/Switch";

import { LeftSideAndContent } from "./LeftSideAndContent";

type Props = {
  checked?: boolean;
  setChecked?: (b: boolean) => void;
  title: ReactNode;
  description?: ReactNode;
  readOnly?: boolean;
  disabled?: boolean;
  leftWidth?: number;
  labelOn?: string;
  labelOff?: string;
};
type FormikProps = Omit<Props, "checked" | "setChecked"> & { name: string };
type LeftProps = Pick<
  Props,
  | "checked"
  | "setChecked"
  | "disabled"
  | "readOnly"
  | "leftWidth"
  | "labelOn"
  | "labelOff"
>;

function Left({
  checked,
  setChecked,
  disabled,
  readOnly,
  leftWidth = 90,
  labelOn = "På",
  labelOff = "Av",
}: LeftProps) {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!readOnly && setChecked) {
        setChecked(e.target.checked);
      }
    },
    [readOnly, setChecked],
  );
  return (
    <Stack direction="row" alignItems="center" width={leftWidth}>
      <Switch checked={checked} onChange={onChange} disabled={disabled} />
      <Typography>{checked ? labelOn : labelOff}</Typography>
    </Stack>
  );
}

export function SwitchWithTitleAndDesc({
  title,
  description,
  ...props
}: Props) {
  return (
    <LeftSideAndContent Left={<Left {...props} />}>
      <Stack gap={0.5}>
        <Typography variant="h4">{title}</Typography>
        {typeof description === "string" ? (
          <Typography>{description}</Typography>
        ) : (
          description
        )}
      </Stack>
    </LeftSideAndContent>
  );
}

export function FormikSwitchWithTitleAndDesc({ name, ...props }: FormikProps) {
  const { value, setValue } = useFormikState<boolean>(name);

  return (
    <SwitchWithTitleAndDesc {...props} checked={value} setChecked={setValue} />
  );
}

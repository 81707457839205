import { useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import { LabeledHardSoftRadios } from "settings/common/LabeledHardSoftRadios";
import PaperWrapper from "settings/common/PaperWrapper";

import { TeamLabel } from "./TeamLabel";
import { Weights } from "./Weights";

type Props = { name: string; disabled?: boolean; readOnly?: boolean };

export function TeamDemandSetting({ name, disabled, readOnly }: Props) {
  const { value: allowUnderstaffing } = useFormikState<boolean>(
    `${name}.allowUnderstaffing`,
  );

  const getName = useCallback(
    (fieldName: string) => `${name}.${fieldName}`,
    [name],
  );

  return (
    <PaperWrapper>
      <Stack gap={2} direction="column" flexWrap={{ xs: "wrap", lg: "nowrap" }}>
        <Stack gap={2} direction="row">
          <TeamLabel
            flexGrow={1}
            name={getName("team")}
            minWidth={110}
            width={110}
          />

          <LabeledHardSoftRadios
            name={getName("allowUnderstaffing")}
            label="Tillåt underbemanning"
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <Typography variant="body1">
          Ställ in prioritet för minimering av:
        </Typography>
        <Weights
          name={name}
          disabled={disabled || !allowUnderstaffing}
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

import { useMemo, useState } from "react";

type Props = {
  nullValue: any;
  getFn: (o: any) => boolean;
  options: any[];
};

export default function useIdFromObjectList<IdType>({
  nullValue,
  getFn,
  options,
}: Props) {
  const [id, setId] = useState<IdType | typeof nullValue>(nullValue);
  const selected = useMemo(
    () => options.find((o) => getFn(o) === id) || nullValue,
    [nullValue, getFn, id, options],
  );

  return useMemo(() => ({ selected, id, setId }), [id, selected, setId]);
}

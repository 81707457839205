import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import { SuggestMeetingShiftsMutation as Mutation } from "./types";

const mutation = graphql`
  mutation SuggestMeetingShiftsMutation($input: SuggestMeetingShiftsInput!) {
    suggestMeetingShifts(input: $input) {
      ok
      shifts {
        id
        name
        start
        end
        meetingDay
        breakTime
        shiftParts {
          id
          start
          end
          partType
        }
        otherMeetings {
          id
          start
          end
        }
      }
    }
  }
`;

export const useSuggestMeetingShifts = () => useMutation<Mutation>(mutation);

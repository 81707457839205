import graphql from "babel-plugin-relay/macro";
import { BaseSettings } from "settings/common/BaseSetting";
import {
  defaultConvertDataToInitialValues,
  dummyUseAdditionalDataForTeamGroup,
  OptimizationSetting,
  useDefaultSubmitFunctionsOnlyTeamGroup,
} from "settings/common/optimizationSetting";
import * as yup from "yup";

import { resourceShiftsWeightChoices } from "components/setting/team_group/setting_boxes/constants";

import {
  SmoothResourcesSettingTeamGroup_fragment$data as TeamGroupData,
  SmoothResourcesSettingTeamGroup_fragment$key as TeamGroupKey,
} from "./__generated__/SmoothResourcesSettingTeamGroup_fragment.graphql";
import { SmoothResourcesForm } from "./form/SmoothResourcesForm";

const teamGroupFragment = graphql`
  fragment SmoothResourcesSettingTeamGroup_fragment on SettingNode {
    id
    constraintModules
    optimizeResourceDistributionWithinTeams
    optimizeResourceDistributionBetweenTeams
    optimizeResourceDistributionBetweenEmployees
    resourceShiftsWeight
  }
`;

const SETTING_NAME = "Resurspass";
const MODULE_NAME = "SmoothResources";
const SETTING_URL_ID = "resource-pool";

type SmoothResourcesFormValues = Pick<
  TeamGroupData,
  | "id"
  | "constraintModules"
  | "optimizeResourceDistributionBetweenEmployees"
  | "optimizeResourceDistributionBetweenTeams"
  | "optimizeResourceDistributionWithinTeams"
  | "resourceShiftsWeight"
>;

const validationSchema = yup.object().shape({
  id: yup.string(),
  optimizeResourceDistributionWithinTeams: yup.boolean().required(),
  optimizeResourceDistributionBetweenTeams: yup.boolean().required(),
  optimizeResourceDistributionBetweenEmployees: yup.boolean().required(),
  resourceShiftsWeight: yup
    .string()
    .oneOf(resourceShiftsWeightChoices)
    .required(),
  constraintModules: yup.array().of(yup.string()),
});

function renderComponent(
  initialValues: SmoothResourcesFormValues,
  onSubmit: (formValues: SmoothResourcesFormValues) => void,
) {
  return (
    <BaseSettings
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <SmoothResourcesForm />
    </BaseSettings>
  );
}

export const smoothResourcesSetting: OptimizationSetting<
  TeamGroupKey,
  undefined,
  undefined,
  SmoothResourcesFormValues
> = {
  name: SETTING_NAME,
  moduleName: MODULE_NAME,
  urlId: SETTING_URL_ID,
  teamGroupFragment,
  userSettingFragment: undefined,
  ruleGroups: undefined,
  convertTeamGroupDataToInitialValues: defaultConvertDataToInitialValues,
  convertUserDataToInitialValues: undefined,
  useAdditionalDataForTeamGroup: dummyUseAdditionalDataForTeamGroup,
  useSubmitFunctions: useDefaultSubmitFunctionsOnlyTeamGroup,
  renderComponent,
};

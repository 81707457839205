import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import type { IEnvironment } from "relay-runtime";

import {
  SetMasterScheduleInput,
  SetMasterScheduleMutation,
} from "./__generated__/SetMasterScheduleMutation.graphql";

const mutation = graphql`
  mutation SetMasterScheduleMutation($input: SetMasterScheduleInput!) {
    setMasterSchedule(input: $input) {
      ok
      schedule {
        id
        isMaster
      }
    }
  }
`;

export default async function SetMasterSchedule(
  environment: IEnvironment,
  input: SetMasterScheduleInput,
) {
  return commitMutation<SetMasterScheduleMutation>(environment, {
    mutation,
    variables: { input },
  }).then((response) => {
    if (!response.setMasterSchedule?.ok) {
      return Promise.reject("Could not set master schedule.");
    }
  });
}

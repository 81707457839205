export const Colors = {
  // Primary palette
  TURE: "#0B5671",
  ANNKATRIN: "#77ACA2",
  KERSTIN: "#FDD177",
  HASSAN: "#F79096",
  // Secondary palette
  HAV: "#4594B7", // Hav
  MANET: "#F5AC83", // Manet
  HJARTA: "#FD7777", // Hjarta
  ANEMON: "#A877AC", // Anemon
  TANG: "#77AC7C", // Tang
  LAND: "#F08E56", // Land
  SKROV: "#635147", // Skrov
  KIND: "#FFB0AB", // Kind
  SKUM: "#A99BCF", // Skum
  FISK: "#88D3A2", // Fisk
  KLIPPA: "#B0E3FF", // Klippa
  SNACKA: "#FFE299", // Snacka
  // Legacy namings
  PRIMARY: "#0B5671",
  BLUE: "#0B5671", // Ture
  BLUE120: "#09455A",
  BLUE80: "#487D8F",
  BLUE60: "#6E9AAA",
  BLUE40: "#9DBAC5",
  BLUE30: "#BBCED5",
  BLUE25: "#C6D6DB",
  BLUE20: "#CEDDE3",
  BLUE15: "#DBE6EA",
  BLUE10: "F3F7F8",
  GREEN: "#77ACA2", // Ann-Katrin
  GREEN80: "#93B8B1",
  GREEN60: "#ADCCC6",
  GREEN40: "#C8DDD9",
  YELLOW: "#FDD177", // Kerstin
  YELLOW80: "#EFD295",
  YELLOW60: "#FAE2AE",
  YELLOW40: "#FBEBC8",
  PINK: "#F79096", // Hassan
  PINK80: "#E9A5A9",
  PINK60: "#F3BBBE",
  PINK40: "#F7D2D3",
  SEA: "#4594B7", // Hav
  APRICOT: "#F5AC83", // Manet
  ROUGE: "#FD7777", // Hjarta
  PURPLE: "#A877AC", // Anemon
  MOSS: "#77AC7C", // Tang
  ORANGE: "#F08E56", // Land
  BROWN: "#635147", // Skrov
  SKIN: "#FFB0AB", // Kind
  LILAC: "#A99BCF", // Skum
  GRASS: "#B8D3A2", // Fisk
  LIGHTBLUE: "#B0E3FF", // Klippa
  LIGHTYELLOW: "#FFE299", // Snacka
  PAPER: "#F9F9F8",
  BLACK: "#263238",
  BLACK80: "#60686C",
  BLACK60: "#7E8589",
  BLACK40: "#A8ADAF",
  BLACK30: "#BFC2C3",
  BLACK25: "#C9CCCD",
  DISABLED: "#E9ECF0",
  DARKGRAY100: "#5A6268",
  DARKGRAY80: "#6C757D",
  DARKGRAY60: "#c2c0c0",
  GRAY: "#d4d4d4",
  FORMBORDERCOLOR: "#e5e6e7",
  BORDERCOLOR: "#cbcbcb",
  TEXTCOLOR: "#676a6c",
  MENUSELECTED: "#ECF2F4",
  SUBMENUSELECTED: "#DFE6E8",
  SUBMENU: "#F5F8F9",
  MENUHOVER: "#F5F5F5",
};

export const SecondaryPalette = [
  Colors.SNACKA,
  Colors.HJARTA,
  Colors.ANEMON,
  Colors.FISK,
  Colors.KIND,
  Colors.SKROV,
  Colors.SKUM,
  Colors.KLIPPA,
  Colors.HAV,
  Colors.TANG,
  Colors.MANET,
  Colors.LAND,
];

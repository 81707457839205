import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { Box, Stack, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import graphql from "babel-plugin-relay/macro";
import { Colors } from "styles/colors";

import { swedishWeekday, yformat } from "components/common/GraphAxisFormat";

import { ShiftProfileChart_fragment$key } from "./__generated__/ShiftProfileChart_fragment.graphql";

const fragment = graphql`
  fragment ShiftProfileChart_fragment on ShiftProfileViewer {
    profile {
      dateTime
      minStaffed
    }
  }
`;

type Event = { x: string; y: number };
type Serie = { id: string; data: Event[] };

type Props = {
  fragmentRef: ShiftProfileChart_fragment$key;
};

export function ShiftProfileChart({ fragmentRef }: Props) {
  const { profile } = useFragment<ShiftProfileChart_fragment$key>(
    fragment,
    fragmentRef,
  );

  // Convert data to better format
  const [groupedData, max, min] = useMemo(() => {
    const allEvents = profile.map((event) => ({
      x: event.dateTime,
      y: event.minStaffed,
    }));
    const groupedData: Serie = {
      id: "profile",
      data: allEvents,
    };
    const timestamps = allEvents.map((e) => Date.parse(e.x));
    const max = Math.max(...timestamps);
    const min = Math.min(...timestamps);
    return [groupedData, max, min];
  }, [profile]);

  // Get constants
  const defaultMax = Date.parse("2021-07-19T00:00:00");
  const defaultMin = Date.parse("2021-07-12T00:00:00");
  const axisMaxTimestamp = Math.max(defaultMax, max);
  const axisMinTimestamp = Math.min(defaultMin, min);
  const axisMaxUTC = new Date(axisMaxTimestamp).toLocaleString("sv");
  const axisMinUTC = new Date(axisMinTimestamp).toLocaleString("sv");
  const axisMax = [axisMaxUTC.slice(0, 10), "T", axisMaxUTC.slice(11, 19)].join(
    "",
  );
  const axisMin = [axisMinUTC.slice(0, 10), "T", axisMinUTC.slice(11, 19)].join(
    "",
  );
  const selectedMaxY =
    Math.max(0, ...groupedData.data.map((event: Event) => event.y)) || 100;
  const yGrids = Array.from(Array(selectedMaxY + 1).keys());

  // Return only text if profile data is empty
  if (!profile.length) {
    return (
      <Stack height={200} alignItems="center" justifyContent="center">
        <Typography>Ingen data att visa.</Typography>
      </Stack>
    );
  }

  return (
    <Box sx={{ height: Math.min(180, 50 + selectedMaxY * 30) }}>
      <ResponsiveLine
        data={[groupedData]}
        margin={{ top: 20, right: 25, bottom: 23, left: 112 }}
        xScale={{
          type: "time",
          format: "%Y-%m-%dT%H:%M:%S",
          useUTC: false,
          min: axisMin,
          max: axisMax,
        }}
        xFormat="time:%H:%M:%S"
        yScale={{
          min: 0,
          type: "linear",
          stacked: true,
        }}
        gridYValues={yGrids}
        curve="stepAfter"
        useMesh={true}
        lineWidth={1}
        enableArea={true}
        areaOpacity={0.5}
        areaBlendMode="normal"
        enablePoints={false}
        colors={[Colors.BLUE20]}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Antal pass",
          legendOffset: -40,
          legendPosition: "middle",
          format: yformat,
          tickValues: yGrids,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          legendOffset: 36,
          legendPosition: "middle",
          format: swedishWeekday,
          tickValues: "every 24 hours",
        }}
        gridXValues="every 24 hours"
        theme={{
          text: { fontFamily: "Nunito" },
          grid: {
            line: {
              stroke: "#DEE6EA",
              strokeWidth: 0.8,
            },
          },
        }}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          return (
            <Stack
              sx={{
                bgcolor: "white",
                py: 1,
                px: 1.5,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "divider",
              }}
            >
              <Typography>{slice.points[0].data.xFormatted}</Typography>
              {slice.points.map((point) => (
                <Typography key={point.id} sx={{ py: 0.5 }}>
                  {point.data.yFormatted} pass
                </Typography>
              ))}
            </Stack>
          );
        }}
      />
    </Box>
  );
}

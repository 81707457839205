import { useCallback } from "react";
import { Close } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";

type Props = {
  query: string;
  setQuery: (query: string) => void;
};

export function SearchField({ query, setQuery }: Props) {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value),
    [setQuery],
  );

  const clearSearch = useCallback(() => setQuery(""), [setQuery]);

  return (
    <TextField
      value={query}
      onChange={onChange}
      fullWidth
      size="small"
      placeholder="Sök"
      InputProps={{
        startAdornment: <SearchIcon sx={{ color: "text.secondary", mr: 1 }} />,
        endAdornment: !query ? undefined : (
          <IconButton onClick={clearSearch} disableRipple sx={{ p: 0 }}>
            <Close fontSize="small" sx={{ color: "text.secondary" }} />
          </IconButton>
        ),
        sx: { borderRadius: 2 },
      }}
    />
  );
}

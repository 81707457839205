import { Suspense, useCallback, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { Container } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useChangeTitle, useCurrentTeamGroup } from "hooks";
import type { IEnvironment } from "relay-runtime";

import { PageWrapper } from "components/layout";
import { MeetingsPlaceholder as Placeholder } from "components/loading";
import {
  MeetingGraph,
  MeetingHeader,
  MeetingList,
  MeetingsScaffold,
} from "components/meetings";

import type { MeetingsQuery as Query } from "./types";

type QueryRef = PreloadedQuery<Query>;
type ContentProps = { queryRef: QueryRef };

const query = graphql`
  query MeetingsQuery($teamGroupId: ID!) {
    meetings(teamGroupId: $teamGroupId) {
      ...MeetingList_meetings
    }
    meetingShifts(teamGroupId: $teamGroupId) {
      ...MeetingGraph_shifts
    }
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      ...MeetingGraph_setting
    }
  }
`;

export function Meetings() {
  const queryRef = useMeetings();

  useChangeTitle("Möten");

  return (
    <Container>
      <PageWrapper header={<MeetingHeader />}>
        <Suspense fallback={<Placeholder />}>
          {!!queryRef && <Content queryRef={queryRef} />}
        </Suspense>
      </PageWrapper>
    </Container>
  );
}

function Content({ queryRef }: ContentProps) {
  const data = useContent(queryRef);
  const { id: teamGroupId } = useCurrentTeamGroup();
  const env = useRelayEnvironment();

  const refetch = useCallback(
    () => refresh(env, teamGroupId),
    [env, teamGroupId],
  );

  return (
    <MeetingsScaffold>
      {!!data.meetings && (
        <MeetingList fragmentRef={data.meetings} refresh={refetch} />
      )}
      {!!data.settingForTeamGroup && !!data.meetingShifts && (
        <MeetingGraph
          fragmentRef={data.meetingShifts}
          settingFragmentRef={data.settingForTeamGroup}
        />
      )}
    </MeetingsScaffold>
  );
}

async function refresh(environment: IEnvironment, teamGroupId: string) {
  await fetchQuery(environment, query, { teamGroupId }).toPromise();
}

function useContent(queryRef: QueryRef) {
  return usePreloadedQuery<Query>(query, queryRef);
}

function useMeetings() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();

  useEffect(() => {
    if (!teamGroup?.id) return;
    loadQuery({ teamGroupId: teamGroup?.id }, { fetchPolicy: "network-only" });
  }, [teamGroup?.id, loadQuery]);

  return queryRef;
}

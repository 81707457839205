import { Stack, Typography } from "@mui/material";
import type { Point, SliceTooltipProps } from "@nivo/line";

type Props = Pick<SliceTooltipProps, "slice"> & {
  getPointName: (point: Point) => string;
};

export function Tooltip({ slice, getPointName }: Props) {
  const dateString = new Date(
    slice.points[0].data.xFormatted,
  ).toLocaleDateString("sv-SE", { weekday: "long" });
  const capitalizedWeekday = dateString[0].toUpperCase() + dateString.slice(1);
  return (
    <Stack
      sx={{
        bgcolor: "white",
        py: 1,
        px: 1.5,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "divider",
      }}
    >
      <Typography>
        {capitalizedWeekday} {slice.points[0].data.xFormatted}
      </Typography>
      {slice.points.map((point) => {
        const title = getPointName(point);
        const color = point.serieColor;
        const y = point.data.yFormatted;
        return (
          <Typography key={point.id} sx={{ color, py: "3px", px: 0 }}>
            <strong>{title}</strong> - {y}{" "}
            {Number(y) === 1 ? "person" : "personer"}
          </Typography>
        );
      })}
    </Stack>
  );
}

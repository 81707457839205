import { InputAdornment, Stack, Typography } from "@mui/material";

import Field from "components/forms/CustomField";

type Props = { readOnly?: boolean };

export function PeriodLengthWeeksField({ readOnly }: Props) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Schemaperiodens längd</Typography>
      <Typography>
        Detta fält styr hur lång en schemaperiod ska vara.
      </Typography>
      <Field
        name="periodLengthWeeks"
        fullWidth
        size="small"
        type="number"
        sx={{ maxWidth: 220 }}
        InputProps={{
          readOnly,
          endAdornment: <InputAdornment position="end">veckor</InputAdornment>,
        }}
      />
    </Stack>
  );
}

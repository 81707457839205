import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "libs/commitMutation";
import { Environment } from "relay-runtime";

import { CreateActivityInput } from "components/schedule/activities/mutations/__generated__/CreateActivityMutation.graphql";
import {
  checkHasChanged,
  checkIsPopulated,
  checkStartEnd,
} from "components/schedule/activities/validations/BasicValidationChecks";

import { ValidateCreateActivityMutation } from "./__generated__/ValidateCreateActivityMutation.graphql";

const validateCreateMutation = graphql`
  mutation ValidateCreateActivityMutation(
    $input: ValidateCreateActivityInput!
  ) {
    validateCreateActivity(input: $input) {
      failedToFetch
      results {
        ok
        errors {
          module
          message
          activity {
            id
            activityType
            start
            end
          }
          user {
            name
          }
        }
      }
    }
  }
`;

const commitCreateValidation = async (
  environment: Environment,
  values: CreateActivityInput,
  scheduleId?: string,
) => {
  // Validate create mutation
  const res = await commitMutation<ValidateCreateActivityMutation>(
    environment,
    {
      mutation: validateCreateMutation,
      variables: {
        input: {
          ...(values as CreateActivityInput),
          scheduleId: scheduleId || "",
        },
      },
    },
  );
  const { results = [], failedToFetch } = res?.validateCreateActivity ?? {};
  return { results, failedToFetch };
};

type Props = {
  values: any;
  lastValidatedValues: any;
  setValidationState: any;
  validationState: any;
  scheduleId: string;
  environment: any;
};

export function validateCreate(props: Props) {
  // Validate activity with constraint module rules. This validation method
  // is performed in addition to the field-level validation.
  // Note: We don't use Formik's errors for this, but a custom error state.

  // Basic validation
  const invalidStartEnd = checkStartEnd(props.values);
  if (invalidStartEnd) {
    return { end: "Sluttid inträffar före starttid" };
  }
  const isPopulated = checkIsPopulated(props.values);
  if (!isPopulated) {
    return;
  }
  const hasChanged = checkHasChanged(props.lastValidatedValues, props.values);
  if (!hasChanged) {
    return;
  }

  // Perform server validation
  props.setValidationState({
    loading: true,
    result: props.validationState?.result,
  });
  props.lastValidatedValues.current = props.values;
  commitCreateValidation(props.environment, props.values, props.scheduleId)
    .then(({ results, failedToFetch }) =>
      props.setValidationState({
        loading: false,
        result: results,
        failedToFetch,
      }),
    )
    .catch((e) => {
      console.error(e);
      props.setValidationState({
        loading: false,
        result: {
          errors: [
            {
              message: "Ett okänt serverfel har uppstått",
              user: { name: "Systemfel" },
            },
          ],
        },
      });
    });
}

import { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import { DialogTitle } from "components/common/Dialog";

type Props = {
  show: boolean;
  onHide: () => void;
  deleteTitle: string;
  deleteMessage: string | React.ReactNode;
  buttonText: string;
  onDeleteClick: () => void;
  deleteDisabled?: boolean;
};

export default function DeleteModal({
  show,
  onHide,
  deleteTitle,
  deleteMessage,
  buttonText,
  onDeleteClick,
  deleteDisabled = false,
}: Props) {
  return (
    <Dialog open={show} onClose={onHide} fullWidth maxWidth={"xs"}>
      <DialogTitle title={deleteTitle} onClose={onHide} />
      <Divider />
      <DialogContent style={{ margin: "5px 0px 10px 0px" }}>
        {typeof deleteMessage === "string" ? (
          <Typography>{deleteMessage}</Typography>
        ) : (
          deleteMessage
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button
            variant="delete"
            onClick={() => onDeleteClick()}
            disabled={deleteDisabled}
          >
            {buttonText}
          </Button>
          <Button variant="cancel" onClick={() => onHide()}>
            Avbryt
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export function useDeleteModal<T extends { id: string }>(
  onDelete: (id: string) => Promise<void>,
  onError: (e: Error) => void,
) {
  const [toDelete, setToDelete] = useState<T>();
  const onHide = useCallback(() => setToDelete(undefined), []);

  const confirmDelete = useCallback(async () => {
    if (!toDelete?.id) return;
    return onDelete(toDelete.id).then(onHide).catch(onError);
  }, [toDelete, onDelete, onHide, onError]);

  return {
    toDelete,
    setToDelete,
    onHide,
    confirmDelete,
  };
}

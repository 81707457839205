import { InputAdornment, Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";

import CustomField from "components/forms/CustomField";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function Cost({ disabled: moduleDisabled, readOnly }: Props) {
  return (
    <PaperWrapper>
      <Stack gap={2}>
        <Stack gap={1}>
          <Typography variant="h4">Timkostnad</Typography>
          <Typography>
            Detta fält styr schablonvärdet för en arbetad timme. Fältet påverkar
            inte hur schemat optimeras, utan används endast för att beräkna
            kostnaden för ett färdigt schema. Denna kostnad kan sättas på
            regelgrupps- och enhetsnivå.
          </Typography>
        </Stack>
        <CustomField
          name="hourlyCost"
          disabled={moduleDisabled}
          fullWidth
          size="small"
          type="number"
          InputProps={{
            readOnly,
            endAdornment: (
              <InputAdornment position="end">kr/timme</InputAdornment>
            ),
            style: { maxWidth: "150px" },
          }}
          inputProps={{ step: 0.5 }}
        />
      </Stack>
    </PaperWrapper>
  );
}

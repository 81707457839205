/**
 * @generated SignedSource<<0c0a6a4f074de2e9cef69795491c9fee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OptimizationSettingTeamGroup_fragment$data = {
  readonly id: string;
  readonly mipgapLimit: number;
  readonly " $fragmentType": "OptimizationSettingTeamGroup_fragment";
};
export type OptimizationSettingTeamGroup_fragment$key = {
  readonly " $data"?: OptimizationSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OptimizationSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OptimizationSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mipgapLimit",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "6d44d9d2ee55b680ae5c48c5dc21cc63";

export default node;

import type { CSSObject } from "@mui/material";
import { InputAdornment } from "@mui/material";

import CustomField from "components/forms/CustomField";

type Props = {
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  sx?: CSSObject;
};

export default function PercentInputField({
  name,
  disabled,
  readOnly,
  sx = {},
}: Props) {
  return (
    <CustomField
      name={name}
      type="number"
      disabled={disabled}
      max={100}
      min={0}
      inputProps={{ step: 1 }}
      InputProps={{
        readOnly,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      sx={sx}
    />
  );
}

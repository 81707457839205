import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

import { InfoButtonAndModal, InfoModalProvider } from "components/layout";

import { CommonHeader } from "./Common";

const Content = () => (
  <Stack gap={4}>
    <CommonHeader />

    <Stack gap={1}>
      <Typography variant="h3">Skapa flera möten</Typography>
      <Typography>
        Du kan skapa flera olika möten, med olika sammansättningar av personer.
        Betänk dock att en person inte kan vara på två möten samtidigt! Vi
        kommer hjälpa dig att kontrollera denna typ av konflikter i formuläret
        för att skapa eller redigera ett möte.
      </Typography>
    </Stack>

    <Stack gap={1}>
      <Typography variant="h3">
        Mötespass är de passtyper som används vid möten
      </Typography>
      <Typography>
        För varje möte du skapar, så kommer det också skapas en uppsättning
        mötespass. Mötespassen är de särskilda passtyper som medarbetare får
        lova att gå på i samband med mötet. Du ser en överblick över samtliga
        mötespass för alla dina skapade möten under rubriken "Mötespass".
      </Typography>
    </Stack>

    <Stack gap={1}>
      <Typography variant="h3">
        Möten planeras in relativt periodens veckor
      </Typography>
      <Typography>
        När du skapar ett nytt möte så behöver du ange vilka dagar och vilka
        veckor som mötet ska infalla. De veckor du kan välja bland är lika med
        periodens längd, som du kan ändra på i{" "}
        <Link to={"/group-settings#period"}>
          Enhetsinställningar/Schemaperioder
        </Link>
        . Tänk på att du kan behöva justera dina mötens dagar och veckor om du
        byter periodlängd!
      </Typography>
    </Stack>
  </Stack>
);
export function ListInfo() {
  return (
    <InfoModalProvider>
      <InfoButtonAndModal buttonLabel="Hjälp" title="Möten">
        <Content />
      </InfoButtonAndModal>
    </InfoModalProvider>
  );
}

import { useCallback } from "react";
import { Box, CircularProgress } from "@mui/material";
import type { Point } from "@nivo/line";
import { ResponsiveLine } from "@nivo/line";
import { DateTime } from "luxon";

import { swedishWeekday, yformat } from "components/common/GraphAxisFormat";

import { useDemandProfileChartData, useDemandProfileData } from "./hooks";
import { Tooltip } from "./Tooltip";
import { resolveName } from "./utils";

type Props = Pick<
  ReturnType<typeof useDemandProfileData>,
  "filteredViews" | "items"
> & {
  allTeams: boolean;
  allCompetences: boolean;
  min: Date;
  max: Date;
};

export function DemandProfileRenderer({
  filteredViews,
  items,
  allTeams,
  allCompetences,
  min,
  max,
}: Props) {
  const { legendData, maxLegend, yGrids, chartColors } =
    useDemandProfileChartData({
      items,
      filteredViews,
      allTeams,
      allCompetences,
    });

  const getPointName = useCallback(
    (point: Point) => {
      const [t, c] = (point.serieId as string).split(".");
      const view = filteredViews.find(
        (v) => v?.team?.id === t && v?.competence?.id === c,
      );
      if (!view) return "";
      return resolveName(view);
    },
    [filteredViews],
  );

  if (items.every((i) => i.data.length === 0)) {
    // Fallback as empty items[*].data crashes the ResponsiveLine component
    return (
      <Box sx={{ height: 220 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: 220 }}>
      <ResponsiveLine
        data={items}
        margin={{ top: 50, right: 40, bottom: 40, left: 80 }}
        xScale={{
          type: "time",
          format: "%Y-%m-%dT%H:%M:%S",
          useUTC: false,
          min,
          max,
        }}
        yScale={{
          min: 0,
          type: "linear",
          stacked: allCompetences || allTeams ? true : false,
        }}
        xFormat="time:%Y-%m-%d %H:%M"
        gridYValues={yGrids}
        curve="stepAfter"
        useMesh={true}
        lineWidth={1}
        enableArea={true}
        areaOpacity={allTeams ? 0.85 : 0.7}
        areaBlendMode="normal"
        enablePoints={false}
        colors={chartColors}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Antal personer",
          legendOffset: -40,
          legendPosition: "middle",
          format: yformat,
          tickValues: yGrids,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          ticksPosition: "before",
          legendOffset: 36,
          legendPosition: "middle",
          format: (v) =>
            `${swedishWeekday(v)} ${DateTime.fromJSDate(v).toFormat("DD")}`,
          tickValues: "every week",
        }}
        legends={[
          {
            data: legendData,
            anchor: "top",
            direction: "row",
            translateX: 0,
            translateY: -35,
            itemsSpacing: Math.pow(maxLegend, 1.3),
            itemWidth: 130,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
            justify: false,
            symbolSize: 12,
            symbolBorderColor: "rgba(0, 0, 0, .5)",
          },
        ]}
        theme={{
          text: { fontFamily: "Nunito" },
          grid: {
            line: {
              stroke: "#DEE6EA",
              strokeWidth: 0.8,
            },
          },
        }}
        enableSlices="x"
        sliceTooltip={({ slice }) => (
          <Tooltip slice={slice} getPointName={getPointName} />
        )}
      />
    </Box>
  );
}

/**
 * @generated SignedSource<<141ec320507ce615ce48c5d3ee568455>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamsTable_fragment$data = ReadonlyArray<{
  readonly archivedAt: string | null;
  readonly color: string;
  readonly group: {
    readonly id: string;
    readonly name: string;
  };
  readonly id: string;
  readonly isActive: boolean;
  readonly name: string;
  readonly " $fragmentType": "TeamsTable_fragment";
}>;
export type TeamsTable_fragment$key = ReadonlyArray<{
  readonly " $data"?: TeamsTable_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamsTable_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TeamsTable_fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archivedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamGroupNode",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "TeamNode",
  "abstractKey": null
};
})();

(node as any).hash = "cb2f7739e5579a4684593384526ab5c2";

export default node;

import type { DemandWeeksInput } from "../types";

import type {
  Demand,
  DemandCompetence,
  DemandForm_fragment$data as FormDemandData,
} from "./types";

export const ALL_COMPETENCES = "";
export const ALL_TEAMS = "";
export const DEMAND_ONLY = "";

// Get the selected form group, given a selected team and competence
export const getSelectedDemandGroup = (
  formDemands: FormDemandData,
  teamId: string,
  competenceId: string | null,
): DemandWeeksInput[] => {
  const teamFilter = (d: Demand) => d?.team?.id === teamId;
  const competenceFilter = (d: DemandCompetence) =>
    d?.competence?.id === competenceId;
  const filter = (d: DemandCompetence) => teamFilter(d) && competenceFilter(d);

  if (!competenceId) {
    return (formDemands.demands.find(teamFilter)?.demands || []).slice();
  }

  return (formDemands.demandCompetences.find(filter)?.demands || []).slice();
};

import useFormikState from "hooks/useFormikState";

import GenericSelect from "components/common/selectors/GenericSelect";
import type { MemberTeamGroupType } from "components/organisation/types";

type Props = {
  name: string;
  label?: string;
  options: MemberTeamGroupType[];
};

export default function TeamGroupSelect({
  name,
  label = "Enhet",
  options,
}: Props) {
  const { value, setValue } = useFormikState(name);
  const selected = options.find((x) => x.id === value) ?? null;

  const handleChange = (_event: any, newValue: MemberTeamGroupType | null) => {
    setValue(newValue?.id ?? null);
  };

  return (
    <GenericSelect<MemberTeamGroupType>
      value={selected ?? undefined}
      onChange={handleChange}
      options={options}
      getOptionLabel={(o) => o.name}
      label={label}
      placeholder="Välj enhet..."
    />
  );
}

import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import {
  CheckCircle as CheckCirleIcon,
  DoDisturb as DoDisturbIcon,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { DateTime } from "luxon";
import type { MRT_ColumnDef } from "material-react-table";

import ColorDot from "components/common/ColorDot";
import DeletePopover from "components/common/DeletePopover";
import Table from "components/common/MRTable";

import {
  ActivateButton,
  ArchiveButton,
  DeleteButton,
  EditButton,
} from "../common/AdminTableIconButtons";

import type { Team, TeamsTable_fragment$key as Key } from "./types";

const TABLE_CONFIG_KEY = "admin-teams-table-config";

const fragment = graphql`
  fragment TeamsTable_fragment on TeamNode @relay(plural: true) {
    id
    name
    color
    isActive
    archivedAt
    group {
      id
      name
    }
  }
`;

type ColumnType = NonNullable<Team>;

type Props = {
  fragmentRef: Key;
  onEdit: (id: string) => void;
  onArchive: (id: string) => void;
  onActivate: (id: string) => void;
  onDelete: (team: Team) => void;
};

type ActionsProps = {
  item: Team;
  onEdit: () => void;
  onArchive: () => void;
  onActivate: () => void;
  onDelete: () => void;
};

function Actions({
  item,
  onEdit,
  onArchive,
  onActivate,
  onDelete,
}: ActionsProps) {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <EditButton onClick={() => onEdit()} />
      {item?.isActive ? (
        <DeletePopover
          deleteMessage={`Är du säker på att du vill inaktivera avdelning ${item?.name}?`}
          buttonText="Inaktivera"
          onDeleteClick={() => onArchive()}
        >
          <ArchiveButton />
        </DeletePopover>
      ) : (
        <ActivateButton onClick={() => onActivate()} />
      )}
      <DeleteButton onClick={() => onDelete()} />
    </Stack>
  );
}

export default function TeamsTable({
  fragmentRef,
  onEdit,
  onArchive,
  onActivate,
  onDelete,
}: Props) {
  const items = useFragment<Key>(fragment, fragmentRef);
  const columns = useMemo<MRT_ColumnDef<ColumnType>[]>(
    () => [
      { accessorKey: "id", header: "ID" },
      {
        accessorKey: "isActive",
        header: "Aktiv",
        accessorFn: (t) =>
          t.isActive ? (
            <CheckCirleIcon color="primary" sx={{ p: "2px" }} />
          ) : (
            <DoDisturbIcon color="disabled" sx={{ p: "2px" }} />
          ),
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} aktiva`,
        Footer: ({ table }) => {
          const activeCount =
            table
              .getFilteredRowModel()
              .flatRows.filter((r) => r.original.isActive).length || 0;
          return <Stack>{activeCount} aktiva</Stack>;
        },
      },
      {
        accessorKey: "archivedAt",
        header: "Tid för arkivering",
        accessorFn: (c) =>
          c?.archivedAt ? DateTime.fromISO(c.archivedAt).toFormat("F") : "-",
      },
      {
        accessorKey: "name",
        header: "Namn",
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} enheter`,
        Footer: ({ table }) => {
          const teamCount = table.getFilteredRowModel().flatRows.length || 0;
          return <Stack>{teamCount} avdelningar</Stack>;
        },
      },
      {
        accessorKey: "color",
        header: "Färg",
        accessorFn: (t) => <ColorDot color={t.color} />,
      },
      {
        accessorKey: "group",
        header: "Tillhör enhet",
        accessorFn: (t) => t.group.name,
      },
      {
        header: "Åtgärder",
        Cell: ({ row }) => {
          const t = row.original;
          return (
            <Actions
              item={t}
              onEdit={() => t?.id && onEdit(t.id)}
              onDelete={() => onDelete(t)}
              onActivate={() => t?.id && onActivate(t.id)}
              onArchive={() => t?.id && onArchive(t.id)}
            />
          );
        },
      },
    ],
    [onEdit, onDelete, onActivate, onArchive],
  );

  return (
    <Table
      tableConfigKey={TABLE_CONFIG_KEY}
      columns={columns as any[]}
      data={items as any[]}
    />
  );
}

/**
 * @generated SignedSource<<0bd708b059438b9566246562dea022cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiMeetingShiftPartPartTypeChoices = "B" | "J" | "M" | "Z" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MeetingGraph_shifts$data = ReadonlyArray<{
  readonly breakTime: number;
  readonly end: string;
  readonly id: string;
  readonly meetingDay: number;
  readonly meetings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly dayMatrix: ReadonlyArray<ReadonlyArray<number>>;
        readonly end: string;
        readonly start: string;
      } | null;
    } | null>;
  };
  readonly name: string;
  readonly shiftParts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly end: string;
        readonly partType: ApiMeetingShiftPartPartTypeChoices;
        readonly start: string;
      } | null;
    } | null>;
  };
  readonly start: string;
  readonly " $fragmentType": "MeetingGraph_shifts";
}>;
export type MeetingGraph_shifts$key = ReadonlyArray<{
  readonly " $data"?: MeetingGraph_shifts$data;
  readonly " $fragmentSpreads": FragmentRefs<"MeetingGraph_shifts">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MeetingGraph_shifts",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "breakTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MeetingShiftPartNodeConnection",
      "kind": "LinkedField",
      "name": "shiftParts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MeetingShiftPartNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MeetingShiftPartNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MeetingNodeConnection",
      "kind": "LinkedField",
      "name": "meetings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MeetingNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MeetingNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dayMatrix",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MeetingShiftNode",
  "abstractKey": null
};
})();

(node as any).hash = "9418458ead03d225111e9ba3094d1f75";

export default node;

/**
 * @generated SignedSource<<7c062994af8bd4993ab6b5c9a2e30741>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CostSettingTeamGroup_fragment$data = {
  readonly hourlyCost: number;
  readonly id: string;
  readonly " $fragmentType": "CostSettingTeamGroup_fragment";
};
export type CostSettingTeamGroup_fragment$key = {
  readonly " $data"?: CostSettingTeamGroup_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CostSettingTeamGroup_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CostSettingTeamGroup_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hourlyCost",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "57a9666709e92add3ed79ca938db1134";

export default node;

import { useMemo } from "react";
import { useAxiosGet } from "hooks";

import type { ActivityGanttTimelineQuery$data as ScheduleData } from "components/schedule/types";

export const useGetSchedule = (id: string) => {
  const [{ data }] = useAxiosGet<ScheduleData>(
    "/schedule",
    useMemo(() => ({ params: { id } }), [id]),
  );
  return data;
};

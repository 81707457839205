import { Stack } from "@mui/material";
import { useFormikState } from "hooks";

import {
  HeaderRadios,
  HeaderSwitch,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Worktime2 } from "components/setting/common/forms";

import { resourceTimeSetting } from "../ResourceTimeSetting";

type FormProps = { readOnly?: boolean };

function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      type="onlyHard"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}
function Switch({ readOnly }: { readOnly?: boolean }) {
  return <HeaderSwitch name="ResourceTime" readOnly={readOnly} />;
}

export function Worktime2OldForm({ readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(resourceTimeSetting.moduleName);

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={<Switch readOnly={readOnly} />}
        />
        <Worktime2 who=" för enheten" readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

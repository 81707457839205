import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import {
  Worktime1,
  Worktime1Info as Info,
} from "components/setting/common/forms";

type TitleProps = { name: string };
type FormProps = TitleProps & {
  readOnly?: boolean;
};

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande ansvarstid för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}
function Radios({
  readOnly,
  disabled,
}: {
  readOnly?: boolean;
  disabled?: boolean;
}) {
  return (
    <HeaderRadios
      type="onlyHard"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}
function Switch({ readOnly }: { readOnly?: boolean }) {
  return <HeaderSwitch name="AdminTime" readOnly={readOnly} />;
}

export function Worktime1Form({ name, readOnly }: FormProps) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes("AdminTime");

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
          switchComponent={<Switch readOnly={readOnly} />}
          titleComponent={<Title name={name} />}
        />
        <Worktime1 who={` för ${name}`} readOnly={readOnly} />
      </Stack>
    </SettingBox>
  );
}

import { Stack, Typography } from "@mui/material";
import PaperWrapper from "settings/common/PaperWrapper";
import { WeightAndHardSoftFields } from "settings/common/TeamGroupOnlyForms";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import { MinDailyRestHours } from "../../../components/setting/common/forms/Advanced/RestBetweenShifts/MinDailyRestHours";
import { restBetweenShiftsSetting } from "../RestBetweenShiftsSetting";

type Props = {
  readOnly?: boolean;
};

export function RestBetweenShifts({ readOnly }: Props) {
  const disabled = !useIsConstraintModuleEnabled(
    restBetweenShiftsSetting.moduleName,
  );
  return (
    <PaperWrapper>
      <Stack gap={4}>
        <Stack gap={1}>
          <Typography variant="h4">Vilans längd (timmar)</Typography>
          <Typography>
            Detta fält styr hur lång vilan mellan pass minst ska vara, uttryckt
            i antal timmar.
          </Typography>
          <MinDailyRestHours disabled={disabled} readOnly={readOnly} />
        </Stack>
        <WeightAndHardSoftFields
          moduleName={restBetweenShiftsSetting.moduleName}
          softFieldName="minDailyRestSoft"
          weightFieldName="minDailyRestWeight"
          readOnly={readOnly}
        />
      </Stack>
    </PaperWrapper>
  );
}

import type { ComponentProps, PropsWithChildren } from "react";
import { useMemo } from "react";
import useFormikState from "hooks/useFormikState";

import { FormikDayCard } from "components/common/DayCard";

import type { DemandDaysInput } from "../types";

type FormikDayCardProps = ComponentProps<typeof FormikDayCard>;

type Props = Omit<
  FormikDayCardProps,
  "addLabel" | "countLabel" | "onAddRow"
> & {
  name: string;
  onAdd: FormikDayCardProps["onAddRow"];
};

const useCountLabel = (name: string) => {
  const { value: demand } = useFormikState<DemandDaysInput>(name);
  return useMemo(() => {
    const count = (demand?.demands || []).length;
    return `${count} ${count === 1 ? "behovsgrupp" : "behovsgrupper"}`;
  }, [demand?.demands]);
};

export function DemandDayCard({
  name,
  onAdd,
  ...props
}: PropsWithChildren<Props>) {
  const countLabel = useCountLabel(name);
  return (
    <FormikDayCard
      {...props}
      name={`${name}.days`}
      countLabel={countLabel}
      addLabel="Lägg till spann i behovsgrafen"
      onAddRow={onAdd}
    />
  );
}

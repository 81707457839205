import type { Day } from "utils/day";

import { CreateShiftsInput as FormValues } from "components/shifts/types";

export function getDaysLeft(days: ReadonlyArray<Day>, formValues: FormValues) {
  const selectedDays = formValues.shiftGroups.reduce(
    (acc, curr) => acc.concat(curr.days),
    [] as number[],
  );
  return days.map((_, i) => i).filter((i) => !selectedDays.includes(i));
}

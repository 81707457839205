/**
 * @generated SignedSource<<7d5e154e66c9e8b6bc717f6d011efa1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingExceptionsUser_fragment$data = ReadonlyArray<{
  readonly fullName: string;
  readonly id: string;
  readonly userSetting: {
    readonly id: string;
    readonly settingModules: ReadonlyArray<string>;
  } | null;
  readonly " $fragmentType": "SettingExceptionsUser_fragment";
}>;
export type SettingExceptionsUser_fragment$key = ReadonlyArray<{
  readonly " $data"?: SettingExceptionsUser_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingExceptionsUser_fragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SettingExceptionsUser_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSettingNode",
      "kind": "LinkedField",
      "name": "userSetting",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settingModules",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};
})();

(node as any).hash = "60fb405b8b3276d009fb714a95af4a6b";

export default node;

import { DateTime } from "luxon";

import { BlockedTimeSlotType } from "components/blocked_time_slot/types";

import { BlockedTimeSlot } from "./types";

export function getStartAndEndTimeForTimeslot(timeslot: BlockedTimeSlot) {
  // Fixed shift
  if (
    timeslot.timeslotType === BlockedTimeSlotType.FIXED_SHIFT &&
    timeslot.shift != null &&
    timeslot.shiftDate != null
  ) {
    const startTime = DateTime.fromISO(
      `${timeslot.shiftDate}T${timeslot.shift.start}`,
    );
    let endTime = DateTime.fromISO(
      `${timeslot.shiftDate}T${timeslot.shift.end}`,
    );
    if (endTime < startTime) {
      endTime = endTime.plus({ days: 1 });
    }
    return { startTime: startTime.toMillis(), endTime: endTime.toMillis() };
  }

  const startTime = Date.parse(timeslot?.start || "");
  const endTime = Date.parse(timeslot?.end || "");
  return { startTime, endTime };
}

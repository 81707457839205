import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { modifyUserSettingModulesMutation as Mutation } from "./__generated__/modifyUserSettingModulesMutation.graphql";

const mutation = graphql`
  mutation modifyUserSettingModulesMutation($input: UpdateUserSettingInput!) {
    updateUserSetting(input: $input) {
      ok
      userSetting {
        settingModules
      }
    }
  }
`;

export const useModifyUserSettingModulesMutation = () =>
  useMutation<Mutation>(mutation);

import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useRequireMe } from "hooks";

import { PageHeader } from "components/layout";

import { ListInfo } from "./info";

export function MeetingHeader() {
  const { evaluatePermission } = useRequireMe();

  return (
    <PageHeader
      title="Möten"
      actions={
        <>
          <ListInfo />
          {evaluatePermission("meetings", "WRITE") && (
            <Button
              variant="primary"
              component={Link}
              to="new"
              sx={{ "&:hover": { color: "primary.contrastText" } }}
            >
              Skapa nytt
            </Button>
          )}
        </>
      }
    />
  );
}

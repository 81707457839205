import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { BaseTeamSelect } from "components/common/selectors/TeamSelect";
import type { Option as Types2Team } from "components/common/selectors/TeamSelect/types";

type Props = {
  teams: Types2Team[];
  readOnly?: boolean;
  disabled?: boolean;
};

type TeamSelectProps = {
  name: string;
  options: Types2Team[];
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
};

function FormikTeamSelect({
  name,
  options,
  label,
  readOnly,
  disabled,
}: TeamSelectProps) {
  const { value, setValue } = useFormikState<string[]>(name);
  return (
    <BaseTeamSelect
      multiple
      label={label}
      value={value}
      onChange={(v: string[]) => setValue(v)}
      options={options}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function TeamsExemptFromResourcePoolField({
  teams,
  readOnly,
  disabled,
}: Props) {
  return (
    <Stack gap={1}>
      <Stack gap={1}>
        <Typography variant="h4">Exkludera från resurspoolen</Typography>
        <Typography>
          Avdelningar valda här kommer inte få några resurspass tilldelade till
          sig från en annan avdelning.
        </Typography>
      </Stack>
      <FormikTeamSelect
        label="Exkluderade avdelningar"
        name="teamsExemptFromResourcePool"
        options={teams}
        readOnly={readOnly}
        disabled={disabled}
      />
    </Stack>
  );
}

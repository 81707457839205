import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { UpdateTimebankMutation as Mutation } from "./types";

const mutation = graphql`
  mutation UpdateTimebankMutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      ok
      setting {
        ...Timebank_fragment
      }
    }
  }
`;

export const useUpdateTimebankMutation = () => useMutation<Mutation>(mutation);

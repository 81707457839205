import { useCallback, useMemo } from "react";
import CheckIcon from "@mui/icons-material/Check";
import type { CSSObject } from "@mui/material";
import { alpha, Chip as MuiChip } from "@mui/material";

import { useIndicesFormikField } from "./hooks";
import type { ChipClickEvent, ChipsProps as Props } from "./types";

type FormikProps = Omit<Props, "checked" | "setChecked"> & { name: string };
type ChipOnClick = (e: ChipClickEvent) => void;

const chipIconOnRight: CSSObject = {
  px: 0.4,
  flexDirection: "row-reverse",
  "& .MuiChip-icon": {
    mx: 0,
    pl: 0.25,
    fontSize: 18.5,
  },
  "& .MuiChip-label": {
    mx: 0,
    pl: 0.35,
    pr: 0,
  },
};

export function Chips({
  checked,
  setChecked,
  options,
  disabled,
  readOnly,
  sx = {},
}: Props) {
  const isChecked = useCallback(
    (idx: number) => checked.includes(idx),
    [checked],
  );
  const toggle = useCallback(
    (idx: number) => {
      setChecked(
        isChecked(idx) ? checked.filter((i) => i !== idx) : [...checked, idx],
      );
    },
    [setChecked, isChecked, checked],
  );

  const handleChange = useCallback(
    (_e: ChipClickEvent, idx: number) => toggle(idx),
    [toggle],
  );

  const renderChip = useCallback(
    (label: string, i: number) => (
      <Chip
        key={i}
        label={label}
        active={isChecked(i)}
        onClick={(e: ChipClickEvent) => {
          if (!readOnly) {
            handleChange(e, i);
          }
        }}
        disabled={disabled}
        sx={sx}
      />
    ),
    [isChecked, readOnly, handleChange, disabled, sx],
  );

  const elements = useMemo(
    () => options.map(renderChip),
    [options, renderChip],
  );

  return <>{elements}</>;
}

const Chip: React.FC<{
  label: string;
  active: boolean;
  onClick: ChipOnClick;
  disabled?: boolean;
  sx?: CSSObject;
}> = ({ label, active, onClick, disabled, sx = {} }) => {
  const chipSx: CSSObject = useMemo(
    () => ({
      minWidth: 74,
      lineHeight: "normal",
      ...chipIconOnRight,
      ...sx,
    }),
    [sx],
  );

  return active ? (
    <ActiveChip
      label={label}
      onClick={onClick}
      disabled={disabled}
      sx={chipSx}
    />
  ) : (
    <InactiveChip
      label={label}
      onClick={onClick}
      disabled={disabled}
      sx={chipSx}
    />
  );
};

const ActiveChip: React.FC<{
  label: string;
  onClick: ChipOnClick;
  disabled?: boolean;
  sx: CSSObject;
}> = ({ label, onClick, disabled, sx }) => (
  <MuiChip
    label={label}
    variant="filled"
    color="primary"
    icon={<CheckIcon />}
    onClick={onClick}
    disabled={disabled}
    sx={(theme) => ({
      ...sx,
      "&.MuiChip-filledPrimary": {
        color: "primary.main",
        backgroundColor: alpha(theme.palette.primary.light, 0.04),
        borderColor: "primary.main",
        borderStyle: "solid",
        borderWidth: 1,
      },
    })}
  />
);

const InactiveChip: React.FC<{
  label: string;
  onClick: ChipOnClick;
  disabled?: boolean;
  sx: CSSObject;
}> = ({ label, onClick, disabled, sx }) => (
  <MuiChip
    label={label}
    variant="outlined"
    onClick={onClick}
    disabled={disabled}
    sx={sx}
  />
);

export function FormikChips({ name, ...props }: FormikProps) {
  const { checked, setChecked } = useIndicesFormikField(name);
  return <Chips checked={checked} setChecked={setChecked} {...props} />;
}

import { useMemo } from "react";
import useFormikState from "hooks/useFormikState";

import GenericSelect from "components/common/selectors/GenericSelect";
import {
  ConstraintModuleBridgeArray,
  TranslateConstraintModule,
} from "components/types/ConstraintModuleType";

type Props = {
  value: string[];
  setValue: (s: string[]) => void;
  disabled?: boolean;
  readOnly?: boolean;
};
type FormikProps = Omit<Props, "value" | "setValue"> & { name: string };

type Option = {
  value: string;
  label: string;
  color: string;
};

const modules: Option[] = ConstraintModuleBridgeArray.map((c) => ({
  value: c,
  label: TranslateConstraintModule(c) || "",
  color: "#677074",
}));

function ModulesBridgeSelect({ value, setValue, disabled, readOnly }: Props) {
  const selected = useMemo<Option[]>(
    () => modules.filter((o: Option) => value.includes(o.value)),
    [value],
  );

  function onChange(_e: any, newValue: Option[]) {
    setValue(newValue.map((o) => o.value));
  }

  return (
    <GenericSelect<Option>
      multiple
      value={selected}
      onChange={onChange}
      options={modules}
      placeholder="Välj regelmoduler..."
      noOptionsText="Inga ytterligare regelmoduler"
      disabled={disabled}
      readOnly={readOnly}
    />
  );
}

export function FormikModulesBridgeSelect({ name, ...props }: FormikProps) {
  const { value, setValue } = useFormikState<string[]>(name);

  return <ModulesBridgeSelect value={value} setValue={setValue} {...props} />;
}

import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { connectionToArray } from "relay-help/arrays";

import {
  AdvancedCard,
  AdvancedForm,
  ComprestCard,
  ComprestForm,
  DefinitionsCard,
  DefinitionsForm,
  DemandProfileCard,
  DemandProfileForm,
  formatHmsToHm,
  ResourcePoolCard,
  ResourcePoolForm,
  SchedulePeriodCard,
  SchedulePeriodForm,
  TimebankCard,
  TimebankForm,
} from "components/group_settings";
import { FormikMock } from "components/setting/common";
import { ShiftType } from "components/setting/types";

import type { ScheduleGroupSettingsViewer_fragment$key as Key } from "./types";

type Props = {
  fragmentRef: Key;
  shiftTypes: ReadonlyArray<ShiftType>;
};

const fragment = graphql`
  fragment ScheduleGroupSettingsViewer_fragment on TeamGroupSettingSnapshotNode {
    id
    periodLengthWeeks
    uniqueWeeks
    wrapAroundPeriodEnd
    useDutyDemand
    useDemandWeights
    constraintModules
    showAdvancedSettings
    constraintModulesBridge
    morningStartsNightEnds
    eveningStartsMorningEnds
    nightStartsEveningEnds
    fullDayDuration
    weekendStartsAt
    weekendStartsAtDay
    weekendEndsAt
    weekendEndsAtDay
    timebankMode
    offsetTimebankImbalance
    maxTimebankPeriod
    minTimebankPeriod
    maxTimebankTotal
    minTimebankTotal
    shiftsLaterStartNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsLaterEndNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsEarlierStartNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    shiftsEarlierEndNotOk {
      edges {
        node {
          id
          name
        }
      }
    }
    teamsExemptFromResourcePool {
      edges {
        node {
          id
        }
      }
    }
    schedule {
      teams {
        edges {
          node {
            id
            name
            color
          }
        }
      }
    }
    comprestCanCutDaybreakBilagaj
    comprestCanCutDaybreakAbjour
  }
`;

export function ScheduleGroupSettingsViewer({
  fragmentRef,
  shiftTypes,
}: Props) {
  const { schedule, teamsExemptFromResourcePool, ...settings } =
    useFragment<Key>(fragment, fragmentRef);
  const initialValues = useMemo(
    () => ({
      ...settings,
      morningStartsNightEnds: formatHmsToHm(settings.morningStartsNightEnds),
      eveningStartsMorningEnds: formatHmsToHm(
        settings.eveningStartsMorningEnds,
      ),
      nightStartsEveningEnds: formatHmsToHm(settings.nightStartsEveningEnds),
      teamsExemptFromResourcePool: connectionToArray(
        teamsExemptFromResourcePool,
      ).map((x) => x.id),
      shiftsLaterStartNotOk: connectionToArray(
        settings.shiftsLaterStartNotOk,
      ).map((s) => s.id),
      shiftsLaterEndNotOk: connectionToArray(settings.shiftsLaterEndNotOk).map(
        (s) => s.id,
      ),
      shiftsEarlierStartNotOk: connectionToArray(
        settings.shiftsEarlierStartNotOk,
      ).map((s) => s.id),
      shiftsEarlierEndNotOk: connectionToArray(
        settings.shiftsEarlierEndNotOk,
      ).map((s) => s.id),
    }),
    [settings, teamsExemptFromResourcePool],
  );
  const teams = useMemo(
    () =>
      connectionToArray(schedule?.teams).map((t) => ({
        ...t,
        group: { id: "", name: "" },
      })),
    [schedule],
  );

  return (
    <FormikMock initialValues={initialValues}>
      <Stack gap={1}>
        <DemandProfileCard>
          <DemandProfileForm readOnly />
        </DemandProfileCard>
        <SchedulePeriodCard>
          <SchedulePeriodForm readOnly />
        </SchedulePeriodCard>
        <DefinitionsCard>
          <DefinitionsForm readOnly />
        </DefinitionsCard>
        <TimebankCard>
          <TimebankForm shiftTypes={shiftTypes} readOnly />
        </TimebankCard>
        <ResourcePoolCard>
          <ResourcePoolForm teams={teams} readOnly />
        </ResourcePoolCard>
        <ComprestCard>
          <ComprestForm readOnly />
        </ComprestCard>
        <AdvancedCard>
          <AdvancedForm readOnly />
        </AdvancedCard>
      </Stack>
    </FormikMock>
  );
}

import { useState } from "react";
import { Stack } from "@mui/material";

import type { RecurrenceRuleType } from "../types";
import { days, WEEKLY, YEARLY } from "../types";

import RulesAndDates from "./RulesAndDates/RulesAndDates";
import AddFooter from "./AddFooter";

type Props = {
  value: RecurrenceRuleType[];
  onChange: (value: RecurrenceRuleType[]) => void;
  start: Date;
  durationMs: number;
};

function newRule(opts?: Partial<RecurrenceRuleType>): RecurrenceRuleType {
  return {
    exclude: false,
    isDate: false,
    freq: YEARLY,
    interval: 1,
    dtstart: null,
    byweekday: [],
    bymonth: [],
    bymonthday: [],
    bysetpos: [],
    until: null,
    count: null,
    ...(opts || {}),
  };
}

export default function Form({ value, onChange, start, durationMs }: Props) {
  const [openIdx, setOpenIdx] = useState<number>(-1);

  function onOpen(index: number) {
    setOpenIdx(openIdx === index ? -1 : index);
  }

  function onAddRule() {
    const newValue = value || [];
    const todaysDayIdx = (start.getDay() + 6) % 7; // days[0] is monday
    const dayObj = days[todaysDayIdx];
    newValue.push(
      newRule({
        exclude: false,
        isDate: false,
        freq: WEEKLY,
        dtstart: start,
        byweekday: [dayObj],
      }),
    );
    onChange(newValue);
    onOpen(newValue?.length ? newValue.length - 1 : 0);
  }
  function onAddDate() {
    const newValue = value || [];
    newValue.push(
      newRule({
        exclude: false,
        isDate: true,
        dtstart: start,
      }),
    );
    onChange(newValue);
    onOpen(newValue?.length ? newValue.length - 1 : 0);
  }
  return (
    <Stack gap={1}>
      <RulesAndDates
        value={value}
        onChange={onChange}
        openIdx={openIdx}
        onOpen={onOpen}
        durationMs={durationMs}
      />
      <AddFooter onAddRule={onAddRule} onAddDate={onAddDate} />
    </Stack>
  );
}

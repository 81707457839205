import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";

import type { UpdateComprestMutation as Mutation } from "./types";

const mutation = graphql`
  mutation UpdateComprestMutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      ok
      setting {
        ...Comprest_fragment
      }
    }
  }
`;

export const useUpdateComprestMutation = () => useMutation<Mutation>(mutation);

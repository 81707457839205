import { PropsWithChildren } from "react";
import { Stack } from "@mui/material";
import { ConstraintModuleSwitch_fragment$key as ConstraintModuleSwitchKey } from "settings/common/__generated__/ConstraintModuleSwitch_fragment.graphql";
import PaperWrapper from "settings/common/PaperWrapper";
import { ture } from "styles/mui/light-palette";

import { TeamGroupSettingBoxHeader } from "./SettingBoxHeaderNew";

type Props = {
  settingModule: string;
  fragmentRef: ConstraintModuleSwitchKey;
  settingName?: string;
};

export function SettingBoxNew({
  settingModule,
  fragmentRef,
  children,
  settingName,
}: PropsWithChildren<Props>) {
  return (
    <PaperWrapper
      sx={{
        backgroundColor: ture[20],
      }}
    >
      <TeamGroupSettingBoxHeader
        settingModule={settingModule}
        fragmentRef={fragmentRef}
        settingName={settingName}
      />
      <Stack gap={1}>{children}</Stack>
    </PaperWrapper>
  );
}

import { useCallback, useMemo } from "react";
import { ListItem } from "@mui/material";

import type {
  GenericOwnerState,
  GenericRenderOptionState,
  SingleProps as GenericSingleProps,
} from "../GenericSelect";
import { GenericSelect } from "../GenericSelect";

import { OptionLabel } from "./OptionLabel";
import type { Option, SingleProps } from "./types";

type GenericProps = Omit<GenericSingleProps<Option>, "value" | "onChange">;

type RenderOptionProps = React.HTMLAttributes<HTMLLIElement> & { key: any };
type OwnerState = GenericOwnerState<Option>;

const getValue = (option: Option | null) => option?.id ?? null;

export function SingleSelect({
  value: valueProp,
  onChange: onChangeProp,
  label = "Kompetens",
  placeholder = "Välj kompetens...",
  isOptionEqualToValue = (option: Option, value: Option | null) =>
    getValue(option) === getValue(value),
  ...props
}: SingleProps) {
  const { options } = props;

  const value = useMemo<Option | null>(
    () => options.find((v) => getValue(v) === valueProp) ?? null,
    [options, valueProp],
  );

  const onChange = useCallback(
    (_e: any, newValue: Option | null, _reason: any, _details?: any) => {
      if (onChangeProp) onChangeProp(getValue(newValue));
    },
    [onChangeProp],
  );

  const renderOption = useCallback(
    (
      props: RenderOptionProps,
      option: Option,
      _state: GenericRenderOptionState,
      ownerState: OwnerState,
    ) => {
      const { key, ...otherProps } = props;
      return (
        <ListItem sx={{ py: 0, pl: "2px" }} key={key} {...otherProps}>
          <OptionLabel
            label={ownerState.getOptionLabel(option)}
            color={option.color}
          />
        </ListItem>
      );
    },
    [],
  );

  return (
    <GenericSelect<Option>
      renderOption={renderOption}
      {...(props as GenericProps)}
      value={value ?? undefined}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
}

/**
 * @generated SignedSource<<925e2725b6f5b1ca94ca47b5c793fbc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiActivityShiftDayTypeChoices = "D" | "E" | "F" | "N" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RequiredVsScheduledChart_fragment$data = {
  readonly userStats: ReadonlyArray<{
    readonly activities: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly shiftDayType: ApiActivityShiftDayTypeChoices | null;
        } | null;
      } | null>;
    };
    readonly actualWeekendWorkdayCount: number;
    readonly averageLength: number;
    readonly competences: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        } | null;
      } | null>;
    };
    readonly employmentDegree: number;
    readonly expectedWeekendWorkdayCount: number;
    readonly hourlyCost: number;
    readonly id: string;
    readonly maxLength: number;
    readonly minLength: number;
    readonly name: string;
    readonly requiredHours: number;
    readonly requiredShifts: number;
    readonly schedule: {
      readonly teamgroupsettingsnapshot: {
        readonly constraintModules: ReadonlyArray<string>;
        readonly dayShiftDistributionShare: number;
        readonly eveningShiftDistributionShare: number;
        readonly fullDayShiftDistributionShare: number;
        readonly nightShiftDistributionShare: number;
      } | null;
    };
    readonly scheduledHours: number;
    readonly scheduledShifts: number;
    readonly subtractedVacationHours: number;
    readonly subtractedVacationShifts: number;
    readonly teams: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly color: string;
          readonly id: string;
          readonly name: string;
        } | null;
      } | null>;
    };
    readonly timebankBalance: number;
    readonly userSetting: {
      readonly dayShiftDistributionShare: number;
      readonly eveningShiftDistributionShare: number;
      readonly fullDayShiftDistributionShare: number;
      readonly nightShiftDistributionShare: number;
      readonly settingModules: ReadonlyArray<string>;
    } | null;
  }>;
  readonly " $fragmentType": "RequiredVsScheduledChart_fragment";
};
export type RequiredVsScheduledChart_fragment$key = {
  readonly " $data"?: RequiredVsScheduledChart_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RequiredVsScheduledChart_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayShiftDistributionShare",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eveningShiftDistributionShare",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nightShiftDistributionShare",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullDayShiftDistributionShare",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequiredVsScheduledChart_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSnapshotNode",
      "kind": "LinkedField",
      "name": "userStats",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employmentDegree",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timebankBalance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtractedVacationHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subtractedVacationShifts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hourlyCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredShifts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "averageLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scheduledHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scheduledShifts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expectedWeekendWorkdayCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualWeekendWorkdayCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityNodeConnection",
          "kind": "LinkedField",
          "name": "activities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ActivityNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ActivityNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "shiftDayType",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetenceSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "competences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetenceSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompetenceSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamSnapshotNodeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSnapshotNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamSnapshotNode",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSettingSnapshotNode",
          "kind": "LinkedField",
          "name": "userSetting",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "settingModules",
              "storageKey": null
            },
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ScheduleNode",
          "kind": "LinkedField",
          "name": "schedule",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamGroupSettingSnapshotNode",
              "kind": "LinkedField",
              "name": "teamgroupsettingsnapshot",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "constraintModules",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScheduleStats",
  "abstractKey": null
};
})();

(node as any).hash = "e02d87952edd609ce2fd934bebe04710";

export default node;

/**
 * @generated SignedSource<<e3328bae3e19b537d80c44b2abcb306b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ApiActivityActivityTypeChoices = "A" | "C" | "D" | "F" | "J" | "M" | "O" | "P" | "Q" | "R" | "S" | "V" | "W" | "Y" | "Z" | "%future added value";
export type ApiActivityShiftDayTypeChoices = "D" | "E" | "F" | "N" | "%future added value";
export type ActivityGanttTimelineQuery$variables = {
  id: string;
  selectedActivities?: ReadonlyArray<ApiActivityActivityTypeChoices> | null;
};
export type ActivityGanttTimelineQuery$data = {
  readonly schedule: {
    readonly activities: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly activityType: ApiActivityActivityTypeChoices;
          readonly ganttItems: ReadonlyArray<{
            readonly breakTime: number | null;
            readonly duration: number;
            readonly endAlteration: number | null;
            readonly endTime: string;
            readonly id: string;
            readonly isPart: boolean | null;
            readonly startAlteration: number | null;
            readonly startTime: string;
            readonly title: string;
            readonly titleCode: string;
            readonly worktime: number | null;
          }>;
          readonly id: string;
          readonly meetingShift: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly shift: {
            readonly id: string;
            readonly name: string;
          } | null;
          readonly shiftDayType: ApiActivityShiftDayTypeChoices | null;
          readonly team: {
            readonly color: string;
            readonly id: string;
            readonly name: string;
          } | null;
          readonly user: {
            readonly id: string;
            readonly name: string;
          } | null;
        } | null;
      } | null>;
    };
    readonly previousWeekActivities: ReadonlyArray<{
      readonly activityType: ApiActivityActivityTypeChoices;
      readonly ganttItems: ReadonlyArray<{
        readonly breakTime: number | null;
        readonly duration: number;
        readonly endAlteration: number | null;
        readonly endTime: string;
        readonly id: string;
        readonly isPart: boolean | null;
        readonly startAlteration: number | null;
        readonly startTime: string;
        readonly title: string;
        readonly titleCode: string;
        readonly worktime: number | null;
      }>;
      readonly id: string;
      readonly meetingShift: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly shift: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly shiftDayType: ApiActivityShiftDayTypeChoices | null;
      readonly team: {
        readonly color: string;
        readonly id: string;
        readonly name: string;
      } | null;
      readonly user: {
        readonly id: string;
        readonly name: string;
        readonly referenceId: any;
      } | null;
    }>;
    readonly users: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly competences: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly color: string;
                readonly id: string;
              } | null;
            } | null>;
          };
          readonly dayBreak: string | null;
          readonly id: string;
          readonly name: string;
          readonly referenceId: any;
          readonly teams: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly name: string;
              } | null;
            } | null>;
          };
          readonly weekBreak: string | null;
          readonly weekBreakDay: number | null;
        } | null;
      } | null>;
    };
  } | null;
};
export type ActivityGanttTimelineQuery = {
  response: ActivityGanttTimelineQuery$data;
  variables: ActivityGanttTimelineQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedActivities"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shiftDayType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activityType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ShiftSnapshotNode",
  "kind": "LinkedField",
  "name": "shift",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "MeetingShiftSnapshotNode",
  "kind": "LinkedField",
  "name": "meetingShift",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "TeamSnapshotNode",
  "kind": "LinkedField",
  "name": "team",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ScheduleTimelineItem",
  "kind": "LinkedField",
  "name": "ganttItems",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "titleCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "breakTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAlteration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAlteration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worktime",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "activityType_In",
      "variableName": "selectedActivities"
    }
  ],
  "concreteType": "ActivityNodeConnection",
  "kind": "LinkedField",
  "name": "activities",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserSnapshotNode",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": null
            },
            (v7/*: any*/),
            (v8/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "UserSnapshotNodeConnection",
  "kind": "LinkedField",
  "name": "users",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSnapshotNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserSnapshotNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v13/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dayBreak",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "weekBreak",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "weekBreakDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetenceSnapshotNodeConnection",
              "kind": "LinkedField",
              "name": "competences",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompetenceSnapshotNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CompetenceSnapshotNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v9/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamSnapshotNodeConnection",
              "kind": "LinkedField",
              "name": "teams",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamSnapshotNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TeamSnapshotNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ActivityNode",
  "kind": "LinkedField",
  "name": "previousWeekActivities",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSnapshotNode",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v13/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    (v7/*: any*/),
    (v8/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityGanttTimelineQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivityGanttTimelineQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleNode",
        "kind": "LinkedField",
        "name": "schedule",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d57a6423135c7e6e573a4c4899a7d0bd",
    "id": null,
    "metadata": {},
    "name": "ActivityGanttTimelineQuery",
    "operationKind": "query",
    "text": "query ActivityGanttTimelineQuery(\n  $id: ID!\n  $selectedActivities: [ApiActivityActivityTypeChoices!]\n) {\n  schedule(id: $id) {\n    activities(activityType_In: $selectedActivities) {\n      edges {\n        node {\n          id\n          shiftDayType\n          activityType\n          user {\n            id\n            name\n          }\n          shift {\n            id\n            name\n          }\n          meetingShift {\n            id\n            name\n          }\n          team {\n            id\n            name\n            color\n          }\n          ganttItems {\n            id\n            title\n            titleCode\n            endTime\n            startTime\n            breakTime\n            startAlteration\n            endAlteration\n            isPart\n            duration\n            worktime\n          }\n        }\n      }\n    }\n    users {\n      edges {\n        node {\n          id\n          referenceId\n          name\n          dayBreak\n          weekBreak\n          weekBreakDay\n          competences {\n            edges {\n              node {\n                id\n                color\n              }\n            }\n          }\n          teams {\n            edges {\n              node {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n    previousWeekActivities {\n      id\n      shiftDayType\n      activityType\n      user {\n        id\n        referenceId\n        name\n      }\n      shift {\n        id\n        name\n      }\n      meetingShift {\n        id\n        name\n      }\n      team {\n        id\n        name\n        color\n      }\n      ganttItems {\n        id\n        title\n        titleCode\n        endTime\n        startTime\n        breakTime\n        startAlteration\n        endAlteration\n        isPart\n        duration\n        worktime\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a2bb3b4a740ebd695025f2d29cd6410";

export default node;

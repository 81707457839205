import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks";

import { BaseUserSelect } from "./BaseUserSelect";
import type { Opt, UserSelect_fragment$key as Key } from "./types";
import { useUserSelectFragmentRef } from "./UserSelectFragmentRefContext";

const fragment = graphql`
  fragment UserSelect_fragment on UserNode @relay(plural: true) {
    id
    fullName
  }
`;

type Props = {
  name: string;
  label?: string;
  multi?: boolean;
  disableCloseOnSelect?: boolean;
  showSelectAll?: boolean;
  teamGroup: ReturnType<typeof useCurrentTeamGroup>;
};

export function UserSelect(props: Props) {
  const fragmentRef = useUserSelectFragmentRef();
  const users = useFragment<Key>(fragment, fragmentRef);
  const options: Opt[] = useMemo(
    () =>
      (users || []).map((u) => ({
        value: u?.id || "",
        label: u?.fullName || "",
      })),
    [users],
  );
  return <BaseUserSelect {...props} options={options} />;
}

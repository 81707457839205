import { SettingBox } from "components/setting/common";
import { Base5, Base5Info as Info } from "components/setting/common/forms";

type FormProps = {
  groupName: string;
  readOnly?: boolean;
};

function Title({ name }: { name: string }) {
  return (
    <>
      Dygnsdefinition för enhet <b>{name}</b>
    </>
  );
}

export function Base5Form({ groupName, readOnly }: FormProps) {
  return (
    <SettingBox title={<Title name={groupName} />} infoButton={<Info />}>
      <Base5 readOnly={readOnly} />
    </SettingBox>
  );
}

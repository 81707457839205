/**
 * @generated SignedSource<<6a0eff3d5f9fa65283951a89984ca9c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Advanced_fragment$data = {
  readonly id: string;
  readonly showAdvancedSettings: boolean;
  readonly " $fragmentType": "Advanced_fragment";
};
export type Advanced_fragment$key = {
  readonly " $data"?: Advanced_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Advanced_fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Advanced_fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAdvancedSettings",
      "storageKey": null
    }
  ],
  "type": "SettingNode",
  "abstractKey": null
};

(node as any).hash = "4e31741a6e6b2e57d2b36f1efa6e2e2f";

export default node;

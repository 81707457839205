import { Suspense, useCallback, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  useChangeTitle,
  useCurrentTeamGroup,
  useRedirectOnGroupChange,
} from "hooks";

import { UserSelectFragmentRefProvider } from "components/common/selectors/UserSelect/UserSelectFragmentRefContext";
import { PageWrapper } from "components/layout";
import { EditMeetingPlaceholder as Placeholder } from "components/loading";
import { EditMeetingForm } from "components/meetings";
import { ParticipantListProvider } from "components/meetings/form/ParticipantList";

import type { EditMeetingQuery as Query } from "./types";

type TParams = {
  id: string;
};

type QueryRef = PreloadedQuery<Query>;
type ContentProps = {
  queryRef: QueryRef;
};

const query = graphql`
  query EditMeetingQuery($id: ID!, $teamGroupId: ID!) {
    meeting(id: $id) {
      ...EditMeetingForm_meeting
    }
    usersOnTeamGroup(teamGroupId: $teamGroupId) {
      ...UserSelect_fragment
      ...ParticipantList_users
    }
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      ...EditMeetingForm_setting
    }
  }
`;

export function EditMeeting() {
  const queryRef = useEditMeeting();
  useChangeTitle("Redigera möte");

  return (
    <Container>
      <PageWrapper>
        <Suspense fallback={<Placeholder />}>
          {!!queryRef && <Content queryRef={queryRef} />}
        </Suspense>
      </PageWrapper>
    </Container>
  );
}

function Content({ queryRef }: ContentProps) {
  const { teamGroupId, data, onClose } = useContent(queryRef);

  if (!data.settingForTeamGroup || !data.meeting) {
    return <p>Laddar...</p>;
  }

  return (
    <UserSelectFragmentRefProvider value={data.usersOnTeamGroup}>
      <ParticipantListProvider fragmentRef={data.usersOnTeamGroup}>
        <EditMeetingForm
          teamGroupId={teamGroupId}
          fragmentRef={data.meeting}
          settingFragmentRef={data.settingForTeamGroup}
          onClose={onClose}
        />
      </ParticipantListProvider>
    </UserSelectFragmentRefProvider>
  );
}

function useEditMeeting() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id ?? "";
  const { id } = useParams<TParams>();

  useRedirectOnGroupChange("..");

  useEffect(() => {
    if (!id || !teamGroupId) return;
    loadQuery({ id, teamGroupId }, { fetchPolicy: "network-only" });
  }, [id, teamGroupId, loadQuery]);

  return queryRef;
}

function useContent(queryRef: QueryRef) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id ?? "";
  const nav = useNavigate();
  const goToList = useCallback(() => nav(".."), [nav]);

  return {
    teamGroupId,
    data,
    onClose: goToList,
  };
}

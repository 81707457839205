import {
  HeaderRadios,
  HeaderSwitch,
  HeaderTitle,
  SettingBoxHeader,
} from "components/setting/common";
import { Free3Info as Info } from "components/setting/common/forms";

type Props = {
  groupName: string;
  readOnly?: boolean;
  moduleName: string;
  disabled?: boolean;
};

type TitleProps = {
  name: Props["groupName"];
};

type RadiosProps = Pick<Props, "readOnly" | "disabled">;

function Title({ name }: TitleProps) {
  const title = (
    <>
      Semester för enhet <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

function Radios({ readOnly, disabled }: RadiosProps) {
  return (
    <HeaderRadios
      type="onlyHard"
      value={true}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
}

export function Header({ groupName, moduleName, readOnly, disabled }: Props) {
  return (
    <SettingBoxHeader
      radiosComponent={<Radios readOnly={readOnly} disabled={disabled} />}
      switchComponent={<HeaderSwitch name={moduleName} readOnly={readOnly} />}
      titleComponent={<Title name={groupName} />}
    />
  );
}

import axios from "api/axiosInstance";
import type { AxiosRequestConfig } from "axios";
import { makeUseAxios } from "axios-hooks";

/** Custom useAxios instance */
const useAxios = makeUseAxios({ axios });

export const useAxiosGet = <TResponse = any, TBody = any, TError = any>(
  url: string,
  config?: AxiosRequestConfig<TBody>,
) =>
  useAxios<TResponse, TBody, TError>({ ...(config ?? {}), url, method: "GET" });

export const useAxiosPost = <TResponse = any, TBody = any, TError = any>(
  url: string,
  config?: AxiosRequestConfig<TBody>,
) =>
  useAxios<TResponse, TBody, TError>({
    ...(config ?? {}),
    url,
    method: "POST",
  });

import { Paper, Stack } from "@mui/material";

import { LoadingCode } from "components/loading/LoadingCode";

export function ResultsPlaceholder() {
  return (
    <Paper
      variant="box"
      sx={{ p: 2, mr: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
    >
      <Stack gap={2} alignItems="flex-start" direction="column">
        <LoadingCode height="205px" />
        <LoadingCode height="205px" />
      </Stack>
    </Paper>
  );
}

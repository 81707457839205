/**
 * @generated SignedSource<<9645694bf41348fb527f9b1184fb83e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageQueryContextQuery$variables = {};
export type PageQueryContextQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"Me_me">;
  } | null;
  readonly teamGroups: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CurrentTeamGroupSelect_fragment" | "CurrentTeamGroup_teamgroups" | "TeamGroupsContext_teamgroups">;
  }>;
};
export type PageQueryContextQuery = {
  response: PageQueryContextQuery$data;
  variables: PageQueryContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TeamNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TeamGroupNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamGroupNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PageQueryContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Me_me"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamGroupNode",
        "kind": "LinkedField",
        "name": "teamGroups",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamGroupsContext_teamgroups"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CurrentTeamGroup_teamgroups"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CurrentTeamGroupSelect_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PageQueryContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperuser",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "memberOf",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNodeConnection",
            "kind": "LinkedField",
            "name": "groupMemberOf",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "teamAdminOf",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNodeConnection",
            "kind": "LinkedField",
            "name": "groupAdminOf",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamGroupNode",
        "kind": "LinkedField",
        "name": "teamGroups",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fc124422d723903b1cd1390d23a6f1ef",
    "id": null,
    "metadata": {},
    "name": "PageQueryContextQuery",
    "operationKind": "query",
    "text": "query PageQueryContextQuery {\n  me {\n    ...Me_me\n    id\n  }\n  teamGroups {\n    ...TeamGroupsContext_teamgroups\n    ...CurrentTeamGroup_teamgroups\n    ...CurrentTeamGroupSelect_fragment\n    id\n  }\n}\n\nfragment CurrentTeamGroupSelect_fragment on TeamGroupNode {\n  id\n  name\n}\n\nfragment CurrentTeamGroup_teamgroups on TeamGroupNode {\n  id\n  name\n}\n\nfragment Me_me on UserNode {\n  id\n  fullName\n  email\n  username\n  isSuperuser\n  memberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  groupMemberOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  teamAdminOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  groupAdminOf {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n\nfragment TeamGroupsContext_teamgroups on TeamGroupNode {\n  id\n  name\n  teams {\n    edges {\n      node {\n        id\n        name\n        color\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "30b3e6e1a841229cfdefa3c2d95d83b7";

export default node;

import { Add as AddIcon } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import type { ArrayHelpers } from "formik";
import { FieldArray } from "formik";
import { useFormikState } from "hooks";

import { ShiftTableRenderer } from "components/shifts/form/ShiftTable";
import { ShiftTableHead } from "components/shifts/form/ShiftTableHead";
import { ShiftTableRow } from "components/shifts/form/ShiftTableRow";

import type { FormShift } from "./types";
import { initialMeetingShift } from "./utils";

type Props = { name: string; day: number; disabled?: boolean };

/** Render meeting shift table.
 * - day - selected day to filter on, or -1 for whole week.
 */
export function MeetingShiftTable({ name, day, disabled = false }: Props) {
  const { shifts, setTouched, shiftStart, shiftEnd } =
    useMeetingShiftTable(name);

  return (
    <>
      <FieldArray name={name}>
        {({ push, remove }: ArrayHelpers<FormShift[]>) => (
          <ShiftTableRenderer
            header={<Header hidden={!shifts.length} />}
            body={shifts.map((s, i) => (
              <ShiftTableRow
                key={i}
                name={`${name}.${i}`}
                onRemove={() => {
                  remove(i);
                  setTouched(true, true);
                }}
                hidden={day !== -1 && s.meetingDay !== day}
                disabled={disabled}
              />
            ))}
            footer={
              day === -1 ? (
                <Typography fontStyle="italic">
                  Kan inte lägga till pass i veckovy
                </Typography>
              ) : (
                <Button
                  onClick={() => {
                    push({
                      ...initialMeetingShift,
                      meetingDay: day,
                      start: shiftStart,
                      end: shiftEnd,
                    });
                    setTouched(true, true);
                  }}
                  startIcon={<AddIcon />}
                >
                  Nytt pass
                </Button>
              )
            }
          />
        )}
      </FieldArray>
    </>
  );
}

const Header: React.FC<{ hidden: boolean }> = ({ hidden }) =>
  hidden ? null : <ShiftTableHead />;

function useMeetingShiftTable(name: string) {
  const {
    value: shifts,
    helpers: { setTouched },
  } = useFormikState<ReadonlyArray<FormShift>>(name);
  const { value: shiftStart } = useFormikState<string>("start");
  const { value: shiftEnd } = useFormikState<string>("end");

  return { shifts: shifts, setTouched, shiftStart, shiftEnd };
}

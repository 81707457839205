import { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import type {
  RuleGroup,
  Shift,
  TeamGroupSetting,
  User,
} from "pages/Schedule/types";

import { Visible } from "components/common/Visible";

import { useSelectedSetting } from "./hooks";
import { RuleGroupSettings } from "./RuleGroupSettings";
import { TeamGroupSettings } from "./TeamGroupSettings";
import { UserSettings } from "./UserSettings";

type Props = {
  levelId: string;
  ruleId: string;
  teamGroupSettingSnapshot: TeamGroupSetting;
  ruleGroups: ReadonlyArray<RuleGroup>;
  shifts: ReadonlyArray<Shift>;
  users: ReadonlyArray<User>;
};

export function SettingBoxSection({
  teamGroupSettingSnapshot,
  ruleGroups,
  shifts,
  users,
  levelId,
  ruleId,
}: Props) {
  const teamGroup = useCurrentTeamGroup();
  const groupId = teamGroup?.id ?? "";
  const groupName = teamGroup?.name ?? "";

  const { periodLengthWeeks = 1, shiftsPerWeek = 1 } =
    teamGroupSettingSnapshot || {};

  const { selectedType, selectedRuleGroup, selectedUser } = useSelectedSetting<
    RuleGroup,
    User
  >({
    groupId,
    levelId,
    userOptions: users,
    ruleGroupOptions: ruleGroups,
  });
  const selectedRuleGroupSetting = useMemo(
    () => selectedRuleGroup?.ruleGroupSetting ?? null,
    [selectedRuleGroup],
  );
  const selectedUserSetting = useMemo(
    () => selectedUser?.userSetting ?? null,
    [selectedUser],
  );

  return (
    <Stack gap={1}>
      <Typography variant="h3" fontStyle="italic">
        Skrivskyddad
      </Typography>
      <Visible visible={selectedType === "group"}>
        {!!teamGroupSettingSnapshot && (
          <TeamGroupSettings
            fragmentRef={teamGroupSettingSnapshot}
            ruleId={ruleId}
            groupName={groupName}
            groupId={groupId}
            shifts={shifts}
          />
        )}
      </Visible>
      <Visible visible={selectedType === "ruleGroup"}>
        {!!selectedRuleGroupSetting && (
          <RuleGroupSettings
            fragmentRef={selectedRuleGroupSetting}
            ruleId={ruleId}
            periodLengthWeeks={periodLengthWeeks}
            shifts={shifts}
          />
        )}
      </Visible>
      <Visible visible={selectedType === "person"}>
        {!!selectedUserSetting && (
          <UserSettings
            fragmentRef={selectedUserSetting}
            ruleId={ruleId}
            periodLengthWeeks={periodLengthWeeks}
            shiftsPerWeek={shiftsPerWeek}
            shifts={shifts}
          />
        )}
      </Visible>
    </Stack>
  );
}

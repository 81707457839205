/**
 * @generated SignedSource<<7b0808a0842c31faa85099ca6d942e3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ApiScheduleSolutionStatusChoices = "ERROR" | "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "QUEUED" | "RUNNING" | "RUNNING_WITH_INFEASIBILITY_VENTS" | "%future added value";
export type ApiScheduleTerminationConditionChoices = "FEASIBLE" | "HARD_CONSTRAINT_COLLISION" | "INFEASIBLE" | "INFEASIBLE_NOW_RERUN" | "MAX_STALL_TIME" | "MAX_TIME_LIMIT" | "OPTIMAL" | "OTHER" | "UNBOUNDED" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Results_fragment$data = {
  readonly id: string;
  readonly period: {
    readonly endDate: string;
    readonly group: {
      readonly id: string;
    };
    readonly startDate: string;
  };
  readonly solutionStatus: ApiScheduleSolutionStatusChoices;
  readonly terminationCondition: ApiScheduleTerminationConditionChoices | null;
  readonly " $fragmentSpreads": FragmentRefs<"InfeasibleResults_fragment">;
  readonly " $fragmentType": "Results_fragment";
};
export type Results_fragment$key = {
  readonly " $data"?: Results_fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Results_fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Results_fragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "solutionStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationCondition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodNode",
      "kind": "LinkedField",
      "name": "period",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamGroupNode",
          "kind": "LinkedField",
          "name": "group",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InfeasibleResults_fragment"
    }
  ],
  "type": "ScheduleNode",
  "abstractKey": null
};
})();

(node as any).hash = "e1d0f8bea94c23086c3436b4bb7848be";

export default node;

/**
 * @generated SignedSource<<5fc5719abe43bb7db7f27e874dc8e6a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganisationQuery$variables = {
  teamGroupId: string;
};
export type OrganisationQuery$data = {
  readonly competences: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CompetenceSelect_fragment">;
  }>;
  readonly ruleGroups: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"RuleGroupSelect_fragment">;
  }>;
  readonly settingForTeamGroup: {
    readonly periodLengthWeeks: number;
  } | null;
  readonly teams: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TeamSelect_fragment">;
  }>;
  readonly userTimebanks: {
    readonly " $fragmentSpreads": FragmentRefs<"UserTimebanks_fragment">;
  };
  readonly usersOnTeamGroup: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"MemberList_fragment">;
  }>;
};
export type OrganisationQuery = {
  response: OrganisationQuery$data;
  variables: OrganisationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamGroupId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "teamGroupId",
    "variableName": "teamGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodLengthWeeks",
  "storageKey": null
},
v3 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "teamGroupIds.0",
        "variableName": "teamGroupId"
      }
    ],
    "kind": "ListValue",
    "name": "teamGroupIds"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/)
],
v9 = [
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "usersOnTeamGroup",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MemberList_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "competences",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetenceSelect_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "ruleGroups",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RuleGroupSelect_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamSelect_fragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserTimebankViewer",
        "kind": "LinkedField",
        "name": "userTimebanks",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserTimebanks_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNode",
        "kind": "LinkedField",
        "name": "usersOnTeamGroup",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentDegree",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentForm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupNodeConnection",
            "kind": "LinkedField",
            "name": "ruleGroups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RuleGroupNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RuleGroupNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamGroupNode",
                        "kind": "LinkedField",
                        "name": "teamGroup",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ResolvedSettings",
            "kind": "LinkedField",
            "name": "resolvedSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shiftsPerPeriod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hoursPerPeriod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hourlyCost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminTimeSettings",
                "kind": "LinkedField",
                "name": "adminTimeSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "restrictAdminTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminHoursMin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminHoursMax",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceTimeSettings",
                "kind": "LinkedField",
                "name": "resourceTimeSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "restrictResourceTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "resourceHoursMin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "resourceHoursMax",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetenceNodeConnection",
            "kind": "LinkedField",
            "name": "competences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetenceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetenceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timebankBaseBalance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserSettingNode",
            "kind": "LinkedField",
            "name": "userSetting",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settingModules",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamNodeConnection",
            "kind": "LinkedField",
            "name": "memberOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TeamNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingNode",
        "kind": "LinkedField",
        "name": "settingForTeamGroup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompetenceNode",
        "kind": "LinkedField",
        "name": "competences",
        "plural": true,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RuleGroupNode",
        "kind": "LinkedField",
        "name": "ruleGroups",
        "plural": true,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TeamNode",
        "kind": "LinkedField",
        "name": "teams",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamGroupNode",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "UserTimebankViewer",
        "kind": "LinkedField",
        "name": "userTimebanks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserTimebankNode",
            "kind": "LinkedField",
            "name": "users",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timebank",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59e069aaa526307c97d5805e13a0bf22",
    "id": null,
    "metadata": {},
    "name": "OrganisationQuery",
    "operationKind": "query",
    "text": "query OrganisationQuery(\n  $teamGroupId: ID!\n) {\n  usersOnTeamGroup(teamGroupId: $teamGroupId) {\n    ...MemberList_fragment\n    id\n  }\n  settingForTeamGroup(teamGroupId: $teamGroupId) {\n    periodLengthWeeks\n    id\n  }\n  competences {\n    ...CompetenceSelect_fragment\n    id\n  }\n  ruleGroups(teamGroupId: $teamGroupId) {\n    ...RuleGroupSelect_fragment\n    id\n  }\n  teams {\n    ...TeamSelect_fragment\n    id\n  }\n  userTimebanks(teamGroupIds: [$teamGroupId]) {\n    ...UserTimebanks_fragment\n  }\n}\n\nfragment CompetenceSelect_fragment on CompetenceNode {\n  id\n  name\n  color\n}\n\nfragment MemberList_fragment on UserNode {\n  id\n  fullName\n  firstName\n  lastName\n  email\n  employmentDegree\n  employmentForm\n  employmentTitle\n  ruleGroups {\n    edges {\n      node {\n        id\n        name\n        teamGroup {\n          id\n        }\n      }\n    }\n  }\n  resolvedSettings(teamGroupId: $teamGroupId) {\n    shiftsPerPeriod\n    hoursPerPeriod\n    hourlyCost\n    adminTimeSettings {\n      restrictAdminTime\n      adminHoursMin\n      adminHoursMax\n    }\n    resourceTimeSettings {\n      restrictResourceTime\n      resourceHoursMin\n      resourceHoursMax\n    }\n  }\n  competences {\n    edges {\n      node {\n        id\n        name\n        color\n      }\n    }\n  }\n  timebankBaseBalance\n  userSetting {\n    settingModules\n    id\n  }\n  memberOf {\n    edges {\n      node {\n        id\n        name\n        color\n      }\n    }\n  }\n}\n\nfragment RuleGroupSelect_fragment on RuleGroupNode {\n  id\n  name\n}\n\nfragment TeamSelect_fragment on TeamNode {\n  id\n  name\n  color\n  group {\n    id\n    name\n  }\n}\n\nfragment UserTimebanks_fragment on UserTimebankViewer {\n  users {\n    user {\n      id\n    }\n    timebank\n  }\n}\n"
  }
};
})();

(node as any).hash = "c3535daa63755e202be7a0e47d629197";

export default node;

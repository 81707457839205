import { FormControlLabel, ListItem } from "@mui/material";

import { OptionCheckbox } from "./OptionCheckbox";

type Props = {
  checked: boolean;
  label?: string;
  onClick: () => void;
};

export function SelectAllOption({
  checked,
  onClick: onClickProp,
  label = "Välj alla",
}: Props) {
  function onClick(e: React.MouseEvent) {
    e.preventDefault();
    onClickProp();
  }

  return (
    <ListItem dense onMouseDown={(e) => e.preventDefault()} sx={{ px: 3.5 }}>
      <FormControlLabel
        control={<OptionCheckbox checked={checked} />}
        label={label}
        onClick={onClick}
      />
    </ListItem>
  );
}

import { UnreachableCaseError } from "ts-essentials";

type NightRotationType = "N" | "R1" | "R2" | "R3" | "%future added value";

const TranslateNightRotationType = (
  type: NightRotationType | undefined,
): string | undefined => {
  switch (type) {
    case "N":
      return "Ingen begränsning";
    case "R1":
      return "Max 1 nattpassrotation tillåts";
    case "R2":
      return "Max 2 nattpassrotationer tillåts";
    case "R3":
      return "Max 3 nattpassrotationer tillåts";
    case undefined:
    case "%future added value":
      return undefined;
    default:
      throw new UnreachableCaseError(type);
  }
};

const nightRotationList: NightRotationType[] = ["N", "R1", "R2", "R3"];

export const nightRotationOptions = nightRotationList.map((u) => ({
  value: u,
  label: TranslateNightRotationType(u),
}));

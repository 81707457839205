import { Suspense, useEffect, useMemo } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { Box, Paper, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useResultsParams } from "hooks";
import { SolutionStatus } from "types/Schedule";

import { ResultsPlaceholder } from "components/loading";
import { ActivityCharts, InfeasibleResults } from "components/schedule";
import { DrawerSibling } from "components/schedule/schedule_warnings";
import { ScheduleWarningsDrawer } from "components/schedule/schedule_warnings/ScheduleWarningsDrawer";

import type {
  Results_fragment$key as Key,
  ResultsQuery as Query,
  ResultsScheduleWarningsContextQuery,
} from "./types";

const fragment = graphql`
  fragment Results_fragment on ScheduleNode {
    id
    solutionStatus
    terminationCondition
    period {
      startDate
      endDate
      group {
        id
      }
    }
    ...InfeasibleResults_fragment
  }
`;

const query = graphql`
  query ResultsQuery($id: ID!) {
    schedule(id: $id) {
      ...ActivityCharts_fragment
    }
  }
`;

export const scheduleWarningsQuery = graphql`
  query ResultsScheduleWarningsContextQuery($scheduleId: ID!) {
    scheduleValidationWarnings(scheduleId: $scheduleId) {
      ...ScheduleWarningsDrawer_fragment
      ...WarningsDrawerButton_fragment
    }
  }
`;

type CommonProps = {
  scheduleId: string;
  refresh: () => Promise<void>;
};
type PeriodStartEnd = {
  periodStart: Date;
  periodEnd: Date;
};
type Props = CommonProps & {
  fragmentRef: Key;
};
type PrefetchProps = CommonProps & PeriodStartEnd;
type ContentProps = CommonProps &
  PeriodStartEnd & {
    queryRef: PreloadedQuery<Query>;
    scheduleWarningsQueryRef:
      | PreloadedQuery<ResultsScheduleWarningsContextQuery>
      | null
      | undefined;
  };

function ResultsContent({
  scheduleId: id,
  queryRef,
  scheduleWarningsQueryRef,
  periodStart,
  periodEnd,
  refresh,
}: ContentProps) {
  const { validation: open, setValidation: setOpen } = useResultsParams({
    periodStart,
    periodEnd,
  });
  const data = usePreloadedQuery<Query>(query, queryRef);

  const toggleDrawer = () => setOpen(!open);
  const closeDrawer = () => setOpen(false);

  const boxSx = {
    maxWidth: "100%",
    pr: open ? 1 : 0,
  };
  const paperSx = {
    px: 2,
    pt: 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    ...(open ? { borderBottomRightRadius: 0 } : {}),
  };

  return (
    <Stack direction="row" sx={{ position: "relative" }}>
      <Box component={DrawerSibling} open={open} sx={boxSx}>
        <Paper variant="box" sx={paperSx}>
          {!!data.schedule && (
            <ActivityCharts
              fragmentRef={data.schedule}
              toggleDrawer={toggleDrawer}
              refresh={refresh}
              scheduleWarningsQueryRef={scheduleWarningsQueryRef}
            />
          )}
        </Paper>
      </Box>
      <Suspense fallback={null}>
        {scheduleWarningsQueryRef && (
          <ScheduleWarningsDrawer
            open={open}
            onClose={closeDrawer}
            refresh={refresh}
            scheduleWarningsQueryRef={scheduleWarningsQueryRef}
          />
        )}
      </Suspense>
    </Stack>
  );
}

function ResultsPrefetch(props: PrefetchProps) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const [scheduleWarningsQueryRef, loadScheduleWarningsQuery] =
    useQueryLoader<ResultsScheduleWarningsContextQuery>(scheduleWarningsQuery);

  useEffect(() => {
    loadQuery({ id: props.scheduleId });
    loadScheduleWarningsQuery({ scheduleId: props.scheduleId });
  }, [loadQuery, loadScheduleWarningsQuery, props.scheduleId]);

  return (
    <Suspense fallback={<ResultsPlaceholder />}>
      {!!queryRef && (
        <ResultsContent
          {...props}
          queryRef={queryRef}
          scheduleWarningsQueryRef={scheduleWarningsQueryRef}
        />
      )}
    </Suspense>
  );
}

export function Results({ fragmentRef, ...props }: Props) {
  const schedule = useFragment<Key>(fragment, fragmentRef);
  const { startDate, endDate } = schedule.period || {};
  const periodStart = new Date(startDate);
  const periodEnd = new Date(endDate);
  const { clear } = useResultsParams({
    periodStart,
    periodEnd,
  });
  const teamGroupId = schedule.period.group.id;

  const isValidSchedule = useMemo(
    () =>
      schedule.solutionStatus === SolutionStatus.FEASIBLE ||
      schedule.solutionStatus === SolutionStatus.HARD_CONSTRAINT_COLLISION,
    [schedule],
  );

  useEffect(() => {
    clear();
  }, [teamGroupId, clear]);

  if (!isValidSchedule) {
    return (
      <InfeasibleResults
        fragmentRef={schedule}
        emptyPaperSx={{
          p: 4,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      />
    );
  }

  return (
    <Suspense fallback={<ResultsPlaceholder />}>
      <ResultsPrefetch
        {...props}
        periodStart={periodStart}
        periodEnd={periodEnd}
      />
    </Suspense>
  );
}

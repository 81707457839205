import { SettingBox } from "components/setting/common";
import { Base7, Base7Info as Info } from "components/setting/common/forms";

type FormProps = { groupName: string; readOnly?: boolean };

function Title({ name }: { name: string }) {
  return (
    <>
      Kostnadsinställningar för enhet <b>{name}</b>
    </>
  );
}

export function Base7Form({ groupName, readOnly }: FormProps) {
  return (
    <SettingBox title={<Title name={groupName} />} infoButton={<Info />}>
      <Base7 readOnly={readOnly} />
    </SettingBox>
  );
}

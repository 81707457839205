import type { Dispatch } from "react";
import { useCallback, useMemo } from "react";
import { useQueryParams } from "hooks";

import type { QueryParamsReturnType } from "./useQueryParams";

type State = { newUser: boolean };
type Actions = {
  setNewUser: Dispatch<boolean>;
};
type OrganisationParamsType = State & Actions & QueryParamsReturnType;

export function useOrganisationParams() {
  const [state, setState, clear] = useQueryParams<State>((query) => ({
    newUser: query.has("newUser"),
  }));

  const setNewUser = useCallback(
    (value: boolean) => setState("newUser", value),
    [setState],
  );

  return useMemo<OrganisationParamsType>(
    () => ({
      ...state,
      setNewUser,
      clear,
    }),
    [state, setNewUser, clear],
  );
}

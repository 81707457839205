import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";
import { requestedDaysOffSetting } from "settings/RequestedDaysOff/RequestedDaysOffSetting";

import { Header } from "./Header";
import { MaxApprovedWishes } from "./MaxApprovedWishes";
import { OptimizeApprovedWishesDistributionBetweenEmployees } from "./OptimizeApprovedWishesDistributionBetweenEmployees";

type Props = { readOnly?: boolean };

const moduleName = requestedDaysOffSetting.moduleName;

export function WishedDaysOffField({ readOnly }: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(moduleName);

  return (
    <Stack gap={1}>
      <Header moduleName={moduleName} readOnly={readOnly} disabled={disabled} />
      <Stack gap={5}>
        <MaxApprovedWishes readOnly={readOnly} disabled={disabled} />
        <OptimizeApprovedWishesDistributionBetweenEmployees
          readOnly={readOnly}
          disabled={disabled}
        />
      </Stack>
    </Stack>
  );
}

import { Stack, Typography } from "@mui/material";

type Props = {
  error?: Error;
};

export function RenderError({ error }: Props) {
  if (!error) return null;
  return (
    <Stack gap={2} sx={{ p: 2, border: "1px solid red", borderRadius: 1 }}>
      <Stack gap={1}>
        <Typography variant="h4">Något gick fel!</Typography>
        <Typography variant="body2">
          Ett systemfel har inträffat. Det är vårt fel, inte ditt! Ta gärna en
          skärmbild och skicka över till din Konvoj-kontakt. Tack!
        </Typography>
      </Stack>
    </Stack>
  );
}

import { useMemo } from "react";

import { MultiSelect } from "./MultiSelect";
import { SingleSelect } from "./SingleSelect";
import type { MultiProps, Option, Props, SingleProps } from "./types";

export function BaseCompetenceSelect({
  multiple,
  insert,
  options: optionsProp,
  getOptionLabel = (option: Option) => option.name || "",
  ...props
}: Props) {
  const options = useMemo(
    () => [
      ...(insert?.before ?? []),
      ...(optionsProp ?? []),
      ...(insert?.after ?? []),
    ],
    [optionsProp, insert],
  );

  if (multiple) {
    return (
      <MultiSelect
        {...(props as MultiProps)}
        options={options}
        getOptionLabel={getOptionLabel}
        noOptionsText={"Inga ytterligare kompetenser finns tillagda"}
      />
    );
  }
  return (
    <SingleSelect
      {...(props as SingleProps)}
      options={options}
      getOptionLabel={getOptionLabel}
      noOptionsText={"Inga ytterligare kompetenser finns tillagda"}
    />
  );
}

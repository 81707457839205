import type { CSSObject } from "@mui/material";
import {
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  Typography,
} from "@mui/material";

import { WeightSelect } from "components/setting/common/components/WeightSelect";

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  sx?: CSSObject;
};

export function LabeledWeightSelect({
  label,
  name,
  disabled,
  readOnly,
  sx = {},
}: Props) {
  const inputLabel = "Prioritet";
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <FormLabel>
        <Typography variant="subtitle2" lineHeight={1}>
          {label}
        </Typography>
      </FormLabel>
      <FormControl sx={sx}>
        <InputLabel>{inputLabel}</InputLabel>
        <WeightSelect
          name={name}
          label={inputLabel}
          disabled={disabled}
          readOnly={readOnly}
          // sx={{ width: sx?.width ?? 150 }}
        />
      </FormControl>
    </Box>
  );
}

import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import { useURLSearchParams } from "./useURLSearchParams";

type GenericState = Record<string, any>;
type QueryType = ReturnType<typeof useURLSearchParams>;
type SetStateValue<S extends GenericState> = S[keyof S];
type SetState<S extends GenericState> = (
  key: string,
  value: SetStateValue<S>,
) => void;

export type QueryParamsReturnType = {
  clear: () => void;
};

type UseQueryParams<State extends GenericState> = [
  State,
  SetState<State>,
  QueryParamsReturnType["clear"],
];

function valueToURL(value: any) {
  if (Array.isArray(value)) {
    return value.join(",");
  } else if (DateTime.fromJSDate(value).isValid) {
    return value.valueOf();
  }
  return value;
}

/** Uses useState to update query state.
 * Updates query parameters using useHistory().push().
 * @param initialStateFn - URLSearchParams instance.
 * @returns [state, setState, clear] */
export function useQueryParams<State extends GenericState>(
  initialStateFn: (query: QueryType) => State,
): UseQueryParams<State> {
  const navigate = useNavigate();
  const query = useURLSearchParams();
  const state = useMemo(() => initialStateFn(query), [initialStateFn, query]);

  const setState = useCallback(
    (key: string, value: SetStateValue<State>) => {
      const valueStr = valueToURL(value);
      if (query.has(key) && !value) {
        query.delete(key);
      } else {
        query.set(key, valueStr);
      }

      query.sort();
      navigate(`?${query.toString()}`);
    },
    [query, navigate],
  );

  const clear = useCallback(() => navigate("?"), [navigate]);

  return useMemo(() => [state, setState, clear], [state, setState, clear]);
}

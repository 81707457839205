import { createContext, useContext } from "react";

import type { UserSelect_fragment$key as Key } from "./types";

const UserSelectFragmentRefContext = createContext<Key | null>(null);
export const UserSelectFragmentRefProvider =
  UserSelectFragmentRefContext.Provider;
export const useUserSelectFragmentRef = () => {
  const ref = useContext(UserSelectFragmentRefContext);
  if (!ref) {
    throw new Error(
      "useUserSelectFragmentRef requires a UserSelectFragmentRefProvider",
    );
  }
  return ref;
};

import { useCallback } from "react";
import { Stack } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { LabeledHardSoftRadios } from "../common/LabeledHardSoftRadios";

import { TeamLabel } from "./TeamLabel";
import { Weights } from "./Weights";

type Props = { name: string; disabled?: boolean; readOnly?: boolean };

export function TeamDemandSetting({ name, disabled, readOnly }: Props) {
  const { value: allowUnderstaffing } = useFormikState<boolean>(
    `${name}.allowUnderstaffing`,
  );

  const getName = useCallback(
    (fieldName: string) => `${name}.${fieldName}`,
    [name],
  );

  return (
    <Stack
      gap={5}
      direction="row"
      flexWrap={{ xs: "wrap", lg: "nowrap" }}
      justifyContent="space-between"
    >
      <Stack columnGap={5} rowGap={2} direction="row">
        <Stack>
          <TeamLabel
            name={getName("team")}
            pt={2.95}
            minWidth={110}
            width={110}
          />
        </Stack>
        <LabeledHardSoftRadios
          name={getName("allowUnderstaffing")}
          label="Tillåt underbemanning"
          disabled={disabled}
          readOnly={readOnly}
        />
      </Stack>
      <Weights
        name={name}
        disabled={disabled || !allowUnderstaffing}
        readOnly={readOnly}
      />
    </Stack>
  );
}

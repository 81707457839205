import type { Dispatch } from "react";
import { useCallback, useMemo } from "react";
import type { QueryParamsReturnType } from "hooks";
import { useQueryParams } from "hooks";

import { ALL_COMPETENCES, ALL_TEAMS } from "components/demand/form/utils";

type State = { team: string; competence: string };
type Actions = {
  setTeam: Dispatch<string>;
  setCompetence: Dispatch<string>;
};
type ParamsType = State & Actions & QueryParamsReturnType;

export function useDemandParams() {
  const [state, setState, clear] = useQueryParams<State>((query) => ({
    team: query.get("team") ?? ALL_TEAMS,
    competence: query.get("competence") ?? ALL_COMPETENCES,
  }));

  const setTeam = useCallback(
    (value: string) => setState("team", value),
    [setState],
  );
  const setCompetence = useCallback(
    (value: string) => setState("competence", value),
    [setState],
  );

  return useMemo<ParamsType>(
    () => ({
      ...state,
      setTeam,
      setCompetence,
      clear,
    }),
    [state, setTeam, setCompetence, clear],
  );
}

/**
 * @generated SignedSource<<28bc559c8fd2830a06c5eabf0f1982c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRuleGroupInput = {
  clientMutationId?: string | null;
  description: string;
  id: string;
  name: string;
};
export type EditRuleGroupMutation$variables = {
  input: EditRuleGroupInput;
};
export type EditRuleGroupMutation$data = {
  readonly editRuleGroup: {
    readonly ruleGroup: {
      readonly " $fragmentSpreads": FragmentRefs<"EditRuleGroupForm_fragment">;
    } | null;
  } | null;
};
export type EditRuleGroupMutation = {
  response: EditRuleGroupMutation$data;
  variables: EditRuleGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditRuleGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditRuleGroupPayload",
        "kind": "LinkedField",
        "name": "editRuleGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupNode",
            "kind": "LinkedField",
            "name": "ruleGroup",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditRuleGroupForm_fragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditRuleGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditRuleGroupPayload",
        "kind": "LinkedField",
        "name": "editRuleGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RuleGroupNode",
            "kind": "LinkedField",
            "name": "ruleGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "06c76418cf1cecf250c53d2c40bd3cb5",
    "id": null,
    "metadata": {},
    "name": "EditRuleGroupMutation",
    "operationKind": "mutation",
    "text": "mutation EditRuleGroupMutation(\n  $input: EditRuleGroupInput!\n) {\n  editRuleGroup(input: $input) {\n    ruleGroup {\n      ...EditRuleGroupForm_fragment\n      id\n    }\n  }\n}\n\nfragment EditRuleGroupForm_fragment on RuleGroupNode {\n  id\n  name\n  description\n}\n"
  }
};
})();

(node as any).hash = "a9b0add91b3dbe5b767e6fe2e12abfc6";

export default node;

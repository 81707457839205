import { Box } from "@mui/material";
import { ResponsivePie as Pie } from "@nivo/pie";

import type { PieProps } from "./types";

export default function EvenSplitPie({
  width,
  height,
  position,
  sx,
  variant,
  hidden,
}: PieProps & { hidden?: boolean }) {
  if (hidden) {
    return <></>;
  }

  const data = [
    { id: "00", label: "00:00", value: 1 },
    { id: "03", label: "03:00", value: 1 },
    { id: "06", label: "06:00", value: 1 },
    { id: "09", label: "09:00", value: 1 },
    { id: "12", label: "12:00", value: 1 },
    { id: "15", label: "15:00", value: 1 },
    { id: "18", label: "18:00", value: 1 },
    { id: "21", label: "21:00", value: 1 },
  ];
  const rotate = variant === "links" ? 360 / data.length / 2 : 0;
  return (
    <Box
      width={width}
      height={height}
      position={position}
      sx={{ ...sx, transform: `rotate(-${rotate}deg)` }}
    >
      <Pie
        data={data}
        arcLabel="label"
        startAngle={variant === "links" ? 0 : -22.5}
        arcLabelsRadiusOffset={0.94}
        enableArcLinkLabels={variant === "links"}
        colors={["transparent"]}
        isInteractive={false}
        theme={{ text: { fontFamily: "Nunito" } }}
        margin={
          variant === "labels"
            ? { top: 10, right: 10, bottom: 10, left: 10 }
            : { top: 1, right: 1, bottom: 1, left: 1 }
        }
        arcLabelsTextColor={variant === "links" ? "transparent" : "black"}
      />
    </Box>
  );
}

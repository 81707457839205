import { Stack } from "@mui/material";
import { useFormikState } from "hooks";

import {
  HeaderSwitch,
  HeaderTitle,
  SettingBox,
  SettingBoxHeader,
} from "components/setting/common";
import { Base2Info as Info } from "components/setting/common/forms";
import type { Shift } from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField";
import {
  Scaling,
  useGraphData,
} from "components/setting/common/forms/Advanced/FullAndPartTimeMeasureField";
import type { ApiSettingShiftsEmploymentDegreeLogicChoices } from "components/setting/types";

type Props = {
  name: string;
  periodLengthWeeks: number;
  shiftsPerWeek: number;
  hoursPerWeek: number;
  shifts: ReadonlyArray<Shift>;
  disabled?: boolean;
  readOnly?: boolean;
};
type TitleProps = { name: string };

const MODULE_NAME = "HoursAndShifts";

function Title({ name }: TitleProps) {
  const title = (
    <>
      Avvikande hel- och deltidsmått för <b>{name}</b>
    </>
  );
  return <HeaderTitle infoButton={<Info />} title={title} />;
}

export function ParttimesForm({
  name,
  periodLengthWeeks,
  shiftsPerWeek,
  hoursPerWeek,
  shifts,
  disabled: settingDisabled,
  readOnly,
}: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);

  const shiftsFactor = useFormikState<number>(
    "shiftsEmploymentDegreeFactor",
  )?.value;
  const shiftsScalingLogic =
    useFormikState<ApiSettingShiftsEmploymentDegreeLogicChoices>(
      "shiftsEmploymentDegreeLogic",
    )?.value.toUpperCase() as ApiSettingShiftsEmploymentDegreeLogicChoices;

  const graphData = useGraphData({
    periodLengthWeeks,
    hoursPerWeek,
    shiftsPerWeek,
    shiftsFactor,
    shiftsScalingLogic,
    shifts,
    hoursMargin: 0,
    shiftsMargin: 0,
  });

  return (
    <SettingBox>
      <Stack gap={1}>
        <SettingBoxHeader
          switchComponent={
            <HeaderSwitch name={MODULE_NAME} readOnly={readOnly} />
          }
          titleComponent={<Title name={name} />}
        />
        <Scaling
          part={`regelgrupp ${name}`}
          graphData={graphData}
          disabled={settingDisabled || disabled}
          readOnly={readOnly}
        />
      </Stack>
    </SettingBox>
  );
}

import { createContext, useContext } from "react";

const DemandFormContext = createContext({
  weekCount: 1,
  uniqueWeeks: false,
  showDutyDemand: false,
  showDemandWeights: false,
});
DemandFormContext.displayName = "DemandFormContext";
export const DemandFormProvider = DemandFormContext.Provider;
export const useDemandForm = () => useContext(DemandFormContext);

import type { PropsWithChildren } from "react";
import type { CSSObject } from "@mui/material";
import Stack from "@mui/material/Stack";

type Props = {
  sx?: CSSObject;
};

export default function ExampleBox({
  children,
  sx = {},
}: PropsWithChildren<Props>) {
  const stackSx = {
    bgcolor: "ture.10",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "divider",
    borderRadius: "4px",
    padding: "5px 10px",
    ...sx,
  };
  return <Stack sx={stackSx}>{children}</Stack>;
}

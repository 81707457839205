/*
 * Returns the current team group for the logged in user, or null otherwise.
 *
 * Implemented using Context and Provider, via react hooks.
 */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { usePageQuery } from "contexts/PageQueryContext";

import { CurrentTeamGroup_teamgroups$key as Key } from "./types";

const LOCAL_STORAGE_KEY = "currentTeamGroup";

const fragment = graphql`
  fragment CurrentTeamGroup_teamgroups on TeamGroupNode @relay(plural: true) {
    id
    name
  }
`;

type SetStringFunction = (s: string) => void;
const CurrentTeamGroupStateContext = React.createContext<string>("");
const CurrentTeamGroupUpdaterContext = React.createContext<
  SetStringFunction | undefined
>(undefined);

type Props = { children: React.ReactNode };

const CurrentTeamGroupProvider: React.FC<Props> = (props) => {
  const previousId = localStorage.getItem(LOCAL_STORAGE_KEY) || "";
  const [teamGroupId, setTeamGroup] = React.useState<string>(previousId);

  // Save selected team group id to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, teamGroupId);
  }, [teamGroupId]);

  return (
    <CurrentTeamGroupStateContext.Provider value={teamGroupId}>
      <CurrentTeamGroupUpdaterContext.Provider value={setTeamGroup}>
        {props.children}
      </CurrentTeamGroupUpdaterContext.Provider>
    </CurrentTeamGroupStateContext.Provider>
  );
};

export const useCurrentTeamGroupFragment = () => {
  const data = usePageQuery();
  return useFragment<Key>(fragment, data.teamGroups);
};

/**
 * Returns the current team group.
 *
 * This function is reactive and will cause a re-render if current team group
 * is changed.
 */
function useCurrentTeamGroup() {
  const currentId = useContext(CurrentTeamGroupStateContext);
  if (typeof currentId === "undefined") {
    throw new Error(
      "useCurrentTeamGroup must be used within a CurrentTeamGroupProvider",
    );
  }

  const teamGroups = useCurrentTeamGroupFragment();

  const group = useMemo(
    () =>
      teamGroups?.find((g) => g.id === currentId) ?? teamGroups?.[0] ?? null,
    [teamGroups, currentId],
  );

  // For some reason, useMemo above get called too often and generates a new
  // object reference even though team group is the same. This causes problems
  // for the redirect on /schedule page, when pressing a schedule period.
  // Investigate this later, but in the meantime do a simple check on id.
  const stableGroupReference = useRef(group);
  if (stableGroupReference.current?.id !== group?.id) {
    stableGroupReference.current = group;
  }

  return stableGroupReference.current;
}

/**
 * Returns a function that sets the current team group.
 */
function useCurrentTeamGroupUpdater() {
  const setTeamGroup = useContext(CurrentTeamGroupUpdaterContext);
  if (typeof setTeamGroup === "undefined") {
    throw new Error(
      "useCurrentTeamGroupUpdater must be used within a CurrentTeamGroupProvider",
    );
  }
  return useCallback(
    (teamGroupId: string) => setTeamGroup(teamGroupId),
    [setTeamGroup],
  );
}

export {
  CurrentTeamGroupProvider,
  useCurrentTeamGroup,
  useCurrentTeamGroupUpdater,
};

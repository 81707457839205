import { Stack, Typography } from "@mui/material";

import { FormikTimebankMode } from "./TimebankMode";

type Props = {
  readOnly?: boolean;
};

export function TimebankModeField({ readOnly }: Props) {
  return (
    <Stack gap={1}>
      <Typography variant="h4">Ska timbank användas?</Typography>
      <FormikTimebankMode name="timebankMode" readOnly={readOnly} />
    </Stack>
  );
}

import { Stack, Typography } from "@mui/material";
import useFormikState from "hooks/useFormikState";

import { ClockwiseShiftRotationLimitHours } from "./ClockwiseRotationLimitHours";
import { Header } from "./Header";

const MODULE_NAME = "ClockwiseRotation";

type Props = { readOnly?: boolean };

export function ClockwiseShiftRotationField({ readOnly }: Props) {
  const { value: constraintModules } =
    useFormikState<string[]>("constraintModules");
  const disabled = !constraintModules.includes(MODULE_NAME);
  const { value: radioSoft } = useFormikState<boolean>("clockwiseRotationSoft");

  return (
    <Stack gap={1}>
      <Header
        moduleName={MODULE_NAME}
        radioSoft={radioSoft}
        disabled={disabled}
        readOnly={readOnly}
      />
      <Typography variant="h4">
        Antal timmar vila tills beräkningen nollställs
      </Typography>
      <Typography>
        Detta fält styr hur många timmar vila som behöver passera innan
        beräkningen för medsols passrotation nollställs.
      </Typography>
      <ClockwiseShiftRotationLimitHours
        disabled={disabled}
        readOnly={readOnly}
      />
    </Stack>
  );
}

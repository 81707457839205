import { Stack, Typography } from "@mui/material";
import { useFormikState } from "hooks";

import { FormikSmallSwitch } from "components/common/Switch";

import { timebankModes } from "../types";

type Props = { readOnly?: boolean };

export function TimebankOffsetImbalanceField({ readOnly }: Props) {
  const timebankMode =
    useFormikState<string>("timebankMode")?.value.toUpperCase();
  const disabled = timebankMode !== timebankModes.TIMEBANK;

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="h4">Eftersträva nollsaldo</Typography>
        <FormikSmallSwitch
          name="offsetTimebankImbalance"
          readOnly={readOnly}
          disabled={disabled}
          showLabels={true}
          labelOn="Ja"
          labelOff="Nej"
        />
      </Stack>
      <Typography>
        Om detta fält är påslaget kommer algoritmen eftersträva att det totala
        timbankssaldot (sammanräknat för alla fastställda scheman) för varje
        medarbetare ligger så nära ±0 som möjligt. Det innebär att om en
        medarbetare sedan tidigare ligger på minus i totala timbankssaldo, så
        kommer algoritmen eftersträva att denna medarbetares timbankssaldo i
        nästkommande schema ligger på plus.
      </Typography>
    </Stack>
  );
}

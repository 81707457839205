import { memo, useCallback } from "react";
import { List, Stack, Typography as Text } from "@mui/material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "components/common/Accordion/FormAccordion";
import { Fail as ErrorIcon, Warning as WarningIcon } from "components/icons";
import type { WarningModuleType } from "components/types/ConstraintModuleType";
import { TranslateWarningModule } from "components/types/ConstraintModuleType";

import { GROUP_BY_MODULE, GROUP_BY_USER } from "./constants";
import type { Warning, Warnings } from "./types";
import {
  BaseWarningItem,
  GroupWarningItem,
  UserWarningItem,
} from "./WarningItem";
import { WarningListEntry } from "./WarningListEntry";

type ListProps = {
  warnings: Warnings;
};

type BaseTimelineProps = ListProps & {
  renderItem: (warning: Warning) => React.ReactNode;
};

const BaseTimeline = memo(function BaseTimelineFn({
  warnings,
  renderItem,
}: BaseTimelineProps) {
  return (
    <List disablePadding>
      {warnings.map((warning, idx) => (
        <WarningListEntry key={`${warning.module}-${idx}`} warning={warning}>
          {renderItem(warning)}
        </WarningListEntry>
      ))}
    </List>
  );
});

type WarningsGroupedByModule = Record<string, Warning[]>;

type GroupedWarningsListProps = {
  summary: React.ReactNode;
  warningList: Warning[];
  renderItem: (warning: Warning) => React.ReactNode;
};

function GroupedWarningsList({
  summary,
  warningList,
  renderItem,
}: GroupedWarningsListProps) {
  return (
    <Accordion>
      <AccordionSummary sx={{ px: 2 }}>{summary}</AccordionSummary>
      <AccordionDetails sx={{ px: 2, py: 0 }}>
        <BaseTimeline warnings={warningList} renderItem={renderItem} />
      </AccordionDetails>
    </Accordion>
  );
}

function GroupByModule({ warnings }: ListProps) {
  const groupedWarnings: WarningsGroupedByModule = warnings.reduce(
    (acc, warning) => {
      const { module } = warning;
      if (!acc[module]) {
        acc[module] = [];
      }
      acc[module].push(warning);
      return acc;
    },
    {} as WarningsGroupedByModule,
  );

  function renderSummary(hardError: boolean, module: string) {
    return (
      <Stack direction="row" alignItems="center" gap={0.5}>
        {hardError ? (
          <ErrorIcon sx={{ color: "error.main" }} />
        ) : (
          <WarningIcon sx={{ color: "warning.main" }} />
        )}
        <Text>{`${TranslateWarningModule(module as WarningModuleType)}`}</Text>
        <Text>{`(${groupedWarnings[module]?.length})`}</Text>
      </Stack>
    );
  }

  function renderItem(warning: Warning) {
    return <GroupWarningItem warning={warning} />;
  }

  return (
    <Stack>
      {Object.entries(groupedWarnings).map(([module, warningList]) => (
        <GroupedWarningsList
          key={module}
          summary={renderSummary(warningList[0].hardError, module)}
          warningList={warningList}
          renderItem={renderItem}
        />
      ))}
    </Stack>
  );
}

type WarningsGroupedByUser = Record<
  string,
  { user: NonNullable<Warning["user"]>; warnings: Warning[] }
>;

function GroupByUser({ warnings }: ListProps) {
  const groupedWarnings: WarningsGroupedByUser = warnings.reduce(
    (acc, warning) => {
      const { user } = warning;
      if (!user?.id) return acc;

      if (!acc[user.id]) {
        acc[user.id] = { user, warnings: [] };
      }
      acc[user.id].warnings.push(warning);
      return acc;
    },
    {} as WarningsGroupedByUser,
  );

  function renderSummary(userFullName: string, warnings: Warning[]) {
    const hardErrorCount = warnings.filter((w) => w.hardError).length;
    const softErrorCount = warnings.length - hardErrorCount;
    const hasHardError = hardErrorCount > 0;
    const hasSoftError = softErrorCount > 0;
    return (
      <Stack direction="row" alignItems="center" gap={0.5}>
        {hasHardError && !hasSoftError && (
          <>
            <ErrorIcon sx={{ color: "error.main" }} />
            <Text>{userFullName}</Text>
            <Text>({hardErrorCount})</Text>
          </>
        )}
        {hasSoftError && !hasHardError && (
          <>
            <WarningIcon sx={{ color: "warning.main" }} />
            <Text>{userFullName}</Text>
            <Text>({softErrorCount})</Text>
          </>
        )}
        {hasHardError && hasSoftError && (
          <>
            <ErrorIcon sx={{ color: "error.main" }} />
            <WarningIcon sx={{ color: "warning.main" }} />
            <Text>{userFullName}</Text>
            <Text>({hardErrorCount})</Text>
            <Text>({softErrorCount})</Text>
          </>
        )}
      </Stack>
    );
  }

  function renderItem(warning: Warning) {
    return <UserWarningItem warning={warning} />;
  }

  return (
    <Stack>
      {Object.entries(groupedWarnings).map(
        ([userId, { user, warnings: warningList }]) => (
          <GroupedWarningsList
            key={userId}
            summary={renderSummary(user.name, warningList)}
            warningList={warningList}
            renderItem={renderItem}
          />
        ),
      )}
    </Stack>
  );
}

type Props = {
  warnings: Warnings;
  idx: number;
};

export function WarningsList({ warnings, idx }: Props) {
  const renderItem = useCallback(
    (warning: Warning) => <BaseWarningItem warning={warning} />,
    [],
  );

  if (idx === GROUP_BY_MODULE) {
    return <GroupByModule warnings={warnings} />;
  }

  if (idx === GROUP_BY_USER) {
    return <GroupByUser warnings={warnings} />;
  }

  return <BaseTimeline warnings={warnings} renderItem={renderItem} />;
}

/**
 * @generated SignedSource<<d8a2d244e451b2823213998da3411ed6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeetingErrorCode = "MEETING_OVERLAPS_WITH_SHIFT_PART" | "MEETING_SHIFT_DOES_NOT_COVER_MEETING" | "OTHER" | "USER_HAS_CONFLICTING_MEETING" | "%future added value";
export type EditMeetingInput = {
  clientMutationId?: string | null;
  dayMatrix: ReadonlyArray<ReadonlyArray<number>>;
  end: string;
  id: string;
  name: string;
  nrMeetings: number;
  selectDaysBy: string;
  shifts: ReadonlyArray<MeetingShiftInput>;
  start: string;
  teamGroupId: string;
  users: ReadonlyArray<string>;
};
export type MeetingShiftInput = {
  breakTime: number;
  end: string;
  id?: string | null;
  meetingDay: number;
  name: string;
  otherMeetings: ReadonlyArray<string>;
  shiftParts: ReadonlyArray<MeetingShiftPartInput>;
  start: string;
};
export type MeetingShiftPartInput = {
  end: string;
  id?: string | null;
  partType: string;
  start: string;
};
export type EditMeetingMutation$variables = {
  input: EditMeetingInput;
};
export type EditMeetingMutation$data = {
  readonly editMeeting: {
    readonly errors: ReadonlyArray<{
      readonly causedByMeeting: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly causedByShift: {
        readonly name: string;
      } | null;
      readonly causedByUser: {
        readonly fullName: string;
        readonly id: string;
      } | null;
      readonly code: MeetingErrorCode;
      readonly partStartsAt: string | null;
    }> | null;
    readonly meeting: {
      readonly " $fragmentSpreads": FragmentRefs<"MeetingList_meetings">;
    } | null;
    readonly ok: boolean | null;
  } | null;
};
export type EditMeetingMutation = {
  response: EditMeetingMutation$data;
  variables: EditMeetingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "MeetingNode",
  "kind": "LinkedField",
  "name": "causedByMeeting",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullName",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "causedByUser",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partStartsAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMeetingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditMeetingPayload",
        "kind": "LinkedField",
        "name": "editMeeting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingNode",
            "kind": "LinkedField",
            "name": "meeting",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MeetingList_meetings"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SuggestedMeetingShift",
                "kind": "LinkedField",
                "name": "causedByShift",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditMeetingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditMeetingPayload",
        "kind": "LinkedField",
        "name": "editMeeting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingNode",
            "kind": "LinkedField",
            "name": "meeting",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dayMatrix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNodeConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeetingError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SuggestedMeetingShift",
                "kind": "LinkedField",
                "name": "causedByShift",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22721abff34af6e9edd5db03141bf2bf",
    "id": null,
    "metadata": {},
    "name": "EditMeetingMutation",
    "operationKind": "mutation",
    "text": "mutation EditMeetingMutation(\n  $input: EditMeetingInput!\n) {\n  editMeeting(input: $input) {\n    ok\n    meeting {\n      ...MeetingList_meetings\n      id\n    }\n    errors {\n      code\n      causedByMeeting {\n        id\n        name\n      }\n      causedByUser {\n        id\n        fullName\n      }\n      causedByShift {\n        name\n        id\n      }\n      partStartsAt\n    }\n  }\n}\n\nfragment MeetingList_meetings on MeetingNode {\n  id\n  name\n  start\n  end\n  dayMatrix\n  users {\n    edges {\n      node {\n        id\n        fullName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "33c24d879ceebd182335beaf87da066b";

export default node;

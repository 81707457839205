import {
  InputAdornment,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import useFormikState from "hooks/useFormikState";

import CustomField from "components/forms/CustomField";

import { timebankModes } from "../types";

type Props = {
  readOnly?: boolean;
};

export function TimebankAllowanceField({ readOnly }: Props) {
  const timebankModeValue =
    useFormikState<string>("timebankMode")?.value.toUpperCase();
  const disabled = timebankModeValue !== timebankModes.TIMEBANK;

  return (
    <Stack gap={1}>
      <Grid container columns={2} columnSpacing={3} rowSpacing={1}>
        <Grid xs={1}>
          <Typography variant="h4">Per schemaperiod (timmar)</Typography>
        </Grid>
        <Grid xs={1}>
          <Typography variant="h4">Totalt (timmar)</Typography>
        </Grid>
        <Grid xs={1}>
          <Typography>
            Minsta / största tillåtna timbankssaldo per period.
          </Typography>
        </Grid>
        <Grid xs={1}>
          <Typography>
            Minsta / största tillåtna ackumulerade timbankssaldo.
          </Typography>
        </Grid>
        <Grid xs={1}>
          <Stack direction="row" gap={1}>
            <CustomField
              name="minTimebankPeriod"
              fullWidth
              size="small"
              type="number"
              disabled={disabled}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">min</InputAdornment>
                ),
              }}
            />
            <CustomField
              name="maxTimebankPeriod"
              fullWidth
              size="small"
              type="number"
              disabled={disabled}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">max</InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
        <Grid xs={1}>
          <Stack direction="row" gap={1}>
            <CustomField
              name="minTimebankTotal"
              fullWidth
              size="small"
              type="number"
              disabled={disabled}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">min</InputAdornment>
                ),
              }}
            />
            <CustomField
              name="maxTimebankTotal"
              fullWidth
              size="small"
              type="number"
              disabled={disabled}
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">max</InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "libs/useMutation";
import type { SelectorStoreUpdater } from "relay-runtime";

import {
  CreateMeetingMutation as Mutation,
  CreateMeetingMutation$data as Data,
} from "./types";

const mutation = graphql`
  mutation CreateMeetingMutation($input: CreateMeetingInput!) {
    createMeeting(input: $input) {
      ok
      meeting {
        ...MeetingList_meetings @relay(plural: false)
        group {
          id
        }
      }
      errors {
        code
        causedByMeeting {
          id
          name
        }
        causedByUser {
          id
          fullName
        }
        causedByShift {
          name
        }
        partStartsAt
      }
    }
  }
`;

const updater: SelectorStoreUpdater<Data> = (store, res) => {
  const linkedRecordArgs = {
    teamGroupId: res?.createMeeting?.meeting?.group?.id,
  };

  const newRecord = store
    .getRootField("createMeeting")
    .getLinkedRecord("meeting");
  if (!newRecord) return;

  const key = "meetings";
  const root = store.getRoot();
  const list = root.getLinkedRecords(key, linkedRecordArgs);

  root.setLinkedRecords([...(list ?? []), newRecord], key, linkedRecordArgs);
};

export const useCreateMeeting = () =>
  useMutation<Mutation>(mutation, { updater });

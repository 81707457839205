import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import { Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import type { MRT_ColumnDef } from "material-react-table";
import type { Unwrap } from "relay-help/arrays";

import ColorDot from "components/common/ColorDot";
import MRTable from "components/common/MRTable";

import type {
  CompetencesList_competences$data as Data,
  CompetencesList_competences$key as Key,
} from "./types";

type Props = {
  fragmentRef: Key;
};

type Competence = Unwrap<Data>;
type ContentProps = {
  readonly competences: ReadonlyArray<{
    readonly name: string;
    readonly color: string;
    readonly description?: string;
  }>;
};

const fragment = graphql`
  fragment CompetencesList_competences on CompetenceNode @relay(plural: true) {
    name
    color
    description
  }
`;

export function CompetencesListContent({ competences }: ContentProps) {
  const columns = useMemo<MRT_ColumnDef<Competence>[]>(
    () => [
      {
        header: "Namn",
        accessorKey: "name",
        Cell: ({ cell }) => <Typography>{cell.getValue<string>()}</Typography>,
      },
      {
        header: "Beskrivning",
        accessorKey: "description",
        accessorFn: (competence) => competence.description ?? "-",
        Cell: ({ cell }) => <Typography>{cell.getValue<string>()}</Typography>,
      },
      {
        header: "Färg",
        accessorKey: "color",
        Cell: ({ cell }) => <ColorDot color={cell.getValue<string>()} />,
      },
    ],
    [],
  );

  return <MRTable columns={columns as any[]} data={competences as any[]} />;
}

export function CompetencesList({ fragmentRef }: Props) {
  const competences = useFragment<Key>(fragment, fragmentRef);

  return <CompetencesListContent competences={competences.map((c) => c)} />;
}

import { Link } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import { useFormikState } from "hooks";
import PaperWrapper from "settings/common/PaperWrapper";
import { useIsConstraintModuleEnabled } from "settings/common/useIsConstraintModuleEnabled";

import AllowedErrorMargin from "components/setting/common/forms/DayNightDist/AllowedErrorMargin";
import ShareInput from "components/setting/common/forms/DayNightDist/ShareInput";

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
  totalShifts: number;
};

const MODULE_NAME = "ShiftDayTypeDistribution";

function DayNightDistribution({ disabled, readOnly, totalShifts }: Props) {
  // Styling

  // Constraint module selector
  const { value: allowedMargin } = useFormikState(
    "shiftDayTypeAllowedErrorMargin",
  );
  const settingUrl = `/group-settings/`;

  return (
    <PaperWrapper>
      <Grid container gap={3}>
        <Stack gap={1}>
          <Typography variant="h4">
            Särskild fördelning av Dag / Kväll / Natt
          </Typography>
          <Typography>
            Dag-/Kväll-/Natt-pass definieras kan du ställa in under{" "}
            <Link to={`${settingUrl}`}>
              Enhetsinställingar &gt; Definitioner.
            </Link>
          </Typography>
        </Stack>
        <Grid container gap={2}>
          <Grid container direction="row" columns={10} alignItems="center">
            <Grid item xs={1} />
            <Grid item xs={3}>
              <Typography variant="h4">Andel</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4">Uträknat antal</Typography>
            </Grid>
            <Grid item xs={3}>
              <AllowedErrorMargin
                visible={true}
                disabled={disabled}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
          <Grid>
            <ShareInput
              allowedMargin={allowedMargin}
              isActive={true}
              totalShifts={totalShifts}
              disabled={disabled}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </PaperWrapper>
  );
}

export function DayNightDist({ readOnly, totalShifts }: Props) {
  const disabled = !useIsConstraintModuleEnabled(MODULE_NAME);

  return (
    <DayNightDistribution
      readOnly={readOnly}
      totalShifts={totalShifts}
      disabled={disabled}
    />
  );
}

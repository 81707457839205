import type { Option as TeamOption } from "components/common/selectors/TeamSelect/types";

import { TeamsExemptFromResourcePoolField } from "./TeamsExemptFromResourcePoolField";

type Props = {
  teams: TeamOption[];
  readOnly?: boolean;
};

export function ResourcePoolForm({ teams, readOnly }: Props) {
  return <TeamsExemptFromResourcePoolField teams={teams} readOnly={readOnly} />;
}

import { Suspense, useEffect } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { Dialog } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Formik } from "formik";
import * as yup from "yup";

import { useEditTeamGroup } from "../mutations";
import type { EditTeamGroupInput as FormValues } from "../types";

import type { EditTeamGroupForm_fragment$key as Key } from "./__generated__/EditTeamGroupForm_fragment.graphql";
import type { EditTeamGroupFormQuery as Query } from "./__generated__/EditTeamGroupFormQuery.graphql";
import { TeamGroupForm } from "./common";

export const teamGroupValidationSchema = yup.object({
  id: yup.string().required(),
  name: yup.string().required("Fyll i ett namn"),
});

const fragment = graphql`
  fragment EditTeamGroupForm_fragment on TeamGroupNode {
    id
    name
  }
`;

const query = graphql`
  query EditTeamGroupFormQuery($id: ID!) {
    teamGroup(id: $id) {
      ...EditTeamGroupForm_fragment
    }
  }
`;

type CommonProps = {
  open: boolean;
  onClose: () => void;
};
type Props = CommonProps & { id: string | null };
type ContentProps = CommonProps & { queryRef: PreloadedQuery<Query> };

function Content({ queryRef, open, onClose }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const selected = useFragment<Key>(fragment, data.teamGroup);
  const [commit] = useEditTeamGroup();
  const initialValues = {
    id: selected?.id || "",
    name: selected?.name || "",
  };

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } })
      .then(() => onClose())
      .catch(console.error);
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Formik
        initialValues={initialValues}
        validationSchema={teamGroupValidationSchema}
        onSubmit={onSubmit}
      >
        <TeamGroupForm
          title="Redigera enhet"
          submitText="Spara"
          onClose={onClose}
        />
      </Formik>
    </Dialog>
  );
}

export function EditTeamGroupForm({ id, open, onClose }: Props) {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);

  useEffect(() => {
    if (!id) return;
    loadQuery({ id });
  }, [id, loadQuery]);

  return (
    <Suspense fallback={null}>
      {!!queryRef && (
        <Content queryRef={queryRef} open={open} onClose={onClose} />
      )}
    </Suspense>
  );
}

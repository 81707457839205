import type { Dispatch } from "react";
import type { CSSObject } from "@mui/material";

import GenericSelect from "components/common/selectors/GenericSelect";

import type { ColorFocusType } from "./types";

type Option = {
  value: ColorFocusType;
  label: string;
};

export const options: Option[] = [
  { value: "activity_types", label: "Aktivitetstyper" },
  { value: "day_evening_night", label: "Dag/Kväll/Natt" },
  { value: "teams", label: "Avdelningar" },
  { value: "competences", label: "Kompetenser" },
  { value: "start_end_alterations", label: "Justerad start eller slut" },
];
const opts = options.map((o) => o.value);

type Props = {
  value: ColorFocusType;
  onChange: Dispatch<ColorFocusType>;
  sx?: CSSObject;
};

export function ColorFocus({ value, onChange, sx = {} }: Props) {
  return (
    <GenericSelect
      label="Färgfokus"
      value={value}
      onChange={(_e: any, v: string | null) => onChange(v as ColorFocusType)}
      getOptionLabel={(v: string) =>
        options.find((o) => o.value === v)?.label || ""
      }
      options={opts}
      sx={sx}
    />
  );
}

import { CircularProgress, Paper } from "@mui/material";

import { PageWrapper } from "components/layout";
import { OrganisationHeader } from "components/organisation";

export function MemberListPlaceholder() {
  return (
    <Paper variant="box" sx={{ height: 200 }}>
      <CircularProgress sx={{ p: 4 }} />
    </Paper>
  );
}

export function OrganisationHeaderPlaceholder({ name }: { name: string }) {
  return (
    <OrganisationHeader
      teams={[]}
      groupName={name}
      openCreateMember={() => {}}
      loading
    />
  );
}

export function OrganisationPlaceholder({ name }: { name: string }) {
  return (
    <PageWrapper header={<OrganisationHeaderPlaceholder name={name} />}>
      <MemberListPlaceholder />
    </PageWrapper>
  );
}

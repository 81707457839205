import PaperWrapper from "settings/common/PaperWrapper";

import { MipgapLimitField } from "components/setting/common/forms/Advanced";

type Props = { readOnly?: boolean };

export function OptimizationForm({ readOnly }: Props) {
  return (
    <PaperWrapper>
      <MipgapLimitField readOnly={readOnly} />
    </PaperWrapper>
  );
}

import { Select } from "@mui/material";
import { useField } from "formik";

import GenericSelect from "components/common/selectors/GenericSelect";

import { ActivityPartTypeType } from "../../types";

type Option = {
  value?: ActivityPartTypeType;
  label?: string;
};

type Props = React.ComponentProps<typeof Select> & {
  name: string;
  label?: string;
  options: Option[];
};

export function ActivityPartSelect({ name, label, options, sx }: Props) {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const selectedOption = options?.find((o) => o.value === value);

  return (
    <GenericSelect
      label={label}
      options={options}
      value={selectedOption}
      isOptionEqualToValue={(option, selected) =>
        option.value === selected.value
      }
      onChange={(_e: any, newSelected: any) => {
        setValue(newSelected?.value || "");
      }}
      sx={sx}
    />
  );
}
